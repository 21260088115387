import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Observable, Subject} from 'rxjs';

export interface Transaction {
  prefix?: string;
  title: string;
}

@Injectable({providedIn: 'root'})
export class TransactionService {
  public readonly prefixObserver: Observable<string>;
  public readonly titleObserver: Observable<string>;

  private readonly prefixSubject: Subject<string>;
  private readonly titleSubject: Subject<string>;

  private readonly _transaction: Transaction = {prefix: '', title: ''};

  public constructor(private readonly tabTitle: Title) {
    this.prefixSubject = new Subject<string>();
    this.prefixObserver = this.prefixSubject.asObservable();

    this.titleSubject = new Subject<string>();
    this.titleObserver = this.titleSubject.asObservable();
  }

  private _updateTabTitle(): void {
    this.tabTitle.setTitle(this.getFullTitle());
  }

  public getFullTitle(): string {
    return `${this._transaction.prefix}${this._transaction.title}`;
  }

  public get prefix(): string | undefined {
    return this._transaction.prefix;
  }

  public set prefix(prefix: string | undefined) {
    if (this._transaction.prefix === prefix) {
      return;
    }
    this._transaction.prefix = prefix;
    this._updateTabTitle();
    this.prefixSubject.next(prefix);
  }

  public get title(): string {
    return this._transaction.title;
  }

  public set title(title: string) {
    if (this._transaction.title === title) {
      return;
    }
    this._transaction.title = title;
    this._updateTabTitle();
    this.titleSubject.next(title);
  }
}
