import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  Optional,
} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {TableComponent} from '../table/table.component';

/**
 * A row of a table. Rows can be used in the table header or body. A row can contain multiple cells.
 *
 * @ngModule TablesModule
 */
@Component({
  selector: 'maia-table-row',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {'[style.width]': 'width ? width : null'},
  /* ScrollWidth is necessary here because of the borders of table not reaching all the content in the row */
})
export class TableRowComponent implements AfterContentChecked {
  @HostBinding('class.maia-table-row--selected')
  @Input()
  public selected = false;

  @coerceBooleanPrimitive()
  @HostBinding('class.maia-table-row--blue-theme')
  @Input()
  public blueOnSmall = false;

  private _table: TableComponent;
  public width: string;

  public constructor(@Optional() table?: TableComponent) {
    if (table == null) {
      throw new Error(
        'Cannot create a <maia-table-body /> component out of <maia-table /> component',
      );
    }

    this._table = table;
  }

  public ngAfterContentChecked() {
    // TODO: domIoService is necessary here. But if I include it, the e2e tests don't work.
    if (this._table && this._table.scrollWidth) {
      this.width = this._table.scrollWidth + 'px';
    }
  }
}
