import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprSearchEmptyStateSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaInformationExprSearchEmptyState]',
  template: '<svg:g class="nc-icon-wrapper" transform="matrix(.8 0 0 .8 4.8 4.8)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><svg:path fill="none" stroke-miterlimit="10" d="M16 18H2"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M16 30H2"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M32 42H2"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M32 6H2"></svg:path><svg:circle data-color="color-2" fill="none" stroke-miterlimit="10" cx="32" cy="24" r="8"></svg:circle><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M45 37l-7.344-7.344"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprSearchEmptyStateSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprSearchEmptyStateSvgComponent,
  ],
  exports: [
    InformationExprSearchEmptyStateSvgComponent,
  ],
})
export class InformationExprSearchEmptyStateSvgModule {}
