import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  HostListener,
  OnInit,
  Optional,
} from '@angular/core';
import {NgControl} from '@angular/forms';
import {onDestroy, UntilDestroy} from '@atlas-angular/rxjs';

import {InputContainer} from '../input-container/input-container.interface';

/**
 * Basic input component, without any extras like masks, datepickers etc.
 *
 * @ngModule FormsModule
 */
@Component({
  selector: 'input[maiaInput], input[maiaInputNumber]',

  // template: input cannot have children, so empty template (you must define a template)
  template: '',

  styleUrls: ['./input.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent {}

/**
 * @ngModule FormsModule
 */
@Directive({
  selector:
    'input[maiaInput][ngModel], input[maiaInput][formControl], input[maiaInput][formControlName], ' +
    'input[maiaInputNumber][ngModel], input[maiaInputNumber][formControl], input[maiaInputNumber][formControlName]',
})
@UntilDestroy()
export class InputWithNgControlDirective implements OnInit {
  public constructor(
    private readonly _control: NgControl,
    @Optional() private readonly _container?: InputContainer,
  ) {}

  @HostListener('blur')
  public blur(): void {
    if (this._container != null) {
      this._container.focused = false;
    }
  }

  @HostListener('focus')
  public focus(): void {
    if (this._container != null) {
      this._container.focused = true;
    }
  }

  public ngOnInit() {
    if (this._container != null) {
      const destroyFn = this._container.registerFormControl(this._control);
      onDestroy(this).subscribe(destroyFn);
    }
  }
}
