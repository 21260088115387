import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, InjectionToken, Injector, Type} from '@angular/core';

import {Configuration} from './configuration.abstract';

export interface ConfigurationProperties {
  path: string;
  type: Type<Configuration>;
}

export const CONFIG_PROPERTIES = new InjectionToken<ConfigurationProperties>('ConfUrl');

/**
 * The ConfigurationLoader should only be used by the ConfigurationModule.
 * See the documentation in ConfigurationModule on how to load configuration files.
 */
@Injectable()
export class ConfigurationLoader {
  private _loaded?: Promise<void>;

  public constructor(
    @Inject(CONFIG_PROPERTIES) private readonly properties: ConfigurationProperties[],
    private readonly http: HttpClient,
    private readonly injector: Injector,
  ) {}

  public async load(): Promise<void> {
    if (this._loaded == null) {
      this._loaded = this._doLoad();
    }

    return this._loaded;
  }

  private async _doLoad(): Promise<void> {
    const paths = new Map<string, Configuration[]>();

    for (const {path, type} of this.properties) {
      let instances = paths.get(path);
      if (!instances) {
        instances = [];
        paths.set(path, instances);
      }

      instances.push(this.injector.get(type));
    }

    await Promise.all(
      Array.from(paths, ([path, instances]) => this._loadConfiguration(path, instances)),
    );
  }

  private async _loadConfiguration(path: string, configurations: Configuration[]): Promise<void> {
    let source: Configuration;
    try {
      source = await this.http.get<Configuration>(path).toPromise();
    } catch (e) {
      // istanbul ignore next - This is hard to test because angular calls this function, not us
      throw new Error(`Could not load configuration file '${path}'`);
    }

    for (const configuration of configurations) {
      Object.assign(configuration, source);
    }
  }
}
