/**
 * The part of the format that represents the day of the month
 */
export const FORMAT_PART_DAY = 'dd';

/**
 * The part of the format that represents the month of the year
 */
export const FORMAT_PART_MONTH = 'MM';

/**
 * The part of the format that represents the year
 */
export const FORMAT_PART_YEAR = 'yyyy';

export function partsToFormatted(
  year: string | number,
  month: string | number,
  day: string | number,
): string {
  if (String(year).length !== 4) {
    throw new Error(`Expected a year of length 4 but got ${year}`);
  }

  switch (String(month).length) {
    case 2:
      break;
    case 1:
      month = `0${month}`;
      break;
    default:
      throw new Error(`Expected month of length 1 or 2 but got ${month}`);
  }

  switch (String(day).length) {
    case 2:
      break;
    case 1:
      day = `0${day}`;
      break;
    default:
      throw new Error(`Expected day of length 1 or 2 but got ${day}`);
  }

  return `${day}${month}${year}`;
}

export function formattedToParts(formatted: string): {year: number; month: number; day: number} {
  const day = parseInt(formatted.slice(0, 2), 10);
  const month = parseInt(formatted.slice(2, 4), 10);
  const year = parseInt(formatted.slice(4), 10);

  return {day, month, year};
}

/**
 * The date format for strings accepted by the constructor.
 */
export const FORMAT = partsToFormatted(FORMAT_PART_YEAR, FORMAT_PART_MONTH, FORMAT_PART_DAY);
