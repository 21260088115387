import {
  ContentChildren,
  Directive,
  forwardRef,
  HostListener,
  OnInit,
  Optional,
  QueryList,
} from '@angular/core';
import {NgControl} from '@angular/forms';
import {onDestroy, UntilDestroy} from '@atlas-angular/rxjs';
import {InputContainer, μSingleInputContainer} from '@maia/forms';

import {RadioButtonComponent} from './radio-button/radio-button.component';

/**
 * @ngModule RadioButtonsModule
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: `
      maia-radio-group[ngModel], maia-radio-group[formControl], maia-radio-group[formControlName],
      maia-toggle-group[ngModel], maia-toggle-group[formControl], maia-toggle-group[formControlName]
      `,
})
@UntilDestroy()
export class GroupWithNgControlDirective implements OnInit {
  @ContentChildren(forwardRef(() => RadioButtonComponent))
  public radioButtons: QueryList<RadioButtonComponent>;
  public constructor(
    private readonly _control: NgControl,
    @Optional() private readonly _container?: InputContainer,
    @Optional() _multiContainer?: InputContainer,
  ) {
    if (_multiContainer && !(_multiContainer instanceof μSingleInputContainer)) {
      throw new Error(
        `A maia-radio-group / maia-toggle-group should not be contained in a maia-form-element with the 'multi' directive`,
      );
    }
  }

  @HostListener('blur')
  public blur(): void {
    if (this._container != null) {
      this._container.focused = false;
    }
  }

  @HostListener('focus')
  public focus(): void {
    if (this._container != null) {
      this._container.focused = true;
      // set `focused` to the first radio-button in case all radio-buttons in radio-group are false (none is pre-selected)
      if (this.radioButtons.length > 0) {
        const noRadioButtonPreSelected = this.radioButtons
          .toArray()
          .every(radioButton => !radioButton.active);
        if (noRadioButtonPreSelected) {
          this.radioButtons.first.active = true;
        }
      }
    }
  }

  public ngOnInit(): void {
    if (this._container != null) {
      const destroyFn = this._container.registerFormControl(this._control);
      onDestroy(this).subscribe(destroyFn);
    }
  }
}
