import {BusinessType, createStringifier} from '../base/businesstype';

import {isValidTsfr} from './validator-base';

const stringifier = createStringifier('tsfr');

/**
 * BusinessType wrapper around tsfr, exposing the tsfr
 * number as a formatted text.
 */
export class Tsfr extends BusinessType<string> {
  /**
   * Returns whether or not `value` is a Tsrf.
   */
  public static isTsfr(value: unknown): value is Tsfr {
    return value instanceof Tsfr;
  }

  /**
   * Constructs a new Tsfr instance.
   *
   * @param value The tsfr value
   * @param encryptedValue The encryptedValue, if any
   */
  public constructor(value: string, encryptedValue?: string) {
    if (typeof value !== 'string') {
      throw new Error(
        `Expected a string but got ${(typeof value === 'object' &&
          (value as object).constructor.name) ||
          typeof value}`,
      );
    } else {
      if (!isValidTsfr(value)) {
        throw new Error(`Cannot create Tsfr for invalid input ${JSON.stringify(value)}`);
      }
    }
    super(value, encryptedValue);
  }

  /**
   * Returns whether `other` is a `Tsfr` representing the same value.
   */
  public equals(other: unknown): boolean {
    if (!other) {
      return false;
    }

    if (other === this) {
      return true;
    }

    return Tsfr.isTsfr(other) && this.internalValue === other.internalValue;
  }

  /**
   * Exposes the value of this BusinessType as a string. The returned value will
   * always be the same.
   */
  public asString(): string {
    return this.internalValue;
  }

  public toString(): string {
    return stringifier(this);
  }
}
