import {NgModule} from '@angular/core';

import { NavigationFuncArrowSvgModule } from './@kdl/icons/navigation/navigation-func-arrow.component';
import { NavigationFuncCrossSvgModule } from './@kdl/icons/navigation/navigation-func-cross.component';
import { NavigationFuncLiveHelpSvgModule } from './@kdl/icons/navigation/navigation-func-live-help.component';
import { CommunicationExprKateSvgModule } from './@kdl/icons/communication/communication-expr-kate.component';
import { InformationFuncCoinsSvgModule } from './@kdl/icons/information/information-func-coins.component';
import { LogosEmblemKbcSvgModule } from './@kdl/logos/logos-emblem-kbc.component';
import { LogosKbcSvgModule } from './@kdl/logos/logos-kbc.component';

@NgModule({
  exports: [
    NavigationFuncCrossSvgModule,
    NavigationFuncArrowSvgModule,
  ],
})
export class βHeaderSvgModule {
}
@NgModule({
  exports: [
    LogosKbcSvgModule,
    LogosEmblemKbcSvgModule,
    InformationFuncCoinsSvgModule,
    NavigationFuncCrossSvgModule,
    NavigationFuncLiveHelpSvgModule,
    NavigationFuncArrowSvgModule,
    CommunicationExprKateSvgModule,
  ],
})
export class βDemoSvgModule {
}
@NgModule({
  exports: [
    NavigationFuncCrossSvgModule,
    NavigationFuncLiveHelpSvgModule,
    NavigationFuncArrowSvgModule,
    CommunicationExprKateSvgModule,
  ],
})
export class βHeadersSvgModule {
}
