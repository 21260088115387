import {BusinessType, createStringifier} from '../base/businesstype';

import {isValidClientNumber} from './validator-base';

const stringifier = createStringifier('clientNumber');

/**
 * BusinessType wrapper around a client number.
 */
export class ClientNumber extends BusinessType<string> {
  /**
   * Returns whether or not `value` is a ClientNumber.
   */
  public static isClientNumber(value: unknown): value is ClientNumber {
    return value instanceof ClientNumber;
  }

  /**
   * Constructs a new ClientNumber instance.
   *
   * @param value The client number.
   * @param encryptedValue The encrypted value, if any
   */
  public constructor(value: string, encryptedValue?: string) {
    if (typeof value !== 'string') {
      throw new Error(
        `Expected a string but got ${(typeof value === 'object' &&
          (value as object).constructor.name) ||
          typeof value}`,
      );
    } else if (!isValidClientNumber(value)) {
      throw new Error(`Cannot create ClientNumber from invalid input ${JSON.stringify(value)}`);
    }

    super(value, encryptedValue);
  }

  /**
   * Returns whether `other` is a `ClientNumber` representing the same value.
   */
  public equals(other: unknown): boolean {
    if (!other) {
      return false;
    }

    if (other === this) {
      return true;
    }

    return ClientNumber.isClientNumber(other) && this.internalValue === other.internalValue;
  }

  /**
   * Exposes the value of this BusinessType as a string.
   */
  public asString(): string {
    return this.internalValue;
  }

  /**
   * Returns an string representation of this clientNumber businesstype
   */
  public toString(): string {
    return stringifier(this);
  }
}
