import {coerceNumberProperty} from '@angular/cdk/coercion';
import {Directive, forwardRef, Input} from '@angular/core';
import {NG_VALIDATORS, ValidatorFn} from '@angular/forms';
import {ExtraEmailAddressValidators, validateEmailAddress} from '@atlas/businesstypes';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore See https://github.com/microsoft/rushstack/issues/1050
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {ParameterizedValidatorConstructor, AtlasValidatorFn} from '../base/validator';
import {
  createParameterizedValidatorDirective,
  AbstractValidatorDirective,
  createValidatorFn,
} from '../base/validator';

function toNumber(value: string | number): number {
  const error = new Error(`Expected a number but got "${value}"`);
  const numericValue = coerceNumberProperty(value, error);

  if (error === numericValue) {
    throw error;
  }
  return numericValue as number;
}

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasEmailAddress]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BaseEmailAddressValidator),
      multi: true,
    },
  ],
})
export class BaseEmailAddressValidator extends AbstractValidatorDirective {
  protected readonly validatorFn = validateEmailAddress;
}

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasEmailAddress][atlasMaxLength]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MaxLengthEmailAddressValidator),
      multi: true,
    },
  ],
})
export class MaxLengthEmailAddressValidator extends createParameterizedValidatorDirective(
  ExtraEmailAddressValidators.maxLength,
) {
  @Input('atlasMaxLength')
  public set maxLength(newValue: number | string) {
    this.setParameter(toNumber(newValue));
  }
}

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasEmailAddress][atlasMinLength]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MinLengthEmailAddressValidator),
      multi: true,
    },
  ],
})
export class MinLengthEmailAddressValidator extends createParameterizedValidatorDirective(
  ExtraEmailAddressValidators.minLength,
) {
  @Input('atlasMinLength')
  public set minLength(newValue: number | string) {
    this.setParameter(toNumber(newValue));
  }
}

export const ExtraEmailAddressControlValidators = {
  minLength(minLength: number): ValidatorFn {
    return createValidatorFn(ExtraEmailAddressValidators.minLength(minLength));
  },

  maxLength(maxLength: number): ValidatorFn {
    return createValidatorFn(ExtraEmailAddressValidators.maxLength(maxLength));
  },
} as const;
