import {Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {WindowRef} from '@atlas-angular/cdk/globals';
import {LoggerFactory} from '@atlas-angular/logger';
import {Logger} from '@atlas/logger';

import {MSG_NO_INTERSECTION_OBSERVER_API} from '../shared/intersection.constants';
import {isIntersectionObserverSupported} from '../shared/intersection.utils';

import {PreviousRatioAndY} from './waypoint.interfaces';
import {getScrollDirection, isLeaving} from './waypoint.utils';

export const enum ScrollDirection {
  Up = 'up',
  Down = 'down',
}

/**
 * Applies the IntersectionObserver to the element where it's attached to as directive and
 * exposes the EventEmitters `onEnter` and `onLeave` which can be subscribed to by others.
 *
 * Be sure the element where this directive has a height & width if you want events to be
 * fired.
 *
 * If you don't care that the IntersectionObserver isn't available then you can set
 * `ignoreNotSupported` to `true`.
 * The `onIntersectionObserverIsMissing` EventEmitter will in that case emit when the API is
 * missing.
 *
 * @ngModule IntersectionsModule
 */
@Directive({
  selector: '[maiaWaypoint]',
})
export class WaypointDirective implements OnInit, OnDestroy {
  @coerceBooleanPrimitive()
  @Input('maiaAllowMissingIntersectionObserver')
  public ignoreIntersectionObserverMissing = false;

  @Output()
  public maiaWaypointEnter: EventEmitter<ScrollDirection> = new EventEmitter();

  @Output()
  public maiaWaypointLeave: EventEmitter<ScrollDirection> = new EventEmitter();

  @Output()
  public intersectionObserverIsMissing: EventEmitter<void> = new EventEmitter();

  private _observer: IntersectionObserver;
  private readonly _logger: Logger;

  private _previousRatioAndY: PreviousRatioAndY = {
    y: 0,
    ratio: 0,
  };

  public constructor(
    private readonly _windowRef: WindowRef,
    private readonly _elementRef: ElementRef<Element>,
    loggerFactory: LoggerFactory,
  ) {
    this._logger = loggerFactory.createLogger('@maia/intersections waypoint directive');
  }

  /**
   * Initialization of the IntersectionObserver API if available.
   */
  public ngOnInit() {
    const {window} = this._windowRef;
    if (isIntersectionObserverSupported(window)) {
      this._observer = new window.IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          this._callbackIntersectionObserver(entries[0]);
        },
        {threshold: 1},
      );
      this._observer.observe(this._elementRef.nativeElement);
    } else if (this.ignoreIntersectionObserverMissing) {
      this.intersectionObserverIsMissing.emit();
    } else {
      this._logger.error(MSG_NO_INTERSECTION_OBSERVER_API);
    }
  }

  public ngOnDestroy() {
    if (this._observer) {
      this._observer.unobserve(this._elementRef.nativeElement);
    }
  }

  private _callbackIntersectionObserver(entry: IntersectionObserverEntry): void {
    const emitter = isLeaving(entry, this._previousRatioAndY)
      ? this.maiaWaypointLeave
      : this.maiaWaypointEnter;
    emitter.emit(getScrollDirection(entry, this._previousRatioAndY));
    this._previousRatioAndY = {
      y: entry.boundingClientRect.top,
      ratio: entry.intersectionRatio,
    };
  }
}
