import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';

import {triggerSynchronousLayout} from './utilities';

/**
 * The available speeds to collapse a collapsible with
 */
export type CollapsibleSpeed = 'slow' | 'default' | 'fast';

/**
 * Maps the speeds to actual times that can be used in CSS
 */
const SPEEDS: {[speed in CollapsibleSpeed]: string} = Object.freeze({
  slow: '.5s',
  default: '.3s',
  fast: '.15s',
});

const DEFAULT_SPEED: CollapsibleSpeed = 'default';

const STYLE_MAX_HEIGHT = 'max-height';
const STYLE_PADDING = 'padding';
const STYLE_TRANSITION = 'transition';
const STYLE_VISIBILITY = 'visibility';

const STYLE_VALUE_UNDEFINED = '';
const MAX_HEIGHT_COLLAPSED = '0';
const PADDING_COLLAPSED = '0px';
const VISIBILITY_COLLAPSED = 'hidden';

const TRANSITION_NONE = '';

/**
 * A collapsible is an element that can collapse vertically until hidden.
 *
 * Note that this directive writes in the element's style, so take care not
 * to use the style.
 *
 * @ngModule CollapsiblesModule
 */
@Directive({
  selector: '[maiaCollapsible]',

  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[attr.aria-hidden]': 'collapsed',
    '[attr.aria-expanded]': '!collapsed',

    '[style.overflow-y]': 'collapsed || transitioning ? "hidden" : null',

    // Fix for IE11 and Edge. Hides scrollbars when there is overflowed content.
    // https://developer.mozilla.org/en-US/docs/Web/CSS/-ms-overflow-style
    '[style.-ms-overflow-style]': '"none"',
  },

  // Allow this directive to be used like this:
  //   in template:
  //     <div maiaCollapsible #collapsible="maiaCollapsible"></div>
  //   in component:
  //     @ViewChild('collapsible') private _collapsible: CollapsibleDirective;
  //     public toggle() {
  //       this._collapsible.collapsed = !this._collapsible.collapsed;
  //     }
  exportAs: 'maiaCollapsible',
})
export class CollapsibleDirective implements AfterViewInit, OnDestroy {
  private _collapsed = false;

  private _transitioning = false;

  private _maxHeight?: string;

  private _speed: CollapsibleSpeed | null = DEFAULT_SPEED;

  private _viewInitialized = false;

  private destroyFn: () => void;

  public constructor(
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly _element: ElementRef,
    private readonly _renderer: Renderer2,
  ) {}

  public ngAfterViewInit(): void {
    this._viewInitialized = true;
    if (this._collapsed) {
      this._setStyle(STYLE_VISIBILITY, VISIBILITY_COLLAPSED);
    }
    this._updateSpeedStyle();

    this.destroyFn = this._renderer.listen(
      this._element.nativeElement,
      'transitionend',
      (e: TransitionEvent) => {
        if (e.propertyName !== 'max-height') {
          return;
        }

        this._transitioning = false;
        this._maxHeight = undefined;

        if (!this._collapsed) {
          this._setStyle(STYLE_MAX_HEIGHT, STYLE_VALUE_UNDEFINED);
          this._setStyle(STYLE_PADDING, STYLE_VALUE_UNDEFINED);
        } else {
          this._setStyle(STYLE_VISIBILITY, VISIBILITY_COLLAPSED);
        }

        this._changeDetector.markForCheck();
      },
    );
  }

  public ngOnDestroy(): void {
    this.destroyFn();
  }

  /**
   * Whether the collapsible is currently transitioning between open and closed
   */
  public get transitioning(): boolean {
    return this._transitioning;
  }

  /**
   * The speed with which to collapse/uncollapse this collapsible.
   * Providing null leads to an unanimated collapse/uncollapse.
   */
  @Input('maiaCollapsibleSpeed')
  public get speed(): CollapsibleSpeed | null {
    return this._speed;
  }

  public set speed(speed: CollapsibleSpeed | null) {
    if (speed != null && !(speed in SPEEDS)) {
      throw new Error(`Unknown speed "${speed}`);
    }

    if (this._speed === speed) {
      return;
    }

    this._speed = speed;
    this._updateSpeedStyle();
  }

  /**
   * Collapses/uncollapses this collapsible.
   */
  @Input('maiaCollapsibleCollapsed')
  public get collapsed(): boolean {
    return this._collapsed;
  }

  public set collapsed(collapsed: boolean) {
    const newCollapsed = coerceBooleanProperty(collapsed);

    if (newCollapsed === this._collapsed) {
      return;
    }

    this._collapsed = newCollapsed;
    this._setStyle(STYLE_VISIBILITY, STYLE_VALUE_UNDEFINED);
    const {speed} = this;
    const shouldTransition = this._viewInitialized && speed != null;

    if (shouldTransition) {
      if (!this._transitioning) {
        this._maxHeight = this._getUncollapsedHeight();
      }

      if (newCollapsed) {
        if (!this._transitioning) {
          this.speed = null;

          this._setStyle(STYLE_MAX_HEIGHT, this._maxHeight);
          this._setStyle(STYLE_PADDING, PADDING_COLLAPSED);

          // Trigger a synchronous layout.
          // We're setting up an animation, so that's allowed.
          // The layout is needed because otherwise we'd go from
          // "max-height" unset to "max-height: 0" in one frame
          // and skip the `max-height: ${this._maxHeight}` stadium.
          // That would mean we don't get an animation.

          triggerSynchronousLayout(this._element);

          this.speed = speed;
        }

        this._setStyle(STYLE_MAX_HEIGHT, MAX_HEIGHT_COLLAPSED);
        this._setStyle(STYLE_PADDING, PADDING_COLLAPSED);
      } else {
        this._setStyle(STYLE_MAX_HEIGHT, this._maxHeight);
        this._setStyle(STYLE_PADDING, STYLE_VALUE_UNDEFINED);
      }

      this._transitioning = true;
    } else {
      if (newCollapsed) {
        this._setStyle(STYLE_MAX_HEIGHT, MAX_HEIGHT_COLLAPSED);
        this._setStyle(STYLE_PADDING, PADDING_COLLAPSED);
      } else {
        this._setStyle(STYLE_MAX_HEIGHT, STYLE_VALUE_UNDEFINED);
        this._setStyle(STYLE_PADDING, STYLE_VALUE_UNDEFINED);
      }

      this._transitioning = false;
    }
  }

  private _updateSpeedStyle(): void {
    if (!this._viewInitialized) {
      return;
    }

    if (this._speed == null) {
      this._setStyle(STYLE_TRANSITION, TRANSITION_NONE);
    } else {
      this._setStyle(STYLE_TRANSITION, `${SPEEDS[this._speed]} ${STYLE_MAX_HEIGHT}`);
    }
  }

  private _setStyle(key: string, value: any): void {
    this._renderer.setStyle(this._element.nativeElement, key, value);
  }

  /**
   * Returns a string representing the exact height of the collapsible element
   * in an uncollapsed state.
   */
  private _getUncollapsedHeight(): string {
    // Oh renderer, why don't you allow fetching values? (answer: doesn't work when server-side
    // rendering or using webworkers)

    const element = this._element.nativeElement as HTMLElement;

    // Step 0: store current transition & max-height values, as we're about to override them

    const {speed} = this;
    const currentMaxHeight = element.style.maxHeight;

    // Step 1: disable transitions, unset max-height

    this.speed = null;
    if (currentMaxHeight != null && currentMaxHeight !== STYLE_VALUE_UNDEFINED) {
      this._setStyle(STYLE_MAX_HEIGHT, STYLE_VALUE_UNDEFINED);
    }

    // Step 2: fetch the height of the element (triggers a synchronous layout)
    // We're setting up an animation, synchronous layouts are allowed!

    const height = element.scrollHeight;

    // Step 3: reset the max-height if needed
    // If it is needed: trigger a synchronous layout!
    // A layout is needed because otherwise we might get:
    //   "max-height: 0px"
    //   -> "max-height" unset
    //   -> calc height (forces layout)
    //   -> "max-height: 0px" (no new layout)
    //   -> exit this method, continue in setter for collapsed
    //   -> "max-height: <real height>px" (no new layout)
    //   -> next layout (e.g. next frame): "max-height" changes from unset to "<real height>px"
    //   -> nothing to transition, no change required

    if (currentMaxHeight != null && currentMaxHeight !== STYLE_VALUE_UNDEFINED) {
      this._setStyle(STYLE_MAX_HEIGHT, currentMaxHeight);
      triggerSynchronousLayout(this._element);
    }

    // Step 3b: reset the transition speed

    this.speed = speed;

    return `${height}px`;
  }
}
