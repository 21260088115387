import {BusinessType, createStringifier} from '../base/businesstype';

import {isValidIdentityCardNumber} from './validator-base';

const stringifier = createStringifier('identityCardNumber');

/**
 * BusinessType wrapper around a identity card number.
 */
export class IdentityCardNumber extends BusinessType<string> {
  /**
   * Returns whether or not `value` is a IdentityCardNumber.
   */
  public static isIdentityCardNumber(value: unknown): value is IdentityCardNumber {
    return value instanceof IdentityCardNumber;
  }

  /**
   * Constructs a new IdentityCardNumber instance.
   *
   * @param value The identity card number.
   * @param encryptedValue The encrypted value, if any
   */
  public constructor(value: string, encryptedValue?: string) {
    if (typeof value !== 'string') {
      throw new Error(
        `Expected a string but got ${(typeof value === 'object' &&
          (value as object).constructor.name) ||
          typeof value}`,
      );
    } else if (!isValidIdentityCardNumber(value)) {
      throw new Error(
        `Cannot create Identity Card Number from invalid input ${JSON.stringify(value)}`,
      );
    }

    super(value, encryptedValue);
  }

  /**
   * Returns whether `other` is a `Identity Card Number` representing the same
   * value.
   */
  public equals(other: unknown): boolean {
    if (!other) {
      return false;
    }

    if (other === this) {
      return true;
    }

    return (
      IdentityCardNumber.isIdentityCardNumber(other) && this.internalValue === other.internalValue
    );
  }

  /**
   * Exposes the value of this BusinessType as a string.
   */
  public asString(): string {
    return this.internalValue;
  }

  public toString(): string {
    return stringifier(this);
  }
}
