import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule QuickSearchPriceTagSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaQuickSearchPriceTag]',
  template: '<svg:path d="M411.8 166.1c-11.4 0-22.8-4.3-31.5-13-17.4-17.4-17.4-45.6 0-63 16.8-16.8 46.2-16.8 63 0 17.4 17.3 17.4 45.6 0 63-8.7 8.7-20.1 13-31.5 13zm0-66.7c-5.9 0-11.5 2.3-15.7 6.5-8.7 8.7-8.7 22.8 0 31.5s22.8 8.7 31.5 0 8.7-22.8 0-31.5c-4.2-4.2-9.8-6.5-15.8-6.5z"></svg:path><svg:path d="M225.6 512c-8.6 0-16.7-3.3-22.7-9.4L9.4 309.2C3.4 303.1 0 295 0 286.4s3.3-16.7 9.4-22.7l253-253C273.3-.1 289-.1 304 0h169c21.5 0 39 17.5 39 39v169.2c.1 15.1.1 30.7-10.7 41.5l-253 253c-6.1 6-14.2 9.3-22.7 9.3zm74.7-489.7c-9.7 0-18.4.4-22.2 4.2l-253 253c-1.9 1.9-2.9 4.4-2.9 7 0 2.7 1 5.1 2.9 7l193.4 193.4c3.7 3.7 10.3 3.7 14 0l253-253c4.3-4.3 4.2-14.6 4.2-25.6V39c0-9.2-7.5-16.7-16.7-16.7H300.3z"></svg:path><svg:path d="M333.9 239.8c-.1-17-4.6-29.9-13.5-38.8-9.2-9.2-20.6-12.8-34.4-11-11 1.5-22.6 6.2-34.8 14.3l51.8 51.8-16.1 6.2-48.1-48.1c-1.1.9-2.1 1.8-3.1 2.8l-2.7 2.7-2 2c-.6.6-1.2 1.3-1.7 2l45.6 45.6-15.9 6.4-39.9-39.9c-22.2 30.3-23.7 55-4.6 74.1 5.9 5.9 12.2 9.9 19 12.1 5 1.6 12.4 2.6 22.3 2.9l-19.2 19.2c-13.4-2.9-25.4-9.7-36-20.3-29.9-29.9-29.6-65.1.7-105.8l-14.2-14 16.1-6.2 8.4 8.4c.5-.7 1-1.4 1.6-2l1.8-1.8 3.1-3.1c1-1 1.9-1.8 2.9-2.6l-13.4-13.4 15.9-6.4 9.9 9.9c17.9-12.1 35.3-18.4 52.2-18.8 19-.7 35.7 6.1 50.1 20.5 11.1 11.1 18.3 26 21.6 44.7l-23.4 6.6z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 512 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickSearchPriceTagSvgComponent {
}

@NgModule({
  declarations: [
    QuickSearchPriceTagSvgComponent,
  ],
  exports: [
    QuickSearchPriceTagSvgComponent,
  ],
})
export class QuickSearchPriceTagSvgModule {}
