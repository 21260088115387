import {Observable} from 'rxjs';

import {getDestroySubject, ensureClassIsDecorated} from '../decorators/internals';

/**
 * Returns an observable that emits one value and completes once the given destroyable is destroyed.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function onDestroy(instance: object): Observable<void> {
  ensureClassIsDecorated(instance, 'function onDestroy');

  return getDestroySubject(instance).asObservable();
}
