import {Directive, forwardRef, Inject} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Text, TextFormatter} from '@atlas/businesstypes';

import {
  ATLAS_VALUE_ACCESSOR,
  AtlasControlValueAccessor,
  BaseBusinesstypeDirective,
} from '../base/index';

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasText]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextDirective),
    },
  ],
})
export class TextDirective extends BaseBusinesstypeDirective {
  public constructor(@Inject(ATLAS_VALUE_ACCESSOR) input: AtlasControlValueAccessor) {
    super(input);
  }

  public writeValue(value?: Text | string): void {
    this._input.writeValue(
      Text.isText(value) ? TextFormatter.format(value) : value == null ? '' : value,
    );
  }

  public registerOnChange(fn: (_: Text | null) => void): void {
    this._input.registerOnChange(value => {
      fn(value === '' ? null : new Text(TextFormatter.unformat(value)));
    });
  }
}
