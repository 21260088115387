import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationFuncOkSvgModule
 */
@Component({
  selector: 'svg[ivyHermesInformationFuncOk]',
  template: '<svg:g class="nc-icon-wrapper"><svg:path class="st0" d="M18.8 9.2c-.5 0-.8.2-1.1.6-.3.4-.4.8-.4 1.5v1.3c0 .6.2 1.1.4 1.5.3.4.7.6 1.1.6.5 0 .9-.2 1.2-.6s.4-.8.4-1.5v-1.3c0-.6-.2-1.1-.4-1.5-.3-.4-.7-.6-1.2-.6z" transform="matrix(.8 0 0 .8 4.4 2.4)"></svg:path><svg:path class="st0" d="M40.8 0L6 2.4c-2 .1-3.8 2-4.1 4L0 20.5c-.3 2.1 1.1 3.8 3.1 3.5L38 20.6c2-.2 3.8-2.1 4.1-4.2L44 3.6c.2-2.1-1.2-3.7-3.2-3.6zM21.6 12.5c0 .8-.3 1.6-.7 2.1-.5.5-1.1.8-2 .8-.7 0-1.5-.3-2-.8-.5-.6-.7-1.3-.7-2.1v-1.3c0-.8.2-1.6.7-2.1.5-.6 1.1-.8 2-.8.8 0 1.5.3 2 .8.5.6.7 1.3.7 2.1v1.3zm5.2 2.9l-2.1-3H24v3h-1.1v-7H24v3h.7l2.1-3H28l-2.4 3.4 2.6 3.7h-1.4z" transform="matrix(.8 0 0 .8 4.4 2.4)"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 44 24',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationFuncOkSvgComponent {
}

@NgModule({
  declarations: [
    InformationFuncOkSvgComponent,
  ],
  exports: [
    InformationFuncOkSvgComponent,
  ],
})
export class InformationFuncOkSvgModule {}
