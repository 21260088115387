import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';
import {TilesModule} from '@maia/tiles';

import {ActionGroupComponent} from './action-group/action-group.component';
import {ActionTileComponent} from './action-tile/action-tile.component';
import {LineClampDirective} from './line-clamp/line-clamp.directive';

@NgModule({
  imports: [BusinessTypesModule, CommonModule, TilesModule],
  declarations: [ActionGroupComponent, ActionTileComponent, LineClampDirective],
  exports: [ActionGroupComponent, ActionTileComponent, LineClampDirective],
})
export class ActionTileModule {}
