import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule QuickSearchDetailedSearchSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaQuickSearchDetailedSearch]',
  template: '<svg:path d="M453.2 92.7H0V29.3C0 13.1 13.2 0 29.3 0h394.6c16.2 0 29.3 13.1 29.3 29.3v63.4zM20.6 72.1h412V29.3c0-4.8-3.9-8.7-8.7-8.7H29.3c-4.8 0-8.7 3.9-8.7 8.7v42.8z"></svg:path><svg:path d="M247.2 422.3H10.3C4.6 422.3 0 417.7 0 412V82.4c0-5.7 4.6-10.3 10.3-10.3s10.3 4.6 10.3 10.3v319.3h226.6c5.7 0 10.3 4.6 10.3 10.3s-4.6 10.3-10.3 10.3z"></svg:path><svg:path d="M442.9 267.8c-5.7 0-10.3-4.6-10.3-10.3V82.4c0-5.7 4.6-10.3 10.3-10.3 5.7 0 10.3 4.6 10.3 10.3v175.1c0 5.7-4.6 10.3-10.3 10.3z"></svg:path><svg:path d="M144.2 154.5h236.9v20.6H144.2z"></svg:path><svg:path d="M72.1 154.5h41.2v20.6H72.1z"></svg:path><svg:path d="M144.2 206h236.9v20.6H144.2z"></svg:path><svg:path d="M72.1 206h41.2v20.6H72.1z"></svg:path><svg:path d="M144.2 257.5h144.2v20.6H144.2z"></svg:path><svg:path d="M72.1 257.5h41.2v20.6H72.1z"></svg:path><svg:path d="M144.2 309h103v20.6h-103z"></svg:path><svg:path d="M72.1 309h41.2v20.6H72.1z"></svg:path><svg:path d="M370.8 473.8c-56.8 0-103-46.2-103-103s46.2-103 103-103 103 46.2 103 103-46.2 103-103 103zm0-185.4c-45.4 0-82.4 37-82.4 82.4s37 82.4 82.4 82.4 82.4-37 82.4-82.4-37-82.4-82.4-82.4z"></svg:path><svg:path transform="rotate(45.001 468.674 468.663)" d="M417.7 458.4h102V479h-102z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 512 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickSearchDetailedSearchSvgComponent {
}

@NgModule({
  declarations: [
    QuickSearchDetailedSearchSvgComponent,
  ],
  exports: [
    QuickSearchDetailedSearchSvgComponent,
  ],
})
export class QuickSearchDetailedSearchSvgModule {}
