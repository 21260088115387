import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

/**
 * @ngModule FlowChapter2Module
 */
@Component({
  selector: 'maia-flow-chapter',
  templateUrl: './flow-chapter2.component.html',
  styleUrls: ['./flow-chapter2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowChapter2Component implements AfterContentInit {
  private _disabled = false;
  private _completed = false;
  private _clickable = true;
  public step: string;

  @Input()
  public title: string;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public set role(role: string) {}

  @Input()
  public type = 'submit';

  @coerceBooleanPrimitive()
  @Input()
  public autofocus = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  public readonly click: EventEmitter<Event> = new EventEmitter<Event>();

  public constructor(private readonly cdr: ChangeDetectorRef, private readonly _el: ElementRef) {}

  @HostListener('keyup.space')
  @HostListener('keyup.spacebar')
  @HostListener('keyup.enter')
  public onKeydownHandler(): void {
    if (!this.disabled) {
      const eventType = this.type === 'button' ? 'click' : this.type;
      this.click.emit(
        new Event(eventType, {
          cancelable: true,
          bubbles: true,
        }),
      );
    }
  }

  public ngAfterContentInit(): void {
    if (this.autofocus) {
      (this._el.nativeElement as HTMLElement).focus();
    }
  }

  @HostBinding('class.disabled')
  @Input()
  public set disabled(disabled: boolean) {
    this._disabled = disabled;
  }
  public get disabled(): boolean {
    return this._disabled;
  }

  @HostBinding('class.completed')
  @Input()
  public set completed(completed: boolean) {
    this._completed = completed;
  }
  public get completed(): boolean {
    return this._completed;
  }

  @HostBinding('class.clickable')
  @Input()
  public set clickable(clickable: boolean) {
    this._clickable = clickable;
  }
  public get clickable(): boolean {
    return this._clickable;
  }

  @Input()
  /**
   * Converts step number to a string (e.g. 2 --> '02')
   *
   * @param chapterNumber
   */
  @Input()
  public set chapterNumber(chapterNumber: number) {
    if (!chapterNumber) {
      return;
    }

    this.step = `0${chapterNumber}`.slice(-2);

    // This is required because we set the chapterNumber through contentChildren in the FlowChapter2GroupComponent
    this.cdr.detectChanges();
  }

  @HostBinding('attr.tabIndex')
  public get tabIndex(): string {
    return this.disabled || !this.clickable ? '-1' : '0';
  }
}
