import {InjectionToken, ValueProvider} from '@angular/core';
import {Text} from '@atlas/businesstypes';
import {UniformTypeIdentifier} from '@hermes/open-resources';
import {noop} from 'rxjs';

import {
  DropZoneConfiguration,
  εDropZoneConfigurationInterface,
} from '../file-drop-zone/file-drop-zone-aggregator.component';
import {
  FileDeleteCall,
  FileUploadCall,
  Reference,
  TemporaryResourceStoreDownload,
} from '../file-uploader/file-uploader.call.factory';

/**
 * Possibility to control several file upload settings. Should be added as a provider (via the modal
 * options). When not provided (or not all settings), a default implementation will be used/merged.
 */
export interface FileUploaderSettings {
  /**
   * Additional info that should be shown on the slide-in.
   */
  additionalInfo?: Text;
  /**
   * Should multiple (simultaneous) uploads to allowed.
   * Note: even when true it could be that some file selectors don't support multiple file
   * selections (e.g. camera capture).
   */
  allowMultipleUpload?: boolean;

  /**
   * Should the uploaded file(s) allowed to be downloaded.
   */
  allowDownload?: boolean;

  /**
   * Removes the uploaded file on backend. By default, no file deletion call is configured, as
   * files are stored temporary in session anyway.
   */
  fileDeletionCall?: () => FileDeleteCall;

  /**
   * Possibility to choose a custom download resource url.
   * @param type which kind of resource to download. This may be needed to detect how to open
   * a downloaded resource.
   */
  fileDownloadResource?: (type: UniformTypeIdentifier) => TemporaryResourceStoreDownload;

  /**
   * Possibility to choose a custom upload service call.
   */
  fileUploadCall?: () => FileUploadCall;

  /**
   * Displays initial references (if any). This is especially useful to edit a previous file upload.
   */
  initialReferences?: Reference[];

  /**
   * Configures the maximum allowed file size.
   */
  maxFileSize?: number;

  /**
   * Configures the minimum number of required files.
   */
  minNumberOfFiles?: number;

  /**
   * Configures the maximum number of allowed files.
   */
  maxNumberOfFiles?: number;

  /**
   * Configures which mime types should be allowed.
   * Defaults to text, images and pdf files.
   */
  mimeTypes?: RegExp;

  /**
   * DropZones configuration
   */
  dropZones?: DropZoneConfiguration[];

  /**
   * callback when a user uploads a file
   */
  afterUpload?: (references: Reference[]) => void;

  /**
   * callback when a user downloads a file
   */
  afterDownload?: (reference: Reference) => void;

  /**
   * callback when a user deletes a file
   */
  afterDelete?: (reference: Reference) => void;
}

/**
 * Since the passed settings are merged with the default implementation, all settings are provided.
 * However, typescript still thinks it might be undefined, so breaks the build when using a property
 * on the file selectors.
 */
export interface εFileUploaderSettingsStrict extends FileUploaderSettings {
  allowMultipleUpload: boolean;
  allowDownload: boolean;
  additionalInfo: Text;
  fileDeletionCall: () => FileDeleteCall;
  fileDownloadResource: (type: UniformTypeIdentifier) => TemporaryResourceStoreDownload;
  fileUploadCall: () => FileUploadCall;
  initialReferences: Reference[];
  maxFileSize: number;
  maxTotalFileSize: number;
  maxNumberOfFiles: number;
  minNumberOfFiles: number;
  mimeTypes: RegExp;
  dropZones: εDropZoneConfigurationInterface[];
  afterUpload: (references: Reference[]) => void;
  afterDownload: (reference: Reference) => void;
  afterDelete: (reference: Reference) => void;
}

export const FILE_UPLOADER_SETTINGS = new InjectionToken<FileUploaderSettings>(
  'fileUploaderSettings',
);

export const DEFAULT_MAX_NUM_OF_FILES = 6;
export const DEFAULT_MIN_NUM_OF_FILES = 1;
export const DEFAULT_MIME_TYPES = /^image\/|^text\/|^application\/pdf$/;
export const DEFAULT_DROP_ZONES = [{}];
export const DEFAULT_MAX_FILE_SIZE = 10 * 1024 * 1024;
export const DEFAULT_ALLOW_DOWNLOAD = true;
export const DEFAULT_MAX_TOTAL_FILE_SIZE = Infinity;
export const DEFAULT_UPLOAD_CALLBACK = noop;
export const DEFAULT_DOWNLOAD_CALLBACK = noop;
export const DEFAULT_DELETE_CALLBACK = noop;

export const DEFAULT_SETTINGS_PROVIDER: ValueProvider = {
  provide: FILE_UPLOADER_SETTINGS,
  useValue: {
    additionalInfo: null,
    allowMultipleUpload: true,
    allowDownload: DEFAULT_ALLOW_DOWNLOAD,
    initialReferences: [],
    fileUploadCall: undefined,
    fileDownloadResource: undefined,
    fileDeletionCall: undefined,
    maxFileSize: DEFAULT_MAX_FILE_SIZE,
    maxTotalFileSize: DEFAULT_MAX_TOTAL_FILE_SIZE,
    minNumberOfFiles: DEFAULT_MIN_NUM_OF_FILES,
    maxNumberOfFiles: DEFAULT_MAX_NUM_OF_FILES,
    mimeTypes: DEFAULT_MIME_TYPES,
    dropZones: DEFAULT_DROP_ZONES,
    afterUpload: DEFAULT_UPLOAD_CALLBACK,
    afterDelete: DEFAULT_DELETE_CALLBACK,
    afterDownload: DEFAULT_DOWNLOAD_CALLBACK,
  },
};
