import {ModuleWithProviders, NgModule, Optional} from '@angular/core';
import {CONNECTOR_INTERCEPTORS} from '@atlas-angular/connector';

import {HeadersInterceptor} from './headers.interceptor';

@NgModule()
export class HeadersModule {
  public static forRoot(): ModuleWithProviders<HeadersModule> {
    return {
      ngModule: HeadersModule,
      providers: [
        HeadersInterceptor,
        {provide: CONNECTOR_INTERCEPTORS, useExisting: HeadersInterceptor, multi: true},
      ],
    };
  }

  public constructor(@Optional() headersInterceptor?: HeadersInterceptor) {
    if (headersInterceptor == null) {
      throw new Error('Import HeadersModule.forRoot() in AppModule, not HeadersModule directly');
    }
  }
}
