import {Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {CssClassUtility, CssClassUtilityFactory} from '@maia/core';

/**
 * Available options for "align-items" option
 */
export type RowAlign = 'start' | 'end' | 'center' | 'baseline' | 'stretch';
// Class for RowAlign in XS breakpoint
const RowAlignClasses = {
  rowAlign: {
    start: 'maia-grid-row--align-items-start',
    end: 'maia-grid-row--align-items-end',
    center: 'maia-grid-row--align-items-center',
    baseline: 'maia-grid-row--align-items-baseline',
    stretch: 'maia-grid-row--align-items-stretch',
  } as {[key in RowAlign]: string | null},
};
// Class for RowAlign in SM breakpoint
const RowAlignSmClasses = {
  rowAlignSm: {
    start: 'maia-grid-row--align-items-sm-start',
    end: 'maia-grid-row--align-items-sm-end',
    center: 'maia-grid-row--align-items-sm-center',
    baseline: 'maia-grid-row--align-items-sm-baseline',
    stretch: 'maia-grid-row--align-items-sm-stretch',
  } as {[key in RowAlign]: string | null},
};
// Class for RowAlign in MD breakpoint
const RowAlignMdClasses = {
  rowAlignMd: {
    start: 'maia-grid-row--align-items-md-start',
    end: 'maia-grid-row--align-items-md-end',
    center: 'maia-grid-row--align-items-md-center',
    baseline: 'maia-grid-row--align-items-md-baseline',
    stretch: 'maia-grid-row--align-items-md-stretch',
  } as {[key in RowAlign]: string | null},
};
// Class for RowAlign in LG breakpoint
const RowAlignLgClasses = {
  rowAlignLg: {
    start: 'maia-grid-row--align-items-lg-start',
    end: 'maia-grid-row--align-items-lg-end',
    center: 'maia-grid-row--align-items-lg-center',
    baseline: 'maia-grid-row--align-items-lg-baseline',
    stretch: 'maia-grid-row--align-items-lg-stretch',
  } as {[key in RowAlign]: string | null},
};
// Class for RowAlign in XL breakpoint
const RowAlignXlClasses = {
  rowAlignXl: {
    start: 'maia-grid-row--align-items-xl-start',
    end: 'maia-grid-row--align-items-xl-end',
    center: 'maia-grid-row--align-items-xl-center',
    baseline: 'maia-grid-row--align-items-xl-baseline',
    stretch: 'maia-grid-row--align-items-xl-stretch',
  } as {[key in RowAlign]: string | null},
};

/**
 * RowAlignDirective to determine align-items class in XS
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-row[rowAlign]',
})
export class RowAlignDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof RowAlignClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(RowAlignClasses, renderer, elementRef);
  }

  @Input()
  public set rowAlign(rowAlign: RowAlign | null) {
    this._cssClassUtility.setValue('rowAlign', rowAlign);
  }
}

/**
 * RowAlignDirective to determine align-items class in SM
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-row[rowAlignSm]',
})
export class RowAlignSmDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof RowAlignSmClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(RowAlignSmClasses, renderer, elementRef);
  }

  @Input()
  public set rowAlignSm(rowAlignSm: RowAlign | null) {
    this._cssClassUtility.setValue('rowAlignSm', rowAlignSm);
  }
}

/**
 * RowAlignDirective to determine align-items class in MD
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-row[rowAlignMd]',
})
export class RowAlignMdDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof RowAlignMdClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(RowAlignMdClasses, renderer, elementRef);
  }

  @Input()
  public set rowAlignMd(rowAlignMd: RowAlign | null) {
    this._cssClassUtility.setValue('rowAlignMd', rowAlignMd);
  }
}

/**
 * RowAlignDirective to determine align-items class in LG
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-row[rowAlignLg]',
})
export class RowAlignLgDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof RowAlignLgClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(RowAlignLgClasses, renderer, elementRef);
  }

  @Input()
  public set rowAlignLg(rowAlignLg: RowAlign | null) {
    this._cssClassUtility.setValue('rowAlignLg', rowAlignLg);
  }
}

/**
 * RowAlignDirective to determine align-items class in XL
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-row[rowAlignXl]',
})
export class RowAlignXlDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof RowAlignXlClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(RowAlignXlClasses, renderer, elementRef);
  }

  @Input()
  public set rowAlignXl(rowAlignXl: RowAlign | null) {
    this._cssClassUtility.setValue('rowAlignXl', rowAlignXl);
  }
}
