/**
 * The minimum length of a card number
 */
export const CARD_NUMBER_MIN_LENGTH = 16;

/**
 * The maximum length of a card number
 */
export const CARD_NUMBER_MAX_LENGTH = 17;

/**
 * The length of a card number group
 */
export const GROUP_LENGTH = 4;

/**
 * The length of the last card number group considering a maximum length card number
 */
export const LAST_GROUP_LENGTH = 1;

/**
 * The starting position for the first card number group
 */
export const FIRST_GROUP_START_POSITION = 0;

/**
 * The starting position for the second card number group
 */
export const SECOND_GROUP_START_POSITION = GROUP_LENGTH;

/**
 * The starting position for the third card number group
 */
export const THIRD_GROUP_START_POSITION = SECOND_GROUP_START_POSITION + GROUP_LENGTH;

/**
 * The starting position for the fourth card number group
 */
export const FOURTH_GROUP_START_POSITION = THIRD_GROUP_START_POSITION + GROUP_LENGTH;

/**
 * The starting position for the fifth card number group
 */
export const FIFTH_GROUP_START_POSITION = FOURTH_GROUP_START_POSITION + GROUP_LENGTH;
