import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SubNavigationToggleMobileSignSvgModule
 */
@Component({
  selector: 'svg[ivyHermesSubNavigationToggleMobileSign]',
  template: '<svg:g><svg:path d="M383.1 0H128.9c-24 0-43.5 19.5-43.5 43.5v425c0 24 19.5 43.5 43.5 43.5h254.3c24 0 43.5-19.5 43.5-43.5v-425c0-24-19.5-43.5-43.6-43.5zM256 492.6c-12.9 0-23.4-10.5-23.4-23.4s10.5-23.4 23.4-23.4 23.4 10.5 23.4 23.4-10.4 23.4-23.4 23.4zm128-65.9H128v-384h256v384z"></svg:path><svg:path d="M268.2 262.4c0-7.5-6.1-13.6-13.6-13.6s-13.6 6.1-13.6 13.6c0 5.4 3.2 10.1 7.7 12.3l-5.1 30.4h21.9l-5.1-30.4c4.6-2.2 7.8-6.9 7.8-12.3z"></svg:path><svg:path d="M283 175.6c-2.5 0-4.4-2-4.4-4.4 0-13.9-11.3-25.2-25.2-25.2-13.9 0-25.2 11.3-25.2 25.2 0 2.5-2 4.4-4.4 4.4s-4.4-2-4.4-4.4c0-18.8 15.3-34.1 34.1-34.1s34.1 15.3 34.1 34.1c-.1 2.4-2.1 4.4-4.6 4.4z"></svg:path><svg:path d="M283 192.8c-2.5 0-4.5-2-4.5-4.5v-17.2c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5v17.2c0 2.5-2 4.5-4.5 4.5z"></svg:path><svg:path d="M223.7 221.8c-2.5 0-4.5-2-4.5-4.5v-46.2c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5v46.2c0 2.5-2 4.5-4.5 4.5z"></svg:path><svg:g><svg:path d="M309.7 338H199.4c-8 0-14.5-6.5-14.5-14.5v-94.2c0-8 6.5-14.5 14.5-14.5h110.2c8 0 14.5 6.5 14.5 14.5v94.2c.1 8-6.4 14.5-14.4 14.5zM199.4 223.6c-3.1 0-5.6 2.5-5.6 5.6v94.2c0 3.1 2.5 5.6 5.6 5.6h110.2c3.1 0 5.6-2.5 5.6-5.6v-94.2c0-3.1-2.5-5.6-5.6-5.6H199.4z"></svg:path></svg:g></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 512 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubNavigationToggleMobileSignSvgComponent {
}

@NgModule({
  declarations: [
    SubNavigationToggleMobileSignSvgComponent,
  ],
  exports: [
    SubNavigationToggleMobileSignSvgComponent,
  ],
})
export class SubNavigationToggleMobileSignSvgModule {}
