import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionFuncTurnRightSvgModule
 */
@Component({
  selector: 'svg[ivyHermesActionFuncTurnRight]',
  template: '<svg:path d="M5.3 12.5v-2.3a4.7 4.7 0 0 1 4.6-4.7h5.6" fill="none" stroke-linecap="round" stroke-miterlimit="8"></svg:path><svg:path d="M9.5 11.8h9.2V21H9.5z" fill="none" stroke-linecap="round" stroke-miterlimit="8"></svg:path><svg:path d="M12.9 3l2.6 2.6-2.6 2.6" fill="none" stroke-linecap="round" stroke-miterlimit="8"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionFuncTurnRightSvgComponent {
}

@NgModule({
  declarations: [
    ActionFuncTurnRightSvgComponent,
  ],
  exports: [
    ActionFuncTurnRightSvgComponent,
  ],
})
export class ActionFuncTurnRightSvgModule {}
