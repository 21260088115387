import {Formatter} from '../base/formatter';

import {PhoneNumber} from './businesstype';
import {COUNTRY_REGEX} from './phone-number-localization';

export const PhoneNumberFormatter: Formatter<PhoneNumber> = {
  /**
   * Returns the formatted phone number for supported countries, and the same string otherwise
   *
   * @param value The text to "format"
   */
  format(value: PhoneNumber): string {
    const stringValue = value.asString();
    if (COUNTRY_REGEX.BE.test(stringValue)) {
      return formatBelgianNumber(stringValue);
    } else if (COUNTRY_REGEX.CZ.test(stringValue)) {
      return formatCzechNumber(stringValue);
    } else if (COUNTRY_REGEX.SK.test(stringValue)) {
      return formatSlovakNumber(stringValue);
    } else if (COUNTRY_REGEX.HU.test(stringValue)) {
      return formatHungarianNumber(stringValue);
    }
    return stringValue;
  },

  /**
   *  Removes all whitespaces.
   *
   * @param formattedValue The string to unformat.
   */
  unformat(formattedValue: string): string {
    return formattedValue.replace(/ /g, '');
  },
};

/**
 * Formats the phone number according to https://en.wikipedia.org/wiki/Telephone_numbers_in_Belgium
 * @param value
 */
function formatBelgianNumber(value: string): string {
  const prefix = value.charAt(3);
  switch (prefix) {
    case '4':
      return `${value.substring(0, 3)} ${value.substring(3, 6)} ${value.substring(
        6,
        8,
      )} ${value.substring(8, 10)} ${value.substring(10, 12)}`;
    case '2':
    case '3':
    case '9':
      return `${value.substring(0, 3)} ${value.substring(3, 4)} ${value.substring(
        4,
        7,
      )} ${value.substring(7, 9)} ${value.substring(9, 11)}`;
    default:
      return `${value.substring(0, 3)} ${value.substring(3, 5)} ${value.substring(
        5,
        7,
      )} ${value.substring(7, 9)} ${value.substring(9, 11)}`;
  }
}

/**
 * Formats the phone number according to https://en.wikipedia.org/wiki/Telephone_numbers_in_Hungary
 * @param value
 */
function formatHungarianNumber(value: string): string {
  const prefix = value.charAt(3);
  if (prefix === '1') {
    return `${value.substring(0, 3)} ${value.substring(3, 4)} ${value.substring(
      4,
      8,
    )} ${value.substring(8, 12)}`;
  } else {
    return `${value.substring(0, 3)} ${value.substring(3, 5)} ${value.substring(
      5,
      8,
    )} ${value.substring(8, 12)}`;
  }
}

/**
 * Formats the phone number according to
 * https://en.wikipedia.org/wiki/Telephone_numbers_in_the_Czech_Republic
 * @param value
 */
function formatCzechNumber(value: string): string {
  return `${value.substring(0, 4)} ${value.substring(4, 7)} ${value.substring(
    7,
    10,
  )} ${value.substring(10, 13)}`;
}

/**
 * Formats the phone number according to https://en.wikipedia.org/wiki/Telephone_numbers_in_Slovakia
 * @param value
 */
function formatSlovakNumber(value: string): string {
  const prefix = value.charAt(4);
  if (prefix === '2') {
    return `${value.substring(0, 4)} ${value.substring(4, 5)} ${value.substring(
      5,
      9,
    )} ${value.substring(9, 13)}`;
  } else {
    return `${value.substring(0, 4)} ${value.substring(4, 6)} ${value.substring(
      6,
      9,
    )} ${value.substring(9, 13)}`;
  }
}
