import {Component, ChangeDetectionStrategy} from '@angular/core';

/**
 * @ngModule LayoutModule
 * @deprecated Use the `maia-content-tail-layout` mixin instead
 */
@Component({
  selector: 'maia-content-footer-layout',
  template: '<ng-content></ng-content>',
  styleUrls: ['./layout-content-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutContentFooterComponent {}
