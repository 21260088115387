/*
 * Factory providing the correct call instance for file-uploader.
 *
 * The default implementation provides a plain json variant
 */
import {InjectionToken} from '@angular/core';
import {Hidden, Text} from '@atlas/businesstypes';
import {Call, CallOptions, CallType, PathIdentifier} from '@atlas/convertor';
import {EnhancedPlainJsonCall, CtorObject} from '@atlas/convertor-plain-json';
import {HermesContextualPathIdentifier} from '@hermes/core';
import {DynamicResourceDownload, UniformTypeIdentifier} from '@hermes/open-resources';

export interface FileUploadOutput {
  references: Reference[];
}

export interface FileDeleteOutput {}

export interface Reference {
  id: Hidden;
  name: Text;
  type: Text;
}

export interface ResourceRequest {
  referenceId: Hidden;
}

export type FileUploadCall<T extends FileUploadOutput = FileUploadOutput> = Call<FormData, T>;

export type FileDeleteCall<T extends FileDeleteOutput = FileDeleteOutput> = Call<Reference, T>;

type PlainFileUploadCallArgs<T extends FileUploadOutput> = [FileUploadOutput] extends [T]
  ? [] | [CtorObject<T>]
  : [CtorObject<T>];

function ensureShape<T extends FileUploadOutput>(shape?: CtorObject<T>): CtorObject<T> {
  if (shape) {
    return shape;
  }

  const defaultShape: CtorObject<FileUploadOutput> = {
    references: [{id: Hidden, name: Text, type: Text}],
  };
  // Thanks to typescript we can only get here if T is FileUploadOutput.
  // Sadly, typescript doesn't understand that, so we need to cast to any here.
  return (defaultShape as unknown) as CtorObject<T>;
}

export class PlainFileUploadCall<T extends FileUploadOutput = FileUploadOutput>
  extends EnhancedPlainJsonCall<FormData, T>
  implements FileUploadCall<T> {
  public identifier = new HermesContextualPathIdentifier('fileUpload', '1');

  public options: CallOptions = {callType: CallType.COMMIT};

  public constructor(...args: PlainFileUploadCallArgs<T>);
  public constructor(shape?: CtorObject<T>) {
    super(ensureShape(shape));
  }

  public convertInput(input: FormData): FormData {
    return input;
  }
}

export class PlainFileDeletionCall<T extends FileDeleteOutput = FileDeleteOutput>
  extends EnhancedPlainJsonCall<Reference, T>
  implements FileDeleteCall<T> {
  // don't bother the user with error messages on deletion
  public options: CallOptions = {callType: CallType.COMMIT, skipLoggingErrors: true};
  public identifier = new HermesContextualPathIdentifier('fileDelete', '1');
}

export abstract class TemporaryResourceStoreDownload implements DynamicResourceDownload {
  public abstract readonly identifier: PathIdentifier;
  public abstract readonly type: UniformTypeIdentifier;
}

export class DefaultTemporaryResourceStoreDownload extends TemporaryResourceStoreDownload {
  public identifier = new HermesContextualPathIdentifier('fileDownload', '1');

  public constructor(public readonly type: UniformTypeIdentifier) {
    super();
  }
}

export interface FileUploaderCallFactoryInterface {
  getFileUploadCall(): FileUploadCall;

  getFileDeletionCall(): FileDeleteCall | undefined;

  getFileDownloadResource(type: UniformTypeIdentifier): TemporaryResourceStoreDownload;
}

export class FileUploaderCallFactory implements FileUploaderCallFactoryInterface {
  public getFileUploadCall(): FileUploadCall {
    return new PlainFileUploadCall();
  }

  public getFileDeletionCall(): FileDeleteCall | undefined {
    return undefined;
  }

  public getFileDownloadResource(type: UniformTypeIdentifier): TemporaryResourceStoreDownload {
    return new DefaultTemporaryResourceStoreDownload(type);
  }
}

export const FILE_UPLOADER_CALL_FACTORY = new InjectionToken<FileUploaderCallFactoryInterface>(
  'fileUploaderCallFactory',
  {
    providedIn: 'root',
    factory: () => new FileUploaderCallFactory(),
  },
);
