import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprSignDeviceSvgModule
 */
@Component({
  selector: 'svg[ivyHermesInformationExprSignDevice]',
  template: '<svg:path d="M39 35.8a2 2 0 0 1-.6 1.3 23.2 23.2 0 0 1-14.5 5.2 21.8 21.8 0 0 1-14.2-5.2 1.4 1.4 0 0 1-.6-1.2V7.2a1.6 1.6 0 0 1 1.5-1.6h26.8A1.6 1.6 0 0 1 39 7.1z" fill="none" stroke-miterlimit="8" stroke-width="1.6"></svg:path><svg:path d="M14.5 9.6h18.6v7.1H14.5z" fill="none"></svg:path><svg:path d="M14.5 9.6h18.6v7.1H14.5z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M17 21.8a2.2 2.2 0 0 1 0 4.4 2.2 2.2 0 0 1 0-4.4z" fill="none" stroke-miterlimit="3.2" stroke-width="1.6"></svg:path><svg:path d="M24 21.8a2.2 2.2 0 1 1-2.1 2.2 2.2 2.2 0 0 1 2.1-2.2z" fill="none" stroke-miterlimit="3.2" stroke-width="1.6"></svg:path><svg:path d="M31 21.8a2.2 2.2 0 1 1-2.1 2.2 2.2 2.2 0 0 1 2.1-2.2z" fill="none" stroke-miterlimit="3.2" stroke-width="1.6"></svg:path><svg:path d="M17 29.4a2.2 2.2 0 0 1 0 4.4 2.2 2.2 0 0 1 0-4.4z" fill="none" stroke-miterlimit="3.2" stroke-width="1.6"></svg:path><svg:path d="M24 29.4a2.2 2.2 0 1 1-2.1 2.2 2.2 2.2 0 0 1 2.1-2.2z" fill="none" stroke-miterlimit="3.2" stroke-width="1.6"></svg:path><svg:path d="M31 29.4a2.2 2.2 0 1 1-2.1 2.2 2.2 2.2 0 0 1 2.1-2.2z" fill="none" stroke-miterlimit="3.2" stroke-width="1.6"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprSignDeviceSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprSignDeviceSvgComponent,
  ],
  exports: [
    InformationExprSignDeviceSvgComponent,
  ],
})
export class InformationExprSignDeviceSvgModule {}
