import {Directive, forwardRef, Input} from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';
import {Country, ExtraPhoneNumberValidators, validatePhoneNumber} from '@atlas/businesstypes';

import {AtlasValidatorFn, createValidatorFn} from '../base/validator';

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasPhoneNumber]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BasePhoneNumberValidator),
      multi: true,
    },
  ],
})
export class BasePhoneNumberValidator implements Validator {
  private localizedPhoneNumberValidator?: AtlasValidatorFn;

  @Input('atlasCountry')
  public set country(newCountry: Country) {
    this.localizedPhoneNumberValidator = ExtraPhoneNumberValidators.localizedPhoneNumber(
      newCountry,
    );
  }

  public validate(control?: AbstractControl): ValidationErrors | null {
    // allow undefined/null values (optional fields)
    if (control == null || control.value == null) {
      return null;
    }

    return (
      validatePhoneNumber(control.value) ??
      (
        this.localizedPhoneNumberValidator ?? ExtraPhoneNumberValidators.callCodePrefixedPhoneNumber
      )(control.value)
    );
  }
}

export const ExtraPhoneNumberControlValidators = {
  localizedPhoneNumber(country: Country | string): ValidatorFn {
    return createValidatorFn(ExtraPhoneNumberValidators.localizedPhoneNumber(country));
  },
} as const;
