import {ComponentFactory, ComponentFactoryResolver, Injectable, Injector} from '@angular/core';
import {ModalController} from '@maia/modals';
import {EMPTY, Observable} from 'rxjs';
import {mergeMapTo} from 'rxjs/operators';

import {LoadingOverlayContentComponent} from '../loading-overlay-content/loading-overlay-content.component';
import {
  LOADING_OVERLAY_OPTIONS,
  LoadingOverlayComponent,
  LoadingOverlayOptions,
  MODAL_TYPE_LOADING_OVERLAY,
} from '../loading-overlay.component';

@Injectable()
export class LoadingOverlayController {
  private readonly _loadingOverlayFactory: ComponentFactory<LoadingOverlayComponent>;
  private readonly _loadingOverlayContentFactory: ComponentFactory<LoadingOverlayContentComponent>;

  public constructor(
    private readonly _modalCtrl: ModalController,
    componentFactoryResolver: ComponentFactoryResolver,
    private readonly injector: Injector,
  ) {
    this._loadingOverlayFactory = componentFactoryResolver.resolveComponentFactory(
      LoadingOverlayComponent,
    );
    this._loadingOverlayContentFactory = componentFactoryResolver.resolveComponentFactory(
      LoadingOverlayContentComponent,
    );
  }

  /**
   * Prepares the loading overlay. Once the returned observable is subscribed to, the loading
   * overlay will be shown. The loading overlay gets dismissed if the subscription is ended.
   *
   * By default the open loading overlay will block scrolling on the page.
   *
   * @param options The text for the loading overlay
   *
   */
  public prepare<O>(options: LoadingOverlayOptions): Observable<never> {
    const modalOptions = {
      withClickableBackdrop: false,
      blockScrolling: true,
      providers: [{provide: LOADING_OVERLAY_OPTIONS, useValue: options}],
    };

    return this._modalCtrl
      .prepare(
        MODAL_TYPE_LOADING_OVERLAY,
        this.injector,
        this._loadingOverlayFactory,
        this._loadingOverlayContentFactory,
        modalOptions,
      )
      .pipe(mergeMapTo(EMPTY));
  }
}
