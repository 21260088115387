import {NgModule} from '@angular/core';
import {DropdownsModule} from '@maia/dropdowns';
import {SelectDropdownModule} from '@maia/dropdowns/select';
import {FormsModule} from '@maia/forms';
import {FormsCaptureModule} from '@maia/forms/capture';
import {μDropdownListsSvgModule} from '@maia/icons';
import {OptionModule, SelectPartsModule} from '@maia/select';

import {μInputSelectOpenAsSlideInDirective} from './input-select-opener.directive';
import {InputSelectWithControlDirective} from './input-select-with-control.directive';
import {InputSelectComponent} from './input-select.component';

@NgModule({
  imports: [
    DropdownsModule,
    μDropdownListsSvgModule,
    FormsModule,
    SelectDropdownModule,
    SelectPartsModule,
  ],
  declarations: [
    InputSelectComponent,
    InputSelectWithControlDirective,
    μInputSelectOpenAsSlideInDirective,
  ],
  exports: [
    FormsCaptureModule,
    OptionModule,

    InputSelectComponent,
    InputSelectWithControlDirective,
    μInputSelectOpenAsSlideInDirective,
  ],
})
export class InputSelectModule {}
