/**
 * Predefined positions of the dropdown.
 *
 * Positions are defined by the unbounded edges of the dropdown:
 *
 * - Vertical:
 *   - top: the bottom edge of the dropdown is positioned at the top of the reference point (+
 *     margins), the top edge is unbounded
 *   - bottom: the top edge of the dropdown is positioned at the bottom of the reference point (+
 *     margins), the bottom edge is unbounded
 * - Horizontal:
 *   - left: the right edge of the dropdown aligns with the right edge of the reference point, the
 *     left edge of the dropdown is unbounded
 *   - middle: the center of the dropdown aligns with the center of the reference point, the left
 *     and right edges of the dropdown are unbounded
 *   - right: the left edge of the dropdown aligns with the left edge of the reference point, the
 *     right edge of the dropdown is unbounded
 *   - aligned: both left and right edges of the dropdown align with the corresponding edge of the
 *     reference point
 */
export enum DropdownPosition {
  TOP_LEFT = 'top-left',
  TOP_MIDDLE = 'top-middle',
  TOP_RIGHT = 'top-right',
  TOP_ALIGNED = 'top-aligned',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_MIDDLE = 'bottom-middle',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_ALIGNED = 'bottom-aligned',
}

export const enum DropdownAlignment {
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Aligned = 'aligned',
}

export const enum DropdownAnchor {
  Top = 'top',
  Bottom = 'bottom',
}

export function parsePosition(
  position: DropdownPosition,
): {anchor: DropdownAnchor; alignment: DropdownAlignment} {
  const [anchor, alignment] = position.split('-', 2) as [DropdownAnchor, DropdownAlignment];
  return {anchor, alignment};
}

const POSITION_CLASS_PREFIX = 'p-maia-dropdown--position-';

export const POSITION_CLASSES: Readonly<Record<DropdownPosition, string>> = Object.freeze({
  [DropdownPosition.TOP_LEFT]: `${POSITION_CLASS_PREFIX}${DropdownPosition.TOP_LEFT}`,
  [DropdownPosition.TOP_MIDDLE]: `${POSITION_CLASS_PREFIX}${DropdownPosition.TOP_MIDDLE}`,
  [DropdownPosition.TOP_RIGHT]: `${POSITION_CLASS_PREFIX}${DropdownPosition.TOP_RIGHT}`,
  [DropdownPosition.TOP_ALIGNED]: `${POSITION_CLASS_PREFIX}${DropdownPosition.TOP_ALIGNED}`,
  [DropdownPosition.BOTTOM_LEFT]: `${POSITION_CLASS_PREFIX}${DropdownPosition.BOTTOM_LEFT}`,
  [DropdownPosition.BOTTOM_MIDDLE]: `${POSITION_CLASS_PREFIX}${DropdownPosition.BOTTOM_MIDDLE}`,
  [DropdownPosition.BOTTOM_RIGHT]: `${POSITION_CLASS_PREFIX}${DropdownPosition.BOTTOM_RIGHT}`,
  [DropdownPosition.BOTTOM_ALIGNED]: `${POSITION_CLASS_PREFIX}${DropdownPosition.BOTTOM_ALIGNED}`,
});

/**
 * The minimum amount of margin (in pixel) to keep between the edge of the viewport and the
 * dropdown.
 */
export const MARGIN_FROM_VIEWPORT = 10; /* px */

/**
 * The minimum amount of margin (in pixel) to keep between reference and dropdown
 */
export const MARGIN_FROM_REFERENCE = 2; /* px */

/**
 * Reasons for which a dropdown can be repositioned.
 */
export const enum RepositionReason {
  /**
   * One reposition is needed when setting up the dropdown
   */
  SETUP = 0,

  /**
   * Reposition due to scrolling
   */
  SCROLL = 1,

  /**
   * Reposition due to a window resize
   */
  RESIZE = 2,

  /**
   * Reposition due to an external request
   */
  EXTERNAL = 3,
}
