import {ActiveDescendantKeyManager} from '@angular/cdk/a11y';
import {SPACE} from '@angular/cdk/keycodes';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  forwardRef,
  HostBinding,
  HostListener,
  QueryList,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

import {SelectTileComponent} from '../select-tile/select-tile.component';

/**
 * Select tile group component
 *
 * @ngModule SelectTileModule
 */
@Component({
  selector: 'maia-select-group',
  template: '<ng-content></ng-content>',
  styleUrls: ['./select-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectGroupComponent), multi: true},
  ],
  host: {role: 'radiogroup', tabIndex: '0'},
})
export class SelectGroupComponent<T> implements ControlValueAccessor, AfterContentInit {
  @ContentChildren(forwardRef(() => SelectTileComponent))
  private readonly _selectTilesQueryList: QueryList<SelectTileComponent<T>>;

  public selectedValue: T | null;
  private keyManager: ActiveDescendantKeyManager<SelectTileComponent<T>>;

  @HostListener('blur')
  public onTouched = () => {};

  public onChange = (_: any) => {};

  @HostListener('keydown', ['$event'])
  public keyDownEvent(event: KeyboardEvent) {
    if (event.keyCode === SPACE) {
      this.keyManager.setActiveItem(this.selectedValueIndex);
      this._updateValueFromKeyManager();
      event.preventDefault();
      event.stopPropagation();
    }
    this.keyManager.onKeydown(event);
  }

  private get selectedValueIndex(): number {
    if (this._selectTilesQueryList == null) {
      return -1;
    }

    const tiles = this._selectTilesQueryList.toArray();
    const selectedValueIndex = tiles.findIndex(tile => tile.value === this.selectedValue);

    if (selectedValueIndex !== -1) {
      return selectedValueIndex;
    }

    return tiles.findIndex(tile => !tile.disabled);
  }

  public constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  public ngAfterContentInit() {
    this.keyManager = new ActiveDescendantKeyManager(this._selectTilesQueryList)
      .withWrap()
      .withHorizontalOrientation('ltr');
    this.keyManager.change.subscribe(() => this._updateValueFromKeyManager());
  }

  private _updateValueFromKeyManager(): void {
    this._selectTilesQueryList.forEach(
      tile => (tile.selected = tile === this.keyManager.activeItem),
    );

    if (this.keyManager.activeItem != null) {
      this.selectedValue = this.keyManager.activeItem.value;
    } else {
      this.selectedValue = null;
    }

    this.onChange(this.selectedValue);
  }

  public writeValue(obj: any): void {
    this.selectedValue = obj;

    if (this.keyManager != null && obj != null) {
      this.keyManager.setActiveItem(this.selectedValueIndex);

      this.changeDetectorRef.detectChanges();
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public select(tile: SelectTileComponent<T>): void {
    this.keyManager.setActiveItem(tile);
    // Make sure to trigger the onchange event in case the selectedValue is undefined.
    // This can be the case when initially loading the page and the first tile is active, but not
    // selected. Clicking on it will not cause a change in the keyManagers stream (because its
    // already active), thus not triggering the onChange event.
    if (this.selectedValue === undefined) {
      this.onChange(tile.value);
    }
  }

  @HostBinding('attr.aria-activedescendant')
  public get activeDescendant(): string | null {
    if (this.keyManager.activeItem == null) {
      return null;
    }

    return this.keyManager.activeItem.id;
  }
}
