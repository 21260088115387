import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FileFuncCloudCheckSvgModule
 */
@Component({
  selector: 'svg[ivyHermesFileFuncCloudCheck]',
  template: '<svg:g class="nc-icon-wrapper"><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04" d="M6.538 14l4 4L19.87 6" data-color="color-2"></svg:path><svg:path d="M15.398 7.36a5.992 5.992 0 0 0-9.784 4.35A3.194 3.194 0 0 0 6.4 18" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04"></svg:path><svg:path d="M14.4 18h3.2a3.2 3.2 0 0 0 1.24-6.152" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
    'data-color': 'color-2',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileFuncCloudCheckSvgComponent {
}

@NgModule({
  declarations: [
    FileFuncCloudCheckSvgComponent,
  ],
  exports: [
    FileFuncCloudCheckSvgComponent,
  ],
})
export class FileFuncCloudCheckSvgModule {}
