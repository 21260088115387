import {Injectable, Optional} from '@angular/core';
import {Company, DEFAULT_DEVICE, Device, Language} from '@hermes/core';

import {
  CompanyConfiguration,
  DeviceConfiguration,
  LanguageConfiguration,
} from './stringed-configuration';

/**
 * An input for configuration values of type `T`
 *
 * This input can be
 *
 * - A value directly
 * - An object mapping companies to values
 * - An object mapping languages to values
 * - An object mapping companies to objects mapping languages to values
 *
 * For example:
 *
 * ```ts
 * // Trying to configure a URL to the KBC website
 *
 * const simple = 'https://www.kbc.be/en';
 *
 * const companyBased = new CompanyConfiguration({
 *   '0001': 'https://www.kbc.be/en',
 *   '0002': 'https://www.cbc.be/en',
 *   '9998': 'https://www.kbcbrussels.be/en',
 * });
 *
 * const languageBased = new LanguageConfiguration({
 *   de: 'https://kbc.be/de',
 *   en: 'https://kbc.be/en',
 *   fr: 'https://kbc.be/fr',
 *   nl: 'https://kbc.be/nl',
 * });
 *
 * const companyAndLanguageBased = new CompanyConfiguration({
 *   '0001': new LanguageConfiguration({
 *     de: 'https://kbc.be/de',
 *     en: 'https://kbc.be/en',
 *     fr: 'https://kbc.be/fr',
 *     nl: 'https://kbc.be/nl',
 *   }),
 *   '0002': new LanguageConfiguration({
 *     de: 'https://cbc.be/de',
 *     en: 'https://cbc.be/en',
 *     fr: 'https://cbc.be/fr',
 *     nl: 'https://cbc.be/nl',
 *   }),
 *   '9998': new LanguageConfiguration({
 *     de: 'https://kbcbrussels.be/de',
 *     en: 'https://kbcbrussels.be/en',
 *     fr: 'https://kbcbrussels.be/fr',
 *     nl: 'https://kbcbrussels.be/nl',
 *   }),
 * });
 * ```
 */
type MaybeDeviceConfiguration<T> = T | DeviceConfiguration<T>;
type MaybeLanguageConfiguration<T> = T | LanguageConfiguration<T>;
type MaybeCompanyConfiguration<T> = T | CompanyConfiguration<T>;
export type ExtractableConfiguration<T> = MaybeCompanyConfiguration<
  MaybeLanguageConfiguration<MaybeDeviceConfiguration<T>>
>;

/**
 * An object containing `ExtractableConfiguration` properties.
 */
export type ExtractableConfigurationObject<T extends Object> = {
  [k in keyof T]: ExtractableConfiguration<T[k]>;
};

/**
 * Options for extracting values
 */
export interface ExtractionOptions<T> {
  /**
   * The default value to use when the value is not defined
   */
  defaultValue: T;
}

/**
 * Service that extracts configuration values using the configured language and company
 *
 * This service can be used to write a single in-code configuration object and then extract the
 * correct value using the company and language the application is running with.
 *
 * When configuring library packages there are two options:
 *
 * - The library accepts all configuration at import time, e.g. `LibraryModule.forRoot(someConfig)`.
 *   This is perfect for options in the library, e.g. "enable analytics". It is not ideal for values
 *   that are internal to the library but that depend on the context of the application (company or
 *   language). For that...
 * - The ConfigurationExtractor can extract configuration stored in the library itself. This is
 *   perfect for internal values that depends on the context of the application, e.g. the URL to
 *   open when the user clicks help is an internal value of the library package but it depends on
 *   the language the application is running in.
 */
@Injectable({providedIn: 'root'})
export class ConfigurationExtractor {
  public constructor(
    private readonly _company: Company,
    private readonly _language: Language,
    @Optional() private readonly _device: Device,
  ) {
    if (this._device == null) {
      this._device = DEFAULT_DEVICE;
    }
  }

  /**
   * Extract a single configuration value
   *
   * If the given input value doesn't contain a value for the current company or language, the
   * default value is used. If no default is provided, this function assumes the value is
   * accidentally missing, e.g. because the library author forgot to include configuration for a
   * certain language or company, and an error is thrown. To allow values to be absent, pass in a
   * default value.
   *
   * @param value The configuration value to extract
   * @param options Options, like default value
   * @returns The extracted value
   * @throws if the input value doesn't have a value for the current company and language and no
   * default is provided
   */
  public extract<T>(value: ExtractableConfiguration<T>, options?: ExtractionOptions<T>): T {
    try {
      if (value instanceof CompanyConfiguration) {
        if (!value.has(this._company.code)) {
          throw new Error(
            `Company configuration ${value} doesn't have a value for company ${this._company.code}`,
          );
        }
        value = value.get(this._company.code);
      }
      if (value instanceof LanguageConfiguration) {
        if (!value.has(this._language.code)) {
          throw new Error(
            `Language configuration ${value} doesn't have a value for language ${this._language.code}`,
          );
        }
        value = value.get(this._language.code);
      }
      if (value instanceof DeviceConfiguration) {
        if (!value.has(this._device.code)) {
          throw new Error(
            `Device configuration ${value} doesn't have a value for device ${this._device.code}`,
          );
        }
        value = value.get(this._device.code);
      }
    } catch (e) {
      if (options != null) {
        return options.defaultValue;
      }
      throw e;
    }

    return value;
  }

  /**
   * Extract an object containing configuration properties
   *
   * If the given input value's properties don't contain a value for the current company or
   * language, the corresponding default value is used. If no default is provided for that property,
   * this function assumes the property's value is accidentally missing, e.g. because the library
   * author forgot to include configuration for a certain language or company, and an error is
   * thrown. To allow property values to be absent, pass in a default value of `null` for that
   * property.
   *
   * @param value The object containing configuration value properties to extract
   * @param options Options, like default value
   * @returns The extracted value object
   * @throws if any of the input value's properties don't have a value for the current company and
   * language and no default is provided for these missing propertie(s)
   */
  public extractObject<T extends Object>(
    value: ExtractableConfigurationObject<T>,
    options?: ExtractionOptions<Partial<T>>,
  ): T {
    const defaultValue = options != null ? options.defaultValue : {};

    return (Object.keys(value) as (keyof T)[]).reduce((result, key) => {
      result[key] = this.extract<T[keyof T]>(
        value[key],
        key in defaultValue ? {defaultValue: (defaultValue as T)[key]} : undefined,
      );

      return result;
    }, {} as Partial<T>) as T;
  }
}
