import {Directive, Optional} from '@angular/core';
import {NgControl} from '@angular/forms';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {InputContainer} from '@maia/forms';
import {μInputSelectWithControlDirective} from '@maia/input-select';

import {InputAutocompleteComponent} from './input-autocomplete.component';

/**
 * @ngModule InputAutocompleteModule
 */
@Directive({
  selector:
    'maia-input-autocomplete[ngModel], maia-input-autocomplete[formControl], maia-input-autocomplete[formControlName]',
})
@UntilDestroy()
export class InputAutocompleteWithControlDirective extends μInputSelectWithControlDirective {
  public constructor(
    input: InputAutocompleteComponent<unknown>,
    ngControl: NgControl,
    @Optional() container?: InputContainer,
  ) {
    super(input, ngControl, container);
  }
}
