import {Component, Optional} from '@angular/core';
import {ListItemContentComponent} from '../list-item-content/list-item-content.component';

/**
 * @ngModule ListsModule
 */
@Component({
  selector: 'maia-list-item-content-icon',
  template: '<ng-content></ng-content>',
  styleUrls: ['./list-item-content-icon.component.scss'],
})
export class ListItemContentIconComponent {
  public constructor(@Optional() parent: ListItemContentComponent) {
    if (parent === null) {
      throw new Error(
        'Cannot create a <maia-list-item-content-icon /> component out of <maia-list-item-content /> component',
      );
    }
  }
}
