import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Text} from '@atlas/businesstypes';
import {SystemMessageController} from '@maia/system-messages/toasts';

import {DEFAULT_SYSTEM_MESSAGE_TYPE, MessageType} from './system-message.utils';

/**
 * @deprecated
 * System Message Component
 *
 * @ngModule SystemMessagesModule
 */
@Component({
  selector: 'maia-system-message',
  templateUrl: './system-message.component.html',
  styleUrls: ['./system-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class SystemMessageComponent implements AfterContentInit {
  @ViewChild('messageContent', {static: true})
  public content: ElementRef<HTMLElement>;

  private _showCloseButton = true;

  /**
   * @deprecated
   * The text for the action button.
   */
  @Input()
  public actionButtonText: Text | string | null;

  /**
   * @deprecated
   * The text for the close button.
   */
  @Input()
  public set closeButtonText(value: Text | string | null) {}

  /**
   * @deprecated
   * The system message type.
   */
  @Input()
  public type: MessageType;

  /**
   * @deprecated
   */
  @Input()
  public get showCloseButton(): boolean {
    return this._showCloseButton;
  }

  public set showCloseButton(showCloseButton: boolean) {
    this._showCloseButton = coerceBooleanProperty(showCloseButton);
  }

  /**
   * @deprecated
   */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  public readonly close: EventEmitter<void>;

  /**
   * @deprecated
   */
  @Output()
  public readonly action: EventEmitter<void>;

  public constructor(private readonly _systemMessageController: SystemMessageController) {
    this.type = DEFAULT_SYSTEM_MESSAGE_TYPE;
    this.actionButtonText = null;
    this.close = new EventEmitter<void>();
    this.action = new EventEmitter<void>();
  }

  public ngAfterContentInit(): void {
    const systemMessage = {
      messageContent: this.content.nativeElement.textContent || '[No message content found]',
      messageType: this.type,
      hideClose: !this.showCloseButton,
      messageActionLabel: Text.isText(this.actionButtonText)
        ? this.actionButtonText.asString()
        : this.actionButtonText || '',
      messageAction: () => this.onAction(),
      onClose: () => this.onClose(),
    };

    this._systemMessageController
      .showSystemMessage(systemMessage)
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  /**
   * @deprecated
   * The action for the Right Button
   */
  public onAction(): void {
    this.action.emit();
  }

  /**
   * @deprecated
   * The close function
   */
  public onClose(): void {
    this.close.emit();
  }
}
