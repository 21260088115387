import {Directive, OnDestroy, OnInit, Optional, TemplateRef} from '@angular/core';

import {FooterContainer} from './footer-container.service';

/**
 * The maiaSlideInFooter directive can be used to mark a part of the maia slide-in content
 * as part of the footer instead of the general content.
 *
 * @ngModule SlideInsModule
 */
@Directive({selector: '[maiaSlideInFooter]'})
export class SlideInFooterDirective implements OnInit, OnDestroy {
  public constructor(
    private _template: TemplateRef<any>,
    @Optional() private _container: FooterContainer,
  ) {
    // FooterContainer is optional so we can throw our own error instead of the generic error when a
    // parameter can't be found in the injector.
    if (this._container == null) {
      throw new Error('*maiaSlideInFooter can only be used inside a slide-in content component');
    }
  }

  public ngOnInit(): void {
    this._container.setFooter(this._template);
  }

  public ngOnDestroy(): void {
    this._container.setFooter(undefined);
  }
}
