import {FocusOrigin} from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {ModalControl} from '@maia/modals';
import {Option, OptionComponent} from '@maia/select';

import {BaseInputAutocompleteComponent} from '../input/base-input-autocomplete.component';
import {OptionHighlightComponent} from '../option/option-highlight.component';
import {OptionWithHighlights} from '../option/option-with-highlights';
import {CustomValueConverter, DefaultCustomValueConverter} from './custom-option.converter';

/**
 * An extra custom option to show in the autocomplete dropdown, which allows the user to type and
 * select a custom value.
 *
 * @ngModule InputAutocompleteExportedPartsModule
 */
@Component({
  selector: 'maia-option[custom]',
  templateUrl: './custom-option.component.html',
  styleUrls: ['./custom-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: OptionWithHighlights, useExisting: forwardRef(() => CustomOptionComponent)},
  ],
})
@UntilDestroy()
export class CustomOptionComponent<T> extends OptionWithHighlights<T> implements OnInit {
  private _option?: OptionComponent<T> = undefined;

  private _optionHighlight?: OptionHighlightComponent;

  public show = false;

  private _selected = false;

  private _value: T;

  private _active = false;

  private _control: ModalControl<Option<T>>;

  private registeredOptionHighlight = false;

  @Input()
  // cast to any because typescript doesn't know T = Text if no valueConvertor is passed
  public valueConverter: CustomValueConverter<T> = new DefaultCustomValueConverter() as any;

  public constructor(
    private readonly _input: BaseInputAutocompleteComponent<unknown>,
    private readonly _cdr: ChangeDetectorRef,
  ) {
    super();
  }

  @ViewChild(OptionComponent)
  public set option(option: OptionComponent<T> | undefined) {
    this._option = option;

    if (option != null) {
      option.selected = this._selected;
      option.control = this._control;
    }
  }

  @ViewChild(OptionHighlightComponent)
  public set optionHighlight(optionHighlight: OptionHighlightComponent) {
    // The OptionHighlightComponent can be destroyed because there is an ngIf directive on it.
    // When it is destroyed unregister the previous highlight and mark that we should register the new
    // OptionHighlightComponent when the type ahead emits a new value.
    if (this._optionHighlight) {
      this.unregisterHighlight(this._optionHighlight);
      this.registeredOptionHighlight = false;
    }
    this._optionHighlight = optionHighlight;
  }

  public ngOnInit() {
    this._input.typeAhead.pipe(takeUntilDestroyed(this)).subscribe(typeAhead => {
      this.show = !!typeAhead;
      if (typeAhead !== undefined && typeAhead !== null) {
        this._value = this.valueConverter.toModel(typeAhead);
        if (this._optionHighlight) {
          if (!this.registeredOptionHighlight) {
            this.registerHighlight(this._optionHighlight).subscribe();
            this.registeredOptionHighlight = true;
          }
          this._cdr.detectChanges();
        }
      }

      this._cdr.markForCheck();
    });
  }

  public get id(): string {
    return this._option != null ? this._option.id : '';
  }

  public get value(): T {
    return this._value;
  }

  public get selected(): boolean {
    return this._selected;
  }

  public set selected(selected: boolean) {
    this._selected = selected;

    if (this._option != null) {
      this._option.selected = selected;
    }
  }

  @HostBinding('class.p-maia-option--active')
  public get active(): boolean {
    return this._active;
  }

  public get disabled(): boolean | undefined {
    return this._option == null || this._option.disabled;
  }

  public set control(control: ModalControl<Option<T>, any>) {
    this._control = control;

    if (this._option != null) {
      this._option.control = control;
    }
  }

  public setActiveStyles(focusOrigin: FocusOrigin): void {
    this._active = true;

    if (this._option != null) {
      this._option.setActiveStyles(focusOrigin);
    }
  }

  public setInactiveStyles(): void {
    this._active = false;

    if (this._option != null) {
      this._option.setInactiveStyles();
    }
  }

  public get label(): string {
    return this.valueConverter.toLabel(this.value);
  }
}
