import {Directive, HostListener, Inject, OnInit, Optional} from '@angular/core';
import {NgControl} from '@angular/forms';
import {onDestroy, takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {InputContainer} from '@maia/forms';

import {BehaviorSubject, merge} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';

import {BaseInputSelectComponent} from './base-input-select.component';
import {InputSelectComponent} from './input-select.component';

/**
 * @ngModule InputSelectModule
 */
@Directive({
  selector:
    'maia-input-select[ngModel], maia-input-select[formControl], maia-input-select[formControlName]',
})
@UntilDestroy()
export class InputSelectWithControlDirective implements OnInit {
  private readonly _hasFocus = new BehaviorSubject(false);

  public constructor(
    @Inject(InputSelectComponent) private readonly _input: BaseInputSelectComponent<unknown>,
    private readonly _ngControl: NgControl,
    @Optional() private readonly _container?: InputContainer,
  ) {}

  // The maia-input-select element cannot receive focus, so listen
  // if one of it's child elements received focus using the focusout
  // and focusin events.
  @HostListener('focusout')
  public blur(): void {
    this._hasFocus.next(false);
  }

  @HostListener('focusin')
  public focus(): void {
    this._hasFocus.next(true);
  }

  public ngOnInit() {
    if (this._container != null) {
      const destroyFn = this._container.registerFormControl(this._ngControl);
      onDestroy(this).subscribe(destroyFn);

      merge(
        this._hasFocus.pipe(
          // If the dropdown is open, ignore blur events
          filter(hasFocus => hasFocus || !this._input.isOpen),
        ),
        // Re-emit whether we have focus when the dropdown closes
        this._input.afterClose.pipe(map(() => this._hasFocus.getValue())),
      )
        .pipe(distinctUntilChanged(), takeUntilDestroyed(this))
        .subscribe(hasFocus => {
          this._container!.focused = hasFocus;
        });
    }
  }
}
