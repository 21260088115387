import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {coerceDecimal} from '@atlas-angular/cdk/coercion';
import {Decimal, DecimalUtils} from '@atlas/businesstypes';

import {DisplaySize} from '../util';

/**
 * @ngModule DisplaysModule
 */
@Component({
  selector: 'maia-duration-display',
  templateUrl: './duration-display.component.html',
  styleUrls: [
    '../number-display/number-display.component.scss',
    './duration-display.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DurationDisplayComponent {
  /**
   * The display size
   */
  @Input()
  public size: DisplaySize | null;

  @Input()
  @coerceDecimal()
  public set years(value: Decimal) {
    this._years = value;
    this.yearsAsInteger = DecimalUtils.toFixed(value, 0);
  }

  public get years(): Decimal {
    return this._years;
  }

  private _years: Decimal;

  @Input()
  @coerceDecimal()
  public set months(value: Decimal) {
    this._months = value;
    this.monthsAsInteger = DecimalUtils.toFixed(value, 0);
  }

  public get months(): Decimal {
    return this._months;
  }

  private _months: Decimal;

  @Input()
  @coerceDecimal()
  public set weeks(value: Decimal) {
    this._weeks = value;
    this.weeksAsInteger = DecimalUtils.toFixed(value, 0);
  }

  public get weeks(): Decimal {
    return this._weeks;
  }

  private _weeks: Decimal;

  // variables containing the values formatted as integers (needed for the i18n plural ICU expressions)
  public yearsAsInteger: string;
  public monthsAsInteger: string;
  public weeksAsInteger: string;
}
