import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';
import {εFileUploaderSvgModule} from '@hermes/icons';
import {ButtonsModule} from '@maia/buttons';
import {ListsModule} from '@maia/lists';
import {PanelsModule} from '@maia/panels';
import {RichTextModule} from '@maia/rich-text';
import {SlideInsModule} from '@maia/slide-ins';
import {SpinnersModule} from '@maia/spinners';
import {TilesModule} from '@maia/tiles';

import {FileDropZoneAggregatorComponent} from './file-drop-zone/file-drop-zone-aggregator.component';
import {FileDropZoneComponent} from './file-drop-zone/file-drop-zone.component';
import {FileUploaderTileInfoComponent} from './file-uploader-tile/file-uploader-tile-info.component';
import {FileUploaderTileComponent} from './file-uploader-tile/file-uploader-tile.component';
import {FileUploader} from './file-uploader/file-uploader.component';
import {
  DIALOG_FILE_SELECTOR_SERVICE,
  DialogFileSelectorService,
} from './shared/dialog-file-selector.service';
import {FileDropDirective} from './shared/file-drop.directive';
import {
  FILE_UPLOADER_ANALYTICS_SERVICE,
  NoopFileUploaderAnalyticsService,
} from './shared/file-uploader-analytics.service';
import {PHOTO_SELECTOR_SERVICE, PhotoSelectorService} from './shared/photo-selector.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    {provide: DIALOG_FILE_SELECTOR_SERVICE, useClass: DialogFileSelectorService},
    {provide: PHOTO_SELECTOR_SERVICE, useClass: PhotoSelectorService},
  ],
  declarations: [FileDropDirective],
  exports: [FileDropDirective],
})
export class εSharedFileUploaderModule {}

@NgModule({
  imports: [
    BusinessTypesModule,
    ButtonsModule,
    CommonModule,
    ListsModule,
    PanelsModule,
    RichTextModule,
    SlideInsModule,
    SpinnersModule,
    TilesModule,
    εFileUploaderSvgModule,
    εSharedFileUploaderModule,
  ],
  providers: [
    {provide: FILE_UPLOADER_ANALYTICS_SERVICE, useClass: NoopFileUploaderAnalyticsService},
  ],
  declarations: [
    FileDropZoneAggregatorComponent,
    FileDropZoneComponent,
    FileUploader,
    FileUploaderTileComponent,
    FileUploaderTileInfoComponent,
  ],
  entryComponents: [FileUploader],
  exports: [FileUploaderTileComponent, FileUploaderTileInfoComponent, FileDropDirective],
})
export class FileUploaderModule {}
