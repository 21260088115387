import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

/**
 * This is a layout component with a left and a right section.
 * Neither section has a fixed width, the width and margins depend on the content inside.
 * The two sections can either be top or center aligned.
 *
 * @ngModule LayoutModule
 */
@Component({
  selector: 'maia-fluid-fluid-layout',
  template: '<ng-content></ng-content>',
  styleUrls: ['./fluid-fluid-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FluidFluidLayoutComponent {
  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('class.maia-fluid-fluid-layout--align-top')
  public alignContentTop = true;

  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('class.maia-fluid-fluid-layout--align-center')
  public alignContentCenter = false;
}
