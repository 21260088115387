/**
 * Randomizes a string by appending a random string.
 *
 * There is absolutely no guarantee that the returned string is unique. Do not use this function to
 * generate unique strings. It should be used to create unpredictable values, e.g. to prevent
 * outside styles from overriding a class that you are trying to define/use.
 *
 * @param value The string to randomize
 */
export function randomize(value: string): string {
  const randomNumber = `${Math.random()}`.substring(2); // cut off `0.`
  const randomSuffix = btoa(randomNumber).slice(0, 5).toLowerCase();

  return `${value}-${randomSuffix}`;
}
