import {Directive, Input, OnInit} from '@angular/core';

import {FormatBasedValueFormatter} from '../util/value-formatter.util';

import {MaskedInputComponent} from './masked-input.component';

/**
 * Allows custom input masks. Together with atlas's `[atlasText]` directive this makes the masked
 * input return Text businesstypes.
 *
 * Note that all inputs to this directive are frozen after initialization. Changing them at runtime
 * won't do anything.
 *
 * @ngModule InputMasksModule
 */
@Directive({
  selector: 'maia-masked-input[atlasText]',
})
export class MaskedTextInputDirective implements OnInit {
  private _placeholder: string;

  /**
   * The input mask, where `#` represents an input character and the rest is considered a static
   * part of the mask.
   */
  @Input()
  public mask = '';

  /**
   * The placeholder to show for empty characters in the mask. This placeholder must match the mask,
   * that is: for a mask `##/##/##` a valid placeholder would be `XX/YY/ZZ` but not `XXYYZZ`.
   *
   * This defaults to the mask where every `#` character is replaced with a dot.
   */
  @Input()
  public set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.setInputMaskAndPlaceholder();
  }

  public get placeholder(): string {
    return this._placeholder;
  }

  /**
   * The format of the value, where `#` represents an input character and the rest is considered a
   * static part of the format. This value must contain the same number of `#` characters as the
   * mask.
   *
   * This defaults to no formatting.
   */
  @Input()
  public valueFormat?: string = undefined;

  public constructor(private readonly _input: MaskedInputComponent) {}

  public ngOnInit(): void {
    this.setInputMaskAndPlaceholder();
  }

  private setInputMaskAndPlaceholder() {
    this._input.setMaskAndPlaceholder(this.mask, this.placeholder);

    if (this.valueFormat != null) {
      this._input.setValueFormatter(new FormatBasedValueFormatter(this.mask, this.valueFormat));
    }
  }
}
