import {Formatter} from '../base/formatter';

import {EmailAddress} from './businesstype';

export const EmailAddressFormatter: Formatter<EmailAddress> = {
  /**
   * Returns the string value of the email address. No formatting is done,
   * as EmailAddress are not structured.
   *
   * @param value The EmailAddress to "format"
   */
  format(value: EmailAddress): string {
    return value.asString();
  },

  /**
   * Simply returns the variable, as EmailAddress are not formatted.
   *
   * @param formattedValue The string to "unformat"
   */
  unformat(formattedValue: string): string {
    return formattedValue;
  },
};
