/*
 * This file contains the extra date validators, that is:
 * the validators used to check extra conditions on valid date values.
 *
 * This differs from the validator-base file, which contains validators
 * that provide the basic date validation: "is it a valid date?".
 */

import {getInternalValue} from '../base/businesstype';
import {
  InvalidLimitResult,
  RESULT_VALID,
  ValidationErrors,
  Validator,
  ValidatorInput,
  ValidValidationResult,
} from '../base/validators';

import {Date} from './businesstype';
import {DateUtils} from './date-utils';
import {isValidDate} from './validator-base';

/**
 * Validation errors object when the date lies after a limit
 */
export interface AtlasAfterValidationErrors extends ValidationErrors {
  atlasAfter: InvalidLimitResult<string>;
}

/**
 * Validation errors object when the date lies before a limit
 */
export interface AtlasBeforeValidationErrors extends ValidationErrors {
  atlasBefore: InvalidLimitResult<string>;
}

function ensureDate(value: string | Date) {
  return Date.isDate(value) ? value : new Date(value);
}

function formatDate(value: Date) {
  // Dates actually store their value as a formatted string rather than a date value
  return getInternalValue(value);
}

function createInvalidLimitResult(actualValue: Date, limit: Date): InvalidLimitResult<string> {
  return {
    actualValue: formatDate(actualValue),
    limit: formatDate(limit),
  };
}

export const ExtraDateValidators = {
  /**
   * Creates a validator for checking that input values lie before the given limit.
   *
   * The validator only takes valid date inputs into account. If the date input
   * defines a date that doesn't lie before the given limit, the validator returns
   * a result invalid.
   *
   * @param limit The limit to create a validator for
   * @return The validator
   */
  before(limit: string | Date): Validator<Date, AtlasBeforeValidationErrors> {
    const limitDate = ensureDate(limit);

    return function(
      input: ValidatorInput<Date>,
    ): ValidValidationResult | AtlasBeforeValidationErrors {
      if (!input || !isValidDate(input)) {
        // Input is invalid, ignore
        return RESULT_VALID;
      }

      const inputDate = ensureDate(input);

      if (DateUtils.isBefore(inputDate, limitDate)) {
        return RESULT_VALID;
      }

      return {
        atlasBefore: createInvalidLimitResult(inputDate, limitDate),
      };
    };
  },

  /**
   * Creates a validator for checking that input values come after the given limit.
   *
   * The validator only takes valid date inputs into account. If the date input
   * defines a date that doesn't lie before the given limit, the validator returns
   * a result invalid.
   *
   * @param limit The limit to create a validator for
   * @return The validator
   */
  after(limit: string | Date): Validator<Date, AtlasAfterValidationErrors> {
    const limitDate = ensureDate(limit);

    return function(
      input: ValidatorInput<Date>,
    ): ValidValidationResult | AtlasAfterValidationErrors {
      if (!input || !isValidDate(input)) {
        // Input is invalid, ignore
        return RESULT_VALID;
      }

      const inputDate = ensureDate(input);

      if (DateUtils.isAfter(inputDate, limitDate)) {
        return RESULT_VALID;
      }

      return {
        atlasAfter: createInvalidLimitResult(inputDate, limitDate),
      };
    };
  },
} as const;
