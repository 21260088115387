import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {ChangeDetectionStrategy, Component, HostBinding, Input, Optional} from '@angular/core';
import {TileComponent} from '../tile/tile.component';

/**
 * Tile items component
 *
 * @ngModule TilesModule
 */
@Component({
  selector: 'maia-tile-items',
  template: '<ng-content></ng-content>',
  styleUrls: ['./tile-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileItemsComponent {
  private _horizontal = false;

  public constructor(@Optional() parent?: TileComponent) {
    if (parent === null) {
      throw new Error(
        'Cannot create a <maia-tile-items /> component out of <maia-tile /> component',
      );
    }
  }

  /**
   * The tile orientation.
   */
  @Input()
  @HostBinding('class.p-maia-tile-items--horizontal')
  @HostBinding('class.p-maia-divider--vertical')
  public get horizontal(): boolean {
    return this._horizontal;
  }

  public set horizontal(horizontal: boolean) {
    this._horizontal = coerceBooleanProperty(horizontal);
  }
}
