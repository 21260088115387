import {NgModule} from '@angular/core';

import {CaptureInputDirective} from './input/capture-input.directive';
import {OptionsInputContainerComponent} from './input/options-input-container.component';

/**
 * Internal module with helper components needed to build autocomplete components. This module is
 * not supposed to be exported publically.
 */
@NgModule({
  declarations: [CaptureInputDirective, OptionsInputContainerComponent],
  exports: [CaptureInputDirective, OptionsInputContainerComponent],
})
export class InputAutocompletePartsModule {}
