import {HttpRequest} from '@angular/common/http';
import {Inject, Injectable, Optional, SkipSelf} from '@angular/core';
import {Call} from '@atlas/convertor';
import {from, of, concat, Observable, EMPTY, throwError} from 'rxjs';
import {filter, first, map, mergeMap, switchMap, throwIfEmpty, toArray} from 'rxjs/operators';

import {PathResolver} from '../interfaces/path-resolver';
import {PATH_RESOLVER_TOKEN} from '../tokens/path-resolver';

@Injectable()
export class CombinedPathResolver implements PathResolver {
  private readonly _resolvers: PathResolver[];

  public constructor(
    @Inject(PATH_RESOLVER_TOKEN) @Optional() resolvers?: PathResolver[],
    @Optional() @SkipSelf() private readonly parent?: CombinedPathResolver,
  ) {
    this._resolvers = resolvers != null ? resolvers.slice() : [];
  }

  public resolve(call: Call<any, any>, input: unknown): Observable<HttpRequest<null>> {
    let resolution = from(this._resolvers).pipe(
      mergeMap(resolver => {
        const request = resolver.resolve(call, input);

        if (request == null || request instanceof HttpRequest) {
          return of([request, resolver] as const);
        }

        return from(request).pipe(map(request => [request, resolver] as const));
      }),
      filter((request): request is [HttpRequest<null>, PathResolver] => request[0] != null),
      toArray(),
      switchMap(requests => {
        switch (requests.length) {
          case 0:
            return EMPTY;
          case 1:
            return of(requests[0][0]);
          default:
            return throwError(
              new Error(
                `More than one registered pathResolver provided a value for call with identifier ${JSON.stringify(
                  call.identifier,
                )}: ${requests.map(([, resolver]) => resolver.constructor.name).join(', ')}`,
              ),
            );
        }
      }),
    );

    if (this.parent != null) {
      resolution = concat(resolution, this.parent.resolve(call, input)).pipe(first());
    }

    return resolution.pipe(
      throwIfEmpty(
        () =>
          new Error(
            `No registered pathResolver provided a value for call with identifier ${JSON.stringify(
              call.identifier,
            )}`,
          ),
      ),
    );
  }
}
