import {ChangeDetectionStrategy, Component, HostBinding, Input, NgModule, ViewEncapsulation} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

/**
 * @ngModule NavigationFuncArrowSvgModule
 */
@Component({
  selector: 'svg[kdlBifrostNavigationFuncArrow]',
  template: '<svg:g class="nc-icon-wrapper" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"><svg:path data-cap="butt" data-color="color-2" fill="none" stroke-miterlimit="10" d="M4 12h16" stroke-width="1.04"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M14.4 6.4L20 12l-5.6 5.6" stroke-width="1.04"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
    
    ':host(.maia-icon--down) {transform: rotate(90deg);} :host(.maia-icon--left) {transform: rotate(180deg);} :host(.maia-icon--up) {transform: rotate(-90deg);}',
  ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationFuncArrowSvgComponent {
  @Input()
  @HostBinding('class.maia-icon--right')
  @coerceBooleanPrimitive()
  public right = true;

  @Input()
  @HostBinding('class.maia-icon--down')
  @coerceBooleanPrimitive()
  public down = false;

  @Input()
  @HostBinding('class.maia-icon--left')
  @coerceBooleanPrimitive()
  public left = false;

  @Input()
  @HostBinding('class.maia-icon--up')
  @coerceBooleanPrimitive()
  public up = false;
}

@NgModule({
  declarations: [
    NavigationFuncArrowSvgComponent,
  ],
  exports: [
    NavigationFuncArrowSvgComponent,
  ],
})
export class NavigationFuncArrowSvgModule {}
