/*
 * This file contains the basic email address validators
 * to check whether an email address is actually a valid address.
 */

import {
  AtlasInvalidCharactersValidationErrors,
  isValid,
  RESULT_VALID,
  ValidationErrors,
  ValidatorInput,
  ValidValidationResult,
} from '../base/validators';

import {EmailAddress} from './businesstype';

// https://en.wikipedia.org/wiki/Email_address
const LOCAL_PART_REGEX = /^[a-zA-Z0-9](\.?[a-zA-Z0-9!$%#&_'*+/=?^`{|}~-])*$/;
const PROVIDER_REGEX = /^[a-zA-Z0-9-]*$/;
const TOP_LEVEL_REGEX = /^[a-zA-Z]*$/;
const DOMAIN_MAX_LENGTH = 255;
const TOP_LEVEL_MIN_LENGTH = 2;
const DOMAIN_LABEL_MIN_LENGTH = 1;
const DOMAIN_LABEL_MAX_LENGTH = 63;
const LOCAL_PART_MAX_LENGTH = 64;
const LOCAL_PART_MIN_LENGTH = 1;

/**
 * Validation errors object if the email input contains more than one `@` symbol.
 */
export interface AtlasMultipleAtValidationErrors extends ValidationErrors {
  atlasInvalidMultipleAt: true;
}

/**
 * Validation errors object if the email input contains an invalid part before the `@`.
 */
export interface AtlasInvalidLocalPartValidationErrors extends ValidationErrors {
  atlasInvalidLocalPart: true;
}

/**
 * Validation errors object if the email input contains an invalid part after the `@`.
 */
export interface AtlasInvalidDomainValidationErrors extends ValidationErrors {
  atlasInvalidDomain: true;
}

/**
 * Checks if there is only one @ instance on the email address
 *
 * @param value
 */
function invalidMultipleAt(
  value: ValidatorInput<EmailAddress>,
): AtlasMultipleAtValidationErrors | ValidValidationResult {
  if (EmailAddress.isEmailAddress(value)) {
    return RESULT_VALID;
  }

  if (value) {
    return validateMultipleAt(value) ? RESULT_VALID : {atlasInvalidMultipleAt: true};
  }

  return RESULT_VALID;
}

/**
 * Checks if the structure of the introduced email is correct
 *
 * @param value The email address input to validate
 */
function invalidEmail(
  value: ValidatorInput<EmailAddress>,
): AtlasInvalidLocalPartValidationErrors | ValidValidationResult {
  if (EmailAddress.isEmailAddress(value)) {
    return RESULT_VALID;
  }

  // Do not trigger email structure validation until multipleAt and characters on local part
  // are validated
  if (!value || validateMultipleAt(value)) {
    return value && validateLocalPart(value) ? RESULT_VALID : {atlasInvalidLocalPart: true};
  } else {
    return RESULT_VALID;
  }
}

/**
 * Checks if there are invalid characters on the local part of the email address
 *
 * @param value
 */
function invalidCharacters(
  value: ValidatorInput<EmailAddress>,
): AtlasInvalidCharactersValidationErrors | ValidValidationResult {
  if (EmailAddress.isEmailAddress(value)) {
    return RESULT_VALID;
  }

  // Do not trigger characters check until multipleAt is validated
  if (value && validateMultipleAt(value) && validateLocalPart(value)) {
    return validateCharacters(value) ? RESULT_VALID : {atlasInvalidCharacters: true};
  } else {
    return RESULT_VALID;
  }
}

/**
 * Checks if the domain of the email address has correct characters and structure
 *
 * @param value
 */
function invalidDomain(
  value: ValidatorInput<EmailAddress>,
): AtlasInvalidDomainValidationErrors | ValidValidationResult {
  if (EmailAddress.isEmailAddress(value)) {
    return RESULT_VALID;
  }
  // Do not trigger domain until multipleAt, characters on local part and structure are
  // validated
  if (value && validateMultipleAt(value) && validateLocalPart(value) && validateCharacters(value)) {
    return validateDomain(value) ? RESULT_VALID : {atlasInvalidDomain: true};
  } else {
    return RESULT_VALID;
  }
}

/**
 * The BaseEmailAddressValidators object contains partial email address validators,
 * returning information on the invalidity instead of simply returning a boolean
 * valid/invalid result.
 *
 * @deprecated Use `validateEmailAddress` instead
 */
export const BaseEmailAddressValidators: {
  /**
   * Checks if there is only one @ instance on the email address
   *
   * @param value
   */
  readonly invalidMultipleAt: (
    value: ValidatorInput<EmailAddress>,
  ) => AtlasMultipleAtValidationErrors | ValidValidationResult;
  /**
   * Checks if the structure of the introduced email is correct
   *
   * @param value The email address input to validate
   */
  readonly invalidEmail: (
    value: ValidatorInput<EmailAddress>,
  ) => AtlasInvalidLocalPartValidationErrors | ValidValidationResult;
  /**
   * Checks if there are invalid characters on the local part of the email address
   *
   * @param value
   */
  readonly invalidCharacters: (
    value: ValidatorInput<EmailAddress>,
  ) => AtlasInvalidCharactersValidationErrors | ValidValidationResult;
  /**
   * Checks if the domain of the email address has correct characters and structure
   *
   * @param value
   */
  readonly invalidDomain: (
    value: ValidatorInput<EmailAddress>,
  ) => AtlasInvalidDomainValidationErrors | ValidValidationResult;
} = {
  invalidMultipleAt,
  invalidEmail,
  invalidCharacters,
  invalidDomain,
};

function validateMultipleAt(input: string): boolean {
  return input.indexOf('@') === input.lastIndexOf('@');
}

function validateLocalPart(input: string): boolean {
  // Does the email address has an '@' ? and local part length isn't zero
  const atIdx = input.indexOf('@');
  return (
    atIdx !== -1 &&
    atIdx !== 0 &&
    isInBetween(input.substring(0, atIdx).length, LOCAL_PART_MIN_LENGTH, LOCAL_PART_MAX_LENGTH)
  );
}

function validateCharacters(input: string): boolean {
  const atIdx = input.indexOf('@');
  const localPart = input.substring(0, atIdx);
  return LOCAL_PART_REGEX.test(localPart);
}

function isInBetween(value: number, min: number, max: number): boolean {
  return value >= min && value <= max;
}

function validateDomain(input: string): boolean {
  const domain = input.substring(input.indexOf('@') + 1);
  const tldIndex = domain.lastIndexOf('.');
  // Check existing domain and characters validation
  if (tldIndex === -1) {
    return false;
  }
  if (domain.length > DOMAIN_MAX_LENGTH) {
    return false;
  }
  const domainLabels = domain.split('.');
  if (
    !domainLabels.every(
      domainLabel =>
        PROVIDER_REGEX.test(domainLabel) &&
        isInBetween(domainLabel.length, DOMAIN_LABEL_MIN_LENGTH, DOMAIN_LABEL_MAX_LENGTH),
    )
  ) {
    return false;
  }
  // Check top level validation for last part of domain
  const topLevel = domain.substring(tldIndex + 1);
  return (
    TOP_LEVEL_REGEX.test(topLevel) &&
    isInBetween(topLevel.length, TOP_LEVEL_MIN_LENGTH, DOMAIN_LABEL_MAX_LENGTH)
  );
}

/**
 * Checks whether the email address input is valid or not.
 *
 * @param input The email address input to validate
 */
export function isValidEmailAddress(input: ValidatorInput<EmailAddress>): boolean {
  return isValid(validateEmailAddress(input));
}

/**
 * Validate the given email address input
 *
 * @param input The email address to validate
 */
export function validateEmailAddress(
  input: ValidatorInput<EmailAddress>,
): ValidationErrors | ValidValidationResult {
  if (EmailAddress.isEmailAddress(input)) {
    return RESULT_VALID;
  }

  return (
    invalidMultipleAt(input) ??
    invalidEmail(input) ??
    invalidCharacters(input) ??
    invalidDomain(input)
  );
}
