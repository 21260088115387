import {Formatter} from '../base/formatter';

import {Vat} from './businesstype';

export const VatFormatter: Formatter<Vat> = {
  /**
   * Returns the string value of the vat. without format
   *
   * @param value The vat to format
   */
  format(value: Vat): string {
    return value.asString();
  },

  /**
   * Removes the original string.
   *
   * @param vatString The string to unformat
   */
  unformat(vatString: string): string {
    return vatString;
  },
};
