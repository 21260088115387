/**
 * All valid values and css classes for the `width` of TileComponents.
 */
export type TileWidth = 'auto' | 'regular' | 'large' | 'beam';

/**
 * All valid values and css classes for the `height` of TileComponents.
 */
export type TileHeight = 'auto' | 'small' | 'regular' | 'large' | 'beam';

/**
 * Default value for `width` of TileComponents.
 */
export const TILE_WIDTH_DEFAULT: TileWidth = 'auto';

/**
 * Default value for `height` of TileComponents.
 */
export const TILE_HEIGHT_DEFAULT: TileHeight = 'auto';

/**
 * Default value for `disabled` of TileComponents.
 */
export const TILE_DISABLED_DEFAULT = false;

/**
 * Default value for `highlightOnFocus` of TileComponents.
 */
export const TILE_HIGLIGHT_ON_FOCUS = false;

/**
 * Default value for `highlightOnHover` of TileComponents.
 */
export const TILE_HIGLIGHT_ON_HOVER = false;

/**
 * Default value for `highlightForced` of TileComponents.
 */
export const TILE_HIGHLIGHT_FORCED = false;

/**
 * Default value for `disableShadows` of TileComponents.
 */
export const TILE_DISABLE_SHADOWS = false;

/**
 * Default value for `selected` of TileComponents.
 */
export const TILE_SELECTED_DEFAULT = false;

export const TILE_CLASSES = {
  width: {
    auto: null,
    regular: 'maia-tile--width-regular',
    large: 'maia-tile--width-large',
    beam: 'maia-tile--width-beam',
  } as {[key in TileWidth]: string | null},
  height: {
    auto: null,
    small: 'maia-tile--height-small',
    regular: 'maia-tile--height-regular',
    large: 'maia-tile--height-large',
    beam: 'maia-tile--height-beam',
  } as {[key in TileHeight]: string | null},
};
