import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';

import {AvatarComponent} from '../abstract-avatar.component';

const AVATAR_SVG_DECIMAL_PERCENTAGE = 0.6;
const AVATAR_SVG_THRESHOLD_EXCEEDED_DECIMAL_PERCENTAGE = 0.7;

@Component({
  selector: 'maia-avatar-svg',
  templateUrl: './avatar-svg.component.html',
  styleUrls: ['./avatar-svg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: AvatarComponent,
      useExisting: forwardRef(() => AvatarSvgComponent),
    },
  ],
})
export class AvatarSvgComponent extends AvatarComponent {
  public iconSize(): number {
    return this.isThresholdExceeded
      ? this.avatarSize * AVATAR_SVG_THRESHOLD_EXCEEDED_DECIMAL_PERCENTAGE
      : this.avatarSize * AVATAR_SVG_DECIMAL_PERCENTAGE;
  }
}
