import {Injectable, Injector, Type} from '@angular/core';
import {ApplicationElementRef} from '@atlas-angular/cdk/globals';

@Injectable({providedIn: 'root'})
export class EagerInitialiser {
  public constructor(
    private readonly applicationElementRef: ApplicationElementRef,
    private readonly injector: Injector,
  ) {}

  public async instantiate<T>(ctor: Type<T>, injector = this.injector): Promise<T> {
    await this.applicationElementRef.nativeElement;

    return injector.get(ctor);
  }

  public async run(func: () => void): Promise<void> {
    await this.applicationElementRef.nativeElement;

    func();
  }
}
