import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

import {ViewportService, ViewportName} from '@maia/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

// keep in sync with CSS
const PADDING_SMALL = 16;
const PADDING_LARGE = 24;

/**
 * Panel Component
 *
 * @ngModule PanelsModule
 */
@Component({
  selector: 'maia-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelComponent {
  /**
   * Exposes the horizontal padding on this element
   */
  public readonly horizontalPadding$: Observable<number>;

  @Input()
  @HostBinding('class.maia-tile--with-close-button')
  @coerceBooleanPrimitive()
  public withCloseButton = false;

  /**
   * Emits when the close button is clicked
   */
  @Output()
  public readonly closePanel = new EventEmitter<void>();

  public constructor(viewport: ViewportService) {
    this.horizontalPadding$ = viewport
      .isAtLeast$(ViewportName.MEDIUM)
      .pipe(map(isLarge => (isLarge ? PADDING_LARGE : PADDING_SMALL)));
  }
}
