import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {CssClassUtility, CssClassUtilityFactory, ViewportName, ViewportService} from '@maia/core';
import {startWith} from 'rxjs/operators';

/**
 * The header layout has two themes : dark/light
 * Default the theme is dark.
 * The light theme should be used in headers with a dark background.
 */
export enum HeaderLayoutTheme {
  dark = 'dark',
  default = 'default',
  light = 'light',
}

const CLASSES = {
  theme: {
    dark: 'p-maia-header-layout--dark',
    default: null,
    light: 'p-maia-header-layout--light',
  } as {[key in HeaderLayoutTheme]: string | null},
};

/**
 * For most of the applications types the header has the same layout.
 *
 *
 * @ngModule HeaderLayoutModule
 */
@Component({
  selector: 'maia-header-layout',
  templateUrl: './header-layout.component.html',
  styleUrls: ['./header-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class HeaderLayoutComponent implements OnInit {
  private _isViewportAtLeastBreakpoint = false;
  private _theme: HeaderLayoutTheme = HeaderLayoutTheme.dark;
  private readonly _cssClassUtility: CssClassUtility<typeof CLASSES>;
  private readonly _viewportLogoBreakpoint: ViewportName;

  /**
   * We collect the content buttons passed through by the consumer to be placed at the right side.
   * This way we can determinate if the buttons on the left side need more spacing.
   */
  @ViewChild('buttonsRightContainer', {static: true})
  public buttonsRight: ElementRef<HTMLDivElement>;

  /**
   * To determinate if a svg logo was passed through, this has influence regarding the styling.
   */
  @ViewChild('logoContainer', {static: true})
  public logoContainer: ElementRef<HTMLSpanElement>;

  /**
   * Public processes want to show the logo on each process.
   * Due to the fact that most processes aren't public, we set the default value false.
   */
  @coerceBooleanPrimitive()
  @Input()
  public alwaysShowLogo = false;

  /**
   * Public processes want to show the logo on each process.
   * Due to the fact that most processes aren't public, we set the default value false.
   */

  /**
   * returns the active theme
   */
  public get theme(): HeaderLayoutTheme {
    return this._theme;
  }

  /**
   * We allow the consumer to set a light theme when required.
   * This is normally only the case for headers with a dark background.
   */
  @Input()
  public set theme(newTheme: HeaderLayoutTheme) {
    if (newTheme === this._theme) {
      return;
    }

    this._theme = newTheme;
    this._cssClassUtility.setValue('theme', newTheme);
  }

  /**
   * @deprecated set theme instead
   */
  @Input()
  public set maiaHeaderLayoutTheme(theme: HeaderLayoutTheme) {
    this.theme = theme;
  }

  /**
   * We provide a modifier class to the header to identify there are at least two buttons present.
   */
  @HostBinding('class.p-maia-header-layout--with-two-buttons-right')
  public get withTwoButtonsRight(): boolean {
    return this.buttonsRight.nativeElement.childElementCount > 1;
  }

  /**
   * We provide a midifier class to the header to identify a logo is provided by the consumer and if it needs to be rendered.
   */
  @HostBinding('class.p-maia-header-layout--with-logo')
  public get withLogo(): boolean {
    return (
      !!this.logoContainer.nativeElement.childElementCount &&
      (this.alwaysShowLogo || this._isViewportAtLeastBreakpoint)
    );
  }

  /**
   * We make use of the css class utility for setting the active theme.
   */
  public constructor(
    private readonly viewport: ViewportService,
    private readonly cdr: ChangeDetectorRef,
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(CLASSES, renderer, elementRef);
    this._viewportLogoBreakpoint = ViewportName.EXTRA_LARGE;
  }

  public ngOnInit(): void {
    this.viewport
      .isAtLeast$(this._viewportLogoBreakpoint)
      .pipe(
        takeUntilDestroyed(this),
        startWith(this.viewport.isAtLeast(this._viewportLogoBreakpoint)),
      )
      .subscribe(isAtLeastXl => {
        this._isViewportAtLeastBreakpoint = isAtLeastXl;
        this.cdr.markForCheck();
      });
  }
}
