import {ChangeDetectionStrategy, Component, HostBinding, Input, Optional} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {FormElementComponent} from '@maia/forms';

/**
 * @ngModule SwitchesModule
 */
@Component({
  selector: 'maia-switch-group',
  template: '<ng-content></ng-content>',
  styleUrls: ['./switch-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchGroupComponent {
  @coerceBooleanPrimitive()
  @Input()
  @HostBinding('class.maia-switch-group--horizontal')
  @HostBinding('class.p-maia-switch-group--horizontal')
  public horizontal = false;

  public constructor(@Optional() parent?: FormElementComponent) {
    if (parent != null) {
      parent.extraPadding = true;
      parent.hideOptionalIndicator = true;
    }
  }
}
