import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SubNavigationToggleSignerScanOSvgModule
 */
@Component({
  selector: 'svg[ivyHermesSubNavigationToggleSignerScanO]',
  template: '<svg:g><svg:g><svg:path d="M-261.8 584.5h-76.7V339h76.7v245.5zm-67.7-9h58.7V348.1h-58.7v227.4z"></svg:path><svg:path d="M-363.8 584.5h-76.7V339h76.7v245.5zm-67.7-9h58.7V348.1h-58.7v227.4z"></svg:path><svg:path d="M-160.7 584.5h-76.7V339h76.7v245.5zm-67.7-9h58.7V348.1h-58.7v227.4z"></svg:path><svg:path d="M-58.7 584.5h-76.7V339h76.7v245.5zm-67.7-9h58.7V348.1h-58.7v227.4z"></svg:path><svg:path d="M42.4 584.5h-76.7V339h76.7v245.5zm-67.7-9h58.7V348.1h-58.7v227.4z"></svg:path><svg:path d="M-401.7 306.6l-52.9-92.1h105.9l-53 92.1zm-37.3-83.1l37.3 65 37.3-65H-439z"></svg:path><svg:path d="M4.5 306.6l-52.9-92.1H57.4L4.5 306.6zm-37.4-83.1l37.3 65 37.3-65h-74.6z"></svg:path></svg:g></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '-454.6 143.5 512 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubNavigationToggleSignerScanOSvgComponent {
}

@NgModule({
  declarations: [
    SubNavigationToggleSignerScanOSvgComponent,
  ],
  exports: [
    SubNavigationToggleSignerScanOSvgComponent,
  ],
})
export class SubNavigationToggleSignerScanOSvgModule {}
