import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule GeneralInfoSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaGeneralInfo]',
  template: '<svg:path d="M-5 513c-141.1 0-256-114.9-256-256S-146.1 1-5 1s256 114.9 256 256S136.1 513-5 513zm0-480.5c-123.5 0-223.8 100.8-223.8 224.5S-128.5 481.5-5 481.5 218.8 380.7 218.8 257 118.5 32.5-5 32.5z"></svg:path><svg:path d="M50.9 337.7v22.1c0 3.1-1 5.5-3.3 7.8s-4.9 3.3-7.8 3.3h-89.3c-3.1 0-5.7-1-7.8-3.3-2.3-2.3-3.3-4.7-3.3-7.8v-22.1c0-3.1 1-5.5 3.3-7.8s4.9-3.3 7.8-3.3h11.3v-66.2h-11.3c-3.1 0-5.7-1-7.8-3.3-2.3-2.3-3.3-4.7-3.3-7.8v-22.1c0-3.1 1-5.5 3.3-7.8s4.9-3.3 7.8-3.3h67c3.1 0 5.7 1 7.8 3.3 2.3 2.3 3.3 4.7 3.3 7.8v99.3h11.3c3.1 0 5.7 1 7.8 3.3 2 2.4 3.2 4.8 3.2 7.9zM28.6 139.2v33c0 3.1-1 5.5-3.3 7.8s-4.9 3.3-7.8 3.3h-44.6c-3.1 0-5.7-1-7.8-3.3-2.3-2.3-3.3-4.7-3.3-7.8v-33c0-3.1 1-5.5 3.3-7.8s4.9-3.3 7.8-3.3h44.6c3.1 0 5.7 1 7.8 3.3s3.3 5 3.3 7.8z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '-261 1 512 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralInfoSvgComponent {
}

@NgModule({
  declarations: [
    GeneralInfoSvgComponent,
  ],
  exports: [
    GeneralInfoSvgComponent,
  ],
})
export class GeneralInfoSvgModule {}
