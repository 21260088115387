import {BusinessType, αgetEncryptedValue, αgetInternalValue} from '@atlas/businesstypes';

const matcher = /{(.*?)}/g;

export interface ContextType {
  [key: string]: BusinessType<any>;
}

export interface StringContextType {
  [key: string]: string;
}

/**
 * Converts a url template string into a URL object based on the passed context
 *
 * Variables in the template should be surrounded with single curly brackets (`{}`) and should be
 * valid javascript property names.
 * The context should be a key-value object where all values are businesstype objects. Nesting is
 * not allowed in the context object. If the businesstype contains an encrypted value, that value
 * will be used as replacement, otherwise the plain value will be used.
 * The values of the businesstypes will be URI escaped
 *
 * Example
 *
 * ```typescript
 * resolve('https://www.{test}.be', {test: new Text('kbc')});
 * // returns URL object with 'https://www.kbc.be'
 * ```
 *
 * @param urlTemplate the string containing the template
 * @param context the context to be used to resolve variables in the template
 */
export function resolveUrl(urlTemplate: string, context: ContextType): URL {
  const stringContext = convertBusinesstypesToString(context);
  const url = replace(urlTemplate, stringContext);
  return new URL(url);
}

export function replace(template: string, context: StringContextType): string {
  return template.replace(matcher, (match, name) => {
    if (context.hasOwnProperty(name)) {
      return encodeURIComponent(context[name]);
    }
    throw new Error(`Property '${name}' does not exist in context.`);
  });
}

export function convertBusinesstypesToString(context: ContextType): StringContextType {
  return Object.keys(context).reduce((previous, key: string) => {
    const value = context[key];
    if (!(value instanceof BusinessType)) {
      throw new Error(
        `Unexpected type '${typeof value}' for field '${key}'. Only Businesstypes are supported.`,
      );
    }
    const encrypted = αgetEncryptedValue(value);
    if (encrypted != null) {
      previous[key] = encrypted;
    } else {
      previous[key] = `${αgetInternalValue(value)}`;
    }
    return previous;
  }, {} as StringContextType);
}
