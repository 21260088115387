import {HttpXsrfTokenExtractor} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BusinessType, αgetEncryptedValue, αgetInternalValue} from '@atlas/businesstypes';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {DynamicResource} from '../interfaces/resource-resolver';

import {CombinedDynamicResourceUrlResolver} from './combined-dynamic-resource-url-resolver.service';

const XSRF_PARAM = 'xsrfToken';

/**
 * Allow hidden or string values as query parameters.
 */
export interface QueryParamMap {
  [key: string]: BusinessType<any>;
}

/**
 * Provider responsible for preparing a dynamic resource url observable. Uses dynamic resource url
 * resolvers to resolve the url to the resource. Extra query parameters can be
 * passed via a query map.
 */
@Injectable()
export class DynamicResourceUrlGenerator {
  public constructor(
    private readonly resolver: CombinedDynamicResourceUrlResolver,
    private readonly tokenService: HttpXsrfTokenExtractor,
  ) {}

  /**
   * Prepares a dynamic resource.
   * Note that when executing the url, the response should have a "Content-Disposition: attachment".
   * @param resource
   * @param query
   */
  public prepare(resource: DynamicResource, query?: QueryParamMap): Observable<URL> {
    return this.resolver.resolve(resource).pipe(map(url => this.appendQuery(resource, url, query)));
  }

  private appendQuery(
    {xsrfNeeded = true, xsrfQueryParamName = XSRF_PARAM}: DynamicResource,
    url: URL,
    query?: QueryParamMap,
  ): URL {
    if (query != null) {
      for (const key of Object.keys(query)) {
        url.searchParams.append(key, convert(query[key]));
      }
    }
    if (xsrfNeeded) {
      url.searchParams.append(xsrfQueryParamName, this.tokenService.getToken() || '');
    }
    return url;
  }
}

/**
 * Converts a business type to its internal value (or encrypted in case of a Hidden)
 * @param businessType
 */
function convert(businessType: BusinessType<any>): string {
  const convertedValue = αgetEncryptedValue(businessType);
  if (convertedValue != null) {
    return convertedValue;
  }
  return αgetInternalValue(businessType).toString();
}
