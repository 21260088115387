import {Call} from '@atlas/convertor';
import {MessagingService} from '../messaging.service';
import {Messenger} from '../messenger';
import {MessagingActionService} from '../messaging.action.service';
import {MessagingScopeService} from './messaging-scope.service';
import {Message} from '../message';

/**
 * A messaging agent handling the distribution of messages for a specific scope
 */
export class ScopedMessenger extends Messenger {
  public constructor(
    public readonly identifier: string,
    private readonly _messagingService: MessagingService,
    private readonly _messagingScopeService: MessagingScopeService,
    messagingActionService: MessagingActionService,
  ) {
    super(messagingActionService);
  }

  public add(message: Message) {
    if (this.messaging.observers.length) {
      super.add(message);
    } else {
      this._messagingService.add(message);
    }
  }

  /**
   * Assigns backend calls to this messaging scope
   * @param calls A variable number of backend calls to be assigned to this scope
   */
  public assign(...calls: Call<any, any>[]) {
    for (const call of calls) {
      this._messagingScopeService.assignCallToScope(call, this);
    }
  }
}
