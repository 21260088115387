import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Text} from '@atlas/businesstypes';
import {defer, EMPTY, Observable, of, throwError} from 'rxjs';
import {catchError, flatMap, tap} from 'rxjs/operators';

import {Reference} from '../file-uploader/file-uploader.call.factory';
import {
  DropZoneAwareFile,
  DropZoneAwareReference,
  FileUploaderService,
  isUploadValidationError,
  UPLOAD_VALIDATION_ERROR_ENUM,
  UploadValidationError,
} from '../file-uploader/file-uploader.service';
import {DragDropEvents, DragZoneEvent, DropZoneEvent} from '../shared/file-drop.directive';
import {
  FILE_UPLOADER_ANALYTICS_SERVICE,
  FileUploaderAnalyticsServiceInterface,
} from '../shared/file-uploader-analytics.service';
import {FileUploaderCallbackService} from '../shared/file-uploader-callback.service';
import {
  FILE_UPLOADER_SETTINGS,
  εFileUploaderSettingsStrict,
} from '../shared/file-uploader-settings';

@Component({
  selector: 'hermes-file-drop-zone',
  templateUrl: './file-drop-zone.component.html',
  styleUrls: ['./file-drop-zone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
/**
 * Displays how file dropzone should be displayed
 * acts as a bridge between FileDropZoneDirective and FileUploaderService
 *
 * @see {@link FileDropDirective}
 * @see {@link FileUploaderService}
 */
export class FileDropZoneComponent implements OnInit {
  private _optional = false;
  public uploadingFiles: File[] = [];
  public uploadedReferences: Reference[] = [];
  public error?: UploadValidationError;
  public uploadValidationErrorEnum = UPLOAD_VALIDATION_ERROR_ENUM;
  public allowDownload = this.settings.allowDownload;
  public maxNumberOfFiles = this.settings.maxNumberOfFiles;

  @Input()
  public id: string;

  @Input()
  public hasSiblingDropZones = false;

  /**
   * drop zone label (should be configured only on multidropzones)
   */
  @Input()
  public label = new Text('');

  /**
   * drop zone information text to be provided by the user via DropZoneConfiguration
   * should serve as additional information of the mimetype and max size of the files
   * to be dropped on the dropzone
   *
   * @see {@link DropZoneConfiguration}
   */
  @Input()
  public constraintText = '';

  @HostBinding('class.hermes-highlight')
  public highLightDropArea = false;

  @HostBinding('class.hermes-error')
  public hasError = false;

  @Input()
  public set optional(value: boolean) {
    this._optional = value;
  }

  public get optional(): boolean {
    return this._optional;
  }

  private addErrorMessage(error: UploadValidationError): void {
    this.hasError = true;
    this.error = error;
    if (this.fileUploaderAnalyticsService.trackError) {
      this.fileUploaderAnalyticsService.trackError(error);
    }
    this.changeDetectorRef.detectChanges();
  }

  private clearError() {
    this.hasError = false;
    this.error = undefined;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * pre validation before invoking uploading service
   * generally the validation should be done on uploaderService
   * but as long as several dropzones can share the same uploader service
   * we need to do dropzone validation in isolation
   */
  private validate(uploadingFiles: File[]): Observable<void> {
    return defer(() => {
      if (
        this.hasSiblingDropZones &&
        uploadingFiles.length + this.uploadedReferences.length + this.uploadingFiles.length > 1
      ) {
        return throwError(
          new UploadValidationError(UPLOAD_VALIDATION_ERROR_ENUM.MAX_NUMBER_OF_FILES_EXCEEDED),
        );
      }
      return of(undefined);
    });
  }

  public constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly fileUploaderService: FileUploaderService,
    private readonly fileUploaderCallbackService: FileUploaderCallbackService,
    @Inject(FILE_UPLOADER_SETTINGS) private readonly settings: εFileUploaderSettingsStrict,
    @Inject(FILE_UPLOADER_ANALYTICS_SERVICE)
    private readonly fileUploaderAnalyticsService: FileUploaderAnalyticsServiceInterface,
  ) {}

  /**
   * decorates File into DropZoneAwareFile to identify
   * the dropzone these files were dropped in in order to keep a proper
   * state among slideIn (open/close) interaction
   */
  public upload(uploadingFiles: File[]): Observable<Reference[]> {
    const mappedFiles: File[] = uploadingFiles
      .slice()
      .map(file => Object.assign(file, {dropZoneId: this.id, optional: this.optional}));
    return of(mappedFiles).pipe(
      flatMap(files => this.validate(files)),
      flatMap(() => this.fileUploaderService.upload(...mappedFiles)),
      tap(references => {
        this.fileUploaderCallbackService.afterUpload(references);
        if (this.fileUploaderAnalyticsService.trackUploadDocument) {
          this.fileUploaderAnalyticsService.trackUploadDocument();
        }
      }),
    );
  }

  /**
   * on drag events (enter, over, leave) we highlight
   * the drop area
   *
   * @param $event
   */
  public onDrag($event: DragZoneEvent) {
    if ($event.type === DragDropEvents.ENTER) {
      this.clearError();
    }
    this.highLightDropArea =
      $event.type === DragDropEvents.ENTER || $event.type === DragDropEvents.OVER;
    this.changeDetectorRef.detectChanges();
  }

  @HostListener('click')
  public onClick() {
    this.clearError();
  }

  /**
   * when files are dropped or selected from system dialog
   * we upload
   *
   * @param $event
   * @see {@link FileDropDirective}
   */
  public onDrop($event: DropZoneEvent) {
    this.clearError();
    this.upload($event.files)
      .pipe(
        catchError((error: UploadValidationError) => {
          if (isUploadValidationError(error)) {
            this.addErrorMessage(error);
            return EMPTY;
          } else {
            return throwError(error);
          }
        }),
      )
      .subscribe();
  }

  public ngOnInit() {
    const matchesThisDropZone = (value: DropZoneAwareFile | DropZoneAwareReference) => {
      if (value.dropZoneId != null) {
        return value.dropZoneId === this.id;
      } else {
        return this.id === '0';
      }
    };

    this.fileUploaderService.uploadedFiles$.pipe(takeUntilDestroyed(this)).subscribe(references => {
      this.uploadedReferences = references.filter(matchesThisDropZone);
      this.changeDetectorRef.markForCheck();
    });
    this.fileUploaderService.uploadingFiles$.pipe(takeUntilDestroyed(this)).subscribe(files => {
      this.uploadingFiles = files.filter(matchesThisDropZone);
      this.changeDetectorRef.markForCheck();
    });
  }

  /**
   * deletes a file entry
   */
  public deleteReference(reference: Reference, $event: Event) {
    $event.stopPropagation();
    this.fileUploaderService.remove(reference).subscribe(() => {
      this.fileUploaderCallbackService.afterDelete(reference);
      if (this.fileUploaderAnalyticsService.trackDeleteDocument) {
        this.fileUploaderAnalyticsService.trackDeleteDocument();
      }
    });
  }

  public uploadCompleted(): boolean {
    if (this.hasSiblingDropZones) {
      return this.uploadedReferences.length > 0;
    } else {
      return this.settings.maxNumberOfFiles === this.uploadedReferences.length;
    }
  }

  /**
   * cancels an upload request
   */
  public cancelUpload(file: File) {
    this.fileUploaderService.cancelUpload(file);
  }

  /**
   * downloads a reference when global settings allow it
   */
  public download(referencedFile: Reference) {
    if (!this.allowDownload) {
      return;
    }
    this.fileUploaderService.download(referencedFile).subscribe(() => {
      this.fileUploaderCallbackService.afterDownload(referencedFile);
      if (this.fileUploaderAnalyticsService.trackDownloadDocument) {
        this.fileUploaderAnalyticsService.trackDownloadDocument();
      }
    });
  }
}
