import {Component, HostBinding, HostListener, Input} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

import {DownloadManager} from '../download-manager/download-manager.service';
import {Target} from '../download-manager/target';
import {UniformTypeIdentifier} from '../download-manager/uniform-type-indentifier';

/**
 * A document link
 *
 * @ngModule OpenResourcesModule
 */
@Component({
  selector: 'a[hermesDocumentLink], button[hermesDocumentLink]',
  templateUrl: './document-link.component.html',
  styleUrls: ['./document-link.component.scss'],
})
export class DocumentLinkComponent {
  @Input()
  @HostBinding('attr.href')
  public href: string;

  /**
   * The target of the anchor, defaults to opening in a new page
   */
  @Input()
  @HostBinding('attr.target')
  public target: Target = Target.BLANK;

  /**
   * The resource type, defaults to com.adobe.pdf
   */
  @Input()
  @HostBinding('attr.type')
  public type = UniformTypeIdentifier.PDF;

  /**
   * Wrap the content on multiple lines
   */
  @Input()
  @coerceBooleanPrimitive()
  public wrapContent = true;

  public constructor(private readonly downloadManager: DownloadManager) {}

  @HostListener('click', ['$event'])
  public executeLink(event: Event) {
    event.preventDefault();
    this.downloadManager.downloadWithUrl(this.href, this.target, this.type).subscribe();
  }
}
