import {NgModule} from '@angular/core';

import {SelectOptionsComponent} from './options/select-options.component';

/**
 * Module containing parts used when implementing select-based components.
 */
@NgModule({
  declarations: [SelectOptionsComponent],
  exports: [SelectOptionsComponent],
})
export class SelectPartsModule {}
