import {ElementRef, Injectable, OnDestroy, Renderer2} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Utilities} from '@maia/core';

import {BehaviorSubject, EMPTY, Observable} from 'rxjs';
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators';

const generateId = Utilities.createIdGenerator('maia-label-');

type GetLabel = () => ElementRef<HTMLLabelElement>;

function getClosestInteractiveAncestor(element: Element): Element | null {
  // Taken from the WHATWG HTML spec at
  // https://html.spec.whatwg.org/multipage/dom.html#interactive-content-2
  return element.closest(
    'a[href], audio[controls], button, details, embed, iframe, img[usemap], ' +
      "input:not([type='hidden']), label, object[usemap], select, textarea, video[controls], [tabindex]",
  );
}

/**
 * This service allows linking the `<label>` element in a `<maia-form-element>` with a
 * non-form-control target.
 *
 * This service could've been part of the FormElementComponent, but is kept out of it for
 * readability.
 */
// https://stackoverflow.com/questions/50222998/error-encountered-in-metadata-generated-for-exported-symbol-when-constructing-an
// @dynamic
@Injectable()
@UntilDestroy()
export class LabelContainerService implements OnDestroy {
  private readonly _targets$ = new BehaviorSubject<Element[]>([]);

  public constructor(getLabel: GetLabel, renderer: Renderer2) {
    this._targets$
      .pipe(
        map(targets => targets[0]),
        distinctUntilChanged(),

        switchMap(target =>
          target == null
            ? EMPTY
            : new Observable<void>(observer => {
                // We take the labelElement lazily, because the constructor of our service
                // gets called before the @ViewChild is initialised.
                const labelElement = getLabel().nativeElement;
                const id = generateId();

                // Give the label an id and pass it to the target in the correct ARIA
                // attribute for screen readers to know the label labels the target.
                renderer.setAttribute(labelElement, 'id', id);
                renderer.setAttribute(target, 'aria-labelledby', id);
                observer.add(() => {
                  renderer.removeAttribute(labelElement, 'id');
                  renderer.removeAttribute(target, 'aria-labelledby');
                });

                // Make clicks on the label "activate" the target (cf. WHATWG HTML spec)
                observer.add(
                  renderer.listen(labelElement, 'click', (event: Event) => {
                    const eventTarget = event.target as Element;

                    if (target.contains(eventTarget)) {
                      // this event bubbled up from our target, we don't want to retrigger the
                      // event

                      // In theory this test is not required because `target` must always be
                      // an interactive element, but let's not allow our developers to end up
                      // in an infinite loop by forgetting to put a tabindex on their element.
                      return;
                    }

                    if (getClosestInteractiveAncestor(eventTarget) !== labelElement) {
                      // the click event was triggered on an interactive control, do nothing

                      // WHATWG HTML spec:
                      // The activation behavior of a label element for events targeted at
                      // interactive content descendants of a label element, and any
                      // descendants of those interactive content descendants, must be to do
                      // nothing.
                      // https://html.spec.whatwg.org/multipage/forms.html#the-label-element
                      return;
                    }

                    event.preventDefault();

                    // it's safe to always stop propagation because we're triggering a new
                    // event anyways. If the click event should bubble through, the second
                    // click we're triggering will do that.
                    event.stopPropagation();

                    target.dispatchEvent(
                      new MouseEvent('click', {
                        cancelable: true,
                        bubbles: true,
                      }),
                    );
                  }),
                );
              }),
        ),

        takeUntilDestroyed(this),
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this._targets$.complete();
  }

  /**
   * Links the `<label>` of the `<maia-form-element>` to the given target element.
   *
   * @param target The target element
   */
  public registerTarget(target: ElementRef<Element>): Observable<void> {
    return new Observable<void>(() => {
      this._targets$.next([...this._targets$.getValue(), target.nativeElement]);

      return () => {
        const targets = Array.from(this._targets$.getValue());
        const idx = targets.indexOf(target.nativeElement);

        if (idx !== -1 && !this._targets$.isStopped) {
          targets.splice(idx, 1);
          this._targets$.next(targets);
        }
      };
    }).pipe(takeUntilDestroyed(this));
  }
}
