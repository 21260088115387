import {Formatter} from '../base/formatter';

import {Boolean} from './businesstype';

/* eslint-disable @typescript-eslint/ban-types */
export const BooleanFormatter: Formatter<Boolean> = {
  /**
   * Formats the Boolean to a simple boolean (to keep the pattern like in other businesstypes).
   *
   * @param value The Boolean to format
   */
  format(value: Boolean): string {
    return value.asBoolean().toString();
  },

  /**
   * Just returns the string value.
   *
   * @param value The formatted value
   */
  unformat(value: string): string {
    return value;
  },
};
