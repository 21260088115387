import {
  DropdownPosition,
  MARGIN_FROM_REFERENCE,
  MARGIN_FROM_VIEWPORT,
  DropdownAnchor,
  DropdownAlignment,
  parsePosition,
} from './dropdown.constants';
import {DropdownMargin, ResolvedDropdownPosition} from './dropdown.interfaces';

export type TargetRect = Pick<ClientRect, 'height' | 'width'>;

/**
 * Returns the position that matches the given position but flipped vertically.
 */
function flipVertical(position: DropdownPosition): DropdownPosition {
  return position.replace(/top|bottom/, toReplace =>
    toReplace === 'bottom' ? 'top' : 'bottom',
  ) as DropdownPosition;
}

/**
 * Returns the position that matches the given position but flipped horizontally. Note that this
 * might return the position itself, as `bottom-middel` and `top-middle` can't be flipped
 * horizontally.
 */
function flipHorizontal(position: DropdownPosition): DropdownPosition {
  return position.replace(/left|right/, toReplace =>
    toReplace === 'right' ? 'left' : 'right',
  ) as DropdownPosition;
}

/**
 * Calculates the naive position: the position a dropdown should have if we don't take the viewport
 * limits into account.
 *
 * @param target The `ClientRect` of the dropdown element
 * @param referencePoint The `ClientRect` of the dropdown's reference point
 * @param position The requested position of the dropdown
 * @param margin The margins to take into account
 */
function calculateRequestedPosition(
  target: TargetRect,
  referencePoint: ClientRect,
  position: DropdownPosition,
  margin: DropdownMargin,
): ResolvedDropdownPosition {
  let top = 0;
  let left = 0;
  let width: number | undefined = undefined;

  const {anchor, alignment} = parsePosition(position);

  if (anchor === DropdownAnchor.Top) {
    top = referencePoint.top - target.height - margin.top;
  } else {
    top = referencePoint.top + referencePoint.height + margin.bottom;
  }

  switch (alignment) {
    case DropdownAlignment.Aligned: {
      const alignedMargin = margin.alignedHorizontal || 0;
      width = referencePoint.width - 2 * alignedMargin;
      left = referencePoint.left + alignedMargin;
      break;
    }
    case DropdownAlignment.Right:
      left = referencePoint.left;
      break;
    case DropdownAlignment.Middle:
      left = referencePoint.left + referencePoint.width / 2 - target.width / 2;
      break;
    case DropdownAlignment.Left:
      left = referencePoint.right - target.width;
      break;
  }

  return {top, left, width, position, disconnected: false, cramped: false};
}

/**
 * Checks whether the dropdown fits in the viewport along one axis.
 *
 * @param requestedPosition The requested position of the dropdown on that axis
 * @param size The size of the dropdown on that axis
 * @param max The size of the viewport on that axis
 */
function fits(requestedPosition: number, size: number, max: number): boolean {
  return (
    requestedPosition >= MARGIN_FROM_VIEWPORT &&
    requestedPosition + size <= max - MARGIN_FROM_VIEWPORT
  );
}

/**
 * Calculates the portion of a rectangle that is visible in the viewport along one axis.
 *
 * @param startPosition The start coordinate of the rectangle on that axis
 * @param size The size of the rectangle on that axis
 * @param max The size of the viewport on that axis
 * @return A number between 0 and 1 (inclusive)
 */
function getVisibleRatio(startPosition: number, size: number, max: number): number {
  let visiblePart = size;

  if (startPosition < 0) {
    visiblePart -= 0 - startPosition;
  }

  if (startPosition + size > max) {
    visiblePart -= startPosition + size - max;
  }

  return Math.max(0, visiblePart) / size;
}

/**
 * Clamps the position of a dropdown along one axis to fit inside the viewport.
 *
 * @param requestedPosition The requested position of the dropdown on that axis
 * @param size The size of the dropdown on that axis
 * @param max The size of the viewport on that axis
 */
function calculateClampedPosition(requestedPosition: number, size: number, max: number): number {
  const maxPosition = max - MARGIN_FROM_VIEWPORT - size;
  const minPosition = MARGIN_FROM_VIEWPORT;

  return Math.max(minPosition, Math.min(requestedPosition, maxPosition));
}

/**
 * Calculates the limit on the size of a dropdown along one axis.
 *
 * This function returns undefined if the natural size of the dropdown fits in the viewport. It
 * returns a number if the dropdown is too large to fit in the viewport and should be limited in
 * size.
 *
 * @param requestedPosition The requested position of the dropdown on that axis
 * @param size The size of the dropdown on that axis
 * @param max The size of the viewport on that axis
 */
function calculateSizeLimit(
  requestedPosition: number,
  size: number,
  max: number,
): number | undefined {
  const diff = max - MARGIN_FROM_VIEWPORT - (requestedPosition + size);
  if (diff >= 0) {
    return undefined;
  }

  return size + diff;
}

/**
 * The property key denoting the start coordinate in a `ClientRect` and `ResolvedDropdownPosition`
 * in a direction.
 */
const enum StartProperty {
  Horizontal = 'left',
  Vertical = 'top',
}

/**
 * The property key denoting the size of a `ClientRect` and `ResolvedDropdownPosition` in a
 * direction.
 */
const enum SizeProperty {
  Horizontal = 'width',
  Vertical = 'height',
}

/**
 * Try to fit the resolved dropdown position inside the viewport along one direction
 *
 * If the given `resolvedPosition` doesn't fit, this function tries to flip it (using
 * `positionFlipper`). If that doesn't fit, the resolved position is moved to a custom
 * position.
 * If the dropdown doesn't fit in the viewport, it is cramped and limited in size.
 *
 * @param resolvedPosition The current resolved position, this parameter will be modified
 * @param target The `ClientRect` of the dropdown element
 * @param referencePoint The `ClientRect` of the dropdown's reference point
 * @param margin The margins between the dropdown and its reference point
 * @param positionFlipper Function that flips the position in the given direction
 * @param startProperty The property denoting the start position of `ClientRect` and
 * `ResolvedDropdownPosition` in the given direction
 * @param sizeProperty The property denoting the size of `ClientRect` and `ResolvedDropdownPosition`
 * in the given direction
 * @param windowSize The size of the viewport in the direction
 * @param withoutReferenceOverlapping Disables reference overlapping
 */
function tryFit(
  resolvedPosition: ResolvedDropdownPosition,
  target: TargetRect,
  referencePoint: ClientRect,
  margin: DropdownMargin,
  positionFlipper: (position: DropdownPosition) => DropdownPosition,
  startProperty: StartProperty,
  sizeProperty: SizeProperty,
  windowSize: number,
  withoutReferenceOverlapping = false,
): void {
  // flipping a position won't impact the size, so it's safe to take it once instead of once before
  // and once after flipping
  const size = resolvedPosition[sizeProperty] || target[sizeProperty];

  if (fits(resolvedPosition[startProperty], size, windowSize)) {
    return;
  }

  const flippedPosition = calculateRequestedPosition(
    target,
    referencePoint,
    positionFlipper(resolvedPosition.position),
    margin,
  );

  if (fits(flippedPosition[startProperty], size, windowSize)) {
    resolvedPosition[startProperty] = flippedPosition[startProperty];
    resolvedPosition.position = flippedPosition.position;
  } else if (
    getVisibleRatio(referencePoint[startProperty], referencePoint[sizeProperty], windowSize) > 0
  ) {
    resolvedPosition.disconnected = true;
    resolvedPosition[startProperty] = withoutReferenceOverlapping
      ? referencePoint[startProperty] + referencePoint[sizeProperty] + MARGIN_FROM_REFERENCE
      : calculateClampedPosition(resolvedPosition[startProperty], size, windowSize);

    resolvedPosition[sizeProperty] = calculateSizeLimit(
      resolvedPosition[startProperty],
      size,
      windowSize,
    );
    resolvedPosition.cramped = resolvedPosition.cramped || resolvedPosition[sizeProperty] != null;
  } else {
    if (
      getVisibleRatio(flippedPosition[startProperty], size, windowSize) >
      getVisibleRatio(resolvedPosition[startProperty], size, windowSize)
    ) {
      resolvedPosition[startProperty] = flippedPosition[startProperty];
      resolvedPosition[sizeProperty] = flippedPosition[sizeProperty];
      resolvedPosition.position = flippedPosition.position;
    }
  }
}

/**
 * Calculates the position of a dropdown.
 *
 * The calculations will take the requested position into account. If the dropdown doesn't fit in
 * the requested position, it'll try to flip the position. If the dropdown still doesn't fit, it
 * will be positioned detached ("cramped") from the reference point. The location of a cramped
 * dropdown is the location that fits in the viewport closest to the location the dropdown would
 * have gotten in the requested position.
 *
 * @param target The `ClientRect` of the dropdown element
 * @param referencePoint The `ClientRect` of the dropdown's reference point
 * @param position The requested position for the dropdown
 * @param margin The margins between the dropdown and its reference point
 * @param windowHeight The height of the viewport
 * @param windowWidth The width of the viewport
 * @param withoutReferenceOverlapping Disables reference overlapping
 */
export function calculatePosition(
  target: TargetRect,
  referencePoint: ClientRect,
  position: DropdownPosition,
  margin: DropdownMargin,
  windowHeight: number,
  windowWidth: number,
  withoutReferenceOverlapping = false,
): ResolvedDropdownPosition {
  const resolvedPosition = calculateRequestedPosition(target, referencePoint, position, margin);

  tryFit(
    resolvedPosition,
    target,
    referencePoint,
    margin,
    flipVertical,
    StartProperty.Vertical,
    SizeProperty.Vertical,
    windowHeight,
    withoutReferenceOverlapping,
  );

  tryFit(
    resolvedPosition,
    target,
    referencePoint,
    margin,
    flipHorizontal,
    StartProperty.Horizontal,
    SizeProperty.Horizontal,
    windowWidth,
  );

  return resolvedPosition;
}
