import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';

import {InputGroupComponent} from './input-group.component';

/**
 * @ngModule FormsModule
 */
@Component({
  selector: 'button[maiaInputGroupButtonAddon]',
  templateUrl: './input-group-button-addon.component.html',
  styleUrls: ['./input-group-button-addon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class InputGroupButtonAddonComponent implements OnInit {
  @Input('separator')
  @coerceBooleanPrimitive()
  public showSeparator = true;

  /**
   * The type of the button, bound to the native `type` attribute
   *
   * This defaults to `button` to ensure clicking the button doesn't submit the form the input is
   * in.
   */
  @Input()
  @HostBinding('attr.type')
  public type = 'button';

  public constructor(
    private readonly _inputGroup: InputGroupComponent,
    private readonly _cdr: ChangeDetectorRef,
  ) {}

  @HostBinding('class.maia-input-group-button-addon--disabled')
  public get disabled(): boolean {
    return this._inputGroup.disabled;
  }

  @HostBinding('class.maia-input-group-button-addon--align-left')
  public get alignLeft(): boolean {
    return this._inputGroup.alignLeft;
  }

  @HostBinding('attr.disabled')
  public get isButtonDisabled(): string | null {
    return this._inputGroup.disabled ? 'disabled' : null;
  }

  public ngOnInit(): void {
    this._inputGroup.changed$.pipe(takeUntilDestroyed(this)).subscribe(() => {
      this._cdr.markForCheck();
    });
  }
}
