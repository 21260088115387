import {Injectable} from '@angular/core';
import {NgElementConstructor} from '@angular/elements';

import {WindowRef} from './window-ref.service';

/**
 * The `CustomElements` wraps customElements api using Angular's dependency
 * injection
 *
 * this way is preferred to decorate some of the native api apart from giving useful info to the
 * developers if they are lacking support from the browser, also it adapts the api to be used as
 * part of an angular application framework
 */
@Injectable({providedIn: 'root'})
export class CustomElements {
  public constructor(private readonly windowRef: WindowRef) {}

  /**
   * Defines the given component as custom element under the given tag name.
   */
  public async define<T>(
    tag: string,
    component: NgElementConstructor<T>,
  ): Promise<NgElementConstructor<T>> {
    const {customElements} = this.windowRef.window;
    if (customElements == null) {
      throw new Error(
        "This browser doesn't support customElements, install and load a custom-elements polyfill",
      );
    }

    if (customElements.get(tag) != null) {
      throw new Error(`Custom element with tag "${tag}" already registered`);
    }

    customElements.define(tag, component);
    await customElements.whenDefined(tag);
    return component;
  }
}
