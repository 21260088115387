import {Pipe, PipeTransform} from '@angular/core';
import {Tsfr, TsfrFormatter} from '@atlas/businesstypes';

/**
 * @ngModule BusinessTypesModule
 */
@Pipe({name: 'atlasTsfr'})
export class TsfrPipe implements PipeTransform {
  public transform(value: any): string {
    return Tsfr.isTsfr(value) ? TsfrFormatter.format(value) : value;
  }
}
