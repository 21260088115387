import {
  BACKSPACE,
  DELETE,
  DOWN_ARROW,
  END,
  HOME,
  LEFT_ARROW,
  RIGHT_ARROW,
  UP_ARROW,
} from '@angular/cdk/keycodes';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  forwardRef,
  OnInit,
  QueryList,
  Renderer2,
} from '@angular/core';
import {ATLAS_VALUE_ACCESSOR, AtlasControlValueAccessor} from '@atlas-angular/businesstypes';
import {WindowRef} from '@atlas-angular/cdk/globals';
import {DomIoService} from '@maia/core';
import {CapturedInputDirective} from '@maia/forms/capture';
import {BehaviorSubject, Observable} from 'rxjs';

import {CaretHelper} from '../util/caret-helper.service';
import {MaskHelper} from '../util/mask-helper.util';
import {ValueFormatter} from '../util/value-formatter.util';
import {noOpValueProcessor, ValueProcessor} from '../util/value-processor.util';

declare global {
  interface Window {
    /** clipboard data in IE */
    clipboardData?: DataTransfer;
  }
}

export const SHIFT = 16;
export const CONTROL = 17;
export const ALT = 18;
export const META = 91;
export const IME_KEYBOARD_EVENT_CODE = 229;

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() {}

/**
 * A masked input contains an input with a mask. It outputs the value of the input without any
 * formatting, i.e. not conforming to the mask.
 *
 * Example:
 *
 * ```
 * Mask: "+++ ### / #### / ##### +++"
 * The value of this component: "123456789002"
 * What the client sees: "+++ 123 / 4567 / 89002 +++"
 * ```
 *
 * This component expects _one_ input as child. The input __must__ at least have the `[maiaInput]`
 * component on it for styling. This is the input the client will be typing in. As such this input's
 * value will be manipulated by this component to match the input mask. The input __mustn't__ be
 * bound to using `ngModel` and the like, that's what this component is for. The input can be used
 * to set placeholder, WAI-ARIA attributes, tabindex and the like.
 *
 * Example usage:
 *
 * ```html
 * <maia-masked-input [(ngModel)]="someDate" atlasDate name="someDate">
 *   <input maiaInput placeholder="Fill in a date">
 * </maia-masked-input>
 * ```
 *
 * @ngModule InputMasksModule
 */
@Component({
  selector: 'maia-masked-input',
  templateUrl: './masked-input.component.html',
  styleUrls: ['./masked-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: ATLAS_VALUE_ACCESSOR, useExisting: forwardRef(() => MaskedInputComponent)}],
})
export class MaskedInputComponent implements AtlasControlValueAccessor, AfterContentInit, OnInit {
  /**
   * We don't provide the input element in our own template for one simple reason: we want the
   * screen developer to be able to set attributes on the input the client is typing in. This is
   * important for ARIA, otherwise we would need a lot of inputs here to possibly set attributes on
   * the input.
   */
  @ContentChildren(CapturedInputDirective)
  private readonly _inputs: QueryList<CapturedInputDirective>;

  /**
   * The input element where the client does the typing.
   *
   * We'll be reading/setting the value of this input directly rather than using Angular's binding
   * because we need to handle setting both the value and the caret position.
   * Using Angular's binding would mean the caret moves to the end of the input every time we change
   * the property the value is bound do (e.g. after formatting).
   */
  private _input?: CapturedInputDirective = undefined;

  /**
   * The unformatted raw value
   *
   * The raw value is the value of the input field in the masked input. This is not necessarily
   * identical to the value of the masked input itself, which is the result of processing the raw
   * value using the `_valueProcessor`.
   */
  private _rawValue = '';

  private _helper: MaskHelper;

  private _valueFormatter?: ValueFormatter = undefined;

  private _isInitialized = false;

  private _disabled = false;

  private readonly _value$: BehaviorSubject<string> = new BehaviorSubject('');
  /**
   * The unformatted string value
   */
  public readonly value$: Observable<string> = this._value$.asObservable();

  private _valueProcessor: ValueProcessor = noOpValueProcessor;

  public constructor(
    private readonly _caretHelper: CaretHelper,
    private readonly _renderer: Renderer2,
    private readonly _domIo: DomIoService,
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly _window: WindowRef,
  ) {}

  // istanbul ignore next: This is instantly overriden by [(ngModel)] we use in tests
  // eslint-disable-next-line
  private _onChange = (s: string) => {};

  // istanbul ignore next: This is instantly overriden by [(ngModel)] we use in tests
  // eslint-disable-next-line
  private _onTouch = () => {};

  /**
   * Sets the mask and placeholder (if any) to apply to the input.
   *
   * If no placeholder is set, a placeholder will be generated where every input character is
   * replaced with a dot.
   *
   * Note: the placeholder must be given as a formatted string, that is: if the format is
   * `##-##-####` the placeholder for a date can be `DD-MM-YYYY`, not `DDMMYYYY`.
   *
   * @param mask The mask to apply on the input
   * @param placeholder The placeholder, if any
   */
  public setMaskAndPlaceholder(mask: string, placeholder?: string): void {
    const caret = this._helper != null ? this._helper.unmapCaret(this._caret) : 0;

    this._helper = new MaskHelper(mask, placeholder);

    // Only detect changes if initialized.
    // If we're not initialized detecting changes is not needed and even leads to errors.
    if (this._isInitialized) {
      this._setValueWithCaretPosition(this._rawValue, this._helper.mapCaret(caret));
      this._changeDetector.detectChanges();
    }
  }

  /**
   * Sets the given value formatter, used when formatting/unformatting the final value.
   *
   * @param formatter The value formatter
   */
  public setValueFormatter(formatter?: ValueFormatter): void {
    this._valueFormatter = formatter;
  }

  /**
   * Sets the value processor to use when converting between values in the masked input's input
   * field and actual values for the masked input itself.
   *
   * @param processor A value processor to use
   */
  public setValueProcessor(processor: ValueProcessor): void {
    this._valueProcessor = processor;
  }

  /**
   * Passes the given value on to the input element without preserving the caret position.
   *
   * This method doesn't modify this component's value.
   *
   * @see #_setValueWithCaretPosition
   * @param value The new value
   */
  private _setValue(value: string): void {
    // istanbul ignore if: unsure how to test this, but absence of this if causes problems
    if (this._input == null) {
      return;
    }

    if (this._helper != null) {
      this._input.element.value = this._helper.formatInput(value);
    }
  }

  /**
   * Passes the given value on to the input element and moves the caret to the given position.
   *
   * This method doesn't modify this component's value.
   *
   * @see #_setValue
   * @param value The new value
   * @param caret The new caret position
   */
  private _setValueWithCaretPosition(value: string, caret: number): void {
    this._setValue(value);
    this._caret = caret;
  }

  public ngOnInit(): void {
    this._isInitialized = true;
  }

  public handleSelectionStart(): void {
    void this._domIo.mutate(() => {
      if (this._input && this._input.element.selectionStart != null) {
        const constrainedSelectionStart = Math.max(
          this._input.element.selectionStart,
          this._helper.mapCaret(0),
        );

        // Only set the selectionStart if it needs to change, because in IE this leads to an
        // infinite loop.
        // Well, not really an infinite loop, more like:
        // - user sets selection, fire 'select' event
        // - await the next frame (due to domIo.mutate())
        // - we set selection, fires 'select' __synchronously__
        // - don't await the next frame (due to domIo.mutate()) but does run on the next microtask
        // - we set selection, fires 'select' __synchronously__
        // - ... ad infinitum
        //
        // Because the browser doesn't continue handling asynchronous events and macrotasks until
        // the microtask queue is empty, the browser effectively stalls. This is very similar to
        // an infinite loop but asynchronous.
        //
        // It appears most browsers only send 'select' events if the selectionStart changes, but IE
        // doens't which leads to the loop. Only setting the selectionStart when it needs to change
        // breaks the loop.
        //
        // More info on microtasks, microtasks and the event loop: https://youtu.be/cCOL7MC4Pl0
        if (this._input.element.selectionStart !== constrainedSelectionStart) {
          this._input.element.selectionStart = constrainedSelectionStart;
        }
      }
    });
  }

  public ngAfterContentInit(): void {
    const boundOnInput = this.onInput.bind(this);
    const boundOnTouch = this.onTouch.bind(this);
    const boundOnKeydown = this.handleKeydown.bind(this);
    const boundOnSelectionStart = this.handleSelectionStart.bind(this);
    const boundOnPaste = this.onPaste.bind(this);

    const unregisterFunctions: (() => void)[] = [];

    function unregisterListeners() {
      for (const unregister of unregisterFunctions) {
        unregister();
      }
      unregisterFunctions.length = 0;
    }

    const onInputUpdate = () => {
      // istanbul ignore else
      if (this._inputs.length !== 1) {
        throw new Error(
          `Expected one input element in the masked input, got ${this._inputs.length}`,
        );
      }

      unregisterListeners();

      this._input = this._inputs.first;
      if (this._input) {
        unregisterFunctions.push(this._renderer.listen(this._input.element, 'input', boundOnInput));
        unregisterFunctions.push(this._renderer.listen(this._input.element, 'blur', boundOnTouch));
        unregisterFunctions.push(this._renderer.listen(this._input.element, 'paste', boundOnPaste));
        unregisterFunctions.push(
          this._renderer.listen(this._input.element, 'keydown', boundOnKeydown),
        );
        unregisterFunctions.push(
          this._renderer.listen(this._input.element, 'select', boundOnSelectionStart),
        );
        unregisterFunctions.push(
          this._renderer.listen(this._input.element, 'focus', boundOnSelectionStart),
        );
        unregisterFunctions.push(
          this._renderer.listen(this._input.element, 'click', boundOnSelectionStart),
        );
        this._input.setDisabledState(this._disabled);
      }

      this._setValue(this._rawValue);
    };

    this._inputs.changes.subscribe({
      next: onInputUpdate,
      complete: unregisterListeners,
    });
    onInputUpdate();
  }

  /**
   * Returns the visible part of the placeholder. For example, with format `##-##-####`, placeholder
   * `DD-MM-YYYY` and value `010220` the result will be `  -  -  YY`.
   */
  public getVisiblePlaceholder(): string {
    if (this._helper == null) {
      return '';
    }

    return this._helper.getPlaceholder(this._rawValue);
  }

  /**
   * Called when the value of the input changed, e.g. due to the client typing a character. This
   * method updates the value of the component depending on what happened in the input element.
   */
  public onInput(): void {
    // Note that it's safe to assume at this point that there's no selection on the input.
    const value = this._helper.unformatInput(this._input!.element.value);
    const caretPosition = this._helper.unmapCaret(this._caret);

    this.writeValueFromRawValue(value);

    if (caretPosition === value.length) {
      this._setValue(value);
    } else {
      this._setValueWithCaretPosition(value, this._caret);
    }

    this._changeDetector.detectChanges();
  }

  public onTouch(): void {
    this._onTouch();
  }

  /**
   * Called when user pastes (Ctrl/Cmd + v) a string to the input field. This method overwrites the
   * default paste behaviour to allow extra formatting to be applied.
   *
   * @param event The clipboard 'paste' event
   */
  public onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    let pastedText;

    if (event.clipboardData != null) {
      pastedText = event.clipboardData.getData('text');
    } else {
      // Fall back to the non-standard window.clipboardData in case event.clipboardData is absent
      // (-> IE)
      pastedText = this._window.window.clipboardData!.getData('Text');
    }

    const selection = this._caretHelper.getSelection(this._input!.element);
    const start = this._helper.unmapCaret(selection.start);

    const valBeforeCaret = this._rawValue.slice(0, start);
    const valAfterCaret = this._rawValue.slice(this._helper.unmapCaret(selection.end));

    const cleanedPastedText = this._helper.unformatInput(
      (this._valueFormatter?.pasteUnformatValue?.(pastedText) ?? pastedText).replace(/\s/g, ''),
    );
    const newCaretPosition = start + cleanedPastedText.length;

    const newValue = `${valBeforeCaret}${cleanedPastedText}${valAfterCaret}`;

    const unformattedValue = this._helper.unformatInput(
      this._valueFormatter != null && typeof this._valueFormatter.pasteUnformatValue === 'function'
        ? this._valueFormatter.pasteUnformatValue(newValue)
        : newValue,
    );

    this.writeValueFromRawValue(unformattedValue);
    this._setValueWithCaretPosition(unformattedValue, this._helper.mapCaret(newCaretPosition));

    this._changeDetector.detectChanges();
  }

  /**
   * Similar to writeValue. Write value, but starting from a raw value.
   *
   * @see writeValue
   */
  public writeValueFromRawValue(value: string): void {
    if (value !== this._rawValue) {
      this._rawValue = value;
      const processedValue = this._valueProcessor.fromRawValue(value);
      this._value$.next(processedValue);
      this._onChange(processedValue);
    }
  }

  /**
   * The position of the caret in the input element.
   */
  private get _caret(): number {
    // istanbul ignore if: unsure how to test this, but absence of this if causes problems
    if (this._input == null) {
      return 0;
    }

    return this._caretHelper.getCaretPosition(this._input.element);
  }

  private set _caret(caret: number) {
    // istanbul ignore else: unsure how to test this, but absence of this if causes problems
    if (this._input != null) {
      this._caretHelper.setCaretPosition(this._input.element, caret);
    }
  }

  /**
   * Moves the caret to the before the first input character.
   *
   * This is not necessarily the start of the input, as the mask could start with a static part
   * (e.g. for TSFR `+++ ### / #### / ##### +++`).
   */
  public moveCaretToStart(event: KeyboardEvent): void {
    event.preventDefault();

    if (event.shiftKey) {
      const selection = this._caretHelper.getSelection(this._input!.element);

      selection.start = this._helper.mapCaret(0);
      if (selection.direction !== 'forward') {
        selection.direction = 'backward';
      }

      this._caretHelper.setSelection(this._input!.element, selection);
    } else {
      this._caret = this._helper.mapCaret(0);
    }
  }

  /**
   * Moves the caret to the after the last input character.
   *
   * This is not necessarily the end of the input, as the mask could end with a static part (e.g.
   * for TSFR `+++ ### / #### / ##### +++`).
   */
  public moveCaretToEnd(event: KeyboardEvent): void {
    event.preventDefault();

    if (event.shiftKey) {
      const selection = this._caretHelper.getSelection(this._input!.element);

      selection.end = this._helper.mapCaret(this._rawValue.length);
      if (selection.direction !== 'backward') {
        selection.direction = 'forward';
      }

      this._caretHelper.setSelection(this._input!.element, selection);
    } else {
      this._caret = this._helper.mapCaret(this._rawValue.length);
    }
  }

  /**
   * Moves the caret to the previous input character.
   */
  public moveCaretLeft(event: KeyboardEvent): void {
    if (event.ctrlKey || event.metaKey) {
      this.moveCaretToStart(event);
      return;
    }
    event.preventDefault();

    const selection = this._caretHelper.getSelection(this._input!.element);
    const hasSelection = selection.start !== selection.end;

    if (hasSelection && !event.shiftKey) {
      this._caret = selection.start;
      return;
    }

    const updateEndIndex = hasSelection && selection.direction === 'forward';

    const oldCaretPosition = updateEndIndex ? selection.end : selection.start;
    const newCaretPosition = this._helper.mapSpacedCaret(
      this._helper.unmapSpacedCaret(oldCaretPosition) - 1,
    );

    if (hasSelection || event.shiftKey) {
      if (updateEndIndex) {
        selection.end = newCaretPosition;
      } else {
        selection.start = newCaretPosition;
        selection.direction = 'backward';
      }

      this._caretHelper.setSelection(this._input!.element, selection);
    } else {
      this._caret = newCaretPosition;
    }
  }

  /**
   * Moves the caret to the next input character.
   */
  public moveCaretRight(event: KeyboardEvent): void {
    if (event.ctrlKey || event.metaKey) {
      this.moveCaretToEnd(event);
      return;
    }
    event.preventDefault();

    const selection = this._caretHelper.getSelection(this._input!.element);
    const hasSelection = selection.start !== selection.end;

    if (hasSelection && !event.shiftKey) {
      this._caret = selection.end;
      return;
    }

    const updateStartIndex = hasSelection && selection.direction === 'backward';

    const oldCaretPosition = updateStartIndex ? selection.start : selection.end;
    const newCaretPosition = this._helper.mapSpacedCaret(
      this._helper.unmapSpacedCaret(oldCaretPosition) + 1,
    );

    if (hasSelection || event.shiftKey) {
      if (updateStartIndex) {
        selection.start = newCaretPosition;
      } else {
        selection.end = newCaretPosition;
        selection.direction = 'forward';
      }

      this._caretHelper.setSelection(this._input!.element, selection);
    } else {
      this._caret = newCaretPosition;
    }
  }

  /**
   * Removes the character before the caret, or removes the selection if there is a selection.
   */
  public handleBackspace(event: KeyboardEvent): void {
    event.preventDefault();
    const selection = this._caretHelper.getSelection(this._input!.element);

    const start = this._helper.unmapCaret(selection.start);

    if (selection.start === selection.end) {
      this._removeFromValue(start - 1, start, this._helper.mapCaret(start - 1));
    } else {
      this._removeFromValue(start, this._helper.unmapCaret(selection.end), selection.start);
    }
  }

  /**
   * Removes the character after the caret, or removes the selection if there is a selection.
   */
  public handleDelete(event: KeyboardEvent): void {
    event.preventDefault();
    const selection = this._caretHelper.getSelection(this._input!.element);

    const start = this._helper.unmapCaret(selection.start);

    if (selection.start === selection.end) {
      this._removeFromValue(start, start + 1, selection.start);
    } else {
      this._removeFromValue(start, this._helper.unmapCaret(selection.end), selection.start);
    }
  }

  /**
   * Removes the characters from start (inclusive) to next (exclusive) from the value string and
   * moves the caret to the given position.
   */
  private _removeFromValue(start: number, end: number, newCaretPosition: number): void {
    const newValue = this._rawValue.substr(0, start) + this._rawValue.substr(end);
    if (newValue === this._rawValue) {
      return;
    }

    this._rawValue = newValue;
    this._setValueWithCaretPosition(newValue, newCaretPosition);
    this._changeDetector.detectChanges();

    const newProcessedValue = this._valueProcessor.fromRawValue(newValue);
    this._value$.next(newProcessedValue);
    this._onChange(newProcessedValue);
  }

  /**
   * Keydown event listener triggered upon every character.
   *
   * This method delegates to the specific key methods defined on this class, e.g. `handleDelete`.
   *
   * For regular typing this method moves the caret to the start of the next input group if it is at
   * the end of the current input group. Example:
   *
   * ```
   * Before client hits the keyboard, the caret is positioned here:
   *   [+++ 123| /     /      +++]
   * This method moves it to
   *   [+++ 123 / |    /      +++]
   * upon keydown so the new character gets inserted at the correct location:
   *   [+++ 123 / 4|   /      +++]
   * ```
   */
  public handleKeydown(event: KeyboardEvent): void {
    const {keyCode} = event;
    const listener = KEYDOWN_LISTENERS[keyCode];

    if (listener != null) {
      listener.call(this, event);
    } else {
      // Client is typing.
      const selection = this._caretHelper.getSelection(this._input!.element);
      if (selection.start === selection.end) {
        // If the caret is at the end of a group of input characters,
        // move it to the start of the next group of input characters.
        this._caret = this._helper.mapCaret(this._helper.unmapCaret(selection.start));
      }
    }
  }

  // Methods of AtlasControlValueAccessor

  public writeValue(value: string): void {
    const processedValue = this._valueFormatter?.unformatValue(value) ?? value;
    const rawValue = this._valueProcessor.toRawValue(processedValue);

    if (rawValue !== this._rawValue) {
      this._rawValue = rawValue;
      this._setValue(rawValue);

      this._value$.next(processedValue);

      if (this._isInitialized) {
        this._changeDetector.detectChanges();
      }
    }
  }

  /**
   * method to run the processor value, this is useful
   * when using the input mask directives in a reactive form context
   */
  public reprocessValue(): void {
    const processedValue = this._value$.getValue();
    if (!processedValue) {
      return;
    }
    const rawValue = this._valueProcessor.toRawValue(processedValue);

    if (rawValue !== this._rawValue) {
      this._rawValue = rawValue;
      this._setValue(rawValue);

      if (this._isInitialized) {
        this._changeDetector.detectChanges();
      }
    }
  }

  public registerOnChange(fn: (newValue: string) => void): void {
    this._onChange = newValue => {
      fn(this._valueFormatter != null ? this._valueFormatter.formatValue(newValue) : newValue);
    };
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled === this._disabled) {
      return;
    }

    this._disabled = isDisabled;

    if (this._input != null) {
      this._input.setDisabledState(isDisabled);
    }

    if (this._isInitialized) {
      this._changeDetector.detectChanges();
    }
  }

  public get disabled(): boolean {
    return this._disabled;
  }
}

interface KeydownListener {
  (this: MaskedInputComponent, event: KeyboardEvent): void;

  call(thisArg: MaskedInputComponent, event: KeyboardEvent): void;
}

/* eslint-disable @typescript-eslint/unbound-method */
// The key-specific handlers in an object so they can be used with keyCode as key.
const KEYDOWN_LISTENERS = {
  [DELETE]: MaskedInputComponent.prototype.handleDelete,
  [BACKSPACE]: MaskedInputComponent.prototype.handleBackspace,

  [LEFT_ARROW]: MaskedInputComponent.prototype.moveCaretLeft,
  [RIGHT_ARROW]: MaskedInputComponent.prototype.moveCaretRight,

  [HOME]: MaskedInputComponent.prototype.moveCaretToStart,
  [UP_ARROW]: MaskedInputComponent.prototype.moveCaretToStart,

  [END]: MaskedInputComponent.prototype.moveCaretToEnd,
  [DOWN_ARROW]: MaskedInputComponent.prototype.moveCaretToEnd,

  [SHIFT]: noop,
  [CONTROL]: noop,
  [ALT]: noop,
  [META]: noop,
  [IME_KEYBOARD_EVENT_CODE]: noop,
} as {[s: string]: KeydownListener};
