import {Pipe, PipeTransform} from '@angular/core';
import {IdentityCardNumber, IdentityCardNumberFormatter} from '@atlas/businesstypes';

/**
 * @ngModule BusinessTypesModule
 */
@Pipe({name: 'atlasIdentityCardNumber'})
export class IdentityCardNumberPipe implements PipeTransform {
  public transform(value: any): string {
    return IdentityCardNumber.isIdentityCardNumber(value)
      ? IdentityCardNumberFormatter.format(value)
      : value;
  }
}
