import {ChangeDetectionStrategy, Component, HostBinding, Input, NgModule, ViewEncapsulation} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

/**
 * @ngModule NavigationFuncArrowCircleSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaNavigationFuncArrowCircle]',
  template: '<svg:path d="M2 12A10 10 0 1 0 12 2 10 10 0 0 0 2 12zm11.57-3.17l2.8 2.8v.06l.08.11a.55.55 0 0 1 0 .4l-.08.11v.06l-2.8 2.8a.52.52 0 1 1-.74-.74l1.91-1.91H8a.52.52 0 0 1 0-1h6.74l-1.91-1.91a.52.52 0 1 1 .74-.74z"></svg:path>',

  styles: [
    ':host { fill: currentColor; }',
    
    ':host(.maia-icon--down) {transform: rotate(90deg);} :host(.maia-icon--left) {transform: rotate(180deg);} :host(.maia-icon--up) {transform: rotate(-90deg);}',
  ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationFuncArrowCircleSvgComponent {
  @Input()
  @HostBinding('class.maia-icon--right')
  @coerceBooleanPrimitive()
  public right = true;

  @Input()
  @HostBinding('class.maia-icon--down')
  @coerceBooleanPrimitive()
  public down = false;

  @Input()
  @HostBinding('class.maia-icon--left')
  @coerceBooleanPrimitive()
  public left = false;

  @Input()
  @HostBinding('class.maia-icon--up')
  @coerceBooleanPrimitive()
  public up = false;
}

@NgModule({
  declarations: [
    NavigationFuncArrowCircleSvgComponent,
  ],
  exports: [
    NavigationFuncArrowCircleSvgComponent,
  ],
})
export class NavigationFuncArrowCircleSvgModule {}
