import {Directive, ElementRef, HostBinding, Injector, Input, OnInit} from '@angular/core';
import {coerceNumberPrimitive} from '@atlas-angular/cdk/coercion';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';

import {EMPTY, Observable} from 'rxjs';

import {LabelContainerService} from './label-container.service';

export function markLabelTarget(injector: Injector): Observable<void> {
  const element = injector.get<ElementRef<Element>>(ElementRef);
  const container = injector.get<LabelContainerService | null>(LabelContainerService, null);

  if (container == null) {
    return EMPTY;
  }

  return container.registerTarget(element);
}

/**
 * Marks the host element as target for the containing form-element's label.
 *
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label
 *
 * Regular `<input>`, `<textarea>` and `<button>` elements will automatically be linked to the label
 * of the form-element the element is in. Custom control components that don't use a real form
 * control behind the screens can use this directive to fake the native label behaviour.
 *
 * - This directive marks the host as focusable and inserts it into the tab order. You can override
 *   this using the `tabindex` (e.g. set it to `-1` to remove the element from the tab order)
 * - All clicks on the label will be moved to this target instead.
 * - This target and the label will be linked using ARIA attributes to ensure screenreaders know
 *   this target is labelled by the label.
 *
 * @ngModule FormsModule
 */
@Directive({
  selector: '[maiaLabelTarget]',
})
@UntilDestroy()
export class LabelTargetDirective implements OnInit {
  /**
   * The tab index of the element
   *
   * Label targets have to be focusable. By default we'll insert these in the natural tab order, but
   * using the regular old tabindex attribute/input this can be overriden to e.g. only allow
   * focusing through the label.
   */
  @Input('tabindex')
  @coerceNumberPrimitive()
  @HostBinding('attr.tabindex')
  public tabIndex = 0;

  public constructor(private readonly _injector: Injector) {}

  public ngOnInit(): void {
    markLabelTarget(this._injector).pipe(takeUntilDestroyed(this)).subscribe();
  }
}
