import {TileDocumentSignComponent} from '../tile-document-sign/tile-document-sign.component';
/**
 * Util function to be used inside constructors of components that need
 * to be wrapped inside a tile document sign component.
 * @throws if the parent isn't a TileDocumentSignComponent
 */
export function throwErrorWhenInvalidParent(
  selector: string,
  parent?: TileDocumentSignComponent,
): void {
  if (!(parent instanceof TileDocumentSignComponent)) {
    throw new Error(
      `Cannot create a ${selector} component out of a <maia-tile-document-sign /> component`,
    );
  }
}
