import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationFuncCoinsSvgModule
 */
@Component({
  selector: 'svg[kdlBifrostInformationFuncCoins]',
  template: '<svg:g class="nc-icon-wrapper" transform="matrix(.8 0 0 .8 2.4 2.4)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"><svg:path data-cap="butt" data-color="color-2" fill="none" stroke-miterlimit="10" d="M1 4v4c0 1.657 2.686 3 6 3s6-1.343 6-3V4"></svg:path><svg:path data-cap="butt" data-color="color-2" fill="none" stroke-miterlimit="10" d="M1 8v4c0 1.657 2.686 3 6 3 1.537 0 2.938-.29 4-.765"></svg:path><svg:path data-cap="butt" data-color="color-2" fill="none" stroke-miterlimit="10" d="M1 12v4c0 1.657 2.686 3 6 3 1.537 0 2.939-.289 4-.764"></svg:path><svg:ellipse data-color="color-2" fill="none" stroke-miterlimit="10" cx="7" cy="4" rx="6" ry="3"></svg:ellipse><svg:path data-cap="butt" fill="none" stroke-miterlimit="10" d="M11 12v4c0 1.657 2.686 3 6 3s6-1.343 6-3v-4"></svg:path><svg:path data-cap="butt" fill="none" stroke-miterlimit="10" d="M11 16v4c0 1.657 2.686 3 6 3s6-1.343 6-3v-4"></svg:path><svg:ellipse fill="none" stroke-miterlimit="10" cx="17" cy="12" rx="6" ry="3"></svg:ellipse></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationFuncCoinsSvgComponent {
}

@NgModule({
  declarations: [
    InformationFuncCoinsSvgComponent,
  ],
  exports: [
    InformationFuncCoinsSvgComponent,
  ],
})
export class InformationFuncCoinsSvgModule {}
