import {HttpRequest} from '@angular/common/http';
import {Injectable, Inject, LOCALE_ID, Optional} from '@angular/core';
import {Call, ConnectorResponseBase} from '@atlas/convertor';
import {Observable} from 'rxjs';

import {ConnectorBackend, ConnectorInterceptor} from '../interfaces/interceptor';

/**
 * A `ConnectorInterceptor` that will add the default headers to the `HttpRequest` object.
 *
 * It will only add headers that are not yet present, so it won't overwrite existing headers.
 */
@Injectable()
export class DefaultHeadersInterceptor implements ConnectorInterceptor {
  private readonly _defaultHeaders = new Map([['accept', 'application/json']]);
  public constructor(@Optional() @Inject(LOCALE_ID) localeId?: string) {
    if (localeId != null) {
      this._defaultHeaders.set('Accept-Language', localeId);
    }
  }

  public intercept<I, O>(
    call: Call<I, O>,
    input: I,
    request: HttpRequest<null>,
    next: ConnectorBackend<O>,
  ): Observable<ConnectorResponseBase<O>> {
    let headers = request.headers;
    this._defaultHeaders.forEach((value: string, key: string): void => {
      if (!headers.has(key)) {
        headers = headers.set(key, value);
      }
    });
    return next.handle(request.clone({headers}));
  }
}
