import {Component, Input} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';
import {Logger} from '@atlas/logger';

/**
 * Progress
 *
 * @ngModule ProgressModule
 */
@Component({
  selector: 'maia-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent {
  private _progressPercentage = 0;
  private _logger: Logger;

  public constructor(loggerFactory: LoggerFactory) {
    this._logger = loggerFactory.createLogger('ProgressComponent');
  }

  @Input()
  public set progressPercentage(value: number) {
    if (value >= 0 && value <= 100) {
      this._progressPercentage = value;
    } else {
      this._logger.error('Entered value for progressPercentage needs to be betweeen 0 and 100.');
    }
  }

  public getProgressTransformValue(): string {
    return 'scaleX(' + this._progressPercentage / 100 + ')';
  }
}
