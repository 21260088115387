import {Injectable} from '@angular/core';
import {WindowRef} from '@atlas-angular/cdk/globals';

// The code in this file is loosely based on the fastdom library.

const enum Mode {
  MEASURE,
  MUTATE,
}

type BatchedFunction = () => void;

/**
 * A batch is a series of measurements and mutations to apply.
 *
 * When the batch runs, the registered functions will be executed in order of registration.
 * Measurements are executed before mutations, to ensure that a measurement doesn't trigger a style
 * recalc or synchronous layout because a mutation made changes to the DOM.
 *
 * If there are no mutations in a batch, its mode will be `MEASURE`. If there are mutations, its
 * mode will be `MUTATE`.
 * Once the mode of a batch is `MUTATE`, it will not accept any new measurements, as that would mean
 * that the batch must either run the functions out of order or it will have to run a measurement
 * after a mutation.
 */
class Batch {
  private readonly _measures: BatchedFunction[] = [];
  private readonly _mutations: BatchedFunction[] = [];

  private _mode = Mode.MEASURE;

  private _running = false;
  private _runningMode = Mode.MEASURE;

  public canHandle(mode: Mode): boolean {
    if (this._mode === Mode.MUTATE && mode === Mode.MEASURE) {
      // We're mutating stuff in this batch, so no more measurements are accepted
      return false;
    }

    if (!this._running) {
      // If the batch isn't running yet, we can handle the request
      return true;
    }

    // We can add more measure requests inside a running batch that's running in the measure phase
    // We can add more mutate requests in any running batch
    return mode === Mode.MUTATE || this._runningMode === Mode.MEASURE;
  }

  public measure(measureFn: BatchedFunction): void {
    // istanbul ignore if: safety measure, should not happen unless developers get freaky
    if (!this.canHandle(Mode.MEASURE)) {
      throw new Error(`No more measures are supported in this batch`);
    }

    this._measures.push(measureFn);
  }

  public mutate(mutateFn: BatchedFunction): void {
    // istanbul ignore if: safety measure, should not happen unless developers get freaky
    if (!this.canHandle(Mode.MUTATE)) {
      throw new Error(`No more mutations are supported in this batch`);
    }

    this._mode = Mode.MUTATE;
    this._mutations.push(mutateFn);
  }

  public run(): void {
    this._running = true;

    for (const fn of this._measures) {
      fn();
    }

    this._runningMode = Mode.MUTATE;

    for (const fn of this._mutations) {
      fn();
    }

    this._measures.length = 0;
    this._mutations.length = 0;
  }
}

/**
 * DomIoService that uses `requestAnimationFrame` to run the registered functions at safe moments in
 * time.
 */
@Injectable({providedIn: 'root'})
export class AnimationFrameDomIoService implements DomIoService {
  /**
   * The currently pending animation frame, if any
   */
  private _animationFrame?: number = undefined;

  /**
   * The batches.
   */
  private readonly _batches: Batch[];

  /**
   * The last batch, this is equal to `_batches[_batches.length - 1]`, but kept separately because
   * most of the time we need the last batch.
   *
   * This is the batch where new measurements/mutations are added to if possible.
   */
  private _lastBatch: Batch;

  /**
   * The `_frame` method bound to this instance.
   */
  private readonly _boundFrame: () => void;

  public constructor(private readonly _window: WindowRef) {
    this._batches = [];
    this._createBatch();

    this._boundFrame = this._frame.bind(this);
  }

  /**
   * Requests a new animation frame to start running the batches, unless we're already running
   * animation frames.
   */
  private _requestFrame(): void {
    if (this._animationFrame != null) {
      // we already have a pending frame
      return;
    }

    this._animationFrame = this._window.window.requestAnimationFrame(this._boundFrame);
  }

  /**
   * The main method called from the `requestAnimationFrame` function. This method will run the
   * first registered batch. If there are any more batches, a new frame is requested to run the next
   * batch.
   */
  private _frame(): void {
    // This frame is no longer pending, so unset the variable
    this._animationFrame = undefined;

    // Get the first batch and run it
    const [batch] = this._batches;

    batch.run();

    /*
     * Only now remove the first batch from the `_batches`. That way, if the first batch is also the
     * last batch, new measurements/mutations that are added from the registered
     * measurements/mutations will be run in the same frame.
     * To give an example, this will run the following measurement and mutation in the same frame:
     *
     * ```ts
     * // Syncs scrolling from someElement to someOtherElement
     * domIo.measure(() => {
     *   const {scrollTop} = someElement;
     *
     *   domIo.mutate(() => {
     *     someOtherElement.scrollTop = scrollTop;
     *   });
     * });
     * ```
     */
    this._batches.shift();

    if (batch === this._lastBatch) {
      // We've just run the last and only batch, create a new batch for future
      // measurements/mutations
      this._createBatch();
    } else {
      // There are more batches to run, schedule a new frame
      this._requestFrame();
    }
  }

  /**
   * Returns a batch that will accept a function with the given `Mode`. If needed, a new
   * batch will be created.
   *
   * @param mode The mode of the function we want to add to the batch
   */
  private _getBatch(mode: Mode): Batch {
    if (!this._lastBatch.canHandle(mode)) {
      this._createBatch();
    }

    return this._lastBatch;
  }

  private _createBatch(): void {
    const newBatch = new Batch();
    this._lastBatch = newBatch;
    this._batches.push(newBatch);
  }

  /* @Override */
  public measure<T>(fn: () => T): Promise<T> {
    this._requestFrame();

    return new Promise<T>((resolve, reject) => {
      this._getBatch(Mode.MEASURE).measure(() => {
        try {
          resolve(fn());
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  /* @Override */
  public mutate(fn: () => void): Promise<void> {
    this._requestFrame();

    return new Promise<void>((resolve, reject) => {
      this._getBatch(Mode.MUTATE).mutate(() => {
        try {
          fn();
          resolve();
        } catch (e) {
          reject(e);
        }
      });
    });
  }
}

/**
 * The `DomIoService` batches DOM operations to ensure that no synchronous layouts or style
 * recalcs are triggered. It exposes two methods: `measure` and `mutate`. These methods accept
 * functions that will be called at a point in time where it is safe to perform DOM operations.
 * Functions passed to the `measure` method can safely _measure_ the DOM, e.g. asking for
 * `scrollTop` or `getBoundingClientRect()`. Functions passed to `mutate` can safely _mutate_ the
 * DOM, e.g. by adding/removing classes or elements or setting styles.
 *
 * Consecutive calls to `measure` and `mutate` are guaranteed to run in the order they're
 * registered. There is no other guarantee with regards to timing. It could very well be that
 * consecutive calls are called synchronously after one another, but they might just as well be
 * called in separate frames.
 *
 * Both methods return a promise that gets resolved once the function is executed. The `measure`
 * function exposes the return value of the function via the promise, `mutate` doesn't  do that
 * (because it is not meant to _get_ anything).
 * It's safe to register new `measure` or `mutate` functions inside a `measure` or `mutate`
 * function.
 *
 * The `DomIoService` should always be used when measuring or mutating the DOM, _except_ in the
 * set-up phase of an animation. In the set-up of an animation it is often necessary to trigger
 * (multiple) synchronous layouts to do calculations. This is not an issue, as the RAIL model allows
 * the set-up of animations to take up to 100ms.
 *
 * This service should not be used to throttle services using frames, use the `FrameThrottleService`
 * for that.
 *
 * @see https://developers.google.com/web/fundamentals/performance/rail
 * @see FrameThrottleService
 */
@Injectable({
  providedIn: 'root',
  useExisting: AnimationFrameDomIoService,
})
export abstract class DomIoService {
  /**
   * Calls the given function at a point in time when it is safe to perform measurements in the DOM,
   * without having to worry about causing synchronous layouts or style recalcs.
   *
   * The returned promise is resolved once the measurement is performed. The promise will be
   * resolved with the return value of the measurement function, if any.
   *
   * Multiple calls to `measure` and `mutate` are guaranteed to run in order.
   *
   * @param fn The measurement function to call
   */
  public abstract measure<T>(fn: () => T): Promise<T>;

  /**
   * Calls the given function at a point in time when it is safe to mutate the DOM. That is: there
   * won't be any measurements in the DOM until the next frame.
   *
   * The returned function is resolved once the mutation function is called.
   *
   * Multiple calls to `measure` and `mutate` are guaranteed to run in order.
   *
   * @param fn The function to call
   */
  public abstract mutate(fn: () => void): Promise<void>;
}
