import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

/**
 * The document group consists of a title, text and one or more document actions.
 * Optionally the document group can be displayed with a divider, determinated by screen design.
 * We use buttons for actions on the page which don't perform a location navigation, e.g. opening the document preview,
 * otherwise anchors are used.
 *
 * Example usage:
 *
 * @example
 * <maia-document-group withDivider>
 *   <h4 maia-document-group-title l18n="@@sub-system-label-id">Document title</h4>
 *   <p maia-document-group-text l18n="@@sub-system-label-id">Here comes the maia document text</p>
 *   <ul>
 *     <li><a maiaDocumentAction href="https://kbc.be/" target="_blank" l18n="@@sub-system-label-id"> Link 1</a></li>
 *     <li><a maiaDocumentAction href="https://kbc.be/" target="_blank" l18n="@@sub-system-label-id"> Link 2</a></li>
 *     <li><a href="https://kbc.be/" target="_blank" l18n="@@sub-system-label-id"> Normal link 1</a></li>
 *     <li>
 *       <button maiaActionWithIcon (click)="performAction()">
 *         <svg ivyMaiaAlertFuncInfoCircle maia-action-icon></svg>
 *         <span l18n="@@sub-system-label-id">Use a button inside the document group for performing an action on the current page</span>
 *       </button>
 *     </li>
 *   </ul>
 * </maia-document-group>
 *
 * @ngModule ActionsModule
 */
@Component({
  selector: 'maia-document-group',
  templateUrl: './document-group.component.html',
  styleUrls: ['./document-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentGroupComponent {
  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('class.maia-document-group--with-divider')
  public withDivider = false;
}
