import {coerceBooleanProperty, coerceNumberProperty} from '@angular/cdk/coercion';
import {Boolean, BusinessType, Date, Decimal, DecimalUtils, Text} from '@atlas/businesstypes';

import {TypedPropertyDecorator} from './interface';
import {wrapPropertySetter} from './util';

/*
 * It is of the utmost importance that functions used to decorate properties contain more than
 * just a return statement.
 * This is why all decorator functions here follow the format
 *
 * ```ts
 * const result = doStuff();
 * return result;
 * ```
 *
 * instead of
 *
 * ```ts
 * return doStuff();
 * ```
 *
 * Why?
 *
 * Angular's AOT compiler will try to inline decorators that are simple return statements. This
 * means it will start complaining if you're using lambda or if you're using non-exported functions,
 * both of which are true for the decorators listed in this file.
 *
 * We prevent this inlining by ensuring our decorators don't look like "macro functions".
 *
 * Fore more info: https://github.com/angular/angular/pull/21708/files, line 1710 in file
 * packages/compiler-cli/test/ngc_spec.ts
 */
/* eslint-disable sonarjs/prefer-immediate-return */
/**
 * Decorates a property to coerce its value into a boolean primitive
 *
 * This wraps the property with `@angular/cdk`'s `coerceBooleanProperty`.
 */
export function coerceBooleanPrimitive(): TypedPropertyDecorator<boolean> {
  const result = wrapPropertySetter(coerceBooleanProperty);
  return result;
}

/**
 * Decorates a property to coerce its value into a number primitive
 *
 * This wraps the property with `@angular/cdk`'s `coerceNumberProperty`.
 */
export function coerceNumberPrimitive(): TypedPropertyDecorator<number>;
/**
 * Decorates a property to coerce its value into a number primitive
 *
 * This wraps the property with `@angular/cdk`'s `coerceNumberProperty`.
 *
 * @param fallback The fallback to use if the property is set to a non-coercible value
 */
export function coerceNumberPrimitive<D>(fallback: D): TypedPropertyDecorator<number | D>;
export function coerceNumberPrimitive(fallback = 0): TypedPropertyDecorator<number> {
  const result = wrapPropertySetter(value => coerceNumberProperty(value, fallback));
  return result;
}

function createBusinesstypePropertySetter<T extends BusinessType<any>, D>(
  isBusinesstype: (value: any) => value is T,
  coerce: (value: string) => T,
  fallback: D,
): (value: T | D) => T | D {
  return (value: T | D): T | D => {
    if (isBusinesstype(value)) {
      return value;
    }

    if (value == null || (value as unknown) === '') {
      return fallback;
    }

    return (coerce as (value: string) => T)(`${value}`);
  };
}

/**
 * Coerce the property to instances of `Boolean`
 */
/* eslint-disable-next-line @typescript-eslint/ban-types */
export function coerceBoolean(): TypedPropertyDecorator<Boolean> {
  const result = wrapPropertySetter(
    createBusinesstypePropertySetter(
      Boolean.isBoolean,
      value => new Boolean(coerceBooleanProperty(value)),
      Boolean.TRUE,
    ),
  );
  return result;
}

/**
 * Coerce the property to instances of `Text`.
 */
export function coerceText(): TypedPropertyDecorator<Text>;
/**
 * Coerce the property to instances of `Text`, falling back to the given `fallback` if the input is
 * empty.
 *
 * @param fallback The fallback value if the given value is empty
 */
export function coerceText<D>(fallback: D): TypedPropertyDecorator<Text | D>;
export function coerceText(fallback = new Text('')): TypedPropertyDecorator<Text> {
  const result = wrapPropertySetter(
    createBusinesstypePropertySetter(Text.isText, value => new Text(value), fallback),
  );
  return result;
}

/**
 * Coerce the property to instances of `Date`.
 */
export function coerceDate(): TypedPropertyDecorator<Date | undefined>;
/**
 * Coerce the property to instances of Date, falling back to the given `fallback` if the input is
 * empty.
 *
 * @param fallback The fallback value if the given value is empty
 */
export function coerceDate<D>(fallback: D): TypedPropertyDecorator<Date | D>;
export function coerceDate(fallback = undefined): TypedPropertyDecorator<Date | undefined> {
  const result = wrapPropertySetter(
    createBusinesstypePropertySetter(Date.isDate, value => new Date(value), fallback),
  );
  return result;
}

/**
 * Coerce the property to instances of `Decimal`.
 */
export function coerceDecimal(): TypedPropertyDecorator<Decimal>;
/**
 * Coerce the property to instances of `Decimal`, falling back to the given `fallback` if the input
 * is empty.
 *
 * @param fallback The fallback value if the given value is empty
 */
export function coerceDecimal<D>(fallback: D): TypedPropertyDecorator<Decimal | D>;
export function coerceDecimal(fallback = DecimalUtils.ZERO): TypedPropertyDecorator<Decimal> {
  const result = wrapPropertySetter(
    createBusinesstypePropertySetter(Decimal.isDecimal, value => new Decimal(value), fallback),
  );
  return result;
}
