import {NgModule} from '@angular/core';
import {ModalsModule} from '@maia/modals';
import {SpinnersModule} from '@maia/spinners';

import {LoadingOverlayController} from './controller/loading-overlay-controller.service';
import {LoadingOverlayContentComponent} from './loading-overlay-content/loading-overlay-content.component';
import {LoadingOverlayComponent} from './loading-overlay.component';

@NgModule({
  imports: [ModalsModule, SpinnersModule],
  declarations: [LoadingOverlayComponent, LoadingOverlayContentComponent],
  entryComponents: [LoadingOverlayComponent, LoadingOverlayContentComponent],
  providers: [LoadingOverlayController],
})
export class LoadingOverlayModule {}
