import {Directive, Inject, OnInit, ViewContainerRef} from '@angular/core';

import {
  MODAL_CONTENT_DESCRIPTION,
  ModalContentDescription,
  ModalTemplateContext,
} from '../interfaces/modal-content-description';
import {ModalContentComponent} from '../interfaces/modal-content.component';
import {ModalReady} from '../interfaces/modal-ready';

/**
 * @ngModule ModalsModule
 */
@Directive({
  selector: '[maiaRenderModal]',
})
export class RenderModalDirective<O, C extends ModalTemplateContext<O, any>> implements OnInit {
  public constructor(
    private readonly _viewContainer: ViewContainerRef,
    private readonly _modalReady: ModalReady,
    @Inject(MODAL_CONTENT_DESCRIPTION)
    private readonly _content: ModalContentDescription<O, any, C>,
  ) {}

  public ngOnInit(): void {
    let contentComponent: ModalContentComponent<unknown> | undefined;
    if ('factory' in this._content) {
      contentComponent = this._viewContainer.createComponent(
        this._content.factory,
        undefined,
        this._content.injector,
      ).instance;
    } else {
      this._viewContainer.createEmbeddedView(this._content.template, this._content.templateContext);
    }

    this._modalReady.markReady(contentComponent);
  }
}
