import {ApplicationRef, ElementRef, Injectable} from '@angular/core';
import {filter, map, take} from 'rxjs/operators';

/**
 * The `ElementRef` for the application's root element.
 */
@Injectable({providedIn: 'root'})
export class ApplicationElementRef extends ElementRef<Promise<HTMLElement>> {
  // This service is quite brittle and might break in future angular releases. However, this is the
  // only way to get to the application's root element without requiring changes to the applications
  // (e.g. by providing a token that they need to fill with the app instance or something similar)
  //
  // More info:
  // - https://stackoverflow.com/a/35148595
  // - https://github.com/angular/angular/issues/6446

  // istanbul ignore next This is hard to test for obvious reasons
  public constructor(app: ApplicationRef) {
    // This is _NOT_ the same as ApplicationInitStatus.donePromise, which fires before the
    // app.components array is filled.
    const isReady = app.isStable
      .pipe(
        filter(stable => stable),
        map(() => app.components[0]),
        filter(component => component != null),
        take(1),
      )
      .toPromise();

    super(isReady.then(component => component.location.nativeElement));
  }
}
