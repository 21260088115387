import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';
import {CollapsiblesModule} from '@maia/collapsibles';
import {FormsModule} from '@maia/forms';
import {μSwitchesSvgModule} from '@maia/icons';

import {CheckboxGroupComponent} from './checkbox-group/checkbox-group.component';
import {CheckboxComponent} from './checkbox/checkbox.component';
import {CollapsibleCheckboxGroupFormElementComponent} from './collapsible-checkbox-group-form-element/collapsible-checkbox-group-form-element.component';
import {CollapsibleCheckboxComponent} from './collapsible-checkbox/collapsible-checkbox.component';
import {ElementWithNgControlDirective} from './element.directive';
import {SwitchGroupComponent} from './switch-group/switch-group.component';
import {SwitchComponent} from './switch/switch.component';

@NgModule({
  imports: [BusinessTypesModule, CollapsiblesModule, CommonModule, FormsModule, μSwitchesSvgModule],
  declarations: [
    CheckboxComponent,
    CheckboxGroupComponent,
    CollapsibleCheckboxComponent,
    CollapsibleCheckboxGroupFormElementComponent,
    SwitchComponent,
    SwitchGroupComponent,
    ElementWithNgControlDirective,
  ],
  exports: [
    CheckboxComponent,
    CheckboxGroupComponent,
    CollapsibleCheckboxComponent,
    CollapsibleCheckboxGroupFormElementComponent,
    SwitchComponent,
    SwitchGroupComponent,
    ElementWithNgControlDirective,
  ],
})
export class SwitchesModule {}
