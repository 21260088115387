import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {DocumentRef} from '@atlas-angular/cdk/globals';

import {HeaderInterface} from './header.interface';
import {HeaderController} from './services/controller/header-control.service';
import {BifrostHeaderVariant} from './variants/header-variant';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

/**
 * The potential color schemes
 */
export const enum ColorScheme {
  DEFAULT = 'default',
  LIGHT = 'light',
  DARK = 'dark',
}

/**
 * @ngModule HeaderModule
 */
@Component({
  selector: 'bifrost-header, header[bifrostHeader]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements HeaderInterface, OnInit, OnDestroy {
  private _scrollStateAtTop: boolean;

  private _canCollapse: boolean;

  private _colorScheme: ColorScheme = ColorScheme.DEFAULT;

  @Input()
  public set colorScheme(colorScheme: ColorScheme) {
    this._colorScheme = colorScheme;
  }

  @Input()
  @coerceBooleanPrimitive()
  public showBadge = false;

  public get colorScheme() {
    return this._colorScheme;
  }

  @HostBinding('class.bifrost-header--with-background')
  public get hasBackground(): boolean {
    return this.variant.hasBackground;
  }

  @HostBinding('class.bifrost-header--shadow')
  public get applyShadow(): boolean {
    return !this._scrollStateAtTop;
  }

  @HostBinding('class.p-bifrost-header--collapsed')
  @HostBinding('class.bifrost-header--collapsed')
  public get isCollapsed(): boolean {
    return this._canCollapse && !this._scrollStateAtTop;
  }

  @HostBinding('class.bifrost-header--authenticated')
  public get isAuthenticated(): boolean {
    return !this.variant.isPublic;
  }

  @HostBinding('class.bifrost-header--public')
  public get isPublic(): boolean {
    return this.variant.isPublic;
  }

  @HostBinding('class.bifrost-header--color-scheme-light')
  public get hasLightColorScheme(): boolean {
    return this._colorScheme === ColorScheme.LIGHT;
  }

  @HostBinding('class.bifrost-header--with-flow-progress')
  public get showFlowProgress(): boolean {
    return this._controller.showFlowProgress;
  }

  @Output()
  public applyExtraAction: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public goBack: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public openLiveHelp: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public openKate: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public closeFlow: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('headerSectionMain')
  private readonly _headerSectionMain: ElementRef<HTMLElement>;

  public constructor(
    private readonly _controller: HeaderController,
    private readonly _documentRef: DocumentRef,
    public readonly variant: BifrostHeaderVariant,
  ) {}

  public ngOnInit(): void {
    this._controller.setHeader(this);
    this._canCollapse = this.showFlowProgress && this.variant.isCollapsible;
  }

  public ngOnDestroy(): void {
    this._controller.unsetHeader();
  }

  public canGoBack(): boolean {
    return this.showBackButton;
  }

  /**
   * The callback handler provided to the waypoint that acts as a placeholder of the header,
   * triggered when header is fully visible.
   */
  public setStateScrollAtTop(): void {
    this._scrollStateAtTop = true;
  }

  /**
   * The callback handler provided to the waypoint that acts as a placeholder of the header,
   * triggered when header isn't fully visible.
   *
   * If the header is collapsible, we first check if the document has enough height to actually
   * collapse. This due the fact that we otherwise end up in an endless expand/collapse loop.
   */
  public unsetStateScrollAtTop(): void {
    this._canCollapse = this.showFlowProgress && this.variant.isCollapsible;
    if (this._canCollapse) {
      const {documentElement} = this._documentRef.document;
      if (documentElement) {
        /**
         * This code block forces layout / reflow
         * More information: https://gist.github.com/paulirish/5d52fb081b3570c81e3a
         *
         * TODO: This should be solved by implementing the DomIoService (BIFROST-130)
         */
        const {scrollHeight, clientHeight} = documentElement;
        const {clientHeight: minimalHeightDiff} = this._headerSectionMain.nativeElement;
        this._canCollapse = scrollHeight - clientHeight > minimalHeightDiff;
      } else {
        this._canCollapse = false;
      }
    }

    this._scrollStateAtTop = false;
  }

  public get showBackButton(): boolean {
    return this._controller.showBackButton;
  }

  public get showCloseButton(): boolean {
    return this._controller.showCloseButton;
  }

  public get showLiveHelpButton(): boolean {
    return this._controller.showLiveHelpButton;
  }

  public onMainCloseFlow(): void {
    this.closeFlow.emit();
  }
}
