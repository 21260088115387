import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef} from '@angular/core';

import {FlowProgressComponent} from '../flow-progress.component';

import {Step} from './step';

/**
 * Component representing a single step in the flow progress.
 *
 * @ngModule FlowProgressModule
 */
@Component({
  selector: 'maia-flow-progress-step',
  template: '',
  styleUrls: ['./step.component.scss'],

  inputs: ['active', 'disabled', 'title'],

  outputs: ['activeChange'],

  changeDetection: ChangeDetectionStrategy.OnPush,

  // With this provider we can use @ContentChildren(Step) in the FlowProgressComponent
  providers: [{provide: Step, useExisting: forwardRef(() => StepComponent)}],
})
export class StepComponent extends Step {
  public progress = 0;

  public constructor(
    private readonly _container: FlowProgressComponent,
    changeDetector: ChangeDetectorRef,
  ) {
    super(changeDetector);
  }

  // Step API

  protected _setInputActive(active: boolean): void {
    this._container.updateActive(this, active);
  }

  public matches(step: Step | null | undefined): boolean {
    return this === step;
  }
}
