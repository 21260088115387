import {coerceNumberProperty} from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Optional,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Date, DateKey, DateUtils, DayOfMonth, DayOfWeek, Month} from '@atlas/businesstypes';
import {CssClassUtility, CssClassUtilityFactory} from '@maia/core';

import {fromEvent, of, Subscription} from 'rxjs';
import {startWith} from 'rxjs/operators';

import {DateRange} from '../../daterange';
import {DaterangepickerComponent} from '../../pickers/daterangepicker/daterangepicker.component';
import {
  calculateWeekDay,
  DAY_CLASSES,
  DISABLED_DAY_DECIDER,
  DisabledDayDecider,
  DOW_CLASSES,
  watchQueryListChanges,
} from '../daypicker/daypicker.util';

const CLASSES = {
  dow: DOW_CLASSES,
  days: DAY_CLASSES,
};

/**
 * A Rangepicker shows the days of the month and allows selecting a range of values.
 *
 * Days can be highlighted, e.g. to highlight today.
 *
 * Days can be disabled using `DisabledDayDecider`s, which are injected using the
 * `DISABLED_DAY_DECIDER` token.
 *
 * @ngModule DatePickersModule
 */
@Component({
  selector: 'maia-rangepicker',
  templateUrl: './rangepicker.component.html',
  styleUrls: ['./rangepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RangepickerComponent), multi: true},
  ],
})
@UntilDestroy()
export class RangepickerComponent
  implements ControlValueAccessor, OnDestroy, AfterViewInit, OnChanges {
  private _selectedDateRange: DateRange = new DateRange();

  // input properties

  private _month: Month;
  private _year: number;

  private _highlightDays: number[] = [];

  // Generated properties based on input

  private _firstWeekDay: DayOfWeek;
  private _numberOfDays: number;

  // Utilities

  @ViewChild('list')
  private readonly _listElement?: ElementRef = undefined;

  @ViewChildren('maiaDay')
  private readonly _maiaDays: QueryList<ElementRef>;

  private _classUtility?: CssClassUtility<typeof CLASSES> = undefined;

  private _disabledDayDeciders: DisabledDayDecider[];

  private _dayMouseEventSubscriptions: Subscription;

  // ControlValueAccessor properties

  // istanbul ignore next: provided by [(ngModel)]
  private _onChange = (range: DateRange) => {};
  // istanbul ignore next: provided by [(ngModel)]
  private _onTouch = () => {};

  public constructor(
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly _renderer: Renderer2,
    private ngZone: NgZone,
    private readonly _classUtilityFactory: CssClassUtilityFactory,
    @Optional() dateRangePicker?: DaterangepickerComponent,
    @Optional() @Inject(DISABLED_DAY_DECIDER) disabledDayDeciders?: DisabledDayDecider[],
  ) {
    const detectChanges = () => this._changeDetector.detectChanges();

    if (disabledDayDeciders == null) {
      // Optional dependencies get injected as null, but we can't use default parameters as those
      // work on undefined
      disabledDayDeciders = [];
    }

    if (dateRangePicker != null) {
      this._disabledDayDeciders = [dateRangePicker as DisabledDayDecider].concat(
        disabledDayDeciders,
      );
    } else {
      this._disabledDayDeciders = disabledDayDeciders;
    }

    for (const decider of this._disabledDayDeciders) {
      decider.registerOnUpdate(detectChanges);
    }
  }

  /**
   * The number of days to show.
   */
  @Input()
  public set month(value: Month) {
    this._month = coerceNumberProperty(value) as Month;
  }

  public get numberOfDays(): number {
    return this._numberOfDays;
  }

  /**
   * The day of the week for the first day of the month, where 1 is Monday and 7 is Sunday.
   */
  @Input()
  public set year(value: number) {
    this._year = coerceNumberProperty(value);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.year == null && changes.month == null) {
      return;
    }

    const startOfMonth = DateUtils.unserialize({
      [DateKey.Year]: this._year,
      [DateKey.Month]: this._month,
      [DateKey.Day]: 1,
    });

    this._firstWeekDay = DateUtils.get(startOfMonth, DateKey.DayOfWeek);
    this._numberOfDays = DateUtils.getDaysInMonth(startOfMonth);

    if (this._classUtility != null) {
      this._classUtility.setValue('dow', `${this._firstWeekDay}` as any);
      this._classUtility.setValue('days', `${this._numberOfDays}` as any);
    }
  }

  /**
   * The day(s) to highlight.
   */
  @Input()
  public set highlightDays(value: number | number[] | null | undefined) {
    if (value == null) {
      value = [];
    }

    this._highlightDays = (Array.isArray(value) ? value : [value]).map(nb =>
      coerceNumberProperty(nb),
    );
  }

  /**
   * Whether or not the given day is selected.
   */
  public isSelected(day: number): boolean {
    const {start, end} = this._selectedDateRange;

    const date = DateUtils.unserialize({
      day: day as DayOfMonth,
      month: this._month,
      year: this._year,
    });

    return date.equals(start) || date.equals(end);
  }

  public isInDateRange(day: number): boolean {
    if (this._selectedDateRange.isValid()) {
      const unserializedDay = DateUtils.unserialize({
        day: day as DayOfMonth,
        month: this._month,
        year: this._year,
      });

      return this._selectedDateRange.isInDateRange(unserializedDay);
    }
    return false;
  }

  /**
   * Whether or not the given day is disabled.
   *
   * This function goes through the registered `DisabledDayDecider`s. The first decider that returns
   * a non-null value for the day decides whether it's disabled or not. If no decider makes a
   * decision, the day is not disabled.
   */
  public isDisabled(day: number): boolean {
    const dayOfWeek = calculateWeekDay(day, this._firstWeekDay);

    for (const decider of this._disabledDayDeciders) {
      const result = decider.isDisabled(day, dayOfWeek, this._month, this._year);

      if (result != null) {
        return result;
      }
    }

    return false;
  }

  /**
   * Whether or not the given day is disabled.
   */
  public isHighlighted(day: number): boolean {
    return this._highlightDays.indexOf(day) > -1;
  }

  /**
   * Selects the given day if it isn't disabled. This updates the value of `[(ngModel)]`.
   */
  public selectDay(day: number): void {
    const dateToSelect = DateUtils.unserialize({
      day: day as DayOfMonth,
      month: this._month,
      year: this._year,
    });

    if (
      this.isDisabled(day) ||
      (dateToSelect.equals(this._selectedDateRange.start) && !this._selectedDateRange.isValid())
    ) {
      return;
    }

    if (this._selectedDateRange.isValid()) {
      this._resetSelection();
    }

    if (!this._selectedDateRange.start) {
      this.updateDateRange(dateToSelect, 'start');
    } else if (DateUtils.isAfter(this._selectedDateRange.start, dateToSelect)) {
      // Make sure start date comes before end date
      this.updateDateRange(this._selectedDateRange.start, 'end');
      this.updateDateRange(dateToSelect, 'start');
    } else {
      this.updateDateRange(dateToSelect, 'end');
    }

    this._onChange(this._selectedDateRange);
  }

  public ngOnDestroy() {
    this._onTouch();

    for (const decider of this._disabledDayDeciders) {
      decider.registerOnUpdate(undefined);
    }
  }

  public ngAfterViewInit(): void {
    this._classUtility = this._classUtilityFactory.create(
      CLASSES,
      this._renderer,
      this._listElement!,
    );
    this._classUtility.setValue('dow', `${this._firstWeekDay}` as any);
    this._classUtility.setValue('days', `${this._numberOfDays}` as any);

    // Angular triggers Change detection of every parent element on every registered mouse event
    // This piece of code registeres listeners outside of Angular, which prevents CD from triggering
    // unnecessarily
    // --- replaces the mouse eventlisteners used in the template
    this._dayMouseEventSubscriptions = new Subscription();

    of(this._maiaDays)
      .pipe(watchQueryListChanges(), startWith(this._maiaDays), takeUntilDestroyed(this))
      .subscribe(days => {
        this._dayMouseEventSubscriptions.unsubscribe();
        this._dayMouseEventSubscriptions = new Subscription();

        this.ngZone.runOutsideAngular(() => {
          days.forEach((maiaDay: ElementRef) => {
            this._dayMouseEventSubscriptions.add(
              fromEvent(maiaDay.nativeElement, 'mouseenter')
                .pipe(takeUntilDestroyed(this))
                .subscribe(
                  (event: Event) =>
                    !this.isSelected(+maiaDay.nativeElement.innerText) &&
                    !this.isDisabled(+maiaDay.nativeElement.innerText) &&
                    this.addHoverState(event.target as EventTarget),
                ),
            );
            this._dayMouseEventSubscriptions.add(
              fromEvent(maiaDay.nativeElement, 'mouseleave')
                .pipe(takeUntilDestroyed(this))
                .subscribe((event: Event) => this.removeHoverState(event.target as EventTarget)),
            );
          });
        });
      });
  }

  // ControlValueAccessor API

  public writeValue(obj: any): void {
    const value = DateRange.isDateRange(obj) ? obj : null;

    const current = this._selectedDateRange;

    if (value == null) {
      this._selectedDateRange = new DateRange();
      if (current != null) {
        this._changeDetector.detectChanges();
      }

      return;
    }

    if (value !== current) {
      this._selectedDateRange = value;
      this._changeDetector.detectChanges();
    }
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  /**
   * Checks if the selected daterange is a valid range, consisting of 2 Dates
   */
  public validDateRangeSelectionActive(): boolean {
    return this._selectedDateRange.isValid();
  }

  /**
   * Assigns new DateRange to _selectedDateRange from the selected start date and end date
   */
  private updateDateRange(date: Date, key: 'start' | 'end'): void {
    this._selectedDateRange[key] = date;

    this._changeDetector.detectChanges();
  }

  /**
   * Removes the current selection
   * @private
   */
  private _resetSelection() {
    this._selectedDateRange = new DateRange();
  }

  /**
   * Adds hover state to maia-day
   * @param eventTarget maia-day list item element
   */
  public addHoverState(eventTarget: EventTarget) {
    if (!this._selectedDateRange.isValid() && this._selectedDateRange.start) {
      const target = eventTarget as HTMLElement;
      target.parentElement!.classList.add('child-hover-active');
    }
  }

  /**
   * Removes hover state from maia-day
   * @param eventTarget maia-day list item element
   */
  public removeHoverState(eventTarget: EventTarget) {
    if (this._selectedDateRange.start) {
      const target = eventTarget as HTMLElement;
      target.parentElement!.classList.remove('child-hover-active');
    }
  }

  /**
   * Checks if a selection is present in a month previous to the current month
   */
  public selectionInPrevMonths(): boolean {
    if (!this._selectedDateRange.start) {
      return false;
    }

    const selectedStartMonth = DateUtils.get(this._selectedDateRange.start, DateKey.Month);
    const selectedStartYear = DateUtils.get(this._selectedDateRange.start, DateKey.Year);

    return (
      (selectedStartMonth < this._month && !(selectedStartYear > this._year)) ||
      selectedStartYear < this._year
    );
  }

  /**
   * Checks if a selection is present in a month after the current month
   */
  public selectionInNextMonths(): boolean {
    if (!this._selectedDateRange.start) {
      return false;
    }

    const selectedStartMonth = DateUtils.get(this._selectedDateRange.start, DateKey.Month);
    const selectedStartYear = DateUtils.get(this._selectedDateRange.start, DateKey.Year);

    return (
      (selectedStartMonth > this._month && !(selectedStartYear < this._year)) ||
      selectedStartYear > this._year
    );
  }

  public isSelectedDate(day: number, req: 'first' | 'last') {
    return DateUtils.unserialize({
      day: day as DayOfMonth,
      month: this._month,
      year: this._year,
    }).equals(this.getDateRangeDate(req));
  }

  private getDateRangeDate(req: 'first' | 'last'): Date | null {
    const {start, end} = this._selectedDateRange;

    if (start == null || (req === 'last' && end == null)) {
      return null;
    }

    if (req === 'first' && end == null) {
      return start;
    }

    const orderedDates =
      DateUtils.isBefore(start, end!) || DateUtils.isSame(start, end!)
        ? [start, end]
        : [end, start];
    return orderedDates[req === 'first' ? 0 : 1];
  }
}
