import {Directive, forwardRef, Inject} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Date, DateFormatter, isValidDate} from '@atlas/businesstypes';

import {
  ATLAS_VALUE_ACCESSOR,
  AtlasControlValueAccessor,
  BaseBusinesstypeDirective,
} from '../base/index';

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasDate]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateDirective),
    },
  ],
})
export class DateDirective extends BaseBusinesstypeDirective {
  public constructor(@Inject(ATLAS_VALUE_ACCESSOR) input: AtlasControlValueAccessor) {
    super(input);
  }

  public writeValue(value?: Date | string): void {
    this._input.writeValue(
      Date.isDate(value) ? DateFormatter.format(value) : value == null ? '' : value,
    );
  }

  public registerOnChange(fn: (_: Date | string | null) => void): void {
    this._input.registerOnChange(value => {
      if (value === '') {
        fn(null);
        return;
      }

      const unformatted = DateFormatter.unformat(value);
      fn(isValidDate(unformatted) ? new Date(unformatted) : unformatted);
    });
  }
}
