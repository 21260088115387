import { NgModule } from '@angular/core';

import { RecaptchaComponent } from './recaptcha.component';

@NgModule({
  declarations: [
    RecaptchaComponent,
  ],
  exports: [
    RecaptchaComponent,
  ],
})
export class RecaptchaCommonModule {
}
