import {ChangeDetectionStrategy, Component, Input, Optional} from '@angular/core';
import {TileComponent} from '../tile/tile.component';

/**
 * Tile item component
 *
 * @ngModule TilesModule
 */
@Component({
  selector: 'maia-tile-amount-display',
  templateUrl: './tile-amount-display.component.html',
  styleUrls: ['./tile-amount-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileAmountDisplayComponent {
  @Input()
  public label?: string;

  public constructor(@Optional() parent?: TileComponent) {
    if (parent === null) {
      throw new Error(
        'Cannot create a <maia-tile-amount-display /> component out of <maia-tile /> component',
      );
    }
  }
}
