import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';

import {AvatarComponent} from '../abstract-avatar.component';

@Component({
  selector: 'maia-avatar-image',
  templateUrl: './avatar-image.component.html',
  styleUrls: ['./avatar-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: AvatarComponent,
      useExisting: forwardRef(() => AvatarImageComponent),
    },
  ],
})
export class AvatarImageComponent extends AvatarComponent {}
