import {BusinessType, getInternalValue} from './businesstype';
import {
  AtlasMaxLengthValidationErrors,
  AtlasMinLengthValidationErrors,
  RESULT_VALID,
  Validator,
  ValidatorInput,
  ValidValidationResult,
} from './validators';

/**
 * Creates a validator for checking that input values contain at least X characters.
 *
 * @param limit The limit to create a validator for
 * @return The validator
 */
export function baseMinLength<BT extends BusinessType<string>>(
  limit: number,
): Validator<BT, AtlasMinLengthValidationErrors> {
  if (limit < 0 || isNaN(limit)) {
    throw new Error(`Can only create minLength validators for non-negative numbers, not ${limit}`);
  }

  if (limit === 0) {
    return function(): ValidValidationResult {
      return RESULT_VALID;
    };
  }

  function createInvalidResult(actualValue: number): AtlasMinLengthValidationErrors {
    return {atlasMinLength: {actualValue, limit}};
  }

  return function(
    input: ValidatorInput<BT>,
  ): ValidValidationResult | AtlasMinLengthValidationErrors {
    if (!input) {
      return createInvalidResult(0);
    }

    const {length} = input instanceof BusinessType ? getInternalValue<string>(input) : input;

    if (length >= limit) {
      return RESULT_VALID;
    }

    return createInvalidResult(length);
  };
}

/**
 * Creates a validator for checking that input values contain at most X characters.
 *
 * @param limit The limit to create a validator for
 * @return The validator
 */
export function baseMaxLength<BT extends BusinessType<string>>(
  limit: number,
): Validator<BT, AtlasMaxLengthValidationErrors> {
  if (limit < 0 || isNaN(limit)) {
    throw new Error(`Can only create maxLength validators for non-negative numbers, not ${limit}`);
  }

  return function(
    input: ValidatorInput<BT>,
  ): ValidValidationResult | AtlasMaxLengthValidationErrors {
    if (!input) {
      return RESULT_VALID;
    }

    const {length} = input instanceof BusinessType ? getInternalValue<string>(input) : input;

    if (length <= limit) {
      return RESULT_VALID;
    }

    return {
      atlasMaxLength: {
        limit,
        actualValue: length,
      },
    };
  };
}
