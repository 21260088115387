import {Directive, Input, OnInit, Self} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';

import {filter} from 'rxjs/operators';

import {Step} from '../step/step';
import {StepContainerDirective} from './step-container.directive';

/**
 * Hooks the flow-progress step into the Angular router to automatically activate the step if the
 * matching route becomes active
 *
 * @ngModule FlowProgressWithRoutingModule
 */
@Directive({
  selector: 'maia-flow-progress-step[routeId], maia-flow-progress-invisible-step[routeId]',
})
@UntilDestroy()
export class StepRouteIdDirective implements OnInit {
  /**
   * The `maiaFlowProgressId` of the route this flow-progress step matches
   */
  @Input()
  public routeId: string;

  public constructor(
    private readonly container: StepContainerDirective,
    @Self() private readonly step: Step,
  ) {}

  public ngOnInit() {
    this.container.activeRouteId
      .pipe(
        takeUntilDestroyed(this),
        filter(id => id === this.routeId),
      )
      .subscribe(() => (this.step.active = true));
  }
}
