import {Directive, OnInit, Optional, SkipSelf} from '@angular/core';
import {NgControl} from '@angular/forms';
import {UntilDestroy, onDestroy} from '@atlas-angular/rxjs';
import {InputContainer} from '@maia/forms';

/**
 * @ngModule InputWithSelectModule
 */
@Directive({
  selector:
    // tslint:disable-next-line:directive-selector This directive extends the InputWithSelect
    'maia-input-with-select[ngModel], maia-input-with-select[formControl], maia-input-with-select[formControlName]',
})
@UntilDestroy()
export class InputWithSelectWithControlDirective implements OnInit {
  public constructor(
    private readonly _ngControl: NgControl,
    @Optional() @SkipSelf() private readonly _container?: InputContainer,
  ) {}

  public ngOnInit(): void {
    if (this._container != null) {
      const destroyFn = this._container.registerFormControl(this._ngControl);
      onDestroy(this).subscribe(destroyFn);
    }
  }
}
