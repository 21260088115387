import {Injectable} from '@angular/core';

/**
 * The active company
 *
 * The company can be injected into your code to access the name and/or code of the currently active
 * company.
 */
@Injectable()
export class Company {
  /**
   * The company code, e.g. `'0001'` for KBC
   */
  public readonly code: string;

  /**
   * The company name, e.g. 'KBC'
   */
  public readonly name: string;
}
