import {FocusOrigin} from '@angular/cdk/a11y';
import {Directive, Input, Optional, QueryList, TemplateRef} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {DropdownTemplateContext} from '@maia/dropdowns';
import {InputContainer} from '@maia/forms';
import {Option, OptionContainer} from '@maia/select';

import {Observable} from 'rxjs';

@Directive()
@UntilDestroy()
export abstract class BaseInputWithSelectComponent<T>
  implements ControlValueAccessor, OptionContainer<T> {
  public abstract readonly keyEvent$: Observable<void>;

  public abstract dropdownContent: TemplateRef<DropdownTemplateContext<Option<T>>>;

  public abstract options: QueryList<Option<T>>;

  public _onTouch = () => {};

  public _onChange = (value?: any) => {};

  private _ariaLabel: string;

  protected constructor(@Optional() protected readonly _inputContainer?: InputContainer) {}

  public abstract applyInitialValue(): void;

  public abstract emitValue(): void;

  public abstract activateOption(option: Option<T>, focusOrigin?: FocusOrigin): void;

  public blur(): void {
    this._onTouch();
  }

  public get ariaLabel() {
    return this._ariaLabel;
  }

  @Input('aria-label')
  public set ariaLabel(ariaLabel: string) {
    this._ariaLabel = ariaLabel;
  }

  // ControlValueAccessor API

  public abstract writeValue(value: unknown): void;

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  public abstract setDisabledState(disabled: boolean): void;

  // /ControlValueAccessor API
}
