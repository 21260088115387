import {Directive, forwardRef} from '@angular/core';
import {NG_VALIDATORS} from '@angular/forms';
import {validateNationalRegisterNumber} from '@atlas/businesstypes';

import {AbstractValidatorDirective} from '../base/validator';

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasNationalRegisterNumber]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BaseNationalRegisterNumberValidator),
      multi: true,
    },
  ],
})
export class BaseNationalRegisterNumberValidator extends AbstractValidatorDirective {
  protected readonly validatorFn = validateNationalRegisterNumber;
}
