const INDEX_NOT_FOUND = -1;

/**
 * Removes the given element from the given array. The array is modified! Returns the array to allow
 * chaining.
 *
 * @param array The array to remove the element from
 * @param element The element to remove
 * @return The array without element (this returns the first parameter)
 */
export function arrayRemove<T>(array: T[], element: T): T[] {
  const index = array.indexOf(element);

  if (index !== INDEX_NOT_FOUND) {
    array.splice(index, 1);
  }

  return array;
}
