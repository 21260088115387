import {NgModule} from '@angular/core';

import {ProgressComponent} from './progress.component';
import {ProgressCircleComponent} from './progress-circle/progress-circle.component';

@NgModule({
  declarations: [ProgressComponent, ProgressCircleComponent],
  exports: [ProgressComponent, ProgressCircleComponent],
})
export class ProgressModule {}
