import {coerceNumberProperty} from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DateKey, DateUtils, DayOfMonth, DayOfWeek, Month} from '@atlas/businesstypes';
import {CssClassUtility, CssClassUtilityFactory} from '@maia/core';

import {DatepickerComponent} from '../../pickers/datepicker/datepicker.component';
import {
  calculateWeekDay,
  DAY_CLASSES,
  DISABLED_DAY_DECIDER,
  DisabledDayDecider,
  DOW_CLASSES,
  STATE_DAY_DECIDER,
  StateDayDecider,
} from './daypicker.util';

const CLASSES = {
  dow: DOW_CLASSES,
  days: DAY_CLASSES,
};

/**
 * A daypicker shows the days of the month and allows selecting a value.
 *
 * Days can be highlighted, e.g. to highlight today.
 *
 * Days can be disabled using `DisabledDayDecider`s, which are injected using the
 * `DISABLED_DAY_DECIDER` token.
 *
 * @ngModule DatePickersModule
 */
@Component({
  selector: 'maia-daypicker',
  templateUrl: './daypicker.component.html',
  styleUrls: ['./daypicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DaypickerComponent), multi: true},
  ],
})
export class DaypickerComponent
  implements ControlValueAccessor, OnDestroy, AfterViewInit, OnChanges {
  // The selected value, if any
  private _selected?: number = undefined;

  // input properties

  private _month: Month;
  private _year: number;

  private _highlightDays: number[] = [];

  // Generated properties based on input

  private _firstWeekDay: DayOfWeek;
  private _numberOfDays: number;

  // Utilities

  @ViewChild('list', {static: true})
  private readonly _listElement?: ElementRef = undefined;

  private _classUtility?: CssClassUtility<typeof CLASSES> = undefined;

  private _disabledDayDeciders: DisabledDayDecider[];
  private _stateDayDeciders: StateDayDecider[];

  // ControlValueAccessor properties

  // istanbul ignore next: provided by [(ngModel)]
  private _onChange = (year: number) => {};
  // istanbul ignore next: provided by [(ngModel)]
  private _onTouch = () => {};

  public constructor(
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly _renderer: Renderer2,
    private readonly _classUtilityFactory: CssClassUtilityFactory,
    @Optional() datepicker?: DatepickerComponent,
    @Optional() @Inject(DISABLED_DAY_DECIDER) disabledDayDeciders?: DisabledDayDecider[],
    @Optional() @Inject(STATE_DAY_DECIDER) stateDayDeciders?: StateDayDecider[],
  ) {
    const detectChanges = () => this._changeDetector.detectChanges();

    if (disabledDayDeciders == null) {
      // Optional dependencies get injected as null, but we can't use default parameters as those
      // work on undefined
      disabledDayDeciders = [];
    }
    if (stateDayDeciders == null) {
      // Optional dependencies get injected as null, but we can't use default parameters as those
      // work on undefined
      stateDayDeciders = [];
    }

    if (datepicker != null) {
      this._disabledDayDeciders = [datepicker as DisabledDayDecider].concat(disabledDayDeciders);
    } else {
      this._disabledDayDeciders = disabledDayDeciders;
    }

    // datepicker does not implement stateDayDecider so no need to execute previous logic for stateDayDeciders
    this._stateDayDeciders = stateDayDeciders;

    for (const decider of this._disabledDayDeciders) {
      decider.registerOnUpdate(detectChanges);
    }

    for (const decider of this._stateDayDeciders) {
      decider.registerOnUpdate(detectChanges);
    }
  }

  /**
   * The number of days to show.
   */
  @Input()
  public set month(value: Month) {
    this._month = coerceNumberProperty(value) as Month;
  }

  public get numberOfDays(): number {
    return this._numberOfDays;
  }

  /**
   * The day of the week for the first day of the month, where 1 is Monday and 7 is Sunday.
   */
  @Input()
  public set year(value: number) {
    this._year = coerceNumberProperty(value);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.year == null && changes.month == null) {
      return;
    }

    const startOfMonth = DateUtils.unserialize({
      [DateKey.Year]: this._year,
      [DateKey.Month]: this._month,
      [DateKey.Day]: 1,
    });

    this._firstWeekDay = DateUtils.get(startOfMonth, DateKey.DayOfWeek);
    this._numberOfDays = DateUtils.getDaysInMonth(startOfMonth);

    if (this._classUtility != null) {
      this._classUtility.setValue('dow', `${this._firstWeekDay}` as any);
      this._classUtility.setValue('days', `${this._numberOfDays}` as any);
    }
  }

  /**
   * The day(s) to highlight.
   */
  @Input()
  public set highlightDays(value: number | number[] | null | undefined) {
    if (value == null) {
      value = [];
    }

    this._highlightDays = (Array.isArray(value) ? value : [value]).map(nb =>
      coerceNumberProperty(nb),
    );
  }

  /**
   * Whether or not the given day is selected.
   */
  public isSelected(day: number): boolean {
    return day === this._selected;
  }

  /**
   * Whether or not the given day is disabled.
   *
   * This function goes through the registered `DisabledDayDecider`s. The first decider that returns
   * a non-null value for the day decides whether it's disabled or not. If no decider makes a
   * decision, the day is not disabled.
   */
  public isDisabled(day: number): boolean {
    const dayOfWeek = calculateWeekDay(day, this._firstWeekDay);

    for (const decider of this._disabledDayDeciders) {
      const result = decider.isDisabled(day, dayOfWeek, this._month, this._year);

      if (result != null) {
        return result;
      }
    }

    return false;
  }

  /**
   * Whether or not the given day is disabled.
   */
  public isHighlighted(day: number): boolean {
    return this._highlightDays.indexOf(day) > -1;
  }

  /**
   * Iterate over all state deciders to see if this day should have a custom state
   *
   * @param day The day of month to resolve the state classes for
   */
  public resolveStateClass(day: DayOfMonth): string {
    if (this.isDisabled(day)) {
      return '';
    }
    const stateClassPrefix = 'maia-day--state-';

    for (const decider of this._stateDayDeciders) {
      const classString = decider.resolveState(day, this._month, this._year);

      if (classString != null) {
        return `${stateClassPrefix}${classString}`;
      }
    }

    return '';
  }

  /**
   * Selects the given day if it isn't disabled. This updates the value of `[(ngModel)]`.
   */
  public selectDay(day: number): void {
    if (this.isDisabled(day)) {
      return;
    }

    this._selected = day;
    this._onChange(day);
  }

  public ngOnDestroy() {
    this._onTouch();

    for (const decider of this._disabledDayDeciders) {
      decider.registerOnUpdate(undefined);
    }
  }

  public ngAfterViewInit(): void {
    this._classUtility = this._classUtilityFactory.create(
      CLASSES,
      this._renderer,
      this._listElement!,
    );
    this._classUtility.setValue('dow', `${this._firstWeekDay}` as any);
    this._classUtility.setValue('days', `${this._numberOfDays}` as any);
  }

  // ControlValueAccessor API

  public writeValue(obj: any): void {
    const value = typeof obj === 'number' ? obj : null;

    const current = this._selected;

    if (value == null) {
      this._selected = undefined;
      if (current != null) {
        this._changeDetector.detectChanges();
      }

      return;
    }

    if (value !== current) {
      this._selected = value;
      this._changeDetector.detectChanges();
    }
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }
}
