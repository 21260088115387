import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

import {CircleStepDisplayState} from './circle-step-display-state.enum';

/**
 * @ngModule CircleStepDisplayModule
 */
@Component({
  selector: 'maia-circle-step-display',
  templateUrl: './circle-step-display.component.html',
  styleUrls: ['./circle-step-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleStepDisplayComponent {
  public step: string;

  private _state = CircleStepDisplayState.DISABLED;

  /**
   * Converts step number to a string (e.g. 2 --> '02')
   *
   * @param stepNumber
   */
  @Input()
  public set stepNumber(stepNumber: number) {
    const HIGHEST_SINGLE_DIGIT = 9;

    if (!stepNumber) {
      return;
    }

    this.step = `${stepNumber <= HIGHEST_SINGLE_DIGIT ? '0' : ''}${stepNumber}`;
  }

  @HostBinding('class.active')
  public get isActive(): boolean {
    return this._state === CircleStepDisplayState.ACTIVE;
  }
  @HostBinding('class.disabled')
  public get isDisabled(): boolean {
    return this._state === CircleStepDisplayState.DISABLED;
  }

  @HostBinding('class.completed')
  public get isCompleted(): boolean {
    return this._state === CircleStepDisplayState.COMPLETED;
  }

  @Input()
  public set state(state: CircleStepDisplayState) {
    if (state == null) {
      return;
    }
    this._state = state;
  }
}
