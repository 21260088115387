import {Pipe, PipeTransform} from '@angular/core';
import {File, FileFormatter} from '@atlas/businesstypes';

/**
 * @ngModule BusinessTypesModule
 */
@Pipe({name: 'atlasFile'})
export class FilePipe implements PipeTransform {
  public transform(value: any): string {
    return File.isFile(value) ? FileFormatter.format(value) : value;
  }
}
