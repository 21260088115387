import {InjectionToken, Provider, Type} from '@angular/core';

import {ConnectorInterceptor, CONNECTOR_INTERCEPTORS} from './interfaces/interceptor';
import {PathResolver} from './interfaces/path-resolver';
import {
  DynamicResourceUrlResolver,
  DYNAMIC_RESOURCE_URL_RESOLVERS,
} from './interfaces/resource-resolver';
import {PATH_RESOLVER_TOKEN} from './tokens/path-resolver';

/**
 * Provide the given connector interceptor class via `useClass`
 */
export function provideConnectorInterceptorClass(useClass: Type<ConnectorInterceptor>): Provider {
  return {
    provide: CONNECTOR_INTERCEPTORS,
    useClass,
    multi: true,
  };
}

/**
 * Provide a connector interceptor via the given factory
 */
export function provideConnectorInterceptorFactory(
  useFactory: () => ConnectorInterceptor,
): Provider;
/**
 * Provide a connector interceptor via the given factory with the given dependencies
 */
export function provideConnectorInterceptorFactory(
  useFactory: (...deps: any[]) => ConnectorInterceptor,
  deps: any[],
): Provider;
export function provideConnectorInterceptorFactory(
  useFactory: (...args: any[]) => ConnectorInterceptor,
  deps?: any[],
): Provider {
  return {
    provide: CONNECTOR_INTERCEPTORS,
    useFactory,
    deps,
    multi: true,
  };
}

/**
 * Provide the given connector interceptor via `useValue`
 */
export function provideConnectorInterceptorValue(useValue: ConnectorInterceptor): Provider {
  return {
    provide: CONNECTOR_INTERCEPTORS,
    useValue,
    multi: true,
  };
}

/**
 * Provide the given connector interceptor token via `useExisting`
 *
 * This function requires the token to be known to the dependency injection engine
 */
export function provideConnectorInterceptorExisting(
  useExisting: Type<ConnectorInterceptor> | InjectionToken<ConnectorInterceptor>,
): Provider {
  return {
    provide: CONNECTOR_INTERCEPTORS,
    useExisting,
    multi: true,
  };
}

/**
 * Provide the given path resolver class via `useClass`
 */
export function providePathResolverClass(useClass: Type<PathResolver>): Provider {
  return {
    provide: PATH_RESOLVER_TOKEN,
    useClass,
    multi: true,
  };
}

/**
 * Provide a path resolver via the given factory
 */
export function providePathResolverFactory(useFactory: () => PathResolver): Provider;
/**
 * Provide a path resolver via the given factory with the given dependencies
 */
export function providePathResolverFactory(
  useFactory: (...args: any[]) => PathResolver,
  deps: any[],
): Provider;
export function providePathResolverFactory(
  useFactory: (...args: any[]) => PathResolver,
  deps?: any[],
): Provider {
  return {
    provide: PATH_RESOLVER_TOKEN,
    useFactory,
    deps,
    multi: true,
  };
}

/**
 * Provide the given path resolver via `useValue`
 */
export function providePathResolverValue(useValue: PathResolver): Provider {
  return {
    provide: PATH_RESOLVER_TOKEN,
    useValue,
    multi: true,
  };
}

/**
 * Provide the given path resolver token via `useExisting`
 *
 * This function requires the token to be known to the dependency injection engine
 */
export function providePathResolverExisting(
  useExisting: Type<PathResolver> | InjectionToken<PathResolver>,
): Provider {
  return {
    provide: PATH_RESOLVER_TOKEN,
    useExisting,
    multi: true,
  };
}

/**
 * Provide the given dynamic resource url resolver class via `useClass`
 */
export function provideDynamicResourceUrlResolverClass(
  useClass: Type<DynamicResourceUrlResolver>,
): Provider {
  return {
    provide: DYNAMIC_RESOURCE_URL_RESOLVERS,
    useClass,
    multi: true,
  };
}

/**
 * Provide a dynamic resource url resolver via the given factory
 */
export function provideDynamicResourceUrlResolverFactory(
  useFactory: () => DynamicResourceUrlResolver,
): Provider;
/**
 * Provide a dynamic resource url resolver via the given factory with the given dependencies
 */
export function provideDynamicResourceUrlResolverFactory(
  useFactory: (...args: any[]) => DynamicResourceUrlResolver,
  deps: any[],
): Provider;
export function provideDynamicResourceUrlResolverFactory(
  useFactory: (...args: any[]) => DynamicResourceUrlResolver,
  deps?: any[],
): Provider {
  return {
    provide: DYNAMIC_RESOURCE_URL_RESOLVERS,
    useFactory,
    deps,
    multi: true,
  };
}

/**
 * Provide the given dynamic resource url resolver via `useValue`
 */
export function provideDynamicResourceUrlResolverValue(
  useValue: DynamicResourceUrlResolver,
): Provider {
  return {
    provide: DYNAMIC_RESOURCE_URL_RESOLVERS,
    useValue,
    multi: true,
  };
}

/**
 * Provide the given dynamic resource url resolver token via `useExisting`
 *
 * This function requires the token to be known to the dependency injection engine
 */
export function provideDynamicResourceUrlResolverExisting(
  useExisting: Type<DynamicResourceUrlResolver> | InjectionToken<DynamicResourceUrlResolver>,
): Provider {
  return {
    provide: DYNAMIC_RESOURCE_URL_RESOLVERS,
    useExisting,
    multi: true,
  };
}
