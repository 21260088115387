import {NgModule} from '@angular/core';

import { LogosItsmeSvgModule } from './@kdl/logos/logos-itsme.component';
import { ActionFuncBinSvgModule } from './@kdl/icons/action-func-bin.component';
import { ActionFuncDownloadSvgModule } from './@kdl/icons/action-func-download.component';
import { ActionFuncFullscreenUndoSvgModule } from './@kdl/icons/action-func-fullscreen-undo.component';
import { ActionFuncFullscreenSvgModule } from './@kdl/icons/action-func-fullscreen.component';
import { ActionFuncMagnifierMinusSvgModule } from './@kdl/icons/action-func-magnifier-minus.component';
import { ActionFuncMagnifierPlusSvgModule } from './@kdl/icons/action-func-magnifier-plus.component';
import { ActionFuncPlusSvgModule } from './@kdl/icons/action-func-plus.component';
import { ActionFuncTurnLeftSvgModule } from './@kdl/icons/action-func-turn-left.component';
import { ActionFuncTurnRightSvgModule } from './@kdl/icons/action-func-turn-right.component';
import { AlertFuncCheckSvgModule } from './@kdl/icons/alert-func-check.component';
import { AlertFuncClearSvgModule } from './@kdl/icons/alert-func-clear.component';
import { AppBranCbcSignSvgModule } from './@kdl/icons/app-bran-cbc-sign.component';
import { AppBranKbcBrusselsSignSvgModule } from './@kdl/icons/app-bran-kbc-brussels-sign.component';
import { AppBranKbcSignSvgModule } from './@kdl/icons/app-bran-kbc-sign.component';
import { FileFuncCloudCheckSvgModule } from './@kdl/icons/file-func-cloud-check.component';
import { FileFuncCloudUploadSvgModule } from './@kdl/icons/file-func-cloud-upload.component';
import { FileFuncDocumentSvgModule } from './@kdl/icons/file-func-document.component';
import { InformationExprSignDeviceSvgModule } from './@kdl/icons/information-expr-sign-device.component';
import { InformationExprSignScanSvgModule } from './@kdl/icons/information-expr-sign-scan.component';
import { InformationFuncOkSvgModule } from './@kdl/icons/information-func-ok.component';
import { InformationFuncSignSvgModule } from './@kdl/icons/information-func-sign.component';
import { NavigationFuncCrossSvgModule } from './@kdl/icons/navigation-func-cross.component';
import { FormsStepperMinusSvgModule } from './@ivy/svg/svgs/forms-stepper-minus.component';
import { FormsStepperPlusSvgModule } from './@ivy/svg/svgs/forms-stepper-plus.component';
import { GeneralDeleteCircleCrossSvgModule } from './@ivy/svg/svgs/general-delete-circle-cross.component';
import { SignerBuySvgModule } from './@ivy/svg/svgs/signer-buy.component';
import { SignerOkSvgModule } from './@ivy/svg/svgs/signer-ok.component';
import { SignerScanMinusSvgModule } from './@ivy/svg/svgs/signer-scan-minus.component';
import { SignerScanPlusSvgModule } from './@ivy/svg/svgs/signer-scan-plus.component';
import { SignerScanSvgModule } from './@ivy/svg/svgs/signer-scan.component';
import { SignerSignSvgModule } from './@ivy/svg/svgs/signer-sign.component';
import { SubNavigationToggleMobileSignOSvgModule } from './@ivy/svg/svgs/sub-navigation-toggle-mobile-sign-o.component';
import { SubNavigationToggleMobileSignSvgModule } from './@ivy/svg/svgs/sub-navigation-toggle-mobile-sign.component';
import { SubNavigationToggleSignerManualOSvgModule } from './@ivy/svg/svgs/sub-navigation-toggle-signer-manual-o.component';
import { SubNavigationToggleSignerManualSvgModule } from './@ivy/svg/svgs/sub-navigation-toggle-signer-manual.component';
import { SubNavigationToggleSignerScanOSvgModule } from './@ivy/svg/svgs/sub-navigation-toggle-signer-scan-o.component';
import { SubNavigationToggleSignerScanSvgModule } from './@ivy/svg/svgs/sub-navigation-toggle-signer-scan.component';

@NgModule({
  exports: [
    AppBranCbcSignSvgModule,
    AppBranKbcBrusselsSignSvgModule,
    AppBranKbcSignSvgModule,
  ],
})
export class εAppBranchSvgModule {
}
@NgModule({
  exports: [
    FileFuncDocumentSvgModule,
  ],
})
export class εDocumentLinkSvgModule {
}
@NgModule({
  exports: [
    ActionFuncBinSvgModule,
    FileFuncCloudCheckSvgModule,
    FileFuncCloudUploadSvgModule,
    FileFuncDocumentSvgModule,
    ActionFuncPlusSvgModule,
  ],
})
export class εFileUploaderSvgModule {
}
@NgModule({
  exports: [
    FileFuncCloudCheckSvgModule,
    FileFuncCloudUploadSvgModule,
    ActionFuncPlusSvgModule,
  ],
})
export class εImageGallerySvgModule {
}
@NgModule({
  exports: [
    AlertFuncCheckSvgModule,
    AlertFuncClearSvgModule,
    FormsStepperMinusSvgModule,
    FormsStepperPlusSvgModule,
    GeneralDeleteCircleCrossSvgModule,
    LogosItsmeSvgModule,
    InformationExprSignDeviceSvgModule,
    InformationExprSignScanSvgModule,
    InformationFuncSignSvgModule,
    InformationFuncOkSvgModule,
    SignerBuySvgModule,
    SignerOkSvgModule,
    SignerScanSvgModule,
    SignerScanMinusSvgModule,
    SignerScanPlusSvgModule,
    SignerSignSvgModule,
    SubNavigationToggleMobileSignSvgModule,
    SubNavigationToggleMobileSignOSvgModule,
    SubNavigationToggleSignerManualSvgModule,
    SubNavigationToggleSignerManualOSvgModule,
    SubNavigationToggleSignerScanSvgModule,
    SubNavigationToggleSignerScanOSvgModule,
  ],
})
export class εSigningSvgModule {
}
@NgModule({
  exports: [
    ActionFuncDownloadSvgModule,
    ActionFuncFullscreenSvgModule,
    ActionFuncFullscreenUndoSvgModule,
    ActionFuncMagnifierPlusSvgModule,
    ActionFuncMagnifierMinusSvgModule,
    NavigationFuncCrossSvgModule,
    ActionFuncTurnLeftSvgModule,
    ActionFuncTurnRightSvgModule,
    FileFuncDocumentSvgModule,
  ],
})
export class εPreviewsSvgModule {
}
