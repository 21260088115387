import {BusinessType, createStringifier} from '../base/businesstype';

import {isValidPhoneNumber} from './validator-base';

const stringifier = createStringifier('phoneNumber');

/**
 * BusinessType wrapper around phone numbers.
 */
export class PhoneNumber extends BusinessType<string> {
  /**
   * Returns whether or not `value` is a PhoneNumber.
   */
  public static isPhoneNumber(value: unknown): value is PhoneNumber {
    return value instanceof PhoneNumber;
  }

  /**
   * Constructs a new PhoneNumber instance.
   *
   * @param value The phone number value
   * @param encryptedValue The encryptedValue, if any
   */
  public constructor(value: string, encryptedValue?: string) {
    if (typeof value !== 'string') {
      throw new Error(
        `Expected a string but got ${(typeof value === 'object' &&
          (value as object).constructor.name) ||
          typeof value}`,
      );
    }
    if (!isValidPhoneNumber(value)) {
      throw new Error(`Cannot create PhoneNumber for invalid input ${JSON.stringify(value)}`);
    }

    super(value, encryptedValue);
  }

  /**
   * Returns whether `other` is a `PhoneNumber` representing the same value.
   */
  public equals(other: unknown): boolean {
    if (!other) {
      return false;
    }

    if (other === this) {
      return true;
    }

    return PhoneNumber.isPhoneNumber(other) && this.internalValue === other.internalValue;
  }

  /**
   * Exposes the value of this BusinessType as a string. The returned value will
   * always be the same.
   */
  public asString(): string {
    return this.internalValue;
  }

  public toString(): string {
    return stringifier(this);
  }
}
