import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 * @ngModule FileUploaderModule
 */
@Component({
  selector: 'hermes-file-uploader-tile-info',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderTileInfoComponent {}
