import {Pipe, PipeTransform} from '@angular/core';
import {PhoneNumber, PhoneNumberFormatter} from '@atlas/businesstypes';

/**
 * @ngModule BusinessTypesModule
 */
@Pipe({name: 'atlasPhoneNumber'})
export class PhoneNumberPipe implements PipeTransform {
  public transform(value: any): string {
    return PhoneNumber.isPhoneNumber(value) ? PhoneNumberFormatter.format(value) : value;
  }
}
