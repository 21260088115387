import {NgModule} from '@angular/core';

import {FlowProgressModule} from '../flow-progress.module';

import {StepContainerDirective} from './step-container.directive';
import {StepRouteIdDirective} from './step-route-id.directive';

/**
 * Module for the `<maia-flow-progress>` hooked into the Angular router
 *
 * This module provides the flow progress hooked into the angular router, using a route data
 * property called `maiaFlowProgressId`.
 *
 * Example usage
 *
 * ```html
 * <maia-flow-progress>
 *   <maia-flow-progress-step title="Step 1" routeId="step1"></maia-flow-progress-step>
 *   <maia-flow-progress-step title="Step 2" routeId="step2"></maia-flow-progress-step>
 *   <maia-flow-progress-multi-step title="Step 3">
 *     <maia-flow-progress-step title="Step 3 substep 1" routeId="step3.1">
 *     </maia-flow-progress-step>
 *     <maia-flow-progress-step title="Step 3 substep 2" routeId="step3.2">
 *     </maia-flow-progress-step>
 *   </maia-flow-progress-multi-step>
 * </maia-flow-progress>
 * ```
 *
 * with route configuration
 *
 * ```ts
 * const routes: Routes = [{
 *   {path: 'step-one', data: {maiaFlowProgressId: 'step1'}},
 *   {path: 'step-two', data: {maiaFlowProgressId: 'step2'}},
 *   {path: 'step-three-part-one', data: {maiaFlowProgressId: 'step3.1'}},
 *   {path: 'step-three-part-two', data: {maiaFlowProgressId: 'step3.2'}},
 * }];
 * ```
 *
 * This module exports the `FlowProgressModule`, there's no need to import that module directly if
 * you want to use this module.
 */
@NgModule({
  declarations: [StepContainerDirective, StepRouteIdDirective],
  exports: [FlowProgressModule, StepContainerDirective, StepRouteIdDirective],
})
export class FlowProgressWithRoutingModule {}
