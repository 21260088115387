import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import {coerceNumberPrimitive} from '@atlas-angular/cdk/coercion';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {ViewportName, ViewportService} from '@maia/core';

/**
 * @ngModule LayoutModule
 * @deprecated use maia-grid-list--min-col-size mixin instead
 */

@Component({
  selector: 'maia-grid-list',
  template: '<ng-content></ng-content>',
  styleUrls: ['./grid-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.maia-grid-list--without-css-grid]': '!supportsGrid',
  },
})
@UntilDestroy()
export class GridListComponent implements OnInit {
  public supportsGrid = 'gridTemplateColumns' in this._el.nativeElement.style;

  /**
   * Number of columns will be displayed on the grid-list
   */
  @Input()
  @coerceNumberPrimitive()
  public columns: number;

  /**
   * Specifies a different number of columns to be displayed when the viewport is MD or higher.
   * Optional.
   */
  @Input()
  @coerceNumberPrimitive()
  public mdColumns?: number;

  private numberOfColumnsToDisplay: number;
  public constructor(
    private readonly _viewportService: ViewportService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _renderer: Renderer2,
    private readonly _el: ElementRef,
  ) {}

  public ngOnInit() {
    if (!this.columns) {
      throw new Error('Cannot use this component if columns to be displayed were not defined.');
    }
    this._viewportService
      .isAtLeast$(ViewportName.MEDIUM)
      .pipe(takeUntilDestroyed(this))
      .subscribe((isAtLeastMedium: boolean) => {
        this.numberOfColumnsToDisplay = (isAtLeastMedium && this.mdColumns) || this.columns;
        this.updateColumns();
        this._changeDetectorRef.detectChanges();
      });
  }

  public updateColumns() {
    if (this.supportsGrid) {
      this._renderer.setStyle(
        this._el.nativeElement,
        'grid-template-columns',
        `repeat(${this.numberOfColumnsToDisplay}, 1fr)`,
      );
    } else {
      for (const element of this._el.nativeElement.children) {
        this._renderer.setStyle(
          element,
          'width',
          `calc((100% / ${this.numberOfColumnsToDisplay}) - 24px)`,
        );
      }
    }
  }
}
