import {Formatter} from '../base/formatter';

import {Tsfr} from './businesstype';

const UNFORMAT_REGEX = /(\/|\+| )+/g;

export const TsfrFormatter: Formatter<Tsfr> = {
  /**
   * Returns the string value of the tsfr. Formatted by adding structure
   *
   * @param value The tsfr to format
   */
  format(value: Tsfr): string {
    const tsfrUnformattedString = value.asString();
    const start = tsfrUnformattedString.substring(0, 3);
    const middle = tsfrUnformattedString.substring(3, 7);
    const end = tsfrUnformattedString.substring(7, 12);
    return `+++ ${start} / ${middle} / ${end} +++`;
  },

  /**
   * Removes all tsfr separator space characters from the pretty value.
   *
   * @param tsfrString The string to unformat
   */
  unformat(tsfrString: string): string {
    let unformatted = tsfrString;
    unformatted = unformatted.replace(UNFORMAT_REGEX, '');
    return unformatted;
  },
};
