import {ChangeDetectionStrategy, Component, OnInit, Input, HostBinding} from '@angular/core';
import {coerceBooleanPrimitive, coerceNumberPrimitive} from '@atlas-angular/cdk/coercion';

export const enum Alignment {
  Block = 'block',
  Inline = 'inline',
}

export const enum ImagePosition {
  Start = 'start',
  End = 'end',
}

/**
 *
 * This component is used to show content together with an image.
 * By default the image always has margin around it but the consumer can...
 * ...choose to remove these by adding the attribute `withoutImagePadding`
 *
 * @ngModule LayoutModule
 */

@Component({
  selector: 'maia-image-content-layout',
  templateUrl: './image-content-layout.component.html',
  styleUrls: ['./image-content-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageContentLayoutComponent implements OnInit {
  @Input()
  public alignment = Alignment.Block;

  @Input()
  public imagePosition = ImagePosition.Start;

  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('class.p-maia-image-content-layout--disabled')
  public disabled = false;

  /**
   * Optional property to specify a custom width to the image content layout image.
   * Valid with any of these options:
   * -"alignment == 'inline' && imagePosition == 'start'"
   * -"alignment == 'inline' && imagePosition == 'end'"
   */
  @Input()
  @coerceNumberPrimitive()
  @HostBinding('class.p-maia-image--custom-width')
  public imageCustomWidth?: number;

  /**
   * Optional property to specify a custom height to the image content layout.
   * Valid with any of these options:
   * -"alignment == 'inline' && imagePosition == 'start'"
   * -"alignment == 'inline' && imagePosition == 'end'"
   */
  @Input()
  @coerceNumberPrimitive()
  @HostBinding('class.p-maia-layout--custom-height')
  public layoutCustomHeight?: number;

  public ngOnInit() {
    if (this.imageCustomWidth && this.alignment !== Alignment.Inline) {
      throw new Error('Cannot use imageCustomWidth property if alignment property is not `inline`');
    }
    if (this.layoutCustomHeight && this.alignment !== Alignment.Inline) {
      throw new Error(
        'Cannot use layoutCustomHeight property if alignment property is not `inline`',
      );
    }
  }
}
