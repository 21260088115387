import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  forwardRef,
  QueryList,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

import {GroupComponent} from '../group.component';
import {ToggleButtonComponent} from '../toggle-button/toggle-button.component';

/**
 * @ngModule RadioButtonsModule
 */
@Component({
  selector: 'maia-toggle-group',
  templateUrl: './toggle-group.component.html',
  styleUrls: ['./toggle-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ToggleGroupComponent), multi: true},
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {role: 'radiogroup', tabIndex: '0'},
})
export class ToggleGroupComponent extends GroupComponent {
  @ContentChildren(forwardRef(() => ToggleButtonComponent))
  protected queryList: QueryList<ToggleButtonComponent>;
}
