import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {μTextListSvgModule} from '@maia/icons';

import {TextListItemComponent} from './text-list-item/text-list-item.component';
import {TextListComponent} from './text-list/text-list.component';
import {TextNumberListComponent} from './text-number-list/text-number-list.component';
import {TextNumberListItemComponent} from './text-number-list-item/text-number-list-item.component';

@NgModule({
  imports: [μTextListSvgModule, CommonModule],
  declarations: [
    TextListComponent,
    TextListItemComponent,
    TextNumberListComponent,
    TextNumberListItemComponent,
  ],
  exports: [
    TextListComponent,
    TextListItemComponent,
    TextNumberListComponent,
    TextNumberListItemComponent,
  ],
})
export class TextListsModule {}
