import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 * This is an internal component in the input-autocomplete component that's necessary because we
 * want to select for viewports in a template modal.
 *
 * Selecting for viewports makes use of the `:host-context` selector, which doesn't work for
 * template modals because the template is inserted into the DOM outside of the host element,
 * ensuring that no viewport selector will ever match.
 *
 * Using a component inside the template for the modal fixes that. That component will be moved in
 * its entirety, so we can safely use the `:host-context`.
 *
 * ---
 *
 * Reading this explanation might make using regular mediaqueries an easier solution than our
 * current viewport system. That impression wouldn't necessarily be false... however, we will run
 * into the same issues for theming. Theming can't use media queries as alternative solution, so
 * using `:host-context` is the only way forward.
 *
 * @ngModule InputAutocompletePartsModule
 */
@Component({
  selector: 'maia-options-input-container',
  template: '<ng-content></ng-content>',
  styleUrls: ['./options-input-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsInputContainerComponent {}
