import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SubNavigationToggleSignerScanSvgModule
 */
@Component({
  selector: 'svg[ivyHermesSubNavigationToggleSignerScan]',
  template: '<svg:g><svg:g><svg:path d="M116.4 268.9h69.8v243.9h-69.8z"></svg:path><svg:path d="M12.1 268.9h69.8v243.9H12.1z"></svg:path><svg:path d="M221.6 268.9h69.8v243.9h-69.8z"></svg:path><svg:path d="M325.8 268.9h69.8v243.9h-69.8z"></svg:path><svg:path d="M431 268.9h69.8v243.9H431z"></svg:path><svg:path d="M0 140.4l46.5 81 46.6-81z"></svg:path><svg:path d="M418.9 140.4l46.6 81 46.5-81z"></svg:path></svg:g></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 70.6 512 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubNavigationToggleSignerScanSvgComponent {
}

@NgModule({
  declarations: [
    SubNavigationToggleSignerScanSvgComponent,
  ],
  exports: [
    SubNavigationToggleSignerScanSvgComponent,
  ],
})
export class SubNavigationToggleSignerScanSvgModule {}
