import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionFuncClockSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaActionFuncClock]',
  template: '<svg:g class="nc-icon-wrapper" transform="matrix(.8 0 0 .8 2.4 2.4)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"><svg:circle fill="none" stroke-miterlimit="10" cx="12" cy="12" r="11"></svg:circle><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M12 6v6h6"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionFuncClockSvgComponent {
}

@NgModule({
  declarations: [
    ActionFuncClockSvgComponent,
  ],
  exports: [
    ActionFuncClockSvgComponent,
  ],
})
export class ActionFuncClockSvgModule {}
