/* eslint-disable @angular-eslint/no-input-rename */
import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

/**
 * All valid values for the `type` property of MiniButtonComponents.
 */
export type MiniButtonType = 'inverted' | 'outlined' | 'full';

/**
 * All valid values for the `theme` property of MiniButtonComponents.
 */
export type MiniButtonTheme = 'info' | 'success' | 'warning' | 'error';

/**
 * Mini button component.
 *
 * It is up to the consumer of this mini button component to ensure the proper accessibility
 * support is present.
 *
 * @ngModule MiniButtonsModule
 */
@Component({
  selector: 'button[maiaMiniButton]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./mini-buttons.component.scss'],

  // Mini buttons element don't hold state and they don't accept any mutable inputs, so we can
  // use OnPush
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniButtonsComponent {
  /**
   * The mini button type.
   */
  @Input('maiaMiniButtonType')
  @HostBinding('attr.maiaMiniButtonType')
  public type: MiniButtonType | null;

  /**
   * Optional custom color the mini button.
   */
  @Input('maiaMiniButtonTheme')
  @HostBinding('attr.maiaMiniButtonTheme')
  public theme: MiniButtonTheme | null;
}
