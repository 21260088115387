import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {FormsModule} from '../../forms.module';

import {FormElementWideComponent} from './form-element-wide.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [FormElementWideComponent],
  exports: [FormElementWideComponent],
})
export class FormElementWideModule {}
