import {ChangeDetectionStrategy, Component, Optional} from '@angular/core';
import {TileTitleComponent} from '../tile-title/tile-title.component';

/**
 * Tile title icon component
 *
 * @ngModule TilesModule
 */
@Component({
  selector: 'maia-tile-title-icon',
  template: '<ng-content></ng-content>',
  styleUrls: ['./tile-title-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileTitleIconComponent {
  public constructor(@Optional() parent?: TileTitleComponent) {
    if (parent === null) {
      throw new Error(
        'Cannot create a <maia-tile-title-icon /> component out of <maia-tile-title /> component',
      );
    }
  }
}
