import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Message} from './message';

@Injectable({providedIn: 'root'})
/**
 * the main purpose of this service is privacy and not exporting subjects on the public api
 * to allow communication between component and messagingService
 * and export only public observables (no subjects) on messagingService
 */
export class MessagingActionService {
  private readonly _onMessageAction = new Subject<Message>();
  public readonly onMessageAction: Observable<Message> = this._onMessageAction.asObservable();

  public onAction(message: Message) {
    this._onMessageAction.next(message);
  }
}
