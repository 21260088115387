import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {TEXT_LIST_DEFAULT_INDENTED} from '../text-list/text-list.component';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

/**
 * TextNumberList component
 *
 * Example usage:
 *
 * <ol maiaTextNumberList></ol>
 *
 *
 *  To set the indent indented
 *
 * <ol maiaTextNumberList indented></ol>
 *
 * @ngModule TextListsModule
 */
@Component({
  selector: 'ol[maiaTextNumberList]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./text-number-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextNumberListComponent {
  @coerceBooleanPrimitive()
  @Input()
  @HostBinding('class.maia-item--indented')
  public indented: boolean = TEXT_LIST_DEFAULT_INDENTED;
}
