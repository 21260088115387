import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 * Document group component.
 *
 * @ngModule OpenResourcesModule
 */
@Component({
  selector: 'hermes-document-group',
  templateUrl: './document-group.component.html',
  styleUrls: ['./document-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentGroupComponent {}
