import {Directive, Input, OnChanges, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {coerceNumberPrimitive} from '@atlas-angular/cdk/coercion';

import {
  ValidationFilter,
  ValidationMessageExtractor,
} from '../form-element/validation-message-extractor.service';

/**
 * A structural directive that registers the element it's placed on as validation template.
 *
 * This directive can only be used inside a form element.
 *
 * @ngModule FormsModule
 */
@Directive({
  selector: '[maiaRegisterValidation]',
})
export class RegisterValidationDirective implements OnInit, OnDestroy, OnChanges {
  /**
   * The priority of the validation message.
   */
  @Input('maiaRegisterValidationPriority')
  @coerceNumberPrimitive(1)
  public priority = 1;

  /**
   * An optional filter function to limit this validation message depending on the return value of
   * this function.
   */
  @Input('maiaRegisterValidation')
  public filter?: ValidationFilter | null;

  private _unregister?: () => void;

  public constructor(
    private readonly _validationMessageExtractor: ValidationMessageExtractor,
    private readonly _template: TemplateRef<unknown>,
  ) {}

  /**
   * Registers the message/filter/priority in this directive in the validation message extractor.
   */
  private register(): void {
    this._unregister = this._validationMessageExtractor.registerMessageExtractor(
      this._template,
      this.priority,
      this.filter ?? undefined,
    );
  }

  public ngOnInit(): void {
    this.register();
  }

  public ngOnDestroy(): void {
    // istanbul ignore else: This shouldn't happen, but better safe than TypeError
    if (this._unregister != null) {
      this._unregister();
    }
  }

  public ngOnChanges(): void {
    if (this._unregister == null) {
      return;
    }

    this._unregister();
    this.register();
  }
}
