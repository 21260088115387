import {Injectable, OnDestroy, Optional, Provider, TemplateRef} from '@angular/core';
import {AbstractControl, NgControl} from '@angular/forms';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

import {ValidationMessageExtractor} from '../form-element/validation-message-extractor.service';

import {InputContainer} from './input-container.interface';
import {MultipleInputContainer} from './multiple-input-container.service';

export const optionalSingleInputContainerFactory = (
  validationMessageExtractor?: ValidationMessageExtractor,
  parent?: MultipleInputContainer,
): SingleInputContainer | null => {
  if (!validationMessageExtractor) {
    return null;
  } else {
    return new SingleInputContainer(validationMessageExtractor, parent);
  }
};

/**
 * Special provider to be used by those Maia components that can be used without forms (i.e.
 * checkboxes, switches).
 * In such case no validation support is needed, meaning no SingleInputContainer needed at all.
 */
export const μOptionalSingleInputContainerProvider: Provider = {
  provide: InputContainer,
  useFactory: optionalSingleInputContainerFactory,
  // mark all deps as optional (see https://angular.io/api/core/FactoryProvider#usage-notes)
  deps: [
    [new Optional(), ValidationMessageExtractor],
    [new Optional(), MultipleInputContainer],
  ],
};

@Injectable()
@UntilDestroy()
export class SingleInputContainer implements InputContainer, OnDestroy {
  private readonly _disabled$ = new BehaviorSubject<boolean>(false);

  private readonly _focused$ = new BehaviorSubject<boolean>(false);

  private readonly _optional$ = new BehaviorSubject(true);

  private readonly _validationErrorChange$ = new Subject<void>();

  private _formControl?: NgControl = undefined;

  private _errorString?: string = undefined;

  private _errorTemplate?: TemplateRef<any> = undefined;

  public constructor(
    private readonly _validationExtractor: ValidationMessageExtractor,
    @Optional() private readonly _parent?: MultipleInputContainer,
  ) {}

  public get disabled(): boolean {
    return this._disabled$.getValue();
  }

  public get disabled$(): Observable<boolean> {
    return this._disabled$.asObservable();
  }

  public get focused(): boolean {
    return this._focused$.getValue();
  }

  public set focused(focused: boolean) {
    this._focused$.next(focused);
  }

  public get focused$(): Observable<boolean> {
    return this._focused$.asObservable().pipe(distinctUntilChanged());
  }

  public get optional(): boolean {
    return this._optional$.getValue();
  }

  public get optional$(): Observable<boolean> {
    return this._optional$.asObservable().pipe(distinctUntilChanged());
  }

  public get validationErrorChange$(): Observable<void> {
    return this._validationErrorChange$.asObservable();
  }

  public get errorString(): string | undefined {
    return this._errorString;
  }

  public get errorTemplate(): TemplateRef<any> | undefined {
    return this._errorTemplate;
  }

  public hasError(): boolean {
    return this._errorString != null || this._errorTemplate != null;
  }

  public registerFormControl(control: NgControl): () => void {
    if (this._formControl != null) {
      throw new Error('A maia-form-element / maia-form-subelement can only contain one ngModel');
    }

    this._formControl = control;

    this._updateDisabled();
    this._updateValidation();

    const subscription = control.statusChanges!.pipe(takeUntilDestroyed(this)).subscribe(() => {
      this._updateValidation();
      this._updateDisabled();
    });

    if (this._parent != null) {
      // The teardown logic we pass here will automatically be called when the subscription is
      // ended, either because the observable above completes (e.g. because this service is
      // destroyed) or when unsubscribe is called below.
      subscription.add(this._parent.registerChildContainer(this));
    }

    let reset = false;
    return () => {
      if (reset) {
        return;
      }

      reset = true;

      subscription.unsubscribe();

      if (this._formControl !== control) {
        return;
      }

      this._formControl = undefined;

      this._updateValidation();
      this._updateDisabled();
    };
  }

  private _updateDisabled(): void {
    const disabled = !!this._formControl?.disabled;

    if (this._disabled$.getValue() !== disabled) {
      this._disabled$.next(disabled);
    }
  }

  private _markValid(): void {
    this._errorString = undefined;
    this._errorTemplate = undefined;

    this._validationErrorChange$.next();
  }

  /**
   * Checks the registered form control, if any, to see whether the shown validation message should
   * be updated, added or removed.
   *
   * Triggers a change detection if anything changed.
   */
  private _updateValidation(): void {
    if (this._formControl == null) {
      this._markValid();
      return;
    }

    if (this._formControl.pending) {
      return;
    }

    this._updateOptionalIndicator();

    const result = this._validationExtractor.extract(this._formControl.errors);

    const message = result.message;

    if (typeof message === 'string') {
      this._errorString = message;
      this._errorTemplate = undefined;
    } else {
      this._errorTemplate = message;
      this._errorString = undefined;
    }

    this._validationErrorChange$.next();
  }

  private _updateOptionalIndicator(): void {
    // by default marked as optional if there are no validation(s) defined or no control at all
    if (!this._formControl?.control?.validator) {
      this._optional$.next(true);
      return;
    }

    // the validator(s) should be executed against a 'null' value to detect if the 'required'
    // validation is defined
    const validationErrors = this._formControl.control.validator({
      ...this._formControl.control,
      value: null,
    } as AbstractControl);
    const hasRequiredError = validationErrors?.['required'] === true;

    this._optional$.next(!hasRequiredError);
  }

  public ngOnDestroy(): void {
    this._formControl = undefined;

    this._disabled$.complete();
    this._optional$.complete();
    this._focused$.complete();
    this._validationErrorChange$.complete();
  }
}
