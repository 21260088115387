import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Month} from '@atlas/businesstypes';

/**
 * A monthpicker which shows the 12 months of the year. Months can be disabled.
 *
 * @ngModule DatePickersModule
 */
@Component({
  selector: 'maia-monthpicker',
  templateUrl: './monthpicker.component.html',
  styleUrls: ['./monthpicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MonthpickerComponent), multi: true},
  ],
})
export class MonthpickerComponent implements ControlValueAccessor, OnDestroy {
  private _selected?: Month = undefined;

  private _disabledMonths: Month[] = [];

  // istanbul ignore next: provided by [(ngModel)]
  private _onChange = (year: Month) => {};
  // istanbul ignore next: provided by [(ngModel)]
  private _onTouch = () => {};

  public constructor(private readonly _changeDetector: ChangeDetectorRef) {}

  /**
   * The disabled months. By default no months are disabled.
   */
  @Input()
  public set disabledMonths(value: Month[]) {
    this._disabledMonths = value != null ? value : [];
  }

  /**
   * Whether the given month is the selected month.
   */
  public isSelected(month: number): boolean {
    return month === this._selected;
  }

  /**
   * Whether the given month is disabled.
   */
  public isDisabled(month: number): boolean {
    return this._disabledMonths.indexOf(month) > -1;
  }

  /**
   * Marks the given month as selected. This function propagates the new value on to the
   * `[(ngModel)]`.
   */
  public selectMonth(month: number): void {
    if (this.isDisabled(month)) {
      return;
    }

    this._selected = month;
    this._onChange(month);
  }

  public ngOnDestroy() {
    this._onTouch();
  }

  // ControlValueAccessor API

  public writeValue(obj: any): void {
    const value = typeof obj === 'number' ? obj : null;

    const current = this._selected;

    if (value == null) {
      this._selected = undefined;
      if (current != null) {
        this._changeDetector.detectChanges();
      }

      return;
    }

    if (value !== current) {
      this._selected = value;
      this._changeDetector.detectChanges();
    }
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }
}
