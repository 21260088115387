import {HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ConnectorBackend, ConnectorInterceptor} from '@atlas-angular/connector';
import {Call, ConnectorResponseBase} from '@atlas/convertor';
import {Company, Language} from '@hermes/core';
import {Observable} from 'rxjs';

@Injectable()
export class HeadersInterceptor implements ConnectorInterceptor {
  public constructor(private readonly company: Company, private readonly language: Language) {}

  public intercept<I, O>(
    call: Call<I, O>,
    input: I,
    request: HttpRequest<null>,
    next: ConnectorBackend<O>,
  ): Observable<ConnectorResponseBase<O>> {
    return next.handle(
      request.clone({setHeaders: {company: this.company.code, language: this.language.code}}),
    );
  }
}
