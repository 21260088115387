import {Formatter} from '../base/formatter';

import {Html} from './businesstype';

export const HtmlFormatter: Formatter<Html> = {
  /**
   * Returns the string value of the html. No formatting is done,
   * as htmls are not structured.
   *
   * @param value The Html to "format"
   */
  format(value: Html): string {
    return value.asString();
  },

  /**
   * Simply returns the variable, as Html is not formatted.
   *
   * @param formattedValue The string to "unformat"
   */
  unformat(formattedValue: string): string {
    return formattedValue;
  },
};
