import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 * Action tile group component.
 *
 * @ngModule ActionTileModule
 */
@Component({
  selector: 'maia-action-group',
  template: '<ng-content></ng-content>',
  styleUrls: ['./action-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {role: 'group'},
})
export class ActionGroupComponent {}
