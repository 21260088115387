import {Text} from './businesstype';

type NullableText = Text | null | undefined;

/**
 * This is an utility library created to help developers working with Text Businesstype.
 */
export const TextUtils = {
  /**
   * Checks whether a Text is empty or its value is empty
   *
   * @param text The text to check
   * @return true if the Text is empty
   */
  isEmpty(text: NullableText): boolean {
    return !text || !text.asString();
  },

  /**
   * Checks whether a Text is not empty or its value is not empty
   *
   * @param text The text to check
   * @return true if the Text is not empty
   */
  isNotEmpty(text: NullableText): boolean {
    return !TextUtils.isEmpty(text);
  },

  /**
   * Returns the trimmed string
   *
   * @param text The text to trim
   * @return the trimmed string
   */
  getValueTrimmed(text: Text): string {
    return text.asString().trim();
  },

  /**
   * Returns a new trimmed Text object
   *
   * @param text The text to trim
   * @return a new trimmed Text object
   */
  trim(text: Text): Text {
    return new Text(TextUtils.getValueTrimmed(text));
  },

  /**
   * Checks whether a Text is blank (void or empty after trimmed)
   *
   * @param text The text to check
   * @return true if not blank (void or empty after trimmed)
   */
  isNotBlank(text: NullableText): boolean {
    return TextUtils.isNotEmpty(text) && !!TextUtils.getValueTrimmed(text as Text);
  },

  /**
   * Checks whether a Text is not blank (void or empty after trimmed)
   *
   * @param text The text to check
   * @return true if blank (void or empty after trimmed)
   */
  isBlank(text: NullableText): boolean {
    return !TextUtils.isNotBlank(text);
  },

  /**
   * Return a extracted string from an original one
   * If start is greater than end value, string will be empty
   *
   * @param text The text to extract a new object Text from
   * @param start index where extraction starts from (included) (if negative it will start from the
   * end)
   * @param end index where extraction ends (not included) (if omitted the rest of the string will
   * be extracted)
   * @return the string extracted
   */

  getValueSliced(text: Text, start: number, end?: number): string {
    return text.asString().slice(start, end);
  },

  /**
   * Return a new Text object extracted from an original one
   * If start is greater than end value, string will be empty
   *
   * @param text The text to extract a string from
   * @param start index where extraction starts from (included)
   * @param end index where extraction ends (not included) (if omitted the rest of the string will
   * be extracted)
   * @return the new object Text extracted
   */

  slice(text: Text, start: number, end?: number): Text {
    return new Text(TextUtils.getValueSliced(text, start, end));
  },
} as const;
