import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {NameValueCollectionGroupComponent} from './name-value-collection-group/name-value-collection-group.component';
import {NameValueCollectionItemComponent} from './name-value-collection-items/name-value-collection-item.component';
import {NameValueCollectionComponent} from './name-value-collection/name-value-collection.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    NameValueCollectionComponent,
    NameValueCollectionGroupComponent,
    NameValueCollectionItemComponent,
  ],
  exports: [
    NameValueCollectionComponent,
    NameValueCollectionGroupComponent,
    NameValueCollectionItemComponent,
  ],
})
export class NameValueCollectionsModule {}
