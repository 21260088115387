/*
 * This file contains the basic bban validators
 * to check whether a bban is actually a valid bban.
 */

import {validateModulo} from '../base/modulo-validator';
import {
  AtlasInvalidCharactersValidationErrors,
  AtlasInvalidCheckDigitsValidationErrors,
  AtlasInvalidLengthValidationErrors,
  combineResults,
  isValid,
  RESULT_VALID,
  ValidationErrors,
  ValidatorInput,
  ValidValidationResult,
} from '../base/validators';

import {Bban, BBAN_LENGTH, CHECK_DIGITS_START, NON_CHECK_DIGITS_LENGTH} from './businesstype';

/**
 * Checks the validity of the given input's length.
 *
 * @param value The bban input to validate
 */
function invalidLength(
  value: ValidatorInput<Bban>,
): AtlasInvalidLengthValidationErrors | ValidValidationResult {
  if (Bban.isBban(value)) {
    return RESULT_VALID;
  }

  if (!!value && validateLength(value)) {
    return RESULT_VALID;
  }

  return {
    atlasInvalidLength: {
      requiredLength: BBAN_LENGTH,
      actualLength: value?.length ?? 0,
    },
  };
}

/**
 * Checks whether the given input contains invalid characters.
 *
 * @param value The bban input to validate
 */
function invalidCharacters(
  value: ValidatorInput<Bban>,
): AtlasInvalidCharactersValidationErrors | ValidValidationResult {
  if (Bban.isBban(value)) {
    return RESULT_VALID;
  }

  if (!value || validateCharacters(value)) {
    return RESULT_VALID;
  }

  return {
    atlasInvalidCharacters: true,
  };
}

/**
 * Checks whether the given input is in an invalid format.
 *
 * The format is considered invalid if the check digit validation fails
 *
 * @param value The bban input to validate
 */
function invalidCheckDigits(
  value: ValidatorInput<Bban>,
): AtlasInvalidCheckDigitsValidationErrors | ValidValidationResult {
  if (Bban.isBban(value)) {
    return RESULT_VALID;
  }

  // Do not trigger checksum validation until correct length and characters are introduced
  if (!value || !validateLength(value) || !validateCharacters(value)) {
    return RESULT_VALID;
  }

  return validateCheckDigits(value) ? RESULT_VALID : {atlasInvalidCheckDigits: true};
}

/**
 * The BaseBbanValidators object contains partial bban validators, returning
 * information on the invalidity instead of simply returning a boolean
 * valid/invalid result.
 *
 * @deprecated Use `validateBban` instead
 */
export const BaseBbanValidators: {
  readonly invalidLength: (
    value: ValidatorInput<Bban>,
  ) => AtlasInvalidLengthValidationErrors | ValidValidationResult;
  readonly invalidCharacters: (
    value: ValidatorInput<Bban>,
  ) => AtlasInvalidCharactersValidationErrors | ValidValidationResult;
  readonly invalidCheckDigits: (
    value: ValidatorInput<Bban>,
  ) => AtlasInvalidCheckDigitsValidationErrors | ValidValidationResult;
} = {
  invalidLength,
  invalidCharacters,
  invalidCheckDigits,
};

/**
 *
 * Performs the length check on the given bban number
 *
 * @param inputBban The bban string to validate
 * @returns True in case the bban has the correct length
 */
function validateLength(inputBban: string): boolean {
  return inputBban.length === BBAN_LENGTH;
}

/**
 *
 * Check if the bban string has the correct characters (decimals)
 *
 * @param inputBban The bban string to validate
 * @returns True in case the bban characters are correct
 */
function validateCharacters(inputBban: string): boolean {
  return /^\d+$/.test(inputBban);
}

/**
 * Performs the check digit validation on the bban number
 *
 * @param inputBban The bban string to validate
 */
function validateCheckDigits(inputBban: string): boolean {
  const checkDigit = inputBban.substr(CHECK_DIGITS_START);
  return validateModulo(inputBban.substring(0, NON_CHECK_DIGITS_LENGTH), checkDigit);
}

/**
 * Checks whether the bban input is valid or not.
 *
 * @param input The bban input to validate
 */
export function isValidBban(input: ValidatorInput<Bban>): boolean {
  return isValid(validateBban(input));
}

/**
 * Validate the given bban input
 *
 * @param input The bban input to validate
 */
export function validateBban(
  input: ValidatorInput<Bban>,
): ValidationErrors | ValidValidationResult {
  if (Bban.isBban(input)) {
    return RESULT_VALID;
  }

  return (
    combineResults(invalidLength(input), invalidCharacters(input)) ?? invalidCheckDigits(input)
  );
}
