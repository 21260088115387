import {Directive, forwardRef, Input, TemplateRef} from '@angular/core';
import {
  DropdownHost,
  DropdownOptions,
  DropdownTemplateContext,
  SmallDropdownVisualisation,
} from '@maia/dropdowns';
import {μInputSelectOpener} from '@maia/input-select';
import {ModalResult} from '@maia/modals';
import {Option} from '@maia/select';
import {Observable} from 'rxjs';

/**
 * @ngModule InputPhonenumberModule
 */
@Directive({
  selector: 'maia-country-dropdown[slideInTitle]',
  providers: [
    {
      provide: μInputSelectOpener,
      useExisting: forwardRef(() => μCountryDropdownOpenAsSlideInDirective),
    },
  ],
})
export class μCountryDropdownOpenAsSlideInDirective implements μInputSelectOpener {
  @Input()
  public slideInTitle: string;

  public open<T>(
    host: DropdownHost,
    content: TemplateRef<DropdownTemplateContext<Option<T>>>,
    options: DropdownOptions,
  ): Observable<ModalResult<Option<T>>> {
    return host.prepareTemplate(content, {
      ...options,
      smallDropdownVisualisation: {
        visualisation: SmallDropdownVisualisation.SlideIn,
        options: {
          title: this.slideInTitle,
          fullWidthContent: true,
        },
      },
    });
  }
}
