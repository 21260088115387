import {Pipe, PipeTransform} from '@angular/core';
import {Vat, VatFormatter} from '@atlas/businesstypes';

/**
 * @ngModule BusinessTypesModule
 */
@Pipe({name: 'atlasVat'})
export class VatPipe implements PipeTransform {
  public transform(value: any): string {
    return Vat.isVat(value) ? VatFormatter.format(value) : value;
  }
}
