import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  Renderer2,
} from '@angular/core';
import {coerceBooleanPrimitive, coerceText} from '@atlas-angular/cdk/coercion';
import {Text} from '@atlas/businesstypes';
import {CssClassUtilityFactory} from '@maia/core';

import {BaseNumberDisplayComponent} from '../number-display/number-display.component';

export const DEFAULT_CURRENCY = new Text('EUR');

/**
 * @ngModule DisplaysModule
 */
@Component({
  selector: 'maia-amount-display:not([animated])',
  templateUrl: '../number-display/number-display.component.html',
  styleUrls: ['../number-display/number-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmountDisplayComponent extends BaseNumberDisplayComponent {
  @Input()
  @coerceText()
  public currency: Text | string;

  public get suffix(): Text | string {
    return this.currency;
  }
  // A coerceTextProperty, coerceBooleanProperty...etc. would be nice to automatically convert
  // a value to an Atlas business type.
  // https://s08983br.servers.kbc.be:6080/jira/browse/ATLAS-78

  public readonly showSmallDecimals: boolean = true;

  /**
   * Set to true if you want to strikethrough
   */
  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('class.maia-number-display--strikethrough')
  public strikethrough: boolean;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    super(cssClassUtilityFactory, renderer, elementRef);
    this.currency = DEFAULT_CURRENCY;
  }
}
