import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {TransactionService} from '@hermes/transaction';
import {VetoStateCheck} from '@hermes/veto';
import {HeaderLayoutTheme} from '@maia/header-layout';
import {filter, startWith} from 'rxjs/operators';

import {HeaderController} from '../../services/controller/header-control.service';

@UntilDestroy()
@Component({
  selector: 'bifrost-header-main-section',
  templateUrl: './header-main-section.component.html',
  styleUrls: ['./header-main-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMainSectionComponent implements OnInit, OnDestroy, AfterViewInit {
  private _title: string;
  private _hasExtra = false;

  @ViewChild('headerExtraAction')
  private readonly _extraAction: ElementRef;

  public get hasExtraAction(): boolean {
    return this._extraAction && this._extraAction.nativeElement.children.length > 0;
  }

  @Input()
  public theme: HeaderLayoutTheme = HeaderLayoutTheme.default;

  @Input()
  @coerceBooleanPrimitive()
  public alwaysShowLogo = false;

  @Output()
  public applyExtraAction: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public goBack: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public openLiveHelp: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public openKate: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public closeFlow: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  @coerceBooleanPrimitive()
  public showBadge = true;

  public constructor(
    private readonly _transactionService: TransactionService,
    private readonly _vetoStateCheck: VetoStateCheck,
    private readonly _controller: HeaderController,
    private readonly _cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this._controller.markForCheck$
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => this._cdr.markForCheck());

    this._transactionService.titleObserver
      .pipe(takeUntilDestroyed(this), startWith(this._transactionService.title))
      .subscribe(title => {
        this._title = title;
        this._cdr.detectChanges();
      });
  }

  public ngAfterViewInit() {
    this._hasExtra = this.hasExtraAction;
  }

  public ngOnDestroy(): void {
    // Allow usage of takeUntilDestroy
  }

  public get title(): string {
    return this._title;
  }

  public get vetoCustomBody(): string | undefined {
    return this._controller.vetoCustomBody;
  }

  public get vetoIsForced(): boolean {
    return this._controller.vetoIsForced;
  }

  public get showBackButton(): boolean {
    return this._controller.showBackButton;
  }

  public get showCloseButton(): boolean {
    return this._controller.showCloseButton;
  }

  public get showExtraActionButton(): boolean {
    return this._controller.showExtraActionButton;
  }

  public get showLiveHelpButton(): boolean {
    return this._controller.showLiveHelpButton;
  }

  public get showKateButton(): boolean {
    return this._controller.showKateButton;
  }

  public emitGoBack(): void {
    this.goBack.next();
  }

  public emitAssistaceOrExtraAction(): void {
    if (this.hasExtraAction) {
      this.applyExtraAction.next();
    } else if (this.showKateButton) {
      this.openKate.next();
    } else {
      this.openLiveHelp.next();
    }
  }

  /**
   * When the close button is clicked in the header we check if the veto needs to be displayed.
   * If not we immediately emit the event emitter, otherwise we wait for the user.
   */
  public emitCloseFlowOrShowVetoIfNeeded(): void {
    // First check if we can close without veto */
    if (this.vetoIsForced) {
      // We subscribe to the veto check for displaying the veto and wait for user response.
      this._vetoStateCheck
        .confirmDeactivation(this.vetoCustomBody)
        .pipe(
          takeUntilDestroyed(this),
          filter((confirmExit: boolean): boolean => confirmExit),
        )
        .subscribe((): void => this.closeFlow.next());
    } else {
      this.closeFlow.next();
    }
  }

  public get liveHelpIcon(): boolean {
    return this.showLiveHelpButton && !this.showKateButton && !this._hasExtra;
  }

  public get kateIcon(): boolean {
    return this.showKateButton && !this._hasExtra;
  }
}
