/**
 * The Viewports supported by the Maia framework.
 *
 * Keep the names in sync with the `$maia-viewports` SCSS variable!
 *
 * @see scss/variables/_viewports.scss
 */
export enum ViewportName {
  /**
   * Phones and other small handheld devices.
   */
  EXTRA_SMALL = 'xs',

  /**
   * Small tablets
   */
  SMALL = 'sm',

  /**
   * Tablets, netbooks
   */
  MEDIUM = 'md',

  /**
   * Large tablets, small laptops
   */
  LARGE = 'lg',

  /**
   * Larger laptops, external displays, ...
   */
  EXTRA_LARGE = 'xl',

  /**
   * Large external displays, ...
   */
  EXTRA_EXTRA_LARGE = 'xxl',
}

/**
 * The breakpoint in pixels between the EXTRA_SMALL and SMALL viewports. This specific width itself
 * is part of the SMALL breakpoint.
 */
const BREAKPOINT_SMALL = 576;
/**
 * The breakpoint in pixels between the SMALL and MEDIUM viewports. This specific width itself is
 * part of the MEDIUM breakpoint.
 */
const BREAKPOINT_MEDIUM = 768;
/**
 * The breakpoint in pixels between the MEDIUM and LARGE viewports. This specific width itself is
 * part of the LARGE breakpoint.
 */
const BREAKPOINT_LARGE = 992;
/**
 * The breakpoint in pixels between the LARGE and EXTRA_LARGE viewports. This specific width itself
 * is part of the EXTRA_LARGE breakpoint.
 */
const BREAKPOINT_EXTRA_LARGE = 1200;
/**
 * The breakpoint in pixels between the EXTRA_LARGE and EXTRA_EXTRA_LARGE viewports. This specific
 * width itself is part of the EXTRA_EXTRA_LARGE breakpoint.
 */
const BREAKPOINT_EXTRA_EXTRA_LARGE = 1440;

export class Viewport {
  public readonly bigger?: Viewport = undefined;
  public readonly max?: number = undefined;

  public constructor(name: ViewportName);
  public constructor(name: ViewportName, smaller: Viewport, min: number);
  public constructor(
    public readonly name: ViewportName,
    public readonly smaller?: Viewport,
    public readonly min?: number,
  ) {
    if (this.smaller != null) {
      (this.smaller as {bigger: Viewport}).bigger = this;
      (this.smaller as {max: number}).max = this.min! - 1;
    }
  }

  public isBiggerThanOrEqualTo(other: Viewport): boolean {
    return other.min == null || (this.min != null && this.min >= other.min);
  }
}

const xsViewport = new Viewport(ViewportName.EXTRA_SMALL);
const smViewport = new Viewport(ViewportName.SMALL, xsViewport, BREAKPOINT_SMALL);
const mdViewport = new Viewport(ViewportName.MEDIUM, smViewport, BREAKPOINT_MEDIUM);
const lgViewport = new Viewport(ViewportName.LARGE, mdViewport, BREAKPOINT_LARGE);
const xlViewport = new Viewport(ViewportName.EXTRA_LARGE, lgViewport, BREAKPOINT_EXTRA_LARGE);
const xxlViewport = new Viewport(
  ViewportName.EXTRA_EXTRA_LARGE,
  xlViewport,
  BREAKPOINT_EXTRA_EXTRA_LARGE,
);

export namespace Viewport {
  export const biggest = xxlViewport;
  export const smallest = xsViewport;
}

export const VIEWPORTS = {
  [ViewportName.EXTRA_SMALL]: xsViewport,
  [ViewportName.SMALL]: smViewport,
  [ViewportName.MEDIUM]: mdViewport,
  [ViewportName.LARGE]: lgViewport,
  [ViewportName.EXTRA_LARGE]: xlViewport,
  [ViewportName.EXTRA_EXTRA_LARGE]: xxlViewport,
} as Readonly<{[v in ViewportName]: Viewport}>;

export const VIEWPORT_NAMES = Object.keys(VIEWPORTS) as readonly ViewportName[];

export function getViewport(name: ViewportName): Viewport {
  return VIEWPORTS[name];
}
