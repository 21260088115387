import {validateModulo} from '../base/modulo-validator';
import {
  AtlasInvalidCharactersValidationErrors,
  AtlasInvalidCheckDigitsValidationErrors,
  AtlasInvalidLengthValidationErrors,
  combineResults,
  isValid,
  RESULT_VALID,
  ValidationErrors,
  ValidatorInput,
  ValidValidationResult,
} from '../base/validators';

import {IdentityCardNumber} from './businesstype';

const IDENTITY_CARD_NUMBER_LENGTH = 12;
const IDENTITY_CARD_SPLIT_CHECKDIGIT_LENGTH = 10;

/**
 * Checks the validity of the given input's characters.
 *
 * @param value The identity card number to validate
 */
function invalidCharacters(
  value: ValidatorInput<IdentityCardNumber>,
): AtlasInvalidCharactersValidationErrors | ValidValidationResult {
  if (IdentityCardNumber.isIdentityCardNumber(value)) {
    return RESULT_VALID;
  }

  if (!value || validateCharacters(value)) {
    return RESULT_VALID;
  }

  return {
    atlasInvalidCharacters: true,
  };
}

/**
 * Checks the validity of the given input's length.
 *
 * @param value The identity card number to validate
 */
function invalidLength(
  value: ValidatorInput<IdentityCardNumber>,
): AtlasInvalidLengthValidationErrors | ValidValidationResult {
  if (IdentityCardNumber.isIdentityCardNumber(value)) {
    return RESULT_VALID;
  }

  if (!!value && validateLength(value)) {
    return RESULT_VALID;
  }

  return {
    atlasInvalidLength: {
      requiredLength: IDENTITY_CARD_NUMBER_LENGTH,
      actualLength: value ? value.length : 0,
    },
  };
}

/**
 * Checks if identity card number's check digits are correct.
 *
 * @param value The identity card number to validate
 */
function invalidCheckDigits(
  value: ValidatorInput<IdentityCardNumber>,
): AtlasInvalidCheckDigitsValidationErrors | ValidValidationResult {
  if (IdentityCardNumber.isIdentityCardNumber(value)) {
    return RESULT_VALID;
  }

  // Do not trigger checksum validation until we have a identity card
  // number with the correct length consisting only out of valid characters.
  if (!value || !validateLength(value) || !validateCharacters(value)) {
    return RESULT_VALID;
  }

  return validateCheckDigits(value) ? RESULT_VALID : {atlasInvalidCheckDigits: true};
}

/**
 * The BaseIdentityCardNumberValidators object contains identity card number
 * validators returning information on the invalidity instead of simply
 * returning a boolean valid/invalid result.
 *
 * @deprecated Use `validateIdentityCardNumber` instead
 */
export const BaseIdentityCardNumberValidators: {
  /**
   * Checks the validity of the given input's characters.
   *
   * @param value The identity card number to validate
   */
  readonly invalidCharacters: (
    value: ValidatorInput<IdentityCardNumber>,
  ) => AtlasInvalidCharactersValidationErrors | ValidValidationResult;
  /**
   * Checks the validity of the given input's length.
   *
   * @param value The identity card number to validate
   */
  readonly invalidLength: (
    value: ValidatorInput<IdentityCardNumber>,
  ) => AtlasInvalidLengthValidationErrors | ValidValidationResult;
  /**
   * Checks if identity card number's check digits are correct.
   *
   * @param value The identity card number to validate
   */
  readonly invalidCheckDigits: (
    value: ValidatorInput<IdentityCardNumber>,
  ) => AtlasInvalidCheckDigitsValidationErrors | ValidValidationResult;
} = {
  invalidCharacters,
  invalidLength,
  invalidCheckDigits,
};

/**
 * Checks if the identity card number only contains the correct characters
 * (decimals).
 *
 * @param identityCardNumber The identity card number to validate
 * @returns True in case the identity card number characters are correct
 */
function validateCharacters(identityCardNumber: string): boolean {
  return /^\d+$/.test(identityCardNumber);
}

/**
 * Performs the length check on the given identity card number.
 *
 * @param identityCardNumber The identity card number to validate
 * @returns True in case the identity card number has the correct length
 */
function validateLength(identityCardNumber: string): boolean {
  return identityCardNumber.length === IDENTITY_CARD_NUMBER_LENGTH;
}

/**
 * Performs the check digit calculation on the identity card number.
 *
 * @param identityCardNumber The identity card number to validate
 * @returns True in case the check digits of the identity card number are
 * correct
 */
function validateCheckDigits(identityCardNumber: string): boolean {
  const checkValue = identityCardNumber.substr(0, IDENTITY_CARD_SPLIT_CHECKDIGIT_LENGTH);
  const checkDigit = identityCardNumber.substr(IDENTITY_CARD_SPLIT_CHECKDIGIT_LENGTH);
  return validateModulo(checkValue, checkDigit);
}

/**
 * Checks whether the identity card number is valid or not.
 *
 * @param input The identity card number to validate
 */
export function isValidIdentityCardNumber(input: ValidatorInput<IdentityCardNumber>): boolean {
  return isValid(validateIdentityCardNumber(input));
}

/**
 * Validate the given identity card number input
 *
 * @param input The identity card number to validate
 */
export function validateIdentityCardNumber(
  input: ValidatorInput<IdentityCardNumber>,
): ValidationErrors | ValidValidationResult {
  if (IdentityCardNumber.isIdentityCardNumber(input)) {
    return RESULT_VALID;
  }

  return (
    combineResults(invalidLength(input), invalidCharacters(input)) ?? invalidCheckDigits(input)
  );
}
