import {TypedPropertyDecorator} from './interface';

function getName(prototypeOrFunction: Function | Record<string, any>): string {
  return typeof prototypeOrFunction === 'function'
    ? prototypeOrFunction.name
    : prototypeOrFunction.constructor.name;
}

/**
 * Creates a property decorator function that passes any set value through the given wrapper
 * function before actually setting the property.
 *
 * This function handles both the case of regular properties and properties defined via an accessor
 * pair. It supports both static and instance properties.
 *
 * @param wrapper
 */
export function wrapPropertySetter<T>(wrapper: (value: T) => T): TypedPropertyDecorator<T> {
  /**
   * When used as direct property decorator, e.g.
   *
   * ```ts
   * @decorator
   * public property: SomeType;
   * ```
   */
  function decorator<K extends keyof any, O extends {[k in K]: T}>(target: O, property: K): void;
  /**
   * When used as accessor property decorator, e.g.
   *
   * ```ts
   * @decorator
   * public get property(): SomeType {
   *   // ...
   * }
   *
   * public set property(p: SomeType) {
   *   // ...
   * }
   * ```
   */
  function decorator<K extends keyof any, O extends {[k in K]: T}>(
    target: O,
    property: K,
    descriptor: TypedPropertyDescriptor<T>,
  ): TypedPropertyDescriptor<T> | void;
  function decorator<K extends keyof any, O extends {[k in K]: T}>(
    target: O,
    property: K,
    descriptor?: TypedPropertyDescriptor<T> & ThisType<O>,
  ): TypedPropertyDescriptor<T> | void {
    let shouldReturnDescriptor = true;

    if (descriptor == null) {
      // Normally no descriptor will be present on the target, but one could have been created by
      // another decorator that ran before this one.
      descriptor = Object.getOwnPropertyDescriptor(target, property) || {
        value: undefined,
        // Object.defineProperty properties are by default not enumerable, not writable and not
        // configurable
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/defineProperty
        configurable: true,
        enumerable: true,
        writable: true,
      };

      shouldReturnDescriptor = false;
    }

    const originalSet = descriptor.set;
    if (typeof originalSet === 'function') {
      // In case of the get/set pair (or set-only property) we simply wrap the set with our
      // wrapper

      // This decorator has been put on an accessor, so we need to return the modified property
      // descriptor (per https://www.typescriptlang.org/docs/handbook/decorators.html)
      descriptor = {
        ...descriptor,
        set(value: T) {
          originalSet.call(this, wrapper(value));
        },
      };
    } else if (typeof descriptor.get === 'function' || descriptor.writable === false) {
      // This is a get-only property, so it's quite impossible to wrap the setter!
      throw new Error(`Cannot wrap setter of readonly property ${property} on ${getName(target)}`);
    } else {
      // In case of a regular property we need to write both getter and setter

      // We won't store the actual value on the instance but use a WeakMap
      // This prevents accidental name clashes (what would we call the hidden property?
      // typescript doesn't know the hidden property exists!)
      const values = new WeakMap<typeof target, T>();

      // A descriptor can't have value/writable and a get/set pair
      // There is be no way this descriptor.value is not undefined, so just delete it
      delete descriptor.value;
      delete descriptor.writable;

      // Property decorators need to write the property themselves (per
      // https://www.typescriptlang.org/docs/handbook/decorators.html)
      descriptor = {
        ...descriptor,
        get(this: O) {
          return values.get(this)!;
        },
        set(this: O, value: any) {
          values.set(this, wrapper(value));
        },
      };
    }

    if (shouldReturnDescriptor) {
      return descriptor;
    } else {
      Object.defineProperty(target, property, descriptor);
    }
  }

  return decorator;
}
