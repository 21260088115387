/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {DebugLogger} from './debug-logger';
import {Logger, ProductionLoggerListener} from './interfaces';

declare global {
  interface Window {
    atlas?: {
      enableConsoleLogging?(): void;
    };
  }
}

/**
 * Implementation of the Logger interface for production apps. This logger doesn't log to the
 * console, but sends the messages to registered observables on the LoggerEventsService.
 */
export class ProductionLogger implements Logger {
  private readonly consoleLogger: Logger;

  public constructor(
    private readonly name: string,
    private readonly loggerEventsServiceImplementation: ProductionLoggerListener,
    window?: Window,
  ) {
    if (window != null) {
      if (window.atlas == null) {
        Object.defineProperty(window, 'atlas', {
          value: {},
          enumerable: false,
          configurable: true,
        });
      }

      if (window.atlas!.enableConsoleLogging == null) {
        window.atlas!.enableConsoleLogging = () => {
          window.name = `atlas-log-to-console|${window.name}`;
        };
      }

      this.isConsoleLoggingEnabled = () => /\batlas-log-to-console\b/.test(window.name);
    }

    this.consoleLogger = new DebugLogger(name);
  }

  private readonly isConsoleLoggingEnabled = () => false;

  public debug(message: any, ...args: any[]): void {
    if (this.isConsoleLoggingEnabled()) {
      this.consoleLogger.debug(message, ...args);
    }
  }

  public info(message: any, ...args: any[]): void {
    if (this.isConsoleLoggingEnabled()) {
      this.consoleLogger.info(message, ...args);
    }
  }

  public warn(message: any, ...args: any[]): void {
    this.loggerEventsServiceImplementation.onWarning(this.name, [message, ...args]);

    if (this.isConsoleLoggingEnabled()) {
      this.consoleLogger.warn(message, ...args);
    }
  }

  public error(message: any, ...args: any[]): void {
    this.loggerEventsServiceImplementation.onError(this.name, [message, ...args]);

    if (this.isConsoleLoggingEnabled()) {
      this.consoleLogger.error(message, ...args);
    }
  }
}
