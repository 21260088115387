import {Component} from '@angular/core';

/**
 * FlexGridComponent - Add CSS styling for responsive container
 *
 * @ngModule FlexGridModule
 */
@Component({
  selector: 'maia-flex-grid',
  templateUrl: './flex-grid.component.html',
  styleUrls: ['./flex-grid.component.scss'],
})
export class FlexGridComponent {}
