/*
 * This file contains the basic tsfr validators
 * to check whether a tsfr is actually a valid tsfr.
 */

import {validateModulo} from '../base/modulo-validator';
import {
  AtlasInvalidCharactersValidationErrors,
  AtlasInvalidCheckDigitsValidationErrors,
  AtlasInvalidLengthValidationErrors,
  AtlasMaxLengthValidationErrors,
  AtlasMinLengthValidationErrors,
  isValid,
  RESULT_VALID,
  ValidationErrors,
  ValidatorInput,
  ValidValidationResult,
} from '../base/validators';

import {Tsfr} from './businesstype';
import {TSFR_MAX_LENGTH, TSFR_SPLIT_CHECKDIGIT_LENGTH, VALID_CHARS_REGEX} from './constants';

type AtlasTsfrValidationLength =
  | AtlasMaxLengthValidationErrors
  | AtlasInvalidLengthValidationErrors
  | AtlasMinLengthValidationErrors
  | ValidValidationResult;
type AtlasTsfrValidationCheckDigits =
  | AtlasInvalidCheckDigitsValidationErrors
  | ValidValidationResult;
type AtlasTsfrValidationChars = AtlasInvalidCharactersValidationErrors | ValidValidationResult;

/**
 * Checks the validity of the given input's length.
 *
 * @param value The tsfr input to validate
 */
function invalidLength(value: ValidatorInput<Tsfr>): AtlasTsfrValidationLength {
  if (Tsfr.isTsfr(value)) {
    return RESULT_VALID;
  }

  if (value) {
    return validateLength(value);
  } else {
    return {
      atlasMinLength: {
        limit: TSFR_MAX_LENGTH,
        actualValue: 0,
      },
    };
  }
}

/**
 * Checks whether the given input is in an invalid format.
 *
 * The format is considered invalid if the check digit validation fails
 *
 * @param value The tsfr input to validate
 */
function invalidCheckDigits(value: ValidatorInput<Tsfr>): AtlasTsfrValidationCheckDigits {
  if (Tsfr.isTsfr(value)) {
    return RESULT_VALID;
  }

  // Do not trigger checksum validations until validateCharacters is validated otherwise we can
  // have big.js not valid number exception
  if (
    value &&
    validateCharacters(value) === RESULT_VALID &&
    !validateModulo(
      value.substr(0, TSFR_SPLIT_CHECKDIGIT_LENGTH),
      value.substr(TSFR_SPLIT_CHECKDIGIT_LENGTH),
    )
  ) {
    return {
      atlasInvalidCheckDigits: true,
    };
  } else {
    return RESULT_VALID;
  }
}

function invalidCharacters(value: ValidatorInput<Tsfr>): AtlasTsfrValidationChars {
  if (Tsfr.isTsfr(value)) {
    return RESULT_VALID;
  }
  if (value) {
    return validateCharacters(value);
  } else {
    return RESULT_VALID;
  }
}

/**
 * The BaseTsfrValidators object contains partial tsfr validators, returning
 * information on the invalidity instead of simply returning a boolean
 * valid/invalid result.
 *
 * @deprecated Use `validateTsfr` instead
 */
export const BaseTsfrValidators: {
  /**
   * Checks the validity of the given input's length.
   *
   * @param value The tsfr input to validate
   */
  readonly invalidLength: (value: ValidatorInput<Tsfr>) => AtlasTsfrValidationLength;
  /**
   * Checks whether the given input is in an invalid format.
   *
   * The format is considered invalid if the check digit validation fails
   *
   * @param value The tsfr input to validate
   */
  readonly invalidCheckDigits: (value: ValidatorInput<Tsfr>) => AtlasTsfrValidationCheckDigits;
  readonly invalidCharacters: (value: ValidatorInput<Tsfr>) => AtlasTsfrValidationChars;
} = {
  invalidLength,
  invalidCheckDigits,
  invalidCharacters,
};

function validateCharacters(inputTsfr: string): AtlasTsfrValidationChars {
  if (
    inputTsfr &&
    validateLength(inputTsfr) === RESULT_VALID &&
    VALID_CHARS_REGEX.test(inputTsfr)
  ) {
    return RESULT_VALID;
  } else {
    return {
      atlasInvalidCharacters: true,
    };
  }
}

/**
 *
 * Performs the length check on the given tsfr number
 *
 * @param inputTsfr The tsfr string to validate
 * @returns True in case the tsfr has the correct length
 */
function validateLength(inputTsfr: string): AtlasTsfrValidationLength {
  const {length} = inputTsfr;

  if (length < TSFR_MAX_LENGTH) {
    return {
      atlasMinLength: {
        limit: TSFR_MAX_LENGTH,
        actualValue: length,
      },
    };
  }

  if (length === TSFR_MAX_LENGTH) {
    return RESULT_VALID;
  } else {
    return {
      atlasMaxLength: {
        limit: TSFR_MAX_LENGTH,
        actualValue: length,
      },
    };
  }
}

/**
 * Checks whether the tsfr input is valid or not.
 *
 * @param input The tsfr input to validate
 */
export function isValidTsfr(input: ValidatorInput<Tsfr>): boolean {
  return isValid(validateTsfr(input));
}

/**
 * Validate the given tsfr input
 *
 * @param input The tsfr to validate
 */
export function validateTsfr(
  input: ValidatorInput<Tsfr>,
): ValidationErrors | ValidValidationResult {
  if (Tsfr.isTsfr(input)) {
    return RESULT_VALID;
  }

  return invalidLength(input) ?? invalidCharacters(input) ?? invalidCheckDigits(input);
}
