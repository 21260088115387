import {ChangeDetectionStrategy, Component, forwardRef, ViewChild} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {DropdownHost} from '@maia/dropdowns';
import {OptionContainer as SelectOptionContainer} from '@maia/select';

import {OptionContainer as AutocompleteOptionContainer} from '../option/option-container';

import {BaseInputAutocompleteComponent} from './base-input-autocomplete.component';

/**
 * Select component to be used inside forms
 *
 * If you want to use a select outside of forms, look at `<maia-select>` (@maia/select) instead.
 *
 * This component expects an input inside, beit a real `<input>` or a `<maia-fake-input>`. All input
 * handling is handled through the input element. This element should never be focused directly.
 *
 * Example usage:
 *
 * ```html
 * <maia-input-autocomplete
 *     name="value" [(ngModel)]="value"
 *     [disabled]="disabled | atlasBoolean">
 *   <input maiaInput
 *     placeholder="Select value">
 *
 *   <!-- options is a list of Text instances -->
 *   <maia-option *ngFor="let option of options" [value]="option">
 *     Option {{ option | atlasText }}
 *   </maia-option>
 * </maia-input-autocomplete>
 * ```
 *
 * This component follows the WAI ARIA guidelines for a collapsible listbox, it is fully accessible
 * using keyboard and screenreader.
 *
 * @see [WAI ARIA collapsible listbox](https://www.w3.org/TR/wai-aria-practices-1.1/examples/listbox/listbox-collapsible.html)
 * @ngModule InputAutocompleteModule
 */
@Component({
  selector: 'maia-input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: BaseInputAutocompleteComponent,
      useExisting: forwardRef(() => InputAutocompleteComponent),
    },
    {provide: SelectOptionContainer, useExisting: forwardRef(() => InputAutocompleteComponent)},
    {
      provide: AutocompleteOptionContainer,
      useExisting: forwardRef(() => InputAutocompleteComponent),
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputAutocompleteComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputAutocompleteComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class InputAutocompleteComponent<T> extends BaseInputAutocompleteComponent<T> {
  @ViewChild('dropdownHost', {static: true})
  public dropdownHost: DropdownHost;

  public openDropdown(event: Event): void {
    this._open();

    event.preventDefault();
    event.stopPropagation();
  }
}
