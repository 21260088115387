import {Injectable} from '@angular/core';
import {convertToParamMap, UrlHandlingStrategy, UrlTree} from '@angular/router';

const parametersToKeep: string[] = ['adobe_mc'];

@Injectable()
export class KeepRouteParamsUrlHandlingStrategy implements UrlHandlingStrategy {
  public shouldProcessUrl() {
    return true;
  }

  public merge(newUrlPart: UrlTree, rawUrl: UrlTree): UrlTree {
    const queryParams = {...newUrlPart.queryParams};

    const newParamMap = newUrlPart.queryParamMap;
    const rawParamMap = rawUrl.queryParamMap;

    for (const param of parametersToKeep) {
      if (!rawParamMap.has(param)) {
        continue;
      }

      const value = newParamMap.get(param) || rawParamMap.get(param);

      if (value != null) {
        queryParams[param] = value;
      } else {
        delete queryParams[param];
      }
    }

    return {
      ...newUrlPart,
      queryParams,
      queryParamMap: convertToParamMap(queryParams),
    };
  }

  public extract(url: UrlTree): UrlTree {
    return url;
  }
}
