import {Component} from '@angular/core';

import {HeaderWithBackgroundComponent} from './header-with-background.component';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'header[bifrostHeaderWithFixedBackground]',
  templateUrl: './header-with-fixed-background.component.html',
  styleUrls: [
    './header.component.scss',
    './header-with-background.component.scss',
    './header-with-fixed-background.component.scss',
  ],
})
export class HeaderWithFixedBackgroundComponent extends HeaderWithBackgroundComponent {}
