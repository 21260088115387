import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';
import {ModalsModule} from '@maia/modals';

import {ContextualMessageComponent} from './contextual-message/contextual-message.component';
import {TooltipContentComponent} from './tooltip-message/tooltip-content/tooltip-content.component';
import {TooltipMessageComponent} from './tooltip-message/tooltip-message.component';
import {InlineMessageComponent} from './inline-message/inline-message.component';
import {μInlineMessagesSvgModule} from '@maia/icons';

@NgModule({
  entryComponents: [TooltipContentComponent],
  imports: [BusinessTypesModule, CommonModule, ModalsModule, μInlineMessagesSvgModule],
  declarations: [
    ContextualMessageComponent,
    TooltipMessageComponent,
    TooltipContentComponent,
    InlineMessageComponent,
  ],
  exports: [ContextualMessageComponent, TooltipMessageComponent, InlineMessageComponent],
})
export class MessagesModule {}
