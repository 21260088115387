export type ValidValidationResult = null;

export interface ValidationErrors {
  [validatorName: string]: any;
}

export const RESULT_VALID: ValidValidationResult = null;

export function isValid<InvalidValidationResult extends ValidationErrors>(
  validationResult: InvalidValidationResult | ValidValidationResult,
): validationResult is ValidValidationResult {
  return validationResult === RESULT_VALID;
}

/**
 * Possible input to a validator for the given BT businesstype.
 */
export type ValidatorInput<BT> = BT | string | null | undefined;

/**
 * A validator is used to validate string input values.
 */
export type Validator<InputType, InvalidValidationResult extends ValidationErrors> = (
  value: InputType | string | null | undefined,
) => InvalidValidationResult | ValidValidationResult;

export function combineResults(
  ...results: (ValidationErrors | ValidValidationResult)[]
): ValidationErrors | ValidValidationResult {
  const merged: ValidationErrors = {};
  let hasError = false;

  for (const result of results) {
    if (!isValid(result)) {
      hasError = true;
      Object.assign(merged, result);
    }
  }

  return hasError ? merged : RESULT_VALID;
}

/*
 * The following interfaces describe ValidationErrors for validations that are shared
 * over multiple businesstypes, e.g. `Date`, `Iban` and `Bban` all share
 * `AtlasInvalidLengthValidationErrors`.
 */

/**
 * Result object used by a validator when a limit is not respected.
 */
export interface InvalidLimitResult<T> {
  /**
   * The limit that the input value didn't respect.
   */
  limit: T;

  /**
   * The value that didn't respect the limit.
   */
  actualValue: T;
}

/**
 * Validation errors object when the length of a string is too long.
 */
export interface AtlasMaxLengthValidationErrors extends ValidationErrors {
  atlasMaxLength: InvalidLimitResult<number>;
}

/**
 * Validation errors object when the length of a string is too short.
 */
export interface AtlasMinLengthValidationErrors extends ValidationErrors {
  atlasMinLength: InvalidLimitResult<number>;
}

/**
 * Validation errors object when the length of a string is too short.
 */
export interface AtlasInvalidLengthValidationErrors extends ValidationErrors {
  atlasInvalidLength: {
    /**
     * The actual length of the input, always different from the `requiredLength` property.
     */
    actualLength: number;

    /**
     * The required length of the input.
     */
    requiredLength: number;
  };
}

/**
 * Validation errors when when invalid characters are present.
 */
export interface AtlasInvalidCharactersValidationErrors extends ValidationErrors {
  atlasInvalidCharacters: true;
}

/**
 * Validation errors object when the input has an invalid format.
 */
export interface AtlasInvalidFormatValidationErrors extends ValidationErrors {
  atlasInvalidFormat: true;
}

/**
 * Validation errors when when invalid characters are present.
 */
export interface AtlasInvalidCheckDigitsValidationErrors extends ValidationErrors {
  atlasInvalidCheckDigits: true;
}
