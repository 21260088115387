import {ChangeDetectionStrategy, Component, ElementRef, ViewChild, OnInit} from '@angular/core';

/**
 * Enriched Divider
 * @ngModule DividerModule
 */
@Component({
  selector: 'maia-enriched-divider',
  templateUrl: './enriched-divider.component.html',
  styleUrls: ['./enriched-divider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrichedDividerComponent implements OnInit {
  @ViewChild('content', {read: ElementRef, static: true}) public readonly content: ElementRef;

  public ngOnInit(): void {
    const element = this.content.nativeElement as HTMLElement;
    if (element.children.length === 0) {
      throw new Error('Cannot use this component if icon or text were not defined.');
    }
  }
}
