import {Hidden} from '@atlas/businesstypes';

export interface ConnectorResponseBaseConstructorArguments<O> {
  data?: O;
  message?: string;
}

export interface ConnectorResponseConstructorArguments<O>
  extends ConnectorResponseBaseConstructorArguments<O> {
  data: O;
}

export interface ConnectorErrorResponseConstructorArguments<O>
  extends ConnectorResponseBaseConstructorArguments<O> {
  httpResponseCode?: number;
}

export abstract class ConnectorResponseBase<O> {
  public readonly data?: O;
  public readonly message?: string;

  protected constructor(input: ConnectorResponseBaseConstructorArguments<O>) {
    this.data = input.data;
    this.message = input.message;
  }

  public abstract isSuccess(): boolean;
  public abstract isWarning(): boolean;
  public abstract isSigningRequired(): boolean;
  public abstract isRetrySigning(): boolean;
  public abstract isError(): boolean;
  public abstract isTimeout(): boolean;
  public abstract isUnknown(): boolean;
  public abstract isHttpError(): boolean;
}

export abstract class ConnectorResponse<O> extends ConnectorResponseBase<O> {
  public readonly data!: O;

  protected constructor(input: ConnectorResponseConstructorArguments<O>) {
    super(input);
  }

  public isSigningRequired(): boolean {
    return false;
  }

  public isRetrySigning(): boolean {
    return false;
  }

  public isError(): boolean {
    return false;
  }

  public isTimeout(): boolean {
    return false;
  }

  public isUnknown(): boolean {
    return false;
  }

  public isHttpError(): boolean {
    return false;
  }
}

export abstract class ConnectorSigningResponse<O> extends ConnectorResponse<O> {
  protected constructor(input: ConnectorResponseConstructorArguments<O>) {
    super(input);
  }

  public abstract getSigningId(): Hidden | undefined;
}

export abstract class ConnectorErrorResponse<O> extends ConnectorResponseBase<O> {
  public readonly httpResponseCode?: number;

  protected constructor(input: ConnectorErrorResponseConstructorArguments<O>) {
    super(input);
    this.httpResponseCode = input.httpResponseCode;
  }

  public isSuccess(): boolean {
    return false;
  }

  public isWarning(): boolean {
    return false;
  }

  public isSigningRequired(): boolean {
    return false;
  }

  public isRetrySigning(): boolean {
    return false;
  }
}
