import {Injectable} from '@angular/core';
import {defer, Observable, of} from 'rxjs';

import {OpenUrlService} from '../open-url/open-url.service';

import {PlatformDownloadManager} from './download-manager';
import {Target} from './target';

/**
 * Provider responsible for downloading dynamic resources from a server. Uses dynamic resource url
 * resolvers to resolve the url to the resource to be downloaded. Extra query parameters can be
 * passed via a query map.
 */
@Injectable({
  providedIn: 'root',
})
export class BrowserDownloadManager implements PlatformDownloadManager {
  public constructor(private readonly openUrlService: OpenUrlService) {}

  /**
   * Downloads (and opens) a (static) file url.
   * @param url
   * @param target
   * @param type
   */
  public downloadWithUrl(url: string, target: Target = Target.BLANK): Observable<void> {
    return defer(() => {
      this.openUrlService.open(url, new Map<string, string>(), target);
      return of(undefined);
    });
  }

  /**
   * Whether a file can be opened
   * Note: on desktop this is always true.
   */
  public canOpen(): Observable<boolean> {
    return of(true);
  }
}
