import {
  BaseDecimalValidator,
  MaxLengthFractionPartDecimalValidator,
  MaxLimitDecimalValidator,
  MinLimitDecimalValidator,
} from './validator';

export {DecimalDirective} from './directive';
export {DecimalPipe} from './pipe';
export {ExtraDecimalControlValidators} from './validator';

export const DECIMAL_VALIDATORS = [
  BaseDecimalValidator,
  MaxLengthFractionPartDecimalValidator,
  MaxLimitDecimalValidator,
  MinLimitDecimalValidator,
];
