import {ChangeDetectionStrategy, Component, HostBinding, Input, NgModule, ViewEncapsulation} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

/**
 * @ngModule NavigationFuncOutlineArrowSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaNavigationFuncOutlineArrow]',
  template: '<svg:g class="nc-icon-wrapper"><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04" d="M4 12h10.4"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04" d="M14.4 8l5.6 4-5.6 4" data-color="color-2"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
    
    ':host(.maia-icon--down) {transform: rotate(90deg);} :host(.maia-icon--left) {transform: rotate(180deg);} :host(.maia-icon--up) {transform: rotate(-90deg);}',
  ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationFuncOutlineArrowSvgComponent {
  @Input()
  @HostBinding('class.maia-icon--right')
  @coerceBooleanPrimitive()
  public right = true;

  @Input()
  @HostBinding('class.maia-icon--down')
  @coerceBooleanPrimitive()
  public down = false;

  @Input()
  @HostBinding('class.maia-icon--left')
  @coerceBooleanPrimitive()
  public left = false;

  @Input()
  @HostBinding('class.maia-icon--up')
  @coerceBooleanPrimitive()
  public up = false;
}

@NgModule({
  declarations: [
    NavigationFuncOutlineArrowSvgComponent,
  ],
  exports: [
    NavigationFuncOutlineArrowSvgComponent,
  ],
})
export class NavigationFuncOutlineArrowSvgModule {}
