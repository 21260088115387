import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Component, HostBinding, Input} from '@angular/core';

/**
 * FlexGridRowComponent - Creates a responsive row in display="flex"
 *
 * @ngModule FlexGridModule
 */
@Component({
  selector: 'maia-flex-grid-row',
  templateUrl: './flex-grid-row.component.html',
  styleUrls: ['./flex-grid-row.component.scss'],
})
export class FlexGridRowComponent {
  public _nogutter: boolean;

  @Input()
  @HostBinding('class.p-maia-grid-row--no-gutters')
  public get gutter(): boolean {
    return this._nogutter;
  }

  public set gutter(gutter: boolean) {
    this._nogutter = !coerceBooleanProperty(gutter);
  }
}
