import {ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2} from '@angular/core';
import {CssClassUtility, CssClassUtilityFactory} from '@maia/core';

/**
 * All valid values for the `type` property of ContextualMessageComponent.
 */
export type ContextualMessageType = 'error' | 'informative' | 'success' | 'warning';

const DEFAULT_CONTEXTUAL_MESSAGE_TYPE: ContextualMessageType = 'informative';

const CLASSES = {
  type: {
    error: 'maia-contextual-message--error',
    informative: 'maia-contextual-message--informative',
    success: 'maia-contextual-message--success',
    warning: 'maia-contextual-message--warning',
  } as {[key in ContextualMessageType]: string | null},
};

/**
 * Contextual message component. Contextual message has a type, which decides the colour scheme to
 * use.
 *
 * @ngModule MessagesModule
 */
@Component({
  selector: 'maia-contextual-message',
  template: '<ng-content></ng-content>',
  styleUrls: ['./contextual-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextualMessageComponent {
  private readonly _cssClassUtility: CssClassUtility<typeof CLASSES>;
  private _type: ContextualMessageType;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(CLASSES, renderer, elementRef);
    this.type = DEFAULT_CONTEXTUAL_MESSAGE_TYPE;
  }

  /**
   * The message type.
   */
  @Input('type')
  public get type(): ContextualMessageType {
    return this._type;
  }

  public set type(newType: ContextualMessageType) {
    if (newType === this._type) {
      return;
    }

    this._type = newType;
    this._cssClassUtility.setValue('type', newType);
  }
}
