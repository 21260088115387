import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import {NgControl} from '@angular/forms';
import {coerceNumberPrimitive} from '@atlas-angular/cdk/coercion';
import {onDestroy, takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {
  InputContainer,
  μMarkAllowedInWideFormElementProvider as MarkAllowedInWideFormElementProvider,
} from '@maia/forms';
import {map} from 'rxjs/operators';

import {TextAreaLimitComponent} from './text-area-limit.component';

/**
 * Basic textarea component, without any extras like masks, datepickers etc.
 *
 * @ngModule TextAreaModule
 */
@Component({
  selector: 'textarea[maiaTextArea]',
  template: '',
  styleUrls: ['./text-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {'[attr.maxLength]': '_parentElement ? maxLength : null'},
  providers: [MarkAllowedInWideFormElementProvider],
})
@UntilDestroy()
export class TextAreaComponent implements AfterContentChecked, OnInit {
  @Input('atlasMaxLength')
  @coerceNumberPrimitive()
  public maxLength: number;

  private _previousLength: number;

  /**
   * nbRemainingCharacters is used to inform about the remaining characters to reach the maxLength
   * of textarea
   */
  @Output()
  public readonly nbRemainingCharacters = new EventEmitter<number>();

  public constructor(
    private readonly textArea: ElementRef,
    @Optional() public readonly _parentElement?: TextAreaLimitComponent,
  ) {}

  public ngOnInit() {
    if (this.maxLength) {
      // previousLength is used to avoid the infinitive loop for using promises inside
      // ngAfterViewChecked
      this._previousLength = -1;
    }
  }

  /**
   * input event is used to handle the user input when the limit is reached on Android devices
   * https://bugs.chromium.org/p/chromium/issues/detail?id=118639#c259
   * @param event the input event
   */
  @HostListener('input')
  public onInput() {
    if (
      this.maxLength &&
      this._parentElement &&
      this.textArea.nativeElement.value.length >= this.maxLength
    ) {
      this.textArea.nativeElement.value = this.textArea.nativeElement.value.slice(
        0,
        this.maxLength,
      );
    }
  }

  public ngAfterContentChecked() {
    // The promise is defined and resolved in case there are maxLength characters and previousLength
    // is different than the new Input
    if (this.maxLength && this._previousLength !== this.textArea.nativeElement.value.length) {
      Promise.resolve().then(() => {
        this._previousLength = this.textArea.nativeElement.value.length;
        this.nbRemainingCharacters.emit(this.maxLength - this.textArea.nativeElement.value.length);
      });
    }
    if (this._parentElement) {
      this._parentElement.actualValue = this.textArea.nativeElement.value.length;
    }
  }
}

/**
 * This class hooks the textarea into the maia form element and manage ngModel's disabled state
 *
 * @ngModule TextAreaModule
 */
@Directive({
  selector:
    'textarea[maiaTextArea][ngModel], textarea[maiaTextArea][formControl], textarea[maiaTextArea][formControlName]',
  host: {
    '[attr.disabled]': 'disabled ? "" : null',
  },
})
@UntilDestroy()
export class TextAreaWithNgModelDirective implements OnInit {
  private _disabled = false;

  public constructor(
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly _ngControl: NgControl,
    @Optional() private readonly _container?: InputContainer,
  ) {}

  public get disabled(): boolean {
    return this._disabled;
  }

  public ngOnInit() {
    const control = this._ngControl.control!;

    if (this._container != null) {
      const destroyFn = this._container.registerFormControl(this._ngControl);
      onDestroy(this).subscribe(destroyFn);
    }

    control.statusChanges
      .pipe(
        takeUntilDestroyed(this),
        map(() => control.disabled),
      )
      .subscribe(disabled => {
        if (disabled !== this._disabled) {
          this._disabled = disabled;
          this._changeDetector.detectChanges();
        }
      });
  }
}
