import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DividerModule} from '@maia/divider';
import {DropdownsModule} from '@maia/dropdowns';
import {SelectDropdownModule} from '@maia/dropdowns/select';
import {FormsModule as MaiaFormsModule} from '@maia/forms';
import {FormsCaptureModule} from '@maia/forms/capture';
import {μDropdownListsSvgModule} from '@maia/icons';
import {InputSelectModule as MaiaInputSelectModule} from '@maia/input-select';
import {SelectPartsModule} from '@maia/select';

import {μContentDropdownOpenAsSlideInDirective} from './content-dropdown/content-dropdown-opener.directive';
import {ContentDropdownComponent} from './content-dropdown/content-dropdown.component';
import {IconTextComponent} from './icon-text/icon-text.component';
import {InputWithSelectWithControlDirective} from './input-with-select/input-with-select-with-control.directive';
import {InputWithSelectComponent} from './input-with-select/input-with-select.component';

@NgModule({
  imports: [
    DropdownsModule,
    μDropdownListsSvgModule,
    CommonModule,
    FormsModule,
    MaiaFormsModule,
    ReactiveFormsModule,
    SelectPartsModule,
    SelectDropdownModule,
    MaiaInputSelectModule,
    DividerModule,
  ],
  declarations: [
    ContentDropdownComponent,
    IconTextComponent,
    InputWithSelectComponent,
    μContentDropdownOpenAsSlideInDirective,
    InputWithSelectWithControlDirective,
  ],
  exports: [
    FormsCaptureModule,

    IconTextComponent,
    InputWithSelectComponent,
    μContentDropdownOpenAsSlideInDirective,
    InputWithSelectWithControlDirective,
  ],
})
export class InputWithSelectModule {}
