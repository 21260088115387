import {BusinessType} from '@atlas/businesstypes';
import {AnyBusinessType, DeepTypedObject} from '@atlas/convertor';
import {PlainJSONCall} from '@atlas/convertor-plain-json';

const ENCRYPTION_SUFFIX = '_Enc';

export type Constructor<T> = new (...args: any[]) => T;

export type CtorObject<T extends {}> = {
  // -? makes all properties required
  // awesome syntax is awesome
  [key in keyof T]-?: T[key] extends (infer U)[]
    ? U extends BusinessType<unknown>
      ? [Constructor<U>]
      : [CtorObject<U>]
    : T[key] extends BusinessType<unknown>
    ? Constructor<T[key]>
    : CtorObject<T[key]>;
};

export abstract class βEnhancedPlainJsonCall<I, O> extends PlainJSONCall<I, O> {
  public constructor(private readonly outputCtorObject: CtorObject<O>) {
    super();
  }

  public convertMessage(message: DeepTypedObject<AnyBusinessType>): O {
    return convert(this.outputCtorObject)(message);
  }
}

export const convert = <O>(structure: CtorObject<O>) => (input: DeepTypedObject<any>): O => {
  return (Object.keys(structure) as (keyof typeof structure)[]).reduce((result, key) => {
    const inputKey = key as any;
    const ctor = structure[key];
    if (input[inputKey] == null) {
      return result;
    }
    if (typeof ctor === 'function') {
      result[key] = new (ctor as Constructor<O[typeof key]>)(
        input[inputKey],
        input[inputKey + ENCRYPTION_SUFFIX],
      );
    } else if (Array.isArray(ctor)) {
      const entryCtor = ctor[0];
      if (typeof entryCtor === 'object') {
        result[key] = input[inputKey].map(convert(entryCtor));
      } else if (typeof entryCtor === 'function') {
        const list = [];
        for (const entry of input[inputKey]) {
          list.push(new (entryCtor as Constructor<O[typeof key]>)(entry));
        }
        result[key] = (list as unknown) as O[keyof O];
      } else {
        throw new Error(`Unexpected type: ${typeof entryCtor}`);
      }
    } else {
      result[key] = convert(ctor as CtorObject<O[typeof key]>)(input[inputKey]);
    }
    return result;
  }, {} as O);
};
