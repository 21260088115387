/* eslint-disable import/no-extraneous-dependencies */
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  InjectionToken,
} from '@angular/core';
import {Text} from '@atlas/businesstypes';

import {µSystemMessage} from './system-message.interface';
import {MessageType} from './system-message.utils';

export const SYSTEM_MESSAGE = new InjectionToken<µSystemMessage>('systemMessage');
/**
 * Internal system messages Component
 * This component is not to be used inline
 * Use the SystemMessageController service for this system message
 *
 * @ngModule SystemMessagesModule
 */
@Component({
  selector: 'maia-internal-system-message',
  templateUrl: './system-message.component.html',
  styleUrls: ['./system-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class µSystemMessageComponent {
  @HostBinding('class.maia__info')
  public get infoClass(): boolean {
    return this.messageType === 'info';
  }
  @HostBinding('class.maia__success')
  public get successClass(): boolean {
    return this.messageType === 'success';
  }
  @HostBinding('class.maia__warning')
  public get warningClass(): boolean {
    return this.messageType === 'warning';
  }
  @HostBinding('class.maia__error')
  public get errorClass(): boolean {
    return this.messageType === 'error';
  }

  public messageType: MessageType;
  public messageContent: string | Text;
  public messageActionLabel?: string | Text;
  public messageAction?: (this: void) => void = undefined;
  public autoCloseTime?: number;
  public hideClose?: boolean;
  public onClose?: (this: void) => void = undefined;

  private readonly doClose: (this: void) => void;

  public constructor(@Inject(SYSTEM_MESSAGE) public readonly options: µSystemMessage) {
    this.messageType = options.messageType;
    this.messageContent = options.messageContent;
    this.messageActionLabel = options.messageActionLabel;
    this.messageAction = options.messageAction;
    this.autoCloseTime = options.autoCloseTime;
    this.hideClose = options.hideClose;
    this.onClose = options.onClose;

    // We know this exists because we set it ourselves in the systemMessageController
    this.doClose = options.doClose!;

    //TODO add an error when hideClose is active but there is no autoClose defined
  }

  public closeSystemMessage(): void {
    // User supplied method to execute on system message close
    if (this.onClose) {
      this.onClose();
    }
    // Method to complete the system message subscription
    if (this.doClose) {
      this.doClose();
    }
  }
}
