import {BusinessType} from '@atlas/businesstypes';
import {
  AnyBusinessType,
  DeepTypedObject,
  DeepTypedUnknownObject,
  mapValues,
} from '@atlas/convertor';

import {businessTypeConvertor} from './businesstype-convertors';

function mapToPlainJsonHelper(value: AnyBusinessType | DeepTypedObject<AnyBusinessType>): any {
  if (value instanceof BusinessType) {
    return businessTypeConvertor.fromBusinessType(value);
  }

  return mapToPlainJson(value);
}

/**
 * Maps an object containing `BusinessType`s into an object containing TypedJSON.
 */
export function mapToPlainJson(value: DeepTypedUnknownObject): DeepTypedObject<string> {
  return mapValues(value, mapToPlainJsonHelper);
}
