import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  Input,
  Optional,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Date, DateKey, DateUtils} from '@atlas/businesstypes';
import {DropdownHost, DropdownPosition, DropdownTemplateContext} from '@maia/dropdowns';
import {InputContainer} from '@maia/forms';
import {ModalConfirmedResult, ModalResolution, ModalResult} from '@maia/modals';
import {filter, finalize, map, tap} from 'rxjs/operators';

import {MAXIMUM_DROPDOWN_WIDTH, MINIMUM_DROPDOWN_WIDTH} from '../constants';

/**
 * A month input. This input provides a dropdown where a year and month can be selected.
 *
 * @ngModule DatePickersModule
 */
@Component({
  selector: 'maia-input-month',
  templateUrl: './input-month.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputMonthComponent), multi: true},
  ],
  styles: [':host { display: block; }'],
})
@UntilDestroy()
export class InputMonthComponent implements ControlValueAccessor, AfterViewInit {
  /**
   * The last complete value.
   */
  private _value?: Date | null = null;

  @ViewChild('dropdownHost', {static: true})
  public _dropdownHost: DropdownHost;

  @ViewChild('dropdownContent', {static: true})
  public dropdownContent: TemplateRef<DropdownTemplateContext<Date>>;

  /**
   * The last allowed date.
   */
  @Input()
  public maximum?: Date;

  /**
   * The first allowed date.
   */
  @Input()
  public minimum?: Date;

  /**
   * The initial year to open.
   */
  @Input()
  public initialYear?: number;

  /**
   * The placeholder to show when there's no value.
   */
  @Input()
  public placeholder: string;

  private _disabled = false;

  private _open = false;

  public constructor(
    private readonly _changeDetector: ChangeDetectorRef,
    @Optional() private readonly _inputContainer?: InputContainer,
  ) {}

  private _onChange = (value: Date | undefined) => {};

  public _onTouched = () => {};

  public get value(): Date | null | undefined {
    return this._value;
  }

  public get month(): number | null | undefined {
    return this._value ? DateUtils.get(this._value, DateKey.Month) : this._value;
  }

  public get year(): number | null | undefined {
    return this._value ? DateUtils.get(this._value, DateKey.Year) : this._value;
  }

  @HostBinding('class.maia-input-month--disabled')
  public get disabled(): boolean {
    return this._disabled;
  }

  /**
   * Opens a datepicker in a dropdown.
   */
  public openDropdown(): void {
    if (this._disabled || this._open) {
      return;
    }

    this._open = true;
    if (this._inputContainer != null) {
      this._inputContainer.focused = true;
    }

    this._dropdownHost
      .prepareTemplate(this.dropdownContent, {
        position: DropdownPosition.BOTTOM_ALIGNED,
        alignedDropdownExtra: {
          maximumSize: MAXIMUM_DROPDOWN_WIDTH,
          minimumSize: MINIMUM_DROPDOWN_WIDTH,
          alternativePosition: DropdownPosition.BOTTOM_LEFT,
        },
      })
      .pipe(
        finalize(() => {
          this._open = false;
          // setting the focused property ensures that the validation errors are shown properly!
          if (this._inputContainer != null) {
            this._inputContainer.focused = false;
          }
        }),
        takeUntilDestroyed(this),
        // call onTouched before filtering on confirmation
        tap(() => this._onTouched()),
        filter<ModalResult<Date>, ModalConfirmedResult<Date>>(
          (result): result is ModalConfirmedResult<Date> =>
            result.resolution === ModalResolution.CONFIRMED,
        ),
        map(result => result.result),
      )
      .subscribe(value => {
        this._value = value;
        this._onChange(value);
        this._changeDetector.detectChanges();
      });
  }

  public ngAfterViewInit(): void {
    if (this._inputContainer != null) {
      this._inputContainer.disabled$.subscribe(() => {
        this._changeDetector.detectChanges();
      });
    }
  }

  // ControlValueAccessor

  public writeValue(obj: any): void {
    this._value = obj;
    this._changeDetector.detectChanges();
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (this._disabled === isDisabled) {
      return;
    }

    this._disabled = isDisabled;
    this._changeDetector.detectChanges();
  }
}
