import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  InjectionToken,
  OnInit,
  TemplateRef,
} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {ModalControl} from '@maia/modals';
import {isObservable, Observable} from 'rxjs';

import {HeaderContainer} from './header/header-container.service';

/**
 * Options for the full view overlay
 */
export interface FullViewOverlayOptions {
  title: string | Observable<string>;
  withMargin?: boolean;
}
export interface FullViewOverlayOptionsWithInput<I> extends FullViewOverlayOptions {
  input: I;
}

export const FULL_VIEW_OVERLAY_OPTIONS = new InjectionToken<FullViewOverlayOptions>(
  'fullViewOverlayOptions',
);

/**
 * Identifier used as type in the modal lifecycle callbacks.
 */
export const MODAL_TYPE_FULL_VIEW_OVERLAY = 'maiaFullViewOverlay';

/**
 * @ngModule LayoutModule
 */
@Component({
  selector: 'maia-full-view-overlay',

  templateUrl: './full-view-overlay.component.html',
  styleUrls: ['./full-view-overlay.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,

  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    tabindex: '-1',
  },
})
@UntilDestroy()
export class FullViewOverlayComponent implements OnInit {
  public header?: TemplateRef<any> = undefined;

  private _title: string;
  private _withMargin: boolean;

  public get title(): string {
    return this._title;
  }

  @HostBinding('class.maia-full-overlay--with-margin')
  public get withMargin(): boolean {
    return this._withMargin;
  }

  public constructor(
    @Inject(FULL_VIEW_OVERLAY_OPTIONS) public options: FullViewOverlayOptions,
    public control: ModalControl<any>,
    private readonly _headerContainer: HeaderContainer,
    private readonly _changeDetector: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    if (isObservable(this.options.title)) {
      this.options.title.pipe(takeUntilDestroyed(this)).subscribe(x => (this._title = x));
    } else {
      this._title = this.options.title;
      if (this.options.withMargin) {
        this._withMargin = this.options.withMargin;
      }
    }

    this._headerContainer.header$.pipe(takeUntilDestroyed(this)).subscribe(header => {
      this.header = header;
      this._changeDetector.detectChanges();
    });
  }
}
