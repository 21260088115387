/**
 * All valid values for the `size` property.
 */
export type DisplaySize =
  | 'tiny-light'
  | 'tiny'
  | 'extra-small-light'
  | 'extra-small'
  | 'small-light'
  | 'small'
  | 'small-bold'
  | 'normal-light'
  | 'normal'
  | 'medium'
  | 'medium-bold'
  | 'large'
  | 'large-bold'
  | 'extra-large'
  | 'grand-bold';

/**
 * The corresponding CSS classes for the size property of a number display component.
 */
export const NumberDisplayClasses = {
  size: {
    'tiny-light': 'maia-number-display--tiny-light',
    tiny: 'maia-number-display--tiny',
    'extra-small-light': 'maia-number-display--extra-small-light',
    'extra-small': 'maia-number-display--extra-small',
    'small-light': 'maia-number-display--small-light',
    small: 'maia-number-display--small',
    'small-bold': 'maia-number-display--small-bold',
    'normal-light': 'maia-number-display--normal-light',
    normal: 'maia-number-display--normal',
    medium: 'maia-number-display--medium',
    'medium-bold': 'maia-number-display--medium-bold',
    large: 'maia-number-display--large',
    'large-bold': 'maia-number-display--large-bold',
    'extra-large': 'maia-number-display--extra-large',
    'grand-bold': 'maia-number-display--grand-bold',
  } as {[key in DisplaySize]: string | null},
};

export const DECIMAL_SEPARATOR = ',';
