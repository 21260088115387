import {NgModule} from '@angular/core';
import {μEmptyStateSvgModule} from '@maia/icons';

import {EmptyStateComponent} from './empty-state.component';

@NgModule({
  imports: [μEmptyStateSvgModule],
  declarations: [EmptyStateComponent],
  exports: [EmptyStateComponent],
})
export class EmptyStateModule {}
