import {BusinessType, createStringifier} from '../base/businesstype';

import {isValidHtml} from './validator-base';

const stringifier = createStringifier('html');

/**
 * BusinessType wrapper around html
 */
export class Html extends BusinessType<string> {
  /**
   * Returns whether or not `value` is an Html.
   */
  public static isHtml(value: unknown): value is Html {
    return value instanceof Html;
  }

  /**
   * Constructs a new Html instance.
   *
   * @param value The html text as a string
   * @param encryptedValue The encryptedValue, if any
   */
  public constructor(value: string, encryptedValue?: string) {
    if (typeof value !== 'string') {
      throw new Error(
        `Expected a string but got ${(typeof value === 'object' &&
          (value as object).constructor.name) ||
          typeof value}`,
      );
    } else if (!isValidHtml(value)) {
      throw new Error(`Cannot create Html for invalid input ${JSON.stringify(value)}`);
    }
    super(value, encryptedValue);
  }

  /**
   * Returns whether `other` is an `Html` representing the same value.
   */
  public equals(other: unknown): boolean {
    if (!other) {
      return false;
    }

    if (other === this) {
      return true;
    }

    return Html.isHtml(other) && this.internalValue === other.internalValue;
  }

  /**
   * Exposes the value of this BusinessType as a Base64 encoded string. The returned value will
   * always be the same.
   */
  public asString(): string {
    return this.internalValue;
  }

  /**
   * Returns a string representation of this BusinessType that can be used when
   * debugging etc. The resulting string will contain at least some type descriptor,
   * a representation of `internalValue` and, if present, the `encryptedValue`.
   *
   * There are no other guarantees regarding the format of the return value. It's
   * format and value should _never_ be depended on.
   *
   * @see Stringifier
   */
  public toString(): string {
    return stringifier(this);
  }
}
