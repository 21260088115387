import {Directive} from '@angular/core';
import {TsfrFormatter} from '@atlas/businesstypes';

import {ValueFormatter} from '../util/value-formatter.util';

import {MaskedInputComponent} from './masked-input.component';

/**
 * @ngModule InputMasksModule
 */
@Directive({
  selector: 'maia-masked-input[atlasTsfr]',
})
export class MaskedInputTsfrDirective implements ValueFormatter {
  public formatValue(value: string): string {
    return value;
  }

  public unformatValue(rawValue: string): string {
    return TsfrFormatter.unformat(rawValue);
  }

  public pasteUnformatValue(value: string): string {
    return value.replace(/\+|\/|\s/g, '');
  }

  public constructor(private readonly _input: MaskedInputComponent) {
    this._input.setMaskAndPlaceholder('+++ ### / #### / ##### +++');
    this._input.setValueFormatter(this);
  }
}
