import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import {Text} from '@atlas/businesstypes';

import {Subscription} from 'rxjs';

import {lexRichText} from './rich-text-lexer.util';
import {RichTextLinkHandler} from './rich-text-link-handler.service';
import {ParserContext, parseRichText} from './rich-text-parser.util';

/**
 * Take a value and parse it in rich text
 *
 * @ngModule RichTextModule
 */
@Component({
  selector: 'maia-rich-text',
  template: '', // no template
  styleUrls: ['./rich-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextComponent implements OnDestroy {
  private _nodes: Node[] = [];
  private _listenerSubscription = new Subscription();
  private readonly _host: HTMLElement;

  /**
   * @param _renderer
   * @param host
   * @param _linkHandler The RichTextLinkHandler service
   */
  public constructor(
    private readonly _renderer: Renderer2,
    host: ElementRef<HTMLElement>,
    private readonly _linkHandler: RichTextLinkHandler,
  ) {
    this._host = host.nativeElement;
  }

  /**
   * Set value parse the value in rich text
   */
  @Input()
  public set value(value: Text | null | undefined) {
    // Clear the existing content in case of value change
    this._clear();

    if (value == null) {
      return;
    }

    const tokens = Array.from(lexRichText(value.asString()));

    const context: ParserContext = {
      tokens,
      length: tokens.length,
      index: 0,

      renderer: this._renderer,
      linkHandler: this._linkHandler,
      subscription: this._listenerSubscription,
    };

    this._nodes.push(...parseRichText(context, this._host));
  }

  private _clear() {
    for (const node of this._nodes) {
      this._renderer.removeChild(this._host, node);
    }
    this._nodes = [];

    this._listenerSubscription.unsubscribe();
    this._listenerSubscription = new Subscription();
  }

  public ngOnDestroy(): void {
    this._clear();
  }
}
