import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ApplicationDisclaimerModule} from '@atlas-angular/application';

import {DisclaimerComponent} from './disclaimer/disclaimer.component';

@NgModule({
  imports: [ApplicationDisclaimerModule, CommonModule],
  declarations: [DisclaimerComponent],
  exports: [DisclaimerComponent],
})
export class DisclaimerModule {}
