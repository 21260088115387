import {animate, state, style, transition, trigger} from '@angular/animations';
import {ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ScrollBlocker, ScrollLock} from '@maia/core';

import {BackdropOptions} from '../interfaces/backdrop-options';
import {ModalControl} from '../interfaces/modal-control';

/**
 * @ngModule ModalsModule
 */
@Component({
  selector: 'maia-backdrop',
  template: '<!-- -->', // comment content to keep angular from flashing on an empty template
  styleUrls: ['./backdrop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  animations: [
    trigger('backdrop', [
      state('in', style({opacity: 1})),
      transition(':enter', [style({opacity: '0'}), animate(300)]),
      transition(':leave', [animate(300, style({opacity: '0'}))]),
    ]),
  ],
  host: {
    '[@backdrop]': '"in"',
    '[class.maia-backdrop--transparent]': 'options.transparent',
    '[class.maia-backdrop--clickable]': 'options.clickable',
  },
})
export class BackdropComponent implements OnInit, OnDestroy {
  private _scrollLock?: ScrollLock = undefined;

  public constructor(
    public readonly options: BackdropOptions,
    private _modalControl: ModalControl<never>,
    private _scrollBlocker: ScrollBlocker,
  ) {}

  @HostListener('click')
  public _onClick(): void {
    if (this.options.clickable) {
      this._modalControl.cancel();
    }
  }

  public ngOnInit(): void {
    if (this.options.blockScrolling) {
      this._scrollLock = this._scrollBlocker.lock();
    }
  }

  public ngOnDestroy(): void {
    if (this._scrollLock != null) {
      this._scrollLock.unlock();
    }
  }
}
