import {Directive, forwardRef} from '@angular/core';

import {BifrostHeaderVariant} from './header-variant';

@Directive({
  selector: 'bifrost-header[oscarAuthenticated], header[oscarAuthenticated]',
  providers: [
    {
      provide: BifrostHeaderVariant,
      useExisting: forwardRef(() => HeaderVariantOscarAuthenticatedDirective),
    },
  ],
})
export class HeaderVariantOscarAuthenticatedDirective implements BifrostHeaderVariant {
  public isPublic = false;
  public isCollapsible = false;
  public allowBackButton = false;
  public hasBackground = false;
}
