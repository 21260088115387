import {
  animate,
  AnimationBuilder,
  AnimationFactory,
  AnimationPlayer,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  InjectionToken,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {DocumentRef, WindowRef} from '@atlas-angular/cdk/globals';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {
  DomIoService,
  FrameThrottleService,
  Utilities,
  ViewportName,
  ViewportService,
} from '@maia/core';
import {
  captureFocus,
  LifecycleModalInformation,
  ModalControl,
  OnModalClose,
  OnModalOpen,
} from '@maia/modals';
import {fromEvent, merge} from 'rxjs';
import {distinctUntilChanged, filter, mergeMap, startWith} from 'rxjs/operators';

import {FooterContainer} from '../footer/footer-container.service';

import {fixIosScroll} from './fix-ios-scroll';

/**
 * Options to be passed when creating a slide-in.
 */
export interface SlideInOptions {
  /**
   * The slide-in title.
   */
  title: string;

  /**
   * If this value is true, the slide-in will fit the height of the screen and not fit the content.
   */
  forceFullHeight?: boolean;

  /**
   * If this value is true, the footer is part of the scroll area of the content. If it is false or
   * absent, the footer is always visible at the bottom of the screen.
   */
  footerInScrollArea?: boolean;

  /**
   * Whether to show the content full width
   *
   * If this flag is set to true, the content is responsible for setting horizontal margins to
   * ensure it respects the correct spacing between the edge of the slide-in and the content area.
   */
  fullWidthContent?: boolean;
}

export const SLIDEIN_OPTIONS = new InjectionToken<SlideInOptions>('slideInOptions');

/**
 * Identifier used as type in the modal lifecycle callbacks.
 */
export const MODAL_TYPE_SLIDE_IN = 'maiaSlideIn';

const OFFSET_PER_OPEN_SLIDE_IN = 5; /* px */

/**
 * Mobile only -- The minimum space between the slide-in and the top of the viewport
 */
const MOBILE_MARGIN_TOP = 12;

const generateTitleId = Utilities.createIdGenerator('maia-slide-in-title');

export const enum ShowAs {
  SlideInVertical = 'slideinvertical',
  SlideInHorizontal = 'slideinhorizontal',
}

function isntNull<T>(value: T): value is NonNullable<T> {
  return value != null;
}

const ANIMATION_DURATION = 300;

/**
 * @ngModule SlideInsModule
 */
@Component({
  selector: 'maia-slide-in',

  templateUrl: './slide-in.component.html',
  styleUrls: ['./slide-in.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,

  animations: [
    trigger('slideIn', [
      // when showing/hiding as a horizontal slide-in, slide in horizontally.
      transition('void => slideinhorizontal', [
        style({transform: 'translateX(100%)'}),
        animate(ANIMATION_DURATION, style({transform: 'translateX(0)'})),
      ]),
      transition('slideinhorizontal => void', [
        animate(ANIMATION_DURATION, style({transform: 'translateX(100%)'})),
      ]),
      // when showing/hiding as a vertical slide-in, slide in vertically.
      transition('void => slideinvertical', [
        style({transform: 'translateY(100%)'}),
        animate(ANIMATION_DURATION, style({transform: 'translateY(0)'})),
      ]),
      transition('slideinvertical => void', [
        animate(ANIMATION_DURATION, style({transform: 'translateY(100%)'})),
      ]),
    ]),
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    tabindex: '-1',
    '[class.maia-slide-in--force-full-height]': 'options.forceFullHeight',
  },
})
@UntilDestroy()
export class SlideInComponent
  implements OnDestroy, OnInit, AfterContentInit, OnModalClose, OnModalOpen {
  public footer?: TemplateRef<unknown> = undefined;
  public scrollableContentDown = false;
  public scrollableContentUp = false;

  public readonly titleId = generateTitleId();

  private _animationPlayer?: AnimationPlayer = undefined;
  private _animation?: AnimationFactory = undefined;

  private _countSlideinsDrawnOverThis = 0;

  private _showAs?: ShowAs = undefined;

  @ViewChild('content', {static: true})
  public _contentElement: ElementRef<HTMLElement>;

  @ViewChild('contentWrapper', {static: true})
  public _contentWrapper: ElementRef<HTMLElement>;

  public constructor(
    @Inject(SLIDEIN_OPTIONS) public readonly options: SlideInOptions,
    public readonly control: ModalControl<never>,
    private readonly _footerContainer: FooterContainer,
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly _animationBuilder: AnimationBuilder,
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _renderer: Renderer2,
    private readonly _documentRef: DocumentRef,
    private readonly _viewportService: ViewportService,
    private readonly _frameThrottler: FrameThrottleService,
    private readonly _domIo: DomIoService,
    private readonly _windowRef: WindowRef,
    private readonly _zone: NgZone,
  ) {}

  @HostBinding('@slideIn')
  public get showAs(): ShowAs | undefined {
    return this._showAs;
  }

  private _modifyCountSlideinsDrawnOverThis(diff: number): void {
    const oldCount = this._countSlideinsDrawnOverThis;
    const newCount = oldCount + diff;
    this._countSlideinsDrawnOverThis = newCount;

    this._playSlideAsideAnimation(oldCount, newCount);
  }

  private _playSlideAsideAnimation(oldCount: number, newCount: number): void {
    if (this._showAs !== ShowAs.SlideInHorizontal || oldCount === newCount) {
      // nothing to do here
      return;
    }

    if (this._animationPlayer != null) {
      this._animationPlayer.destroy();
    }

    if (this._animation == null) {
      this._animation = this._animationBuilder.build([
        style({transform: `translateX(calc({{ oldCount }} * -${OFFSET_PER_OPEN_SLIDE_IN}px))`}),
        animate(
          ANIMATION_DURATION,
          style({transform: `translateX(calc({{ newCount }} * -${OFFSET_PER_OPEN_SLIDE_IN}px)`}),
        ),
        style({transform: `translateX(calc({{ newCount }} * -${OFFSET_PER_OPEN_SLIDE_IN}px)`}),
      ]);
    }

    this._animationPlayer = this._animation.create(this._elementRef.nativeElement, {
      params: {oldCount, newCount},
    });
    this._animationPlayer.play();
  }

  @HostListener('keyup.escape', ['$event'])
  public _onEscape(event: KeyboardEvent): void {
    event.stopImmediatePropagation();
    event.preventDefault();

    this.control.cancel();
  }

  public maiaOnModalOpen(openedModalInformation: LifecycleModalInformation): void {
    if (openedModalInformation.type !== MODAL_TYPE_SLIDE_IN) {
      return;
    }
    this._modifyCountSlideinsDrawnOverThis(+1);
  }

  public maiaOnModalClose(closedModalInformation: LifecycleModalInformation): void {
    if (closedModalInformation.type !== MODAL_TYPE_SLIDE_IN) {
      return;
    }
    this._modifyCountSlideinsDrawnOverThis(-1);
  }

  public ngOnDestroy(): void {
    if (this._animationPlayer != null) {
      this._animationPlayer.destroy();
      this._animationPlayer = undefined;
    }
  }

  public ngOnInit(): void {
    captureFocus(this, this._documentRef.document, this._elementRef);
    fixIosScroll(this, this._elementRef);

    // Some mobile browsers don't update the actual height of 100vh when the address bar appears,
    // making 100vh larger than the actual viewport height. This means we can't just set the
    // max-height to 100vh - 12px,  as the address bar will end up on top of the slide-in and hide
    // the close button.
    // As a workaround we need to manually set the max-height to window.innerHeight - 12px
    this._frameThrottler
      .throttle$(this._windowRef.on$('resize'))
      .pipe(
        startWith(null),
        filter(
          () =>
            this._contentWrapper != null &&
            (this._viewportService.viewport === ViewportName.EXTRA_SMALL ||
              this._viewportService.viewport === ViewportName.SMALL),
        ),
        takeUntilDestroyed(this),
      )
      .subscribe(() =>
        this._renderer.setStyle(
          this._contentWrapper.nativeElement,
          'maxHeight',
          `${window.innerHeight - MOBILE_MARGIN_TOP}px`,
        ),
      );

    this._viewportService
      .isAtLeast$(ViewportName.MEDIUM)
      .pipe(takeUntilDestroyed(this))
      .subscribe(atLeastMedium => {
        this._showAs = atLeastMedium ? ShowAs.SlideInHorizontal : ShowAs.SlideInVertical;
        if (atLeastMedium && this._contentWrapper != null) {
          this._renderer.removeStyle(this._contentWrapper.nativeElement, 'maxHeight');
        }
        this._playSlideAsideAnimation(0, this._countSlideinsDrawnOverThis);
      });

    this._footerContainer.footer$.pipe(takeUntilDestroyed(this)).subscribe(footer => {
      this.footer = footer;
      this._changeDetector.markForCheck();
    });

    this._zone.runOutsideAngular(() => {
      this._frameThrottler
        .throttle$(
          merge(
            fromEvent(this._contentElement.nativeElement, 'scroll'),
            this._footerContainer.footer$,
          ),
        )
        .pipe(
          filter(() => this.footer !== undefined),
          mergeMap(() =>
            this._domIo.measure(() => {
              if (this._contentElement == null) {
                // This component got destroyed in the mean time
                return null;
              }

              const {scrollHeight, offsetHeight, scrollTop} = this._contentElement.nativeElement;
              return {down: scrollHeight - scrollTop > offsetHeight, up: scrollTop > 0};
            }),
          ),
          filter(isntNull),
          distinctUntilChanged(),
          takeUntilDestroyed(this),
        )
        .subscribe(scrollContent =>
          this._zone.run(() => {
            this.scrollableContentDown = scrollContent.down;
            this.scrollableContentUp = scrollContent.up;
            this._changeDetector.markForCheck();
          }),
        );
    });
  }

  public ngAfterContentInit(): void {
    void this._domIo.measure(() => {
      if (this._contentElement == null) {
        // This component got destroyed in the mean time
        return;
      }

      const {scrollHeight, offsetHeight, scrollTop} = this._contentElement.nativeElement;
      this.scrollableContentDown = scrollHeight - scrollTop > offsetHeight;
      this.scrollableContentUp = scrollTop > 0;
    });
  }
}
