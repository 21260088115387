import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionFuncPlusSvgModule
 */
@Component({
  selector: 'svg[ivyHermesActionFuncPlus]',
  template: '<svg:g class="nc-icon-wrapper" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"><svg:path fill="none" stroke-miterlimit="10" d="M12 8v8" stroke-width="1.04"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M16 12H8" stroke-width="1.04"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionFuncPlusSvgComponent {
}

@NgModule({
  declarations: [
    ActionFuncPlusSvgComponent,
  ],
  exports: [
    ActionFuncPlusSvgComponent,
  ],
})
export class ActionFuncPlusSvgModule {}
