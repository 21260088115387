import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {WindowRef} from '@atlas-angular/cdk/globals';
import {Text} from '@atlas/businesstypes';
import {CssClassUtilityFactory} from '@maia/core';

import {DEFAULT_CURRENCY} from '../../amount-display/amount-display.component';
import {BaseNumberDisplayAnimatedComponent} from '../base-number-animated/base-number-animated.component';

/**
 * @ngModule AnimatedDisplaysModule
 */
@Component({
  selector: 'maia-amount-display[animated]',
  templateUrl: '../base-number-animated/base-number-animated.component.html',
  styleUrls: ['../base-number-animated/base-number-animated.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmountDisplayAnimatedComponent
  extends BaseNumberDisplayAnimatedComponent
  implements OnChanges {
  @Input()
  public set currency(value: Text) {
    this._currency = value;
    this._changeDetector.detectChanges();
  }

  public get suffix(): Text | string {
    return this._currency;
  }
  private _currency: Text | string;

  public readonly showSmallDecimals: boolean;
  public constructor(
    private _changeDetector: ChangeDetectorRef,
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
    windowRef: WindowRef,
  ) {
    super(cssClassUtilityFactory, renderer, elementRef, windowRef, _changeDetector);
    this._currency = DEFAULT_CURRENCY;
  }

  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }
}
