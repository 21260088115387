import {ChangeDetectionStrategy, Component, Optional} from '@angular/core';
import {TileComponent} from '../tile/tile.component';

/**
 * Tile action icon component
 *
 * @ngModule TilesModule
 */
@Component({
  selector: 'maia-tile-button-action-icon',
  template: '<ng-content></ng-content>',
  styleUrls: ['./tile-action-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileActionIconComponent {
  public constructor(@Optional() parent?: TileComponent) {
    if (parent === null) {
      throw new Error(
        'Cannot create a <maia-tile-button-action-icon /> component outside of a <maia-tile /> component',
      );
    }
  }
}
