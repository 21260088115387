import {BusinessType} from '@atlas/businesstypes';

/**
 * Any `BusinessType`
 */
export type AnyBusinessType = BusinessType<any>;

export interface Type<T> {
  new (...args: any[]): T;
}

/**
 * A type that's either `T` or an array of `T`.
 */
export type MaybeArray<T> = T | T[];

/**
 * A type that is an object with properties set to a BusinessType, null or undefined.
 */
export type DeepTypedUnknownObject = DeepTypedObject<AnyBusinessType>;

/**
 * Objects in which all leaf properties are of type `T`.
 */
export interface DeepTypedObject<T> {
  // Note: we cannot use TypedObject here because that makes tsc error on a circular definition,
  //       so `type DeepTypedObject<T> = TypedObject<MaybeArray<T | DeepTypedObject<T>>>;` doesn't
  //       work
  [key: string]: MaybeArray<T | DeepTypedObject<T>> | undefined | null;
}

/**
 * Maps the values of an object, returning a new object with the same shape as the original object.
 * It will ignore null or undefined values.
 *
 * @param object The object of which to map the values
 * @param mapper The mapping function
 */
export function mapValues<T, R>(
  object: Record<string, T | null | undefined>,
  mapper: (property: T) => R,
): Record<string, R>;
export function mapValues<T, R>(
  object: Record<string, T[] | null | undefined>,
  mapper: (property: T) => R,
): Record<string, R[]>;
export function mapValues<T, R>(
  object: Record<string, MaybeArray<T> | null | undefined>,
  mapper: (property: T) => R,
): Record<string, MaybeArray<R>>;
export function mapValues<T, R>(
  object: Record<string, MaybeArray<T> | null | undefined>,
  mapper: (property: T) => R,
): Record<string, MaybeArray<R>> {
  return Object.keys(object).reduce((result, key) => {
    const property = object[key];
    if (property != null) {
      // Filter null/undefined
      result[key] = Array.isArray(property) ? property.map(mapper) : mapper(property);
    }

    return result;
  }, {} as Record<string, MaybeArray<R>>);
}
