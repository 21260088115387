import {BusinessType, createStringifier} from '../base/businesstype';

import {isValidEmailAddress} from './validator-base';

const stringifier = createStringifier('emailAddress');

/**
 * BusinessType wrapper around Email Addresses without imposing any
 * format or structure on that email address text.
 */
export class EmailAddress extends BusinessType<string> {
  /**
   * Returns whether or not `value` is an EmailAddress.
   */
  public static isEmailAddress(value: unknown): value is EmailAddress {
    return value instanceof EmailAddress;
  }

  /**
   * Constructs a new EmailAddress instance.
   *
   * @param value The EmailAddress value
   * @param encryptedValue The encryptedValue, if any
   */
  public constructor(value: string, encryptedValue?: string) {
    if (typeof value !== 'string') {
      throw new Error(
        `Expected a string but got ${
          (typeof value === 'object' && (value as object).constructor.name) || typeof value
        }`,
      );
    } else {
      if (!isValidEmailAddress(value)) {
        throw new Error(`Cannot create EmailAddress for invalid input ${JSON.stringify(value)}`);
      }
    }

    super(value, encryptedValue);
  }

  /**
   * Returns whether `other` is an `EmailAddress` representing the same value.
   */
  public equals(other: unknown): boolean {
    if (!other) {
      return false;
    }

    if (other === this) {
      return true;
    }

    return EmailAddress.isEmailAddress(other) && this.internalValue === other.internalValue;
  }

  /**
   * Exposes the value of this BusinessType as a string. The returned value will
   * always be the same.
   */
  public asString(): string {
    return this.internalValue;
  }

  public toString(): string {
    return stringifier(this);
  }
}
