import {Injectable, Injector, Optional} from '@angular/core';
import {WindowRef} from '@atlas-angular/cdk/globals';
import {
  DynamicResource,
  DynamicResourceUrlGenerator,
  QueryParamMap,
} from '@atlas-angular/connector';
import {Boolean} from '@atlas/businesstypes';
import {Cordova} from '@hermes-cordova/core';
import {MncDetector} from '@hermes-mnc/core';
import {Observable, of} from 'rxjs';
import {flatMap, map, take} from 'rxjs/operators';
import {PlatformDownloadManager} from './download-manager';
import {BrowserDownloadManager} from './download-manager-browser.service';
import {CordovaDownloadManager} from './download-manager-cordova.service';
import {MncDownloadManager} from './download-manager-mnc.service';
import {Target} from './target';
import {UniformTypeIdentifier} from './uniform-type-indentifier';

export interface DynamicResourceDownload extends DynamicResource, AsAttachment {
  type: UniformTypeIdentifier;
}

export interface AsAttachment {
  asAttachment?: Boolean;
}

export type DownloadQueryParamMap = QueryParamMap & AsAttachment;

export const enum PLATFORM {
  CORDOVA = 'cordova',
  BROWSER = 'browser',
  MNC = 'mnc',
}

/**
 * Provider responsible for downloading dynamic resources from a server. Uses dynamic resource url
 * resolvers to resolve the url to the resource to be downloaded. Extra query parameters can be
 * passed via a query map. Supported platforms: Cordova, MNC and browser.
 */
@Injectable({
  providedIn: 'root',
})
export class DownloadManager {
  private readonly _platform: PLATFORM;
  protected readonly window: Window;
  public constructor(
    private readonly urlGenerator: DynamicResourceUrlGenerator,
    private readonly cordova: Cordova,
    private readonly injector: Injector,
    windowRef: WindowRef,
    @Optional() private readonly mncDetector?: MncDetector,
  ) {
    this.window = windowRef.window;
    this._platform = this.detectPlatform();
  }

  /**
   * Prepares the dynamic resource
   * @param download
   * @param query
   */
  public prepareDynamicResource(
    download: DynamicResourceDownload,
    query?: DownloadQueryParamMap,
  ): Observable<string> {
    return this.urlGenerator.prepare(download, query).pipe(
      take(1),
      map(url => url.toString()),
    );
  }

  /**
   * Downloads and opens a dynamic resource.
   * @param download
   * @param query
   * @param target
   */
  public download(
    download: DynamicResourceDownload,
    query?: DownloadQueryParamMap,
    target: Target = Target.BLANK,
  ): Observable<void> {
    const asAttachment =
      (download.asAttachment != null && download.asAttachment.asBoolean()) ||
      (query != null && query.asAttachment != null && query.asAttachment.asBoolean());
    const selectHandleMethod = (url: string) => {
      return this.canHandleInSameWindow(asAttachment)
        ? this.redirect(url)
        : this.downloadWithUrl(url, target, download.type);
    };
    return this.prepareDynamicResource(download, query).pipe(flatMap(selectHandleMethod));
  }

  /**
   * Verifies if the download can be initiated in the same window.
   * Note: on devices, this should be always false.
   * @param asAttachment
   */
  private canHandleInSameWindow(asAttachment: boolean): boolean {
    return this._platform === PLATFORM.BROWSER && asAttachment;
  }

  /**
   * Redirects to the file resource to download it.
   * Note: response needs "ContentDisposition: attachment" in order to work properly.
   * @param url
   */
  private redirect(url: string): Observable<void> {
    this.window.location.assign(url);
    return of(undefined);
  }

  /**
   * Downloads (and opens) a (static) file url.
   * @param url
   * @param target
   * @param type
   */
  public downloadWithUrl(
    url: string,
    target: Target = Target.BLANK,
    type = UniformTypeIdentifier.PDF,
  ): Observable<void> {
    return this.downloadManager.downloadWithUrl(url, target, type);
  }

  /**
   * Checks if a file can be opened.
   * @param url
   * @param type
   */
  public canOpen(url: string, type = UniformTypeIdentifier.PDF): Observable<boolean> {
    return this.downloadManager.canOpen(url, type);
  }

  /**
   * @returns The download manager depending on the platform
   */
  private get downloadManager(): PlatformDownloadManager {
    if (this._platform === PLATFORM.CORDOVA) {
      return this.injector.get(CordovaDownloadManager);
    } else if (this._platform === PLATFORM.MNC) {
      return this.injector.get(MncDownloadManager);
    }
    return this.injector.get(BrowserDownloadManager);
  }

  /**
   * Detects which platform is used
   */
  private detectPlatform(): PLATFORM {
    if (this.cordova.platform != null) {
      return PLATFORM.CORDOVA;
    }
    if (this.mncDetector && this.mncDetector.isMnc()) {
      return PLATFORM.MNC;
    }
    return PLATFORM.BROWSER;
  }
}
