import {NgZone} from '@angular/core';
import {MonoTypeOperatorFunction, Observable} from 'rxjs';

/**
 * Operator that enters the angular zone for the rest of the observable's pipeline
 *
 * @example
 *
 * // Observe scroll events, which can come more than once per frame, so we want to do that out of
 * // the angular zone to keep the number of change detections from exploding
 * zone.runOutsideAngular(() => {
 *   fromEvent(window, 'scroll').pipe(
 *     throttleTime(16),
 *     map(() => window.scrollY > 0),
 *     observeInZone(zone),
 *   ).subscribe(hasScrolled => this.hasScrolled = hasScrolled);
 * });
 *
 */
export function observeInZone<T>(zone: NgZone): MonoTypeOperatorFunction<T> {
  return function(source) {
    return new Observable(observer => {
      return source.subscribe({
        next(value) {
          zone.run(() => observer.next(value));
        },
        error(error) {
          zone.run(() => observer.error(error));
        },
        complete() {
          zone.run(() => observer.complete());
        },
      });
    });
  };
}
