import {Injectable} from '@angular/core';
import {DocumentRef} from '@atlas-angular/cdk/globals';
import {LoggerFactory} from '@atlas-angular/logger';
import {Logger} from '@atlas/logger';
import {defer, Observable, throwError} from 'rxjs';
import {mapTo, share, take, tap} from 'rxjs/operators';

import {εCordova} from './apache/cordova.service';
import {Platform} from './platform';

declare global {
  interface DocumentEventMap {
    // cordova events
    deviceready: Event;
    pause: Event;
    resume: Event;
  }
}

/**
 * The Cordova service handles cordova related events & communication.
 */
@Injectable({
  providedIn: 'root',
})
export class Cordova {
  private readonly _ready: Promise<void>;
  private readonly _pause: Observable<Event>;
  private readonly _resume: Observable<Event>;

  private readonly logger: Logger;

  public constructor(
    private readonly cordova: εCordova,
    documentRef: DocumentRef,
    loggerFactory: LoggerFactory,
  ) {
    this.logger = loggerFactory.createLogger('Cordova');
    this._ready = documentRef
      .on$('deviceready')
      .pipe(
        take(1),
        tap(() => this.logger.debug('Device is ready')),
        mapTo(undefined),
      )
      .toPromise();
    this._pause = documentRef.on$('pause').pipe(share());
    this._resume = documentRef.on$('resume').pipe(share());
  }

  /**
   * Returns the active cordova platform (ios or android). Returns null when cordova is not active).
   */
  public get platform(): Platform | null {
    try {
      return this.cordova.platformId as Platform;
    } catch (err) {
      return null;
    }
  }

  /**
   * Waits for the device to be ready (deviceready event).
   */
  public get ready(): Promise<void> {
    if (this.platform === null) {
      return Promise.reject(
        new Error('Only use Cordova#ready on a cordova platform (iOS, Android)'),
      );
    }
    return this._ready;
  }

  /**
   * The pause event emits when the native platform puts the application
   * into the background, typically when the user switches to a different
   * application. This event would emit when a Cordova app is put into
   * the background.
   */
  public get pause(): Observable<Event> {
    if (this.platform === null) {
      return throwError(new Error('Only use Cordova#pause on a cordova platform (iOS, Android)'));
    }
    return this._pause;
  }

  /**
   * The resume event emits when the native platform pulls the application
   * out from the background. This event would emit when a Cordova app comes
   * out from the background.
   */
  public get resume(): Observable<Event> {
    if (this.platform === null) {
      return throwError(new Error('Only use Cordova#resume on a cordova platform (iOS, Android)'));
    }
    return this._resume;
  }

  /**
   * Executes an action on a cordova plugin.
   * @param plugin
   * @param action
   * @param params
   */
  public execAction(plugin: string, action: string, ...params: any[]): Observable<any> {
    return defer(() => this.doExecAction(plugin, action, params));
  }

  private async doExecAction(plugin: string, action: string, params: any[]): Promise<any> {
    await this.ready;

    return new Promise((resolve, reject) => {
      this.logger.debug(`Executing plugin '${plugin}' with action '${action}'`);
      const rejectWrapper = (err: any) => {
        this.logger.error(`Plugin '${plugin}' with '${action}' failed due to error`, err);
        reject(err);
      };
      const resolveWrapper = (result: any) => {
        this.logger.debug('Result is', result);
        resolve(result);
      };
      this.cordova.exec(resolveWrapper, rejectWrapper, plugin, action, params);
    });
  }
}
