/*
 * This file contains extra validators for EmailAddress, that is:
 * the validators used to check extra conditions on valid date values.
 */

import {
  AtlasMaxLengthValidationErrors,
  AtlasMinLengthValidationErrors,
  Validator,
} from '../base/validators';
import {baseMaxLength, baseMinLength} from '../base/validators-extra';

import {EmailAddress} from './businesstype';

export const ExtraEmailAddressValidators = {
  minLength: (limit: number): Validator<EmailAddress, AtlasMinLengthValidationErrors> =>
    baseMinLength<EmailAddress>(limit),
  maxLength: (limit: number): Validator<EmailAddress, AtlasMaxLengthValidationErrors> =>
    baseMaxLength<EmailAddress>(limit),
} as const;
