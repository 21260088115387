import {Directive, Input, OnInit, Self} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {DEFAULT_IBAN_MAX_LENGTH, IBAN_COUNTRY_LENGTH, IbanFormatter} from '@atlas/businesstypes';

import {distinctUntilChanged, map, startWith} from 'rxjs/operators';

import {DEFAULT_PLACEHOLDER_CHAR, INPUT_CHAR} from '../util/mask-helper.util';
import {ValueFormatter} from '../util/value-formatter.util';
import {ValueProcessor} from '../util/value-processor.util';
import {MaskedInputComponent} from './masked-input.component';

const IBAN_COUNTRY_PREFIX_LENGTH = 2;

/**
 * Extracts the country from a (partial) IBAN
 *
 * @param value A (partial) IBAN
 * @returns The country or empty string if no country is found
 */
function getIbanCountry(value: string): string {
  if (!value || value.length < IBAN_COUNTRY_PREFIX_LENGTH) {
    return '';
  }

  return value.substr(0, IBAN_COUNTRY_PREFIX_LENGTH).toUpperCase();
}

/**
 * Returns the length of IBAN account numbers from the given country
 *
 * @param country The country of the IBAN
 */
function getIbanLength(country: string): number {
  return IBAN_COUNTRY_LENGTH[country] || DEFAULT_IBAN_MAX_LENGTH;
}

/**
 * The length of the character groups:
 *
 * `XXXX XXXX XXXX XX`
 */
const IBAN_GROUP_LENGTH = 4;

/**
 * The spacer used between groups in an IBAN
 */
const IBAN_GROUP_SPACER = ' ';

/**
 * Repeats the given `str`
 *
 * @param str The string to repeat
 * @param spacer The spacer to add in between repeated `str`
 * @param count How many times to repeat `str`
 * @return The repeated string
 */
function repeatString(str: string, spacer: string, count: number): string {
  return Array(count).fill(str).join(spacer);
}

/**
 * Creates a string that is formatted like an IBAN
 *
 * Example:
 *
 * ```ts
 * createIbanLikeString('x', 16) === 'xxxx xxxx xxxx xxxx'
 * ```
 *
 * @param character The character to use instead of actual IBAN content
 * @param length The length of the "IBAN"
 * @returns A formatted string
 */
function createIbanlikeString(character: string, length: number) {
  const lastPartLength = length % IBAN_GROUP_LENGTH;
  const groupCount = (length - lastPartLength) / IBAN_GROUP_LENGTH;

  const group = repeatString(character, '', IBAN_GROUP_LENGTH);
  const groups = repeatString(group, IBAN_GROUP_SPACER, groupCount);

  if (lastPartLength > 0) {
    return `${groups}${IBAN_GROUP_SPACER}${repeatString(character, '', lastPartLength)}`;
  } else {
    return groups;
  }
}

/**
 * Creates a string that is formatted like an IBAN for the specified country
 *
 * This string will include the country prefix, e.g.
 *
 * ```
 * createCountryIbanlikeString('x', 'BE') === 'BExx xxxx xxxx xxxx'
 * ```
 *
 * @param character The character to use instead of actual IBAN content
 * @param country The country for which to create an "IBAN"
 * @returns A formatted string
 */
function createCountryIbanlikeString(character: string, country: string): string {
  return (
    country +
    createIbanlikeString(character, getIbanLength(country)).substr(IBAN_COUNTRY_PREFIX_LENGTH)
  );
}

/**
 * `ValueFormatter` that removes the formatting added by the `[atlasIban]` directive.
 */
const ibanFormatter: ValueFormatter = {
  /**
   * Doesn't format anything, formatting is done by the `[atlasIban]` directive.
   */
  formatValue(value: string): string {
    return value;
  },

  /**
   * Unformats any formatting left in place by the `[atlasIban]` directive.
   */
  unformatValue(value: string): string {
    return IbanFormatter.unformat(value);
  },
};

/**
 * Allows for IBANs of a specific country to be entered in a masked input
 *
 * The country can be set by passing the two-digit key as `[country]` in this directive.
 * This value mustn't change after initialisation.
 *
 * @see MaskedIbanInputDirective
 * @ngModule InputMasksModule
 */
@Directive({
  selector: 'maia-masked-input[atlasIban][country]',
})
@UntilDestroy()
export class MaskedIbanInputWithCountryDirective implements OnInit, ValueProcessor {
  private _country: string;

  private _isInitialized = false;

  public constructor(@Self() private readonly _input: MaskedInputComponent) {
    this._input.setValueFormatter(ibanFormatter);
    this._input.setValueProcessor(this);
  }

  /**
   * The country for which to create a masked input
   *
   * This value mustn't change after initialisation of the components.
   */
  @Input()
  public get country(): string {
    return this._country;
  }

  public set country(country: string) {
    if (country.toUpperCase() === this._country) {
      return;
    }

    if (this._isInitialized) {
      throw new Error(
        `Modifying country from ${this._country} to ${country} after initialisation is not allowed`,
      );
    }

    if (country.length !== IBAN_COUNTRY_PREFIX_LENGTH) {
      throw new Error(
        `Expected a ${IBAN_COUNTRY_PREFIX_LENGTH} character country code, got "${country}"`,
      );
    }

    this._country = country.toUpperCase();
  }

  public ngOnInit(): void {
    this._input.setMaskAndPlaceholder(createCountryIbanlikeString(INPUT_CHAR, this.country));

    this._input.reprocessValue();
    this._isInitialized = true;
  }

  public fromRawValue(rawValue: string): string {
    return `${this.country}${rawValue}`;
  }

  public toRawValue(processedValue: string): string {
    return processedValue.startsWith(this.country)
      ? processedValue.substr(IBAN_COUNTRY_PREFIX_LENGTH)
      : processedValue;
  }
}

/**
 * Allows for IBANs to be entered in a masked input
 *
 * This directive allows IBANs to be entered regardless of the IBAN's country. The mask changes
 * dynamically when the country changes in the value.
 *
 * @see MaskedIbanInputWithCountryDirective
 * @ngModule InputMasksModule
 */
@Directive({
  selector: 'maia-masked-input[atlasIban]:not([country])',
})
@UntilDestroy()
export class MaskedIbanInputDirective implements OnInit {
  public constructor(@Self() private readonly _input: MaskedInputComponent) {
    this._input.setValueFormatter(ibanFormatter);
  }

  public ngOnInit(): void {
    this._input.value$
      .pipe(takeUntilDestroyed(this), map(getIbanCountry), startWith(''), distinctUntilChanged())
      .subscribe(country => {
        const length = getIbanLength(country);
        this._input.setMaskAndPlaceholder(
          createIbanlikeString(INPUT_CHAR, length),
          // For IBANs where we don't know the country, don't show placeholders
          // Otherwise this can get confusing: I want to enter a Belgian IBAN but I see too many
          // dots, which confuses me into thinking I'm looking at the wrong number.
          createIbanlikeString(country ? DEFAULT_PLACEHOLDER_CHAR : ' ', length),
        );
      });
  }
}
