import {Injectable} from '@angular/core';
import {fromEvent, Observable} from 'rxjs';

/**
 * The `WindowRef` grants access to the Window using Angular's dependency injection.
 *
 * This method of accessing the window is preferred over using the global window variable because it
 * easily allows tests to mock (parts of) the window.
 */
@Injectable({providedIn: 'root'})
export class WindowRef {
  /**
   * The window object, same as the `window` global.
   */
  public get window(): Window {
    return window;
  }

  /**
   * Exposes events on the Window object as observable.
   *
   * @param eventName The name of the window event to listen to
   * @param options Options to pass on to `addEventListener`, if any
   */
  public on$<K extends keyof WindowEventMap>(
    eventName: K,
    options?: AddEventListenerOptions,
  ): Observable<WindowEventMap[K]> {
    return options != null
      ? fromEvent<WindowEventMap[K]>(this.window, eventName, options)
      : fromEvent<WindowEventMap[K]>(this.window, eventName);
  }
}
