import {
  ComponentFactory,
  ComponentFactoryResolver,
  Injectable,
  Injector,
  StaticProvider,
} from '@angular/core';
import {
  ModalContentComponent,
  ModalController,
  ModalOptions,
  ModalOptionsWithInput,
  ModalResult,
} from '@maia/modals';
import {Observable} from 'rxjs';

import {FooterContainer} from '../footer/footer-container.service';
import {
  MODAL_TYPE_POP_UP,
  POPUP_OPTIONS,
  PopUpComponent,
  PopUpOptions,
} from '../pop-up/pop-up.component';

/**
 * The PopUpController allows showing pop-ups.
 */
@Injectable()
export class PopUpController {
  private _popUpFactory: ComponentFactory<PopUpComponent>;

  public constructor(
    private _modalCtrl: ModalController,
    private _componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this._popUpFactory = this._componentFactoryResolver.resolveComponentFactory(PopUpComponent);
  }

  /**
   * Prepares a pop-up. Once the returned observable is subscribed to, the pop-up will be shown.
   * The pop-up gets dismissed if the subscription is ended.
   *
   * By default the open pop-up will not block scrolling on the page.
   *
   * @param componentFactory The content component to show in the pop-up
   * @param injector The parent injector for the content component
   * @param options The options, e.g. title, for this pop-up
   * @param modalOptions The options to pass on to the modal controller
   */
  public prepare<O>(
    componentFactory: ComponentFactory<ModalContentComponent<O>>,
    injector: Injector,
    options: PopUpOptions,
    modalOptions?: ModalOptions,
  ): Observable<ModalResult<O>>;
  public prepare<O, I>(
    componentFactory: ComponentFactory<ModalContentComponent<O, I>>,
    injector: Injector,
    options: PopUpOptions,
    modalOptions: ModalOptionsWithInput<I>,
  ): Observable<ModalResult<O>>;
  public prepare<O, I>(
    componentFactory: ComponentFactory<ModalContentComponent<O>>,
    injector: Injector,
    options: PopUpOptions,
    modalOptions?: ModalOptions<I>,
  ): Observable<ModalResult<O>> {
    return new Observable<ModalResult<O>>(observer => {
      const mergedModalOptions = Object.assign({}, modalOptions);
      const popUpProviders: StaticProvider[] = [
        {provide: POPUP_OPTIONS, useValue: options},
        {provide: FooterContainer, deps: []},
      ];

      if (mergedModalOptions.providers != null) {
        mergedModalOptions.providers = mergedModalOptions.providers.concat(popUpProviders);
      } else {
        mergedModalOptions.providers = popUpProviders;
      }

      // Defaults to false for pop-ups
      if (typeof mergedModalOptions.blockScrolling !== 'boolean') {
        mergedModalOptions.blockScrolling = true;
      }

      return this._modalCtrl
        .prepare(
          MODAL_TYPE_POP_UP,
          injector,
          this._popUpFactory,
          componentFactory,
          // Explicit cast is necessary because typescript doesn't know what we know thanks to
          // our function definition: The input property on the merged modal options is always
          // present if I isn't undefined/void.
          mergedModalOptions as ModalOptionsWithInput<I>,
        )
        .subscribe(observer);
    });
  }
}
