import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule, Optional} from '@angular/core';
import {CONNECTOR_INTERCEPTORS} from '@atlas-angular/connector';
import {SystemMessagesModule as SystemMessagesOldModule} from '@maia/system-messages';
import {SystemMessagesModule} from '@maia/system-messages/toasts';

import {MessagingComponent} from './messaging.component';
import {MessagingInterceptor} from './messaging.interceptor';
import {RenderSystemMessageService} from './render-system-messages.service';

@NgModule({
  imports: [CommonModule, SystemMessagesModule, SystemMessagesOldModule],
  declarations: [MessagingComponent],
  exports: [MessagingComponent],
})
export class MessagingModule {
  public static forRoot(): ModuleWithProviders<MessagingModule> {
    return {
      ngModule: MessagingModule,
      providers: [
        MessagingInterceptor,
        {provide: CONNECTOR_INTERCEPTORS, useExisting: MessagingInterceptor, multi: true},
      ],
    };
  }
  public constructor(
    renderSystemMessage: RenderSystemMessageService,
    @Optional() messagingInterceptor?: MessagingInterceptor,
  ) {
    if (messagingInterceptor == null) {
      throw new Error(
        'Import MessagingModule.forRoot() in AppModule, not MessagingModule directly',
      );
    }
  }
}
