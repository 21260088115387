import {Formatter} from '../base/formatter';

import {Iban} from './businesstype';

export const IbanFormatter: Formatter<Iban> = {
  /**
   * Returns the string value of the iban. Formatted by adding structure
   * of International Bank Account Numbers. IBANs do not have the same length
   * for each country (see iban/validator-base.ts), so this formatter will split
   * the number in groups of 4 digits.
   *
   * @param value The iban to format
   */
  format(value: Iban): string {
    return value.asString().replace(/(....)(?=.)/g, '$1 ');
  },

  /**
   * Removes all iban separator space characters from the pretty value.
   *
   * @param ibanString The string to unformat
   */
  unformat(ibanString: string): string {
    return ibanString.replace(/[ ]+/g, '');
  },
};
