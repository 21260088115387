import {Injectable} from '@angular/core';
import {DocumentRef} from '@atlas-angular/cdk/globals';

export type SelectionDirection = 'forward' | 'backward' | 'none';

/**
 * A Selection represents a client's selection of (parts of) the text of an input field.
 */
export interface Selection {
  /**
   * The caret position right before the selection. This value must not be higher than the `end`.
   */
  start: number;

  /**
   * The caret position right after the selection. This value must not be smaller than the `start`.
   */
  end: number;

  /**
   * Denotes the direction of the selection. A value of `'none'` means there's no selection.
   *
   * On IE this value is undefined when measured using `CaretHelper#getSelection` and the value is
   * ignored in `CaretHelper#setSelection`.
   */
  direction?: SelectionDirection;
}

/**
 * Helper for setting/getting the caret position on an input element.
 */
@Injectable({providedIn: 'root'})
export class CaretHelper {
  public constructor(private readonly _document: DocumentRef) {}

  /**
   * Returns the caret position of the given input element.
   *
   * @param control The input element
   */
  public getCaretPosition(control: HTMLInputElement): number {
    return control.selectionStart!;
  }

  /**
   * Moves the caret to the given position on the given input if it's the focused element.
   *
   * @param control The input element
   * @param position The position to set the caret to
   */
  public setCaretPosition(control: HTMLInputElement, position: number): void {
    if (control === this._document.document.activeElement) {
      control.setSelectionRange(position, position, 'none');
    }
  }

  /**
   * Returns the range of selection in the given input element.
   *
   * @param control The input element
   */
  public getSelection(control: HTMLInputElement): Selection {
    const start = control.selectionStart!;
    const end = control.selectionEnd!;

    // Some browser engines behave differently here.
    // Blink and WebKit will, on macOS, give a direction of "none" if there is no selection
    // All other engines or blink/webkit on other operating systems give a direction of "forward"
    // if there is no selection.
    //
    // We force it to "none" because that's what the spec prescribes:
    //
    // > Each selection has a direction, forwards, backwards, or directionless. If the user creates
    // > a selection by indicating first one boundary point of the range and then the other (such as
    // > by clicking on one point and dragging to another), and the first indicated boundary point
    // > is after the second, then the corresponding selection must initially be backwards. If the
    // > first indicated boundary point is before the second, then the corresponding selection must
    // > initially be forwards. Otherwise, it must be directionless.
    // > ~ https://www.w3.org/TR/selection-api/#definition
    const direction = start === end ? 'none' : (control.selectionDirection as SelectionDirection);

    return {start, end, direction};
  }

  /**
   * Sets the range of selection in the given input element.
   *
   * @param control The input element
   * @param selection The new selection
   */
  public setSelection(control: HTMLInputElement, selection: Selection): void {
    if (control === this._document.document.activeElement) {
      control.setSelectionRange(selection.start, selection.end, selection.direction);
    }
  }
}
