import {Directive, OnInit, Optional} from '@angular/core';
import {NgControl} from '@angular/forms';
import {onDestroy, UntilDestroy} from '@atlas-angular/rxjs';
import {InputContainer} from '@maia/forms';

@UntilDestroy()
@Directive({
  selector:
    'maia-input-month[ngModel], maia-input-month[formControl], maia-input-month[formControlName]',
})
export class InputMonthWithControlDirective implements OnInit {
  public constructor(
    private readonly _ngControl: NgControl,
    @Optional() private readonly _container?: InputContainer,
  ) {}

  public ngOnInit(): void {
    if (this._container != null) {
      const destroyFn = this._container.registerFormControl(this._ngControl);
      onDestroy(this).subscribe(destroyFn);
    }
  }
}
