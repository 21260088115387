import {LogFunction, Logger} from './interfaces';

function createPrefixedLogFunction(logLevel: keyof Console, name: string): LogFunction {
  /* eslint-disable-next-line no-console */
  return console[logLevel].bind(console, `%c[${name}]%c`, 'font-weight: bold;', '');
}

/**
 * Implementation of the Logger for debug builds. This logger logs to the browser console,
 * it doesn't notify listeners in the LoggerEventsService.
 */
export class DebugLogger implements Logger {
  public readonly debug: LogFunction;
  public readonly info: LogFunction;
  public readonly warn: LogFunction;
  public readonly error: LogFunction;

  public constructor(name: string) {
    this.debug = createPrefixedLogFunction('debug', name);
    this.info = createPrefixedLogFunction('info', name);
    this.warn = createPrefixedLogFunction('warn', name);
    this.error = createPrefixedLogFunction('error', name);
  }
}
