import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import {DocumentRef} from '@atlas-angular/cdk/globals';

/**
 * Declaring 'focusin' and 'focusout' event names since they are missing in lib.dom.ts
 * @see https://github.com/microsoft/TypeScript/issues/30716
 */
declare global {
  interface DocumentEventMap {
    focusin: FocusEvent;
    focusout: FocusEvent;
  }
}

/**
 * This service delegates `focusin` and `focusout` events that bubble up from any `input` or `textarea` element.
 */
@Injectable({providedIn: 'root'})
export class InputFocusService {
  public readonly focus$: Observable<Event>;
  public readonly blur$: Observable<Event>;

  public constructor(document: DocumentRef) {
    function isInput(event: Event) {
      const focusTarget = event.target as Element;
      return (
        focusTarget != null &&
        (focusTarget.tagName === 'INPUT' || focusTarget.tagName === 'TEXTAREA')
      );
    }

    this.focus$ = document.on$('focusin').pipe(filter(isInput), share());
    this.blur$ = document.on$('focusout').pipe(filter(isInput), share());
  }
}
