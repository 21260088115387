import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef} from '@angular/core';

import {FlowProgressComponent} from '../flow-progress.component';

import {Step} from './step';
import {StepComponent} from './step.component';

/**
 * @ngModule FlowProgressModule
 */
@Component({
  selector: 'maia-flow-progress-invisible-step',
  template: '',
  styleUrls: ['./invisible-step.component.scss'],

  inputs: ['active', 'disabled', 'title'],

  outputs: ['activeChange'],

  changeDetection: ChangeDetectionStrategy.OnPush,

  // With this provider we can use @ContentChildren(Step) in the FlowProgressComponent
  providers: [{provide: Step, useExisting: forwardRef(() => InvisibleStepComponent)}],
})
export class InvisibleStepComponent extends StepComponent {
  public constructor(container: FlowProgressComponent, changeDetector: ChangeDetectorRef) {
    super(container, changeDetector);

    // make this step invisible
    this.weight = 0;
  }
}
