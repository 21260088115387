import {Directive, ElementRef, forwardRef} from '@angular/core';

import {CapturedInput} from './captured-input';

/**
 * @ngModule FormsCaptureModule
 */
@Directive({
  selector: 'input:not([ngModel]):not([formControl]):not([formControlName])',
  providers: [{provide: CapturedInput, useExisting: forwardRef(() => CapturedInputDirective)}],
})
export class CapturedInputDirective implements CapturedInput {
  public readonly element: HTMLInputElement;

  public constructor(elementRef: ElementRef<HTMLInputElement>) {
    this.element = elementRef.nativeElement;
  }

  public setDisabledState(disabled: boolean): void {
    this.element.disabled = disabled;
  }
}
