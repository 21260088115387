import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  Optional,
  ViewChild,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Date, DateKey, DateUtils} from '@atlas/businesstypes';
import {DomIoService} from '@maia/core';

import {
  coerceYearProperty,
  createDefaultYearRange,
  DefaultYearRange,
  NB_VISIBLE_YEARS,
  SELECTED_YEAR_VISIBLE_IDX,
  YEAR_ITEM_HEIGHT,
} from './yearpicker.util';

type DateInput = Date | string | number | undefined | null;

/**
 * A yearpicker allows selection of a year
 *
 * @ngModule DatePickersModule
 */
@Component({
  selector: 'maia-yearpicker',
  templateUrl: './yearpicker.component.html',
  styleUrls: ['./yearpicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => YearpickerComponent), multi: true},
  ],
})
export class YearpickerComponent implements ControlValueAccessor, OnDestroy, AfterViewInit {
  private _minimum: number;
  private _maximum: number;

  private _selected?: number = undefined;

  private _initialYear?: number = undefined;

  private readonly _defaultRange: DefaultYearRange;

  @ViewChild('list', {static: true})
  private _listElement: ElementRef;

  // istanbul ignore next: provided by [(ngModel)]
  private _onChange = (_year: number) => {};
  // istanbul ignore next: provided by [(ngModel)]
  private _onTouch = () => {};

  public constructor(
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly _domIo: DomIoService,
    @Optional() defaultRange?: DefaultYearRange,
  ) {
    this._defaultRange = createDefaultYearRange(defaultRange);
    this.minimum = null;
    this.maximum = null;
  }

  /**
   * The minimum year to show. If no minimum value is given, the `DefaultYearRange` will be used to
   * decide the minimum value.
   */
  @Input()
  public set minimum(value: DateInput) {
    this._minimum = coerceYearProperty(
      value,
      DateUtils.get(DateUtils.today(), DateKey.Year) - this._defaultRange.lowerBound,
    );
  }

  /**
   * The minimum value as number.
   */
  public get rawMinimum(): number {
    return this._minimum;
  }

  /**
   * The maximum year to show. If no maximum value is given, the `DefaultYearRange` will be used to
   * decide the maximum value.
   */
  @Input()
  public set maximum(value: DateInput) {
    this._maximum = coerceYearProperty(
      value,
      DateUtils.get(DateUtils.today(), DateKey.Year) + this._defaultRange.upperBound,
    );
  }

  /**
   * The maximum value as number.
   */
  public get rawMaximum(): number {
    return this._maximum;
  }

  /**
   * The year to center initially. This doesn't set a default value, it only decides what value to
   * center the scrollable list on by default.
   *
   * If a value for this component is set through `[ngModel]`, that value takes precedence over this
   * input.
   */
  @Input()
  public set initialYear(value: DateInput) {
    this._initialYear = coerceYearProperty(value);
  }

  /**
   * Scrolls the container to make the given year visible.
   */
  private _scrollTo(year: number): void {
    this._domIo.measure(() => {
      const listElement = this._listElement.nativeElement as HTMLUListElement;
      const {scrollTop} = listElement;

      // The scrollTop to show the current element at the top of the year picker
      const topScrollTop = (year - this._minimum) * YEAR_ITEM_HEIGHT;
      // The scrollTop to show the current element at the bottom of the year picker
      const bottomScrollTop = (year - this._minimum - (NB_VISIBLE_YEARS - 1)) * YEAR_ITEM_HEIGHT;

      if (scrollTop >= bottomScrollTop && scrollTop <= topScrollTop) {
        return;
      }

      this._domIo.mutate(() => {
        listElement.scrollTop =
          (year - this._minimum - SELECTED_YEAR_VISIBLE_IDX) * YEAR_ITEM_HEIGHT;
      });
    });
  }

  public ngAfterViewInit(): void {
    const scrollToYear = this._selected != null ? this._selected : this._initialYear;

    if (scrollToYear != null) {
      this._scrollTo(scrollToYear);
    }
  }

  /**
   * Returns whether the given year is selected.
   */
  public isSelected(year: number): boolean {
    return year === this._selected;
  }

  /**
   * Selects the given year. The scrollable year list is scrolled to make the given year visible.
   * This updates the `[(ngModel)]` value.
   */
  public selectYear(year: number): void {
    this._selected = year;
    this._scrollTo(year);
    this._onChange(year);
  }

  public ngOnDestroy() {
    this._onTouch();
  }

  // ControlValueAccessor API

  public writeValue(obj: any): void {
    const value = typeof obj === 'number' ? obj : null;

    const current = this._selected;

    if (value == null) {
      this._selected = undefined;
      if (current != null) {
        this._changeDetector.detectChanges();
      }

      return;
    }

    if (value !== current) {
      this._selected = value;
      this._scrollTo(value);
      this._changeDetector.detectChanges();
    }
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }
}
