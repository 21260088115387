import {Injectable, InjectionToken} from '@angular/core';
import {ModalResolution, ModalResult} from '@maia/modals';
import {Observable, of} from 'rxjs';

export const enum DialogResolution {
  FILE_DIALOG = 0,
  PHOTO_DIALOG = 1,
}

export interface DialogFileSelectorServiceInterface {
  showDialog(): Observable<ModalResult<DialogResolution>>;
}

@Injectable()
export class DialogFileSelectorService implements DialogFileSelectorServiceInterface {
  public showDialog(): Observable<ModalResult<DialogResolution>> {
    return of({
      resolution: ModalResolution.CONFIRMED,
      result: DialogResolution.FILE_DIALOG,
    } as ModalResult<DialogResolution>);
  }
}

export const DIALOG_FILE_SELECTOR_SERVICE = new InjectionToken<DialogFileSelectorServiceInterface>(
  'dialogFileSelectorService',
);
