import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule GeneralArrowPointerSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaGeneralArrowPointer]',
  template: '<svg:path d="M340.7 85c-10.9-10.9-28.5-10.9-39.4 0-10.9 10.9-10.9 26 0 36.8l104.1 101.5H27.8C12.5 223.3 0 235.8 0 251.2S12.5 279 27.8 279h377.5l-104 106.7c-10.9 10.9-10.9 29.8 0 40.7 5.3 5.3 12.3 8.8 19.7 8.8 7.4 0 14.4-2.6 19.7-7.8L512 256.1 340.7 85zM321 421.9z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 512 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralArrowPointerSvgComponent {
}

@NgModule({
  declarations: [
    GeneralArrowPointerSvgComponent,
  ],
  exports: [
    GeneralArrowPointerSvgComponent,
  ],
})
export class GeneralArrowPointerSvgModule {}
