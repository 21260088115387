import {Injectable} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Subject} from 'rxjs';

import {Reference} from '../file-uploader/file-uploader.call.factory';

@Injectable()
@UntilDestroy()
export class FileUploaderCallbackService {
  private readonly _upload: Subject<Reference[]> = new Subject();
  private readonly _delete: Subject<Reference> = new Subject();
  private readonly _download: Subject<Reference> = new Subject();

  public afterUpload(references: Reference[]): void {
    this._upload.next(references);
  }

  public afterDelete(reference: Reference): void {
    this._delete.next(reference);
  }

  public afterDownload(reference: Reference): void {
    this._download.next(reference);
  }

  public registerOnUpload(callback: (references: Reference[]) => void) {
    this._upload
      .pipe(takeUntilDestroyed(this))
      .subscribe(uploadReferences => callback(uploadReferences));
  }

  public registerOnDownload(callback: (reference: Reference) => void) {
    this._download
      .pipe(takeUntilDestroyed(this))
      .subscribe(downloadReference => callback(downloadReference));
  }

  public registerOnDelete(callback: (reference: Reference) => void) {
    this._delete
      .pipe(takeUntilDestroyed(this))
      .subscribe(deleteReference => callback(deleteReference));
  }
}
