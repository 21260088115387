import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CircleStepDisplayModule} from '../circle-step-display/circle-step-display.module';

import {FlowChapterGroupComponent} from './flow-chapter-group.component';
import {FlowChapterComponent} from './flow-chapter/flow-chapter.component';

@NgModule({
  declarations: [FlowChapterComponent, FlowChapterGroupComponent],
  imports: [CommonModule, CircleStepDisplayModule],
  exports: [FlowChapterComponent, FlowChapterGroupComponent],
})
export class FlowChapterModule {}
