import {Directive, forwardRef, Input, TemplateRef} from '@angular/core';
import {
  DropdownHost,
  DropdownOptions,
  DropdownTemplateContext,
  SmallDropdownVisualisation,
} from '@maia/dropdowns';
import {ModalResult} from '@maia/modals';
import {Option} from '@maia/select';
import {Observable} from 'rxjs';

export abstract class μInputSelectOpener {
  /**
   * Open the given dropdown content
   */
  public abstract open<T>(
    host: DropdownHost,
    content: TemplateRef<DropdownTemplateContext<Option<T>>>,
    options: DropdownOptions,
  ): Observable<ModalResult<Option<T>>>;
}

export const OPEN_AS_DROPDOWN: μInputSelectOpener = {
  open(host, content, options) {
    return host.prepareTemplate(content, options);
  },
};

/**
 * @ngModule InputSelectModule
 */
@Directive({
  selector: 'maia-input-select[slideInTitle]',
  providers: [
    {
      provide: μInputSelectOpener,
      useExisting: forwardRef(() => μInputSelectOpenAsSlideInDirective),
    },
  ],
})
export class μInputSelectOpenAsSlideInDirective implements μInputSelectOpener {
  @Input()
  public slideInTitle: string;

  public forceFullHeight = false;

  public open<T>(
    host: DropdownHost,
    content: TemplateRef<DropdownTemplateContext<Option<T>>>,
    options: DropdownOptions,
  ): Observable<ModalResult<Option<T>>> {
    return host.prepareTemplate(content, {
      ...options,
      smallDropdownVisualisation: {
        visualisation: SmallDropdownVisualisation.SlideIn,
        options: {
          title: this.slideInTitle,
          fullWidthContent: true,
          forceFullHeight: this.forceFullHeight,
        },
      },
    });
  }
}
