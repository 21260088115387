import {FocusOrigin} from '@angular/cdk/a11y';
import {ModalControl} from '@maia/modals';
import {Observable} from 'rxjs';

import {Highlightable} from '../list-key-manager';

export abstract class OptionContainer<T> {
  /**
   * Emits on keyboard interaction
   */
  public abstract keyEvent$: Observable<void>;

  /**
   * Marks the given option as the active option
   *
   * The active option is the option the user is currently interacting with, e.g. by hovering over
   * it or by toggling through the options using the keyboard.
   */
  public abstract activateOption(option: Option<T>, focusOrigin?: FocusOrigin): void;
}
/**
 * Options for use in select-based components
 *
 * This abstract class can be used instead of injecting the component directly to support different
 * option types, e.g. the OptionDirective in the autocomplete that will extend this interface.
 */
export abstract class Option<T> implements Highlightable {
  /**
   * Unique identifier used for the option element in the DOM
   */
  public abstract readonly id: string;

  /**
   * The value of the option
   */
  public abstract readonly value: T;

  /**
   * Whether the option is selected
   */
  public abstract selected: boolean;

  /**
   * Control for the modal showing the option
   */
  public abstract set control(control: ModalControl<Option<T>, any>);

  // Highlightable API
  public abstract disabled?: boolean;
  public abstract setActiveStyles(focusOrigin: FocusOrigin): void;
  public abstract setInactiveStyles(focusOrigin: FocusOrigin): void;
}
