import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';

/**
 * @deprecated
 * @ngModule MessagingModule
 */
@Component({
  selector: 'hermes-messaging',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagingComponent {
  @Input()
  public messageContext: object;

  @Input()
  public scope: any;

  public constructor(loggerFactory: LoggerFactory) {
    const logger = loggerFactory.createLogger('hermes-messaging');

    logger.error('You are still using hermes-messaging component on this page');
    logger.error('This component is obsolete and should be removed');
    logger.error('Remove the <hermes-messaging> component from your html');
  }
}
