import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 * The Maia Dots Spinner shows 3 dots and an animated bar which will move
 * from left to right infinitely. Example usage:
 *
 * @example
 * <maia-dots-spinner></maia-dots-spinner>
 *
 * @ngModule SpinnersModule
 */
@Component({
  selector: 'maia-dots-spinner',
  templateUrl: './dots-spinner.component.html',
  styleUrls: ['./dots-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DotsSpinnerComponent {}
