/**
 * Extractor for sign responses
 */
export abstract class SignResponseExtractor {
  /**
   * Extracts a sign response from an HTTP response
   *
   * @param body The body of an HTTP response
   */
  public abstract extractSignResponse(body: object): unknown;
}
