import {Component} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';

@Component({
  selector: 'maia-loading-overlay-content',
  templateUrl: './loading-overlay-content.component.html',
  styleUrls: ['./loading-overlay-content.component.scss'],
})
export class LoadingOverlayContentComponent extends ModalContentComponent<void> {
  public constructor(public control: ModalControl<void>) {
    super();
  }
}
