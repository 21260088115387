import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {WindowRef} from '@atlas-angular/cdk/globals';
import {CssClassUtilityFactory} from '@maia/core';

import {BaseNumberDisplayAnimatedComponent} from '../base-number-animated/base-number-animated.component';

/**
 * @ngModule AnimatedDisplaysModule
 */
@Component({
  selector: 'maia-percentage-display[animated]',
  templateUrl: './percentage-display-animated.component.html',
  styleUrls: ['../base-number-animated/base-number-animated.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PercentageDisplayAnimatedComponent
  extends BaseNumberDisplayAnimatedComponent
  implements OnChanges {
  public readonly showSmallDecimals: boolean;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
    windowRef: WindowRef,
    changeDetector: ChangeDetectorRef,
  ) {
    super(cssClassUtilityFactory, renderer, elementRef, windowRef, changeDetector);
  }

  /**
   * Percentage sign
   */
  public get suffix(): string {
    return '%';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }
}
