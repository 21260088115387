import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ViewportName, ViewportService} from '@maia/core';
import {Observable} from 'rxjs';

/**
 * @ngModule DisclaimerModule
 */
@Component({
  selector: 'maia-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisclaimerComponent {
  public readonly isLarge$: Observable<boolean>;

  public constructor(viewportService: ViewportService) {
    this.isLarge$ = viewportService.isAtLeast$(ViewportName.MEDIUM);
  }
}
