import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule LogosItsmeSvgModule
 */
@Component({
  selector: 'svg[ivyHermesLogosItsme]',
  template: '<svg:path fill="#FF4612" d="M272.8 104.9l-58.2-59.2c-10.6-11-31.5-16.3-45.9-12.5l-79 21.6C75.2 58.6 60.2 74 56 88.7l-21 80.6c-3.8 14.9 1.6 35.7 12.3 46.2l57.9 58.7c10.6 11 31.5 16.3 45.9 12.5l79.4-21.4c14.4-3.8 29.4-19.2 33.6-33.9l21.1-80.6c3.4-14.3-2-35.1-12.4-45.9zM95.2 75.1c5.4 0 10.1 4.5 10.1 10.1s-4.5 10.1-10.1 10.1c-5.4 0-10.1-4.5-10.1-10.1.3-5.6 5-9.9 10.1-10.1zm-9.3 31.8c0-1.9 1.3-3.5 3.4-3.7h11.4c1.9 0 3.5 1.3 3.7 3.4v.3l-.3 31.8c0 7.2 4.5 9.8 9.4 9.8 4.8.3 9.1-3.4 9.4-8.2v-.6l.3-50.5c0-2.9 2.2-5.4 5.4-5.4h7.2c2.9 0 5.4 2.2 5.4 5.4v8.5c4.6-2.9 10.7-1.3 13.6 3.4 2.9 4.6 1.3 10.7-3.4 13.6-1.6 1-3.4 1.3-5.3 1.3-1.8 0-3.5-.6-5.1-1.6v23.2c0 7 4.5 9.8 9.4 9.8 3.8.3 7.5-1.3 10.4-3.8 1.6-1.6 3.7-2.2 6.1-2.2 3.2 0 6.1 1.8 7.5 4.5 1.6 2.9 4.5 4.6 7.8 4.5 4.8.3 8.2-1.6 8.5-5.1.3-3.8-1.8-5.4-11-8.5-13.3-4.5-22.2-8.2-21.1-21.4 1-12.5 11-19.7 27-18.6 9 .6 15.7 4.3 19.5 9.1 2.9 3.7 2.2 9-1.6 12-1.6 1.3-3.5 1.8-5.3 1.8-3.2 0-6.1-1.9-7.2-4.8-1.1-2.6-3.7-4.2-6.4-4.2-4.8-.3-7.5 1.6-7.8 4.8-.3 4.5 4.5 5.4 12 8.2 12.6 4.3 21.4 9.1 20.5 20.8-1 13.3-11 20.8-28 19.7-5.4.3-11-1.3-15.7-3.8-6.1 3.4-12.6 4.8-19.5 4.5-7.8 0-14.2-2.2-18.2-6.6-3.5 4.3-8.8 6.9-14.4 6.6-16.2 0-28-7-27.7-22.1l.1-35.9zM242.6 203c0 3.2-2.6 5.6-5.6 5.6h-38.6c.3 8.2 5.1 13.3 13.6 13.3 4.8 0 8.2-1.6 10.4-4.3 1.8-2.6 4.6-3.8 7.8-3.7 4.6.6 8 4.8 7.2 9.4-.3 1.9-1.3 3.5-2.6 4.8-.3.3-.6.6-1 .6s-.3.3-.6.6c-.3 0-.3.3-.6.3-6.1 4.3-13.3 6.4-20.5 6.4-16.6 0-28.6-9.1-32.2-24.6v13.9c0 4.8-3.8 9-8.8 9.1-4.8 0-8.8-4.2-8.8-9v-26.7c0-8.2-3.7-12.6-10.6-12.6-6.2 0-10.4 4.3-11 10.6v28.5c0 4.8-3.8 9-8.8 9.1-4.8 0-8.8-4.2-8.8-9v-26.7c0-8.2-3.7-12.6-10.4-12.6-7 0-11 5.1-11 12.6V225c0 4.8-3.8 9-8.8 9.1-4.8 0-8.8-4.2-8.8-9v-23.7c0-32.9 25.4-31.8 33.1-31.8 7.8-.3 15.2 3.5 19.5 10.1 4.2-6.6 11.5-10.4 19.2-10.1 13.6 0 22.7 8.8 23.8 23.2 3.4-15.8 15.4-24.8 31.5-24.8 18.2 0 30.9 12 30.9 32.5l.5 2.5zm-30.8-19.6c-7.8 0-12.6 4.8-13.3 13.1h25.9c-.4-7.7-4.4-13.1-12.6-13.1z"></svg:path>',

  styles: [
  ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 320 320',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogosItsmeSvgComponent {
}

@NgModule({
  declarations: [
    LogosItsmeSvgComponent,
  ],
  exports: [
    LogosItsmeSvgComponent,
  ],
})
export class LogosItsmeSvgModule {}
