/*
 * This file contains the basic date validators, that is:
 * the validators used to check whether a date is actually a valid date.
 *
 * This differs from the validator-extra file, which contains validators
 * that provide _extra_ validation on top of the basic "is it a valid date?",
 * like "is it before XXX?"
 */

import {
  AtlasInvalidCharactersValidationErrors,
  AtlasInvalidFormatValidationErrors,
  AtlasInvalidLengthValidationErrors,
  combineResults,
  InvalidLimitResult,
  isValid,
  RESULT_VALID,
  ValidationErrors,
  ValidatorInput,
  ValidValidationResult,
} from '../base/validators';

import {Date} from './businesstype';
import {FORMAT, FORMAT_PART_DAY, FORMAT_PART_MONTH, FORMAT_PART_YEAR} from './format';

/**
 * Validation errors object when the year of a date is invalid.
 */
export interface AtlasInvalidYearValidationErrors extends ValidationErrors {
  atlasInvalidYear: InvalidLimitResult<number>;
}

/**
 * Validation errors object when the month of a date is invalid.
 */
export interface AtlasInvalidMonthValidationErrors extends ValidationErrors {
  atlasInvalidMonth: InvalidLimitResult<number>;
}

/**
 * Validation errors object when the day of a date is invalid.
 */
export interface AtlasInvalidDayValidationErrors extends ValidationErrors {
  atlasInvalidDay: InvalidLimitResult<number>;
}

type NumberExtractorInput = string | null | undefined;

const helpers = (function () {
  function createHelper(formatPart: string): (input: NumberExtractorInput) => number {
    const start = FORMAT.indexOf(formatPart);
    const end = start + formatPart.length;

    return function (input: NumberExtractorInput): number {
      if (!input || input.length < end) {
        return NaN;
      }

      return parseInt(input.substring(start, end), 10);
    };
  }

  const getDay = createHelper(FORMAT_PART_DAY);
  const getMonth = createHelper(FORMAT_PART_MONTH);
  const getYear = createHelper(FORMAT_PART_YEAR);

  function hasMonth31Days(month: number): boolean {
    return (
      month === 1 ||
      month === 3 ||
      month === 5 ||
      month === 7 ||
      month === 8 ||
      month === 10 ||
      month === 12
    );
  }

  function isMonthFebruary(month: number): boolean {
    return month === 2;
  }

  function isLeapYear(year: number): boolean {
    return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
  }

  return {
    getDay,
    getMonth,
    getYear,
    getDaysInMonth: (input: NumberExtractorInput): number => {
      const month = getMonth(input);

      if (isNaN(month)) {
        // Month not known, take the highest possible allowed value: 31
        return 31;
      }

      if (hasMonth31Days(month)) {
        return 31;
      }

      if (!isMonthFebruary(month)) {
        return 30;
      }

      const year = getYear(input);
      const allow29Days = isNaN(year) || isLeapYear(year);

      return allow29Days ? 29 : 28;
    },
  };
})();

/**
 * Checks the validity of the given input's length.
 *
 * @param value The date input to validate
 */
function invalidLength(
  value: ValidatorInput<Date>,
): AtlasInvalidLengthValidationErrors | ValidValidationResult {
  if (Date.isDate(value)) {
    return RESULT_VALID;
  }

  if (value && value.length === FORMAT.length) {
    return RESULT_VALID;
  }

  return {
    atlasInvalidLength: {
      requiredLength: FORMAT.length,
      actualLength: value ? value.length : 0,
    },
  };
}

/**
 * Checks whether the given input contains invalid characters.
 *
 * @param value The date input to validate
 */
function invalidCharacters(
  value: ValidatorInput<Date>,
): AtlasInvalidCharactersValidationErrors | ValidValidationResult {
  if (Date.isDate(value)) {
    return RESULT_VALID;
  }

  if (!value || /^[0-9]*$/.test(value)) {
    return RESULT_VALID;
  }

  return {
    atlasInvalidCharacters: true,
  };
}

/**
 * Checks whether the given input is in an invalid format.
 *
 * The format is considered invalid if the first digit cannot
 * possibly start a day.
 *
 * @param value The date input to validate
 */
function invalidFormat(
  value: ValidatorInput<Date>,
): AtlasInvalidFormatValidationErrors | ValidValidationResult {
  if (Date.isDate(value)) {
    return RESULT_VALID;
  }

  // Sadly this is linked to the FORMAT described in ./businesstype.ts
  // The rest of this file is not hard coupled to the value of that format, but this is.

  if (!value || value.length !== 1 || /^[0-3]/.test(value)) {
    return RESULT_VALID;
  }

  return {
    atlasInvalidFormat: true,
  };
}

/**
 * Checks whether the given input contains a valid year.
 *
 * Valid years are positive integers.
 *
 * @param value The date input to validate
 */
function invalidYear(
  value: ValidatorInput<Date>,
): AtlasInvalidYearValidationErrors | ValidValidationResult {
  if (Date.isDate(value)) {
    return RESULT_VALID;
  }

  const year = helpers.getYear(value);

  if (isNaN(year)) {
    // Year is incomplete -> counts as valid
    return RESULT_VALID;
  }

  if (year > 0) {
    return RESULT_VALID;
  }

  return {
    atlasInvalidYear: {
      actualValue: year,
      limit: 1,
    },
  };
}

/**
 * Checks whether the given input contains a valid month.
 *
 * Valid months are integers between 1 and 12 (inclusive).
 *
 * @param value The date input to validate
 */
function invalidMonth(
  value: ValidatorInput<Date>,
): AtlasInvalidMonthValidationErrors | ValidValidationResult {
  if (Date.isDate(value)) {
    return RESULT_VALID;
  }

  const month = helpers.getMonth(value);

  if (isNaN(month)) {
    // Month is incomplete -> counts as valid
    return RESULT_VALID;
  }

  if (month < 1) {
    return {
      atlasInvalidMonth: {actualValue: month, limit: 1},
    };
  }

  if (month > 12) {
    return {
      atlasInvalidMonth: {actualValue: month, limit: 12},
    };
  }

  return RESULT_VALID;
}

/**
 * Checks whether the given input contains a valid day.
 *
 * Valid days are positive integers. The upper bound is defined
 * by the month (e.g. 31 for January, 30 for April) and the year
 * (29 for February 2016, 28 for February 2017).
 *
 * @param value The date input to validate
 */
function invalidDay(
  value: ValidatorInput<Date>,
): AtlasInvalidDayValidationErrors | ValidValidationResult {
  if (Date.isDate(value)) {
    return RESULT_VALID;
  }

  const day = helpers.getDay(value);

  if (isNaN(day)) {
    // Day is incomplete -> counts as valid
    return RESULT_VALID;
  }

  // First check that the day is high enough
  if (day < 1) {
    return {
      atlasInvalidDay: {
        actualValue: day,
        limit: 1,
      },
    };
  }

  const allowedDays = helpers.getDaysInMonth(value);

  if (day > allowedDays) {
    return {
      atlasInvalidDay: {
        actualValue: day,
        limit: allowedDays,
      },
    };
  }

  return RESULT_VALID;
}

/**
 * The BaseDateValidators object contains partial date validators, returning
 * information on the invalidity instead of simply returning a boolean
 * valid/invalid result.
 *
 * @deprecated Use `validateDate` instead
 */
export const BaseDateValidators: {
  /**
   * Checks the validity of the given input's length.
   *
   * @param value The date input to validate
   */
  readonly invalidLength: (
    value: ValidatorInput<Date>,
  ) => AtlasInvalidLengthValidationErrors | ValidValidationResult;
  /**
   * Checks whether the given input contains invalid characters.
   *
   * @param value The date input to validate
   */
  readonly invalidCharacters: (
    value: ValidatorInput<Date>,
  ) => AtlasInvalidCharactersValidationErrors | ValidValidationResult;
  /**
   * Checks whether the given input is in an invalid format.
   *
   * The format is considered invalid if the first digit cannot
   * possibly start a day.
   *
   * @param value The date input to validate
   */
  readonly invalidFormat: (
    value: ValidatorInput<Date>,
  ) => AtlasInvalidFormatValidationErrors | ValidValidationResult;
  /**
   * Checks whether the given input contains a valid year.
   *
   * Valid years are positive integers.
   *
   * @param value The date input to validate
   */
  readonly invalidYear: (
    value: ValidatorInput<Date>,
  ) => AtlasInvalidYearValidationErrors | ValidValidationResult;
  /**
   * Checks whether the given input contains a valid month.
   *
   * Valid months are integers between 1 and 12 (inclusive).
   *
   * @param value The date input to validate
   */
  readonly invalidMonth: (
    value: ValidatorInput<Date>,
  ) => AtlasInvalidMonthValidationErrors | ValidValidationResult;
  /**
   * Checks whether the given input contains a valid day.
   *
   * Valid days are positive integers. The upper bound is defined
   * by the month (e.g. 31 for January, 30 for April) and the year
   * (29 for February 2016, 28 for February 2017).
   *
   * @param value The date input to validate
   */
  readonly invalidDay: (
    value: ValidatorInput<Date>,
  ) => AtlasInvalidDayValidationErrors | ValidValidationResult;
} = {
  invalidLength,
  invalidCharacters,
  invalidFormat,
  invalidYear,
  invalidMonth,
  invalidDay,
};

/**
 * Checks whether the date input is valid or not.
 *
 * @param input The date input to validate
 */
export function isValidDate(input: ValidatorInput<Date>): boolean {
  return isValid(validateDate(input));
}

/**
 * Validate the given date input
 *
 * @param input The date to validate
 */
export function validateDate(
  input: ValidatorInput<Date>,
): ValidationErrors | ValidValidationResult {
  if (Date.isDate(input)) {
    return RESULT_VALID;
  }

  return combineResults(
    invalidLength(input),
    invalidCharacters(input),
    invalidFormat(input),
    invalidYear(input),
    invalidMonth(input),
    invalidDay(input),
  );
}
