import {Formatter} from '../base/formatter';

import {
  ACCOUNT_NUMBER_END,
  ACCOUNT_NUMBER_START,
  BANK_IDENTIFIER_END,
  BANK_IDENTIFIER_START,
  Bban,
  CHECK_DIGITS_END,
  CHECK_DIGITS_START,
} from './businesstype';

export const BbanFormatter: Formatter<Bban> = {
  /**
   * Returns the string value of the bban. Formatted by adding structure
   * of Basic Bank Account Numbers: XXX-XXXXXXX-XX
   *
   * @param value The bban to format
   */
  format(value: Bban): string {
    const unformatted = value.asString();
    return `${unformatted.substring(
      BANK_IDENTIFIER_START,
      BANK_IDENTIFIER_END,
    )}-${unformatted.substring(ACCOUNT_NUMBER_START, ACCOUNT_NUMBER_END)}-${unformatted.substring(
      CHECK_DIGITS_START,
      CHECK_DIGITS_END,
    )}`;
  },

  /**
   * Removes all bban separator characters (spaces, `/` and `-`) from the pretty value.
   *
   * @param bbanString The string to unformat
   */
  unformat(bbanString: string): string {
    return bbanString.replace(/[\s\-/]/g, '');
  },
};
