import {coerceNumberProperty} from '@angular/cdk/coercion';
import {Directive, forwardRef, Input} from '@angular/core';
import {NG_VALIDATORS, ValidatorFn} from '@angular/forms';
import {ExtraTextValidators} from '@atlas/businesstypes';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore See https://github.com/microsoft/rushstack/issues/1050
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type {ParameterizedValidatorConstructor} from '../base/validator';
import {createParameterizedValidatorDirective, createValidatorFn} from '../base/validator';

function toNumber(value: string | number): number {
  const error = new Error(`Expected a number but got "${value}"`);
  const numericValue = coerceNumberProperty(value, error);

  if (error === numericValue) {
    throw error;
  }
  return numericValue as number;
}

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasText][atlasMaxLength]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MaxLengthTextValidator),
      multi: true,
    },
  ],
})
export class MaxLengthTextValidator extends createParameterizedValidatorDirective(
  ExtraTextValidators.maxLength,
) {
  @Input('atlasMaxLength')
  public set maxLength(newValue: number | string) {
    this.setParameter(toNumber(newValue));
  }
}

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasText][atlasMinLength]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MinLengthTextValidator),
      multi: true,
    },
  ],
})
export class MinLengthTextValidator extends createParameterizedValidatorDirective(
  ExtraTextValidators.minLength,
) {
  @Input('atlasMinLength')
  public set minLength(newValue: number | string) {
    this.setParameter(toNumber(newValue));
  }
}

export const ExtraTextControlValidators = {
  minLength(minLength: number): ValidatorFn {
    return createValidatorFn(ExtraTextValidators.minLength(minLength));
  },

  maxLength(maxLength: number): ValidatorFn {
    return createValidatorFn(ExtraTextValidators.maxLength(maxLength));
  },
} as const;
