import {HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CancelError, ConnectorBackend, ConnectorInterceptor} from '@atlas-angular/connector';
import {Call, ConnectorResponse, ConnectorResponseBase} from '@atlas/convertor';
import {SystemMessageType} from '@maia/system-messages';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {MessagingScopeService} from './messaging-scope/messaging-scope.service';
import {MessagingService} from './messaging.service';

/**
 * A `ConnectorInterceptor` that will check whether a message has been set on the response of a
 * call. If so, this message is pushed to the MessagingService and displayed in the
 * MessagingComponent.
 */
@Injectable()
export class MessagingInterceptor implements ConnectorInterceptor {
  private readonly _loggedMessages = new WeakSet<ConnectorResponseBase<unknown> | Error>();

  public constructor(
    private readonly messagingService: MessagingService,
    private readonly messagingScopeService: MessagingScopeService,
  ) {}

  public intercept<I, O>(
    call: Call<I, O>,
    input: I,
    request: HttpRequest<null>,
    next: ConnectorBackend<O>,
  ): Observable<ConnectorResponseBase<O>> {
    return next.handle(request).pipe(
      tap(
        response => this.postMessageToService(response, call),
        responseError => this.postMessageToService(responseError, call),
      ),
    );
  }

  /**
   * This method posts the message on the response object to the messaging service.
   * Depending on the type of the ConnectorResponse given, the type of the message (success, error
   * or warning) is deducted.
   */
  private postMessageToService<I, O>(
    response: ConnectorResponseBase<O> | Error,
    call: Call<I, O>,
  ): void {
    if (response instanceof CancelError || this._loggedMessages.has(response)) {
      return;
    }

    this._loggedMessages.add(response);

    const message = response.message;

    if (message != null && message.length > 0) {
      let messageType: SystemMessageType;
      if (response instanceof ConnectorResponse) {
        messageType = response.isSuccess() ? 'success' : 'warning';
      } else {
        messageType = 'error';
      }

      const scope = this.messagingScopeService.getAssignedScope(call);
      (scope || this.messagingService).add({type: messageType, text: message});
    }
  }
}
