import {Formatter} from '../base/formatter';

import {IdentityCardNumber} from './businesstype';

/**
 * The Identity Card Number Formatter
 */
export const IdentityCardNumberFormatter: Formatter<IdentityCardNumber> = {
  /**
   * Formats the identity card number (e.g. 930518001895 -> 930 - 5180018 - 95)
   *
   * @param value The Identity card number to format
   */
  format(value: IdentityCardNumber): string {
    const stringValue = value.asString();
    return `${stringValue.substring(0, 3)}-${stringValue.substring(3, 10)}-${stringValue.substring(
      10,
      12,
    )}`;
  },

  /**
   * Removes all separators characters (spaces, `-`) from the formatted value.
   *
   * @param value The formatted value
   */
  unformat(value: string): string {
    return value.replace(/[\s/.-]/g, '');
  },
};
