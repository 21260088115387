import {Pipe, PipeTransform} from '@angular/core';
import {Date, DateFormatter} from '@atlas/businesstypes';

/**
 * @ngModule BusinessTypesModule
 */
@Pipe({name: 'atlasDate'})
export class DatePipe implements PipeTransform {
  public transform(value: any): string {
    return Date.isDate(value) ? DateFormatter.format(value) : value;
  }
}
