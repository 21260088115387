import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DividerModule} from '@maia/divider';
import {DropdownsModule} from '@maia/dropdowns';
import {SelectDropdownModule} from '@maia/dropdowns/select';
import {FormsModule as MaiaFormsModule} from '@maia/forms';
import {FormsCaptureModule} from '@maia/forms/capture';
import {μDropdownListsSvgModule} from '@maia/icons';
import {InputSelectModule as MaiaInputSelectModule} from '@maia/input-select';
import {InputWithSelectModule as MaiaInputWithSelectModule} from '@maia/input-with-select';

import {SelectPartsModule} from '@maia/select';

import {μCountryDropdownOpenAsSlideInDirective} from './country-dropdown/country-dropdown-opener.directive';
import {CountryDropdownComponent} from './country-dropdown/country-dropdown.component';
import {InputPhonenumberComponent} from './input-phonenumber/input-phonenumber.component';
import {InputPhonenumberWithControlDirective} from './input-phonenumber/input-phonenumber-with-control.directive';
import {PhonenumberCountryComponent} from './phonenumber-country/phonenumber-country.component';

@NgModule({
  imports: [
    DropdownsModule,
    μDropdownListsSvgModule,
    CommonModule,
    FormsModule,
    FormsCaptureModule,
    MaiaFormsModule,
    ReactiveFormsModule,
    SelectPartsModule,
    SelectDropdownModule,
    MaiaInputSelectModule,
    MaiaInputWithSelectModule,
    DividerModule,
  ],
  declarations: [
    InputPhonenumberComponent,
    CountryDropdownComponent,
    μCountryDropdownOpenAsSlideInDirective,
    PhonenumberCountryComponent,
    InputPhonenumberWithControlDirective,
  ],
  exports: [
    InputPhonenumberComponent,
    μCountryDropdownOpenAsSlideInDirective,
    PhonenumberCountryComponent,
    InputPhonenumberWithControlDirective,
  ],
})
export class InputPhonenumberModule {}
