import {ModalContentComponent} from './modal-content.component';

/**
 * Injectable service that must be notified when the entire modal has been rendered,
 * i.e. any lazy-loaded content.
 */
export abstract class ModalReady {
  /**
   * Whether or not the modal is ready.
   */
  public abstract readonly ready: boolean;

  /**
   * The component, if any
   */
  public abstract readonly component?: ModalContentComponent<unknown>;

  /**
   * Function to be called when the modal is ready.
   */
  public abstract markReady(component?: ModalContentComponent<unknown>): void;
}
