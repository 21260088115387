import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {
  CssClassUtility,
  CssClassUtilityFactory,
  DomIoService,
  FrameThrottleService,
} from '@maia/core';

import {fromEvent, ReplaySubject} from 'rxjs';
import {distinctUntilChanged, mergeMap, startWith} from 'rxjs/operators';

import {TABLE_WIDTH_DEFAULT} from './table.utils';

export type TableDensity = 'big' | 'normal' | 'small';

const CLASSES = {
  density: {
    big: 'p-maia-table--density-big',
    normal: null,
    small: 'p-maia-table--density-small',
  } as {[key in TableDensity]: string | null},
};

/**
 * A table, which has a header, a body and a footer.
 *
 * Tables can be nested, up to one level deep. The nested table will have a blue-ish background.
 *
 * Tables can allow horizontal and/or vertical scrolling. Vertical scrolling only applies to the
 * table body, while vertical scrolling applies to both the header, the body and the footer.
 *
 * @ngModule TablesModule
 */
@Component({
  selector: 'maia-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class TableComponent implements AfterViewInit, OnInit, OnDestroy {
  /**
   * The table container child element for the icons and the horizontal scroll
   */
  @ViewChild('tableContainer')
  private _tableContainer: ElementRef;
  /**
   * Whether the table should be scrollable horizontally.
   */
  @coerceBooleanPrimitive()
  @Input()
  public allowHorizontalScroll = false;

  /**
   * Whether the table should be scrollable vertically.
   */
  @coerceBooleanPrimitive()
  @Input()
  @HostBinding('class.p-maia-table--overflow-y-scroll')
  public allowVerticalScroll = false;

  /**
   * The horizontal scroll position in the table both for left and right sides.
   */
  public scrollLeft = false;
  public scrollRight = false;

  /**
   * Option for enabling/disabling density buttons
   */
  @coerceBooleanPrimitive()
  @Input()
  public showDensityControls = false;

  private readonly _cssClassUtility: CssClassUtility<typeof CLASSES>;

  public activeDensity = new FormControl('normal');

  /**
   * The table width.
   */
  private _width: number;
  /**
   * Observable that emits the width of the table.
   */
  public width$ = new ReplaySubject<number>(1);

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    private readonly _elementRef: ElementRef,
    private readonly _domIoService: DomIoService,
    private readonly _frameThrottleService: FrameThrottleService,
    private readonly _zone: NgZone,
    private readonly _changeDetectionRef: ChangeDetectorRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(CLASSES, renderer, this._elementRef);
    this._width = TABLE_WIDTH_DEFAULT;
  }

  /**
   * The table's width.
   */
  public get width(): number {
    return this._width;
  }

  public set width(tableWidth: number) {
    this._width = tableWidth;
    this.width$.next(this._width);
  }

  /**
   * The table's height if vertical scroll is possible.
   */
  public get height(): number | null {
    if (this.allowVerticalScroll) {
      return this._elementRef.nativeElement.offsetHeight;
    }
    return null;
  }

  /**
   * The table's total width, including scroll content.
   */
  public get scrollWidth(): number | null {
    if (this.allowHorizontalScroll && this._tableContainer) {
      return this._tableContainer.nativeElement.scrollWidth;
    }
    return null;
  }

  public ngOnInit() {
    if (this.allowHorizontalScroll) {
      this.scrollLeft = true;
    }
    this.activeDensity.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe((value: TableDensity) => this._cssClassUtility.setValue('density', value));
  }

  public ngAfterViewInit() {
    this._zone.runOutsideAngular(() => {
      this._frameThrottleService
        .throttle$(fromEvent(this._tableContainer.nativeElement, 'scroll'))
        .pipe(
          startWith(null),
          mergeMap(() =>
            this._domIoService.measure(() => {
              const {scrollWidth, offsetWidth, scrollLeft} = this._tableContainer.nativeElement;
              return {scrollWidth, offsetWidth, scrollLeft};
            }),
          ),
          distinctUntilChanged(),
          takeUntilDestroyed(this),
        )
        .subscribe(scrollContent => {
          this._zone.run(() => {
            if (this.allowHorizontalScroll) {
              this.scrollLeft =
                scrollContent.scrollWidth > scrollContent.offsetWidth
                  ? !(
                      scrollContent.scrollWidth <=
                      scrollContent.scrollLeft + scrollContent.offsetWidth
                    )
                  : true;
              this.scrollRight = scrollContent.scrollLeft > 0;
              this._changeDetectionRef.detectChanges();
            }
          });
        });
    });
  }

  public ngOnDestroy() {
    this.width$.complete();
  }
}
