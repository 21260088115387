import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  QueryList,
} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';

import {FlowProgressComponent} from '../flow-progress/flow-progress.component';
import {InvisibleStepComponent} from '../flow-progress/step/invisible-step.component';
import {MultiStepComponent} from '../flow-progress/step/multi-step.component';
import {Step} from '../flow-progress/step/step';

interface InternalStep {
  title: string;
  enabled: boolean;
  done: boolean;
  step: Step;
  stepIndex: string;
  children?: InternalStep[];
}

const HIGHEST_SINGLE_DIGIT = 9;

function mapToInternalItem(steps: Step[]): InternalStep[] {
  return steps
    .filter((step: Step) => !(step instanceof InvisibleStepComponent))
    .map((step: Step, index: number) => ({
      title: step.title,
      enabled: !step.disabled && (step.done || step.active),
      done: step.done,
      step,
      stepIndex: `${index + 1 <= HIGHEST_SINGLE_DIGIT ? '0' : ''}${index + 1}`,
      children:
        step instanceof MultiStepComponent
          ? mapToInternalItem(step._substeps.toArray())
          : undefined,
    }));
}

@Component({
  selector: 'maia-fallout',
  templateUrl: './fallout.component.html',
  styleUrls: ['./fallout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class FalloutComponent implements AfterContentInit {
  @Input()
  @coerceBooleanPrimitive()
  public show: boolean;

  @Input()
  public steps: QueryList<Step>;

  public items: InternalStep[];

  public constructor(
    private readonly _parent: FlowProgressComponent,
    private readonly _changeDetectorRef: ChangeDetectorRef,
  ) {}

  public activateStep(internalStep: InternalStep): void {
    if (internalStep.done) {
      this._parent.updateActive(internalStep.step, true);
    }
  }

  public ngAfterContentInit(): void {
    this._update();
    this._parent.stateChanged.pipe(takeUntilDestroyed(this)).subscribe(() => this._update());
  }

  public _update(): void {
    this.items = mapToInternalItem(this.steps.toArray());
    this._changeDetectorRef.markForCheck();
  }
}
