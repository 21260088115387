import {Directive} from '@angular/core';
import {μInputSelectOpenAsSlideInDirective} from '@maia/input-select';

/**
 * @ngModule InputAutocompleteModule
 */
@Directive({
  selector: 'maia-input-autocomplete[slideInTitle]',
})
export class InputAutocompleteOpenAsSlideInDirective extends μInputSelectOpenAsSlideInDirective {
  public constructor() {
    super();
    this.forceFullHeight = true;
  }
}
