import {Injectable, isDevMode} from '@angular/core';
import {DocumentRef} from '@atlas-angular/cdk/globals';

import {Utilities} from '../utilities';

/**
 * A global style rule.
 */
export interface GlobalStyleRule {
  /**
   * The CSS class name of the rule.
   */
  readonly className: string;

  /**
   * Whether this rule is installed in the global stylesheet or not.
   */
  readonly active: boolean;

  /**
   * Removes this rule from the global stylesheet.
   */
  destroy(): void;
}

/**
 * The GlobalStyleService grants access to a global dynamic stylesheet, where rules for classes
 * can dynamically be added.
 */
@Injectable({providedIn: 'root'})
export class GlobalStyleService {
  private _styleSheet?: CSSStyleSheet = undefined;

  public constructor(private _document: DocumentRef) {}

  private _getStyleSheet(): CSSStyleSheet {
    if (this._styleSheet == null) {
      const styleElement = this._document.document.createElement('style');
      // helpful attribute for debugging
      // istanbul ignore else: we don't test production mode
      if (isDevMode()) {
        styleElement.setAttribute('maia-global-styles', '');
      }
      // this is only null on very very old browsers
      this._document.document.head!.appendChild(styleElement);

      this._styleSheet = styleElement.sheet as CSSStyleSheet;
    }

    return this._styleSheet;
  }

  /**
   * Adds a CSS rule. The rule will use a randomized version of the `classNameInput` as class name.
   *
   * @param classNameInput The classname input
   * @param body The body of the CSS rule to add
   * @return A rule object
   */
  public addRule(classNameInput: string, body: string): GlobalStyleRule {
    const className = Utilities.randomizeClass(classNameInput);
    const styleSheet = this._getStyleSheet();

    // The index parameter to CSSStyleSheet#insertRule is optional, but the fact that it is optional
    // is quite new (at time of writing: only optional in Chrome, and only as of the previous
    // stable release)
    // For an up to date view on compatibility, see
    // https://developer.mozilla.org/en-US/docs/Web/API/CSSStyleSheet/insertRule#Browser_compatibility
    const idx = styleSheet.cssRules.length;
    styleSheet.insertRule(`.${className} {${body}}`, idx);
    const rule = styleSheet.cssRules[idx];

    let destroyed = false;

    return {
      className,

      get active(): boolean {
        return !destroyed;
      },

      destroy(): void {
        if (destroyed) {
          return;
        }

        for (let i = idx; i >= 0; i--) {
          if (styleSheet.cssRules[i] === rule) {
            styleSheet.deleteRule(i);
          }
        }

        destroyed = true;
      },
    };
  }
}
