import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FormsStepperPlusSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaFormsStepperPlus]',
  template: '<svg:path d="M27 227.5h-216.6V1h-59.1v226.5H-485v59h236.3V513h59.1V286.5H27z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '-485 1 512 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormsStepperPlusSvgComponent {
}

@NgModule({
  declarations: [
    FormsStepperPlusSvgComponent,
  ],
  exports: [
    FormsStepperPlusSvgComponent,
  ],
})
export class FormsStepperPlusSvgModule {}
