import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  Optional,
} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Text} from '@atlas/businesstypes';
import {EMPTY, Observable, Subject} from 'rxjs';

import {Highlighter} from '../highlight';

import {OptionContainer} from './option-container';
import {OptionWithHighlights} from './option-with-highlights';

const RICH_TEXT_HIGHLIGHTER: Highlighter = text => `[C=secondary]${text}[/C]`;

const noopContainer: OptionContainer = {
  findHighlight() {
    return null;
  },
  queryChange: EMPTY,
};

/**
 * A highlight in the option
 *
 * The highlight defines what strings the user can start typing in a type-ahead.
 *
 * @ngModule InputAutocompleteExportedPartsModule
 */
@Component({
  selector: 'maia-option-highlight',
  templateUrl: './option-highlight.component.html',
  styleUrls: ['./option-highlight.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class OptionHighlightComponent implements OnInit, OnChanges {
  private _hasMatch = false;

  private _highlightedContent = new Text('');

  private readonly _matchChange = new Subject<void>();

  /**
   * The label in which to look for highlights
   */
  @Input()
  public label = '';

  /**
   * The key of the label, always set this for options with more than one highlight
   */
  @Input()
  public key?: string = undefined;

  /**
   * Observable that emits `undefined` whenever `hasMatch` changes
   */
  public readonly matchChange: Observable<void> = this._matchChange.asObservable();

  private readonly _container: OptionContainer;

  public constructor(
    private readonly _cdr: ChangeDetectorRef,
    @Optional() container?: OptionContainer,
    @Optional() private readonly _highlightContainer?: OptionWithHighlights<unknown>,
  ) {
    this._container = container || noopContainer;
  }

  /**
   * Whether the highlight matches the type-ahead
   */
  public get hasMatch(): boolean {
    return this._hasMatch;
  }

  public ngOnChanges(): void {
    this._updateHighlight();
  }

  public ngOnInit(): void {
    this._container.queryChange
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => this._updateHighlight());

    if (this._highlightContainer != null) {
      this._highlightContainer.registerHighlight(this).pipe(takeUntilDestroyed(this)).subscribe();
    }
  }

  /**
   * The `label` of this component with highlight applied to it
   */
  public get highlightedContent(): Text {
    return this._highlightedContent;
  }

  private _updateHighlight(): void {
    const highlight = this._container.findHighlight(RICH_TEXT_HIGHLIGHTER, this.label, this.key);

    this._highlightedContent = new Text(highlight || this.label);
    const hasMatch = highlight != null;

    if (this._hasMatch !== hasMatch) {
      this._hasMatch = hasMatch;
      this._matchChange.next();
    }
    this._cdr.markForCheck();
  }
}
