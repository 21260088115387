import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Component, EventEmitter, HostBinding, Input, Optional, Output} from '@angular/core';
import {ListItemService} from '../list-item-service/list-item.service';
import {ListItemComponent} from '../list-item/list-item.component';

/**
 * @ngModule ListsModule
 */
@Component({
  selector: 'maia-list-item-content',
  templateUrl: './list-item-content.component.html',
  styleUrls: ['./list-item-content.component.scss'],
})
export class ListItemContentComponent {
  private _truncate: boolean;
  public disabled: boolean;

  @Output()
  public action: EventEmitter<void>;

  public constructor(
    @Optional() parent: ListItemComponent,
    @Optional() private readonly _listItemService: ListItemService,
  ) {
    if (parent === null) {
      throw new Error(
        'Cannot create a <maia-list-item-content /> component out of <maia-list-item /> component',
      );
    }

    this.action = new EventEmitter<void>();

    this._listItemService.isDisabled().subscribe(isDisabled => {
      this.disabled = isDisabled;
    });
  }

  @Input()
  @HostBinding('class.maia-list-item-content--truncate')
  public get truncate(): boolean {
    return this._truncate;
  }

  public set truncate(truncate: boolean) {
    this._truncate = coerceBooleanProperty(truncate);
  }

  public onAction() {
    this.action.emit();
  }
}
