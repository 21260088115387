import {ComponentFactory, ComponentFactoryResolver, Injectable, Injector} from '@angular/core';
import {
  ModalContentComponent,
  ModalController,
  ModalOptionsWithInput,
  ModalResult,
} from '@maia/modals';

import {Observable} from 'rxjs';

import {
  FULL_VIEW_OVERLAY_OPTIONS,
  FullViewOverlayComponent,
  FullViewOverlayOptions,
  FullViewOverlayOptionsWithInput,
  MODAL_TYPE_FULL_VIEW_OVERLAY,
} from '../full-view-overlay.component';
import {HeaderContainer} from '../header/header-container.service';

@Injectable()
export class FullViewOverlayController {
  private readonly _overlayFactory: ComponentFactory<FullViewOverlayComponent>;

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly injector: Injector,
    componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this._overlayFactory = componentFactoryResolver.resolveComponentFactory(
      FullViewOverlayComponent,
    );
  }

  /**
   * Prepares the loading overlay. Once the returned observable is subscribed to, the loading
   * overlay will be shown. The loading overlay gets dismissed if the subscription is ended.
   *
   * By default the open loading overlay will block scrolling on the page.
   *
   * @param options The text for the loading overlay
   *
   */
  public prepare<O>(
    componentFactory: ComponentFactory<ModalContentComponent<O>>,
    options: FullViewOverlayOptions,
  ): Observable<ModalResult<O>>;
  public prepare<O, I>(
    componentFactory: ComponentFactory<ModalContentComponent<O, I>>,
    options: FullViewOverlayOptionsWithInput<I>,
  ): Observable<ModalResult<O>>;
  public prepare<O, I>(
    componentFactory: ComponentFactory<ModalContentComponent<O>>,
    options: FullViewOverlayOptionsWithInput<I>,
  ): Observable<ModalResult<O>> {
    return new Observable<ModalResult<O>>(observer => {
      const modalOptions = {
        withBackdrop: true,
        blockScrolling: true,
        withVisibleBackdrop: true,
        withClickableBackdrop: false,
        providers: [
          {provide: FULL_VIEW_OVERLAY_OPTIONS, useValue: options},
          {provide: HeaderContainer, deps: []},
        ],
        input: options.input,
      };

      return this._modalCtrl
        .prepare(
          MODAL_TYPE_FULL_VIEW_OVERLAY,
          this.injector,
          this._overlayFactory,
          componentFactory,

          // Explicit cast is necessary because typescript doesn't know what we know thanks to
          // our function definition: The input property on the modal options is always
          // present if I isn't undefined/void.
          modalOptions as ModalOptionsWithInput<I>,
        )
        .subscribe(observer);
    });
  }
}
