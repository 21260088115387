/* eslint-disable @typescript-eslint/ban-types */
import {Hidden, JsonObject} from '@atlas/businesstypes';
import {
  AnyBusinessType,
  Call,
  CallOptions,
  ConnectorErrorResponse,
  ConnectorErrorResponseConstructorArguments,
  ConnectorResponseConstructorArguments,
  ConnectorSigningResponse,
  DeepTypedObject,
  PathIdentifier,
  SignResponseExtractor,
} from '@atlas/convertor';
import {Observable, of, throwError} from 'rxjs';

import {mapToPlainJson} from './plain-json-converter';

export interface PlainJsonConnectorResponseBaseConstructorArguments<O>
  extends ConnectorResponseConstructorArguments<O> {
  signResponse?: unknown;
}

export class PlainJsonConnectorSigningResponse<O> extends ConnectorSigningResponse<O> {
  private readonly signResponse?: any;

  public constructor(input: PlainJsonConnectorResponseBaseConstructorArguments<O>) {
    super(input);
    this.signResponse = input.signResponse;
  }

  public isSuccess(): boolean {
    return true;
  }

  public isWarning(): boolean {
    return false;
  }

  public isSigningRequired(): boolean {
    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    return (
      this.signResponse != null &&
      this.signResponse.signId != null &&
      this.signResponse.signStrategy != null
    );
    /* eslint-enable @typescript-eslint/no-unsafe-member-access */
  }

  public getSigningId(): Hidden | undefined {
    return undefined;
  }

  public getSignResponse(): unknown | undefined {
    return this.signResponse;
  }

  public isRetrySigning(): boolean {
    return false;
  }
}

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface PlainJsonConnectorErrorResponseConstructorArguments<O>
  extends ConnectorErrorResponseConstructorArguments<O> {}

export class PlainJsonConnectorErrorResponse<O> extends ConnectorErrorResponse<O> {
  public constructor(input: PlainJsonConnectorErrorResponseConstructorArguments<O>) {
    super(input);
  }

  public isError(): boolean {
    return false;
  }

  public isTimeout(): boolean {
    return false;
  }

  public isUnknown(): boolean {
    return false;
  }

  public isHttpError(): boolean {
    return true;
  }
}

const enum HttpStatusCode {
  Ok = 200,
  MultipleChoices = 300,
}

interface ProblemJson {
  status: number;
  title: string;

  detail?: string;
}

function isProblem(obj: JsonObject): obj is ProblemJson & JsonObject {
  if (obj == null) {
    return false;
  }

  return typeof obj.status === 'number' && typeof obj.title === 'string';
}

function handleHttpError<O>(
  output: JsonObject,
  httpResponseCode: number,
): Observable<ConnectorSigningResponse<O>> {
  const input: PlainJsonConnectorErrorResponseConstructorArguments<undefined> = {
    httpResponseCode,
  };

  if (isProblem(output)) {
    input.message = output.title;
  }

  return throwError(new PlainJsonConnectorErrorResponse(input));
}

/**
 * Abstract superclass for `Call` that use the Plain JSON format.
 *
 * This class implements the conversion part of the call using a generic mapping
 * between objects containing `BusinessType`s and objects containing Plain JSON.
 */
export abstract class PlainJsonCall<I, O> implements Call<I, O> {
  /**
   * The identifier of this call.
   */
  public abstract readonly identifier: PathIdentifier;

  /**
   * The options for this call.
   */
  public abstract readonly options: CallOptions;

  /**
   * Converts the given input object containing `BusinessType`s into an object containing Plain
   * JSON.
   *
   * @param input The input object to convert
   */
  public convertInput(input: I): object | null {
    return mapToPlainJson(input as any);
  }

  /**
   * Converts the given Plain JSON output object into an object containing `BusinessType`s.
   *
   * @param message The output object to convert
   */
  public abstract convertMessage(message: JsonObject): O;

  /**
   * Converts the given Plain JSON output object into an object containing `BusinessType`s.
   *
   * The success callback on the observable is triggered when the http response code is in the 200
   * range. In all other cases the
   * error callback will be triggered.
   *
   * @param output The output object to convert
   * @param httpResponseCode The response code of the http request
   * @param signResponseExtractor Extractor for sign responses
   */
  public convertOutput(
    output: object,
    httpResponseCode: number,
    signResponseExtractor: SignResponseExtractor,
  ): Observable<ConnectorSigningResponse<O>> {
    if (
      httpResponseCode < HttpStatusCode.Ok ||
      httpResponseCode >= HttpStatusCode.MultipleChoices
    ) {
      return handleHttpError(output as JsonObject, httpResponseCode);
    }

    const data = this.convertMessage(output as JsonObject);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const signResponse = (signResponseExtractor.extractSignResponse(output) as any)
      ?.signResponse as unknown;

    return of(
      new PlainJsonConnectorSigningResponse<O>({data, signResponse}),
    );
  }
}

/**
 * Abstract superclass for `Call` that use the Plain JSON format.
 *
 * This class implements the conversion part of the call using a generic mapping
 * between objects containing `BusinessType`s and objects containing Plain JSON.
 *
 * @deprecated Use `PlainJsonCall` or `EnhancedPlainJsonCall` instead
 */
export abstract class PlainJSONCall<I, O> implements Call<I, O> {
  /**
   * The identifier of this call.
   */
  public abstract readonly identifier: PathIdentifier;

  /**
   * The options for this call.
   */
  public abstract readonly options: CallOptions;

  /**
   * Converts the given input object containing `BusinessType`s into an object containing Plain
   * JSON.
   *
   * @param input The input object to convert
   */
  public convertInput(input: I): object | null {
    return mapToPlainJson(input as any);
  }

  /**
   * Converts the given Plain JSON output object into an object containing `BusinessType`s.
   *
   * @param message The output object to convert
   */
  public abstract convertMessage(message: DeepTypedObject<AnyBusinessType>): O;

  /**
   * Converts the given Plain JSON output object into an object containing `BusinessType`s.
   *
   * The success callback on the observable is triggered when the http response code is in the 200
   * range. In all other cases the
   * error callback will be triggered.
   *
   * @param output The output object to convert
   * @param httpResponseCode The response code of the http request
   * @param signResponseExtractor Extractor for sign responses
   */
  public convertOutput(
    output: object,
    httpResponseCode: number,
    signResponseExtractor: SignResponseExtractor,
  ): Observable<ConnectorSigningResponse<O>> {
    if (
      httpResponseCode < HttpStatusCode.Ok ||
      httpResponseCode >= HttpStatusCode.MultipleChoices
    ) {
      return handleHttpError(output as JsonObject, httpResponseCode);
    }

    const data = this.convertMessage(output as DeepTypedObject<AnyBusinessType>);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const signResponse = (signResponseExtractor.extractSignResponse(output) as any)
      ?.signResponse as unknown;

    return of(
      new PlainJsonConnectorSigningResponse<O>({data, signResponse}),
    );
  }
}
