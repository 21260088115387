import {BusinessType, getEncryptedValue} from '../base/businesstype';
import {JsonValue} from '../base/json';

let instanceCounter = 0;

/**
 * BusinessType wrapper around texts without imposing any
 * format or structure on that text.
 */
export class Hidden extends BusinessType<number> {
  /**
   * Returns whether or not `value` is a Hidden.
   */
  public static isHidden(value: unknown): value is Hidden {
    return value instanceof Hidden;
  }

  /**
   * Don't use this directly, use `fromJsonValue` instead
   */
  public static overrideFromJsonValue(value: JsonValue, encryptedValue?: string): Hidden {
    if (encryptedValue != null) {
      if (value != null) {
        throw new Error(`Can't create a hidden instance with a value and an encrypted value`);
      }

      return new this(encryptedValue);
    }

    if (typeof value !== 'string') {
      throw new Error(`Expected a string but got ${value && typeof value}`);
    }

    return new this(value);
  }

  /**
   * Don't use this directly, use `toJsonValue` instead
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static overrideToJsonValue(_value: Hidden): JsonValue {
    return null;
  }

  /**
   * Constructs a new Hidden instance.
   *
   * @param encryptedValue The encryptedValue
   */
  public constructor(encryptedValue: string) {
    if (typeof encryptedValue !== 'string') {
      throw new Error(
        `Expected a string but got ${
          (typeof encryptedValue === 'object' &&
            // eslint-disable-next-line @typescript-eslint/ban-types
            (encryptedValue as object).constructor.name) ||
          typeof encryptedValue
        }`,
      );
    }

    super(++instanceCounter, encryptedValue);
  }

  /**
   * Returns whether `other` is a `Hidden` representing the same value.
   *
   * `Hidden` objects are only considered representing the same value if they are the same object.
   * This discouraged building actual logic on `Hidden` objects, which should not be done.
   */
  public equals(other: unknown): boolean {
    return other === this;
  }

  public toString(): string {
    return `hidden => ${JSON.stringify(getEncryptedValue(this))}`;
  }
}
