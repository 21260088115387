import {DOWN_ARROW, LEFT_ARROW, RIGHT_ARROW, SPACE, UP_ARROW} from '@angular/cdk/keycodes';

import {CommonButton} from './common-button.interface';

export const DEFAULT_RADIO_GROUP_HORIZONTAL = false;

export function isKeySupported(key: number): boolean {
  return (
    key === RIGHT_ARROW ||
    key === UP_ARROW ||
    key === DOWN_ARROW ||
    key === LEFT_ARROW ||
    key === SPACE
  );
}

export function getIndex(components: CommonButton[], value: any): number {
  for (let i = 0; i < components.length; i++) {
    if (components[i].value === value) {
      return i;
    }
  }
  // 0 for those that have not selection
  return 0;
}

export function allDisabled(components: CommonButton[]): boolean {
  return components.every(component => component.disabled);
}
