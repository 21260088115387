import {Provider} from '@angular/core';

/**
 * Marks a component as allowed to be used in a wide form element.
 * This is purposefully not an InjectionToken, as this is not an officially supported way to query
 * the token via ContentChild (even though it would work)
 */
export const allowedInWideFormElementToken = 'allowedInWideFormElementToken';

export const μMarkAllowedInWideFormElementProvider: Provider = {
  provide: allowedInWideFormElementToken,
  useValue: true,
};
