import {Pipe, PipeTransform} from '@angular/core';
import {Iban, IbanFormatter} from '@atlas/businesstypes';

/**
 * @ngModule BusinessTypesModule
 */
@Pipe({name: 'atlasIban'})
export class IbanPipe implements PipeTransform {
  public transform(value: any): string {
    return Iban.isIban(value) ? IbanFormatter.format(value) : value;
  }
}
