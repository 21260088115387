import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  Renderer2,
} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {CssClassUtilityFactory} from '@maia/core';

import {BaseNumberDisplayComponent} from '../number-display/number-display.component';

/**
 * @ngModule DisplaysModule
 */
@Component({
  selector: 'maia-percentage-display:not([animated])',
  templateUrl: './percentage-display.component.html',
  styleUrls: ['../number-display/number-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PercentageDisplayComponent extends BaseNumberDisplayComponent {
  public readonly showSmallDecimals: boolean = false;

  /**
   * Percentage sign
   */
  public get suffix(): string {
    return '%';
  }

  /**
   * Set to true if you want to strikethrough
   */
  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('class.maia-number-display--strikethrough')
  public strikethrough: boolean;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    super(cssClassUtilityFactory, renderer, elementRef);
  }
}
