import {Injectable} from '@angular/core';
import {WindowRef} from '@atlas-angular/cdk/globals';
import {LoggerFactory} from '@atlas-angular/logger';
import {Logger} from '@atlas/logger';
import {Cordova} from '@hermes-cordova/core';
import {Observable, of} from 'rxjs';
import {catchError, flatMap, map, take} from 'rxjs/operators';

import {OpenUrlService} from '../open-url/open-url.service';

import {PlatformDownloadManager} from './download-manager';
import {Target} from './target';
import {UniformTypeIdentifier} from './uniform-type-indentifier';

type Redirect = 'redirect';
const REDIRECT = 'redirect';

interface NativeResponse {
  url: string;
  type: Redirect;
}

@Injectable({
  providedIn: 'root',
})
export class CordovaDownloadManager implements PlatformDownloadManager {
  private readonly _logger: Logger;
  private readonly window: Window;
  public constructor(
    private readonly cordova: Cordova,
    private readonly openUrlService: OpenUrlService,
    windowRef: WindowRef,
    loggerFactory: LoggerFactory,
  ) {
    this._logger = loggerFactory.createLogger('DownloadManager (cordova)');
    this.window = windowRef.window;
  }

  /**
   * Downloads (and opens) a (static) file url.
   * @param url
   * @param target
   * @param type
   */
  public downloadWithUrl(
    url: string,
    target: Target = Target.BLANK,
    type = UniformTypeIdentifier.PDF,
  ): Observable<void> {
    return this.cordova.execAction('ExternalFileUtil', 'openWith', url, type).pipe(
      take(1),
      flatMap(response => this.redirectIfNeeded(response)),
      // Fallback to opening in the WebView in case of errors (InAppBrowser)
      catchError((error: any) => {
        this._logger.error(`Cannot download url with error: ${error.toString()}`);
        this.openUrlService.open(url, new Map<string, string>(), target);
        return of(undefined);
      }),
    );
  }

  /**
   * Verifies if there are native app(s) to open this file.
   * @param url
   * @param type
   */
  public canOpen(url: string, type = UniformTypeIdentifier.PDF): Observable<boolean> {
    return this.cordova.execAction('ExternalFileUtil', 'canOpen', url, type).pipe(
      take(1),
      map((response: number) => response > 0),
    );
  }

  private redirectIfNeeded(response?: NativeResponse): Observable<void> {
    // on Android, native listeners are created, redirect via javascript (if needed/enabled)
    if (response != null && response.type === REDIRECT) {
      return this.redirect(response.url);
    }
    return of(undefined);
  }

  /**
   * Redirects to the file resource to download it.
   * Note: response needs "ContentDisposition: attachment" in order to work properly.
   * @param url
   */
  private redirect(url: string): Observable<void> {
    this.window.location.assign(url);
    return of(undefined);
  }
}
