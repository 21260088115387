import {Injectable} from '@angular/core';
import {WindowRef} from '@atlas-angular/cdk/globals';
import {animationFrameScheduler, Observable} from 'rxjs';
import {throttleTime} from 'rxjs/operators';

// Ideally we import Throttleconfig from rxjs, but the import was lost in version 6.0

/**
 * Options to pass on in throttle operations
 */
export interface ThrottleConfig {
  /**
   * Whether to emit a value on the leading edge of the throttle window
   */
  leading?: boolean;

  /**
   * Whether to emit a value on the trailing edge of the throttle window
   */
  trailing?: boolean;
}

/**
 * FrameThrottleService that uses the `requestAnimationFrame` function to throttle.
 */
@Injectable({providedIn: 'root'})
export class AnimationFrameFrameThrottleService implements FrameThrottleService {
  public constructor(private _window: WindowRef) {}

  /* @Override */
  public throttle<R>(fn: (...args: any[]) => R): (...args: any[]) => Promise<R> {
    let result: Promise<R> | undefined = undefined;
    let storedArgs: any[] | undefined = undefined;
    let thisArg: any | undefined = undefined;

    const {window} = this._window;

    return function (this: any, ...args: any[]): Promise<R> {
      storedArgs = args;
      thisArg = this;

      if (!result) {
        result = new Promise<R>(resolve => {
          window.requestAnimationFrame(() => {
            result = undefined;
            resolve(fn.apply(thisArg, storedArgs));
          });
        });
      }

      return result;
    };
  }

  /* @Override */
  public throttle$<T>(
    observable: Observable<T>,
    config: ThrottleConfig = {leading: true},
  ): Observable<T> {
    return observable.pipe(throttleTime(0, animationFrameScheduler, config));
  }
}

/**
 * The `FrameThrottleService` exposes methods to throttle functions and observables to ensure only
 * one execution/emit is allowed per browser frame.
 * This can be used to ensure that a function is called at most once per frame, e.g. to respond to
 * mouse events in a drag-and-drop directive.
 *
 * This should not be used to execute code in a frame, see the `DomIoService` for that.
 *
 * @see DomIoService
 */
@Injectable({
  providedIn: 'root',
  useExisting: AnimationFrameFrameThrottleService,
})
export abstract class FrameThrottleService {
  /**
   * Returns a function that throttles the source function.
   *
   * Throttling means that calls to the given function will be spaced out, at most one execution is
   * allowed per browser frame. Calls to the returned function within the same frame will return the
   * same promise. This promise will be resolved after the call to the throttled function.
   *
   * The returned function will pass on all arguments and the `this` context of the _last_ call to
   * the source function. Earlier arguments/context are lost.
   *
   * @see http://drupalmotion.com/article/debounce-and-throttle-visual-explanation
   * @param fn The function to throttle
   * @return a throttled function
   */
  public abstract throttle<R>(fn: () => R): () => Promise<R>;
  public abstract throttle<A, R>(fn: (arg: A) => R): (arg: A) => Promise<R>;
  public abstract throttle<A, B, R>(fn: (arg: A, arg2: B) => R): (arg: A, arg2: B) => Promise<R>;
  public abstract throttle<A, B, C, R>(
    fn: (arg: A, arg2: B, arg3: C) => R,
  ): (arg: A, arg2: B, arg3: C) => Promise<R>;

  /**
   * Throttles the given observable so that it only emits one value per frame.
   *
   * @see http://reactivex.io/rxjs/class/es6/Observable.js~Observable.html#instance-method-throttleTime
   * @param observable The observable to throttle
   * @param config The throttle config, defaults to only emit at the leading edge
   */
  public abstract throttle$<T>(observable: Observable<T>, config?: ThrottleConfig): Observable<T>;
}
