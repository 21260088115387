import {Injectable, NgZone} from '@angular/core';
import {WindowRef} from '@atlas-angular/cdk/globals';
import {observeInZone} from '@atlas-angular/rxjs';
import {Observable, merge} from 'rxjs';

import {DomIoService} from '../raf/dom-io.service';
import {FrameThrottleService} from '../raf/frame-throttle.service';
import {distinctUntilChanged, startWith, switchMap} from 'rxjs/operators';
import {RenderViewportService} from '../viewport/render-viewport.service';

export interface ObservedSize {
  width: number;
  height: number;
}

function isSizeEqual(a: ObservedSize, b: ObservedSize) {
  return a.height === b.height && a.width === b.width;
}

function parseStringSize(padding: string | null): number {
  if (padding == null) {
    return 0;
  }

  const parsed = parseInt(padding, 10);
  return Number.isNaN(parsed) ? 0 : parsed;
}

function mapBorderBoxToContentBox(
  contentBox: ObservedSize,
  style: CSSStyleDeclaration,
): ObservedSize {
  const {
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,

    borderTopWidth,
    borderBottomWidth,
    borderLeftWidth,
    borderRightWidth,
  } = style;

  const {width, height} = contentBox;

  return {
    width:
      width -
      parseStringSize(borderLeftWidth) -
      parseStringSize(paddingLeft) -
      parseStringSize(paddingRight) -
      parseStringSize(borderRightWidth),
    height:
      height -
      parseStringSize(borderTopWidth) -
      parseStringSize(paddingTop) -
      parseStringSize(paddingBottom) -
      parseStringSize(borderBottomWidth),
  };
}

declare global {
  interface ResizeObserverSize {
    readonly blockSize: number;
    readonly inlineSize: number;
  }

  var ResizeObserverEntry: {
    prototype: ResizeObserverEntry;
  };

  interface ResizeObserverEntry {
    // Modern browsers have these properties
    borderBoxSize?: ResizeObserverSize | readonly ResizeObserverSize[];
    contentBoxSize?: readonly ResizeObserverSize[];
    devicePixelContentBoxSize?: readonly ResizeObserverSize[];

    // Deprecated property we need to use in Safari...
    /** @deprecated use contentBoxSize instead */
    contentRect: DOMRectReadOnly;

    target: Element;
  }

  type ResizeObserverCallback = (entries: ResizeObserverEntry[]) => void;

  type ResizeObserverBoxOptions = 'border-box' | 'content-box' | 'device-pixel-content-box';

  interface ResizeObserverOptions {
    box?: ResizeObserverBoxOptions;
  }

  var ResizeObserver: {
    prototype: ResizeObserver;
    new (callback: ResizeObserverCallback): ResizeObserver;
  };

  interface ResizeObserver {
    observe(element: Element, options?: ResizeObserverOptions): void;
    disconnect(): void;
  }

  interface Window {
    ResizeObserver?: typeof ResizeObserver;
    ResizeObserverEntry?: typeof ResizeObserverEntry;
  }
}

/**
 * Observes the size of elements
 */
@Injectable({providedIn: 'root'})
export class SizeObserver {
  public constructor(
    private readonly window: WindowRef,
    private readonly zone: NgZone,
    private readonly domIo: DomIoService,
    private readonly throttler: FrameThrottleService,
    private readonly renderViewport: RenderViewportService,
  ) {}

  /**
   * {@inheritdoc SizeObserver.observeContentBox$}
   *
   * @deprecated Use `observeContentBox$` instead
   */
  public observe$(element: HTMLElement): Observable<ObservedSize> | null {
    return this.observeContentBox$(element);
  }

  /**
   * Observe the content size of the given element
   *
   * Be very careful when using this! If the subscription to the callback changes the content size,
   * it's possible to end up in an infinite asynchronous loop, where the screen toggles between two
   * ore more states ad infinitum.
   *
   * This function returns null if the browser doesn't support observing the element's size.
   *
   * In browsers that do support observing element sizes, this function returns an observable that
   * starts observing the size of the element upon subscription.
   */
  public observeContentBox$(element: HTMLElement): Observable<ObservedSize> | null {
    const {ResizeObserver} = this.window.window;
    if (ResizeObserver == null) {
      return null;
    }

    return this.zone.runOutsideAngular(() =>
      new Observable<ObservedSize>(observer => {
        const resizeObserver = new ResizeObserver(([entry]) => {
          if (entry.target !== element) {
            return;
          }

          let width, height;
          if (entry.contentBoxSize != null) {
            ({inlineSize: width, blockSize: height} = entry.contentBoxSize[0]);
          } else {
            // Safari doesn't support newer ResizeObserverEntry properties as of 2020, so we need to
            // fallback to this deprecated property at least until support for iOS 14 is dropped
            // from the support matrix.
            ({width, height} = entry.contentRect);
          }

          observer.next({width, height});
        });

        resizeObserver.observe(element);
        return () => resizeObserver.disconnect();
      }).pipe(distinctUntilChanged(isSizeEqual), observeInZone(this.zone)),
    );
  }

  /**
   * Observe the border box size of the given element
   *
   * This function returns null if the browser doesn't support observing the element's border box
   * size. The set of browsers that support this isn't necessarily the same as the set of browsers
   * that support observing the content size of an element.
   *
   * In browsers that do support observing element content box sizes, this function returns an
   * observable that starts observing the size of the element upon subscription.
   */
  public observeBorderBox$(element: HTMLElement): Observable<ObservedSize> | null {
    const {ResizeObserver, ResizeObserverEntry} = this.window.window;
    if (
      ResizeObserver == null ||
      ResizeObserverEntry == null ||
      !('borderBoxSize' in ResizeObserverEntry.prototype)
    ) {
      return null;
    }

    return this.zone.runOutsideAngular(() =>
      new Observable<ObservedSize>(observer => {
        const resizeObserver = new ResizeObserver(entries => {
          for (const entry of entries) {
            if (entry.target !== element) {
              continue;
            }
            let width, height;
            const borderBoxSize = entry.borderBoxSize!;
            if (Array.isArray(borderBoxSize)) {
              // According to specification this should be an array
              ({inlineSize: width, blockSize: height} = borderBoxSize[0]);
            } else {
              // Firefox implemented borderBoxSize before the spec changed it into an array
              ({inlineSize: width, blockSize: height} = borderBoxSize as ResizeObserverSize);
            }
            observer.next({width, height});
          }
        });

        resizeObserver.observe(element, {box: 'border-box'});
        return () => resizeObserver.disconnect();
      }).pipe(distinctUntilChanged(isSizeEqual), observeInZone(this.zone)),
    );
  }

  /**
   * {@inheritdoc SizeObserver.observeContentBoxFallback$}
   *
   * @deprecated Use `observeContentBoxFallback$` instead
   */
  public observeFallback$(element: HTMLElement): Observable<ObservedSize> {
    return this.observeContentBoxFallback$(element);
  }

  /**
   * "Observe" the content size of the element using a best-effort fallback
   *
   * The returned observable "observes" the size of the element by listening to the most common
   * cause(s) for element size changes. The result is something that is inherently not 100% correct,
   * but it should be good enough to provide a usable user interface for people on older browsers.
   */
  public observeContentBoxFallback$(element: HTMLElement): Observable<ObservedSize> {
    return this.zone.runOutsideAngular(() =>
      merge(this.window.on$('resize'), this.renderViewport.classesUpdated$).pipe(
        startWith(null),
        obs => this.throttler.throttle$(obs),
        switchMap(() =>
          this.domIo.measure(() => {
            return mapBorderBoxToContentBox(
              element.getBoundingClientRect(),
              this.window.window.getComputedStyle(element),
            );
          }),
        ),
        distinctUntilChanged(isSizeEqual),
        observeInZone(this.zone),
      ),
    );
  }

  /**
   * "Observe" the content size of the element using a best-effort fallback
   *
   * The returned observable "observes" the size of the element by listening to the most common
   * cause(s) for element size changes. The result is something that is inherently not 100% correct,
   * but it should be good enough to provide a usable user interface for people on older browsers.
   */
  public observeBorderBoxFallback$(element: HTMLElement): Observable<ObservedSize> {
    return this.zone.runOutsideAngular(() =>
      merge(this.window.on$('resize'), this.renderViewport.classesUpdated$).pipe(
        startWith(null),
        obs => this.throttler.throttle$(obs),
        switchMap(() =>
          this.domIo.measure(() => {
            const {width, height} = element.getBoundingClientRect();
            return {width, height};
          }),
        ),
        distinctUntilChanged(isSizeEqual),
        observeInZone(this.zone),
      ),
    );
  }
}
