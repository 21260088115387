import {Directive, HostBinding, Input} from '@angular/core';
import {Html} from '@atlas/businesstypes';

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasRenderHtml]',
})
export class HtmlDirective {
  @Input('atlasRenderHtml')
  public htmlInput!: Html; // property is always set because the input name == the directive selector

  @HostBinding('innerHtml')
  public get htmlAsString(): string | undefined {
    return Html.isHtml(this.htmlInput) ? this.htmlInput.asString() : undefined;
  }
}
