import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DropdownsModule} from '@maia/dropdowns';
import {SelectDropdownModule} from '@maia/dropdowns/select';
import {FormsModule as MaiaFormsModule} from '@maia/forms';
import {μDropdownListsSvgModule} from '@maia/icons';
import {SelectPartsModule} from '@maia/select';

import {InputAutocompleteExportedPartsModule} from './input-autocomplete-exported-parts.module';
import {InputAutocompletePartsModule} from './input-autocomplete-parts.module';
import {InputAutocompleteOpenAsSlideInDirective} from './input/input-autocomplete-opener.directive';
import {InputAutocompleteWithControlDirective} from './input/input-autocomplete-with-control.directive';
import {InputAutocompleteComponent} from './input/input-autocomplete.component';

export const EXPORTED_DECLARATIONS = [
  InputAutocompleteComponent,
  InputAutocompleteWithControlDirective,
  InputAutocompleteOpenAsSlideInDirective,
];

/**
 * Module for the `maia-input-autocomplete` component
 */
@NgModule({
  imports: [
    InputAutocompletePartsModule,
    CommonModule,
    MaiaFormsModule,
    DropdownsModule,
    SelectDropdownModule,
    SelectPartsModule,
    μDropdownListsSvgModule,
  ],
  declarations: [EXPORTED_DECLARATIONS],
  exports: [InputAutocompleteExportedPartsModule, EXPORTED_DECLARATIONS],
})
export class InputAutocompleteModule {}
