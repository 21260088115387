export type Country = 'BE' | 'CZ' | 'HU' | 'SK';

export const COUNTRY_REGEX: {[c in Country]: RegExp} = {
  BE: /^\+32/,
  HU: /^\+36/,
  CZ: /^\+420/,
  SK: /^\+421/,
};

export const COUNTRY_PREFIX: {[c in Country]: string} = {
  BE: '+32',
  HU: '+36',
  CZ: '+420',
  SK: '+421',
};
