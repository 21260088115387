import {FocusOrigin} from '@angular/cdk/a11y';
import {ALT, CONTROL, ENTER, ESCAPE, META, SHIFT, TAB} from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  ChangeDetectorRef,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {Validator} from '@angular/forms';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {DocumentRef} from '@atlas-angular/cdk/globals';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {ViewportName, ViewportService} from '@maia/core';
import {
  DropdownHost,
  DropdownOptions,
  DropdownPosition,
  DropdownTemplateContext,
} from '@maia/dropdowns';
import {CapturedInput} from '@maia/forms/capture';
import {BaseInputSelectComponent, μInputSelectOpener} from '@maia/input-select';
import {ModalResolution} from '@maia/modals';
import {Option} from '@maia/select';
import {Subject, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

import {findAnywhereHighlightMatcher, Highlighter, HighlightMatcher} from '../highlight';
import {OptionContainer as AutocompleteOptionContainer} from '../option/option-container';
import {OptionWithHighlights} from '../option/option-with-highlights';
import {typeAhead} from '../type-ahead.util';

import {InputAutocompleteOpenAsSlideInDirective} from './input-autocomplete-opener.directive';
import {MaiaInputAutocompleteInvalidOptionErrors} from './input-autocomplete-validator-interface';

const ATTR_ARIA_EXPANDED = 'aria-expanded';
const ATTR_ARIA_ACTIVEDESCENDANT = 'aria-activedescendant';

function isModifier(keyCode: number): boolean {
  return keyCode === SHIFT || keyCode === CONTROL || keyCode === META || keyCode === ALT;
}

function isntUndefined<T>(value?: T): value is T {
  return value != null;
}

/**
 * Abstract superclass for autocomplete components
 *
 * You don't want to use this directly, use `<maia-input-autocomplete>` or
 * `<maia-search-autocomplete>` instead.
 */
@Directive()
@UntilDestroy()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseInputAutocompleteComponent<T>
  extends BaseInputSelectComponent<T>
  implements OnInit, OnDestroy, AfterViewInit, AutocompleteOptionContainer, Validator {
  public abstract dropdownHost: DropdownHost;

  @ViewChild('dropdownContent', {static: true})
  public dropdownContent: TemplateRef<DropdownTemplateContext<Option<T>>>;

  @ContentChildren(OptionWithHighlights)
  public options: QueryList<OptionWithHighlights<T>>;

  private _input?: CapturedInput = undefined;

  public showToggleButton = false;

  private _value?: T | null = undefined;

  private _typeAheadValue = '';

  private _fallbackInput?: ElementRef<HTMLInputElement> = undefined;

  private _fallbackInputSubscription = Subscription.EMPTY;

  private _optionsInput?: ElementRef<HTMLInputElement> = undefined;

  private _optionsInputSubscription = Subscription.EMPTY;

  /**
   * Whether to show an input element in the options modal
   */
  public showInputInOptions = false;

  /**
   * The raw typeahead input typed by the user, can be used for e.g. filtering the options to show
   */
  @Output()
  public readonly typeAhead = new EventEmitter<string>();

  /**
   * The matcher to use when highlighting options
   *
   * The default highlighter will look for case sensitive matches everywhere in the option, but this
   * can be overriden to e.g. support small user errors, write logic to compare IBAN numbers or only
   * look at the start of the option's content.
   */
  @Input()
  public highlightMatcher: HighlightMatcher = findAnywhereHighlightMatcher;

  /**
   * Emits when the options should re-evaluate whether they match or not
   */
  public readonly queryChange = new Subject<void>();

  /**
   * Emits all keyboard events on the input element
   */
  public readonly keyboardEvents = new Subject<KeyboardEvent>();

  /**
   * Whether or not the fallback `<input>` element should be shown
   */
  public showFallbackInput = false;

  /**
   * The `key` of the highlight that best matches the user input
   *
   * This is used for e.g. a typeahead for accounts where the user can typeahead both the account
   * number and the account name. If they entered the account number, the next time they open the
   * input, we want to show the account number again. The same for account name, if the user typed
   * and selected a value based on the name, we want to show the name the next time the input is
   * opened.
   */
  private _activeHighlightKey?: string = undefined;

  /**
   * Filter the options to only show options that match the typeahead
   *
   * If the user hasn't typed any typeahead, all options are shown.
   *
   * This option is kept simply by choice. If you want more complex behaviour, e.g. fetching values
   * from a remote server or only showing options when three or more characters have been typed, you
   * will need to filter the options from the outside.
   */
  @Input()
  @coerceBooleanPrimitive()
  public filtered = false;

  public constructor(
    cdr: ChangeDetectorRef,
    renderer: Renderer2,
    private readonly _viewport: ViewportService,
    private readonly _document: DocumentRef,
    @Inject(InputAutocompleteOpenAsSlideInDirective) @Optional() opener?: μInputSelectOpener,
  ) {
    super(cdr, renderer, opener);
  }

  // istanbul ignore next: provided by [(ngModel)]
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private _onValidatorChanged = () => {};

  public hasTypeAhead(): boolean {
    return !!this._typeAheadValue;
  }

  protected get typeAheadValue(): string {
    return this._typeAheadValue;
  }

  protected get value(): T | undefined | null {
    return this._value;
  }

  protected set value(value: T | undefined | null) {
    this._value = value;

    if (this._hasCapturedInput()) {
      this._setRawInputValue(this._getValueAsString());
    }
  }

  protected _hasCapturedInput(): boolean {
    return this._input != null;
  }

  /**
   * The captured input
   *
   * Accessing this property when no input has been captured will yield an error
   */
  @ContentChild(CapturedInput)
  public get capturedInput(): CapturedInput {
    this._assertHasCapturedInput();

    return this._input!;
  }

  public set capturedInput(capturedInput: CapturedInput) {
    this._inputSubscription.unsubscribe();
    this._input = capturedInput;

    if (this._input != null) {
      this._input.setDisabledState(this.isDisabled);
      const {element} = this._input;

      const inputSubscription = new Subscription();

      if (element instanceof HTMLInputElement) {
        inputSubscription.add(
          this._renderer.listen(element, 'click', (event: Event) => {
            this._open();
            event.preventDefault();
          }),
        );

        inputSubscription.add(this._listenToInput(element));
      } else {
        const open = (event: Event) => {
          if (!this.isDisabled && !this.showFallbackInput) {
            this.showFallbackInput = true;
            this._cdr.markForCheck();
          }

          event.preventDefault();
        };

        inputSubscription.add(this._renderer.listen(element, 'click', open));
        inputSubscription.add(this._renderer.listen(element, 'keydown.enter', open));
      }

      this._renderer.setAttribute(element, 'aria-haspopup', 'listbox');
      this._renderer.removeAttribute(element, ATTR_ARIA_EXPANDED);

      this._inputSubscription = inputSubscription;
    }
  }

  @ViewChild('fallbackInput', {read: ElementRef})
  public set fallbackInput(fallbackInput: ElementRef<HTMLInputElement>) {
    if (
      this._fallbackInput != null &&
      fallbackInput != null &&
      this._fallbackInput.nativeElement === fallbackInput.nativeElement
    ) {
      return;
    }

    this._fallbackInputSubscription.unsubscribe();
    this._fallbackInput = fallbackInput;

    if (fallbackInput != null) {
      fallbackInput.nativeElement.focus();
      this._fallbackInputSubscription = this._listenToInput(fallbackInput.nativeElement);

      this._setRawInputValue(this._typeAheadValue || null);
      this._writeActiveDescendant();

      // This setter is called in a part of the angular lifecycle where further changes are not
      // allowed (same as AfterViewInit, AfterViewChecked), so open the dropdown asynchronously.
      void Promise.resolve().then(() => {
        if (this._fallbackInput === fallbackInput) {
          this._open();
        }
      });
    }
  }

  public set optionsInput(optionsInput: ElementRef<HTMLInputElement> | undefined) {
    this._optionsInputSubscription.unsubscribe();
    this._optionsInput = optionsInput;

    if (optionsInput != null) {
      if (this.capturedInput.element instanceof HTMLInputElement) {
        optionsInput.nativeElement.placeholder = this.capturedInput.element.placeholder;
      }
      this._optionsInputSubscription = this._listenToInput(optionsInput.nativeElement);

      this._setRawInputValue(this._typeAheadValue || null);
      this._writeActiveDescendant();

      optionsInput.nativeElement.focus();
      optionsInput.nativeElement.select();
    }
  }

  private _listenToInput(element: HTMLInputElement): Subscription {
    const subscription = new Subscription();

    subscription.add(
      this._renderer.listen(element, 'keydown', event => {
        this._captureKeydown(event);
      }),
    );

    subscription.add(
      this._renderer.listen(element, 'blur', (event: FocusEvent) => {
        /**
         * Blur events have weird timings in browsers. The `document.activeElement` is updated _after_
         * the blur event but _before_ the focus event of the new focused element.
         *
         * At the moment the `blur` is triggered, the `document.activeElement` is either the `body`
         * element or the blurred element itself. The first case means another element on the page
         * has gained focus, the latter means our element is still focused.
         * How can a blurred element still be focused? It turns out `blur` events are also triggered
         * if the document loses focus, e.g. when focus moves to devtools, when the user clicks in
         * another window or when the user moves to another tab.
         *
         * `FocusEvent`s have a property called `relatedTarget`. This can be the `EventTarget` that
         * has gained focus, but it can also be `null` for security reasons (e.g. when jumping
         * across `iframe` boundaries).
         *
         * __However__: IE doesn't follow any of what is written above: it updates
         * `document.activeElement` _before_ the blur event, meaning we can use that value safely
         * here, and it doesn't fill in the `relatedTarget` (always `null`).
         *
         * __Conclusion__: we try to use `event.relatedTarget` but fallback to the
         * `document.activeElement`.
         *
         * More info:
         * - https://developer.mozilla.org/en-US/docs/Web/API/FocusEvent
         * - https://www.w3.org/TR/uievents/#idl-focusevent
         * - https://www.w3.org/TR/uievents/#event-type-blur
         */

        if (this.showInputInOptions && this.isOpen) {
          // Slide-in is opened, ignore blur events because the user cannot leave the slide-in without
          // going through the modal control, and we move focus back on close of the modal.
          return;
        }

        // istanbul ignore else - hard to test due to not using IE for running tests
        const newFocusedElement =
          (event.relatedTarget as Element | null) || this._document.document.activeElement;

        // We would prefer to just check the tagName of newFocusedElement, but IE has a vague bug
        // where elements with `display: flex` can become the document.activeElement even if they
        // aren't focusable. Thanks IE.
        // This check also has proper merit though: if someone comes up with an autocomplete that
        // includes a button (looking at you, beneficiary picker), that button will get focus, not the
        // entire options wrapper.

        if (newFocusedElement != null && newFocusedElement.closest('maia-select-options') != null) {
          // focus moved to the options element, this means the client has interacted with the
          // options component using the mouse or touch, move focus back to our element

          element.focus();
          return;
        }

        if (element.parentElement == null) {
          return;
        }

        if (this._document.document.activeElement !== element) {
          this._close();
        }
      }),
    );

    subscription.add(
      this._renderer.listen(element, 'input', () => {
        this._updateTypeAhead(element.value);
      }),
    );

    return subscription;
  }

  protected get _dropdownOptions(): DropdownOptions {
    return {
      position: DropdownPosition.BOTTOM_ALIGNED,
      withoutBackdrop: true,
    };
  }

  protected _open(selectAll = true): void {
    this._setRawInputValue(this._getValueAsString());
    if (this.typeAheadValue !== '') {
      this._setRawInputValue(this.typeAheadValue);
    }

    if (selectAll) {
      const input = this._getActiveInputElement();

      if (input != null) {
        // Select all input to easily allow clearing the value
        input.select();
      }
    }

    super._open();

    this.queryChange.next();
  }

  public ngOnInit(): void {
    this._viewport
      .isAtMost$(ViewportName.SMALL)
      .pipe(takeUntilDestroyed(this))
      .subscribe(isSmall => {
        this.showToggleButton = isSmall;
        this.showInputInOptions = isSmall;
        this._cdr.detectChanges();
      });

    this.afterClose.subscribe(resolution => {
      this.queryChange.next();

      if (this.showFallbackInput) {
        this.showFallbackInput = false;
        this._cdr.markForCheck();
      }

      if (resolution !== ModalResolution.CONFIRMED) {
        // reset the typeahead to the selected value
        const value = this._typeAheadValue;
        this._setRawInputValue(value);
        this._updateTypeAhead(value);
      }
    });
  }

  public ngOnDestroy(): void {
    this.queryChange.complete();

    this._fallbackInputSubscription.unsubscribe();
    this._optionsInputSubscription.unsubscribe();
  }

  public ngAfterViewInit(): void {
    this.initKeyManager();

    typeAhead(this.options)
      .pipe(takeUntilDestroyed(this), filter(isntUndefined))
      .subscribe(typeAheadOption => {
        this.keyManager.setActiveItem(typeAheadOption, 'program');
      });

    this.keyManager.change.pipe(takeUntilDestroyed(this)).subscribe(() => {
      this._writeActiveDescendant();
    });

    this.typeAhead.pipe(takeUntilDestroyed(this)).subscribe(typeAheadvalue => {
      if (typeAheadvalue === '' && this._value != null) {
        this.options.forEach(option => (option.selected = false));
        this.keyManager.setActiveItem(-1);
        this._selectValue(undefined);
      }
    });
  }

  private _captureKeydown(event: KeyboardEvent): void {
    this.keyEvent$.next();

    if (
      !this.isOpen &&
      !isModifier(event.keyCode) &&
      event.keyCode !== ESCAPE &&
      event.keyCode !== TAB
    ) {
      this._open(event.keyCode === ENTER);
      return;
    }

    this.keyboardEvents.next(event);
  }

  public findHighlight(highlighter: Highlighter, content: string, key?: string): string | null {
    if (this.isOpen) {
      return this.highlightMatcher.findHighlight(highlighter, content, this._typeAheadValue, key);
    } else {
      return null;
    }
  }

  public _selectOption(option?: Option<T>): void {
    const actualOption = option != null ? this.options.find(o => o.id === option.id) : null;

    if (actualOption != null) {
      this._activeHighlightKey = actualOption.activeHighlightKey;
    } else {
      this._activeHighlightKey = undefined;
    }

    super._selectOption(option);
  }

  public activateOption(option: Option<T>, focusOrigin?: FocusOrigin): void {
    super.activateOption(this.options.find(o => o.id === option.id)!, focusOrigin);
  }

  private _getActiveInputElement(): HTMLInputElement | undefined {
    if (this._optionsInput != null) {
      return this._optionsInput.nativeElement;
    }

    if (this._fallbackInput != null) {
      return this._fallbackInput.nativeElement;
    }

    const {element} = this.capturedInput;
    if (element instanceof HTMLInputElement) {
      return element;
    }

    return undefined;
  }

  private _writeActiveDescendant(): void {
    const input = this._getActiveInputElement();

    if (input != null) {
      const activeOption = this.keyManager.activeItem;

      if (activeOption != null) {
        this._renderer.setAttribute(input, ATTR_ARIA_ACTIVEDESCENDANT, activeOption.id);
      } else {
        this._renderer.removeAttribute(input, ATTR_ARIA_ACTIVEDESCENDANT);
      }
    }
  }

  private _setRawInputValue(value: string | null) {
    // HTMLInputElement.value can be set to null to clear it, but the typescript types don't
    // reflect this. Therefore, a cast is necessary.

    if (this._optionsInput != null) {
      this._optionsInput.nativeElement.value = value as string;
    }

    if (this._fallbackInput != null) {
      this._fallbackInput.nativeElement.value = value as string;
    }

    const {element} = this.capturedInput;
    if (element instanceof HTMLInputElement) {
      element.value = value as string;
    }
  }

  private _updateTypeAhead(typeAheadValue: string | null): void {
    if (this._typeAheadValue === typeAheadValue) {
      return;
    }

    this._typeAheadValue = typeAheadValue || '';
    this.typeAhead.emit(this._typeAheadValue);

    this._onValidatorChanged();

    if (this.isOpen) {
      this.queryChange.next();
    }

    this._cdr.markForCheck();
  }

  private _getValueAsString(): string | null {
    const option = this.options != null ? this.options.find(o => o.value === this._value) : null;
    return option != null ? option.getHighlightLabel(this._activeHighlightKey) : null;
  }

  // Wrap the input and output of the component to update the typeahead value

  public writeValue(value: T): void {
    super.writeValue(value);

    this._updateTypeAhead(this._getValueAsString());
  }

  public registerOnChange(fn: (value: T) => void): void {
    super.registerOnChange((value: T) => {
      fn(value);

      this._updateTypeAhead(this._getValueAsString());
    });
  }

  public registerOnValidatorChange(onValidatorChange: () => void): void {
    this._onValidatorChanged = onValidatorChange;
  }

  public validate(): MaiaInputAutocompleteInvalidOptionErrors | null {
    if (this._typeAheadValue !== '' && this._typeAheadValue !== this._getValueAsString()) {
      return {
        maiaInputAutocompleteNotFromList: true,
      };
    }
    return null;
  }
}
