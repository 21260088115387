import {animate, style, transition, trigger} from '@angular/animations';
import {ViewportScroller} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {DocumentRef} from '@atlas-angular/cdk/globals';
import {asapScheduler} from 'rxjs';

/**
 * Text to be passed when creating a loading-overlay.
 */
export interface LoadingOverlayOptions {
  title: string;
  scrollTop?: boolean;
}

export const LOADING_OVERLAY_OPTIONS = new InjectionToken<LoadingOverlayOptions>(
  'loadingOverlayOptions',
);

/**
 * Identifier used as type in the modal lifecycle callbacks.
 */
export const MODAL_TYPE_LOADING_OVERLAY = 'maiaLoadingOverlay';
export const TIMING_ANIMATION = 300;
export const OPACITY_ENTER = '1';
export const OPACITY_LEAVE = '0';
export const SCROLL_TOP_COORS: [number, number] = [0, 0];

@Component({
  selector: 'maia-loading-overlay',

  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,

  animations: [
    trigger('loadingOverlay', [
      transition(':enter', [
        style({opacity: OPACITY_LEAVE}),
        animate(TIMING_ANIMATION, style({opacity: OPACITY_ENTER})),
        style('*'),
      ]),
      transition(':leave', [
        style({opacity: OPACITY_ENTER}),
        animate(TIMING_ANIMATION, style({opacity: OPACITY_LEAVE})),
      ]),
    ]),
  ],
  host: {
    '[@loadingOverlay]': '"in"',
    tabindex: '-1',
  },
})
export class LoadingOverlayComponent implements OnDestroy, OnInit {
  private _lastFocus: HTMLElement;
  private _lastScrollPos: [number, number];

  public constructor(
    @Inject(LOADING_OVERLAY_OPTIONS) public options: LoadingOverlayOptions,
    private readonly _documentRef: DocumentRef,
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _viewportScroller: ViewportScroller,
  ) {}

  public ngOnDestroy(): void {
    asapScheduler.schedule(() => {
      this._lastFocus?.focus();
      this._viewportScroller.scrollToPosition(this._lastScrollPos);
    });
  }

  public ngOnInit(): void {
    this._lastFocus = this._documentRef.document.activeElement as HTMLElement;
    this._lastScrollPos = this.options.scrollTop
      ? SCROLL_TOP_COORS
      : this._viewportScroller.getScrollPosition();
    this._elementRef.nativeElement.focus();
  }
}
