import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {UntilDestroy, takeUntilDestroyed} from '@atlas-angular/rxjs';
import {Text} from '@atlas/businesstypes';
import {ConfigurationExtractor} from '@hermes/configuration';
import {LoadingOverlayController} from '@maia/loading-overlay';

import {DeeplinkingConfiguration} from './deeplinking.configuration';
import {DeeplinkingService} from './deeplinking.service';

const FUNCTION_KEY = 'function';
const TOKEN_KEY = 'token';
const CALLER_ID_KEY = 'callerId';
const CATEGORY_ID_KEY = 'categoryId';

function getQueryParam(key: string, params: ParamMap): Text | undefined {
  return params.has(key) ? new Text(params.get(key)!) : undefined;
}

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class DeeplinkingComponent implements OnInit {
  public constructor(
    private readonly deeplinkingService: DeeplinkingService,
    private readonly route: ActivatedRoute,
    private readonly loadingCtrl: LoadingOverlayController,
    private readonly configuration: DeeplinkingConfiguration,
    private readonly configExtractor: ConfigurationExtractor,
  ) {}

  public ngOnInit(): void {
    this.loadingCtrl
      .prepare({
        title: this.configExtractor.extract(this.configuration.title),
      })
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    const params = this.route.snapshot.queryParamMap;
    if (!params.has(FUNCTION_KEY)) {
      throw new Error('Cannot start a deeplink without a function');
    }

    const functionToStart = params.get(FUNCTION_KEY)!;
    const token = getQueryParam(TOKEN_KEY, params);
    const callerId = getQueryParam(CALLER_ID_KEY, params);
    const categoryId = getQueryParam(CATEGORY_ID_KEY, params);

    this.deeplinkingService.start(functionToStart, token, callerId, categoryId);
  }
}
