import {coerceBooleanProperty, coerceNumberProperty} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  ExistingProvider,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import {ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator} from '@angular/forms';
import {coerceBooleanPrimitive, coerceNumberPrimitive} from '@atlas-angular/cdk/coercion';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Text} from '@atlas/businesstypes';
import {Call} from '@atlas/convertor';
import {UniformTypeIdentifier} from '@hermes/open-resources';
import {ModalResolution} from '@maia/modals';
import {TILE_DISABLED_DEFAULT} from '@maia/tiles';

import {DropZoneConfiguration} from '../file-drop-zone/file-drop-zone-aggregator.component';
import {FileUploaderController} from '../file-uploader/file-uploader-controller.service';
import {
  FileUploadOutput,
  Reference,
  TemporaryResourceStoreDownload,
} from '../file-uploader/file-uploader.call.factory';
import {FileUploaderProgressService} from '../shared/file-uploader-progress.service';
import {
  DEFAULT_ALLOW_DOWNLOAD,
  DEFAULT_DELETE_CALLBACK,
  DEFAULT_DOWNLOAD_CALLBACK,
  DEFAULT_MAX_FILE_SIZE,
  DEFAULT_MAX_NUM_OF_FILES,
  DEFAULT_MAX_TOTAL_FILE_SIZE,
  DEFAULT_MIME_TYPES,
  DEFAULT_MIN_NUM_OF_FILES,
  DEFAULT_UPLOAD_CALLBACK,
  FILE_UPLOADER_SETTINGS,
} from '../shared/file-uploader-settings';

import {FileUploaderTileInfoComponent} from './file-uploader-tile-info.component';

const DEFAULT_NUM_FILES_TO_UPLOAD = Infinity;

export interface FileUploaderSlideInConfig {
  title: Text | string;
  additionalInfo?: Text | string;
  dropZones?: DropZoneConfiguration[];
  mimeTypes?: RegExp;
  maxFileSize?: number;
  maxTotalFileSize?: number;
  minNumberOfFiles?: number;
  maxNumberOfFiles?: number;
  allowDownload?: boolean;
  fileUploadCall?: () => Call<FormData, FileUploadOutput>;
  fileDeletionCall?: () => Call<unknown, {}>;
  fileDownloadResource?: (type: UniformTypeIdentifier) => TemporaryResourceStoreDownload;
  afterDownload?: (reference: Reference) => void;
  afterDelete?: (reference: Reference) => void;
  afterUpload?: (references: Reference[]) => void;
}

interface ConstrainedSlideInConfig {
  title: Text;
  additionalInfo?: Text;
  dropZones?: DropZoneConfiguration[];
  mimeTypes?: RegExp;
  maxFileSize?: number;
  maxTotalFileSize?: number;
  minNumberOfFiles?: number;
  maxNumberOfFiles?: number;
  allowDownload?: boolean;
  fileUploadCall?: () => Call<FormData, FileUploadOutput>;
  fileDeletionCall?: () => Call<unknown, {}>;
  fileDownloadResource?: (type: UniformTypeIdentifier) => TemporaryResourceStoreDownload;
  afterDownload?: (reference: Reference) => void;
  afterDelete?: (reference: Reference) => void;
  afterUpload?: (references: Reference[]) => void;
}

const FILE_TILE_VALUE_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileUploaderTileComponent),
  multi: true,
};

const FILE_TILE_VALIDATOR: ExistingProvider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => FileUploaderTileComponent),
  multi: true,
};

/**
 * @ngModule FileUploaderModule
 */
@Component({
  selector: 'hermes-file-uploader-tile',
  templateUrl: './file-uploader-tile.component.html',
  styleUrls: ['./file-uploader-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FileUploaderController,
    FileUploaderProgressService,
    FILE_TILE_VALUE_ACCESSOR,
    FILE_TILE_VALIDATOR,
  ],
  host: {
    tabindex: '0',
  },
})
@UntilDestroy()
export class FileUploaderTileComponent
  implements OnInit, OnChanges, ControlValueAccessor, Validator {
  private _referencedFiles: Reference[] = [];
  private _disabled = TILE_DISABLED_DEFAULT;
  private _filesToUpload = DEFAULT_NUM_FILES_TO_UPLOAD;
  private _slideInConfig?: ConstrainedSlideInConfig;
  public uploadCompleted = false;
  private _additionalInfo: string;

  @coerceBooleanPrimitive()
  @Input()
  public hideUploadedAmountOfFiles = false;

  @Input()
  @coerceNumberPrimitive()
  public minNumberOfFiles = DEFAULT_MIN_NUM_OF_FILES;
  @Input()
  @coerceNumberPrimitive()
  public maxNumberOfFiles = DEFAULT_MAX_NUM_OF_FILES;

  @ContentChild(FileUploaderTileInfoComponent, {read: ElementRef, static: false})
  public set additionalInfo(element: ElementRef) {
    if (element) {
      this._additionalInfo = element.nativeElement.innerHTML;
      if (this._slideInConfig != null)
        this._slideInConfig.additionalInfo = new Text(this._additionalInfo);
    }
  }

  private controlValueAccessorChangeFn = (_value: Reference[]) => {};

  @HostListener('blur')
  public onTouched = () => {};

  public constructor(
    private readonly fileUploaderController: FileUploaderController,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly fileUploaderProgressService: FileUploaderProgressService,
  ) {}

  private emitChangeEvent() {
    this.controlValueAccessorChangeFn(this._referencedFiles.slice());
  }

  @Input()
  public set slideInConfig(slideInConfig: FileUploaderSlideInConfig) {
    const {
      title,
      dropZones,
      maxFileSize,
      maxTotalFileSize,
      mimeTypes,
      allowDownload,
      fileDownloadResource,
      fileDeletionCall,
      fileUploadCall,
      minNumberOfFiles,
      maxNumberOfFiles,
      afterUpload,
      afterDownload,
      afterDelete,
    } = slideInConfig;
    const config = {
      title: Text.isText(title) ? title : new Text(title),
      additionalInfo: new Text(this._additionalInfo ?? ''),
      dropZones,
      maxFileSize,
      maxTotalFileSize,
      mimeTypes,
      allowDownload,
      fileDownloadResource,
      fileDeletionCall,
      fileUploadCall,
      minNumberOfFiles,
      maxNumberOfFiles,
      afterUpload,
      afterDownload,
      afterDelete,
    };
    if (dropZones && dropZones.length > 1) {
      this.filesToUpload = dropZones.length;
    }
    this.minNumberOfFiles = slideInConfig.minNumberOfFiles
      ? slideInConfig.minNumberOfFiles
      : DEFAULT_MIN_NUM_OF_FILES;
    this.maxNumberOfFiles = slideInConfig.maxNumberOfFiles
      ? slideInConfig.maxNumberOfFiles
      : DEFAULT_MAX_NUM_OF_FILES;
    this._slideInConfig = config;
  }

  public setDisabledState(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this.changeDetectorRef.detectChanges();
  }

  @HostBinding('class.hermes-file-uploader-tile--disabled')
  public get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  public get filesToUpload(): number {
    return this._filesToUpload;
  }

  public set filesToUpload(numFiles: number) {
    if (this.isMultiZone()) {
      this._filesToUpload = this._slideInConfig?.dropZones?.length ?? 0;
    } else {
      this._filesToUpload = Math.max(coerceNumberProperty(numFiles), 1);
    }
    const requiredDropZones = ((this._slideInConfig && this._slideInConfig.dropZones) || []).filter(
      dropZone => !dropZone.optional,
    );

    this.minNumberOfFiles = Number.isFinite(this._filesToUpload)
      ? this._filesToUpload
      : DEFAULT_MIN_NUM_OF_FILES;
    this.maxNumberOfFiles = Number.isFinite(this._filesToUpload)
      ? this._filesToUpload
      : DEFAULT_MAX_NUM_OF_FILES;
    if (this.isMultiZone() && requiredDropZones.length > 0) {
      const minNumDropZones = requiredDropZones.length;
      this.minNumberOfFiles = Number.isFinite(minNumDropZones)
        ? minNumDropZones
        : DEFAULT_MIN_NUM_OF_FILES;
    }
  }

  private isMultiZone(): boolean {
    return (
      this._slideInConfig !== undefined &&
      this._slideInConfig.dropZones !== undefined &&
      this._slideInConfig.dropZones.length > 1
    );
  }

  public updateValueStatus() {
    this.fileUploaderProgressService.setProgress(this._referencedFiles);
    const completionStatus =
      this.maxNumberOfFiles >= this._referencedFiles.length &&
      this._referencedFiles.length >= this.minNumberOfFiles;
    this.fileUploaderProgressService.setCompletionStatus(completionStatus);
  }

  public writeValue(references: Reference[]) {
    if (references != null) {
      this._referencedFiles = references;
    } else {
      this._referencedFiles = [];
    }
    this.updateValueStatus();
  }

  public registerOnChange(fn: (references: Reference[]) => void) {
    this.controlValueAccessorChangeFn = fn;
  }

  public validate() {
    return this.uploadCompleted ? null : {fileUploadCompletedError: {valid: false}};
  }

  public registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  public showFileUploadProgress(): boolean {
    return (
      this._filesToUpload > DEFAULT_MIN_NUM_OF_FILES &&
      this._filesToUpload < DEFAULT_NUM_FILES_TO_UPLOAD
    );
  }

  public isUploadStarted(): boolean {
    return this._referencedFiles.length >= DEFAULT_MIN_NUM_OF_FILES;
  }

  public uploadedFilesAmount(): number {
    return this._referencedFiles.length;
  }

  public ngOnChanges() {
    this.updateValueStatus();
  }

  public ngOnInit() {
    this.fileUploaderProgressService.completed$
      .pipe(takeUntilDestroyed(this))
      .subscribe((completed: boolean) => {
        this.uploadCompleted = completed;
        this.changeDetectorRef.detectChanges();
      });
    this.fileUploaderProgressService.onProgress$
      .pipe(takeUntilDestroyed(this))
      .subscribe(references => {
        this._referencedFiles = references;
        this.changeDetectorRef.detectChanges();
      });
  }

  @HostListener('click')
  public onClick() {
    if (this.disabled || this._slideInConfig == null) {
      return;
    }
    const {
      title,
      additionalInfo,
      dropZones,
      mimeTypes,
      maxFileSize,
      maxTotalFileSize,
      allowDownload,
      afterUpload,
      fileUploadCall,
      fileDownloadResource,
      fileDeletionCall,
      afterDownload,
      afterDelete,
    } = this._slideInConfig;
    this.updateValueStatus();
    this.fileUploaderController
      .prepare(
        {title: title.asString()},
        {
          providers: [
            {
              provide: FILE_UPLOADER_SETTINGS,
              useValue: {
                minNumberOfFiles: this.minNumberOfFiles,
                maxNumberOfFiles: this.maxNumberOfFiles,
                additionalInfo,
                fileUploadCall,
                fileDeletionCall,
                fileDownloadResource,
                initialReferences: this._referencedFiles,
                maxFileSize: maxFileSize ? maxFileSize : DEFAULT_MAX_FILE_SIZE,
                maxTotalFileSize: maxTotalFileSize ? maxTotalFileSize : DEFAULT_MAX_TOTAL_FILE_SIZE,
                mimeTypes: mimeTypes ? mimeTypes : DEFAULT_MIME_TYPES,
                dropZones: dropZones !== undefined ? dropZones : [{}],
                allowDownload: allowDownload !== undefined ? allowDownload : DEFAULT_ALLOW_DOWNLOAD,
                afterUpload: afterUpload || DEFAULT_UPLOAD_CALLBACK,
                afterDownload: afterDownload || DEFAULT_DOWNLOAD_CALLBACK,
                afterDelete: afterDelete || DEFAULT_DELETE_CALLBACK,
              },
              deps: [FileUploaderProgressService],
            },
          ],
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe(modalResult => {
        if (modalResult.resolution === ModalResolution.CONFIRMED) {
          this._referencedFiles = modalResult.result;
        }
        this.emitChangeEvent();
        this.changeDetectorRef.markForCheck();
      });
  }
}
