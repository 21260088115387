import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {FormsModule as MaiaFormsModule} from '@maia/forms';
import {FormsCaptureModule as MaiaFormsCaptureModule} from '@maia/forms/capture';

import {MaskedCardNumberInputDirective} from './masked-input/masked-input-card-number.directive';
import {MaskedClientNumberInputDirective} from './masked-input/masked-input-client-number.directive';
import {MaskedDateInputDirective} from './masked-input/masked-input-date.directive';
import {
  MaskedIbanInputDirective,
  MaskedIbanInputWithCountryDirective,
} from './masked-input/masked-input-iban.directive';
import {MaskedInputIdentityCardNumberDirective} from './masked-input/masked-input-identity-card-number.directive';
import {MaskedNationalRegisterNumberInputDirective} from './masked-input/masked-input-national-register-number.directive';
import {MaskedTextInputDirective} from './masked-input/masked-input-text.directive';
import {MaskedInputTsfrDirective} from './masked-input/masked-input-tsfr.directive';
import {
  MaskedVatInputDirective,
  MaskedVatInputWithCountryDirective,
} from './masked-input/masked-input-vat.directive';
import {MaskedInputWithControlDirective} from './masked-input/masked-input-with-control.directive';
import {MaskedInputComponent} from './masked-input/masked-input.component';

export const MASKED_INPUT_COMPONENTS = [
  MaskedCardNumberInputDirective,
  MaskedClientNumberInputDirective,
  MaskedDateInputDirective,
  MaskedIbanInputDirective,
  MaskedIbanInputWithCountryDirective,
  MaskedInputIdentityCardNumberDirective,
  MaskedInputComponent,
  MaskedInputTsfrDirective,
  MaskedInputWithControlDirective,
  MaskedNationalRegisterNumberInputDirective,
  MaskedTextInputDirective,
  MaskedVatInputDirective,
  MaskedVatInputWithCountryDirective,
];

@NgModule({
  imports: [FormsModule, MaiaFormsModule],
  declarations: [MASKED_INPUT_COMPONENTS],
  exports: [MaiaFormsCaptureModule, MASKED_INPUT_COMPONENTS],
})
export class InputMasksModule {}
