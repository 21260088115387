import {Directive, forwardRef, Inject} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ClientNumber, ClientNumberFormatter, isValidClientNumber} from '@atlas/businesstypes';

import {
  ATLAS_VALUE_ACCESSOR,
  AtlasControlValueAccessor,
  BaseBusinesstypeDirective,
} from '../base/index';

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasClientNumber]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ClientNumberDirective),
    },
  ],
})
export class ClientNumberDirective extends BaseBusinesstypeDirective {
  public constructor(@Inject(ATLAS_VALUE_ACCESSOR) input: AtlasControlValueAccessor) {
    super(input);
  }

  public writeValue(value?: ClientNumber | string): void {
    this._input.writeValue(
      ClientNumber.isClientNumber(value)
        ? ClientNumberFormatter.format(value)
        : value == null
        ? ''
        : value,
    );
  }

  public registerOnChange(fn: (_: ClientNumber | string | null) => void): void {
    this._input.registerOnChange(value => {
      if (value === '') {
        fn(null);
        return;
      }

      const unformatted = ClientNumberFormatter.unformat(value);
      fn(isValidClientNumber(unformatted) ? new ClientNumber(unformatted) : unformatted);
    });
  }
}
