import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FormsStepperMinusSvgModule
 */
@Component({
  selector: 'svg[ivyHermesFormsStepperMinus]',
  template: '<svg:path d="M0 226.4h512v59.2H0z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 512 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormsStepperMinusSvgComponent {
}

@NgModule({
  declarations: [
    FormsStepperMinusSvgComponent,
  ],
  exports: [
    FormsStepperMinusSvgComponent,
  ],
})
export class FormsStepperMinusSvgModule {}
