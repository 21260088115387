/*
 * This file contains basic validators for Decimals.
 */

import {
  AtlasInvalidCharactersValidationErrors,
  AtlasInvalidFormatValidationErrors,
  combineResults,
  isValid,
  RESULT_VALID,
  ValidationErrors,
  ValidatorInput,
  ValidValidationResult,
} from '../base/validators';

import {Decimal} from './businesstype';

export const NEGATIVE_SIGN = '-';
export const DECIMAL_CHAR = '.';
export const DECIMAL_CHARACTERS = /^[-|0-9|.]*$/;
export const DECIMAL_REGEXP = /^-?\d*((\.)\d+)?$/;

export interface AtlasInvalidMultipleDecimalSeparatorsErrors extends ValidationErrors {
  /**
   * If this property is set, the decimal has multiple decimal separator
   */
  atlasInvalidMultipleSeparators: true;
}

export interface AtlasIncompleteIntegerPartErrors extends ValidationErrors {
  /**
   * If this property is set, the decimal has only the character '-'.
   */
  atlasIncompleteIntegerPart: true;
}

export interface AtlasIncompleteFractionPartErrors extends ValidationErrors {
  /**
   * If this property is set, the decimal fraction part is not completed
   */
  atlasIncompleteFractionPart: true;
}

/**
 * Checks if there are invalid characters
 *
 * @param value the decimal input to validate
 */
function invalidCharacters(
  value: ValidatorInput<Decimal>,
): AtlasInvalidCharactersValidationErrors | ValidValidationResult {
  if (Decimal.isDecimal(value)) {
    return RESULT_VALID;
  }
  if (value) {
    return DECIMAL_CHARACTERS.test(value) ? RESULT_VALID : {atlasInvalidCharacters: true};
  }
  return RESULT_VALID;
}

/**
 * Checks if it has the correct format
 *
 * @param value the decimal input to validate
 */
function invalidFormat(
  value: ValidatorInput<Decimal>,
): AtlasInvalidFormatValidationErrors | ValidValidationResult {
  if (Decimal.isDecimal(value)) {
    return RESULT_VALID;
  }
  if (value) {
    return validateRegExp(value) ? RESULT_VALID : {atlasInvalidFormat: true};
  }
  return RESULT_VALID;
}

/**
 * Checks if the given input has more than one decimal separator
 *
 * @param value the decimal input to validate
 */
function invalidMultipleSeparators(
  value: ValidatorInput<Decimal>,
): AtlasInvalidMultipleDecimalSeparatorsErrors | ValidValidationResult {
  if (Decimal.isDecimal(value)) {
    return RESULT_VALID;
  }
  if (value) {
    return validateMultipleSeparators(value)
      ? RESULT_VALID
      : {atlasInvalidMultipleSeparators: true};
  }
  return RESULT_VALID;
}

/**
 * Checks the validity of the given input integer part.
 *
 * @param value The decimal input to validate
 */
function incompleteIntegerPart(
  value: ValidatorInput<Decimal>,
): AtlasIncompleteIntegerPartErrors | ValidValidationResult {
  if (Decimal.isDecimal(value)) {
    return RESULT_VALID;
  }

  return validateIntegerPart(value) ? RESULT_VALID : {atlasIncompleteIntegerPart: true};
}

/**
 * Checks the validity of the given input fraction part.
 *
 * @param value The decimal input to validate
 */
function incompleteFractionPart(
  value: ValidatorInput<Decimal>,
): AtlasIncompleteFractionPartErrors | ValidValidationResult {
  if (Decimal.isDecimal(value)) {
    return RESULT_VALID;
  }
  if (value) {
    return value.endsWith(DECIMAL_CHAR) ? {atlasIncompleteFractionPart: true} : RESULT_VALID;
  }
  return RESULT_VALID;
}

/**
 * @deprecated Use `validateDecimal` instead
 */
export const BaseDecimalValidators: {
  /**
   * Checks if there are invalid characters
   *
   * @param value the decimal input to validate
   */
  readonly invalidCharacters: (
    value: ValidatorInput<Decimal>,
  ) => AtlasInvalidCharactersValidationErrors | ValidValidationResult;
  /**
   * Checks if it has the correct format
   *
   * @param value the decimal input to validate
   */
  readonly invalidFormat: (
    value: ValidatorInput<Decimal>,
  ) => AtlasInvalidFormatValidationErrors | ValidValidationResult;
  /**
   * Checks if the given input has more than one decimal separator
   *
   * @param value the decimal input to validate
   */
  readonly invalidMultipleSeparators: (
    value: ValidatorInput<Decimal>,
  ) => AtlasInvalidMultipleDecimalSeparatorsErrors | ValidValidationResult;
  /**
   * Checks the validity of the given input integer part.
   *
   * @param value The decimal input to validate
   */
  readonly incompleteIntegerPart: (
    value: ValidatorInput<Decimal>,
  ) => AtlasIncompleteIntegerPartErrors | ValidValidationResult;
  /**
   * Checks the validity of the given input fraction part.
   *
   * @param value The decimal input to validate
   */
  readonly incompleteFractionPart: (
    value: ValidatorInput<Decimal>,
  ) => AtlasIncompleteFractionPartErrors | ValidValidationResult;
} = {
  invalidCharacters,
  invalidFormat,
  invalidMultipleSeparators,
  incompleteIntegerPart,
  incompleteFractionPart,
};

function validateRegExp(input: string): boolean {
  return DECIMAL_REGEXP.test(input);
}

function validateMultipleSeparators(input: string): boolean {
  return input.indexOf(DECIMAL_CHAR) === input.lastIndexOf(DECIMAL_CHAR);
}

function validateIntegerPart(input: string | null | undefined): boolean {
  return !!input && input !== NEGATIVE_SIGN;
}

/**
 * Checks whether the decimal input is valid or not.
 *
 * @param input The decimal input to validate
 */
export function isValidDecimal(input: ValidatorInput<Decimal>): boolean {
  return isValid(validateDecimal(input));
}

/**
 * Validate the given decimal input
 *
 * @param input The decimal to validate
 */
export function validateDecimal(
  input: ValidatorInput<Decimal>,
): ValidationErrors | ValidValidationResult {
  if (Decimal.isDecimal(input)) {
    return RESULT_VALID;
  }

  return combineResults(
    invalidCharacters(input),
    invalidFormat(input),
    invalidMultipleSeparators(input),
    incompleteIntegerPart(input),
    incompleteFractionPart(input),
  );
}
