import {ChangeDetectionStrategy, Component, Optional} from '@angular/core';

import {ImageTileLargeComponent} from '../image-tile-large/image-tile-large.component';
import {ImageTileComponent} from '../image-tile/image-tile.component';

/**
 * Image Tile Label Component
 *
 * This component contains the text for the top label in normal font size and bold weight
 * Can only be used inside a maia-image-tile
 *
 * @ngModule TilesModule
 */
@Component({
  selector: 'maia-image-tile-label',
  template: '<ng-content></ng-content>',
  styleUrls: ['./image-tile-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageTileLabelComponent {
  public constructor(
    @Optional() parent?: ImageTileComponent,
    @Optional() parentLarge?: ImageTileLargeComponent,
  ) {
    if (parent == null && parentLarge == null) {
      throw new Error(
        'Cannot create a <maia-image-tile-label /> component out of <maia-image-tile /> component',
      );
    }
  }
}
