import {Directive, forwardRef} from '@angular/core';
import {NG_VALIDATORS} from '@angular/forms';
import {validateVat} from '@atlas/businesstypes';

import {AbstractValidatorDirective} from '../base/validator';

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasVat]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BaseVatValidator),
      multi: true,
    },
  ],
})
export class BaseVatValidator extends AbstractValidatorDirective {
  protected readonly validatorFn = validateVat;
}
