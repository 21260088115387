import {ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2} from '@angular/core';
import {coerceDecimal, coerceNumberPrimitive, coerceText} from '@atlas-angular/cdk/coercion';
import {Decimal, Text} from '@atlas/businesstypes';

import {CssClassUtility, CssClassUtilityFactory} from '@maia/core';

import {PriceBlockClasses, PriceBlockType} from './price-block.utils';

const DEFAULT_DECIMALS = 2;

/**
 * Price block component
 *
 * @ngModule PriceBlockModule
 */
@Component({
  selector: 'maia-price-block',
  templateUrl: './price-block.component.html',
  styleUrls: ['./price-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceBlockComponent {
  private readonly _cssClassUtility: CssClassUtility<typeof PriceBlockClasses>;
  private _type: PriceBlockType | null;

  @Input()
  @coerceDecimal()
  public amount: Decimal;

  @Input()
  @coerceText()
  public stringValue: Text;

  @Input()
  @coerceNumberPrimitive()
  public decimals: number = DEFAULT_DECIMALS;

  // Default currency
  private _currency = new Text('EUR');

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    elementRef: ElementRef,
    renderer: Renderer2,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(PriceBlockClasses, renderer, elementRef);
  }

  @Input()
  public get currency(): Text {
    return this._currency;
  }

  public set currency(value: Text) {
    this._currency = value;
  }

  /**
   * @deprecated Set color or background color via classname on element.
   */
  @Input()
  public set type(value: PriceBlockType | null) {
    if (value === this._type) {
      return;
    }
    this._type = value;
    this._cssClassUtility.setValue('type', value);
  }
}
