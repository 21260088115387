import {Component, EventEmitter, HostBinding, Input, Optional, Output} from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {ListItemService} from '../list-item-service/list-item.service';
import {ListItemComponent} from '../list-item/list-item.component';

/**
 * @ngModule ListsModule
 */
@Component({
  selector: 'maia-list-item-extra-action',
  templateUrl: './list-item-extra-action.component.html',
  styleUrls: ['./list-item-extra-action.component.scss'],
})
export class ListItemExtraActionComponent {
  private _disabled: boolean;
  public itemDisabled: boolean;

  @Output()
  public action: EventEmitter<void>;

  public constructor(
    @Optional() parent: ListItemComponent,
    @Optional() private readonly _listItemService: ListItemService,
  ) {
    if (parent === null) {
      throw new Error(
        'Cannot create a <maia-list-item-extra-action /> component out of <maia-list-item /> component',
      );
    }

    this.action = new EventEmitter<void>();

    this._listItemService.isDisabled().subscribe(isDisabled => {
      this.itemDisabled = isDisabled;
    });
  }

  @coerceBooleanPrimitive()
  @HostBinding('class.maia-list-item-extra-action-warning')
  @Input('warning')
  public warning = false;

  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }

  public set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
  }

  public onAction() {
    this.action.emit();
  }
}
