import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Directive, Input, OnInit} from '@angular/core';
import {DateFormatter} from '@atlas/businesstypes';

import {ValueFormatter} from '../util/value-formatter.util';
import {ValueProcessor} from '../util/value-processor.util';

import {MaskedInputComponent} from './masked-input.component';

// Matches with the format needed for creating a new atlas Date
export const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY';

export type DateFormat =
  | typeof DEFAULT_DATE_FORMAT
  | 'MM-DD-YYYY' // USA
  | 'YYYY-MM-DD' // ISO standard
  | 'YYYY. MM. DD.'; // Hungary

export const DATE_TEXT_LENGTH = 8;

function extendFormat(dateFormat: string): string {
  return dateFormat.replace(/-/g, ' - ');
}

const formatPartRe = /(DD|MM|YYYY)/g;

/**
 * This directive configures the masked input for usage with dates. It sets up the correct mask and
 * placeholder. Together with atlas's `[atlasDate]` directive this makes the masked input create
 * Date businesstypes.
 *
 * @ngModule InputMasksModule
 */
@Directive({
  selector: 'maia-masked-input[atlasDate]',
})
export class MaskedDateInputDirective implements ValueFormatter, OnInit {
  private _narrow = false;

  private _dateFormat: DateFormat = DEFAULT_DATE_FORMAT;

  @Input()
  public set dateFormat(dateFormat: DateFormat) {
    this._dateFormat = dateFormat;
    this._setMaskAndPlaceholder();
    this._input.reprocessValue();
  }

  public get dateFormat(): DateFormat {
    return this._dateFormat;
  }

  @Input()
  public set narrow(narrow: boolean) {
    this._narrow = coerceBooleanProperty(narrow);
  }

  /**
   * The placeholder to use
   *
   * The placeholder must match regex `/^..-..-....$/` if `narrow`, otherwise it must match
   * `/^.. - .. - ....$/`.
   */
  @Input()
  public placeholder?: string;

  public constructor(private readonly _input: MaskedInputComponent) {
    this._input.setValueFormatter(this);
  }

  /**
   * Doesn't format anything, formatting is done by the `[atlasDate]` directive.
   */
  public formatValue(value: string): string {
    return value;
  }

  /**
   * Unformats any formatting left in place by the `[atlasDate]` directive.
   */
  public unformatValue(value: string): string {
    return DateFormatter.unformat(value);
  }

  public ngOnInit(): void {
    this._setMaskAndPlaceholder();
  }

  private _setMaskAndPlaceholder() {
    const mask = this._createMask();
    const processor = this._getValueProcessor();
    const placeholder = this._getFormattedPlaceholder();

    if (this._narrow) {
      this._input.setMaskAndPlaceholder(mask, placeholder ?? this.dateFormat);
    } else {
      this._input.setMaskAndPlaceholder(
        extendFormat(mask),
        placeholder ? extendFormat(placeholder) : extendFormat(this.dateFormat),
      );
    }

    if (processor) {
      this._input.setValueProcessor(processor);
    }
    this._input.reprocessValue();
  }

  private _createMask(): string {
    return this.dateFormat.replace(/[A-Z]/g, '#');
  }

  /**
   * Return the placeholder in the currently format
   *
   * In other words: put the date parts of the localized placeholder in the same order as the current format.
   *
   * Example: for a date format: 'YYYY-MM-DD' and a placeholder in dutch 'DD-MM-JJJJ', this placeholder is adapted to 'JJJJ-MM-DD'
   */
  private _getFormattedPlaceholder(): string | undefined {
    if (!this.placeholder) {
      return undefined;
    }

    if (!formatPartRe.test(this.dateFormat)) {
      throw new Error('Invalid format. Please use one of the accepted formats.');
    }

    // Year is always 4 chars
    const placeholderYear = /(\w)\1\1\1/.exec(this.placeholder);
    // H for Hungarian translation: 'hónap'
    const placeholderMonth = /MM|HH/i.exec(this.placeholder);

    if (placeholderYear == null || placeholderMonth == null) {
      throw new Error(`Invalid placeholder: ${JSON.stringify(this.placeholder)}`);
    }

    // the days are the residual characters after removing year, month and separators
    const placeholderDay = /(\w)\1/.exec(
      this.placeholder.replace(placeholderYear[0], '').replace(placeholderMonth[0], ''),
    );

    if (placeholderDay == null) {
      throw new Error(`Invalid placeholder: ${JSON.stringify(this.placeholder)}`);
    }

    const parts: Record<string, string> = {
      YYYY: placeholderYear[0],
      MM: placeholderMonth[0],
      DD: placeholderDay[0],
    };

    return this.dateFormat.replace(formatPartRe, part => {
      return parts[part] ?? part;
    });
  }

  /**
   * Return a value processor
   *
   * A ValueProcessor sits between the masked-input and the `[atlasXxx]` directive on the component.
   *
   * The masked-input must emit dates in the `DDMMYYYY` format, that's what the `[atlasDate]`
   * directive expects. We use a ValueProcessor to translate the "raw" value, that's the value the
   * masked-input is using, into "processed" values, i.e. the value returned from / given to the
   * `[atlasDate]` directive.
   */
  private _getValueProcessor(): ValueProcessor | undefined {
    if (!this.placeholder) {
      return undefined;
    }

    const processorParts = this.dateFormat.match(formatPartRe);

    if (processorParts?.length !== 3) {
      throw new Error('Invalid format. Please use one of the accepted formats.');
    }

    let dayIndex = -1,
      monthIndex = -1,
      yearIndex = -1;
    let currentIndex = 0;
    for (const part of processorParts) {
      switch (part) {
        case 'DD':
          dayIndex = currentIndex;
          currentIndex += 2;
          break;
        case 'MM':
          monthIndex = currentIndex;
          currentIndex += 2;
          break;
        case 'YYYY':
          yearIndex = currentIndex;
          currentIndex += 4;
      }
    }

    return {
      toRawValue(processedValue) {
        // processedValue is always DDMMYYYY -> map to correct format

        // We make use of the fact that Array(number) creates an array with empty items. That's
        // different from undefined items: empty items are printed empty when you join the array:
        //   Array(3).join() === ',,'
        const rawValue = Array(8) as string[];

        rawValue[dayIndex] = processedValue.substr(0, 2);
        rawValue[monthIndex] = processedValue.substr(2, 2);
        rawValue[yearIndex] = processedValue.substr(4, 4);

        return rawValue.join('');
      },

      fromRawValue(rawValue) {
        // raw value is in the used format -> map to DDMMYYYY
        return `${rawValue.substr(dayIndex, 2)}${rawValue.substr(monthIndex, 2)}${rawValue.substr(
          yearIndex,
          4,
        )}`;
      },
    };
  }
}
