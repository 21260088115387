import {animate, style, transition, trigger} from '@angular/animations';
import {ChangeDetectionStrategy, Component} from '@angular/core';

const ANIMATION_DURATION = 150; /* ms */

/**
 * A dropdown attached to the viewport edge
 *
 * @ngModule DropdownsModule
 */
@Component({
  selector: 'maia-attached-dropdown',
  templateUrl: './attached-dropdown.component.html',
  styleUrls: ['./attached-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  host: {
    '[@show]': '"shown"',
  },
  animations: [
    trigger('show', [
      transition(':enter', [
        style({opacity: 0}),
        animate(ANIMATION_DURATION, style({opacity: 1})),
        style('*'),
      ]),
      transition(':leave', [style({opacity: 1}), animate(ANIMATION_DURATION, style({opacity: 0}))]),
    ]),
  ],
})
export class AttachedDropdownComponent {}
