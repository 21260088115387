import {BusinessType} from '@atlas/businesstypes';
import {AnyBusinessType, DeepTypedObject} from '@atlas/convertor';
import {PlainJSONCall} from '@atlas/convertor-plain-json';

const ENCRYPTION_SUFFIX = '_Enc';

export interface Constructor<T> {
  new (...args: any[]): T;
}

/**
 * @deprecated Use `CtorObject` from `@atlas/convertor-plain-json` instead
 */
export type CtorHelper<T> = T extends BusinessType<unknown> ? Constructor<T> : CtorObject<T>;

/**
 * Object containing the constructors to instantiate each property of a certain object structure
 *
 * @deprecated Use `CtorObject` from `@atlas/convertor-plain-json` instead
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type CtorObject<T extends {}> = {
  // -? makes all properties required, awesome syntax is awesome
  [key in keyof T]-?: T[key] extends (infer U)[] ? [CtorHelper<U>] : CtorHelper<T[key]>;
};

/**
 * Class providing the necessary logic to convert the output message from Plain JSON calls
 * into a message containing the instantiated BusinessTypes of each property of the output message
 *
 * @deprecated Use `EnhancedPlainJsonCall` from `@atlas/convertor-plain-json` instead
 */
export abstract class εnhancedPlainJsonCall<I, O> extends PlainJSONCall<I, O> {
  public constructor(private readonly outputCtorObject: CtorObject<O>) {
    super();
  }

  public convertMessage(message: DeepTypedObject<AnyBusinessType>): O {
    return convert(this.outputCtorObject)(message || {});
  }
}

/**
 * When called with a constructors' structure object, it will return a function that can be called
 * with the actual output message of a Plain JSON call in order to convert it to another
 * one containing the instantiated BusinessTypes of each property of the message
 *
 * @param structure An object defining the structure of the output Plain JSON message.
 * This structure defines the constructors to instantiate each property of the message.
 *
 * @deprecated Use `createConvertor` from `@atlas/convertor-plain-json` instead
 */

export function convert<O>(structure: CtorObject<O>) {
  return (input: DeepTypedObject<any>): O => {
    return (Object.keys(structure) as (keyof typeof structure)[]).reduce((result, key) => {
      const inputKey = key as string;
      const ctor = structure[key];
      if (input[inputKey] == null) {
        return result;
      }
      if (typeof ctor === 'function') {
        result[key] = new (ctor as Constructor<O[typeof key]>)(
          input[inputKey],
          input[inputKey + ENCRYPTION_SUFFIX],
        );
      } else if (Array.isArray(ctor)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const entryCtor = ctor[0];
        if (typeof entryCtor === 'object') {
          // eslint-disable-next-line
          result[key] = input[inputKey].map(convert(entryCtor));
        } else if (typeof entryCtor === 'function') {
          const list = [];
          for (const entry of input[inputKey]) {
            list.push(new (entryCtor as Constructor<O[typeof key]>)(entry));
          }
          result[key] = (list as unknown) as O[keyof O];
        } else {
          throw new Error(`Unexpected type: ${typeof entryCtor}`);
        }
      } else {
        result[key] = convert(ctor as CtorObject<O[typeof key]>)(input[inputKey]);
      }
      return result;
    }, {} as O);
  };
}
