import {Directive, forwardRef, Inject} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Boolean, BooleanFormatter, isValidBoolean} from '@atlas/businesstypes';

import {
  ATLAS_VALUE_ACCESSOR,
  AtlasControlValueAccessor,
  BaseBusinesstypeDirective,
} from '../base/index';

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasBoolean]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => BooleanDirective),
    },
  ],
})
export class BooleanDirective extends BaseBusinesstypeDirective {
  public constructor(@Inject(ATLAS_VALUE_ACCESSOR) input: AtlasControlValueAccessor) {
    super(input);
  }

  /* eslint-disable-next-line @typescript-eslint/ban-types */
  public writeValue(value?: Boolean | string): void {
    this._input.writeValue(
      Boolean.isBoolean(value) ? BooleanFormatter.format(value) : value == null ? '' : value,
    );
  }

  public registerOnChange(fn: (_: any) => void): void {
    this._input.registerOnChange(value => {
      if (value === '') {
        fn(null);
        return;
      }
      const unformatted = BooleanFormatter.unformat(value);
      fn(isValidBoolean(unformatted) ? new Boolean(unformatted) : unformatted);
    });
  }
}
