import {NgModule} from '@angular/core';
import {UrlHandlingStrategy} from '@angular/router';

import {KeepRouteParamsUrlHandlingStrategy} from './services/keep-route-params-url-handling-strategy.service';

@NgModule({
  providers: [
    {
      provide: UrlHandlingStrategy,
      useClass: KeepRouteParamsUrlHandlingStrategy,
    },
  ],
})
export class AnalyticsJourneyModule {}
