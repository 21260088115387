import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Date, DateFormatter} from '@atlas/businesstypes';
import {DateRange} from '../../daterange';

@Component({
  selector: 'maia-daterange-action-buttons',
  templateUrl: './daterange-action-buttons.component.html',
  styleUrls: ['./daterange-action-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DaterangeActionButtonsComponent {
  @Output()
  public confirm: EventEmitter<void> = new EventEmitter();

  @Output()
  public cancel: EventEmitter<void> = new EventEmitter();

  @Input()
  public dateRange: DateRange;

  @Input()
  public disableConfirm: boolean;

  /**
   * Format the date to DD-MM.
   * */
  public formatDate(date: Date | null): string {
    if (!date) {
      return '';
    }

    // remove -YYYY from formatted string
    return DateFormatter.format(date).slice(0, -5);
  }

  public emitConfirmation() {
    this.confirm.emit();
  }

  public emitCancel() {
    this.cancel.emit();
  }
}
