import {Type} from '@angular/core';
import {Subject, ReplaySubject} from 'rxjs';

/* eslint-disable @typescript-eslint/ban-types */

const subjects = new WeakMap<object, Subject<void>>();

/**
 * Applied to definitions and informs that class is decorated
 */
const DECORATOR_APPLIED = Symbol('__decoratorApplied');

/**
 * If we use the `untilDestroyed` operator multiple times inside the single
 * instance providing different `destroyMethodName`, then all streams will
 * subscribe to the single subject. If any method is invoked, the subject will
 * emit and all streams will be unsubscribed. We wan't to prevent this behavior,
 * thus we store subjects under different symbols.
 */
export function getDestroySubject(instance: object): Subject<void> {
  let subject = subjects.get(instance);

  if (subject == null) {
    subject = new ReplaySubject(1);
    subjects.set(instance, subject);
  }

  return subject;
}

export function markAsDecorated<T>(type: Type<T>): void {
  // Store this property on the prototype if it's an injectable class, component or directive.
  // We will be able to handle class extension this way.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  type.prototype[DECORATOR_APPLIED] = type;
}

export function ensureClassIsDecorated(instance: object, name: string): never | void {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const decoratedType = (instance as any)[DECORATOR_APPLIED] as Type<unknown>;

  if (!decoratedType) {
    throw new Error(
      `Using ${name} requires ${instance.constructor.name} to be marked with the @UntilDestroy() decorator`,
    );
  }

  if (decoratedType !== instance.constructor) {
    throw new Error(
      `Using ${name} requires the @UntilDestroy() decorator to be applied on the instantiated class ${instance.constructor.name} but it was only applied on ${decoratedType.name}`,
    );
  }
}

export function completeSubjectOnTheInstance(instance: object): void {
  const subject = getDestroySubject(instance);
  subject.next();
  subject.complete();
}
