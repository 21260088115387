import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Text} from '@atlas/businesstypes';

import {FileUploaderService} from '../file-uploader/file-uploader.service';
import {
  FILE_UPLOADER_SETTINGS,
  εFileUploaderSettingsStrict,
} from '../shared/file-uploader-settings';

export interface DropZoneConfiguration {
  label?: Text | string;
  constraintText?: Text | string;
  optional?: boolean;
}

export interface εDropZoneConfigurationInterface extends DropZoneConfiguration {
  id: string;
  optional?: boolean;
}

@Component({
  selector: 'hermes-file-drop-zone-aggregator',
  styleUrls: ['./file-drop-zone-aggregator.component.scss'],
  templateUrl: './file-drop-zone-aggregator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * Utility class to create programmatically dropzones according to module configuration
 * settings and keep the id's of the dropzones in session
 * displays progress on top if multiple dropzones and if more than a file is going to be uploaded
 */
@UntilDestroy()
export class FileDropZoneAggregatorComponent implements OnInit {
  public dropZoneConfigurationItems: εDropZoneConfigurationInterface[] = [];
  public amountFilesToUpload: number;
  public amountUploadedFiles = 0;

  public constructor(
    @Inject(FILE_UPLOADER_SETTINGS) private readonly settings: εFileUploaderSettingsStrict,
    private readonly fileUploaderService: FileUploaderService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    this.dropZoneConfigurationItems = this.settings.dropZones.map(
      (dropZoneConfiguration, index) => ({
        ...dropZoneConfiguration,
        ...{
          id: `${index}`,
        },
      }),
    );
    this.amountFilesToUpload = this.settings.minNumberOfFiles;
  }

  public ngOnInit() {
    this.fileUploaderService.uploadedFiles$.pipe(takeUntilDestroyed(this)).subscribe(files => {
      this.amountUploadedFiles = files.length;
      this.changeDetectorRef.detectChanges();
    });
  }

  /**
   * checks if more than a dropzone from configuration settings
   */
  public hasDropZones() {
    return this.dropZoneConfigurationItems.length > 1;
  }

  /**
   * checks if progress should be shown on top of aggregator component
   */
  public showProgress() {
    return !this.hasDropZones() && this.settings.minNumberOfFiles > 1;
  }
}
