/*
 * This file contains the basic iban validators
 * to check whether a iban is actually a valid iban.
 */

import {
  AtlasInvalidCheckDigitsValidationErrors,
  AtlasInvalidLengthValidationErrors,
  AtlasMaxLengthValidationErrors,
  AtlasMinLengthValidationErrors,
  isValid,
  RESULT_VALID,
  ValidationErrors,
  ValidatorInput,
  ValidValidationResult,
} from '../base/validators';

import {Iban} from './businesstype';
import {DEFAULT_IBAN_MAX_LENGTH, IBAN_COUNTRY_LENGTH, IBAN_MIN_LENGTH} from './constants';

/**
 * Checks the validity of the given input's length.
 *
 * @param value The iban input to validate
 */
function invalidLength(
  value: ValidatorInput<Iban>,
):
  | AtlasMaxLengthValidationErrors
  | AtlasInvalidLengthValidationErrors
  | AtlasMinLengthValidationErrors
  | ValidValidationResult {
  if (Iban.isIban(value)) {
    return RESULT_VALID;
  }

  if (value) {
    return validateLength(value);
  } else {
    return {
      atlasMinLength: {
        limit: IBAN_MIN_LENGTH,
        actualValue: 0,
      },
    };
  }
}

/**
 * Checks whether the given input is in an invalid format.
 *
 * The format is considered invalid if the check digit validation fails
 *
 * @param value The iban input to validate
 */
function invalidCheckDigits(
  value: ValidatorInput<Iban>,
): AtlasInvalidCheckDigitsValidationErrors | ValidValidationResult {
  if (Iban.isIban(value)) {
    return RESULT_VALID;
  }

  // Do not trigger checksum validations until length is validated
  if (value && validateLength(value) === RESULT_VALID) {
    // Transform IBAN string by moving country code + checksum digits to the end
    // BE03xxxx -> xxxxBE03
    // Map letters inside iban string to numeral system
    // xxxxBE03 -> xxxx111403
    const transformedIban = (value.substr(4) + value.substring(0, 4)).replace(/./g, char =>
      String(parseInt(char, 36)),
    );

    // TODO(jd68766): Use big.js to calculate the modulo when Decimal BT is finished
    const coss = Math.ceil(transformedIban.length / 7);
    let remainder = '';
    for (let i = 0; i <= coss; i++) {
      const currentActualValue = remainder + transformedIban.substr(i * 7, 7);
      const modulo = parseInt(currentActualValue, 10) % 97;
      remainder = String(modulo);
    }
    if (parseInt(remainder, 10) === 1) {
      return RESULT_VALID;
    } else {
      return {
        atlasInvalidCheckDigits: true,
      };
    }
  } else {
    return RESULT_VALID;
  }
}

/**
 * The BaseIbanValidators object contains partial iban validators, returning
 * information on the invalidity instead of simply returning a boolean
 * valid/invalid result.
 *
 * @deprecated Use `validateIban` instead
 */
export const BaseIbanValidators: {
  /**
   * Checks the validity of the given input's length.
   *
   * @param value The iban input to validate
   */
  readonly invalidLength: (
    value: ValidatorInput<Iban>,
  ) =>
    | AtlasMaxLengthValidationErrors
    | AtlasInvalidLengthValidationErrors
    | AtlasMinLengthValidationErrors
    | ValidValidationResult;
  /**
   * Checks whether the given input is in an invalid format.
   *
   * The format is considered invalid if the check digit validation fails
   *
   * @param value The iban input to validate
   */
  readonly invalidCheckDigits: (
    value: ValidatorInput<Iban>,
  ) => AtlasInvalidCheckDigitsValidationErrors | ValidValidationResult;
} = {
  invalidLength,
  invalidCheckDigits,
};

/**
 *
 * Performs the length check on the given iban number
 *
 * @param inputIban The iban string to validate
 * @returns True in case the iban has the correct length
 */
function validateLength(
  inputIban: string,
):
  | AtlasMaxLengthValidationErrors
  | AtlasInvalidLengthValidationErrors
  | AtlasMinLengthValidationErrors
  | ValidValidationResult {
  const {length} = inputIban;

  if (length < IBAN_MIN_LENGTH) {
    return {
      atlasMinLength: {
        limit: IBAN_MIN_LENGTH,
        actualValue: length,
      },
    };
  }

  // First two characters represent country code: BEXX XXXX... (Belgium)
  const countryCode = inputIban.substring(0, 2).toUpperCase();
  if (IBAN_COUNTRY_LENGTH[countryCode]) {
    if (length === IBAN_COUNTRY_LENGTH[countryCode]) {
      return RESULT_VALID;
    } else {
      return {
        atlasInvalidLength: {
          requiredLength: IBAN_COUNTRY_LENGTH[countryCode],
          actualLength: length,
        },
      };
    }
  }

  // If unknown country code, no country-specific check (future proof)
  if (length <= DEFAULT_IBAN_MAX_LENGTH) {
    return RESULT_VALID;
  } else {
    return {
      atlasMaxLength: {
        limit: DEFAULT_IBAN_MAX_LENGTH,
        actualValue: length,
      },
    };
  }
}

/**
 * Checks whether the iban input is valid or not.
 *
 * @param input The iban input to validate
 */
export function isValidIban(input: ValidatorInput<Iban>): boolean {
  return isValid(validateIban(input));
}

/**
 * Validate the given iban input
 *
 * @param input The iban to validate
 */
export function validateIban(
  input: ValidatorInput<Iban>,
): ValidationErrors | ValidValidationResult {
  if (Iban.isIban(input)) {
    return RESULT_VALID;
  }

  return invalidLength(input) ?? invalidCheckDigits(input);
}
