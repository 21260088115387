import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule LogosEmblemKbcSvgModule
 */
@Component({
  selector: 'svg[kdlBifrostLogosEmblemKbc]',
  template: '<svg:ellipse cx="24.2" cy="15.6" rx="6.6" ry="6.6"></svg:ellipse><svg:path d="M29.1 21c-1.2 1.4-3.5 2.7-6.3 2.7-2.2 0-4.1-.9-5.4-1.8-7.2.8-12.6 1.8-12.6 1.8v2.9h38.4v-6c0 .1-6.6 0-14.1.4z"></svg:path><svg:path d="M9.4 35.3v3.6H4.8v-9.5h4.6v4.3l2.7-4.3h5.5l-4 4.9 3.8 4.6h-5.2l-2.8-3.6z"></svg:path><svg:path d="M43.2 38.4c-1.1.3-2.5.5-3.8.5-4.5 0-7.8-1.3-7.8-5 0-3.4 3.2-4.8 7.6-4.8 1.2 0 2.8.1 4 .5v3.3c-.9-.6-1.8-.9-3.1-.9-1.7 0-3.3.8-3.3 2s1.7 2 3.3 2c1.3 0 2.2-.4 3.1-.9v3.3z"></svg:path><svg:path d="M18.3 29.4h9.2c2.4 0 3.2.9 3.2 2.4 0 1.6-1.4 2.3-3 2.4 1.5.1 3 .3 3 2.2 0 1.2-.8 2.5-3.4 2.5h-9.1v-9.5zm4.5 7.5h2.4c.9 0 1.2-.4 1.2-.9s-.3-.9-1.2-.9h-2.4v1.8zm0-3.5h2.3c.9 0 1.2-.4 1.2-.9 0-.6-.3-.9-1.2-.9h-2.3v1.8z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogosEmblemKbcSvgComponent {
}

@NgModule({
  declarations: [
    LogosEmblemKbcSvgComponent,
  ],
  exports: [
    LogosEmblemKbcSvgComponent,
  ],
})
export class LogosEmblemKbcSvgModule {}
