// Temporary fix due typescript missing IntersectionObserver on on global window
// More information https://github.com/Microsoft/TypeScript/issues/16255
declare global {
  interface Window {
    IntersectionObserver: typeof IntersectionObserver;
    IntersectionObserverEntry: typeof IntersectionObserverEntry;
  }
}

/**
 * Returns true if the intersectionObserver API is fully supported.
 * Older browser versions sometimes support IntersectionObserverEntry but are missing
 * intersectionRatio. More information about intersection API:
 * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 */
export function isIntersectionObserverSupported(window: Window): boolean {
  if ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window) {
    const nativeSupport = 'intersectionRatio' in window.IntersectionObserverEntry.prototype;
    const polyfillSupport = 'THROTTLE_TIMEOUT' in window.IntersectionObserver.prototype;
    return nativeSupport || polyfillSupport;
  } else {
    return false;
  }
}
