import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule NavigationFuncLiveHelpSvgModule
 */
@Component({
  selector: 'svg[kdlBifrostNavigationFuncLiveHelp]',
  template: '<svg:path d="M20.953 14.809c.38-2.585.394-5.884-1.307-8.518A10.537 10.537 0 0 0 1.615 17.2c.113.188.233.372.358.552 2.03 3.151 6.007 4.8 9.256 5.01 3.386.209 7.9-.492 12.679-5.207 0 0-3.322-.16-2.954-2.745m-7.424.627a.492.492 0 0 1-.358.148H8.248a.458.458 0 0 1-.342-.148.439.439 0 0 1-.15-.344.509.509 0 0 1 .492-.493h4.924a.5.5 0 0 1 .358.148.5.5 0 0 1 .137.345.479.479 0 0 1-.137.344m1.97-2.955a.494.494 0 0 1-.355.147H6.281a.473.473 0 0 1-.347-.147.448.448 0 0 1-.147-.345.5.5 0 0 1 .492-.493h8.863a.49.49 0 0 1 .355.148.507.507 0 0 1 .135.345.492.492 0 0 1-.135.345m0-2.954a.5.5 0 0 1-.355.149H6.279a.471.471 0 0 1-.347-.149.448.448 0 0 1-.147-.345.459.459 0 0 1 .147-.343.47.47 0 0 1 .347-.148h8.863a.49.49 0 0 1 .355.148.5.5 0 0 1 .135.343.48.48 0 0 1-.135.345" transform="matrix(.8 0 0 .8 2.4 2.4)"></svg:path>',

  styles: [
    ':host { fill: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationFuncLiveHelpSvgComponent {
}

@NgModule({
  declarations: [
    NavigationFuncLiveHelpSvgComponent,
  ],
  exports: [
    NavigationFuncLiveHelpSvgComponent,
  ],
})
export class NavigationFuncLiveHelpSvgModule {}
