import {ModuleWithProviders, NgModule} from '@angular/core';

import {Company} from './configuration/company';
import {Language} from './configuration/language';
import {LocaleLanguage} from './configuration/locale-language';

@NgModule()
export class CoreModule {
  /**
   * @deprecated Use the {@link #forCompany} function instead
   */
  public static forRoot(company: Company, language: Language): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {provide: Company, useValue: company},
        {provide: Language, useValue: language},
      ],
    };
  }

  public static forCompany(company: Company): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {provide: Company, useValue: company},
        {provide: Language, useClass: LocaleLanguage},
      ],
    };
  }
}
