import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SignerBuySvgModule
 */
@Component({
  selector: 'svg[ivyHermesSignerBuy]',
  template: '<svg:path d="M186.1 258.2H169v25.7h15.9c4.5 0 8-1.1 10.5-3.3 2.5-2.2 3.8-5.3 3.8-9.2 0-8.6-4.4-13-13.1-13.2z"></svg:path><svg:path d="M475.4 124.5L70 149.6c-22.5 1-44.5 21.5-47.6 44L.4 347.2c-3.1 23 13.3 41 36.3 38.4l405.9-35.8c23-2.1 44.5-22.6 48.1-45.6l21-140.8c2.6-22.5-13.3-40.4-36.3-38.9zM206 289.2c-5 4.2-12.1 6.2-21.3 6.2h-30.1V213h28.3c9.3 0 16.4 1.9 21.3 5.6 4.9 3.7 7.3 9.3 7.3 16.7 0 3.8-1 7.2-3.1 10.2-2 3-5 5.4-8.9 7 4.5 1.2 7.9 3.5 10.3 6.8 2.4 3.3 3.7 7.4 3.7 12 0 7.8-2.5 13.7-7.5 17.9zm82.1-21.1c0 8.8-2.8 15.7-8.4 20.8-5.6 5.1-13.1 7.7-22.4 7.7-9.4 0-16.9-2.5-22.5-7.6s-8.3-12-8.3-21v-55h14.3v55c0 5.5 1.4 9.7 4.2 12.6 2.8 2.9 6.9 4.4 12.3 4.4 11 0 16.5-5.8 16.5-17.4V213h14.3v55.1zm49.4-3v30.4h-14.4v-30.4L295.4 213h15.9l19 39.1 19-39.1h15.9l-27.7 52.1z"></svg:path><svg:path d="M193.5 244.7c2.5-2 3.8-4.9 3.8-8.6 0-4.1-1.2-7-3.5-8.8-2.3-1.8-5.9-2.7-10.8-2.7h-14v23.2h14c4.5-.1 8-1.1 10.5-3.1z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 512 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignerBuySvgComponent {
}

@NgModule({
  declarations: [
    SignerBuySvgComponent,
  ],
  exports: [
    SignerBuySvgComponent,
  ],
})
export class SignerBuySvgModule {}
