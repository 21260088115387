import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
} from '@angular/router';
import {LoggerFactory} from '@atlas-angular/logger';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Logger} from '@atlas/logger';
import {noop, Observable, of} from 'rxjs';
import {take, tap} from 'rxjs/operators';

import {AuthorisationService} from './authorisation.service';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class AuthorisationRouteCheck implements CanActivate, CanActivateChild {
  private readonly logger: Logger;
  private errorFunction: () => void = noop;

  public constructor(
    private readonly authorisationService: AuthorisationService,
    loggerFactory: LoggerFactory,
  ) {
    this.logger = loggerFactory.createLogger('AuthorisationRouteCheck');
  }

  /**
   * Uses the isUserAuthorised method from the RouteCheck Service to see whether the user has access
   * to the functioncode supplied in the 'functioncode' data field.
   * @returns an observable which returns true if the user has access.
   */
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    if (route.data[FUNCTIONCODE_DATA_FIELD] == null) {
      this.logger.error(
        'Error checking user access: ' +
          `Data field ${FUNCTIONCODE_DATA_FIELD} hasn't been set for path ${state.url}.`,
      );
      return of(false);
    }

    const isUserAuthorised = this.authorisationService.isUserAuthorised(
      route.data[FUNCTIONCODE_DATA_FIELD],
    );

    return isUserAuthorised.pipe(
      takeUntilDestroyed(this),
      take(1),
      tap(authorised => {
        if (!authorised) {
          this.logger.warn(
            `User does not have the function code ${route.data[FUNCTIONCODE_DATA_FIELD]}`,
          );
          this.errorFunction();
        }
      }),
    );
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.canActivate(route, state);
  }

  /**
   * Register the function to handle errors when user does not have the authorisation to load the
   * page.
   *
   * @param errorFunction the function to execute on errors
   */
  public setErrorFunction(errorFunction: () => void): void {
    this.errorFunction = errorFunction;
  }
}

const FUNCTIONCODE_DATA_FIELD = 'functionCode';
