import {Directive} from '@angular/core';
import {NationalRegisterNumberFormatter} from '@atlas/businesstypes';

import {ValueFormatter} from '../util/value-formatter.util';

import {MaskedInputComponent} from './masked-input.component';

/**
 * This directive configures the masked input for usage with national register numbers. It sets up
 * the correct mask and placeholder. Together with atlas's `[atlasNationalRegisterNumber]` directive
 * this makes the masked input create NationalRegisterNumber businesstypes.
 *
 * @ngModule InputMasksModule
 */
@Directive({
  selector: 'maia-masked-input[atlasNationalRegisterNumber]',
})
export class MaskedNationalRegisterNumberInputDirective implements ValueFormatter {
  public constructor(input: MaskedInputComponent) {
    input.setMaskAndPlaceholder('##.##.##-###.##');
    input.setValueFormatter(this);
  }

  /**
   * Doesn't format anything, formatting is done by the `[atlasNationalRegisterNumber]` directive.
   */
  public formatValue(value: string): string {
    return value;
  }

  /**
   * Unformats any formatting left in place by the `[atlasNationalRegisterNumber]` directive.
   */
  public unformatValue(value: string): string {
    return NationalRegisterNumberFormatter.unformat(value);
  }
}
