import {MonoTypeOperatorFunction} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ensureClassIsDecorated, getDestroySubject} from '../decorators/internals';

/**
 * Pipeable operator that ends an observable once the given destroyable is destroyed.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function takeUntilDestroyed<T>(instance: object): MonoTypeOperatorFunction<T> {
  ensureClassIsDecorated(instance, 'operator takeUntilDestroyed');

  return takeUntil<T>(getDestroySubject(instance));
}
