/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
  Provider,
  TemplateRef,
} from '@angular/core';
import {Utilities} from '@maia/core';
import {Observable, of, Subject, Subscriber} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {BackdropComponent} from '../backdrop/backdrop.component';
import {ModalViewContainer} from '../container/modal-view-container.service';
import {BackdropOptions} from '../interfaces/backdrop-options';
import {
  FactoryModalContentDescription,
  MODAL_CONTENT_DESCRIPTION,
  ModalTemplateContext,
  TemplateModalContentDescription,
} from '../interfaces/modal-content-description';
import {ModalContentComponent} from '../interfaces/modal-content.component';
import {ModalControl} from '../interfaces/modal-control';
import {LifecycleCallback, OnModalClose, OnModalOpen} from '../interfaces/modal-lifecycle';
import {ModalOptions, ModalOptionsWithInput} from '../interfaces/modal-options';
import {ModalReady} from '../interfaces/modal-ready';
import {ModalResolution, ModalResult} from '../interfaces/modal-result';

import {ModalController} from './modal-controller.interface';

const MODAL_ELEMENT_CLASS = 'p-maia-modal';

function createModalReady(): ModalReady {
  let component: ModalContentComponent<unknown> | undefined;
  let ready = false;

  return {
    markReady(c): void {
      ready = true;
      component = c;
    },

    get ready(): boolean {
      return ready;
    },

    get component() {
      return component;
    },
  };
}

type Unpick<T, K extends keyof T> = {
  [P in Exclude<keyof T, K>]: T[P];
};

type FactoryModalContentDescriptionInput<O, I> = Unpick<
  FactoryModalContentDescription<O, I>,
  'injector'
>;

interface TemplateModalContentDescriptionInput<
  O,
  I = undefined,
  C extends ModalTemplateContext<O, I> = ModalTemplateContext<O, I>
> {
  readonly template: TemplateRef<C>;

  readonly templateContext: Unpick<C, 'control' | '$implicit'>;
}

type ModalContentDescriptionInput<
  O,
  I = undefined,
  C extends ModalTemplateContext<O, I> = ModalTemplateContext<O, I>
> = FactoryModalContentDescriptionInput<O, I> | TemplateModalContentDescriptionInput<O, I, C>;

/**
 * The ModalController is used to instantiate and show modals.
 */
@Injectable()
export class μModalController implements ModalController {
  /**
   * All currently open backdrops.
   */
  private readonly _backdrops: ComponentRef<BackdropComponent>[] = [];

  /**
   * All currently open modals.
   */
  private readonly _modals: ComponentRef<ModalContentComponent<any>>[] = [];

  /**
   * All currently open "always on top" modals
   *
   * These modals cannot have backdrops
   */
  private readonly _modalsOnTop: ComponentRef<ModalContentComponent<any>>[] = [];

  /**
   * Component factory that creates a new backdrop.
   */
  private _backdropFactory?: ComponentFactory<BackdropComponent> = undefined;

  public constructor(
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _modalViewContainer: ModalViewContainer,
  ) {}

  private get backdropFactory(): ComponentFactory<BackdropComponent> {
    if (this._backdropFactory != null) {
      return this._backdropFactory;
    }

    this._backdropFactory = this._componentFactoryResolver.resolveComponentFactory(
      BackdropComponent,
    );
    return this._backdropFactory;
  }

  private _insertView<T extends ComponentRef<unknown>>(array: T[], component: T): void {
    this._modalViewContainer.viewContainer.insert(
      component.hostView,
      this._modalViewContainer.viewContainer.length - this._modalsOnTop.length,
    );
    array.push(component);
  }

  /**
   * Creates a new backdrop instance and inserts it into the component tree.
   *
   * @param parentInjector The injector to use when creating the backdrop. This injector must have a
   * `ModalControl` available.
   * @param visible Whether or not the backdrop should be visible. If this is false, the backdrop
   * will never be visible, if it is true, it might be visible.
   * @param clickable Whether or not clicking the backdrop should dismiss the modal.
   * @param blockScrolling whether user can scroll the page
   * @return The backdrop
   */
  private _createBackdrop(
    parentInjector: Injector,
    visible: boolean,
    clickable: boolean,
    blockScrolling: boolean,
  ): ComponentRef<BackdropComponent> {
    const transparent = !visible;
    const injector = Injector.create({
      providers: [{provide: BackdropOptions, useValue: {transparent, clickable, blockScrolling}}],
      parent: parentInjector,
    });

    const backdrop = this.backdropFactory.create(injector);
    this._insertView(this._backdrops, backdrop);

    backdrop.onDestroy(() => Utilities.arrayRemove(this._backdrops, backdrop));

    return backdrop;
  }

  /**
   * Creates and presents a new modal to the user.
   *
   * @param modalType The type of the modal, to be used in modal lifecycle callbacks
   * @param parentInjector The parent injector for creating the modal and backdrop.
   * @param componentFactory The component factory for creating the modal component.
   * @param contentFactory The component factory for creating the modal's content.
   * @param options The options for this modal.
   * @return An observable that emits the result
   */
  public prepare<T>(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentFactory: ComponentFactory<ModalContentComponent<T>>,
    options?: ModalOptions,
  ): Observable<ModalResult<T>>;
  public prepare<T, I>(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentFactory: ComponentFactory<ModalContentComponent<T, I>>,
    options: ModalOptionsWithInput<I>,
  ): Observable<ModalResult<T>>;
  public prepare<T, I = undefined>(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentFactory: ComponentFactory<ModalContentComponent<T, I>>,
    options?: ModalOptions<I>,
  ): Observable<ModalResult<T>> {
    return this._doPrepare(
      this._modals,
      modalType,
      parentInjector,
      componentFactory,
      {factory: contentFactory},
      options,
    );
  }

  /**
   * Creates and presents a new modal to the user.
   *
   * Note that the given providers will be available in the modal component itself but not in the
   * content component or its contents. You shouldn't need to access these, however, because using a
   * `TemplateRef` gives you access to everything on the containing component.
   *
   * @param modalType The type of the modal, to be used in modal lifecycle callbacks
   * @param parentInjector The parent injector for creating the modal and backdrop.
   * @param componentFactory The component factory for creating the modal component.
   * @param contentTemplate The template for creating the modal's content.
   * @param options The options for this modal.
   * @param contentContext Context to pass on to the content when instantiating the template. A
   * `control` property with the `ModalControl` will be added to the context.
   * @return An observable that emits the result
   */
  public prepareTemplate<T>(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentTemplate: TemplateRef<ModalTemplateContext<T>>,
    options?: ModalOptions,
    contentContext?: {},
  ): Observable<ModalResult<T>>;
  public prepareTemplate<T, C extends ModalTemplateContext<T>>(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentTemplate: TemplateRef<C>,
    options: ModalOptions | undefined,
    contentContext: Unpick<C, 'control' | '$implicit'>,
  ): Observable<ModalResult<T>>;
  public prepareTemplate<T, I>(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentTemplate: TemplateRef<ModalTemplateContext<T, I>>,
    options: ModalOptionsWithInput<I>,
    contentContext?: {},
  ): Observable<ModalResult<T>>;
  public prepareTemplate<T, I, C extends ModalTemplateContext<T, I>>(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentTemplate: TemplateRef<C>,
    options: ModalOptionsWithInput<I>,
    contentContext: Unpick<C, 'control' | '$implicit'>,
  ): Observable<ModalResult<T>>;
  public prepareTemplate<
    T,
    I = undefined,
    C extends ModalTemplateContext<T, I> = ModalTemplateContext<T, I>
  >(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentTemplate: TemplateRef<C>,
    options?: ModalOptions<I>,
    contentContext?: Unpick<C, 'control' | '$implicit'>,
  ): Observable<ModalResult<T>> {
    return this._doPrepare(
      this._modals,
      modalType,
      parentInjector,
      componentFactory,
      {
        template: contentTemplate,
        // We know due to our method signature that if C is not ModalTemplateContext<T, I>,
        // the contentContext parameter has been filled in. Typescript doesn't know this, so
        // cast explicitly
        templateContext: (contentContext || {}) as Unpick<C, 'control' | '$implicit'>,
      },
      options,
    );
  }

  /**
   * Creates and presents a new modal on top of all other modals
   *
   * @param modalType The type of the modal, to be used in modal lifecycle callbacks
   * @param parentInjector The parent injector for creating the modal and backdrop.
   * @param componentFactory The component factory for creating the modal component.
   * @param contentFactory The component factory for creating the modal's content.
   * @param options The options for this modal.
   * @return An observable that emits the result
   */
  public prepareOnTop<T>(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentFactory: ComponentFactory<ModalContentComponent<T>>,
    options?: Omit<ModalOptions, 'withBackdrop' | 'withVisibleBackdrop' | 'withClickableBackdrop'>,
  ): Observable<ModalResult<T>>;
  public prepareOnTop<T, I>(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentFactory: ComponentFactory<ModalContentComponent<T, I>>,
    options: Omit<
      ModalOptionsWithInput<I>,
      'withBackdrop' | 'withVisibleBackdrop' | 'withClickableBackdrop'
    >,
  ): Observable<ModalResult<T>>;
  public prepareOnTop<T, I = undefined>(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentFactory: ComponentFactory<ModalContentComponent<T, I>>,
    options?: Omit<
      ModalOptions<I>,
      'withBackdrop' | 'withVisibleBackdrop' | 'withClickableBackdrop'
    >,
  ): Observable<ModalResult<T>> {
    return this._doPrepare(
      this._modalsOnTop,
      modalType,
      parentInjector,
      componentFactory,
      {factory: contentFactory},
      {
        ...options,
        withBackdrop: false,
      },
    );
  }

  private _doPrepare<
    O,
    I = undefined,
    C extends ModalTemplateContext<O, I> = ModalTemplateContext<O, I>
  >(
    modals: ComponentRef<ModalContentComponent<any>>[],
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentDescription: ModalContentDescriptionInput<O, I, C>,
    {
      input,
      withBackdrop = true,
      withVisibleBackdrop = false,
      withClickableBackdrop = true,
      blockScrolling = true,
      providers = [],
    }: ModalOptions<I> = {},
  ): Observable<ModalResult<O>> {
    return new Observable<ModalResult<O>>(observer => {
      const descriptionProvider: Provider =
        'factory' in contentDescription
          ? {
              provide: MODAL_CONTENT_DESCRIPTION,
              useFactory: (): FactoryModalContentDescription<O, I> => ({
                ...contentDescription,
                injector,
              }),
              deps: [],
            }
          : {
              provide: MODAL_CONTENT_DESCRIPTION,
              useFactory: (
                control: ModalControl<O, I>,
              ): TemplateModalContentDescription<O, I, C> => {
                return {
                  ...contentDescription,
                  templateContext: {
                    ...contentDescription.templateContext,
                    control,
                    $implicit: control,
                  } as C,
                };
              },
              deps: [ModalControl],
            };

      const injector = Injector.create({
        providers: [
          ...providers,
          descriptionProvider,
          {provide: ModalReady, useFactory: createModalReady, deps: []},
          {
            provide: ModalControl,
            useFactory: (modalReady: ModalReady) => {
              // We need the `!`
              // Our function's input technically allows non-null values but we know it as the
              // _doPrepare call locations ensure that either `(I === undefined)` is equivalent with
              // `(input === undefined)`.
              // There's no way to tell typescript this though, hence the `!`.

              return this._createModalControl<O, I>(input!, observer, modalReady);
            },
            deps: [ModalReady],
          },
        ],
        parent: parentInjector,
      });

      const backdrop = withBackdrop
        ? this._createBackdrop(injector, withVisibleBackdrop, withClickableBackdrop, blockScrolling)
        : null;

      const modal = componentFactory.create(injector);
      (modal.location.nativeElement as Element).classList.add(MODAL_ELEMENT_CLASS);

      this._insertModal(modals, modalType, modal);
      modal.onDestroy(() => {
        if (backdrop != null) {
          backdrop.destroy();
        }

        this._removeModal(modals, modalType, modal);
      });

      return () => {
        modal.destroy();
      };
    });
  }

  /**
   * Creates a shield modal and presents it to the user.
   *
   * @param options The options for this shield modal.
   * @return A promise resolved when the shield modal is closed.
   */
  public prepareShield({
    withVisibleBackdrop = false,
    withClickableBackdrop = true,
    blockScrolling = true,
  }: ModalOptions<void> = {}): Observable<ModalResult<never>> {
    return new Observable<ModalResult<never>>(observer => {
      const modalReady = createModalReady();
      const modalControl = this._createModalControl<never>(undefined, observer, modalReady);
      const injector = Injector.create({
        providers: [
          {provide: ModalControl, useValue: modalControl},
          {provide: ModalReady, useValue: modalReady},
        ],
      });

      const backdrop = this._createBackdrop(
        injector,
        withVisibleBackdrop,
        withClickableBackdrop,
        blockScrolling,
      );
      modalReady.markReady();

      return () => {
        backdrop.destroy();
        modalControl.dismiss();
      };
    });
  }

  /**
   * Shows the modal, adds it to the modal stack and triggers the 'maiaOnModalOpen' callback on all
   * previously open modals.
   */
  private _insertModal(
    modals: ComponentRef<ModalContentComponent<any>>[],
    modalType: string,
    modal: ComponentRef<ModalContentComponent<any>>,
  ): void {
    this._fireLifecycleCallback(modals, 'maiaOnModalOpen', modalType);

    this._insertView(modals, modal);
  }

  /**
   * Removes the modal from the modal stack and triggers the 'maiaOnModalClose' callback on all
   * modals that were opened before the one that's currently being closed.
   */
  private _removeModal(
    modals: ComponentRef<ModalContentComponent<any>>[],
    modalType: string,
    modal: ComponentRef<ModalContentComponent<any>>,
  ): void {
    const idx = modals.indexOf(modal);

    modals.splice(idx, 1);

    this._fireLifecycleCallback(modals.slice(0, idx), 'maiaOnModalClose', modalType);
  }

  private _fireLifecycleCallback(
    modals: ComponentRef<ModalContentComponent<any>>[],
    callback: LifecycleCallback,
    modalType: string,
  ): void {
    for (const modal of modals) {
      const instance = modal.instance as ModalContentComponent<unknown> &
        Partial<OnModalClose & OnModalOpen>;
      instance[callback]?.({type: modalType});
    }
  }

  private _createModalControl<T, I = undefined>(
    input: I,
    observer: Subscriber<ModalResult<T>>,
    readyContainer: ModalReady,
  ): ModalControl<T, I> {
    let shown = true;

    const cancelled = new Subject<void>();

    observer.add(
      cancelled
        .pipe(
          map(() => {
            if (!readyContainer.ready) {
              throw new Error("Don't call ModalControl#cancel in the component constructor");

              // Do not actually hide the component. The developer has made a mistake. We've shown an
              // error (the reject call above) and we'll leave the UI in a completely wrong state.
              // Hopefully this means developers will see something's wrong.
              // Hopefully.
            }

            return readyContainer.component;
          }),
          switchMap(component => {
            const canCancel = component?.canCancel?.();

            if (canCancel == null || typeof canCancel === 'boolean') {
              return of(true);
            }

            return canCancel;
          }),
        )
        .subscribe(
          doCancel => {
            if (doCancel) {
              hide('cancel', {resolution: ModalResolution.CANCELLED});
            }
          },
          err => observer.error(err),
        ),
    );

    function hide(method: string, result: ModalResult<T>): void {
      if (!shown) {
        return;
      }
      shown = false;
      if (!readyContainer.ready) {
        observer.error(new Error(`Don't call ModalControl#${method} in the component constructor`));

        // Do not actually hide the component. The developer has made a mistake. We've shown an
        // error (the reject call above) and we'll leave the UI in a completely wrong state.
        // Hopefully this means developers will see something's wrong.
        // Hopefully.

        return;
      }

      observer.next(result);
      observer.complete();
    }

    return {
      get input() {
        return input;
      },

      dismiss(): void {
        hide('dismiss', {resolution: ModalResolution.DISMISSED});
      },

      cancel(): void {
        cancelled.next();
      },

      confirm(result: T): void {
        hide('confirm', {resolution: ModalResolution.CONFIRMED, result});
      },
    };
  }
}
