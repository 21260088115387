import {coerceNumberProperty} from '@angular/cdk/coercion';
import {Date, DateKey, DateUtils} from '@atlas/businesstypes';

/*
 * By default a yearpicker will show some years centered around the current year.
 * The default bounds are from 80 years in the past up to 30 years in the future, inclusive.
 */

export const DEFAULT_LOWER_BOUND = 80;
export const DEFAULT_UPPER_BOUND = 30;

export const YEAR_ITEM_HEIGHT = 40; /* px */

/**
 * When scrolling to the selected year, show the selected year at this index.
 */
export const SELECTED_YEAR_VISIBLE_IDX = 3;

/**
 * The number of visible years. The year scroll area is 240px, and each year is shown 40px high, so
 * we show 6 years.
 */
export const NB_VISIBLE_YEARS = 6;

/**
 * Describes how the default years to show in a yearpicker, centered around the current year.
 *
 * The years shown are from `(currentYear - lowerBound)` to `(currentYear + upperBound)`, where both
 * bounds are inclusive.
 */
export abstract class DefaultYearRange {
  /**
   * The default number of years to show before today.
   */
  public lowerBound: number;

  /**
   * The default number of years to show after today.
   */
  public upperBound: number;
}

export function createDefaultYearRange(
  defaultYearRange: DefaultYearRange | null | undefined,
): DefaultYearRange {
  return Object.assign(
    {lowerBound: DEFAULT_LOWER_BOUND, upperBound: DEFAULT_UPPER_BOUND},
    defaultYearRange,
  );
}

type YearPropertyInput = Date | string | number | null | undefined;

/**
 * Coerces directive input to a year.
 *
 * @param value The input to coerce into a year
 * @param defaultValue The default value if no year is passed
 */
export function coerceYearProperty(value: YearPropertyInput): number | undefined;
export function coerceYearProperty(value: YearPropertyInput, defaultValue: number): number;
export function coerceYearProperty(
  value: YearPropertyInput,
  defaultValue?: number,
): number | undefined {
  let year: number | undefined;

  if (Date.isDate(value)) {
    year = DateUtils.get(value, DateKey.Year);
  } else if (value == null) {
    year = defaultValue;
  } else {
    year = coerceNumberProperty(value);
  }

  return year;
}
