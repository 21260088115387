import {ChangeDetectionStrategy, Component, forwardRef, Input} from '@angular/core';

import {AvatarComponent} from '../abstract-avatar.component';

const AVATAR_STRING_DECIMAL_PERCENTAGE = 0.4;

@Component({
  selector: 'maia-avatar-string',
  templateUrl: './avatar-string.component.html',
  styleUrls: ['./avatar-string.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: AvatarComponent,
      useExisting: forwardRef(() => AvatarStringComponent),
    },
  ],
})
export class AvatarStringComponent extends AvatarComponent {
  @Input()
  public value: string;

  public iconSize(): number {
    return this.avatarSize * AVATAR_STRING_DECIMAL_PERCENTAGE;
  }
}
