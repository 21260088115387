import {NgModule} from '@angular/core';
import {μDocumentLinkSvgModule} from '@maia/icons';
import {LayoutModule} from '@maia/layout';
import {TilesModule} from '@maia/tiles';

import {ActionWithIconComponent} from './action-with-icon/action-with-icon.component';
import {DocumentActionComponent} from './action-with-icon/document-action/document-action.component';
import {DocumentGroupComponent} from './action-with-icon/document-group/document-group.component';
import {DocumentTileComponent} from './action-with-icon/document-tile/document-tile.component';
import {InlineActionButtonComponent} from './inline-action/inline-action-button.component';
import {InlineActionLinkComponent} from './inline-action/inline-action-link.component';

@NgModule({
  declarations: [
    ActionWithIconComponent,
    DocumentActionComponent,
    DocumentGroupComponent,
    DocumentTileComponent,
    InlineActionButtonComponent,
    InlineActionLinkComponent,
  ],
  imports: [μDocumentLinkSvgModule, LayoutModule, TilesModule],
  exports: [
    ActionWithIconComponent,
    DocumentActionComponent,
    DocumentGroupComponent,
    DocumentTileComponent,
    InlineActionButtonComponent,
    InlineActionLinkComponent,
  ],
})
export class ActionsModule {}
