import {Injectable, ViewContainerRef} from '@angular/core';

/**
 * The `ModalViewContainer` contains the `ViewContainerRef` that all modals are rendered in.
 */
@Injectable({providedIn: 'root'})
export class ModalViewContainer {
  private _viewContainer?: ViewContainerRef;

  public set viewContainer(viewContainer: ViewContainerRef) {
    if (this._viewContainer != null) {
      throw new Error(
        "There's already a ViewContainer for the ModalController, did you create two [maiaModalContainer] elements?",
      );
    }

    this._viewContainer = viewContainer;
  }

  /**
   * The `ViewContainerRef` to render all modals in. This variable can be set only once, and it
   * cannot be accessed until it has been set.
   */
  public get viewContainer(): ViewContainerRef {
    if (this._viewContainer == null) {
      throw new Error(
        "There's no ViewContainerRef for the ModalController. Do you have a [maiaModalContainer] element?",
      );
    }

    return this._viewContainer;
  }
}
