import {Date, DateFormatter, DateUtils} from '@atlas/businesstypes';
import {DateFormat, DEFAULT_DATE_FORMAT} from '@maia/input-masks';

import {formatDateToCustomFormat} from './util';

function formatDate(date: Date, dateFormat?: DateFormat): string {
  let formattedDate = DateFormatter.unformat(DateFormatter.format(date));

  if (dateFormat != null && dateFormat !== DEFAULT_DATE_FORMAT) {
    formattedDate = formatDateToCustomFormat(date, dateFormat);
  }

  return formattedDate;
}

/**
 * Date range class - Represents a range of dates between two selected dates
 */
export class DateRange {
  public start: Date | null;
  public end: Date | null;

  /**
   * Returns whether `value` is a DateRange or not.
   */
  public static isDateRange(value: any): value is DateRange {
    return value instanceof DateRange;
  }

  /**
   * Creates a new DateRange instance.
   *
   * @param start The start date of the range
   * @param end The end date of the range
   */
  public constructor(start?: Date | null, end?: Date | null) {
    this.start = start || null;
    this.end = end || null;
  }

  /**
   * Returns whether the date range range contains a start and end Date
   */
  public isValid(): this is DateRange & {start: Date; end: Date} {
    return Date.isDate(this.start) && Date.isDate(this.end);
  }

  /**
   * Returns whether the given value is a `DateRange` representing the same range of dates.
   */
  public equals(other: DateRange | null): boolean {
    if (!other) {
      return false;
    }
    if (other === this) {
      return true;
    }

    // check if this.start is equal to the other.start
    if (this.start != null && !this.start.equals(other.start)) {
      return false;
    }

    // check if this.end is equal to the other.end
    if (this.end != null && !this.end.equals(other.end)) {
      return false;
    }

    return true;
  }

  /**
   * Checks whether the given date is included in the date range
   *
   * @param date The date to be checked
   */
  public isInDateRange(date: Date): boolean {
    if (this.start == null || this.end == null) {
      return false;
    }

    return (
      (DateUtils.isAfter(date, this.start) && DateUtils.isBefore(date, this.end)) ||
      (DateUtils.isBefore(date, this.start) && DateUtils.isAfter(date, this.end)) ||
      date.equals(this.start) ||
      date.equals(this.end)
    );
  }

  /**
   * Checks whether the date range start and end date are null values.
   */
  public isEmpty(): boolean {
    return this.start === null && this.end === null;
  }

  /**
   * @param format the DateFormat to use when formatting the string
   */
  public toString(format?: DateFormat): string {
    if (this.isEmpty()) {
      return '';
    }

    return `${Date.isDate(this.start) ? formatDate(this.start, format) : ''}${
      Date.isDate(this.end) ? formatDate(this.end, format) : ''
    }`;
  }

  /**
   * Returns a date range where start lies before end
   */
  public normalize(): this {
    if (!this.isValid()) {
      return this;
    }

    if (DateUtils.isAfter(this.start, this.end)) {
      return new DateRange(this.end, this.start) as this;
    } else {
      return this;
    }
  }
}
