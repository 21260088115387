import {ChangeDetectionStrategy, Component, HostListener, Optional} from '@angular/core';

import {InputContainer} from '../input-container/input-container.interface';
import {SingleInputContainer} from '../input-container/single-input-container.service';

import {FormElementComponent, FormElementMultiDirective} from './form-element.component';
import {ValidationMessageExtractor} from './validation-message-extractor.service';

/**
 * A form subelement. Subelements are contained within an actual form element. They contain an input
 * and the validation linked to that input.
 *
 * @ngModule FormsModule
 */
@Component({
  selector: 'maia-form-subelement',
  templateUrl: './form-subelement.component.html',
  styleUrls: ['./form-subelement.component.scss'],

  providers: [
    ValidationMessageExtractor,
    {provide: InputContainer, useClass: SingleInputContainer},
  ],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSubelementComponent {
  public constructor(
    @Optional() parent?: FormElementComponent,
    @Optional() multiParent?: FormElementMultiDirective,
  ) {
    if (parent == null) {
      throw new Error(
        `maia-form-multielement cannot be used standalone, only inside a maia-form-element with [multi]`,
      );
    }

    if (multiParent == null) {
      throw new Error(`maia-form-element requires the parent maia-form-element to have [multi]`);
    }
  }

  // Fixes bug in Safari which caused the click event to bubble through to the label which in its
  // turn always focused on the first subelement.
  @HostListener('click', ['$event'])
  public receiveFocus(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }
}
