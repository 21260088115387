const PrefixBE = '+32';

function stripLeadingPlusSignStrategy(number: string) {
  return number.startsWith('+') ? number.substring(1) : number;
}

function stripLeadingZeroesStrategy(number: string) {
  return number.startsWith('0') ? number.substring(1) : number;
}

function trimStrategy(number: string) {
  return number.trim();
}

const sanitizeStrategies = [
  {prefix: undefined, strategy: trimStrategy},
  {prefix: undefined, strategy: stripLeadingPlusSignStrategy},
  {prefix: PrefixBE, strategy: stripLeadingZeroesStrategy},
];

/**
 * Sanitize the phone number value
 */
export function sanitizeNumber(number: string, prefix: string): string {
  return sanitizeStrategies
    .filter(({prefix: strategyPrefix}) => strategyPrefix == null || strategyPrefix === prefix)
    .reduce((sanitizedNumber, {strategy}) => strategy(sanitizedNumber), number);
}
