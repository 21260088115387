import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationFuncTodoSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaInformationFuncTodo]',
  template: '<svg:g class="nc-icon-wrapper" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"><svg:path fill="none" stroke-miterlimit="10" d="M16 4h4v16.8H4V4h4" stroke-width="1.04"></svg:path><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M9.6 14.4l1.6 1.6 3.2-3.2" stroke-width="1.04"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M8 3.2V8h2.4a1.6 1.6 0 1 1 3.2 0H16V3.2H8z" stroke-width="1.04"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationFuncTodoSvgComponent {
}

@NgModule({
  declarations: [
    InformationFuncTodoSvgComponent,
  ],
  exports: [
    InformationFuncTodoSvgComponent,
  ],
})
export class InformationFuncTodoSvgModule {}
