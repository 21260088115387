import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class ListItemService {
  private readonly _isDisabled$ = new BehaviorSubject<boolean>(false);

  public isDisabled(): Observable<boolean> {
    return this._isDisabled$.asObservable();
  }

  public updateIsDisabled(isDisabled: boolean) {
    this._isDisabled$.next(isDisabled);
  }
}
