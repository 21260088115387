import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Directive, forwardRef, Input} from '@angular/core';
import {Boolean, DayOfWeek} from '@atlas/businesstypes';
import {DISABLED_DAY_DECIDER, DisabledDayDecider} from '../parts/daypicker/daypicker.util';

/**
 * This directive allows disabling weekend days in daypickers, datepickers and date inputs.
 *
 * @ngModule DatePickersModule
 */
@Directive({
  selector:
    'maia-datepicker[disableWeekends], maia-daterangepicker[disableWeekends], maia-daypicker[disableWeekends], maia-input-date[disableWeekends], maia-input-date-range[disableWeekends], maia-rangepicker[disableWeekends],',
  providers: [
    {
      provide: DISABLED_DAY_DECIDER,
      useExisting: forwardRef(() => DisableWeekendDirective),
      multi: true,
    },
  ],
})
export class DisableWeekendDirective implements DisabledDayDecider {
  private _updateFn?: () => void = undefined;

  private _weekendsDisabled = false;

  /**
   * Whether or not weekends should be disabled.
   */
  @Input()
  public set disableWeekends(value: Boolean | boolean | string) {
    value = coerceBooleanProperty(Boolean.isBoolean(value) ? value.asBoolean() : value);

    if (value === this._weekendsDisabled) {
      return;
    }

    this._weekendsDisabled = value;
    if (this._updateFn != null) {
      this._updateFn();
    }
  }

  public isDisabled(_day: number, dayOfWeek: DayOfWeek): boolean | null {
    if (!this._weekendsDisabled) {
      return null;
    }

    return dayOfWeek === DayOfWeek.Saturday || dayOfWeek === DayOfWeek.Sunday ? true : null;
  }

  public registerOnUpdate(updateFn?: (() => void) | undefined): void {
    this._updateFn = updateFn;
  }
}
