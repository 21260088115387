import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

/**
 * Name Value Collections Component
 * Component to show a label and its associated value
 *
 * @ngModule NameValueCollectionsModule
 */
@Component({
  selector: 'maia-name-value-collection',
  templateUrl: './name-value-collection.component.html',
  styleUrls: ['./name-value-collection.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NameValueCollectionComponent implements OnInit {
  @Input()
  public key: string;

  public ngOnInit() {
    if (this.key === undefined) {
      throw new Error('title key is required');
    }
  }
}
