import {QueryList} from '@angular/core';
import {
  asapScheduler,
  from,
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
} from 'rxjs';
import {
  audit,
  distinctUntilChanged,
  map,
  mapTo,
  mergeMap,
  observeOn,
  startWith,
  switchMap,
} from 'rxjs/operators';

import {OptionWithHighlights} from './option/option-with-highlights';

/**
 * Returns the first option with at least one match, or undefined if no such option exists
 */
function findMatch<T>(options: OptionWithHighlights<T>[]): OptionWithHighlights<T> | undefined {
  return options.find(option => option.highlights.some(highlight => highlight.hasMatch));
}

/**
 * Maps a query list to an array of its values
 */
function mapQueryListToArray<T>(): OperatorFunction<QueryList<T>, T[]> {
  return map(queryList => queryList.toArray());
}

/**
 * Watches for changes in the passed through query lists
 *
 * Note that the resulting observable doesn't emit immediately, it will only emit on changes.
 */
function watchQueryListChanges<T>(): MonoTypeOperatorFunction<QueryList<T>> {
  return mergeMap(queryList =>
    queryList.changes.pipe(
      mapTo(queryList),
      // Observe on an async scheduler because the `changes` observable might emit during
      // a phase in change detection where further changes are not allowed, depending on whether
      // it's a property with @ViewChildren or @ContentChildren.
      observeOn(asapScheduler),
    ),
  );
}

function watchMatchChanges(): MonoTypeOperatorFunction<OptionWithHighlights<any>[]> {
  return switchMap(options =>
    from(options).pipe(
      // For every option
      mergeMap(option =>
        of(option.highlights).pipe(
          // Watch for changes in the highlights, because an added/removed
          // highlight might change whether the option matches
          watchQueryListChanges(),
          startWith(option.highlights),

          mapQueryListToArray(),

          // Listen for changes in the match of all highlights in the option
          switchMap(highlights =>
            from(highlights).pipe(mergeMap(highlight => highlight.matchChange)),
          ),
        ),
      ),

      // Immediately emit a match change, because if this observable is subscribed to it means
      // the options changed (e.g. option added/removed using *ngIf).
      startWith(null),

      // Throttle all emitted values to emit only once per tick
      // The audit operator is like throttle but throttle emits at the leading edge of the frame
      // and audit at the trailing edge. This means audit makes the emitted values asynchronous
      // in our case.
      audit(() => of(null).pipe(observeOn(asapScheduler))),

      // Emit the options
      mapTo(options),
    ),
  );
}

/**
 * Looks for options that match and emits the first option whenever it changes
 *
 * @param optionsQuery All options to consider
 */
export function typeAhead<T>(
  optionsQuery: QueryList<OptionWithHighlights<T>>,
): Observable<OptionWithHighlights<T> | undefined> {
  return of(optionsQuery).pipe(
    // Watch for changes in the options
    watchQueryListChanges(),
    // Start with a value (the watch doesn't emit immediatly, noly on change)
    startWith(optionsQuery),
    mapQueryListToArray(),

    // Watch the options for changes in the matches
    watchMatchChanges(),

    // Find a match
    map(findMatch),

    // Only emit when the value changes, otherwise we'd be emitting a lot of duplicates
    distinctUntilChanged(),
  );
}
