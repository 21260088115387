import {NgModule} from '@angular/core';
import {TilesModule} from '@maia/tiles';

import {ConditionGroupComponent} from './condition-group/condition-group.component';
import {ConditionComponent} from './condition/condition.component';

@NgModule({
  imports: [TilesModule],
  declarations: [ConditionComponent, ConditionGroupComponent],
  exports: [ConditionComponent, ConditionGroupComponent],
})
export class TermsAndConditionsModule {}
