import {getDate, getMonth, getYear} from 'date-fns';

import {BusinessType, createStringifier, getInternalValue} from '../base/businesstype';

import {formattedToParts, partsToFormatted} from './format';
import {create, GlobalDate} from './global-date';
import {isValidDate} from './validator-base';

const stringifier = createStringifier('date');

/**
 * BusinessType wrapper around dates
 *
 * The value of a Date businesstype shouldn't be accessed directly but using the DateUtils object.
 */
export class Date extends BusinessType<string> {
  /**
   * Returns whether `value` is a Date or not.
   */
  public static isDate(value: unknown): value is Date {
    return value instanceof Date;
  }

  /**
   * Creates a new Date instance.
   *
   * @param value The date as string matching the `FORMAT`
   * @param encryptedValue The encrypted value, if any
   * @see FORMAT
   */
  public constructor(value: string, encryptedValue?: string) {
    if (!isValidDate(value)) {
      throw new Error(`Cannot create Date for invalid input ${JSON.stringify(value)}`);
    }

    super(value, encryptedValue);
  }

  /**
   * Returns whether `other` is a `Date` representing the same date.
   */
  public equals(other: unknown): boolean {
    if (!other) {
      return false;
    }

    if (other === this) {
      return true;
    }

    return Date.isDate(other) && this.internalValue === other.internalValue;
  }

  public toString(): string {
    return stringifier(this);
  }
}

export function toGlobalDate(value: Date): GlobalDate {
  const {year, month, day} = formattedToParts(getInternalValue(value));

  return create(year, month - 1, day);
}

export function fromGlobalDate(value: GlobalDate): Date {
  return new Date(partsToFormatted(getYear(value), getMonth(value) + 1, getDate(value)));
}
