import {Inject, Injectable, InjectionToken, NgModule, Provider} from '@angular/core';

import {CordovaSqliteStorage} from './cordova-sqlite/cordova-sqlite-storage';
import {DomStorage} from './dom/dom-storage';
import {NoSupportedStorageAdapterError} from './keepsake.errors';
import {CordovaSqlCipherStorageFactory, DomStorageFactory, MemoryStorageFactory, StorageFactory,} from './keepsake.factories';
import {Keepsake} from './keepsake.interface';

// eslint-disable-next-line sonarjs/no-duplicate-string
export type StorageType = 'dom'|'memory'|'cordova-sqlite';
export const typesToken = new InjectionToken<StorageType[]>('storage types');
const availableStorageTypes: Map < string, {
  factory: () => StorageFactory;
  isSupported: () => Promise<boolean>;
}
> = new Map([
  [
    'dom',
    {
      factory: () => new DomStorageFactory(),
      isSupported: (): Promise<boolean> => DomStorage.isSupported(),
    },
  ],
  [
    'cordova-sqlite',
    {
      factory: () => new CordovaSqlCipherStorageFactory(),
      isSupported: (): Promise<boolean> => CordovaSqliteStorage.isSupported(),
    },
  ],
  [
    'memory',
    {
      factory: () => new MemoryStorageFactory(),
      isSupported: (): Promise<boolean> => Promise.resolve(true),
    },
  ],
]);

function isntNull<T>(value: T): value is NonNullable<T> {
  return value != null;
}

@Injectable()
export class ProvidedFactory implements StorageFactory {
  private _storageFactory?: Promise<StorageFactory> = undefined;

  public constructor(@Inject(typesToken) private readonly tokens:
                         StorageType[]) {}

  private async createStorageFactory(
      requestedStorageTypes:
          StorageType[] = ['dom', 'cordova-sqlite', 'memory'],
      ): Promise<StorageFactory> {
    const firstPossibleStorageFactory =
        (await Promise.all(
             requestedStorageTypes
                 .map(
                     requestedStorageType =>
                         availableStorageTypes.get(requestedStorageType))
                 .filter(isntNull)
                 .map(
                     async foundStorageType =>
                         (await foundStorageType.isSupported()) ?
                         foundStorageType :
                         null,
                     ),
             ))
            .find(isntNull);
    if (firstPossibleStorageFactory != null) {
      return firstPossibleStorageFactory.factory();
    }

    throw new NoSupportedStorageAdapterError(
        'Expected at least one supported storage type for the current environment, however none were found.',
    );
  }

  public async createStorage<T>(
      tableName: string,
      databaseAlternativeName?: string,
      ): Promise<Keepsake<T>> {
    if (this._storageFactory == null) {
      this._storageFactory = this.createStorageFactory(this.tokens);
    }
    return (await this._storageFactory)
        .createStorage<T>(tableName, databaseAlternativeName);
  }
}

export function provideKeepsake(requestedStorageTypes?: StorageType[]):
    Provider[] {
  return [
    {provide: typesToken, useValue: requestedStorageTypes},
    {
      provide: StorageFactory,
      useClass: ProvidedFactory,
    },
  ];
}

@NgModule()
export class KeepsakeModule {
}
