import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {μCircleStepSvgModule} from '@maia/icons';

import {CircleStepDisplayComponent} from './circle-step-display.component';

@NgModule({
  declarations: [CircleStepDisplayComponent],
  imports: [μCircleStepSvgModule, CommonModule],
  exports: [CircleStepDisplayComponent],
})
export class CircleStepDisplayModule {}
