import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {coerceText} from '@atlas-angular/cdk/coercion';
import {WindowRef} from '@atlas-angular/cdk/globals';
import {Text} from '@atlas/businesstypes';
import {CssClassUtilityFactory} from '@maia/core';

import {BaseNumberDisplayAnimatedComponent} from '../base-number-animated/base-number-animated.component';

/**
 * @ngModule AnimatedDisplaysModule
 */
@Component({
  selector: 'maia-number-display[animated]',
  templateUrl: '../base-number-animated/base-number-animated.component.html',
  styleUrls: ['../base-number-animated/base-number-animated.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberDisplayAnimatedComponent
  extends BaseNumberDisplayAnimatedComponent
  implements OnChanges {
  @Input()
  @coerceText()
  public suffix: Text | string;

  public readonly showSmallDecimals: boolean;
  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
    windowRef: WindowRef,
    changeDetector: ChangeDetectorRef,
  ) {
    super(cssClassUtilityFactory, renderer, elementRef, windowRef, changeDetector);
  }

  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }
}
