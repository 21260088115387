import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 * @ngModule HeaderLayoutModule
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[maiaHeaderLayoutButton]',
  template: '<ng-content select="svg,maia-badge-small"></ng-content>',
  styleUrls: ['./header-layout-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLayoutButtonComponent {}
