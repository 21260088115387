import {Directive, forwardRef} from '@angular/core';
import {NG_VALIDATORS} from '@angular/forms';
import {validateClientNumber} from '@atlas/businesstypes';

import {AbstractValidatorDirective} from '../base/validator';

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasClientNumber]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BaseClientNumberValidator),
      multi: true,
    },
  ],
})
export class BaseClientNumberValidator extends AbstractValidatorDirective {
  protected readonly validatorFn = validateClientNumber;
}
