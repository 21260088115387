import {JsonValue} from './json';

/**
 * A producer produces values that represent the same values, though they are
 * always new values.
 *
 * For example, a producer of Date objects will always produce a Date representing
 * the same moment in time, but it should always return a new value.
 *
 * To put it in code, given a type `T` which implements an `equals`
 * method to test for equality, a `Producer<T>` called `p` must
 * conform to
 *
 * ```
 * assert(p() !== p(), 'the producer must produce a new value');
 * assert(p().equals(p()), 'the producer must produce equal values');
 * ```
 */
export type Producer<T> = () => T;

export interface StringBusinessTypeConstructor<T extends BusinessType<unknown>> {
  new (value: string, encryptedValue?: string): T;
}

/**
 * A businesstype class
 */
export interface BusinessTypeConstructor<T extends BusinessType<unknown>> {
  new (value: never, encryptedValue?: string): T;

  /**
   * Create a new instance of this businesstype class
   *
   * @param value The value of the businesstype
   * @param encryptedValue The encrypted value of the businesstype, if any
   * @throws if the given input is invalid
   */
  fromJsonValue(this: this, value: JsonValue, encryptedValue?: string): T;

  /**
   * Extracts a JSON value from the given instance
   *
   * @param value The instance, must be an instance of this class
   */
  toJsonValue(this: this, value: T): JsonValue;
}

export interface BusinessTypeConstructorWithOverrideFromJson<T extends BusinessType<unknown>>
  extends BusinessTypeConstructor<T> {
  overrideFromJsonValue(value: JsonValue, encryptedValue?: string): T;
}

function hasOverriddenFromJsonValue(
  type: Function,
): type is BusinessTypeConstructorWithOverrideFromJson<any> {
  return (
    typeof (type as BusinessTypeConstructorWithOverrideFromJson<any>).overrideFromJsonValue ===
    'function'
  );
}

export interface BusinessTypeConstructorWithOverrideToJson<T extends BusinessType<unknown>>
  extends BusinessTypeConstructor<T> {
  overrideToJsonValue(value: T): JsonValue;
}

function hasOverriddenToJsonValue(
  type: Function,
): type is BusinessTypeConstructorWithOverrideToJson<any> {
  return (
    typeof (type as BusinessTypeConstructorWithOverrideToJson<any>).overrideToJsonValue ===
    'function'
  );
}

/**
 * BusinessType instances are immutable objects that wrap around values of
 * `InternalType`.
 */
export abstract class BusinessType<InternalType> {
  /**
   * Create a new instance of this businesstype class
   *
   * @param value The value of the businesstype
   * @param encryptedValue The encrypted value of the businesstype, if any
   * @throws if the given input is invalid
   */
  public static fromJsonValue<T extends BusinessTypeConstructor<any>>(
    this: T,
    value: JsonValue,
    encryptedValue?: string,
  ): T extends StringBusinessTypeConstructor<infer U>
    ? U
    : T extends BusinessTypeConstructorWithOverrideFromJson<infer V>
    ? V
    : never {
    if (hasOverriddenFromJsonValue(this)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return this.overrideFromJsonValue(value, encryptedValue);
    }

    if (!(this.prototype instanceof BusinessType)) {
      throw new Error('fromJsonValue requires a BusinessType subclass');
    }

    if (typeof value !== 'string') {
      throw new Error(`Expected value to be a string but got ${value && typeof value}`);
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return new ((this as unknown) as StringBusinessTypeConstructor<any>)(value, encryptedValue);
  }

  /**
   * Extracts a JSON value from the given instance
   *
   * @param value The instance, must be an instance of this class
   */
  public static toJsonValue<T extends BusinessTypeConstructor<any>>(
    this: T,
    value: T extends BusinessTypeConstructor<BusinessType<string | boolean>>
      ? T extends BusinessTypeConstructor<infer U>
        ? U
        : never
      : T extends BusinessTypeConstructorWithOverrideToJson<infer V>
      ? V
      : never,
  ): JsonValue {
    if (value.constructor !== this) {
      throw new Error(
        `Can't call ${this.name}.toJsonValue on an instance of ${value.constructor.name}`,
      );
    }

    if (hasOverriddenToJsonValue(this)) {
      return this.overrideToJsonValue(value);
    }

    return (value as BusinessType<string | boolean>).internalValue;
  }

  /**
   * This property is the internal value used by the BusinessType, it should be
   * exposed using an aptly named function, e.g. `asString` for a BusinessType
   * text.
   *
   * Asking for the `internalValue` will always yield equal values. There's no guarantee
   * that `internalValue` always yields the same instance of the value, but we do guarantee
   * that if it doesn't return the same instance, it will never return the same instance.
   * That is, assuming `equals` returns
   * whether or not two instances of `InternalType` are equal and `bt` is a
   * `BusinessType`, we get:
   *
   * ```
   * // always equal
   * assert(equals(b.internalValue, b.internalValue))
   *
   * // either this
   * assert(b.internalValue === b.internalValue)
   * // _or_
   * assert(b.internalValue !== b.internalValue)
   * // will always fail, but we cannot tell which.
   * ```
   *
   * So your code shouldn't depend on the identity of the `internalValue` property.
   */
  protected readonly internalValue!: InternalType;

  /**
   * This property represents an "encrypted value", that is: a value that can prove
   * to a remote server that the value this BusinessType represents has been received
   * from that remote.
   *
   * This value is _not_ accessible to outside code and it shouldn't be depended on.
   * Converters are free to not support encrypted values.
   */
  private readonly encryptedValue?: string;

  /**
   * Creates a new BusinessType instance.
   *
   * Subclasses should make sure that this constructor is either always called with
   * a value of `InternalType` _or_ always called with a value of `Producer<InternalType>`.
   * If `InternalType` is immutable, e.g. a `string`, `number`, `boolean`, &hellip; the subclass
   * should simply pass the value on to the `BusinessType` constructor. If the `InternalType` is
   * mutable, e.g. a `Big`, the parent constructor should be called with a producer
   * instead.
   *
   * @param value The value, if immutable, or a value producer.
   * @param encryptedValue The encrypted value the new BusinessType instance should store, if any
   * @see Producer
   */
  protected constructor(value: InternalType | Producer<InternalType>, encryptedValue?: string) {
    if (typeof value === 'function') {
      Object.defineProperties(this, {
        internalValue: {
          get: value as Producer<InternalType>,
          configurable: false,
          enumerable: true,
        },
        encryptedValue: {
          value: encryptedValue,
          configurable: false,
          writable: false,
          enumerable: false,
        },
      });
    } else {
      Object.defineProperties(this, {
        internalValue: {
          value,
          configurable: false,
          writable: false,
          enumerable: true,
        },
        encryptedValue: {
          value: encryptedValue,
          configurable: false,
          writable: false,
          enumerable: false,
        },
      });
    }
  }

  /**
   * Tests for equality. In equality tests the encryptedValue, if any, is not taken
   * into account. For two `BusinessType<string>` implementations `BT` and `BT2`,
   * `equals` should behave like this:
   *
   * ```
   * const bt = new BT('foo')
   *
   * assert(bt.equals(bt));
   *
   * assert(bt.equals(new BT('foo')));
   * assert(bt.equals(new BT('foo', 'some encrypted value')));
   *
   * assert(!bt.equals(new BT('bar')));
   * assert(!bt.equals(new BT2('bar')));
   *
   * assert(!bt.equals(new BT2('foo')));
   * ```
   *
   * @param other The thing to test equality with
   */
  public abstract equals(other: unknown): boolean;

  /**
   * Returns a string representation of this BusinessType that can be used when
   * debugging etc. The resulting string will contain at least some type descriptor,
   * a representation of `internalValue` and, if present, the `encryptedValue`.
   *
   * There are no other guarantees regarding the format of the return value. It's
   * format and value should _never_ be depended on.
   *
   * @see Stringifier
   */
  public abstract toString(): string;
}

/**
 * Extracts the internal value from a businesstype
 *
 * @param value The businesstype for which to return the internal value
 */
export function getInternalValue<T>(value: BusinessType<T>): T {
  return value['internalValue'];
}

/**
 * Extracts the encrypted value from a businesstype value
 *
 * @param value The businesstype for which to return the encrypted value
 */
export function getEncryptedValue(value: BusinessType<any>): string | undefined {
  return value['encryptedValue'];
}

/**
 * A stringifier can (should) be used by BusinessType implementations to implement
 * the `toString` function.
 */
export type Stringifier = (value: BusinessType<any>, internalValue?: string) => string;

/**
 * Creates a helper function for concrete BusinessType classes to implement the
 * `toString` method. Use of such a helper is advised to ensure that the log output
 * looks similar for all BusinessTypes.
 *
 * @param type A string representation of the concrete BusinessType name
 */
export function createStringifier(type: string): Stringifier {
  return function (value: BusinessType<any>, internalValue?: string): string {
    if (internalValue == null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      internalValue = getInternalValue(value);
    }

    const encryptedValue = getEncryptedValue(value);
    if (encryptedValue != null) {
      return `${type} => ${JSON.stringify(internalValue)}, ${JSON.stringify(encryptedValue)}`;
    }

    return `${type} => ${JSON.stringify(internalValue)}`;
  };
}
