import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {ChangeDetectorRef, EventEmitter, QueryList} from '@angular/core';

/**
 * The different states of a step
 */
const enum StepState {
  TODO = 'todo',
  ACTIVE = 'active',
  DONE = 'done',
}

const resolved = Promise.resolve();

/**
 * A step in the flow progress
 */
export interface PublicStep {
  /**
   * Whether the step is active or not.
   *
   * A step is always either active, done or todo.
   */
  readonly active: boolean;

  /**
   * Whether the step is done or not.
   *
   * A step is always either active, done or todo.
   */
  readonly done: boolean;

  /**
   * Whether the step is todo or not.
   *
   * A step is always either active, done or todo.
   */
  readonly todo: boolean;
}

/**
 * Abstract class for step components.
 *
 * A step has three possible states:
 *
 * - done
 * - active
 * - todo (i.e. not done and not active)
 *
 * The actual components must register a provider for Step, e.g.
 *
 * ```ts
 * @Component({
 *   template: '{{label}}',
 *   providers: [{provide: Step, useExisting: forwardRef(() => BasicStepComponent)}],
 * })
 * export class BasicStepComponent extends Step {
 *   // ...
 * }
 * ```
 */
export abstract class Step implements PublicStep {
  private _state = StepState.TODO;

  private _disabled = false;

  /**
   * The weight of this step, e.g. a step of weight `.5` will only take up half the width of a step
   * with weight `1`. A step with weigth of `0` will not take up any size.
   */
  public weight = 1;

  /**
   * The title of this step.
   */
  public title: string;

  /**
   * Emits an event every time the step becomes active or inactive. It doesn't fire when the state
   * changes from inactive to a different kind of inactive (todo -> done, done -> todo)
   *
   * This should be an output of the component.
   */
  public activeChange = new EventEmitter<boolean>();

  /**
   * The progress within this step
   *
   * For instance if a step contains three substeps and two are done, the result could be `.66`.
   * This property migth be readonly.
   */
  public abstract progress: number;

  public constructor(protected _changeDetector: ChangeDetectorRef) {}

  /**
   * Whether the step is done or not.
   */
  public get done(): boolean {
    return this._state === StepState.DONE;
  }

  /**
   * Whether the step is active or not. If the 'active' state changes by calling changing this
   * property, no event is emitted from the `activeChange` emitter.
   *
   * Note that setting this property calls the FlowProgressComponent to update this step's activity.
   *
   * This should be an input of the component.
   */
  public get active(): boolean {
    return this._state === StepState.ACTIVE;
  }

  public set active(active: boolean) {
    if (active && this.disabled) {
      // Not strictly necessary given that the markActive function also throws this error, but if we
      // throw here we throw _before_ making any changes, while throwing from markActive leaves the
      // ticket in an invalid state.
      // Note that we can't just throw here and not in markActive because then we can't account for
      // non-disabled steps inside a disabled multi-step.
      throw new Error('Cannot activate a disabled step');
    }

    this._setInputActive(coerceBooleanProperty(active));
  }

  /**
   * Whether the step is todo or not.
   */
  public get todo(): boolean {
    return this._state === StepState.TODO;
  }

  /**
   * Whether or not the step is disabled. Disabling a step means that it cannot be made active.
   * Active steps that are disabled become inactive.
   */
  public get disabled(): boolean {
    return this._disabled;
  }

  public set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);

    if (this._disabled && this.active) {
      this.active = false;
    }
  }

  private _setState(newState: StepState): void {
    if (this._state === newState) {
      return;
    }

    const wasActive = this.active;
    this._state = newState;
    const isActive = this.active;

    resolved.then(() => {
      // This emit has to be async. We might be reacting to a change in the host view. In that case
      // emitting a new value which gets put back into the host view makes the ChangeDetector throw
      // an error.
      // By going async we ensure that this emit is handled in the next change detection cycle.
      if (isActive !== wasActive) {
        this.activeChange.emit(isActive);
      }

      // Change detection has to happen async because of timing issues when modifying the state of a
      // multi-step resulting in undetected changes
      this._changeDetector.markForCheck();
    });
  }

  /**
   * Marks this step as done.
   */
  public markDone() {
    this._setState(StepState.DONE);
  }

  /**
   * Marks this step as active. More specifically, the `activeStep` should become active. In a
   * component with substeps, this may be a substep of this component.
   */
  public markActive(_activeStep: Step) {
    if (this.disabled) {
      throw new Error('Cannot activate a disabled step');
    }

    this._setState(StepState.ACTIVE);
  }

  /**
   * Marks this step as todo.
   */
  public markTodo() {
    this._setState(StepState.TODO);
  }

  /**
   * Called by the `active` setter. This should call the FlowProgressComponent to update this step's
   * activity.
   * @param active whether this step should become active or inactive
   */
  protected abstract _setInputActive(active: boolean): void;

  /**
   * Returns whether this step matches the given step. This should return true if the given step is
   * this step instance or any of the substeps it contains.
   */
  public abstract matches(step: Step | null | undefined): boolean;
}

/**
 * Calculate the total weight of the given steps
 */
export function getTotalWeight(steps: Step[] | QueryList<Step>): number {
  return steps.reduce((sumOfWeight, step) => sumOfWeight + step.weight, 0);
}
