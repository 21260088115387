import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  Optional,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Date, DateKey, DateUtils, Month} from '@atlas/businesstypes';

import {
  coerceYearProperty,
  createDefaultYearRange,
  DefaultYearRange,
} from '../../parts/yearpicker/yearpicker.util';

/**
 * A year-monthpicker combines a yearpicker with a monthpicker to allow selection of months.
 *
 * @ngModule DatePickersModule
 */
@Component({
  selector: 'maia-yearmonthpicker',
  templateUrl: './yearmonthpicker.component.html',
  styleUrls: ['./yearmonthpicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => YearmonthpickerComponent),
      multi: true,
    },
  ],
})
export class YearmonthpickerComponent implements ControlValueAccessor, OnDestroy {
  private _showMonthView = false;

  /**
   * The value set with `[ngModel]`. We will re-use this object if the same date gets selected to
   * keep any `encryptedValue` in the businesstype intact.
   *
   * If an `initialYear` is set, that year will be preselected and the month view will be opened at
   * the start.
   */
  private _value?: Date = undefined;

  private _minimum: Date;
  private _maximum: Date;

  private _selectedYear?: number = undefined;
  private _selectedMonth?: Month = undefined;

  private _initialYear?: number = undefined;

  private readonly _defaultRange: DefaultYearRange;

  private _onTouched = () => {};
  private _onChanged = (_value: Date) => {};

  public constructor(
    private readonly _changeDetector: ChangeDetectorRef,
    @Optional() defaultRange?: DefaultYearRange,
  ) {
    this._defaultRange = createDefaultYearRange(defaultRange);
    this.rawMaximum = null;
    this.rawMinimum = null;
  }

  /**
   * To show the month view (`true`) or year view (`false`).
   */
  public get showMonthView(): boolean {
    return this._showMonthView;
  }

  /**
   * The maximum allowed month. If no value is passed, the `DefaultYearRange` will be used to
   * calculate the maximum year relative to the current year.
   */
  @Input('maximum')
  public set rawMaximum(value: Date | undefined | null) {
    if (value == null) {
      value = DateUtils.add(DateUtils.today(), DateKey.Year, this._defaultRange.upperBound);
    }

    this._maximum = !Date.isDate(value) ? new Date(value) : value;
  }

  /**
   * The minimum allowed month. If no value is passed, the `DefaultYearRange` will be used to
   * calculate the minimum year relative to the current year.
   */
  @Input('minimum')
  public set rawMinimum(value: Date | undefined | null) {
    if (value == null) {
      value = DateUtils.subtract(DateUtils.today(), DateKey.Year, this._defaultRange.lowerBound);
    }

    this._minimum = !Date.isDate(value) ? new Date(value) : value;
  }

  public get minimum(): Date {
    return this._minimum;
  }

  public get maximum(): Date {
    return this._maximum;
  }

  /**
   * The year to show initially in the yearpicker.
   */
  @Input()
  public get initialYear(): number | undefined {
    return this._initialYear;
  }

  public set initialYear(value: number | undefined) {
    this._initialYear = coerceYearProperty(value);

    if (this._selectedYear == null && this._initialYear != null) {
      this._selectedYear = this._initialYear;
      this._showMonthView = true;
    }
  }

  /**
   * The selected year, if any
   */
  public get selectedYear(): number | undefined {
    return this._selectedYear;
  }

  /**
   * The selected month, if any
   */
  public get selectedMonth(): Month | undefined {
    return this._selectedMonth;
  }

  /**
   * The disabled months for the selected year.
   */
  public get disabledMonths(): Month[] {
    if (!this._showMonthView) {
      return [];
    }

    const disabledMonths: number[] = [];

    if (this._selectedYear === DateUtils.get(this._minimum, DateKey.Year)) {
      const minimumMonth = DateUtils.get(this._minimum, DateKey.Month);

      for (let i = Month.January; i < minimumMonth; i++) {
        disabledMonths.push(i);
      }
    }

    if (this._selectedYear === DateUtils.get(this._maximum, DateKey.Year)) {
      const maximumMonth = DateUtils.get(this._maximum, DateKey.Month);

      for (let i = maximumMonth + 1; i <= Month.December; i++) {
        disabledMonths.push(i);
      }
    }

    return disabledMonths;
  }

  /**
   * Whether there is a year before the selected year.
   */
  public hasPreviousYear(): boolean {
    return this._selectedYear! > DateUtils.get(this._minimum, DateKey.Year);
  }

  /**
   * Selects the previous year.
   * There's no validation here, this assumes there _is_ a previous year to go to. Use
   * `hasPreviousYear()` to protect this call.
   */
  public selectPreviousYear(): void {
    this._selectedYear!--;
  }

  /**
   * Whether there is a year after the selected year.
   */
  public hasNextYear(): boolean {
    return this._selectedYear! < DateUtils.get(this._maximum, DateKey.Year);
  }

  /**
   * Selects the next year.
   * There's no validation here, this assumes there _is_ a next year to go to. Use `hasNextYear()`
   * to protect this call.
   */
  public selectNextYear(): void {
    this._selectedYear!++;
  }

  /**
   * Opens the year view.
   */
  public openYearView(): void {
    this._showMonthView = false;
  }

  /**
   * Marks the given year as selected and opens the month view.
   */
  public selectYear(year: number): void {
    this._selectedYear = year;
    this._showMonthView = true;
  }

  /**
   * Marks the given month as selected. This updates the `[(ngModel)]` value to the first day of the
   * selected month in the selected year.
   */
  public selectMonth(month: Month): void {
    this._selectedMonth = month;

    const startOfSelectedMonth = DateUtils.unserialize({
      [DateKey.Year]: this._selectedYear!,
      [DateKey.Month]: this._selectedMonth,
      [DateKey.Day]: 1,
    });

    if (this._value != null) {
      const previousStartOfMonth = DateUtils.atStartOf(this._value, DateKey.Month);

      if (startOfSelectedMonth.equals(previousStartOfMonth)) {
        // emit the previous value to keep encrypted values etc
        this._onChanged(this._value);
        return;
      }
    }

    this._onChanged(
      DateUtils.unserialize({
        [DateKey.Year]: this._selectedYear!,
        [DateKey.Month]: this._selectedMonth,
        [DateKey.Day]: 1,
      }),
    );
  }

  public ngOnDestroy() {
    this._onTouched();
  }

  // ControlValueAccessor API

  public writeValue(obj: any): void {
    if (obj == null) {
      this._value = undefined;
      this._selectedMonth = undefined;
      this._selectedYear = undefined;

      this._showMonthView = false;
    } else {
      this._value = Date.isDate(obj) ? obj : new Date(obj);

      this._selectedYear = DateUtils.get(this._value, DateKey.Year);
      this._selectedMonth = DateUtils.get(this._value, DateKey.Month);

      this._showMonthView = true;
    }

    this._changeDetector.detectChanges();
  }

  public registerOnChange(fn: any): void {
    this._onChanged = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
}
