import {ModalTemplateContext} from '@maia/modals';
import {SlideInOptions} from '@maia/slide-ins';

import {DropdownRepositionerService} from './dropdown-repositioner.service';
import {DropdownPosition} from './dropdown.constants';

/**
 * Visualisation options for the dropdown controller on small breakpoints
 */
export const enum SmallDropdownVisualisation {
  /**
   * Show the dropdown as a slide-in on small breakpoints
   */
  SlideIn = 0,

  /**
   * Keep the dropdown as dropdown on small breakpoints
   */
  Dropdown = 1,
}

export interface VisualiseSmallDropdownAsDropdownOption {
  visualisation: SmallDropdownVisualisation.Dropdown;
}

export interface VisualiseSmallDropdownAsSlideInOption {
  visualisation: SmallDropdownVisualisation.SlideIn;

  /**
   * Options for the slide-in when opening the dropdown on a small breakpoint
   */
  options: Omit<SlideInOptions, 'footerInScrollArea'>;
}

export interface AlignedDropdownExtraOptions {
  /**
   * The maximum size (in pixels) the dropdown can have while showing aligned
   *
   * If the size lies above this threshold, the `alternativePosition` will be used instead of the
   * normal position.
   */
  maximumSize?: number;

  /**
   * The minimum size (in pixels) the dropdown can have while showing aligned
   *
   * If the size lies below this threshold, the `alternativePosition` will be used instead of the
   * normal position.
   */
  minimumSize?: number;

  /**
   * The alternative position to use if the dropdown would be too large when shown aligned
   */
  alternativePosition: DropdownPosition;
}

/**
 * Options for the dropdown
 */
export interface DropdownOptions {
  /**
   * The default position the dropdown should take if there's plenty of space on the viewport.
   */
  position: DropdownPosition;

  /**
   * Disables creating a backdrop
   */
  withoutBackdrop?: boolean;

  /**
   * The visualisation of the dropdown on small breakpoints
   *
   * This defaults to showing the dropdown as dropdown, but can be modified to show the dropdown
   * as slide-in.
   */
  smallDropdownVisualisation?:
    | VisualiseSmallDropdownAsSlideInOption
    | VisualiseSmallDropdownAsDropdownOption;

  /**
   * Extra options that apply only if the requested position is aligned
   *
   * This option is completely ignored for dropdowns that don't use an aligned position
   */
  alignedDropdownExtra?: AlignedDropdownExtraOptions;

  /**
   * Disables reference overlapping when there is no enough space to show dropdown
   */
  withoutReferenceOverlapping?: boolean;
}

/**
 * Internal options for the dropdown. Contrary to how other modals like the slide-in and pop-up, not
 * all of these options are left open for the developer.
 *
 * @see DropdownOptions
 */
export abstract class InternalDropdownOptions implements DropdownOptions {
  /**
   * The default position the dropdown should take if there's plenty of space on the viewport.
   */
  public position: DropdownPosition;

  /**
   * The element to attach the dropdown to.
   */
  public referencePoint: HTMLElement;

  /**
   * The margins for positioning the dropdown element.
   */
  public margins?: Partial<DropdownMargin>;

  /**
   * Disables creating a backdrop
   */
  public withoutBackdrop?: boolean;

  /**
   * The visualisation of the dropdown on small breakpoints
   *
   * This defaults to showing the dropdown as dropdown, but can be modified to show the dropdown
   * as slide-in.
   */
  public smallDropdownVisualisation?:
    | VisualiseSmallDropdownAsSlideInOption
    | VisualiseSmallDropdownAsDropdownOption;

  /**
   * Extra options that apply only if the requested position is aligned
   *
   * This option is completely ignored for dropdowns that don't use an aligned position
   */
  public alignedDropdownExtra?: AlignedDropdownExtraOptions;

  /**
   * Disables reference overlapping when there is no enough space to show dropdown
   */
  public withoutReferenceOverlapping?: boolean;
}

/**
 * The margins between the dropdown and the reference point, if any.
 */
export interface DropdownMargin {
  /**
   * The margin on top of the dropdown if the dropdown is shown below the anchor
   */
  top: number;

  /**
   * The margin on bottom of the margin if the dropdown is shown above the anchor
   */
  bottom: number;

  /**
   * The margins left/right of the dropdown if shown horizontally aligned with the anchor
   *
   * The value defaults to 0 if not set
   *
   * Note that positive values mean the dropdown will be smaller than the anchor, i.e.
   *
   * ```
   * /-----------------------------------------\
   * |              anchor element             |
   * \-----------------------------------------/
   * |        /-----------------------\        |
   * | margin |        dropdown       | margin |
   * |        \-----------------------/        |
   * ```
   */
  alignedHorizontal?: number;
}

/**
 * The resolved dropdown position.
 */
export interface ResolvedDropdownPosition {
  /**
   * The distance of the resolved position from the top of the viewport.
   */
  top: number;

  /**
   * The distance of the resolved position from the left of the viewport.
   */
  left: number;

  /**
   * The used position. This can differ from the `defaultPosition` of the dropdown's options if
   * there isn't enough space in the viepwort.
   */
  position: DropdownPosition;

  /**
   * Whether the element is truly positioned according to `position` or not, e.g. when there's no
   * predefined position that fits the dropdown, it might be positioned to fit in the viewport
   * without respecting the linkb between the dropdown and the `referencePoint`.
   */
  disconnected: boolean;

  /**
   * Whether the entire dropdown fits in the viewport. If this value is true, either width or height
   * is set.
   */
  cramped: boolean;

  /**
   * The height of the dropdown. If this value is set, that means the dropdown is too large to fit
   * in the viewport and should its height should be limited to this value. If it is unset, the
   * entire natural height of the dropdown can be used.
   */
  height?: number;

  /**
   * The width of the dropdown. If this value is set, that means the dropdown is too large to fit
   * in the viewport and should its width should be limited to this value. If it is unset, the
   * entire natural width of the dropdown can be used.
   */
  width?: number;
}

export interface DropdownTemplateContext<T, I = undefined> extends ModalTemplateContext<T, I> {
  repositioner: DropdownRepositionerService;
}
