import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {InputAtlasControlValueAccessorDirective} from './base/index';
import {BBAN_VALIDATORS, BbanDirective, BbanPipe} from './bban/index';
import {BOOLEAN_VALIDATORS, BooleanDirective, BooleanPipe} from './boolean/index';
import {CARD_NUMBER_VALIDATORS, CardNumberDirective, CardNumberPipe} from './card-number/index';
import {
  CLIENT_NUMBER_VALIDATORS,
  ClientNumberDirective,
  ClientNumberPipe,
} from './client-number/index';
import {DATE_VALIDATORS, DateDirective, DatePipe} from './date/index';
import {DECIMAL_VALIDATORS, DecimalDirective, DecimalPipe} from './decimal/index';
import {
  EMAIL_ADDRESS_VALIDATORS,
  EmailAddressDirective,
  EmailAddressPipe,
} from './email-address/index';
import {FilePipe} from './file/index';
import {HtmlDirective} from './html/index';
import {IBAN_VALIDATORS, IbanDirective, IbanPipe} from './iban/index';
import {
  IDENTITY_CARD_NUMBER_VALIDATORS,
  IdentityCardNumberDirective,
  IdentityCardNumberPipe,
} from './identity-card-number/index';
import {
  NATIONAL_REGISTER_NUMBER_VALIDATORS,
  NationalRegisterNumberDirective,
  NationalRegisterNumberPipe,
} from './national-register-number/index';
import {PHONE_NUMBER_VALIDATORS, PhoneNumberDirective, PhoneNumberPipe} from './phone-number/index';
import {TEXT_VALIDATORS, TextDirective, TextPipe} from './text/index';
import {TSFR_VALIDATORS, TsfrDirective, TsfrPipe} from './tsfr/index';
import {VAT_VALIDATORS, VatDirective, VatPipe} from './vat/index';

const EXPORTS = [
  [InputAtlasControlValueAccessorDirective],
  [BBAN_VALIDATORS, BbanDirective, BbanPipe],
  [BOOLEAN_VALIDATORS, BooleanDirective, BooleanPipe],
  [CARD_NUMBER_VALIDATORS, CardNumberDirective, CardNumberPipe],
  [CLIENT_NUMBER_VALIDATORS, ClientNumberDirective, ClientNumberPipe],
  [DATE_VALIDATORS, DateDirective, DatePipe],
  [DECIMAL_VALIDATORS, DecimalDirective, DecimalPipe],
  [EMAIL_ADDRESS_VALIDATORS, EmailAddressDirective, EmailAddressPipe],
  [FilePipe],
  [HtmlDirective],
  [IBAN_VALIDATORS, IbanDirective, IbanPipe],
  [IDENTITY_CARD_NUMBER_VALIDATORS, IdentityCardNumberDirective, IdentityCardNumberPipe],
  [
    NATIONAL_REGISTER_NUMBER_VALIDATORS,
    NationalRegisterNumberDirective,
    NationalRegisterNumberPipe,
  ],
  [PHONE_NUMBER_VALIDATORS, PhoneNumberDirective, PhoneNumberPipe],
  [TEXT_VALIDATORS, TextDirective, TextPipe],
  [TSFR_VALIDATORS, TsfrDirective, TsfrPipe],
  [VAT_VALIDATORS, VatDirective, VatPipe],
];

@NgModule({
  imports: [FormsModule],
  declarations: [EXPORTS],
  exports: [EXPORTS],
})
export class BusinessTypesModule {}
