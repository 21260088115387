import {Injectable, InjectionToken} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';

export interface PhotoSelectorServiceInterface {
  takePhoto(): Observable<File>;
}

@Injectable()
export class PhotoSelectorService implements PhotoSelectorServiceInterface {
  public constructor() {}

  public takePhoto() {
    return EMPTY;
  }
}

export const PHOTO_SELECTOR_SERVICE = new InjectionToken<PhotoSelectorServiceInterface>(
  'photoSelectorService',
);
