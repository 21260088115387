import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {EnrichedDividerComponent} from './enriched-divider/enriched-divider.component';
import {DividerComponent} from './regular-divider/divider.component';

@NgModule({
  declarations: [DividerComponent, EnrichedDividerComponent],
  imports: [CommonModule],
  exports: [DividerComponent, EnrichedDividerComponent],
})
export class DividerModule {}
