import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

/**
 * @ngModule LayoutModule
 */
@Component({
  selector: 'maia-inherit-layout',
  template: '<div class="maia-target"><ng-content></ng-content></div>',
  styleUrls: ['./inherit-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InheritLayoutComponent {
  private _disabled = false;

  @Input()
  @HostBinding('class.maia-inherit-layout--disabled')
  public get disabled(): boolean {
    return this._disabled;
  }

  public set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }
}
