import {Directive, HostListener, OnInit, Optional} from '@angular/core';
import {NgControl} from '@angular/forms';
import {onDestroy, UntilDestroy} from '@atlas-angular/rxjs';
import {InputContainer} from '@maia/forms';

/**
 * @ngModule InputMasksModule
 */
@Directive({
  selector:
    'maia-masked-input[ngModel], maia-masked-input[formControl], maia-masked-input[formControlName]',
})
@UntilDestroy()
export class MaskedInputWithControlDirective implements OnInit {
  public constructor(
    private readonly _ngControl: NgControl,
    @Optional() private readonly _container?: InputContainer,
  ) {}

  // The maia-masked-input element cannot receive focus, so listen
  // if one of it's child elements received focus using the focusout
  // and focusin events.
  @HostListener('focusout')
  public blur(): void {
    if (this._container != null) {
      this._container.focused = false;
    }
  }

  @HostListener('focusin')
  public focus(): void {
    if (this._container != null) {
      this._container.focused = true;
    }
  }

  public ngOnInit() {
    if (this._container != null) {
      const destroyFn = this._container.registerFormControl(this._ngControl);
      onDestroy(this).subscribe(destroyFn);
    }
  }
}
