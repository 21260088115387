import {ChangeDetectionStrategy, Component, Optional} from '@angular/core';

import {HeaderLayoutComponent} from '../header-layout/header-layout.component';

/**
 * @ngModule HeaderLayoutModule
 */
@Component({
  selector: 'maia-header-layout-title',
  template: '<ng-content></ng-content>',
  styleUrls: ['./header-layout-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLayoutTitleComponent {
  public constructor(@Optional() header?: HeaderLayoutComponent) {
    if (header == null) {
      throw new Error(
        'Cannot create a <maia-header-layout-title /> component out of <maia-header-layout /> component',
      );
    }
  }
}
