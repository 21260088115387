import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SocialFuncPersonSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaSocialFuncPerson]',
  template: '<svg:path d="M19.55 21.5H4.45v-3.88A2.83 2.83 0 0 1 6.27 15 16.16 16.16 0 0 1 12 14a16.16 16.16 0 0 1 5.73 1 2.83 2.83 0 0 1 1.82 2.65z" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M12 13.83h0c-2.61 0-4.72-3.06-4.72-5.67v-.94A4.72 4.72 0 0 1 12 2.5h0a4.72 4.72 0 0 1 4.72 4.72v.94c0 2.61-2.11 5.67-4.72 5.67z" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialFuncPersonSvgComponent {
}

@NgModule({
  declarations: [
    SocialFuncPersonSvgComponent,
  ],
  exports: [
    SocialFuncPersonSvgComponent,
  ],
})
export class SocialFuncPersonSvgModule {}
