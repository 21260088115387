import {Component, HostBinding, Input} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

/**
 * @ngModule ListsModule
 */
@Component({
  selector: 'maia-list',
  template: '<ng-content></ng-content>',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent {
  @Input()
  @HostBinding('class.p-maia-list--disable-hover')
  @coerceBooleanPrimitive()
  public disableHover: boolean;
}
