import {Component, ChangeDetectionStrategy} from '@angular/core';

/**
 * @ngModule LayoutModule
 * @deprecated Use the `maia-content-tail-layout` mixin instead
 */
@Component({
  selector: 'maia-layout-footer',
  template: '<ng-content></ng-content>',
  styleUrls: ['./layout-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutFooterComponent {}
