import {Component} from '@angular/core';

/**
 * Badge small variant component
 *
 * @ngModule BadgeModule
 */
@Component({
  selector: 'maia-badge-small',
  styleUrls: ['./badge-small.component.scss'],
  template: '',
})
export class BadgeSmallComponent {}
