import {Injectable} from '@angular/core';
import {fromEvent, Observable} from 'rxjs';

/**
 * The `DocumentRef` grants access to the Document using Angular's dependency injection.
 *
 * This method of accessing the document is preferred over using the global document variable
 * because it easily allows tests to mock (parts of) the document.
 */
@Injectable({providedIn: 'root'})
export class DocumentRef {
  /**
   * The document object, same as the `document` global.
   */
  public get document(): Document {
    return document;
  }

  /**
   * Exposes events on the Document object as observable.
   *
   * @param eventName The name of the document event to listen to
   * @param options Options to pass on to `addEventListener`, if any
   */
  public on$<K extends keyof DocumentEventMap>(
    eventName: K,
    options?: AddEventListenerOptions,
  ): Observable<DocumentEventMap[K]> {
    return options != null
      ? fromEvent<DocumentEventMap[K]>(this.document, eventName, options)
      : fromEvent<DocumentEventMap[K]>(this.document, eventName);
  }
}
