import {Directive, OnInit, Optional} from '@angular/core';
import {onDestroy, UntilDestroy} from '@atlas-angular/rxjs';

import {ValidationMessageExtractor} from '../form-element/validation-message-extractor.service';
import {BuiltinValidationMessageService} from './builtin-validation-message.service';

@Directive()
export abstract class BaseDirective implements OnInit {
  public constructor(
    @Optional() private readonly builtinMessages?: BuiltinValidationMessageService,
    @Optional() private readonly messageExtractor?: ValidationMessageExtractor,
  ) {}

  public ngOnInit(): void {
    if (this.builtinMessages == null || this.messageExtractor == null) {
      return;
    }

    onDestroy(this).subscribe(this.register(this.builtinMessages, this.messageExtractor));
  }

  protected abstract register(
    builtinMessages: BuiltinValidationMessageService,
    messageExtractor: ValidationMessageExtractor,
  ): () => void;
}

/**
 * @ngModule FormsModule
 */
@Directive({selector: '[atlasBban]'})
@UntilDestroy()
export class BbanDirective extends BaseDirective {
  protected register(
    builtinMessages: BuiltinValidationMessageService,
    messageExtractor: ValidationMessageExtractor,
  ): () => void {
    return builtinMessages.registerBbanMessages(messageExtractor);
  }
}

/**
 * @ngModule FormsModule
 */
@Directive({selector: '[atlasBoolean]'})
@UntilDestroy()
export class BooleanDirective extends BaseDirective {
  protected register(
    builtinMessages: BuiltinValidationMessageService,
    messageExtractor: ValidationMessageExtractor,
  ): () => void {
    return builtinMessages.registerBooleanMessages(messageExtractor);
  }
}

/**
 * @ngModule FormsModule
 */
@Directive({selector: '[atlasCardNumber]'})
@UntilDestroy()
export class CardNumberDirective extends BaseDirective {
  protected register(
    builtinMessages: BuiltinValidationMessageService,
    messageExtractor: ValidationMessageExtractor,
  ): () => void {
    return builtinMessages.registerCardNumberMessages(messageExtractor);
  }
}

/**
 * @ngModule FormsModule
 */
@Directive({selector: '[atlasClientNumber]'})
@UntilDestroy()
export class ClientNumberDirective extends BaseDirective {
  protected register(
    builtinMessages: BuiltinValidationMessageService,
    messageExtractor: ValidationMessageExtractor,
  ): () => void {
    return builtinMessages.registerClientNumberMessages(messageExtractor);
  }
}

/**
 * @ngModule FormsModule
 */
@Directive({selector: '[atlasDate], maia-input-date'})
@UntilDestroy()
export class DateDirective extends BaseDirective {
  protected register(
    builtinMessages: BuiltinValidationMessageService,
    messageExtractor: ValidationMessageExtractor,
  ): () => void {
    return builtinMessages.registerDateMessages(messageExtractor);
  }
}

/**
 * @ngModule FormsModule
 */
@Directive({selector: '[atlasDecimal]'})
@UntilDestroy()
export class DecimalDirective extends BaseDirective {
  protected register(
    builtinMessages: BuiltinValidationMessageService,
    messageExtractor: ValidationMessageExtractor,
  ): () => void {
    return builtinMessages.registerDecimalMessages(messageExtractor);
  }
}

/**
 * @ngModule FormsModule
 */
@Directive({selector: '[atlasEmailAddress]'})
@UntilDestroy()
export class EmailAddressDirective extends BaseDirective {
  protected register(
    builtinMessages: BuiltinValidationMessageService,
    messageExtractor: ValidationMessageExtractor,
  ): () => void {
    return builtinMessages.registerEmailAddressMessages(messageExtractor);
  }
}

/**
 * @ngModule FormsModule
 */
@Directive({selector: '[atlasFile]'})
@UntilDestroy()
export class FileDirective extends BaseDirective {
  protected register(
    builtinMessages: BuiltinValidationMessageService,
    messageExtractor: ValidationMessageExtractor,
  ): () => void {
    return builtinMessages.registerFileMessages(messageExtractor);
  }
}

/**
 * @ngModule FormsModule
 */
@Directive({selector: '[atlasHtml]'})
@UntilDestroy()
export class HtmlDirective extends BaseDirective {
  protected register(
    builtinMessages: BuiltinValidationMessageService,
    messageExtractor: ValidationMessageExtractor,
  ): () => void {
    return builtinMessages.registerHtmlMessages(messageExtractor);
  }
}

/**
 * @ngModule FormsModule
 */
@Directive({selector: '[atlasIban]'})
@UntilDestroy()
export class IbanDirective extends BaseDirective {
  protected register(
    builtinMessages: BuiltinValidationMessageService,
    messageExtractor: ValidationMessageExtractor,
  ): () => void {
    return builtinMessages.registerIbanMessages(messageExtractor);
  }
}

/**
 * @ngModule FormsModule
 */
@Directive({selector: '[atlasIdentityCardNumber]'})
@UntilDestroy()
export class IdentityCardNumberDirective extends BaseDirective {
  protected register(
    builtinMessages: BuiltinValidationMessageService,
    messageExtractor: ValidationMessageExtractor,
  ): () => void {
    return builtinMessages.registerIdentityCardNumberMessages(messageExtractor);
  }
}

/**
 * @ngModule FormsModule
 */
@Directive({selector: '[maiaInputAutocomplete]'})
@UntilDestroy()
export class InputAutocompleteDirective extends BaseDirective {
  protected register(
    builtinMessages: BuiltinValidationMessageService,
    messageExtractor: ValidationMessageExtractor,
  ): () => void {
    return builtinMessages.registerInputAutocompleteMessages(messageExtractor);
  }
}

/**
 * @ngModule FormsModule
 */
@Directive({selector: '[atlasNationalRegisterNumber]'})
@UntilDestroy()
export class NationalRegisterNumberDirective extends BaseDirective {
  protected register(
    builtinMessages: BuiltinValidationMessageService,
    messageExtractor: ValidationMessageExtractor,
  ): () => void {
    return builtinMessages.registerNationalRegisterNumberMessages(messageExtractor);
  }
}

/**
 * @ngModule FormsModule
 */
@Directive({selector: '[atlasPhoneNumber], maia-input-phonenumber'})
@UntilDestroy()
export class PhoneNumberDirective extends BaseDirective {
  protected register(
    builtinMessages: BuiltinValidationMessageService,
    messageExtractor: ValidationMessageExtractor,
  ): () => void {
    return builtinMessages.registerPhoneNumberMessages(messageExtractor);
  }
}

/**
 * @ngModule FormsModule
 */
@Directive({selector: '[atlasText]'})
@UntilDestroy()
export class TextDirective extends BaseDirective {
  protected register(
    builtinMessages: BuiltinValidationMessageService,
    messageExtractor: ValidationMessageExtractor,
  ): () => void {
    return builtinMessages.registerTextMessages(messageExtractor);
  }
}

/**
 * @ngModule FormsModule
 */
@Directive({selector: '[atlasTsfr]'})
@UntilDestroy()
export class TsfrDirective extends BaseDirective {
  protected register(
    builtinMessages: BuiltinValidationMessageService,
    messageExtractor: ValidationMessageExtractor,
  ): () => void {
    return builtinMessages.registerTsfrMessages(messageExtractor);
  }
}

/**
 * @ngModule FormsModule
 */
@Directive({selector: '[atlasVat]'})
@UntilDestroy()
export class VatDirective extends BaseDirective {
  protected register(
    builtinMessages: BuiltinValidationMessageService,
    messageExtractor: ValidationMessageExtractor,
  ): () => void {
    return builtinMessages.registerVatMessages(messageExtractor);
  }
}
