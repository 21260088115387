import {ChangeDetectionStrategy, Component, HostBinding, Input, Optional} from '@angular/core';
import {coerceNumberPrimitive} from '@atlas-angular/cdk/coercion';

import {ImageContentLayoutComponent} from '../image-content-layout/image-content-layout.component';

/**
 *
 * This component contains the background image and the optional overlay content.
 * Can contain `img` or `svg` for the background image.
 * Can contain any element as `overlay content`.
 * Can only be used inside a maia-image-content-layout.
 *
 * @ngModule LayoutModule
 */
@Component({
  selector: 'maia-image-content-layout-image',
  templateUrl: './image-content-layout-image.component.html',
  styleUrls: ['./image-content-layout-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageContentLayoutImageComponent {
  /**
   * Optional property to specify a custom height to the image in the content layout image.
   * Valid with any of these options:
   * -alignment = 'block' & imagePosition == 'start'".
   * -alignment == 'block' & imagePosition == 'end'".
   */
  @Input()
  @coerceNumberPrimitive()
  @HostBinding('style.height.px')
  public imageHeight?: number;

  public constructor(@Optional() parent?: ImageContentLayoutComponent) {
    if (parent == null) {
      throw new Error(
        'Cannot create a <maia-image-content-layout-image /> component out of <maia-image-content-layout /> component',
      );
    }
  }
}
