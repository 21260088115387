import {DOWN_ARROW, LEFT_ARROW, RIGHT_ARROW, SPACE, UP_ARROW} from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  forwardRef,
  HostBinding,
  HostListener,
  Optional,
  QueryList,
  Renderer2,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {Utilities} from '@maia/core';
import {
  FormElementComponent,
  InputContainer,
  LabelContainerService,
  labelContainerServiceFactory,
  ValidationMessageExtractor,
  μSingleInputContainer as SingleInputContainer,
} from '@maia/forms';
import {ReplaySubject} from 'rxjs';

import {CollapsibleRadioButtonComponent} from '../collapsible-radio-button/collapsible-radio-button.component';
import {allDisabled, getIndex, isKeySupported} from '../utils';

const generateName = Utilities.createIdGenerator('maia-collapsible-radio-group-');

/**
 * A collapsible form element. This form element is created sole for use with the
 * collapsible radio buttons.
 *
 * @ngModule FormsModule
 */
@Component({
  selector: 'maia-collapsible-radio-group-form-element',
  templateUrl: './collapsible-radio-group-form-element.component.html',
  styleUrls: [
    '../../../forms/src/form-element/form-element.component.scss',
    './collapsible-radio-group-form-element.component.scss',
  ],

  host: {
    '[class.maia-form-element--label-aligned-left]': 'options.alignLabelLeft || false',
  },

  providers: [
    ValidationMessageExtractor,
    {
      provide: LabelContainerService,
      useFactory: labelContainerServiceFactory,
      deps: [forwardRef(() => CollapsibleRadioGroupFormElementComponent), Renderer2],
    },
    {provide: InputContainer, useClass: SingleInputContainer},
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CollapsibleRadioGroupFormElementComponent),
      multi: true,
    },
  ],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class CollapsibleRadioGroupFormElementComponent
  extends FormElementComponent
  implements ControlValueAccessor {
  @ContentChildren(forwardRef(() => CollapsibleRadioButtonComponent))
  protected queryList: QueryList<CollapsibleRadioButtonComponent>;

  public name = generateName();
  public selectedValue$ = new ReplaySubject(1);
  private _selectedValueIndex = 0;

  @HostBinding('attr.aria-activedescendant')
  public activeDescendant: string;

  @HostListener('keydown', ['$event'])
  public keyEvent(event: KeyboardEvent): void {
    if (isKeySupported(event.keyCode) && !allDisabled(this.queryList.toArray())) {
      this.manageButtonSelection(event.keyCode);
      event.preventDefault();
      event.stopPropagation();
    }
  }

  public set selectedValue(selectedValue: any) {
    this.writeValue(selectedValue);
    this.onChange(selectedValue);
  }

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
    inputContainer: InputContainer,
    @Optional() parent?: FormElementComponent,
    @Optional() container?: InputContainer,
  ) {
    super(inputContainer, changeDetectorRef);
    this.extraPadding = true;
    if (container != null && this.queryList != null) {
      container.disabled$.subscribe(isDisabled => {
        this.queryList.forEach(
          radioButtonComponent => (radioButtonComponent.forceDisabled = isDisabled),
        );
      });
    }
  }

  private manageButtonSelection(keyCode: number) {
    if (keyCode === RIGHT_ARROW || keyCode === DOWN_ARROW) {
      this._selectedValueIndex = (this._selectedValueIndex + 1) % this.queryList.length;
    } else if (keyCode === LEFT_ARROW || keyCode === UP_ARROW) {
      this._selectedValueIndex =
        (this._selectedValueIndex + this.queryList.length - 1) % this.queryList.length;
    }

    if (!this.queryList.toArray()[this._selectedValueIndex].disabled) {
      this.selectedValue = this.queryList.toArray()[this._selectedValueIndex].value;
    } else {
      // In case keyCode is space, we need to change it to select the next one and avoid infinite
      // loop
      keyCode = keyCode === SPACE ? DOWN_ARROW : keyCode;
      this.manageButtonSelection(keyCode);
    }
  }

  public onTouched = () => {};
  public onChange = (_: any) => {};

  public writeValue(obj: any): void {
    this.selectedValue$.next(obj);
    this._selectedValueIndex = this.queryList ? getIndex(this.queryList.toArray(), obj) : 0;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
