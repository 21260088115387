import {Directive, forwardRef} from '@angular/core';

import {BifrostHeaderVariant} from './header-variant';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'header[backgroundPublic]',
  providers: [
    {
      provide: BifrostHeaderVariant,
      useExisting: forwardRef(() => HeaderVariantBackgroundPublicDirective),
    },
  ],
})
export class HeaderVariantBackgroundPublicDirective implements BifrostHeaderVariant {
  public isPublic = true;
  public isCollapsible = true;
  public allowBackButton = true;
  public hasBackground = true;
}
