import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  InjectionToken,
  Renderer2,
} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';

export const ATLAS_VALUE_ACCESSOR = new InjectionToken<AtlasControlValueAccessor>(
  'AtlasControlValueAccessor',
);

/**
 * The AtlasControlValueAccessor is used by the Atlas businesstypes directives to get/set the string
 * representation of the businesstypes on inputs.
 */
export interface AtlasControlValueAccessor {
  /**
   * This method will be called by the forms API to write to the view when programmatic (model ->
   * view) changes are requested.
   *
   * Example implementation of writeValue:
   *
   * ```ts
   * writeValue(value: any): void {
   *   this._renderer.setProperty(this._elementRef.nativeElement, 'value', value);
   * }
   * ```
   *
   * @see ng.forms.ControlValueAccessor#writeValue
   */
  writeValue(value: string): void;

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   *
   * If you are implementing registerOnChange in your own value accessor, you will typically want to
   * save the given function so your class can call it at the appropriate time.
   *
   * ```ts
   * registerOnChange(fn: (_: any) => void): void {
   *   this._onChange = fn;
   * }
   * ```
   *
   * When the value changes in the UI, your class should call the registered function to allow the
   * forms API to update itself:
   *
   * ```ts
   * host: {
   *   (change): '_onChange($event.target.value)'
   * }
   * ```
   *
   * @see ng.forms.ControlValueAccessor#registerOnChange
   */
  registerOnChange(fn: (newValue: string) => void): void;

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   *
   * If you are implementing registerOnTouched in your own value accessor, you will typically want
   * to save the given function so your class can call it when the control should be considered
   blurred
   * (a.k.a. "touched").

   * ```ts
   * registerOnTouched(fn: any): void {
   *   this._onTouched = fn;
   * }
   * ```
   *
   * On blur (or equivalent), your class should call the registered function to allow the forms API
   to
   * update itself:
   *
   * ```ts
   * host: {
   *   '(blur)': '_onTouched()'
   * }
   * ```
   *
   * @see ng.forms.ControlValueAccessor#registerOnTouched
   */
  registerOnTouched(fn: () => void): void;

  /**
   * This function is called when the disabled state of the form control changes.
   *
   * Example implementation:
   *
   * ```ts
   * setDisabledState(isDisabled: boolean): void {
   *   this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
   * }
   * ```
   *
   * @param isDisabled Whether the form control is disabled
   * @see ng.forms.ControlValueAccessor#setDisabledState
   */
  setDisabledState(isDisabled: boolean): void;
}

/**
 * Base class for all businesstype directives
 *
 * This class implements shared behaviour for all businesstype control value accessors.
 */
export abstract class BaseBusinesstypeDirective implements ControlValueAccessor {
  public constructor(protected readonly _input: AtlasControlValueAccessor) {}

  public abstract writeValue(value: any): void;

  public abstract registerOnChange(onChange: (value: any) => void): void;

  public registerOnTouched(onTouched: () => void): void {
    this._input.registerOnTouched(onTouched);
  }

  public setDisabledState(isDisabled: boolean): void {
    this._input.setDisabledState(isDisabled);
  }
}

/**
 * An AtlasControlValueAccessor to get/set values from HTML `<input>` and `<textarea>` elements.
 *
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: 'input, textarea',
  providers: [
    {
      provide: ATLAS_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputAtlasControlValueAccessorDirective),
    },
  ],
})
export class InputAtlasControlValueAccessorDirective implements AtlasControlValueAccessor {
  @HostListener('input', ['$event.target.value'])
  public onChange = (_: any) => {
    // do nothing
  };

  @HostListener('blur')
  public onTouched = () => {
    // do nothing
  };

  /* eslint-disable-next-line @typescript-eslint/member-ordering */
  public constructor(
    private readonly _renderer: Renderer2,
    private readonly _elementRef: ElementRef,
  ) {}

  public writeValue(value: string): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'value', value);
  }

  public registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }
}
