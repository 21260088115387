import {Date, DateFormatter} from '@atlas/businesstypes';
import {DATE_TEXT_LENGTH, DateFormat, DEFAULT_DATE_FORMAT} from '@maia/input-masks';

/**
 * The possible number of days in a month, as used in the datepickers module.
 *
 * Values ranges from 28 to 31.
 */
export const enum DaysInMonth {
  TwentyEight = 28,
  TwentyNine = 29,
  Thirty = 30,
  ThirtyOne = 31,
}

/**
 * Formats a date string from the default format to the given DateFormat
 * e.g. DD-MM-YYYY --> YYYYMMDD
 * e.g. DDMMYYYY --> YYYYMMDD
 */
export function formatDateToCustomFormat(
  value: string | Date,
  dateFormat: DateFormat,
  keepSeparators = false,
): string {
  if (Date.isDate(value)) {
    value = DateFormatter.unformat(DateFormatter.format(value));
  }

  const clearedDefaultFormat = DEFAULT_DATE_FORMAT.replace(/[-]/g, '');

  const year = value.substr(clearedDefaultFormat.indexOf('Y'), 4);
  const month = value.substr(clearedDefaultFormat.indexOf('M'), 2);
  const day = value.substr(clearedDefaultFormat.indexOf('D'), 2);

  let clearedFormat: string = dateFormat;

  if (!keepSeparators) {
    clearedFormat = dateFormat.replace(/[-\s]|\.\s?/g, '');
  }

  return clearedFormat.replace('YYYY', `${year}`).replace('MM', `${month}`).replace('DD', `${day}`);
}

/**
 * Formats a given formatted date string to the default format (without hyphens or dots)
 * e.g. YYYY-MM-DD --> DDMMYYYY
 * e.g. YYYY. MM. DD. --> DDMMYYYY
 * e.g. MMDDYYYY --> DDMMYYYY
 */
export function formatDateToDefaultFormat(
  value: string,
  currentFormat: DateFormat,
  keepSeparators = false,
): string {
  if (value.length < DATE_TEXT_LENGTH || currentFormat === DEFAULT_DATE_FORMAT) {
    return value;
  }

  const clearedFormat = currentFormat.replace(/[-]|\.\s?/g, '');

  const year = value.substr(clearedFormat.indexOf('Y'), 4);
  const month = value.substr(clearedFormat.indexOf('M'), 2);
  const day = value.substr(clearedFormat.indexOf('D'), 2);

  let clearedDefaultFormat = DEFAULT_DATE_FORMAT;

  if (!keepSeparators) {
    clearedDefaultFormat = clearedDefaultFormat.replace(/[-]/g, '');
  }

  return clearedDefaultFormat
    .replace('YYYY', `${year}`)
    .replace('MM', `${month}`)
    .replace('DD', `${day}`);
}
