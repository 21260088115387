import {Component, Input, OnDestroy, Optional} from '@angular/core';

import {FormElementComponent} from '../form-element/form-element.component';

/**
 * A customizable label to be used in form elements.
 *
 * Note that this component doesn't represent the native `<label/>` element. This component simply
 * grants the user more control over the look of the form element label.
 *
 * @ngModule FormsModule
 */
@Component({
  selector: 'maia-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent implements OnDestroy {
  private readonly destroyFn?: () => void;

  @Input('sublabel')
  public sublabel?: Text | string;

  public constructor(@Optional() formElement?: FormElementComponent) {
    if (formElement != null) {
      this.destroyFn = formElement.registerCustomLabel();
    }
  }

  public ngOnDestroy(): void {
    if (this.destroyFn != null) {
      this.destroyFn();
    }
  }
}
