import {Directive, HostBinding, Input} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

const THRESHOLD = 32;
const INVALID_SIZE_ERROR = 'avatarSize must be a number that is divisible by 4';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AvatarComponent {
  private _avatarSize: number;

  /**
   * Property to set a disabled state to an avatar component.
   * If added when implementing component, e.g. <maia-avatar-svg disabled></maia-avatar-svg>
   * Adds a class that toggles the disabled state.
   * Note: by default avatar is in not a disabled state.
   */
  @coerceBooleanPrimitive()
  @Input()
  @HostBinding('class.maia-avatar--disabled')
  public disabled = false;

  public get avatarSize(): number {
    return this._avatarSize;
  }

  /**
   * Property to specify a custom size to an avatar component.
   */
  @Input()
  @HostBinding('style.height.px')
  @HostBinding('style.width.px')
  public set avatarSize(avatarSize: number) {
    if (avatarSize % 4 !== 0) {
      throw new Error(INVALID_SIZE_ERROR);
    }
    this._avatarSize = avatarSize;
  }

  /**
   * Determines whether threshold is exceeeded by checking if
   * avatar size entered by consumer is equal or lower than threshold value.
   */
  @HostBinding('class.maia-avatar--threshold-exceeded')
  public get isThresholdExceeded(): boolean {
    return this.avatarSize <= THRESHOLD;
  }

  @Input()
  public shape: 'square' | 'round' = 'square';

  /**
   * Determines wether the avatar should be round
   */
  @HostBinding('class.maia-avatar--shape-round')
  public get isRound(): boolean {
    return this.shape === 'round';
  }
}
