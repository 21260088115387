/**
 * Generate Id for Radio Button.
 */
let nextId = 0;

export function generateId() {
  ++nextId;
  return `maia-collapsible-radio-button-${nextId}`;
}
/**
 * All valid values for the size of Radio Button.
 */
export type RadioButtonSize = 'small' | 'large';
/**
 * Default value for the size of Radio Button.
 */
export const DEFAULT_RADIO_BUTTON_SIZE: RadioButtonSize = 'large';
/**
 * Classes for Radio Button Component.
 */
export const RADIO_BUTTON_CLASSES = {
  size: {
    small: 'maia-collapsible-radio-button--small',
    large: 'maia-collapsible-radio-button--large',
  } as {[key in RadioButtonSize]: string},
};
