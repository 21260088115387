import {coerceBooleanProperty, coerceNumberProperty} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  Directive,
} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {Decimal, DecimalFormatter, DecimalUtils, Text} from '@atlas/businesstypes';
import {CssClassUtility, CssClassUtilityFactory} from '@maia/core';

import {DECIMAL_SEPARATOR, DisplaySize, NumberDisplayClasses} from '../util';

/**
 * The default number of decimals to display
 */
const DEFAULT_DECIMALS = 2;

@Directive()
export abstract class BaseNumberDisplayComponent implements OnChanges {
  private readonly _cssClassUtility: CssClassUtility<typeof NumberDisplayClasses>;
  private _size: DisplaySize | null;
  private _decimals = DEFAULT_DECIMALS;
  private _value: Decimal;
  private _integer: string;
  private _fractional: string;
  private _custom = false;

  public abstract readonly suffix?: Text | string;

  /**
   * The display size
   */
  @Input()
  public get size(): DisplaySize | null {
    return this._size;
  }

  public set size(value: DisplaySize | null) {
    if (value === this._size) {
      return;
    }
    this._size = value;
    this._cssClassUtility.setValue('size', value);
  }

  /**
   * Decimal part
   */
  @Input()
  public get decimals(): number {
    return this._decimals;
  }

  public set decimals(value: number) {
    this._decimals = coerceNumberProperty(value);
  }

  /**
   * The value as a Decimal object.
   */
  @Input()
  public get value(): Decimal {
    return this._value;
  }

  public set value(value: Decimal) {
    this._value = value;
  }

  /**
   * The integer part of the value.
   */
  public get integer(): string {
    return this._integer;
  }

  /**
   * The fractional part of the value.
   */
  public get fractional(): string {
    return this._fractional;
  }

  /**
   * The value formatted in such a way so that a screenreader can handle it.
   */
  public get label(): string {
    let label = '';
    if (this._value) {
      // Screenreader compatiblite numbers must be formatted without thousands seperators and a
      // minus symbol for negative values.
      label = DecimalFormatter.formatDecimal(this._value, this._decimals, '-', '');
      const {suffix} = this;
      if (suffix) {
        if (Text.isText(suffix)) {
          label = `${label} ${suffix.asString()}`;
        } else {
          label = `${label} ${suffix}`;
        }
      }
    }
    return label;
  }

  public get prefix(): string | false {
    return this.showPositiveSignSymbol && DecimalUtils.isPositive(this._value) ? '+' : false;
  }

  /**
   * Set to true if you want to apply a custom color by letting the display component inherit the
   * color.
   */
  @Input()
  @HostBinding('class.maia-number-display--custom')
  public get custom(): boolean {
    return this._custom;
  }

  public set custom(value: boolean) {
    this._custom = coerceBooleanProperty(value);
  }

  /**
   * Set to true if you want to apply a small decimal size on number-display component
   */
  @HostBinding('class.maia-number-display--small-decimals')
  public abstract readonly showSmallDecimals: boolean;

  /**
   * Whether or not we display the plus sign when the value is positive
   */
  @coerceBooleanPrimitive()
  @Input()
  public showPositiveSignSymbol = false;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(
      NumberDisplayClasses,
      renderer,
      elementRef,
    );
  }

  private parseNumber(): void {
    if (this._value) {
      [this._integer, this._fractional] = DecimalFormatter.formatDecimal(
        this._value,
        this._decimals,
      ).split(DECIMAL_SEPARATOR);
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.value || changes.decimals) {
      this.parseNumber();
    }
  }
}

/**
 * @ngModule DisplaysModule
 */
@Component({
  selector: 'maia-number-display:not([animated])',
  templateUrl: './number-display.component.html',
  styleUrls: ['./number-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberDisplayComponent extends BaseNumberDisplayComponent {
  @Input()
  public suffix: Text | string;

  @Input()
  @coerceBooleanPrimitive()
  public showSmallDecimals = false;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    super(cssClassUtilityFactory, renderer, elementRef);
  }
}
