import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Utilities} from '@maia/core';

import {CommonButton} from '../common-button.interface';
import {ToggleGroupComponent} from '../toggle-group/toggle-group.component';

const generateId = Utilities.createIdGenerator('maia-toggle-button');

/**
 * @ngModule RadioButtonsModule
 */
@Component({
  selector: 'maia-toggle-button',
  styleUrls: ['./toggle-button.component.scss'],
  templateUrl: './toggle-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {role: 'radio'},
})
@UntilDestroy()
export class ToggleButtonComponent implements CommonButton, OnInit {
  private _id: string;
  private _active = false;
  public disabled = false;

  @Input()
  public value: any;

  public constructor(
    @Inject(forwardRef(() => ToggleGroupComponent))
    private readonly radioGroupComponent: ToggleGroupComponent,
  ) {}

  @Input()
  @HostBinding('id')
  public set id(value: string) {
    if (this._id !== undefined && this._id !== value) {
      throw new Error('a different id cannot be set once maia-toggle-button has been initialised');
    }
    this._id = value;
  }

  public get id() {
    if (this._id === undefined) {
      this._id = generateId();
    }
    return this._id;
  }

  @HostListener('click')
  public select() {
    this.radioGroupComponent.selectedValue = this.value;
  }

  public ngOnInit() {
    this.radioGroupComponent.selectedValue$
      .pipe(takeUntilDestroyed(this))
      .subscribe((value: any) => {
        this.active = value === this.value;
      });
  }

  @HostBinding('class.maia-toggle-button-active')
  public set active(value: boolean) {
    this._active = value;
  }

  public get active(): boolean {
    return this._active;
  }
}
