import {Directive, forwardRef, Inject} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {CardNumber, CardNumberFormatter, isValidCardNumber} from '@atlas/businesstypes';

import {
  ATLAS_VALUE_ACCESSOR,
  AtlasControlValueAccessor,
  BaseBusinesstypeDirective,
} from '../base/index';

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasCardNumber]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CardNumberDirective),
    },
  ],
})
export class CardNumberDirective extends BaseBusinesstypeDirective {
  public constructor(@Inject(ATLAS_VALUE_ACCESSOR) input: AtlasControlValueAccessor) {
    super(input);
  }

  public writeValue(value?: CardNumber | string): void {
    this._input.writeValue(
      CardNumber.isCardNumber(value)
        ? CardNumberFormatter.format(value)
        : value == null
        ? ''
        : value,
    );
  }

  public registerOnChange(fn: (_: CardNumber | string | null) => void): void {
    this._input.registerOnChange(value => {
      if (value === '') {
        fn(null);
        return;
      }

      const unformatted = CardNumberFormatter.unformat(value);
      fn(isValidCardNumber(unformatted) ? new CardNumber(unformatted) : unformatted);
    });
  }
}
