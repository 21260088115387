import {Component} from '@angular/core';

/**
 * List Item component to be used inside `LinkedListComponent`
 *
 * The LinkedListItem is designed to have both a left-content and right-content, fully customisable by the user.
 * All linked list items are attached by a dotted line between the left-contents.
 * Content is alligned center-center in the left content
 * Content is alligned center-left in the right content
 *
 * Example usage:
 *
 * ```html
 * <maia-linked-list>
 *     <maia-linked-list-item>
 *         <div class="left-content-area" left-content>left content area</div>
 *         <div class="right-content-area">right content area</div>
 *     </maia-linked-list-item>
 *     ...
 * </maia-linked-list>
 * ```
 * Any element or component can be used for either `left-content` or `right-content`.
 * The left content must have the `left-content` selector.
 * All other content is projected into the `right-content-area`
 *
 * @see {@link LinkedListComponent}
 * @ngModule ListsModule
 */
@Component({
  selector: 'maia-linked-list-item',
  templateUrl: './linked-list-item.component.html',
  styleUrls: ['./linked-list-item.component.scss'],
})
export class LinkedListItemComponent {}
