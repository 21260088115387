import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SubNavigationToggleMobileSignOSvgModule
 */
@Component({
  selector: 'svg[ivyHermesSubNavigationToggleMobileSignO]',
  template: '<svg:path d="M380.6 0H131.3c-26.2 0-47.6 21.3-47.6 47.6v416.9c0 26.2 21.3 47.6 47.6 47.6h249.4c26.2 0 47.6-21.3 47.6-47.6V47.6c0-26.3-21.4-47.6-47.7-47.6zm37.9 464.4c0 20.8-16.9 37.8-37.8 37.8H131.3c-20.8 0-37.8-16.9-37.8-37.8V47.6c0-20.8 16.9-37.8 37.8-37.8h249.3c20.9 0 37.9 16.9 37.9 37.8v416.8z"></svg:path><svg:path d="M125.5 428.3h260.9V41.9H125.5v386.4zm9.8-376.6h241.3v366.8H135.3V51.7z"></svg:path><svg:path d="M267.9 262.3c0-7.4-6-13.3-13.3-13.3s-13.3 6-13.3 13.3c0 5.3 3.1 9.9 7.6 12.1l-5 29.8h21.5l-5-29.8c4.4-2.2 7.5-6.8 7.5-12.1zm-11.9 3.2l-1.6.7-1.4-.7c-1-.5-2.1-1.7-2.1-3.2 0-2 1.6-3.5 3.5-3.5 2 0 3.5 1.6 3.5 3.5.2 1.6-.9 2.7-1.9 3.2z"></svg:path><svg:path d="M308.5 215.6h-79.8v-42.8c0-13.6 11.1-24.7 24.7-24.7 13.3 0 24.2 10.6 24.7 23.9 0 .2-.1.5-.1.8v16.9c0 2.5 2 4.4 4.4 4.4 2.5 0 4.4-2 4.4-4.4v-16.1c0-.2.1-.5.1-.7 0-18.4-15-33.4-33.4-33.4-18 0-32.6 14.2-33.4 32-.1.4-.2.9-.2 1.4v42.9h-19.4c-7.8 0-14.2 6.4-14.2 14.2v92.4c0 7.8 6.4 14.2 14.2 14.2h108.2c7.8 0 14.2-6.4 14.1-14.2V230c-.1-8-6.4-14.4-14.3-14.4zm5.5 106.5c0 3-2.5 5.5-5.5 5.5H200.4c-3 0-5.5-2.5-5.5-5.5v-92.4c0-3 2.5-5.5 5.5-5.5h108.1c3 0 5.5 2.5 5.5 5.5v92.4z"></svg:path><svg:path d="M256 435.9c-16.1 0-29.2 13.1-29.2 29.2s13.1 29.2 29.2 29.2 29.2-13.1 29.2-29.2-13.1-29.2-29.2-29.2zm0 48.6c-10.7 0-19.4-8.7-19.4-19.4s8.7-19.4 19.4-19.4 19.4 8.7 19.4 19.4-8.7 19.4-19.4 19.4z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 512 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubNavigationToggleMobileSignOSvgComponent {
}

@NgModule({
  declarations: [
    SubNavigationToggleMobileSignOSvgComponent,
  ],
  exports: [
    SubNavigationToggleMobileSignOSvgComponent,
  ],
})
export class SubNavigationToggleMobileSignOSvgModule {}
