import {bigConstructor as Big, bigConstructor} from '../base/big-utils';
import {getInternalValue} from '../base/businesstype';

import {Decimal, wrapBig} from './businesstype';

/**
 * Rounding modes to use for the `DecimalUtils.round` function
 */
export const enum RoundMode {
  // This enum should track the Big.RM configuration: http://mikemcl.github.io/big.js/#rm
  // We don't expose @types/big.js's RoundingMode enum directly because we don't want consumers to
  // depend on internals of big.js.

  /**
   * Always round towards zero (i.e. truncate)
   *
   * e.g.
   * - 2.4 -> 2
   * - 2.5 -> 2
   * - 2.6 -> 2
   * - 3.5 -> 3
   */
  TowardsZero = 0,

  /**
   * Round values regularly, but round the middle point away from zero
   *
   * e.g.
   * - 2.4 -> 2
   * - 2.5 -> 3
   * - 2.6 -> 3
   * - 3.5 -> 4
   */
  HalfAwayFromZero = 1,

  /**
   * Round values regularly, but round the middle point towards the even neighbour
   *
   * e.g.
   * - 2.4 -> 2
   * - 2.5 -> 2
   * - 2.6 -> 3
   * - 3.5 -> 4
   */
  HalfEven = 2,

  /**
   * Always round away from zero
   *
   * e.g.
   * - 2.4 -> 3
   * - 2.5 -> 3
   * - 2.6 -> 3
   * - 3.5 -> 4
   */
  AwayFromZero = 3,
}

const ZERO = new Decimal('0');

/**
 * Utility functions to manipulate Decimal instances
 */
// @dynamic - see https://github.com/angular/angular/issues/19698
export class DecimalUtils {
  /**
   * A Decimal with value `0`
   */
  public static readonly ZERO = ZERO;

  /**
   * A Decimal with value `1`
   */
  public static readonly ONE = new Decimal('1');

  /**
   * Check whether a Decimal is positive or not.
   *
   * A positive number is greater than zero.
   *
   * @param decimal The decimal to check
   */
  public static isPositive(decimal: Decimal) {
    return this.gt(decimal, ZERO);
  }

  /**
   * Check whether a Decimal is negative or not.
   *
   * A negative number is less than zero.
   *
   * @param decimal The decimal to check
   */
  public static isNegative(decimal: Decimal) {
    return this.lt(decimal, ZERO);
  }

  /**
   * Return a Decimal with the absolute value of the given decimal
   *
   * @param decimal The decimal whose absolute value to return
   */
  public static abs(decimal: Decimal): Decimal {
    return wrapBig(getInternalValue(decimal).abs());
  }

  /**
   * Return a Decimal as the result of dividing the given dividend by the divisor
   *
   * @param dividend The dividend (also known as the enumerator in a fraction)
   * @param divisor The divisor (also known as the numerator in a fraction)
   */
  public static div(dividend: Decimal, divisor: Decimal): Decimal {
    const divisorValue = getInternalValue(divisor);
    if (divisorValue.eq(0)) {
      throw Error('Infinity BigError: Divisor parameter should be different from 0.');
    }
    return wrapBig(getInternalValue(dividend).div(divisorValue));
  }

  /**
   * Return whether the two given Decimals has the same internal value
   *
   * @param firstDecimal First Decimal to compare
   * @param secondDecimal Second Decimal to compare
   */
  public static eq(firstDecimal: Decimal, secondDecimal: Decimal): boolean {
    return getInternalValue(firstDecimal).eq(getInternalValue(secondDecimal));
  }

  /**
   * Return a Decimal rounded down (floor) with a specific number of decimals
   *
   * @param decimal Decimal to round
   * @param numberOfDecimals Number of decimals after the decimal point to keep, defaults to zero (i.e. by default this function returns an integer)
   */
  public static floor(decimal: Decimal, numberOfDecimals = 0): Decimal {
    return this.round(
      decimal,
      numberOfDecimals,
      this.isPositive(decimal) ? RoundMode.TowardsZero : RoundMode.AwayFromZero,
    );
  }

  /**
   * Return whether the given value is greater than the given threshold
   *
   * @param value Decimal used in the operation that should be greater
   * @param threshold Decimal used in the operation
   */
  public static gt(value: Decimal, threshold: Decimal): boolean {
    return getInternalValue(value).gt(getInternalValue(threshold));
  }

  /**
   * Return whether the given value is greater or equal than the given threshold
   *
   * @param value The value to compare to a threshold
   * @param threshold The threshold to compare the value
   */
  public static gte(value: Decimal, threshold: Decimal): boolean {
    return getInternalValue(value).gte(getInternalValue(threshold));
  }

  /**
   * Return whether the given value is lower than the given threshold
   *
   * @param value The value to compare to a threshold
   * @param threshold The threshold to compare the value
   */
  public static lt(value: Decimal, threshold: Decimal): boolean {
    return getInternalValue(value).lt(getInternalValue(threshold));
  }

  /**
   * Return whether the given value is lower or equal than the given threshold
   *
   * @param value The value to compare to a threshold
   * @param threshold The threshold to compare the value
   */
  public static lte(value: Decimal, threshold: Decimal): boolean {
    return getInternalValue(value).lte(getInternalValue(threshold));
  }

  /**
   * Return the Decimal result of substracting the subtrahends from the minuend
   *
   * @param minuend Decimal used in the operation as 'minuend'
   * @param subtrahends Decimal used in the operation as 'subtrahend'
   */
  public static minus(minuend: Decimal, ...subtrahends: Decimal[]): Decimal {
    return wrapBig(
      subtrahends.reduce(
        (intermediaryValue, subtrahend) => intermediaryValue.minus(getInternalValue(subtrahend)),
        getInternalValue(minuend),
      ),
    );
  }

  /**s
   * Return the Decimal with the opposite value of the decimal parameter
   *
   * @param value The value to negate
   */
  public static neg(value: Decimal): Decimal {
    return wrapBig(getInternalValue(value).times(-1));
  }

  /**
   * Return the Decimal result of adding the decimals passed as parameters
   *
   * @param decimalsToAdd Decimals that we want to add
   */
  public static plus(...decimalsToAdd: Decimal[]): Decimal {
    return wrapBig(
      decimalsToAdd.reduce(
        (intermediaryValue, decimalToAdd) => intermediaryValue.plus(getInternalValue(decimalToAdd)),
        new Big(0),
      ),
    );
  }

  /**
   * Return the Decimal value of a multiplying the two factors
   *
   * @param factor1 The first factor
   * @param factor2 The second factor
   */
  public static times(factor1: Decimal, factor2: Decimal): Decimal {
    return wrapBig(getInternalValue(factor1).times(getInternalValue(factor2)));
  }

  /**
   * Return a string representing the value with a fixed number of digits after the decimal point
   *
   * If the value doesn't have enough digits after the decimal point, zeros are added at the end. If
   * the value has too many digits, the value is rounded using `RoundingMode.HalfAwayFromZero`.
   *
   * @param decimal          Decimal used in the operation
   * @param numberOfDecimals Number of digits that the decimal will have, defaults to zero (i.e. print as integer)
   */
  public static toFixed(decimal: Decimal, numberOfDecimals = 0): string {
    return getInternalValue(decimal).toFixed(numberOfDecimals);
  }

  /**
   * Return a string with the Decimal with the given number of significant digits
   *
   * If the value doesn't have enough significant digits, zeros are appended. If the value has too
   * many significant digits, the resulting string may use exponential notation.
   *
   * If the significant digits are not specified, the number is stringified without being modified.
   *
   * @param value           Decimal used in the operation
   * @param significantDigits Number of significant digits used to display the value
   */
  public static toPrecision(value: Decimal, significantDigits?: number): string {
    return getInternalValue(value).toPrecision(significantDigits);
  }

  /**
   * Return a string with the Decimal with the given number of significant digits in exponential
   * notation
   *
   * This is identical to `toPrecision` except it always returns numbers in exponential notation,
   * e.g. `3e-1` or `5.204e+0`, and the number of significant digits counts the number of decimal
   * significant digits, i.e. the number of significant digits in `5.204e+0` is 3 not 4. In practice
   * this means you need to pass 1 less as significantDigits parameter.
   *
   * @param value           Decimal used in the operation
   * @param significantDigits Number of significant _decimal_ digits used to display the value
   */
  public static toExponential(value: Decimal, significantDigits?: number): string {
    return getInternalValue(value).toExponential(significantDigits);
  }

  /**
   * Return the value of the given safe integer decimal
   *
   * A value is considered safe if it lies between `Number.MIN_SAFE_INTEGER` and `Number.MAX_SAFE_INTEGER`.
   *
   * @param value A Decimal representing a safe integer
   * @throws If the given value is not an integer or if it lies outside the safe range
   */
  public static asInteger(value: Decimal): number {
    const internalValue = getInternalValue(value);

    if (internalValue.lt(Number.MIN_SAFE_INTEGER) || internalValue.gt(Number.MAX_SAFE_INTEGER)) {
      throw new Error(
        `Cannot get decimal "${internalValue}" as integer, it lies outside the safe range`,
      );
    }

    if (!internalValue.mod(1).eq(0)) {
      throw new Error(`Cannot get non-integer decimal "${internalValue}" as integer`);
    }

    return parseInt(internalValue.toFixed(), 10);
  }

  /**
   * Transform the given safe integer into a Decimal
   *
   * An integer is considered safe if it lies between `Number.MIN_SAFE_INTEGER` and `Number.MAX_SAFE_INTEGER`.
   *
   * @param value A safe integer to make into a Decimal
   * @throws If the given value is not an integer or if it lies outside the safe range
   */
  public static fromInteger(value: number): Decimal {
    if (value < Number.MIN_SAFE_INTEGER || value > Number.MAX_SAFE_INTEGER) {
      throw new Error(
        `Cannot transform number "${value}" to decimal, it lies outside the safe range`,
      );
    }

    if (Number.isNaN(value) || !Number.isInteger(value)) {
      throw new Error(`Cannot make non-integer number "${value}" into a decimal`);
    }

    return wrapBig(bigConstructor(value));
  }

  /**
   * Return a Decimal with the given number of decimals and the specified round mode
   *
   * @param decimal           Decimal used in the operation
   * @param numberOfDecimals  Number of digits after the decimal point to keep, defaults to zero (i.e. by default this function returns an integer)
   * @param roundMode         Round mode, by default this will round regularly with the midpoint away from zero
   */
  public static round(decimal: Decimal, numberOfDecimals?: number, roundMode?: RoundMode): Decimal;
  /**
   * Return a Decimal with the given number of decimals and the specified round mode
   *
   * @param decimal           Decimal used in the operation
   * @param numberOfDecimals  Number of digits after the decimal point to keep, defaults to zero (i.e. by default this function returns an integer)
   * @param roundMode         Round mode, by default this will round regularly with the midpoint away from zero
   * @deprecated Use round with a RoundMode instead
   */
  public static round(
    decimal: Decimal,
    numberOfDecimals?: number,
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    roundMode?: 0 | 1 | 2 | 3,
  ): Decimal;
  public static round(
    decimal: Decimal,
    numberOfDecimals = 0,
    roundMode = RoundMode.HalfAwayFromZero,
  ): Decimal {
    return wrapBig(getInternalValue(decimal).round(numberOfDecimals, roundMode as number));
  }

  /**
   * Return a Decimal rounded up (ceil) with a specific number of decimals
   *
   * @param decimal           Decimal to ceil
   * @param numberOfDecimals  Number of decimals after the decimal point to keep, defaults to zero (i.e. by default this function returns an integer)
   */
  public static ceil(decimal: Decimal, numberOfDecimals = 0): Decimal {
    return this.round(
      decimal,
      numberOfDecimals,
      this.isPositive(decimal) ? RoundMode.AwayFromZero : RoundMode.TowardsZero,
    );
  }

  /**
   * Return the square root Decimal of the given input
   *
   * @param value The value to get the squre root from
   */
  public static sqrt(value: Decimal): Decimal {
    return wrapBig(getInternalValue(value).sqrt());
  }

  /**
   * Returns the Decimal with the given base raised to the specific exponent
   *
   * @param base The value to raise to the given power
   * @param exponent The power to raise the base value to
   */
  public static pow(base: Decimal, exponent: number): Decimal {
    return wrapBig(getInternalValue(base).pow(exponent));
  }

  /**
   * Return the first Decimal representing the minimum value inside the parameters list
   *
   * @param decimalsToCompare Array with the decimals to compare
   */
  public static min(...decimalsToCompare: Decimal[]): Decimal {
    return decimalsToCompare.reduce((min, current) => {
      return this.lt(current, min) ? current : min;
    });
  }

  /**
   * Return the first Decimal representing the maximum value inside the parameters list
   *
   * @param decimalsToCompare Array with the decimals to compare
   */
  public static max(...decimalsToCompare: Decimal[]): Decimal {
    return decimalsToCompare.reduce((max, current) => {
      return this.gt(current, max) ? current : max;
    });
  }

  /**
   * Return a Decimal representing the integer remainder when dividing the value by the divisor
   *
   * This is identical to JavaScript's `%` modulo operator, except this function uses infinite
   * precision numbers.
   *
   * The returned number will have the same sign as the given value.
   *
   * @param value The value for which to calculate the modulo
   * @param divisor A positive number
   */
  public static mod(value: Decimal, divisor: number | Decimal): Decimal {
    const div = Decimal.isDecimal(divisor) ? getInternalValue(divisor) : divisor;
    return wrapBig(getInternalValue(value).mod(div));
  }
}
