import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  forwardRef,
  OnInit,
  Renderer2,
} from '@angular/core';
import {UntilDestroy} from '@atlas-angular/rxjs';

import {FormElementComponent, labelContainerServiceFactory} from '../form-element.component';
import {LabelContainerService} from '../label-container.service';
import {ValidationMessageExtractor} from '../validation-message-extractor.service';
import {allowedInWideFormElementToken} from './form-element-wide.providers';

@Component({
  selector: 'maia-text-area-form-element-wide',
  templateUrl: '../form-element.component.html',
  styleUrls: ['../form-element.component.scss', './form-element-wide.component.scss'],

  providers: [
    ValidationMessageExtractor,
    {
      provide: LabelContainerService,
      useFactory: labelContainerServiceFactory,
      deps: [forwardRef(() => FormElementComponent), Renderer2],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class FormElementWideComponent extends FormElementComponent implements OnInit {
  @ContentChild(allowedInWideFormElementToken, {static: true})
  public allowedToken: boolean;

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.allowedToken) {
      throw new Error('maia-text-area-form-element-wide should contain a maiaTextArea component.');
    }
  }
}
