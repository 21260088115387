import {Inject, ModuleWithProviders, NgModule, Optional} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {DeeplinkingComponent} from './deeplinking.component';
import {εDeeplinkingModule} from './deeplinking.module';
import {JsonType, εJSON_TYPE_TOKEN} from './type';

export const ROUTES: Routes = [
  {
    path: 'deeplinking',
    component: DeeplinkingComponent,
  },
];

@NgModule({
  imports: [εDeeplinkingModule, RouterModule.forChild(ROUTES)],
  declarations: [DeeplinkingComponent],
})
export class DeeplinkingModule {
  public static forRoot(type = JsonType.PALLAS_TVE_JSON): ModuleWithProviders<DeeplinkingModule> {
    return {
      ngModule: DeeplinkingModule,
      providers: [{provide: εJSON_TYPE_TOKEN, useValue: type}],
    };
  }
  public constructor(@Optional() @Inject(εJSON_TYPE_TOKEN) acceptHeader?: JsonType) {
    if (acceptHeader == null) {
      throw new Error(
        'Import DeeplinkingModule.forRoot() in AppModule, not DeeplinkingModule directly',
      );
    }
  }
}
