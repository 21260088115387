import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SignerScanMinusSvgModule
 */
@Component({
  selector: 'svg[ivyHermesSignerScanMinus]',
  template: '<svg:path d="M426 0H85C37.9 0 0 37.9 0 85.2v342.7c0 47.2 37.9 85.2 85 84.2h342c47.1 0 85-37 85-85.2V85.2C512 38 474.1 0 426 0zm18.4 307.8H68.6V205.2h375.8v102.6z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 512 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignerScanMinusSvgComponent {
}

@NgModule({
  declarations: [
    SignerScanMinusSvgComponent,
  ],
  exports: [
    SignerScanMinusSvgComponent,
  ],
})
export class SignerScanMinusSvgModule {}
