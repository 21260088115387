import {BehaviorSubject, Observable} from 'rxjs';

/**
 * An observable that holds a collection
 *
 * The observable immediately emits its current value and it emits its new value whenever the value
 * changes. The emitted array is never edited, if the value changes a new array with the updated
 * value is emitted.
 */
export class CollectionSubject<T> extends Observable<readonly T[]> {
  private readonly source$: BehaviorSubject<T[]>;

  /**
   * Create a new collection subject with the given initial value
   *
   * @param initialValue The initial value for the collection, defaults to empty
   */
  public constructor(initialValue: T[] = []) {
    super(observer => this.source$.subscribe(observer));
    this.source$ = new BehaviorSubject<T[]>(Array.from(initialValue));
  }

  /**
   * Add the given item to the collection while the returned observable is subscribed to
   *
   * @param item The item to add to the collection
   * @returns An observable that adds the given item to the collection while the observable is subscribed to
   */
  public addItem$(item: T): Observable<never> {
    return new Observable(() => {
      this.source$.next([...this.source$.value, item]);

      return () => {
        const registeredItems = Array.from(this.source$.value);
        registeredItems.splice(registeredItems.indexOf(item), 1);
        this.source$.next(registeredItems);
      };
    });
  }
}
