import {Directive, forwardRef} from '@angular/core';

import {BifrostHeaderVariant} from './header-variant';

@Directive({
  selector: 'bifrost-header[classicPublic], header[classicPublic]',
  providers: [
    {
      provide: BifrostHeaderVariant,
      useExisting: forwardRef(() => HeaderVariantClassicPublicDirective),
    },
  ],
})
export class HeaderVariantClassicPublicDirective implements BifrostHeaderVariant {
  public isPublic = true;
  public isCollapsible = true;
  public allowBackButton = true;
  public hasBackground = false;
}
