import {
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {DocumentRef} from '@atlas-angular/cdk/globals';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {ModalResolution} from '@maia/modals';
import {defer, fromEvent, Observable, Subject, timer} from 'rxjs';
import {filter, finalize, flatMap, map, share, takeUntil} from 'rxjs/operators';

import {DropZoneAwareFile} from '../file-uploader/file-uploader.service';

import {
  DIALOG_FILE_SELECTOR_SERVICE,
  DialogFileSelectorServiceInterface,
  DialogResolution,
} from './dialog-file-selector.service';
import {PHOTO_SELECTOR_SERVICE, PhotoSelectorServiceInterface} from './photo-selector.service';

export const enum DragDropEvents {
  ENTER,
  LEAVE,
  OVER,
  DROP,
}

export interface DragZoneEvent {
  type: DragDropEvents;
}

export interface DropZoneEvent extends DragZoneEvent {
  files: DropZoneAwareFile[];
}

@Directive({
  selector: '[hermesFileDrop]',
})
@UntilDestroy()
export class FileDropDirective implements OnDestroy {
  @Output()
  public dropZone: EventEmitter<DropZoneEvent> = new EventEmitter();

  @Output()
  public dragZone: EventEmitter<DragZoneEvent> = new EventEmitter();

  private status: DragDropEvents;

  private readonly input: HTMLInputElement = this.renderer.createElement('input');

  private readonly closeDialog = new Subject<boolean>();

  public constructor(
    @Inject(DIALOG_FILE_SELECTOR_SERVICE)
    private readonly dialogFileSelectorService: DialogFileSelectorServiceInterface,
    @Inject(PHOTO_SELECTOR_SERVICE)
    private readonly photoSelectorService: PhotoSelectorServiceInterface,
    private readonly renderer: Renderer2,
    private readonly documentRef: DocumentRef,
  ) {
    this.input.setAttribute('type', 'file');
    this.input.setAttribute('accept', '*');
    this.renderer.setStyle(this.input, 'display', 'none');
    this.renderer.appendChild(this.documentRef.document.body, this.input);
  }

  public ngOnDestroy() {
    this.renderer.removeChild(this.documentRef.document.body, this.input);
  }

  public selectFiles(): Observable<File[]> {
    // when the user cancels a file selection
    // a change event is not triggered so we send a closeDialog signal
    // to  avoid subscribers listen to different file system dialogs
    return defer(() => {
      const change$ = fromEvent(this.input, 'change').pipe(
        map(() =>
          this.input.files && this.input.files.length > 0 ? Array.from(this.input.files) : [],
        ),
        takeUntilDestroyed(this),
        takeUntil(this.closeDialog),
        finalize(() => {
          this.input.value = '';
        }),
      );
      // IE and Safari don't display the system dialog on input.click until next macrotask
      timer(0).subscribe(() => {
        this.input.click();
      });
      return change$;
    });
  }

  @Input('multiple')
  @coerceBooleanPrimitive()
  public set isMultiple(multi: boolean) {
    if (multi) {
      this.input.setAttribute('multiple', '');
    } else {
      this.input.removeAttribute('multiple');
    }
  }

  @HostListener('dragenter')
  public onDragEnter() {
    if (this.status !== DragDropEvents.ENTER) {
      this.status = DragDropEvents.ENTER;
      this.dragZone.emit({type: DragDropEvents.ENTER});
    }
    return false;
  }

  @HostListener('dragleave')
  public onDragLeave() {
    if (this.status !== DragDropEvents.LEAVE) {
      this.status = DragDropEvents.LEAVE;
      this.dragZone.emit({type: DragDropEvents.LEAVE});
    }
    return false;
  }

  @HostListener('dragover')
  public onDragOver() {
    if (this.status !== DragDropEvents.OVER) {
      this.status = DragDropEvents.OVER;
      this.dragZone.emit({type: DragDropEvents.OVER});
    }
    return false;
  }

  // mobile browsers don't support well DropEvent so basically
  // we cannot use DropEvent type
  // https://developer.mozilla.org/en-US/docs/Web/API/DragEvent/dataTransfer
  @HostListener('drop', ['$event'])
  public onDrop($event: Event) {
    if (this.status !== DragDropEvents.DROP) {
      const {files} = ($event as any).dataTransfer;
      this.status = DragDropEvents.DROP;
      this.dropZone.emit({type: DragDropEvents.DROP, files: Array.from(files)});
      this.dragZone.emit({type: DragDropEvents.LEAVE});
    }
    return false;
  }

  @HostListener('click')
  public handleDialog() {
    const dialog$ = this.dialogFileSelectorService.showDialog().pipe(share());
    const file$ = dialog$.pipe(
      filter(
        result =>
          result.resolution === ModalResolution.CONFIRMED &&
          result.result === DialogResolution.FILE_DIALOG,
      ),
    );
    const photo$ = dialog$.pipe(
      filter(
        result =>
          result.resolution === ModalResolution.CONFIRMED &&
          result.result === DialogResolution.PHOTO_DIALOG,
      ),
    );
    file$
      .pipe(
        flatMap(() => this.openFileDialog()),
        takeUntilDestroyed(this),
      )
      .subscribe(files => {
        this.dropZone.emit({type: DragDropEvents.DROP, files});
      });
    photo$
      .pipe(
        flatMap(() => this.photoSelectorService.takePhoto()),
        takeUntilDestroyed(this),
      )
      .subscribe(file => {
        this.dropZone.emit({type: DragDropEvents.DROP, files: [file]});
      });
  }

  private openFileDialog(): Observable<DropZoneAwareFile[]> {
    return defer(() => {
      this.closeDialog.next();
      return this.selectFiles().pipe(filter(files => files.length > 0));
    });
  }
}
