import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';

/**
 * @ngModule VetoModule
 */
@Component({
  selector: 'hermes-veto-modal',
  templateUrl: './veto-modal.component.html',
  styleUrls: ['./veto-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VetoModalComponent extends ModalContentComponent<void, {customBody?: string}> {
  public customBody?: string;
  public constructor(public control: ModalControl<void, {customBody?: string}>) {
    super();
    this.customBody = this.control.input.customBody;
  }

  /**
   * Confirm route deactivation
   */
  public continue(): void {
    this.control.confirm(undefined);
  }
}
