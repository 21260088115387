/**
 * A captured input, beit a real input or a fake input
 */
export abstract class CapturedInput {
  /**
   * The native element of this captured input
   */
  public abstract readonly element: HTMLElement;

  public abstract setDisabledState(disabled: boolean): void;
}
