import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 * @ngModule EmptyStateModule
 */
@Component({
  selector: 'maia-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateComponent {}
