import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Injector,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {
  DropdownHost,
  DropdownHostFactory,
  DropdownOptions,
  DropdownPosition,
  DropdownTemplateContext,
} from '@maia/dropdowns';
import {ModalResult} from '@maia/modals';

import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

/**
 * Simple select component, allowing users to pick an item from a list _outside_ of a form.
 *
 * If you want to add a select to a form, use `<maia-input-select />` instead.
 *
 * Example usage:
 *
 * ```html
 * <maia-select [(value)]="myValue">
 *   <maia-option *ngFor="let option of options" [value]="option">
 *     {{ option | atlasText }}
 *   </maia-option>
 * </maia-select>
 * ```
 *
 * This component follows the WAI ARIA guidelines for a collapsible listbox, it is fully accessible
 * using keyboard and screenreader.
 *
 * @see [WAI ARIA collapsible listbox](https://www.w3.org/TR/wai-aria-practices-1.1/examples/listbox/listbox-collapsible.html)
 * @ngModule SelectDropdownModule
 */
@Component({
  selector: 'maia-dropdown-select',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class SelectDropdownComponent<T> {
  private _disabled = false;

  private _withoutBackdrop = false;
  private _withoutReferenceOverlapping = false;

  private _isOpen = false;

  private _asPlaceholder = false;

  private readonly _dropdownHost: DropdownHost;

  @Output()
  public readonly didOpen = new EventEmitter<void>();

  private readonly willClose = new Subject<void>();

  @Output()
  public readonly didClose = new EventEmitter<void>();

  public constructor(
    private readonly _cdr: ChangeDetectorRef,
    dropdownHostFactory: DropdownHostFactory,
    elementRef: ElementRef,
    injector: Injector,
  ) {
    this._dropdownHost = dropdownHostFactory.createHost(elementRef, injector);
  }

  /**
   * Whether to style the dropdown as if the content is a placeholder
   */
  @Input()
  @HostBinding('class.maia-select-dropdown--as-placeholder')
  public get asPlaceholder(): boolean {
    return this._asPlaceholder;
  }

  public set asPlaceholder(asPlaceholder: boolean) {
    this._asPlaceholder = coerceBooleanProperty(asPlaceholder);
  }

  /**
   * Whether to create a backdrop for the dropdown or not
   *
   * Use this option with caution, as it means the user can keep interacting with the rest of the
   * page. You should ensure that the dropdown closes if the user interacts with another part of the
   * page.
   */
  @Input()
  public get withoutBackdrop(): boolean {
    return this._withoutBackdrop;
  }

  public set withoutBackdrop(withoutBackdrop: boolean) {
    this._withoutBackdrop = coerceBooleanProperty(withoutBackdrop);
  }

  @Input()
  public get withoutReferenceOverlapping(): boolean {
    return this._withoutReferenceOverlapping;
  }

  public set withoutReferenceOverlapping(withoutReferenceOverlapping: boolean) {
    this._withoutReferenceOverlapping = coerceBooleanProperty(withoutReferenceOverlapping);
  }

  /**
   * Whether the select is disabled or not
   */
  @Input()
  @HostBinding('class.maia-dropdown-select--disabled')
  @HostBinding('class.p-maia-dropdown-select--disabled')
  public get disabled(): boolean {
    return this._disabled;
  }

  public set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);

    if (this._disabled) {
      this.close();
    }
  }

  @HostBinding('class.maia-dropdown-select--open')
  public get isOpen(): boolean {
    return this._isOpen;
  }

  public close(): void {
    if (this._isOpen) {
      this.willClose.next();
    }
  }

  public open(
    dropdownContent: TemplateRef<DropdownTemplateContext<T>>,
    smallVisualisation?: DropdownOptions['smallDropdownVisualisation'],
  ): Observable<ModalResult<T>> {
    return new Observable(observer => {
      this.willClose.next();

      if (this.disabled) {
        observer.complete();
        return;
      }

      this._isOpen = true;
      this._cdr.markForCheck();
      this.didOpen.next();

      observer.add(() => {
        this._isOpen = false;
        this._cdr.markForCheck();
        this.didClose.next();
      });

      return this._dropdownHost
        .prepareTemplate<T>(dropdownContent, {
          position: DropdownPosition.BOTTOM_ALIGNED,
          withoutBackdrop: this._withoutBackdrop,
          smallDropdownVisualisation: smallVisualisation,
          withoutReferenceOverlapping: this._withoutReferenceOverlapping,
        })
        .pipe(takeUntilDestroyed(this), takeUntil(this.willClose))
        .subscribe(observer);
    });
  }
}
