import {Inject, Injectable, Optional} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {distinctUntilChanged, map, shareReplay, startWith} from 'rxjs/operators';

import {
  AUTHORISATION_PROVIDER_TOKEN,
  AuthorisationFunction,
  AuthorisedFunctionsProvider,
} from './authorisation-provider';

export function isFunction(
  identifier: AuthorisationFunction | string,
): identifier is AuthorisationFunction {
  return typeof identifier !== 'string';
}

@Injectable({
  providedIn: 'root',
})
export class AuthorisationService {
  private readonly _authorizedFunctions: Observable<Map<string, AuthorisationFunction>>;

  public constructor(
    @Inject(AUTHORISATION_PROVIDER_TOKEN)
    @Optional()
    authorisedFunctionsProviders?: AuthorisedFunctionsProvider[],
  ) {
    if (authorisedFunctionsProviders != null) {
      this._authorizedFunctions = combineLatest(
        authorisedFunctionsProviders.map(
          provider =>
            provider.authorisedFunctions().pipe(
              map(values => {
                if (!isFunction(values[0])) {
                  return (values as string[]).map((stringVal: string) => {
                    return {functionNumber: stringVal, questionaireC: false, actionCode: ''};
                  });
                }
                return values as AuthorisationFunction[];
              }),
            ),
          startWith([] as AuthorisationFunction[]),
        ),
      ).pipe(
        map(values => {
          const concattedList = values.reduce(
            (a: AuthorisationFunction[], b: AuthorisationFunction[]) => a.concat(b),
          );
          const result = concattedList.map((func): [string, AuthorisationFunction] => [
            func.functionNumber,
            {
              functionNumber: func.functionNumber,
              questionaireC: func.questionaireC,
              actionCode: func.actionCode,
            },
          ]);
          return new Map<string, AuthorisationFunction>(result);
        }),
        shareReplay(1),
      );
    } else {
      this._authorizedFunctions = of(new Map());
    }
  }

  /**
   * Checks the list of function codes and returns whether the user has the supplied
   * functioncode.
   *
   * @param functionCode The functioncode which you want to verify the user has.
   * @returns an observable which emits true if the user has the functioncode.
   */
  public isUserAuthorised(functionCode: string): Observable<boolean> {
    return this.getFunction(functionCode).pipe(
      map(auhorisationFunction => auhorisationFunction != null),
      distinctUntilChanged(),
    );
  }

  /**
   * Checks the list of function codes and returns a function object if one is found
   *
   * @param functionCode The functionCode which you want to verify the user has.
   * @returns an observable which emits the Function object, if the user has the functioncode,
   * or null if no Function was found.
   */
  public getFunction(functionCode: string): Observable<AuthorisationFunction | null> {
    return this._authorizedFunctions.pipe(
      map(authorisedFunctions => {
        const foundFunction: AuthorisationFunction | undefined = authorisedFunctions.get(
          functionCode,
        );
        return foundFunction != undefined ? foundFunction : null;
      }),
      distinctUntilChanged(),
    );
  }
}
