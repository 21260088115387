import {PathIdentifier} from '@atlas/convertor';

export const HERMES_AUTHENTICATED_PATH_IDENTIFIER_TYPE = 'hermes-authenticated';
export const HERMES_PUBLIC_PATH_IDENTIFIER_TYPE = 'hermes-public';
export const HERMES_CONTEXTUAL_PATH_IDENTIFIER_TYPE = 'hermes-contextual';
export const HERMES_LOGON_PATH_IDENTIFIER_TYPE = 'hermes-logon';
export const HERMES_TOKEN_PATH_IDENTIFIER_TYPE = 'hermes-token';
export const HERMES_TRANSACTION_SIGNING_PATH_IDENTIFIER_TYPE = 'hermes-transaction-signing';

/**
 * MUST be used to connect to private endpoints
 */
export class HermesAuthenticatedPathIdentifier implements PathIdentifier {
  public type = HERMES_AUTHENTICATED_PATH_IDENTIFIER_TYPE;

  public constructor(public service: string, public version?: string) {}
}

/**
 * MUST be used to connect to public endpoints
 */
export class HermesPublicPathIdentifier implements PathIdentifier {
  public type = HERMES_PUBLIC_PATH_IDENTIFIER_TYPE;

  public constructor(public service: string, public version?: string) {}
}

export class HermesLogonPathIdentifier implements PathIdentifier {
  public type = HERMES_LOGON_PATH_IDENTIFIER_TYPE;

  public constructor(public service: string, public version?: string) {}
}

export class HermesTokenPathIdentifier implements PathIdentifier {
  public type = HERMES_TOKEN_PATH_IDENTIFIER_TYPE;

  public constructor(public service: string, public version?: string) {}
}

export class HermesTransactionSigningPathIdentifier implements PathIdentifier {
  public type = HERMES_TRANSACTION_SIGNING_PATH_IDENTIFIER_TYPE;

  public constructor(public service: string, public signStrategy: string, public signId: string) {}
}

/**
 * MUST be used to connect to public or authenticated endpoints
 * based on the current state of the application
 */
export class HermesContextualPathIdentifier implements PathIdentifier {
  public type = HERMES_CONTEXTUAL_PATH_IDENTIFIER_TYPE;
  public constructor(public service: string, public version?: string) {}
}

export function isAuthenticatedHermesCall(
  identifier: PathIdentifier,
): identifier is HermesAuthenticatedPathIdentifier {
  return identifier.type === HERMES_AUTHENTICATED_PATH_IDENTIFIER_TYPE;
}

export function isContextualHermesCall(
  identifier: PathIdentifier,
): identifier is HermesContextualPathIdentifier {
  return identifier.type === HERMES_CONTEXTUAL_PATH_IDENTIFIER_TYPE;
}

export function isLogonHermesCall(
  identifier: PathIdentifier,
): identifier is HermesLogonPathIdentifier {
  return identifier.type === HERMES_LOGON_PATH_IDENTIFIER_TYPE;
}

export function isPublicHermesCall(
  identifier: PathIdentifier,
): identifier is HermesPublicPathIdentifier {
  return identifier.type === HERMES_PUBLIC_PATH_IDENTIFIER_TYPE;
}

export function isTokenHermesCall(
  identifier: PathIdentifier,
): identifier is HermesTokenPathIdentifier {
  return identifier.type === HERMES_TOKEN_PATH_IDENTIFIER_TYPE;
}

export function isTransactionSigningHermesCall(
  identifier: PathIdentifier,
): identifier is HermesTransactionSigningPathIdentifier {
  return identifier.type === HERMES_TRANSACTION_SIGNING_PATH_IDENTIFIER_TYPE;
}
