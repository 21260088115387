import {Inject, Injectable, OnDestroy, Optional} from '@angular/core';
import {Connector} from '@atlas-angular/connector';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Boolean} from '@atlas/businesstypes';
import {DownloadManager, UniformTypeIdentifier} from '@hermes/open-resources';
import {
  BehaviorSubject,
  concat,
  defer,
  EMPTY,
  forkJoin,
  from,
  Observable,
  of,
  OperatorFunction,
  Subject,
  throwError,
} from 'rxjs';
import {
  endWith,
  filter,
  finalize,
  flatMap,
  last,
  map,
  mapTo,
  mergeMap,
  mergeMapTo,
  reduce,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {FileUploaderProgressService} from '../shared/file-uploader-progress.service';
import {
  FILE_UPLOADER_SETTINGS,
  εFileUploaderSettingsStrict,
} from '../shared/file-uploader-settings';

import {
  FILE_UPLOADER_CALL_FACTORY,
  FileUploaderCallFactoryInterface,
  Reference,
} from './file-uploader.call.factory';

export interface DropZoneAwareFile extends File {
  dropZoneId?: string;
  optional?: boolean;
}

export interface DropZoneAwareReference extends Reference {
  dropZoneId?: string;
  optional?: boolean;
  fileSize?: number;
}

const UPLOAD_VALIDATION_TYPE = 'pre-upload-validation-type';

export enum UPLOAD_VALIDATION_ERROR_ENUM {
  ZERO_SIZE,
  NON_SUPPORTED_MULTI_UPLOAD,
  MAX_NUMBER_OF_FILES_EXCEEDED,
  TOO_LARGE_SIZE,
  TOO_LARGE_TOTAL_SIZE,
  INCORRECT_MIME_TYPE,
}

export class UploadValidationError extends Error {
  public type = UPLOAD_VALIDATION_TYPE;
  public errorType: UPLOAD_VALIDATION_ERROR_ENUM;

  public constructor(errorType: UPLOAD_VALIDATION_ERROR_ENUM) {
    super();
    this.errorType = errorType;
  }
}

export function isUploadValidationError(
  identifier: UploadValidationError,
): identifier is UploadValidationError {
  return identifier.type === UPLOAD_VALIDATION_TYPE;
}

function validateNumberOfFiles(
  settings: εFileUploaderSettingsStrict,
  uploadedFiles: DropZoneAwareReference[],
  uploadingFiles: DropZoneAwareFile[],
): Observable<void> {
  return defer(() => {
    if (!settings.allowMultipleUpload && uploadingFiles.length > 1) {
      return throwError(
        new UploadValidationError(UPLOAD_VALIDATION_ERROR_ENUM.NON_SUPPORTED_MULTI_UPLOAD),
      );
    }

    if (uploadingFiles.length + uploadedFiles.length > settings.maxNumberOfFiles) {
      return throwError(
        new UploadValidationError(UPLOAD_VALIDATION_ERROR_ENUM.MAX_NUMBER_OF_FILES_EXCEEDED),
      );
    }

    return of(undefined);
  });
}

function calculateTotalSize<T>(getSize: (value: T) => number): OperatorFunction<T, number> {
  return reduce<T, number>((acc, value) => acc + getSize(value), 0);
}

function validateFileSizes(
  settings: εFileUploaderSettingsStrict,
  uploadedFiles: DropZoneAwareReference[],
  uploadingFiles: DropZoneAwareFile[],
): Observable<void> {
  const totalFileSize$ = forkJoin(
    from(uploadedFiles).pipe(calculateTotalSize(ref => ref.fileSize || 0)),
    from(uploadingFiles).pipe(calculateTotalSize(file => file.size)),
  ).pipe(
    map(
      ([totalUploadedFileSize, totalUploadingFileSize]) =>
        totalUploadedFileSize + totalUploadingFileSize,
    ),
  );

  return from(uploadingFiles).pipe(
    mergeMap(file => {
      if (file.size > settings.maxFileSize) {
        return throwError(new UploadValidationError(UPLOAD_VALIDATION_ERROR_ENUM.TOO_LARGE_SIZE));
      }

      if (file.size === 0) {
        return throwError(new UploadValidationError(UPLOAD_VALIDATION_ERROR_ENUM.ZERO_SIZE));
      }

      // If the browser fails to detect the mime type, `file.type` will be empty. In this case
      // we always consider the type valid, because we cannot be sure it's not.
      // Frontend validation only exists to enhance the user experience, not to actually
      // perform validation, so defaulting to valid makes a lot more sense than defaulting to
      // invalid.
      // The remote endpoint shouldn't be using the mime type the FE sends anyways, it does
      // its own mime type detection. It would otherwise be very easy to upload viruses and
      // worms.
      if (file.type && !file.type.match(settings.mimeTypes)) {
        return throwError(
          new UploadValidationError(UPLOAD_VALIDATION_ERROR_ENUM.INCORRECT_MIME_TYPE),
        );
      }

      return EMPTY;
    }),
    endWith(null),
    mergeMapTo(totalFileSize$),
    mergeMap(totalFileSize => {
      if (totalFileSize > settings.maxTotalFileSize) {
        return throwError(
          new UploadValidationError(UPLOAD_VALIDATION_ERROR_ENUM.TOO_LARGE_TOTAL_SIZE),
        );
      }

      return of(undefined);
    }),
  );
}

/**
 * Service responsible for backend communication when uploading, downloading, ... files
 */
@Injectable()
@UntilDestroy()
export class FileUploaderService implements OnDestroy {
  private readonly uploadingFiles = new BehaviorSubject<DropZoneAwareFile[]>([]);
  private readonly uploadedFiles = new BehaviorSubject<DropZoneAwareReference[]>(
    this.settings.initialReferences || [],
  );
  private readonly allowUpload = new BehaviorSubject<boolean>(false);
  private readonly cancelUpload$ = new Subject<File>();
  private readonly cancelEveryUpload$ = new Subject<boolean>();
  public readonly uploadingFiles$ = this.uploadingFiles.asObservable();
  public readonly uploadedFiles$ = this.uploadedFiles.asObservable();
  public readonly allowFinish$ = this.allowUpload.asObservable();
  public totalFileSize = 0;

  public constructor(
    private readonly connector: Connector,
    private readonly downloadManager: DownloadManager,
    @Inject(FILE_UPLOADER_CALL_FACTORY)
    private readonly callFactory: FileUploaderCallFactoryInterface,
    @Inject(FILE_UPLOADER_SETTINGS) public readonly settings: εFileUploaderSettingsStrict,
    @Optional()
    @Inject(FileUploaderProgressService)
    private readonly fileUploaderProgressService: FileUploaderProgressService,
  ) {
    this.uploadedFiles$.subscribe(references => {
      const requiredReferences = references.filter(
        (reference: DropZoneAwareReference) => !reference.optional,
      );
      this.allowUpload.next(
        this.settings.maxNumberOfFiles >= requiredReferences.length &&
          requiredReferences.length >= this.settings.minNumberOfFiles,
      );
      if (this.fileUploaderProgressService) {
        this.fileUploaderProgressService.setProgress(references);
      }
    });
    this.allowFinish$.subscribe(status => {
      if (this.fileUploaderProgressService) {
        this.fileUploaderProgressService.setCompletionStatus(status);
      }
    });
  }

  /**
   * validates that the files meet some preconditions
   * before sending to the storage system service
   *
   * @param uploadingFiles files to send to send to the storage service
   * @throw UploadValidationError
   */
  private validate(uploadingFiles: DropZoneAwareFile[]): Observable<void> {
    uploadingFiles = [...this.uploadingFiles.getValue(), ...uploadingFiles];

    return concat(
      validateNumberOfFiles(this.settings, this.uploadedFiles.getValue(), uploadingFiles),
      validateFileSizes(this.settings, this.uploadedFiles.getValue(), uploadingFiles),
    ).pipe(last());
  }

  /**
   * Uploads files to backend.
   * @param files
   */
  public upload(...files: DropZoneAwareFile[]): Observable<DropZoneAwareReference[]> {
    const formData = new FormData();
    // multiple files dropped in the same zone share the same zoneId so that's the reason to read
    // from index 0
    const dropZoneId = files.length > 0 ? files[0].dropZoneId : undefined;
    // multiple dropzones only allow a single upload so that's the reason to read from index 0
    const optional = files.length > 0 ? files[0].optional : false;
    const uploadedFiles = this.uploadedFiles.getValue().length;
    files.forEach((file, index) => {
      formData.append('file_index', `${index + uploadedFiles}_${file.name}`);
      formData.append('file', file, `${file.name}`);
    });
    const cancelFileUpload$ = this.cancelUpload$
      .asObservable()
      .pipe(filter(file => this.uploadingFiles.getValue().indexOf(file) !== -1));
    return of(files).pipe(
      flatMap(uploadingFiles => this.validate(uploadingFiles)),
      tap(() => {
        this.uploadingFiles.next([...this.uploadingFiles.getValue(), ...files]);
      }),
      flatMap(() =>
        this.connector.prepare(
          this.settings.fileUploadCall
            ? this.settings.fileUploadCall()
            : this.callFactory.getFileUploadCall(),
          formData,
        ),
      ),
      map(response => response.references),
      tap((references: Reference[]) => {
        this.uploadedFiles.next([
          ...this.uploadedFiles.getValue(),
          ...references.map((reference, index) =>
            Object.assign({}, reference, {dropZoneId, optional, fileSize: files[index].size}),
          ),
        ]);
      }),
      takeUntil(cancelFileUpload$),
      takeUntil(this.cancelEveryUpload$.asObservable()),
      takeUntilDestroyed(this),
      finalize(() => {
        const filteredCompletedFiles = this.uploadingFiles
          .getValue()
          .filter(file => files.indexOf(file) === -1);
        this.uploadingFiles.next(filteredCompletedFiles);
      }),
    );
  }

  public ngOnDestroy() {
    this.cancelAllUploads();
  }

  public cancelAllUploads() {
    this.cancelEveryUpload$.next();
  }

  public cancelUpload(file: File) {
    this.cancelUpload$.next(file);
  }

  /**
   * Downloads a just uploaded file. Note that backend should use Content-Disposition = attachment
   * for this to work.
   * @param reference
   */
  public download(reference: Reference): Observable<void> {
    const resourceCall = this.settings.fileDownloadResource
      ? this.settings.fileDownloadResource
      : this.callFactory.getFileDownloadResource;
    const resource = resourceCall(reference.type.asString() as UniformTypeIdentifier);
    return this.downloadManager.download(resource, {
      referenceId: reference.id,
      asAttachment: Boolean.TRUE,
    });
  }

  /**
   * Removes an uploaded file from backend.
   * @param id
   */
  public remove(reference: Reference): Observable<void> {
    const call = this.settings.fileDeletionCall
      ? this.settings.fileDeletionCall()
      : this.callFactory.getFileDeletionCall();
    const onDelete = tap(() => {
      const uploadedFiles = this.uploadedFiles.getValue();
      const foundFile = uploadedFiles.find(
        (uploadedReference: Reference) => uploadedReference.id === reference.id,
      );
      if (foundFile) {
        const deleteIndex = uploadedFiles.indexOf(foundFile);
        uploadedFiles.splice(deleteIndex, 1);
        this.uploadedFiles.next(uploadedFiles);
      }
    });
    if (call !== undefined) {
      return this.connector.prepare(call, reference).pipe(onDelete, mapTo(undefined));
    } else {
      return of([]).pipe(onDelete, mapTo(undefined));
    }
  }
}
