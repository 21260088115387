import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';
import {μTablesSvgModule} from '@maia/icons';

import {TableBodyComponent} from './table-body/table-body.component';
import {TableCellComponent} from './table-cell/table-cell.component';
import {TableFooterComponent} from './table-footer/table-footer.component';
import {TableHeaderComponent} from './table-header/table-header.component';
import {TableRowComponent} from './table-row/table-row.component';
import {OrderByPipe} from './table-row/table-row.pipe';
import {TableComponent} from './table/table.component';

@NgModule({
  imports: [BusinessTypesModule, μTablesSvgModule, CommonModule, ReactiveFormsModule],
  declarations: [
    OrderByPipe,
    TableBodyComponent,
    TableCellComponent,
    TableComponent,
    TableFooterComponent,
    TableHeaderComponent,
    TableRowComponent,
  ],
  exports: [
    OrderByPipe,
    TableBodyComponent,
    TableCellComponent,
    TableComponent,
    TableFooterComponent,
    TableHeaderComponent,
    TableRowComponent,
  ],
})
export class TablesModule {}
