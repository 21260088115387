import {Injectable, TemplateRef} from '@angular/core';
import {CollectionSubject} from '@atlas-angular/rxjs';
import {combineLatest, NEVER, Observable, OperatorFunction, pipe} from 'rxjs';
import {distinctUntilChanged, map, switchAll} from 'rxjs/operators';

export interface RegisteredTemplateConsumer {
  (template?: TemplateRef<TemplateContext>): Observable<never>;
}

export interface TemplateContext {
  readonly isInline: boolean;
}

interface RegisteredTemplate {
  template: TemplateRef<TemplateContext>;

  fallback: Observable<never>;
}

function lastElement<T>(): OperatorFunction<readonly T[], T | null> {
  return pipe(
    map(arr => (arr.length ? arr[arr.length - 1] : null)),
    distinctUntilChanged(),
  );
}

@Injectable()
export abstract class AbstractContainer {
  /**
   * The registered template(s) in this container.
   *
   * If more than one template is registered, the last registration will "win"
   * and be shown. This allows for behaviour where in a nested router a leaf
   * page can overrule the registered template of a parent route while the leaf
   * page is open.
   */
  private readonly registeredTemplates$ = new CollectionSubject<RegisteredTemplate>();

  /**
   * The registered template consumer(s), if any.
   *
   * If more than one consumer is registered, the last consumer will be passed
   * the active template, if any.
   *
   * If no consumer is registered, the template's fallback will be used.
   */
  private readonly registeredConsumer$ = new CollectionSubject<RegisteredTemplateConsumer>();

  public constructor() {
    const activeTemplate$ = this.registeredTemplates$.pipe(lastElement());

    const activeConsumer$ = this.registeredConsumer$.pipe(lastElement());

    combineLatest([activeTemplate$, activeConsumer$])
      .pipe(
        map(([activeTemplate, activeConsumer]) => {
          if (activeTemplate == null) {
            return NEVER;
          }

          return activeConsumer?.(activeTemplate.template) ?? activeTemplate.fallback;
        }),
        distinctUntilChanged(),
        switchAll(),
      )
      .subscribe();
  }

  /**
   * Register the given template and fallback in this container while the returned observable is subscribed to
   *
   * If more than one template is registered, the last registration is considered the "active"
   * template. This allows for scenarios where a parent route registers a general template but a
   * child route overrides the template while the child route is open.
   *
   * If this container doesn't have an active consumer, the fallback observable will be subscribed to while this template is considered active.
   *
   * @param template The template to register
   * @param fallback Observable to subscribe to while the template is active if no consumer is present
   * @returns An observable that registers the given template as long as the observable is subscribed to
   */
  public registerTemplate(
    template: TemplateRef<TemplateContext>,
    fallback: Observable<never>,
  ): Observable<never> {
    return this.registeredTemplates$.addItem$({template, fallback});
  }

  /**
   * Registers a consumer
   *
   * If more than one consumer is registered, the last registration is considred teh "active"
   * registration.
   *
   * If no consumer is registered, the registered template's fallback will be used.
   *
   * @param consumer The consumer to register
   * @returns An observable that registers the consumer while the observable is subscribed to
   */
  public registerConsumer(consumer: RegisteredTemplateConsumer): Observable<never> {
    return this.registeredConsumer$.addItem$(consumer);
  }
}
