import {CordovaSqliteStorage} from './cordova-sqlite/cordova-sqlite-storage';
import {DomStorage} from './dom/dom-storage';
import {Keepsake} from './keepsake.interface';
import {MemoryStorage} from './memory/memory-storage';

export abstract class StorageFactory {
  public abstract createStorage<T>(
    tableName: string,
    databaseAlternativeName?: string,
  ): Promise<Keepsake<T>>;
}

export class DomStorageFactory extends StorageFactory {
  public async createStorage<T>(tableName: string): Promise<DomStorage<T>> {
    return new DomStorage<T>(tableName);
  }
}

export class MemoryStorageFactory extends StorageFactory {
  private readonly memoryStorageStore = new Map<string, MemoryStorage<any>>();
  public async createStorage<T>(tableName: string): Promise<MemoryStorage<T>> {
    if (!this.memoryStorageStore.get(tableName)) {
      this.memoryStorageStore.set(tableName, new MemoryStorage<T>());
    }
    return this.memoryStorageStore.get(tableName) as MemoryStorage<T>;
  }
}

export class CordovaSqlCipherStorageFactory extends StorageFactory {
  public createStorage<T>(
    tableName: string,
    databaseAlternativeName?: string,
  ): Promise<CordovaSqliteStorage<T>> {
    return CordovaSqliteStorage.prepareSqliteStorage<T>(tableName, databaseAlternativeName);
  }
}
