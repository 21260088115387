/**
 * Marks the given text as highlighted
 */
export type Highlighter = (text: string) => string;

/**
 * Finds matches in a string and highlights those
 */
export interface HighlightMatcher {
  /**
   * Find and highlight matches in a string
   *
   * @param highlighter Function that highlights text
   * @param content The content string to look in
   * @param query The query string to highlight
   * @param key The key of the highlight, if any
   * @returns `null` if the `content` doesn't match the `query`, otherwise the higlighted string
   */
  findHighlight(
    highlighter: Highlighter,
    content: string,
    query: string,
    key?: string,
  ): string | null;
}

/**
 * Highlight matcher that looks for the query at the start of the content
 */
export const findStartHighlightMatcher: HighlightMatcher = {
  findHighlight(highlighter, content, query) {
    if (query.length === 0 || !content.toLowerCase().startsWith(query.toLowerCase())) {
      return null;
    }

    return `${highlighter(content.slice(0, query.length))}${content.slice(query.length)}`;
  },
};

/**
 * Highlight matcher that looks for the query anywhere in the content, not just at the start
 */
export const findAnywhereHighlightMatcher: HighlightMatcher = {
  findHighlight(highlighter, content, query) {
    const index = query.length > 0 ? content.toLowerCase().indexOf(query.toLowerCase()) : -1;

    if (index === -1) {
      return null;
    }

    return `${content.slice(0, index)}${highlighter(
      content.slice(index, index + query.length),
    )}${content.slice(index + query.length)}`;
  },
};
