import {Injectable, Inject, LOCALE_ID} from '@angular/core';

import {Language} from './language';

/**
 * The active language
 *
 * The language can be injected into your code to access the name and/or code of the currently
 * active language.
 */
@Injectable()
export class LocaleLanguage implements Language {
  /**
   * The language code, e.g. `'en'` for English
   */
  public readonly code: string;

  /**
   * The language name, in this case equal to the code
   */
  public readonly name: string;

  public constructor(@Inject(LOCALE_ID) activeLocale: string) {
    this.code = (activeLocale.match(/^[a-z]+/i)?.[0] ?? activeLocale).toLowerCase();
    this.name = this.code;
  }
}
