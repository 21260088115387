import {Injectable} from '@angular/core';
import {Subject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class RepositionerService implements DropdownRepositionerService {
  /**
   * It is used to generate an observable to allow to emit every time reposition needs to be done
   */
  private readonly _repositionEmitter = new Subject<void>();
  /**
   * It exposes an observable that emits every time reposition function is called
   */
  public readonly repositionObservable: Observable<void> = this._repositionEmitter.asObservable();

  /* @Override */
  public reposition(): void {
    this._repositionEmitter.next();
  }
}

/**
 * The `DropdownRepositionerService` exposes method `reposition` to be able to recalculate dropdown
 * position from an external component.
 */
@Injectable({
  providedIn: 'root',
  useExisting: RepositionerService,
})
export abstract class DropdownRepositionerService {
  /**
   * Calls the given function to recalculate dropdown position
   */
  public abstract reposition(): void;
}
