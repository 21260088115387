import {ModuleWithProviders, NgModule} from '@angular/core';
import {FILE_UPLOADER_CALL_FACTORY} from '@hermes/file-uploader';

import {TypedJsonFileUploaderCallFactory} from './file-uploader.call.factory';

/**
 * Module providing a TypedJson factory for the file uploader call
 */
@NgModule()
export class FileUploaderTypedJsonModule {
  public static forRoot(): ModuleWithProviders<FileUploaderTypedJsonModule> {
    return {
      ngModule: FileUploaderTypedJsonModule,
      providers: [
        {
          provide: FILE_UPLOADER_CALL_FACTORY,
          useClass: TypedJsonFileUploaderCallFactory,
        },
      ],
    };
  }
}
