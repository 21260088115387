import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Text} from '@atlas/businesstypes';
import {Logger} from '@atlas/logger';
import {ModalController} from '@maia/modals';

import {merge, Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';

import {
  MODAL_TYPE_TOOLTIP,
  TooltipContentComponent,
} from './tooltip-content/tooltip-content.component';

const MESSAGE_MAX_LENGTH = 160;

@Component({
  selector: 'maia-tooltip-message',
  templateUrl: './tooltip-message.component.html',
  styleUrls: ['./tooltip-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {tabindex: '0', role: 'tooltip', 'aria-describedby': 'maia-tooltip-content'},
})
@UntilDestroy()
export class TooltipMessageComponent implements OnInit {
  private readonly _focusLeave: Subject<void> = new Subject();
  private readonly _focusEnter: Subject<void> = new Subject();
  private readonly _mouseEnter: Subject<void> = new Subject();
  private readonly _mouseLeave: Subject<void> = new Subject();
  private readonly _escapePressed: Subject<void> = new Subject();

  private _logger: Logger;

  private _message: string | Text;

  @ViewChild('tooltip')
  public templateref: TemplateRef<any>;

  @Input()
  public set message(value: string | Text) {
    if ((Text.isText(value) ? value.asString() : value).length > MESSAGE_MAX_LENGTH) {
      this._logger.error(`Tooltips support max ${MESSAGE_MAX_LENGTH} characters`);
    }
    this._message = value;
  }

  public get message(): string | Text {
    return this._message;
  }

  @HostListener('focus')
  public onFocus() {
    this._focusEnter.next();
  }

  @HostListener('blur')
  public onBlur() {
    this._focusLeave.next();
  }

  @HostListener('mouseenter')
  public onMouseEnter() {
    this._mouseEnter.next();
  }

  @HostListener('mouseleave')
  public onMouseLeave() {
    this._mouseLeave.next();
  }

  @HostListener('document:keydown.escape', [])
  public onEscapePressed() {
    this._escapePressed.next();
  }

  public constructor(
    loggerFactory: LoggerFactory,
    private readonly _coresolver: ComponentFactoryResolver,
    private readonly _modalCtrl: ModalController,
    private readonly _injector: Injector,
    private readonly _elementRef: ElementRef<HTMLElement>,
  ) {
    this._logger = loggerFactory.createLogger('TooltipMessageComponent');
  }

  public ngOnInit(): void {
    const openToolTip = () =>
      this._modalCtrl.prepareTemplate(
        MODAL_TYPE_TOOLTIP,
        this._injector,
        this._coresolver.resolveComponentFactory(TooltipContentComponent),
        this.templateref,
        {
          withBackdrop: false,
          input: {anchorElement: this._elementRef.nativeElement},
        },
      );
    merge(
      this._focusEnter.pipe(
        switchMap(() =>
          openToolTip().pipe(takeUntil(merge(this._focusLeave, this._escapePressed))),
        ),
      ),
      this._mouseEnter.pipe(
        switchMap(() =>
          openToolTip().pipe(takeUntil(merge(this._mouseLeave, this._escapePressed))),
        ),
      ),
    )
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }
}
