import {coerceNumberProperty} from '@angular/cdk/coercion';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Decimal, DecimalUtils} from '@atlas/businesstypes';

const DEFAULT_BADGE_MAX_VALUE = new Decimal('99');
const DEFAULT_BADGE_VALUE = new Decimal('0');

/**
 * Badge component
 *
 * @ngModule BadgeModule
 */
@Component({
  selector: 'maia-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  private _value: Decimal;
  private _maxValue: Decimal;

  public constructor() {
    this.value = DEFAULT_BADGE_VALUE;
    this.max = DEFAULT_BADGE_MAX_VALUE;
  }

  /**
   * The badge value
   */
  @Input()
  public get value(): Decimal | number | string {
    return this._value;
  }

  public set value(value: Decimal | number | string) {
    this._value =
      value == null
        ? DEFAULT_BADGE_VALUE
        : Decimal.isDecimal(value)
        ? value
        : new Decimal(coerceNumberProperty(value).toString());
  }

  /**
   * The badge maximum value.
   */
  @Input()
  public get max(): Decimal | number | string {
    return this._maxValue;
  }

  public set max(maxValue: Decimal | number | string) {
    this._maxValue =
      maxValue == null
        ? DEFAULT_BADGE_MAX_VALUE
        : Decimal.isDecimal(maxValue)
        ? maxValue
        : new Decimal(coerceNumberProperty(maxValue).toString());
  }

  public isTooHigh() {
    return DecimalUtils.gt(this._value, this._maxValue);
  }
}
