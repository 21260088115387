import {Formatter} from '../base/formatter';

import {Text} from './businesstype';

export const TextFormatter: Formatter<Text> = {
  /**
   * Returns the string value of the text. No formatting is done,
   * as Texts are not structured.
   *
   * @param value The text to "format"
   */
  format(value: Text): string {
    return value.asString();
  },

  /**
   * Simply returns the variable, as Texts are not formatted.
   *
   * @param formattedValue The string to "unformat"
   */
  unformat(formattedValue: string): string {
    return formattedValue;
  },
};
