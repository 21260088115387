import {ChangeDetectionStrategy, Component, Optional} from '@angular/core';
import {TextNumberListComponent} from '../text-number-list/text-number-list.component';

/**
 * TextNumberListItem component
 *
 * Example usage:
 *
 *  <ol maiaTextNumberList>
 *    <li maiaTextNumberListItem>
 *    </li>
 *  </ol>
 *
 *
 * @ngModule TextListsModule
 */
@Component({
  selector: 'li[maiaTextNumberListItem]',
  templateUrl: './text-number-list-item.component.html',
  styleUrls: ['./text-number-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextNumberListItemComponent {
  public constructor(@Optional() parent?: TextNumberListComponent) {
    if (parent == null) {
      throw new Error(
        'Cannot create a TextNumberListItemComponent outside a TextNumberListComponent',
      );
    }
  }
}
