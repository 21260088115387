import {FocusOrigin} from '@angular/cdk/a11y';
import {Directive, forwardRef} from '@angular/core';
import {ModalControl} from '@maia/modals';
import {Option} from '@maia/select';

import {OptionWithHighlights} from './option-with-highlights';

/**
 * @ngModule μInputAutocompleteExportedPartsModule
 */
@Directive({
  selector: 'maia-option:not([custom])',
  providers: [{provide: OptionWithHighlights, useExisting: forwardRef(() => OptionDirective)}],
})
export class OptionDirective<T> extends OptionWithHighlights<T> {
  public constructor(private readonly _delegate: Option<T>) {
    super();
  }

  // Forward Option API to the deletgate Option

  public get id(): string {
    return this._delegate.id;
  }

  public get value(): T {
    return this._delegate.value;
  }

  public get selected(): boolean {
    return this._delegate.selected;
  }

  public set selected(selected: boolean) {
    this._delegate.selected = selected;
  }

  public get disabled(): boolean | undefined {
    return this._delegate.disabled;
  }

  public set disabled(disabled: boolean | undefined) {
    this._delegate.disabled = disabled;
  }

  public set control(control: ModalControl<Option<T>, any>) {
    this._delegate.control = control;
  }

  public setActiveStyles(focusOrigin: FocusOrigin): void {
    this._delegate.setActiveStyles(focusOrigin);
  }

  public setInactiveStyles(focusOrigin: FocusOrigin): void {
    this._delegate.setInactiveStyles(focusOrigin);
  }
}
