import {Formatter} from '../base/formatter';

import {File} from './businesstype';

export const FileFormatter: Formatter<File> = {
  /**
   * Returns the string value of the file to be use as source
   *
   * @param value The file to format
   */
  format(value: File): string {
    const mimeType = value.getMimeType() ? value.getMimeType() : '';
    return `data:${mimeType};base64,${value.asString()}`;
  },

  /**
   * Select only internal value part of entire formatted file string
   *
   * @param fileString The string to unformat
   */
  unformat(fileString: string): string {
    return fileString.split('base64,')[1];
  },
};
