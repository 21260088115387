/*
 * This file contains the extra phone number validators, that is:
 * the validators used to check country specific conditions on valid phone values.
 *
 * This differs from the validator-base file, which contains validators
 * that provide the basic phone number validation: "is it a valid phone number
 * ?".
 */

import {
  AtlasInvalidLengthValidationErrors,
  RESULT_VALID,
  ValidationErrors,
  Validator,
  ValidatorInput,
  ValidValidationResult,
} from '../base/validators';

import {PhoneNumber} from './businesstype';
import {Country, COUNTRY_PREFIX, COUNTRY_REGEX} from './phone-number-localization';
import {isValidPhoneNumber} from './validator-base';

/**
 * Result object used by a validator when a phone number has an incorrect prefix.
 */
export interface AtlasInvalidCountryPrefixValidationErrors extends ValidationErrors {
  atlasInvalidPrefix: {
    /**
     * The expected prefix.
     *
     */
    expectedPrefix: string;

    /**
     * The actual prefix.
     */
    actualPrefix: string;
  };
}

function toString(value: string | PhoneNumber): string {
  if (PhoneNumber.isPhoneNumber(value)) {
    return value.asString();
  }

  return value;
}

export type ExtraPhoneNumberValidatorResult =
  | ValidValidationResult
  | AtlasInvalidCountryPrefixValidationErrors
  | AtlasInvalidLengthValidationErrors;

export const ExtraPhoneNumberValidators = {
  /**
   * Creates a validator for checking the length of belgian phone numbers.
   *
   * The validator only takes valid phone number inputs into account. If the phone number isn't a
   * valid belgian phone number, the validator returns an InvalidLengthResult
   *
   * @return The validator
   */
  belgianPhoneNumber(input: ValidatorInput<PhoneNumber>): ExtraPhoneNumberValidatorResult {
    if (!input || !isValidPhoneNumber(input)) {
      // Input is invalid, ignore
      return RESULT_VALID;
    }
    const phoneNumberString = toString(input);
    if (!COUNTRY_REGEX.BE.test(phoneNumberString)) {
      return {
        atlasInvalidPrefix: {
          expectedPrefix: COUNTRY_PREFIX.BE,
          actualPrefix: phoneNumberString.substring(0, 3),
        },
      };
    } else if (phoneNumberString.charAt(3) === '4' && phoneNumberString.length !== 12) {
      return {
        atlasInvalidLength: {
          requiredLength: 12,
          actualLength: phoneNumberString.length,
        },
      };
    } else if (phoneNumberString.charAt(3) !== '4' && phoneNumberString.length !== 11) {
      return {
        atlasInvalidLength: {
          requiredLength: 11,
          actualLength: phoneNumberString.length,
        },
      };
    } else {
      return RESULT_VALID;
    }
  },

  /**
   * Creates a validator for checking the length of czech phone numbers.
   *
   * The validator only takes valid phone number inputs into account. If the phone number isn't a
   * valid czech phone number, the validator returns an InvalidLengthResult
   *
   * @return The validator
   */
  czechPhoneNumber(input: ValidatorInput<PhoneNumber>): ExtraPhoneNumberValidatorResult {
    if (!input || !isValidPhoneNumber(input)) {
      // Input is invalid, ignore
      return RESULT_VALID;
    }
    const phoneNumberString = toString(input);
    if (!COUNTRY_REGEX.CZ.test(phoneNumberString)) {
      return {
        atlasInvalidPrefix: {
          expectedPrefix: COUNTRY_PREFIX.CZ,
          actualPrefix: phoneNumberString.substring(0, 4),
        },
      };
    } else if (phoneNumberString.length !== 13) {
      return {
        atlasInvalidLength: {
          requiredLength: 13,
          actualLength: phoneNumberString.length,
        },
      };
    } else {
      return RESULT_VALID;
    }
  },

  /**
   * Creates a validator for checking the length of slovak phone numbers.
   *
   * The validator only takes valid phone number inputs into account. If the phone number isn't a
   * valid slovak phone number, the validator returns an InvalidLengthResult
   *
   * @return The validator
   */
  slovakPhoneNumber(input: ValidatorInput<PhoneNumber>): ExtraPhoneNumberValidatorResult {
    if (!input || !isValidPhoneNumber(input)) {
      // Input is invalid, ignore
      return RESULT_VALID;
    }
    const phoneNumberString = toString(input);
    if (!COUNTRY_REGEX.SK.test(phoneNumberString)) {
      return {
        atlasInvalidPrefix: {
          expectedPrefix: COUNTRY_PREFIX.SK,
          actualPrefix: phoneNumberString.substring(0, 4),
        },
      };
    } else if (phoneNumberString.length !== 13) {
      return {
        atlasInvalidLength: {
          requiredLength: 13,
          actualLength: phoneNumberString.length,
        },
      };
    } else {
      return RESULT_VALID;
    }
  },

  /**
   * Creates a validator for checking the length of hungarian phone numbers.
   *
   * The validator only takes valid phone number inputs into account. If the phone number isn't a
   * valid hungarian phone number, the validator returns an InvalidLengthResult
   *
   * @return The validator
   */
  hungarianPhoneNumber(input: ValidatorInput<PhoneNumber>): ExtraPhoneNumberValidatorResult {
    if (!input || !isValidPhoneNumber(input)) {
      // Input is invalid, ignore
      return RESULT_VALID;
    }
    const phoneNumberString = toString(input);
    if (!COUNTRY_REGEX.HU.test(phoneNumberString)) {
      return {
        atlasInvalidPrefix: {
          expectedPrefix: COUNTRY_PREFIX.HU,
          actualPrefix: phoneNumberString.substring(0, 3),
        },
      };
    } else if (phoneNumberString.length !== 12) {
      return {
        atlasInvalidLength: {
          requiredLength: 12,
          actualLength: phoneNumberString.length,
        },
      };
    } else {
      return RESULT_VALID;
    }
  },

  /**
   * Creates a fallback validator for unknown phone numbers.
   *
   * This validator does not introduce additional checks and always returns valid.
   */
  defaultPhoneNumber(): ValidValidationResult {
    return RESULT_VALID;
  },

  /**
   * Creates a validator based on country call code prefixes. Not supported prefixes are considered
   * valid.
   * @param input
   */
  callCodePrefixedPhoneNumber(input: ValidatorInput<PhoneNumber>): ExtraPhoneNumberValidatorResult {
    if (!input || !isValidPhoneNumber(input)) {
      // Input is invalid, ignore
      return RESULT_VALID;
    }
    const phoneNumberString = toString(input);

    if (COUNTRY_REGEX.BE.test(phoneNumberString)) {
      return ExtraPhoneNumberValidators.belgianPhoneNumber(phoneNumberString);
    } else if (COUNTRY_REGEX.CZ.test(phoneNumberString)) {
      return ExtraPhoneNumberValidators.czechPhoneNumber(phoneNumberString);
    } else if (COUNTRY_REGEX.HU.test(phoneNumberString)) {
      return ExtraPhoneNumberValidators.hungarianPhoneNumber(phoneNumberString);
    } else if (COUNTRY_REGEX.SK.test(phoneNumberString)) {
      return ExtraPhoneNumberValidators.slovakPhoneNumber(phoneNumberString);
    }
    return RESULT_VALID;
  },

  /**
   * Creates a validator based on country code. Not supported country codes are considered valid.
   * @param country
   */
  localizedPhoneNumber(
    country: Country | string,
  ): Validator<
    PhoneNumber,
    AtlasInvalidCountryPrefixValidationErrors | AtlasInvalidLengthValidationErrors
  > {
    return (
      LOCALIZED_VALIDATORS[country as Country] || ExtraPhoneNumberValidators.defaultPhoneNumber
    );
  },
} as const;

const LOCALIZED_VALIDATORS: {
  [c in Country]: Validator<
    PhoneNumber,
    AtlasInvalidCountryPrefixValidationErrors | AtlasInvalidLengthValidationErrors
  >;
} = {
  BE: ExtraPhoneNumberValidators.belgianPhoneNumber,
  CZ: ExtraPhoneNumberValidators.czechPhoneNumber,
  SK: ExtraPhoneNumberValidators.slovakPhoneNumber,
  HU: ExtraPhoneNumberValidators.hungarianPhoneNumber,
};
