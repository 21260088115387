import {Injectable, TemplateRef} from '@angular/core';
import {ValidationErrors} from '@angular/forms';
import {ValidValidationResult} from '@atlas/businesstypes';

/**
 * Extraction result where no validation should be shown.
 */
export interface ValidExtractedValidationMessageResult {
  isInvalid: false;
  message: undefined;
}

/**
 * Extraction result where a validation message should be shown.
 */
export interface InvalidExtractedValidationMessageResult {
  isInvalid: true;
  message: string | TemplateRef<any>;
}

/**
 * Extraction result of validation. Either a message should be shown, or not.
 */
export type ExtractedValidationMessageResult =
  | ValidExtractedValidationMessageResult
  | InvalidExtractedValidationMessageResult;

/**
 * Function that decides whether a validation message should be shown.
 *
 * __Important:__ This must be a pure function. This function is only called when the validation
 * result changes. If the function depends on an external variable, it will not be re-evaluated when
 * that variable changes.
 *
 * @param errors The errors returned by the validators of the input
 * @return Whether or not a validation message should be shown
 */
export type ValidationFilter = (errors: ValidValidationResult | ValidationErrors) => boolean;

const DEFAULT_PRIORITY = 0;

const DEFAULT_FILTER: ValidationFilter = () => true;

export const EXTRACTED_RESULT_VALID: ValidExtractedValidationMessageResult = Object.freeze({
  isInvalid: false,
  message: undefined,
});

/**
 * Function used to sort an array of numbers high to low.
 */
const sortFn = (a: number, b: number): number => b - a;

export type ValidationMessage = string | TemplateRef<unknown>;
export type ValidationMessageFactory = (
  errors: ValidValidationResult | ValidationErrors,
) => ValidationMessage;

/**
 * A validation extractor turns a basic validation result object into the correct message to show.
 *
 * It does this using registered extractors. An extractor is registered given a message to show, a
 * filter function and a priority.
 */
@Injectable()
export class ValidationMessageExtractor {
  /**
   * The registered extractors, grouped per priority.
   */
  private registeredExtractors: {
    [priority: number]: {message: ValidationMessageFactory; filter: ValidationFilter}[];
  };

  /**
   * The registered priorities, sorted from low to high.
   *
   * This is basically just a sorted `Object.keys(this.registeredExtractors)`.
   */
  private registeredPriorities: number[];

  public constructor() {
    this.registeredExtractors = {};
    this.registeredPriorities = [];
  }

  /**
   * Registers the given message, when the given filter matches.
   *
   * @param message The message to show if the filter matches
   * @param priority The priority of the extractor. Higher priority extractors run before lower
   * priority extractors. The default priority is 0.
   * @param filter The filter function, defaults to a function that returns true.
   * @return A function that unregisters the message extractor when called.
   */
  // TODO(jd70444): Review this API: doesn't really allow using the validation object in the
  // message. Also take a look at making the validation object available in the TemplateRef, if
  // possible.
  public registerMessageExtractor(
    message: ValidationMessage | ValidationMessageFactory,
    priority: number = DEFAULT_PRIORITY,
    filter: ValidationFilter = DEFAULT_FILTER,
  ): () => void {
    if (this.registeredExtractors[priority] == null) {
      this.registeredExtractors[priority] = [];

      this.registeredPriorities.push(priority);
      this.registeredPriorities.sort(sortFn);
    }

    if (typeof message !== 'function') {
      message = (actualMessage => () => actualMessage)(message);
    }

    const registration = {message, filter};

    this.registeredExtractors[priority].push(registration);

    return () => {
      const priorityValidators = this.registeredExtractors[priority];

      if (priorityValidators == null) {
        return;
      }

      const idx = priorityValidators.indexOf(registration);

      if (idx === -1) {
        return;
      }

      if (priorityValidators.length !== 1) {
        priorityValidators.splice(idx, 1);
        return;
      }

      delete this.registeredExtractors[priority];
      this.registeredPriorities.splice(this.registeredPriorities.indexOf(priority), 1);
    };
  }

  /**
   * Turns the validation result into a message to show, if anything should be shown.
   *
   * @param input The validation object (or null) produced by the validators.
   * @return Whether to show a message, and the message (if it should be shown)
   */
  public extract(
    input: ValidValidationResult | ValidationErrors,
  ): ExtractedValidationMessageResult {
    for (const priority of this.registeredPriorities) {
      for (const {message, filter} of this.registeredExtractors[priority]) {
        if (filter(input)) {
          return {
            isInvalid: true,
            message: message(input),
          };
        }
      }
    }

    return EXTRACTED_RESULT_VALID;
  }
}
