import {NgModule} from '@angular/core';

import {TransactionRouteHandler} from './transaction-route-handler';

@NgModule({providers: [TransactionRouteHandler]})
export class TransactionModule {
  public constructor(transactionRouteHandler: TransactionRouteHandler) {
    transactionRouteHandler.handleTitleRouterEvents();
  }
}
