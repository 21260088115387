import {INPUT_CHAR, RE_INPUT_CHAR} from './mask-helper.util';

/**
 * Formats and unformats a value.
 */
export interface ValueFormatter {
  /**
   * Formats the given value
   */
  formatValue(value: string): string;

  /**
   * Unformats the given value
   */
  unformatValue(value: string): string;

  /**
   * Unformats on paste (Ctrl/Cmd + v)
   */
  pasteUnformatValue?(value: string): string;
}

/**
 * Formatter based on a format string where `#` is an actual value character and all other
 * characters are literal parts, e.g. `###/####/#####` will format `123456789002` as
 * `123/4567/89002`.
 */
export class FormatBasedValueFormatter implements ValueFormatter {
  public readonly valueLength: number;

  public readonly formatLength: number;

  public constructor(mask: string, private readonly format: string) {
    this.formatLength = format.length;
    this.valueLength = (format.match(RE_INPUT_CHAR) || []).length;

    if (this.valueLength !== (mask.match(RE_INPUT_CHAR) || []).length) {
      throw new Error(`Format "${format}" has a different value size from mask "${mask}"`);
    }
  }

  public formatValue(value: string): string {
    const {formatLength} = this;

    const formattedValue: string[] = [];

    let valueIndex = 0;
    const valueLength = Math.min(value.length, this.valueLength);

    for (let i = 0; i < formatLength; i++) {
      if (this.format[i] === INPUT_CHAR) {
        if (valueIndex === valueLength) {
          formattedValue.push(' ');
        } else {
          formattedValue.push(value[valueIndex]);
          valueIndex++;
        }
      } else {
        formattedValue.push(this.format[i]);
      }
    }

    return formattedValue.join('');
  }

  public unformatValue(value: string): string {
    const {length} = value;

    const unformattedValue: string[] = [];
    for (let i = 0; i < length; i++) {
      if (this.format[i] === INPUT_CHAR) {
        unformattedValue.push(value[i]);
      }
    }

    return unformattedValue.join('');
  }
}
