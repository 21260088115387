import {ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2} from '@angular/core';
import {CssClassUtility, CssClassUtilityFactory} from '@maia/core';

/**
 * Possible positions for the pull-tab
 */
export const enum PullTabPosition {
  /**
   * Align the pull-tab at the top of the screen
   */
  Top = 'top',

  /**
   * Align the pull-tab to the right hand side of the screen
   */
  Right = 'right',

  /**
   * Align the pull-tab to the bottom of the screen
   */
  Bottom = 'bottom',

  /**
   * Align the pull-tab to the left-hand side of the screen
   */
  Left = 'left',
}

/**
 * A pull tab is a button attached to a side of the screen
 *
 * This component can be styled via CSS properties:
 *
 * - set `background-color` and `border-color` to change the background
 * - set `color` to change the text colour
 *
 * The pull-tab is shown centered on the edge defined by the `position` input.
 *
 * @ngModule PullTabModule
 */
@Component({
  selector: 'button[maiaPullTab]',
  templateUrl: './pull-tab.component.html',
  styleUrls: ['./pull-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PullTabComponent {
  private _position: PullTabPosition;

  private readonly _classUtility: CssClassUtility<{
    position: {[p in PullTabPosition]: string};
  }>;

  public constructor(
    classUtilifyFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    element: ElementRef<HTMLElement>,
  ) {
    this._classUtility = classUtilifyFactory.create(
      {
        position: {
          [PullTabPosition.Right]: `maia-pull-tab--position-${PullTabPosition.Right}`,
          [PullTabPosition.Top]: `maia-pull-tab--position-${PullTabPosition.Top}`,
          [PullTabPosition.Bottom]: `maia-pull-tab--position-${PullTabPosition.Bottom}`,
          [PullTabPosition.Left]: `maia-pull-tab--position-${PullTabPosition.Left}`,
        },
      },
      renderer,
      element,
    );

    this.position = PullTabPosition.Right;
  }

  /**
   * The position of the pull-tab, defaults to the right hand side of the screen
   */
  @Input()
  public get position(): PullTabPosition {
    return this._position;
  }

  public set position(position: PullTabPosition) {
    if (this._position === position) {
      return;
    }

    this._position = position;
    this._classUtility.setValue('position', position);
  }
}
