import {HttpRequest} from '@angular/common/http';
import {InjectionToken} from '@angular/core';
import {Call, ConnectorResponseBase} from '@atlas/convertor';
import {Observable} from 'rxjs';
import {UniqueToken} from '../utils/unique';

export interface ConnectorBackend<O> {
  handle(request: HttpRequest<null>): Observable<ConnectorResponseBase<O>>;
}

export interface ConnectorInterceptor {
  /**
   * Uniqueness token for the interceptor
   *
   * If present, the connector guarantees that only one interceptor will run for any given token,
   * so if multiple interceptors are registered for a given token, only one will run.
   *
   * Only one interceptor can be registered with any given token in a single connector. If two
   * interceptors are provided with the same token, one will be ignored.
   */
  readonly uniquenessToken?: UniqueToken;

  /**
   * Function that intercepts all requests handled by this connector
   *
   * @param call The call being intercepted
   * @param input The input for the given call
   * @param request The HTTP request that will be sent
   * @param backend The backend to pass the request on to
   */
  intercept<I, O>(
    call: Call<I, O>,
    input: I,
    request: HttpRequest<null>,
    next: ConnectorBackend<O>,
  ): Observable<ConnectorResponseBase<O>>;
}

export const CONNECTOR_INTERCEPTORS = new InjectionToken<ConnectorInterceptor[]>(
  'connectorInterceptors',
);
