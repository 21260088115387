import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef} from '@angular/core';
import {DomIoService} from '@maia/core';

/**
 * A component that always has a 1:1 aspect ratio where the size is defined by the component's
 * height
 *
 * For this to work, the component must have an explicit size set by the consumer. The most common
 * ways to do this is
 *
 * - By setting the CSS `height` property using a class, or
 * - By including this component inside a flexbox container with `align-items` set to `stretch`.
 */
@Component({
  selector: 'maia-square[byHeight]',
  templateUrl: './height-square.component.html',
  styleUrls: ['./height-square.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeightBasedSquareComponent implements AfterViewInit {
  public constructor(
    private readonly elementRef: ElementRef,
    private readonly domIo: DomIoService,
  ) {}

  public ngAfterViewInit(): void {
    const element: HTMLElement = this.elementRef.nativeElement;

    this.domIo.measure(() => {
      const boundingRect = element.getBoundingClientRect();

      if (boundingRect.width !== boundingRect.height) {
        this.domIo.mutate(() => {
          element.style.width = `${boundingRect.height}px`;
        });
      }
    });
  }
}
