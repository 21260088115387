import {
  addDays,
  addMonths,
  addQuarters,
  addYears,
  differenceInDays,
  differenceInMonths,
  differenceInQuarters,
  differenceInYears,
  getDate,
  getDay,
  getDaysInMonth,
  getMonth,
  getQuarter,
  getWeek,
  getWeekYear,
  getYear,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isSameQuarter,
  isSameWeek,
  isSameYear,
  setDate,
  setDay,
  setMonth,
  setQuarter,
  setWeek,
  setWeekYear,
  setYear,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subYears,
} from 'date-fns';
import {Date, fromGlobalDate, toGlobalDate} from './businesstype';
import {
  DateKey,
  DayOfMonth,
  DayOfWeek,
  DaysInMonth,
  DurationDateKey,
  Month,
  SerializedDate,
  StartOfDateKey,
} from './date-constants';
import {partsToFormatted} from './format';
import GlobalDateModule, {GlobalDate} from './global-date';
import {isValidDate} from './validator-base';

const GETTERS: {[key in DateKey]: (value: GlobalDate) => number} = {
  [DateKey.Day]: getDate,
  [DateKey.DayOfWeek]: getDay,
  [DateKey.Month]: getMonth,
  [DateKey.Quarter]: getQuarter,
  [DateKey.Week]: getWeek,
  [DateKey.WeekBasedYear]: getWeekYear,
  [DateKey.Year]: getYear,
};

const SETTERS: {
  [key in DateKey]: (value: GlobalDate, newValue: number) => GlobalDate;
} = {
  [DateKey.Day]: setDate,
  [DateKey.DayOfWeek]: setDay,
  [DateKey.Month]: setMonth,
  [DateKey.Quarter]: setQuarter,
  [DateKey.Week]: setWeek,
  [DateKey.WeekBasedYear]: setWeekYear,
  [DateKey.Year]: setYear,
};

const ADDERS: {
  [key in DurationDateKey]: (value: GlobalDate, newValue: number) => GlobalDate;
} = {
  [DateKey.Day]: addDays,
  [DateKey.Month]: addMonths,
  [DateKey.Quarter]: addQuarters,
  [DateKey.Year]: addYears,
};

const SUBTRACTERS: {
  [key in DurationDateKey]: (value: GlobalDate, newValue: number) => GlobalDate;
} = {
  [DateKey.Day]: subDays,
  [DateKey.Month]: subMonths,
  [DateKey.Quarter]: subQuarters,
  [DateKey.Year]: subYears,
};

const DIFFERS: {
  [key in DurationDateKey]: (value: GlobalDate, otherValue: GlobalDate) => number;
} = {
  [DateKey.Day]: differenceInDays,
  [DateKey.Month]: differenceInMonths,
  [DateKey.Quarter]: differenceInQuarters,
  [DateKey.Year]: differenceInYears,
};

const TO_START_OF: {
  [key in StartOfDateKey | DateKey.Day]: (value: GlobalDate) => GlobalDate;
} = {
  [DateKey.Day]: startOfDay,
  [DateKey.Week]: date => startOfWeek(date, {weekStartsOn: DayOfWeek.Monday}),
  [DateKey.Month]: startOfMonth,
  [DateKey.Quarter]: startOfQuarter,
  [DateKey.Year]: startOfYear,
};

const COMPARATORS: {
  [key in StartOfDateKey | DateKey.Day]: (value: GlobalDate, valueToCompare: GlobalDate) => boolean;
} = {
  [DateKey.Day]: isSameDay,
  [DateKey.Week]: isSameWeek,
  [DateKey.Month]: isSameMonth,
  [DateKey.Quarter]: isSameQuarter,
  [DateKey.Year]: isSameYear,
};

// @dynamic - see https://github.com/angular/angular/issues/19698
export class DateUtils {
  /**
   * Returns a date for today
   *
   * Always returns a new instance.
   */
  public static today(): Date {
    // Always return a new instance because we might be around midnight
    return fromGlobalDate(GlobalDateModule.today());
  }

  /**
   * Gets a property of a date.
   *
   * @param value The date to get the value from
   * @param key The key of the property to get
   */
  public static get(value: Date, key: DateKey.Day): DayOfMonth;
  public static get(value: Date, key: DateKey.DayOfWeek): DayOfWeek;
  public static get(value: Date, key: DateKey.Month): Month;
  public static get(value: Date, key: DateKey): number;
  public static get(value: Date, key: DateKey): number {
    return GETTERS[key](toGlobalDate(value));
  }

  /**
   * Gets a property of a date
   *
   * @param value The date to get the value from
   * @param key The key of the property to get
   * @param newPropertyValue The new value to set on the given property
   */
  public static set(value: Date, key: DateKey.Day, newPropertyValue: DayOfMonth): Date;
  public static set(value: Date, key: DateKey.Month, newPropertyValue: Month): Date;
  public static set(value: Date, key: DateKey.Year, newPropertyValue: number): Date;
  public static set(value: Date, key: DurationDateKey, newPropertyValue: number): Date {
    return fromGlobalDate(SETTERS[key](toGlobalDate(value), newPropertyValue));
  }

  /**
   * Calculates the difference between the given base and value
   *
   * @param base The base date
   * @param value The date to compare with base
   * @param key The key of the diff to get
   */
  public static diff(base: Date, value: Date, key: DurationDateKey): number {
    return DIFFERS[key](toGlobalDate(value), toGlobalDate(base));
  }

  /**
   * Returns a date with the given amount added to the given property of the given date
   *
   * This operation wraps, e.g. adding 2 months to 21/12/2012 yields 21/02/2013.
   * This operation also truncates, e.g. adding 1 month to 31/01/2018 yields 28/02/2018, not
   * 03/03/2018.
   *
   * @param value The date to start from
   * @param key The property to add the value to
   * @param amountToAdd The amount to add to the property
   */
  public static add(value: Date, key: DurationDateKey, amountToAdd: number): Date {
    return fromGlobalDate(ADDERS[key](toGlobalDate(value), amountToAdd));
  }

  /**
   * Returns a date with the given amount subtracted from the given property of the given date
   *
   * This operation wraps, e.g. subtracting 2 months from 21/02/2012 yields 21/12/2011.
   * This operation also truncates, e.g. subtracting 1 month from 31/03/2018 yields 28/02/2018.
   *
   * @param value The date to start from
   * @param key The property to subtract the value to
   * @param amountToSubtract The amount to subtract to the property
   */
  public static subtract(value: Date, key: DurationDateKey, amountToSubtract: number): Date {
    return fromGlobalDate(SUBTRACTERS[key](toGlobalDate(value), amountToSubtract));
  }

  /**
   * Returns the number of days in the month where the given value lies in
   *
   * @param value The date
   */
  public static getDaysInMonth(value: Date): DaysInMonth {
    return getDaysInMonth(toGlobalDate(value)) as DaysInMonth;
  }

  /**
   * Serializes the given date object
   *
   * @param value The date value
   */
  public static serialize(value: Date): SerializedDate {
    const internalValue = toGlobalDate(value);

    return {
      [DateKey.Year]: getYear(internalValue),
      [DateKey.Month]: getMonth(internalValue) as Month,
      [DateKey.Day]: getDate(internalValue) as DayOfMonth,
    };
  }

  /**
   * Unserializes the given serialized date into a date
   *
   * @param value The serialized date
   * @throws if the given date is invalid
   */
  public static unserialize(value: Readonly<SerializedDate>): Date {
    const dateInput = partsToFormatted(
      value[DateKey.Year],
      value[DateKey.Month] + 1,
      value[DateKey.Day],
    );

    if (!isValidDate(dateInput)) {
      throw new Error(`Cannot create Date for invalid input ${JSON.stringify(value)}`);
    }

    return new Date(dateInput);
  }

  /**
   * Returns a date that denotes the start of the given duration in which the given date lies
   *
   * For example: Given the value of Friday 13 April 2018, the following values will be returned:
   *
   * - Year: Monday 1 January 2018
   * - Quarter: Sunday 1 April 2018
   * - Month: Sunday 1 April 2018
   * - Week: Monday 9 April 2018
   *
   * @param value The value
   * @param duration The duration
   */
  public static atStartOf(value: Date, duration: StartOfDateKey): Date {
    return fromGlobalDate(TO_START_OF[duration](toGlobalDate(value)));
  }

  /**
   * Returns whether the given value is before the given threshold at the given duration
   *
   * To give an example: 17 May 2018 is before 18 May 2018, but it's not if we only compare weeks
   * because both dates lie in the same week.
   *
   * @param value The value
   * @param threshold The threshold to compare the value with
   * @param duration The duration to take into account
   */
  public static isBefore(value: Date, threshold: Date, duration?: StartOfDateKey): boolean {
    const startOf = TO_START_OF[duration || DateKey.Day];

    return isBefore(startOf(toGlobalDate(value)), startOf(toGlobalDate(threshold)));
  }

  /**
   * Returns whether the given value is after the given threshold at the given duration
   *
   * To give an example: 18 May 2018 is after 17 May 2018, but it's not if we only compare weeks
   * because both dates lie in the same week.
   *
   * @param value The value
   * @param threshold The threshold to compare the value with
   * @param duration The duration to take into account
   */
  public static isAfter(value: Date, threshold: Date, duration?: StartOfDateKey): boolean {
    const startOf = TO_START_OF[duration || DateKey.Day];

    return isAfter(startOf(toGlobalDate(value)), startOf(toGlobalDate(threshold)));
  }

  /**
   * Returns whether the given value is the same as the given threshold at the given duration
   *
   * To give an example: 17 May 2018 is not the same as 18 May 2018, but it is if we only compare
   * weeks because both dates lie in the same week.
   *
   * @param value The value
   * @param threshold The threshold to compare the value with
   * @param duration The duration to take into account
   */
  public static isSame(value: Date, threshold: Date, duration?: StartOfDateKey): boolean {
    return COMPARATORS[duration || DateKey.Day](toGlobalDate(value), toGlobalDate(threshold));
  }
}
