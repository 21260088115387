import {Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {CssClassUtility, CssClassUtilityFactory} from '@maia/core';
import {
  CellOffset,
  CellOffsetClasses,
  CellOffsetLgClasses,
  CellOffsetMdClasses,
  CellOffsetSmClasses,
  CellOffsetXlClasses,
} from './utils';

/**
 * CellOffsetDirective to determine mobile-first breakpoint class
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-cell[cellOffset]',
})
export class CellOffsetDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof CellOffsetClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(CellOffsetClasses, renderer, elementRef);
  }

  @Input()
  public set cellOffset(cellOffset: CellOffset | null) {
    this._cssClassUtility.setValue('cellOffset', cellOffset);
  }
}

/**
 * CellOffsetSmDirective to determine Small breakpoint class
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-cell[cellOffsetSm]',
})
export class CellOffsetSmDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof CellOffsetSmClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(
      CellOffsetSmClasses,
      renderer,
      elementRef,
    );
  }

  @Input()
  public set cellOffsetSm(cellOffsetSm: CellOffset | null) {
    this._cssClassUtility.setValue('cellOffsetSm', cellOffsetSm);
  }
}

/**
 * CellOffsetMdDirective to determine Medium breakpoint class
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-cell[cellOffsetMd]',
})
export class CellOffsetMdDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof CellOffsetMdClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(
      CellOffsetMdClasses,
      renderer,
      elementRef,
    );
  }

  @Input()
  public set cellOffsetMd(cellOffsetMd: CellOffset | null) {
    this._cssClassUtility.setValue('cellOffsetMd', cellOffsetMd);
  }
}

/**
 * CellOffsetLgDirective to determine Large breakpoint class
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-cell[cellOffsetLg]',
})
export class CellOffsetLgDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof CellOffsetLgClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(
      CellOffsetLgClasses,
      renderer,
      elementRef,
    );
  }

  @Input()
  public set cellOffsetLg(cellOffsetLg: CellOffset | null) {
    this._cssClassUtility.setValue('cellOffsetLg', cellOffsetLg);
  }
}

/**
 * CellOffsetXlDirective to determine Extra Large breakpoint class
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-cell[cellOffsetXl]',
})
export class CellOffsetXlDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof CellOffsetXlClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(
      CellOffsetXlClasses,
      renderer,
      elementRef,
    );
  }

  @Input()
  public set cellOffsetXl(cellOffsetXl: CellOffset | null) {
    this._cssClassUtility.setValue('cellOffsetXl', cellOffsetXl);
  }
}
