import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FileFuncDocumentSignedSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaFileFuncDocumentSigned]',
  template: '<svg:path d="M13.47 2.5v4.89h4.85" fill="none" stroke="#4d7293" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M18.32 16.45v-9L13.47 2.5h-9.8v18h7.52" fill="none" stroke="#4d7293" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M6.93 7.39h3.28" fill="none" stroke="#4d7293" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M6.93 11.47h8.17" fill="none" stroke="#4d7293" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M6.93 15.56h8.17" fill="none" stroke="#4d7293" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M13.06 20.25c-.36-.15-.49-.55-.3-2.52v-.13a.47.47 0 0 1 .18-.28.4.4 0 0 1 .55.1h0a.32.32 0 0 1 .07.19 6.75 6.75 0 0 0 .51 1.21 2 2 0 0 0-.06-.49.36.36 0 0 1 .08-.3.37.37 0 0 1 .27-.16.39.39 0 0 1 .44.33h0c0 .21.1.26.12.27h.59a1.28 1.28 0 0 1 1.08.09.62.62 0 0 1 .22.35l.78-.12.43-.06a3.25 3.25 0 0 1 2.11.14.42.42 0 0 1 .19.29c.07.57-.57 1.63-5.72 2.33" fill="none" stroke="#00aeef" stroke-linecap="round" stroke-linejoin="round"></svg:path>',

  styles: [
  ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileFuncDocumentSignedSvgComponent {
}

@NgModule({
  declarations: [
    FileFuncDocumentSignedSvgComponent,
  ],
  exports: [
    FileFuncDocumentSignedSvgComponent,
  ],
})
export class FileFuncDocumentSignedSvgModule {}
