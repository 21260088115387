import {NgModule} from '@angular/core';
import {ActionTileModule} from '@maia/action-tile';
import {RadioButtonsModule} from '@maia/radio-buttons';

import {SelectGroupComponent} from './select-group/select-group.component';
import {SelectTileComponent} from './select-tile/select-tile.component';

@NgModule({
  imports: [ActionTileModule, RadioButtonsModule],
  declarations: [SelectGroupComponent, SelectTileComponent],
  exports: [SelectGroupComponent, SelectTileComponent],
})
export class SelectTileModule {}
