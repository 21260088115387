import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Optional,
} from '@angular/core';

import {TableComponent} from '../table/table.component';

/**
 * The body of a table. This component must be used inside a table, otherwise an exception will be
 * thrown.
 *
 * @ngModule TablesModule
 */
@Component({
  selector: 'maia-table-body',
  template: '<ng-content></ng-content>',
  styleUrls: ['./table-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableBodyComponent implements AfterViewChecked {
  private _table: TableComponent;

  public constructor(private _body: ElementRef, @Optional() table?: TableComponent) {
    if (table == null) {
      throw new Error(
        'Cannot create a <maia-table-body /> component out of <maia-table /> component',
      );
    }

    this._table = table;
  }

  public ngAfterViewChecked() {
    // TODO: domIoService is necessary here. But if I include it, the e2e tests don't work.
    if (this._table.width !== this._body.nativeElement.clientWidth) {
      this._table.width = this._body.nativeElement.clientWidth;
    }
  }
}
