import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

/**
 * Default value for indented property.
 */
export const TEXT_LIST_DEFAULT_INDENTED = false;
/**
 * Default value for icon.
 */
export type IconType = 'bullet' | 'checkmark';
export const TEXT_LIST_DEFAULT_ICON: IconType = 'bullet';

/**
 * @ngModule TextListsModule
 */
@Component({
  template: '<ng-content></ng-content>',
  selector: 'ul[maiaTextList]',
  styleUrls: ['./text-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextListComponent {
  private _indented: boolean = TEXT_LIST_DEFAULT_INDENTED;

  /**
   * The icon for the text-list elements
   */
  @Input()
  public iconType: IconType = TEXT_LIST_DEFAULT_ICON;

  /**
   * The textlist indentation.
   */
  @Input()
  public get indented(): boolean {
    return this._indented;
  }

  public set indented(indented: boolean) {
    this._indented = coerceBooleanProperty(indented);
  }
}
