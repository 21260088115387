import {NgModule} from '@angular/core';
import {DropdownsModule} from '@maia/dropdowns';
import {SelectDropdownModule} from '@maia/dropdowns/select';
import {μDropdownListsSvgModule} from '@maia/icons';
import {ModalsModule} from '@maia/modals';

import {OptionModule} from './option.module';
import {SelectPartsModule} from './select-parts.module';
import {OpenSelectWithSlideInDirective} from './select/select-open.directive';
import {SelectComponent} from './select/select.component';

@NgModule({
  imports: [
    DropdownsModule,
    μDropdownListsSvgModule,
    ModalsModule,
    SelectDropdownModule,
    SelectPartsModule,
  ],
  declarations: [SelectComponent, OpenSelectWithSlideInDirective],
  exports: [OptionModule, SelectComponent, OpenSelectWithSlideInDirective],
})
export class SelectModule {}
