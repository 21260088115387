import {
  BusinessType,
  BusinessTypeConstructor,
  JsonArray,
  JsonObject,
  JsonValue,
} from '@atlas/businesstypes';

const ENCRYPTION_SUFFIX = '_Enc';

/**
 * A function that forward references a {@link CtorObject}
 */
export interface ForwardCtorRef<T> {
  readonly __forwardRef: true;

  (): T;
}

/**
 * Either a {@link CtorObject} directly or a forward reference to one
 */
export type MaybeForwardCtorRef<T> = T | ForwardCtorRef<T>;

function isForwardCtorRef<T>(value: MaybeForwardCtorRef<T>): value is ForwardCtorRef<T> {
  return typeof value === 'function' && (value as ForwardCtorRef<T>).__forwardRef;
}

/**
 * Creates a forward reference to a ctor object
 *
 * This allows ctor objects to self-reference, making it possible to convert tree-like structures:
 *
 * ```ts
 * interface Node {
 *   name: Text;
 *   children: Node[];
 * }
 *
 * const nodeCtorObject: CtorObject<Node> = {
 *   name: Text,
 *   children: forwardCtorRef(() => [nodeCtorObject]),
 * };
 * ```
 *
 * @param fn Function returning the ctor object
 */
export function forwardCtorRef<T>(fn: () => [T]): ForwardCtorRef<[T]>;
/**
 * Creates a forward reference to a ctor object
 *
 * This allows ctor objects to self-reference, making it possible to convert list-like structures:
 *
 * ```ts
 * interface LinkedList {
 *   name: Text;
 *   next?: LinkedList;
 * }
 *
 * const linkedListCtorObject: CtorObject<LinkedList> = {
 *   name: Text,
 *   next: forwardCtorRef(() => linkedListCtorObject),
 * };
 * ```
 *
 * @param fn Function returning the ctor object
 */
export function forwardCtorRef<T>(fn: () => T): ForwardCtorRef<T>;
export function forwardCtorRef<T>(fn: () => T): ForwardCtorRef<T> {
  return Object.assign(fn, {__forwardRef: true as const});
}

function resolveForwardRef<T>(value: MaybeForwardCtorRef<T>): T {
  if (isForwardCtorRef(value)) {
    return value();
  }

  return value;
}

/**
 * An object containing the businesstype classes to convert the shape `T` from JSON into businesstype output
 *
 * The interface and CtorObject are very alike:
 *
 * ```ts
 * interface MyOutput {
 *   aText: Text;
 *   aDate: Date;
 * }
 *
 * const myOutputCtorObject: CtorObject<MyOutput> = {
 *   aText: Text,
 *   aDate: Date,
 * };
 * ```
 *
 * with some exceptions:
 *
 * - Every property on the CtorObject is required, even if the property on the interface is optional
 * - An array `T[]` in the interface is mapped to a single-item tuple `[CtorObject<T>]` in the CtorObject
 * - Self-referencing requires use of the {@link forwardCtorRef} function
 *
 * A more complex example:
 *
 * ```ts
 * interface MyOutput {
 *   signers: ClientNumber[];
 *   comment?: Text;
 * }
 *
 * const myOutputCtorObject: CtorObject<MyOutput> = {
 *   signers: [ClientNumber],
 *   comment: Text,
 * };
 * ```
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type CtorObject<T extends {}> = {
  // -? makes all properties required
  // awesome syntax is awesome
  [key in keyof T]-?: MaybeForwardCtorRef<
    T[key] extends (infer U)[]
      ? U extends BusinessType<unknown>
        ? [BusinessTypeConstructor<U>]
        : [CtorObject<U>]
      : NonNullable<T[key]> extends BusinessType<unknown>
      ? BusinessTypeConstructor<NonNullable<T[key]>>
      : CtorObject<NonNullable<T[key]>>
  >;
};

/**
 * Create a convertor for turning JSON objects into objects containing businesstypes
 *
 * The input is partially validated. It is assumed to have the correct shape, but the values for the businesstypes are validated by the businesstypes themselves.
 *
 * @param structure The {@link CtorObject} describing the convertor's output
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const createConvertor = <O extends {}>(structure: CtorObject<O>) => (
  input: JsonObject,
): O => {
  return (Object.keys(structure) as (keyof typeof structure)[]).reduce((result, key) => {
    const inputKey = key as string;

    // The property is absent in the output
    // Note: we don't actually know if the property was optional, our convertor doesn't validate
    // whether required properties are present.
    // That's by design as it's very complex to do so. Sure, we could quite easily validate these
    // interfaces
    //   interface FooOutput { text: Text }
    //   interface BarOutput { text?: Text }
    // but what about
    //   type BazOutput = { text: Text; date?: Date } | { text?: Text; date: Date }
    // ?
    // Let's not go there.
    if (input[inputKey] == null) {
      return result;
    }

    // Dereference any forwardCtorRef calls
    const ctor = resolveForwardRef(structure[key]);

    if (typeof ctor === 'function') {
      // The current property is a proper businesstype -> convert it

      result[key] = fromJsonValue(
        ctor as BusinessTypeConstructor<O[typeof key] & BusinessType<unknown>>,
        input[inputKey],
        input[inputKey + ENCRYPTION_SUFFIX],
      );
    } else if (Array.isArray(ctor)) {
      /* eslint-disable @typescript-eslint/no-unsafe-assignment */

      // ctor is [CtorObject<O[keyof O]>], there is exactly one item in the ctor array
      const entryCtor = ctor[0];
      // The current property is an array, but what kind?
      if (typeof entryCtor === 'object') {
        // An array of objects -> pass through a child convertor
        result[key] = ((input[inputKey] as JsonObject[]).map(
          createConvertor(entryCtor),
        ) as unknown) as O[keyof O];
      } else if (typeof entryCtor === 'function') {
        // An array of businesstypes -> convert every item
        // Note: we currently only support reading unencrypted values from arrays (with the exception of Hidden instances)
        // If we need to support encrypted businesstype arrays -> contact Pallas first, they aren't even sure they support it

        result[key] = ((input[inputKey] as JsonArray).map(entry =>
          (entryCtor as BusinessTypeConstructor<
            O[typeof key] & BusinessType<unknown>
          >).fromJsonValue(entry),
        ) as unknown) as O[keyof O];
      } else {
        throw new Error(`Unexpected type: ${typeof entryCtor}`);
      }

      /* eslint-enable @typescript-eslint/no-unsafe-assignment */
    } else {
      // The current property is an object, pass it on to a child convertor

      // @ts-expect-error typescript can't handle ctor being CtorObject<O[typeof key]>, it says
      //   Type instantiation is excessively deep and possibly infinite. ts(2589)
      result[key] = createConvertor(ctor as CtorObject<O[typeof key]>)(input[inputKey]);
    }

    return result;
  }, {} as O);
};

function fromJsonValue<T extends BusinessType<unknown>>(
  Type: BusinessTypeConstructor<T>,
  value: JsonValue,
  encryptedValue?: JsonValue,
): T {
  return Type.fromJsonValue(value, typeof encryptedValue === 'string' ? encryptedValue : undefined);
}
