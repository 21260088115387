import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';

const STROKE_DASHARRAY = 314.1592; // 2 * PI * 50 being 50 radius
const AVATAR_PROGRESS_CIRCLE_SEPARATION = 6;

/**
 * Progress Circle
 *
 * @ngModule ProgressModule
 */
@Component({
  selector: 'maia-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressCircleComponent implements OnInit, AfterViewInit {
  @ViewChild('progressCircleRef', {static: true, read: ElementRef})
  public progressCircleRef: ElementRef;

  @ViewChild('outerStrokeRef', {static: true, read: ElementRef})
  public outerStrokeRef: ElementRef;

  @ViewChild('avatarWrapper', {static: true, read: ElementRef})
  public avatarWrapper: ElementRef;

  @Input()
  public percentage: number;

  private _iconSize: number;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  public ngAfterViewInit(): void {
    this._iconSize = this.avatarWrapper.nativeElement.children[0].clientWidth;
    this._setProgressCircleSize();
    this._setProgress();
  }

  private _setProgressCircleSize(): void {
    this.renderer.setStyle(
      this.progressCircleRef.nativeElement,
      'width',
      `${this._iconSize + AVATAR_PROGRESS_CIRCLE_SEPARATION}px`,
    );
    this.renderer.setStyle(
      this.progressCircleRef.nativeElement,
      'height',
      `${this._iconSize + AVATAR_PROGRESS_CIRCLE_SEPARATION}px`,
    );
  }

  private _setProgress(): void {
    this.renderer.setStyle(
      this.outerStrokeRef.nativeElement,
      'stroke-dashoffset',
      `calc(${STROKE_DASHARRAY * (this.percentage / 100)}%)`,
    );
  }
}
