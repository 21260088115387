import {
  ChangeDetectorRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  Directive,
} from '@angular/core';
import {coerceNumberPrimitive} from '@atlas-angular/cdk/coercion';
import {WindowRef} from '@atlas-angular/cdk/globals';
import {Decimal, DecimalFormatter, DecimalUtils} from '@atlas/businesstypes';
import {CssClassUtilityFactory} from '@maia/core';

import {BaseNumberDisplayComponent} from '../../number-display/number-display.component';
import {DECIMAL_SEPARATOR} from '../../util';

const DEFAULT_STARTING_VALUE = '0';
const INTERSECTION_OPTIONS: IntersectionObserverInit = {
  threshold: 0.7,
};
const DEFAULT_DURATION = 4000;

function easeOutQuint(intervalValue: number, time: number, duration: number): number {
  return intervalValue * ((time = time / duration - 1) * time ** 4 + 1);
}

@Directive()
export abstract class BaseNumberDisplayAnimatedComponent
  extends BaseNumberDisplayComponent
  implements OnChanges, OnInit {
  private _startTime: any;
  @Input()
  @coerceNumberPrimitive()
  public duration = DEFAULT_DURATION;
  private _countTo: number;
  private _countFrom: number;
  private _animationFrame: any;
  private _intersected: boolean;
  private _triggerFinished: boolean;
  private _latestChanges: SimpleChanges;
  public animatedValue: string;
  public animatedFractional: string;
  public intersectionAnimationOptions = INTERSECTION_OPTIONS;

  /**
   * Emits when the animation is done.
   */
  @Output()
  public onAnimationFinished = new EventEmitter<void>();

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
    private readonly windowRef: WindowRef,
    private readonly changeDetector: ChangeDetectorRef,
  ) {
    super(cssClassUtilityFactory, renderer, elementRef);
  }

  public ngOnInit() {
    this._updateAnimatedValue(DEFAULT_STARTING_VALUE);
  }

  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this._latestChanges = changes;
    this._triggerFinished = false;
    this._reactToChanges(this._latestChanges);
  }

  private _valueChanged(changedValue: any) {
    if (changedValue.firstChange || !changedValue.previousValue) {
      this._countFrom = +DEFAULT_STARTING_VALUE;
    } else if (changedValue.previousValue) {
      this._countFrom = Number(DecimalUtils.toFixed(changedValue.previousValue, this.decimals));
    }
    if (changedValue.currentValue) {
      this._countTo = Number(DecimalUtils.toFixed(this.value, this.decimals));
    } else {
      this._countTo = this._countFrom;
    }
  }

  private _animateValue = (timestamp: number) => {
    if (!this._startTime) {
      this._startTime = timestamp;
    }
    let updatedVal: number;
    const time = timestamp - this._startTime;

    if (this._countTo < this._countFrom) {
      const intervalValue = this._countFrom - this._countTo;
      updatedVal = this._countFrom - easeOutQuint(intervalValue, time, this.duration);
      updatedVal = updatedVal < this._countTo ? this._countTo : updatedVal;
    } else {
      const intervalValue = this._countTo - this._countFrom;
      updatedVal = this._countFrom + easeOutQuint(intervalValue, time, this.duration);
      updatedVal = updatedVal > this._countTo ? this._countTo : updatedVal;
    }
    this._updateAnimatedValue(updatedVal.toFixed(this.decimals));

    if (time < this.duration) {
      this._animationFrame = this.windowRef.window.requestAnimationFrame(this._animateValue);
    } else {
      this._updateAnimatedValue(this._countTo.toFixed(this.decimals));
      this._triggerFinished = true;
      this.onAnimationFinished.emit();
    }
  };

  private _updateAnimatedValue(newValue: string) {
    const decimalValue = new Decimal(newValue);
    [this.animatedValue, this.animatedFractional] = DecimalFormatter.formatDecimal(
      decimalValue,
      this.decimals,
    ).split(DECIMAL_SEPARATOR);
    this.changeDetector.detectChanges();
  }

  private _clearAnimation() {
    cancelAnimationFrame(this._animationFrame);
    this._startTime = null;
  }

  private _needsTrigger(): boolean {
    return this._intersected && !this._triggerFinished;
  }

  private _reactToChanges(changes: SimpleChanges): void {
    if (this._needsTrigger()) {
      this._clearAnimation();
      if (changes.value) {
        this._valueChanged(changes.value);
        this._animationFrame = this.windowRef.window.requestAnimationFrame(this._animateValue);
      } else if (changes.decimals) {
        this._updateAnimatedValue(this._countTo.toFixed(this.decimals));
      }
    }
  }

  public intersectionAnimationChanged(entries: IntersectionObserverEntry[]) {
    const {intersectionRatio} = entries[0];
    if (intersectionRatio >= INTERSECTION_OPTIONS.threshold!) {
      this._intersected = true;
      if (this._latestChanges) {
        this._reactToChanges(this._latestChanges);
      }
    } else {
      this._intersected = false;
    }
  }
}
