import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Observable, Subject} from 'rxjs';

import {InputContainer} from '../input-container/input-container.interface';

/**
 * @ngModule FormsModule
 */
@Component({
  selector: 'maia-input-group',
  template: '<ng-content></ng-content>',
  styleUrls: ['./input-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class InputGroupComponent implements OnInit, OnDestroy {
  private _focused = false;
  private _disabled = false;
  private _alignLeft = false;

  private readonly _changed$ = new Subject<void>();
  public readonly changed$: Observable<void> = this._changed$.asObservable();

  public constructor(
    private readonly _cdr: ChangeDetectorRef,
    @Optional() private readonly _container?: InputContainer,
  ) {}

  @HostBinding('class.maia-input-group--focused')
  public get focus(): boolean {
    return this._focused;
  }

  @HostBinding('class.maia-input-group--disabled')
  public get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  @HostBinding('class.maia-input-group--align-left')
  public get alignLeft(): boolean {
    return this._alignLeft;
  }

  public set alignLeft(alignLeft: boolean) {
    this._alignLeft = coerceBooleanProperty(alignLeft);
  }

  public ngOnInit(): void {
    this._container?.focused$.pipe(takeUntilDestroyed(this)).subscribe(focused => {
      this._focused = focused;
      this._changed$.next();
      this._cdr.markForCheck();
    });

    this._container?.disabled$.pipe(takeUntilDestroyed(this)).subscribe(disabled => {
      this._disabled = disabled;
      this._changed$.next();
      this._cdr.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    this._changed$.complete();
  }
}
