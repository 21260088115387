import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';

import {HeaderInterface} from '../../header.interface';
import {BifrostHeaderVariant} from '../../variants/header-variant';
import {HeaderProperties} from '../properties/header-properties.service';
import {Logger} from '@atlas/logger';
import {LoggerFactory} from '@atlas-angular/logger';

@Injectable({
  providedIn: 'root',
})
export class HeaderController {
  private readonly logger: Logger;
  private readonly _header = new BehaviorSubject<HeaderInterface | null>(null);

  private _showBackButton: boolean;

  private _propertySubscriptions: Subscription;

  private _showCloseButton: boolean;
  private _showExtraActionButton: boolean;
  private _showLiveHelpButton: boolean;
  private _showKateButton: boolean;
  private _showFlowProgress: boolean;
  private _vetoIsForced: boolean;
  private _vetoCustomBody?: string;

  private readonly _markForCheck$ = new Subject<void>();
  public readonly markForCheck$ = this._markForCheck$.asObservable();

  public constructor(private readonly _properties: HeaderProperties, loggerFactory: LoggerFactory) {
    this.logger = loggerFactory.createLogger('@headers/HeaderController');
  }

  public setHeader(header: HeaderInterface) {
    const {_properties: props} = this;
    this._header.next(header);

    if (this._propertySubscriptions) {
      this._propertySubscriptions.unsubscribe();
    }
    this._propertySubscriptions = new Subscription();

    this._propertySubscriptions
      .add(
        props.showBackButton$.subscribe(v => {
          this._showBackButton = v;
          this._markForCheck$.next();
        }),
      )
      .add(
        props.showCloseButton$.subscribe(v => {
          this._showCloseButton = v;
          this._markForCheck$.next();
        }),
      )
      .add(
        props.showExtraActionButton$.subscribe(v => {
          this._showExtraActionButton = v;
          this._markForCheck$.next();
        }),
      )
      .add(
        props.showLiveHelpButton$.subscribe(v => {
          this._showLiveHelpButton = v;
          this._markForCheck$.next();
        }),
      )
      .add(
        props.showKateButton$.subscribe(v => {
          this._showKateButton = v;
          this._markForCheck$.next();
        }),
      )
      .add(
        props.showFlowProgress$.subscribe(v => {
          this._showFlowProgress = v;
          this._markForCheck$.next();
        }),
      )
      .add(
        props.vetoIsForced$.subscribe(v => {
          this._vetoIsForced = v;
          this._markForCheck$.next();
        }),
      )
      .add(
        props.vetoCustomBody$.subscribe(v => {
          this._vetoCustomBody = v;
          this._markForCheck$.next();
        }),
      );
  }

  public unsetHeader(): void {
    this._header.next(null);
    this._propertySubscriptions.unsubscribe();
  }

  private _getVariant(): BifrostHeaderVariant | null {
    return (this._header.getValue() || {variant: null}).variant;
  }

  public get showBackButton(): boolean {
    const variant = this._getVariant();
    if (variant !== null && variant.allowBackButton === false) {
      return false;
    } else {
      return this._showBackButton;
    }
  }

  public get showCloseButton(): boolean {
    return this._showCloseButton;
  }

  public get showExtraActionButton(): boolean {
    return this._showExtraActionButton;
  }

  public get showLiveHelpButton(): boolean {
    if (this._showKateButton) {
      this.logger.warn(
        'incorrect state for showLiveHelpButton. Cannot be true when kate should be shown',
      );
      return false;
    }
    return this._showLiveHelpButton;
  }

  public get showKateButton(): boolean {
    return this._showKateButton;
  }

  public get showFlowProgress(): boolean {
    return this._showFlowProgress;
  }

  public get vetoIsForced(): boolean {
    return this._vetoIsForced;
  }

  public get vetoCustomBody(): string | undefined {
    return this._vetoCustomBody;
  }

  /** @deprecated please use the {@link HeaderProperties} service directly.  */
  public overwriteVetoIsForced(value: boolean): Observable<void> {
    return this._properties.overwriteVetoIsForced(value);
  }

  /** @deprecated please use the {@link HeaderProperties} service directly.  */
  public overwriteVetoCustomBody(value?: string): Observable<void> {
    return this._properties.overwriteVetoCustomBody(value);
  }

  /** @deprecated please use the {@link HeaderProperties} service directly.  */
  public overwriteShowBackButton(value: boolean): Observable<void> {
    return this._properties.overwriteShowBackButton(value);
  }

  /** @deprecated please use the {@link HeaderProperties} service directly.  */
  public overwriteShowCloseButton(value: boolean): Observable<void> {
    return this._properties.overwriteShowCloseButton(value);
  }

  /** @deprecated please use the {@link HeaderProperties} service directly.  */
  public overwriteShowFlowProgress(value: boolean): Observable<void> {
    return this._properties.overwriteShowFlowProgress(value);
  }

  /** @deprecated please use the {@link HeaderProperties} service directly.  */
  public overwriteShowLiveHelpButton(value: boolean): Observable<void> {
    return this._properties.overwriteShowLiveHelpButton(value);
  }

  public canGoBack(): boolean {
    return (this._header.getValue() || {canGoBack: () => true}).canGoBack();
  }
}
