import {Pipe, PipeTransform} from '@angular/core';
import {ClientNumber, ClientNumberFormatter} from '@atlas/businesstypes';

/**
 * @ngModule BusinessTypesModule
 */
@Pipe({name: 'atlasClientNumber'})
export class ClientNumberPipe implements PipeTransform {
  public transform(value: any): string {
    return ClientNumber.isClientNumber(value) ? ClientNumberFormatter.format(value) : value;
  }
}
