import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';
import {ActionsModule} from '@maia/actions';
import {CollapsiblesModule} from '@maia/collapsibles';
import {μAdditionalInformationSvgModule} from '@maia/icons';

import {AdditionalInformationCollapseComponent} from './additional-information-collapse/additional-information-collapse.component';
import {AdditionalInformationCustomComponent} from './additional-information-custom/additional-information-custom.component';
import {AdditionalInformationSlideInComponent} from './additional-information-slide-in/additional-information-slide-in.component';

@NgModule({
  imports: [
    ActionsModule,
    BusinessTypesModule,
    CommonModule,
    CollapsiblesModule,
    μAdditionalInformationSvgModule,
  ],
  declarations: [
    AdditionalInformationSlideInComponent,
    AdditionalInformationCollapseComponent,
    AdditionalInformationCustomComponent,
  ],
  exports: [
    AdditionalInformationSlideInComponent,
    AdditionalInformationCollapseComponent,
    AdditionalInformationCustomComponent,
  ],
})
export class AdditionalInformationModule {}
