import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';
import {εDocumentLinkSvgModule} from '@hermes/icons';
import {ActionsModule} from '@maia/actions';

import {DocumentGroupComponent} from './document-group/document-group.component';
import {DocumentLinkComponent} from './document-link/document-link.component';

@NgModule({
  imports: [εDocumentLinkSvgModule, BusinessTypesModule, CommonModule, ActionsModule],
  declarations: [
    DocumentGroupComponent,
    DocumentLinkComponent,
  ],
  exports: [
    DocumentGroupComponent,
    DocumentLinkComponent,
  ],
})
export class OpenResourcesModule {
  public constructor() {}
}
