import {HttpClientModule} from '@angular/common/http';
import {NgModule, Optional} from '@angular/core';
import {SignResponseExtractor} from '@atlas/convertor';

import {AdditionalHeadersInterceptor} from './interceptors/additional-headers-interceptor';
import {DefaultHeadersInterceptor} from './interceptors/default-headers-interceptor';
import {RequestParametersInterceptor} from './interceptors/request-parameters-interceptor';
import {XsrfHeaderInterceptor} from './interceptors/xsrf-header-interceptor';
import {provideConnectorInterceptorClass} from './providers';
import {CombinedConnectorInterceptor} from './services/combined-connector-interceptor.service';
import {CombinedDynamicResourceUrlResolver} from './services/combined-dynamic-resource-url-resolver.service';
import {CombinedPathResolver} from './services/combined-path-resolver.service';
import {Connector} from './services/connector.service';
import {DynamicResourceUrlGenerator} from './services/dynamic-resource-url-generator.service';
import {NoopSignResponseExtractor} from './services/sign-response-extractor.service';
import {SIGN_RESPONSE_EXTRACTOR_TOKEN} from './tokens/sign-response-extractor';

/**
 * Only exported for AOT compilation, not exported from index.ts
 */
export function createSignResponseExtractor(
  extractor: SignResponseExtractor | null,
): SignResponseExtractor {
  if (extractor != null) {
    return extractor;
  } else {
    return new NoopSignResponseExtractor();
  }
}

/**
 * The ConnectorModule registers the Connector service which can be used to
 * access API endpoints.
 */
@NgModule({
  imports: [HttpClientModule],
  providers: [
    Connector,
    CombinedPathResolver,
    CombinedConnectorInterceptor,

    DynamicResourceUrlGenerator,
    CombinedDynamicResourceUrlResolver,

    // WARNING! The order of the connector interceptors is important!
    // `DefaultHeadersInterceptor` and `XsrfHeaderInterceptor` should definitely be the last entries
    // because they provide default values that should not overwrite existing values.
    provideConnectorInterceptorClass(AdditionalHeadersInterceptor),
    provideConnectorInterceptorClass(RequestParametersInterceptor),
    provideConnectorInterceptorClass(DefaultHeadersInterceptor),
    provideConnectorInterceptorClass(XsrfHeaderInterceptor),

    {
      provide: SignResponseExtractor,
      useFactory: createSignResponseExtractor,
      deps: [[SIGN_RESPONSE_EXTRACTOR_TOKEN, new Optional()]],
    },
  ],
})
export class ConnectorModule {}

/**
 * The `ChildConnectorModule` creates a child connector.
 *
 * This module can be used inside a lazy loaded module (e.g. lazy loaded route). At the level this
 * module is loaded, extra path resolvers, dynamic resource resolvers and connector interceptors can
 * be regsitered. These will only be available to the child `Connector` provided by this module, not
 * the global `Connector` from the `ConnectorModule`.
 *
 * This module requires the `ConnectorModule` to already have been loaded at a higher level, i.e.
 * in the `AppModule`.
 */
@NgModule({
  providers: [
    Connector,
    CombinedPathResolver,
    CombinedConnectorInterceptor,

    DynamicResourceUrlGenerator,
    CombinedDynamicResourceUrlResolver,
  ],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class ChildConnectorModule {
  public constructor(@Optional() parent?: ConnectorModule) {
    if (parent == null) {
      throw new Error(
        `ChildConnectorModule can only be used inside a lazy loaded module with a ConnectorModule at a higher level`,
      );
    }
  }
}
