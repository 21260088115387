import {Injectable, Optional, Provider, SkipSelf, TemplateRef} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Observable} from 'rxjs';

import {InputContainer} from './input-container.interface';

export function createNestedInputContainer(
  container: InputContainer | null,
): InputContainer | null {
  return container && new NestedInputContainer(container);
}

export function provideNestedInputContainer(): Provider {
  return {
    provide: InputContainer,
    useFactory: createNestedInputContainer,
    deps: [[InputContainer, new Optional(), new SkipSelf()]],
  };
}

@Injectable()
@UntilDestroy()
export class NestedInputContainer implements InputContainer {
  public readonly disabled$: Observable<boolean>;
  public readonly focused$: Observable<boolean>;
  public readonly optional$: Observable<boolean>;
  public readonly validationErrorChange$: Observable<void>;

  public constructor(private readonly _parent: InputContainer) {
    this.disabled$ = _parent.disabled$.pipe(takeUntilDestroyed(this));
    this.focused$ = _parent.focused$.pipe(takeUntilDestroyed(this));
    this.optional$ = _parent.optional$.pipe(takeUntilDestroyed(this));
    this.validationErrorChange$ = _parent.validationErrorChange$.pipe(takeUntilDestroyed(this));
  }

  public get errorString(): string | undefined {
    return this._parent.errorString;
  }

  public get errorTemplate(): TemplateRef<any> | undefined {
    return this._parent.errorTemplate;
  }

  public get disabled(): boolean {
    return this._parent.disabled;
  }

  public get focused(): boolean {
    return this._parent.focused;
  }

  public set focused(focused: boolean) {
    this._parent.focused = focused;
  }

  public get optional(): boolean {
    return this._parent.optional;
  }

  public hasError(): boolean {
    return this._parent.hasError();
  }

  /**
   * No-op
   */
  public registerFormControl(): () => void {
    // Don't actually register the form control, as this is nested
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }
}
