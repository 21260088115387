import {A11yModule} from '@angular/cdk/a11y';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ModalsModule} from '@maia/modals';

import {PopUpController} from './controller/pop-up-controller.service';
import {PopUpFooterDirective} from './footer/footer.directive';
import {PopUpComponent} from './pop-up/pop-up.component';

@NgModule({
  imports: [A11yModule, CommonModule, ModalsModule],
  declarations: [PopUpComponent, PopUpFooterDirective],
  exports: [PopUpFooterDirective],
  entryComponents: [PopUpComponent],
  providers: [PopUpController],
})
export class PopUpsModule {}
