import {Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {CssClassUtility, CssClassUtilityFactory} from '@maia/core';
import {
  CellAlign,
  CellAlignClasses,
  CellAlignLgClasses,
  CellAlignMdClasses,
  CellAlignSmClasses,
  CellAlignXlClasses,
} from './utils';

/**
 * CellAlignDirective to determine mobile-first breakpoint class
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-cell[cellAlign]',
})
export class CellAlignDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof CellAlignClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(CellAlignClasses, renderer, elementRef);
  }

  @Input()
  public set cellAlign(cellAlign: CellAlign | null) {
    this._cssClassUtility.setValue('cellAlign', cellAlign);
  }
}

/**
 * CellAlignSmDirective to determine Small breakpoint class
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-cell[cellAlignSm]',
})
export class CellAlignSmDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof CellAlignSmClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(CellAlignSmClasses, renderer, elementRef);
  }

  @Input()
  public set cellAlignSm(cellAlignSm: CellAlign | null) {
    this._cssClassUtility.setValue('cellAlignSm', cellAlignSm);
  }
}

/**
 * CellAlignMdDirective to determine Medium breakpoint class
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-cell[cellAlignMd]',
})
export class CellAlignMdDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof CellAlignMdClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(CellAlignMdClasses, renderer, elementRef);
  }

  @Input()
  public set cellAlignMd(cellAlignMd: CellAlign | null) {
    this._cssClassUtility.setValue('cellAlignMd', cellAlignMd);
  }
}

/**
 * CellAlignLgDirective to determine Large breakpoint class
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-cell[cellAlignLg]',
})
export class CellAlignLgDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof CellAlignLgClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(CellAlignLgClasses, renderer, elementRef);
  }

  @Input()
  public set cellAlignLg(cellAlignLg: CellAlign | null) {
    this._cssClassUtility.setValue('cellAlignLg', cellAlignLg);
  }
}

/**
 * CellAlignXlDirective to determine Extra Large breakpoint class
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-cell[cellAlignXl]',
})
export class CellAlignXlDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof CellAlignXlClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(CellAlignXlClasses, renderer, elementRef);
  }

  @Input()
  public set cellAlignXl(cellAlignXl: CellAlign | null) {
    this._cssClassUtility.setValue('cellAlignXl', cellAlignXl);
  }
}
