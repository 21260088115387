import {Injectable} from '@angular/core';
import {Keepsake, NotFoundError, StorageFactory} from 'keepsake';

import {createIdentityConverter, DeviceStorageConverter} from './device-storage-converter';
import {JsonValue} from './json';
import {StorageConfiguration} from './storage.configuration';

/**
 * Structure in which the device data is stored in the device storage
 *
 * The structure is always {
 *  value: <value>
 * }, value can be different types
 */
export interface DeviceStorage<T = unknown> {
  value: T;
}

/**
 * Fabricates device storage accessors.
 * @see {@link DeviceStorageAccessor}
 */
@Injectable()
export class DeviceStorageAccessorFactory {
  public constructor(
    private readonly storageFactory: StorageFactory,
    private readonly storageConfiguration: StorageConfiguration,
  ) {}

  /**
   * Fabricates a `DeviceStorageAccessor`. Orly? Yarly!
   * @see {@link DeviceStorageAccessor}
   *
   * @param key The key under which the value object is stored in the device storage
   * @param converter
   * utility methods to convert the stored value to the value to use in code, and vice versa
   * @see {@link DeviceStorageConverter}
   * @returns {DeviceStorageAccessor<TStored, TConverted>} the created device storage accessor
   */
  public createAccessor<TStored extends JsonValue, TConverted = TStored>(
    key: string,
    converter: DeviceStorageConverter<TStored, TConverted>,
  ): DeviceStorageAccessor<TConverted> {
    return new DeviceStorageAccessorImpl<TStored, TConverted>(
      this.storageFactory,
      this.storageConfiguration,
      key,
      converter,
    );
  }

  /**
   * Creates a {@link DeviceStorageAccessor}, which uses the stored value as the converted value and
   * vice versa. No conversion is done between the stored value and the converted value
   *
   * Naming was based on the identity function in functional programming
   *
   * @see {@link DeviceStorageAccessor}
   *
   * @param key The key under which the value object is stored in the device storage
   * @see {@link DeviceStorageConverter}
   * @returns {DeviceStorageAccessor<TStored>} the created device storage accessor
   */
  public createIdentityAccessor<TStored extends JsonValue>(
    key: string,
  ): DeviceStorageAccessor<TStored> {
    return new DeviceStorageAccessorImpl<TStored>(
      this.storageFactory,
      this.storageConfiguration,
      key,
      createIdentityConverter<TStored>(),
    );
  }
}

/**
 * Accesses the device storage.
 *
 * 'Device storage' is the storage used for device-specific settings. While user settings are
 * user-specific, device settings are saved on a per-device basis.
 */
export abstract class DeviceStorageAccessor<T> {
  /**
   * Gets the value from the device storage. If no value is saved in storage for the given key it
   * will return the defaultValue, if set. If not, it will throw an error.
   *
   * @param defaultValue The default value which is returned if no value can be found for the given
   * key in the storage
   * @returns {Promise}
   */
  public abstract get(defaultValue?: T): Promise<T>;
  /**
   * Saves the value to the device storage
   *
   * @param value
   * @returns {Promise<void>}
   */
  public abstract save(value: T): Promise<void>;
}

class DeviceStorageAccessorImpl<
  TStored extends JsonValue,
  TConverted = TStored
> extends DeviceStorageAccessor<TConverted> {
  private static _keepsake?: Promise<Keepsake<DeviceStorage>> = undefined;

  private get keepsake(): Promise<Keepsake<DeviceStorage<TStored>>> {
    if (!DeviceStorageAccessorImpl._keepsake) {
      DeviceStorageAccessorImpl._keepsake = this.storageFactory.createStorage<DeviceStorage>(
        `${this.storageConfiguration.prefix}Device`,
      );
    }
    return DeviceStorageAccessorImpl._keepsake as Promise<Keepsake<DeviceStorage<TStored>>>;
  }

  public constructor(
    private readonly storageFactory: StorageFactory,
    private readonly storageConfiguration: StorageConfiguration,
    private readonly key: string,
    private readonly converter: DeviceStorageConverter<TStored, TConverted>,
  ) {
    super();
  }

  public async get<U>(defaultValue?: U): Promise<TConverted | U> {
    try {
      const device = await (await this.keepsake).get(this.key);
      return this.converter.convertFromStorage(device.value);
    } catch (e) {
      if (defaultValue !== undefined && e instanceof NotFoundError) {
        return defaultValue;
      }
      throw e;
    }
  }

  public async save(value: TConverted): Promise<void> {
    const device: DeviceStorage<TStored> = {
      value: this.converter.convertToStorage(value),
    };
    return (await this.keepsake).save(this.key, device);
  }
}
