import {BusinessType, createStringifier} from '../base/businesstype';

import {isValidVat} from './validator-base';

const stringifier = createStringifier('vat');

/**
 * BusinessType wrapper around vat, exposing the vat
 * number as a formatted text.
 */
export class Vat extends BusinessType<string> {
  /**
   * Returns whether or not `value` is a Vat.
   */
  public static isVat(value: unknown): value is Vat {
    return value instanceof Vat;
  }

  /**
   * Constructs a new Vat instance.
   *
   * @param value The vat value
   * @param encryptedValue The encryptedValue, if any
   */
  public constructor(value: string, encryptedValue?: string) {
    if (typeof value !== 'string') {
      throw new Error(
        `Expected a string but got ${(typeof value === 'object' &&
          (value as object).constructor.name) ||
          typeof value}`,
      );
    } else {
      if (!isValidVat(value)) {
        throw new Error(`Cannot create Vat for invalid input ${JSON.stringify(value)}`);
      }
    }
    super(value, encryptedValue);
  }

  /**
   * Returns whether `other` is a `Vat` representing the same value.
   */
  public equals(other: unknown): boolean {
    if (!other) {
      return false;
    }

    if (other === this) {
      return true;
    }

    return Vat.isVat(other) && this.internalValue === other.internalValue;
  }

  /**
   * Exposes the value of this BusinessType as a string. The returned value will
   * always be the same.
   */
  public asString(): string {
    return this.internalValue;
  }

  public toString(): string {
    return stringifier(this);
  }
}
