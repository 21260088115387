import {ChangeDetectionStrategy, Component, OnInit, Input, HostBinding} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {Alignment, ImagePosition} from '@maia/layout';

/**
 * This component is used to display the most important details of a deal.
 *
 * @ngModule TilesModule
 */
@Component({
  selector: 'button[maiaTileDeal]',
  templateUrl: './tile-deal.component.html',
  styleUrls: ['./tile-deal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileDealComponent implements OnInit {
  public alignment: Alignment = Alignment.Inline;
  public imagePosition: ImagePosition = ImagePosition.End;

  @Input()
  public companyName: string | Text;

  @Input()
  public discount: string | Text;

  @Input()
  public extraConditions: string | Text;

  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('attr.disabled')
  @HostBinding('class.p-maia-tile-deal--disabled')
  public disabled = false;

  public ngOnInit() {
    if (!this.companyName || !this.discount || !this.extraConditions) {
      throw new Error('Cannot use `maia-tile-deal` if these properties are not defined.');
    }
  }
}
