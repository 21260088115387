import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

/**
 * This is a layout component with 2 children.
 * The first child is fixed and should have a fixed width or child with fixed width.
 * The second child is fluid and will adjust width on available space.
 * The two sections can either be top or center aligned.(default top aligned)
 *
 * The exact width of the left section and the margin between the sections depend on the content and styling.
 *
 * ```html
 * <maia-fixed-fluid-layout  alignContentCenter>
 *  <div> ... </div>
 *  <div> ... </div>
 * </maia-fixed-fluid-layout>
 * ```
 *
 * @ngModule LayoutModule
 */
@Component({
  selector: 'maia-fixed-fluid-layout',
  template: '<ng-content></ng-content>',
  styleUrls: ['./fixed-fluid-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FixedFluidLayoutComponent {
  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('class.maia-fluid-fluid-layout--align-top')
  public alignContentTop = true;

  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('class.maia-fluid-fluid-layout--align-center')
  public alignContentCenter = false;
}
