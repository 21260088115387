import {HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Call, ConnectorResponseBase} from '@atlas/convertor';
import {Observable} from 'rxjs';

import {ConnectorBackend, ConnectorInterceptor} from '../interfaces/interceptor';

/**
 * A `ConnectorInterceptor` that will add the headers from the `Call`s `options` property to the
 * `HttpRequest` object.
 *
 * It will only add headers that are not yet present, so it won't overwrite existing headers.
 */
@Injectable()
export class AdditionalHeadersInterceptor implements ConnectorInterceptor {
  public intercept<I, O>(
    call: Call<I, O>,
    input: I,
    request: HttpRequest<null>,
    next: ConnectorBackend<O>,
  ): Observable<ConnectorResponseBase<O>> {
    if (call.options.headers == null) {
      return next.handle(request);
    }
    let headers = request.headers;
    call.options.headers.forEach((value: string, key: string): void => {
      if (!headers.has(key)) {
        headers = headers.set(key, value);
      }
    });
    return next.handle(request.clone({headers}));
  }
}
