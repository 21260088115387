import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';

import {μDisplaysSvgModule} from '@maia/icons';
import {MiniButtonsModule} from '@maia/mini-buttons';

import {AmountDisplayComponent} from './amount-display/amount-display.component';
import {AvatarDisplayComponent} from './avatar-display/avatar-display.component';
import {BannerComponent} from './banner/banner.component';
import {DurationDisplayComponent} from './duration-display/duration-display.component';
import {NumberDisplayComponent} from './number-display/number-display.component';
import {OpeningHoursDisplayItemComponent} from './opening-hours-display-item/opening-hours-display-item.component';
import {OpeningHoursDisplayLineComponent} from './opening-hours-display-line/opening-hours-display-line.component';
import {OpeningHoursDisplayComponent} from './opening-hours-display/opening-hours-display.component';
import {PercentageDisplayComponent} from './percentage-display/percentage-display.component';

@NgModule({
  imports: [BusinessTypesModule, CommonModule, MiniButtonsModule, μDisplaysSvgModule],
  declarations: [
    AmountDisplayComponent,
    AvatarDisplayComponent,
    BannerComponent,
    DurationDisplayComponent,
    NumberDisplayComponent,
    PercentageDisplayComponent,
    OpeningHoursDisplayComponent,
    OpeningHoursDisplayItemComponent,
    OpeningHoursDisplayLineComponent,
  ],
  exports: [
    AmountDisplayComponent,
    AvatarDisplayComponent,
    BannerComponent,
    DurationDisplayComponent,
    NumberDisplayComponent,
    PercentageDisplayComponent,
    OpeningHoursDisplayComponent,
    OpeningHoursDisplayItemComponent,
    OpeningHoursDisplayLineComponent,
  ],
})
export class DisplaysModule {}
