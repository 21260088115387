import {CallOptions, CallType} from '@atlas/convertor';
import {TypedJSONCall} from '@atlas/convertor-typed-json';
import {HermesContextualPathIdentifier} from '@hermes/core';
import {FileUploadCall, FileUploaderCallFactory, FileUploadOutput} from '@hermes/file-uploader';

export class TypedJsonFileUploaderCallFactory extends FileUploaderCallFactory {
  public getFileUploadCall(): FileUploadCall {
    return new FileUploadTypedJsonCall();
  }
}

export class FileUploadTypedJsonCall extends TypedJSONCall<FormData, FileUploadOutput>
  implements FileUploadCall {
  public options: CallOptions = {callType: CallType.COMMIT};
  public identifier = new HermesContextualPathIdentifier('fileUpload', '1');

  public convertInput(input: FormData) {
    // Ensure default converter for input is not used, because it makes input no longer an instance
    // of FormData. For more info: HERMES-420
    return input;
  }
}
