/**
 * The modal control allows the modal content to control the modal.
 */
export abstract class ModalControl<O, I = undefined> {
  /**
   * The input passed into the modal
   */
  public readonly input: I;

  /**
   * Confirms the modal, with the given data.
   *
   * @param data The data to confirm with
   */
  public abstract confirm(data: O): void;

  /**
   * Cancels the modal. This method can be used by a cancel button inside the modal,
   * or e.g. when the user clicks the modal's backdrop.
   */
  public abstract cancel(): void;

  /**
   * Dismisses the modal. This method should only be used when the modal is closed
   * programmatically, e.g. if the user is redirected to another page and the modal
   * is no longer necessary.
   */
  public abstract dismiss(): void;
}
