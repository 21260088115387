import {animate, style, transition, trigger} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  InjectionToken,
  OnInit,
  TemplateRef,
} from '@angular/core';
import {DocumentRef} from '@atlas-angular/cdk/globals';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Utilities} from '@maia/core';
import {captureFocus, ModalControl} from '@maia/modals';

import {FooterContainer} from '../footer/footer-container.service';

/**
 * Options to be passed when creating a pop-up.
 */
export interface PopUpOptions {
  /**
   * The pop-up title.
   */
  title: string;

  /**
   * The pop-up size. If it is absent the popup is normal.
   */
  size?: 'normal' | 'large';

  /**
   * If this value is true, the footer is part of the scroll area of the content. If it is false or
   * absent, the footer is always visible at the bottom of the screen.
   */
  footerInScrollArea?: boolean;
}

export const POPUP_OPTIONS = new InjectionToken<PopUpOptions>('popUpOptions');

/**
 * Identifier used as type in the modal lifecycle callbacks.
 */
export const MODAL_TYPE_POP_UP = 'maiaPopUp';

const generateTitleId = Utilities.createIdGenerator('maia-pop-up-title');

/**
 * @ngModule PopUpsModule
 */
@Component({
  selector: 'maia-pop-up',

  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,

  animations: [
    trigger('popUp', [
      transition(':enter', [
        style({opacity: '0'}),
        animate(300, style({opacity: '1'})),
        style('*'),
      ]),
      transition(':leave', [style({opacity: '1'}), animate(300, style({opacity: '0'}))]),
    ]),
  ],
  host: {
    '[@popUp]': '"in"',
    '[class.maia-pop-up--large]': `options.size === 'large'`,
    tabindex: '-1',
  },
})
@UntilDestroy()
export class PopUpComponent implements OnInit {
  public footer?: TemplateRef<any> = undefined;
  public readonly titleId = generateTitleId();

  public constructor(
    @Inject(POPUP_OPTIONS) public readonly options: PopUpOptions,
    public readonly control: ModalControl<any>,
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly _footerContainer: FooterContainer,
    private readonly _documentRef: DocumentRef,
    private readonly _elementRef: ElementRef<HTMLElement>,
  ) {}

  public ngOnInit(): void {
    captureFocus(this, this._documentRef.document, this._elementRef);

    this._footerContainer.footer$.pipe(takeUntilDestroyed(this)).subscribe(footer => {
      this.footer = footer;
      this._changeDetector.detectChanges();
    });
  }
}
