import {AfterViewInit, Component, ElementRef, OnDestroy, Renderer2, ViewChild} from '@angular/core';
import {WindowRef} from '@atlas-angular/cdk/globals';
import {DomIoService} from '@maia/core';

const INNER_SIZE = 32;
const DURATION = 2000;
const TWO_PI = 2 * Math.PI;

let browserCheckPromise: Promise<boolean> | null = null;

/**
 * Function used by tests to reset the browser check promise
 *
 * Only exported from this file, not to be exported from the module itself. This function can easily
 * be removed by treeshakers like rollup and webpack.
 *
 * @internal
 */
export function resetBrowserCheck(): void {
  browserCheckPromise = null;
}

/**
 * A simple spinner component. This spinner will spin forever.
 * Adding/removing the spinner, positioning the spinner and providing a backdrop (if needed) are
 * responsibility of the containing components.
 *
 * @ngModule SpinnersModule
 */
@Component({
  selector: 'maia-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements AfterViewInit, OnDestroy {
  private readonly start: number;
  private stopped = false;

  @ViewChild('inner', {static: true})
  private readonly innerElementRef: ElementRef;
  @ViewChild('outer', {static: true})
  private readonly outerElementRef: ElementRef;
  @ViewChild('spinner', {static: true})
  private readonly spinnerElementRef: ElementRef;

  private inner: SVGElement;
  private outer: SVGElement;
  private spinner: SVGElement;

  public constructor(
    private readonly domIo: DomIoService,
    private readonly renderer: Renderer2,
    private readonly windowRef: WindowRef,
  ) {
    this.start = Date.now();
  }

  private checkBrowser(): Promise<boolean> {
    if (browserCheckPromise != null) {
      return browserCheckPromise;
    }

    return (browserCheckPromise = this.domIo.measure(() => {
      return 'msAnimation' in this.windowRef.window.getComputedStyle(this.inner);
    }));
  }

  private transformInner(angle: number): void {
    this.renderer.setAttribute(this.inner, 'transform', `scale(-1 1) rotate(${angle})`);
  }

  private changeStrokeInner(offset: number): void {
    this.renderer.setStyle(this.inner, 'stroke-dashoffset', offset * TWO_PI * INNER_SIZE);
  }

  // istanbul ignore next This function is hard to test completely and testing it is quite useless
  private frame(): void {
    if (this.stopped) {
      return;
    }
    const position = ((Date.now() - this.start) % DURATION) / DURATION;

    // them would reduce readability
    // inner dashoffset
    if (position < 0.08) {
      this.changeStrokeInner(0.9);
    } else if (position < 0.5) {
      this.changeStrokeInner(0.9 - 0.75 * (position - 0.08) * 2.38);
    } else if (position < 0.6) {
      this.changeStrokeInner(0.15);
    } else {
      this.changeStrokeInner(0.15 + 0.75 * (position - 0.6) * 2.5);
    }
    // inner rotation
    if (position < 0.08) {
      this.transformInner(630 - position * 12.5 * 90);
    } else if (position < 0.5) {
      this.transformInner(540 - (position - 0.08) * 2.38 * 450);
    } else {
      this.transformInner(90 - (position - 0.5) * 2 * 180);
    }
    this.windowRef.window.requestAnimationFrame(() => this.frame());
  }

  public ngAfterViewInit(): void {
    this.inner = this.innerElementRef.nativeElement;
    this.outer = this.outerElementRef.nativeElement;
    this.spinner = this.spinnerElementRef.nativeElement;

    this.checkBrowser().then(jsAnimationNeeded => {
      if (!jsAnimationNeeded) {
        return;
      }

      // make transform origin center
      this.renderer.setAttribute(this.outer, 'cx', '0');
      this.renderer.setAttribute(this.outer, 'cy', '0');
      this.renderer.setAttribute(this.inner, 'cx', '0');
      this.renderer.setAttribute(this.inner, 'cy', '0');

      // center svg
      this.renderer.setAttribute(this.spinner, 'viewBox', '-50 -50 100 100');

      this.windowRef.window.requestAnimationFrame(() => this.frame());
    });
  }

  public ngOnDestroy(): void {
    this.stopped = true;
  }
}
