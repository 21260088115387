// don't shorten the import below to '..' to avoid circular dependencies
import {
  AtlasInvalidCharactersValidationErrors,
  AtlasInvalidCheckDigitsValidationErrors,
  AtlasInvalidLengthValidationErrors,
  combineResults,
  isValid,
  RESULT_VALID,
  ValidationErrors,
  ValidatorInput,
  ValidValidationResult,
} from '../base/validators';
import {isValidDate} from '../date/index';

import {
  CHECK_DIGITS_MODULO_BASE,
  CHECK_DIGITS_START,
  DATE_FIRST_MONTH,
  DATE_LAST_MONTH,
  FOREIGNER_GENDER_KNOWN,
  FOREIGNER_GENDER_NOT_KNOWN,
  NationalRegisterNumber,
  NON_CHECK_DIGITS_LENGTH,
} from './businesstype';

const NATIONAL_REGISTER_NUMBER_LENGTH = 11;
const DATE_PART_LENGTH = 6;

export interface AtlasInvalidDateValidationErrors extends ValidationErrors {
  atlasInvalidDate: true;
}

/**
 * Checks the validity of the given input's characters.
 *
 * @param value The national register number to validate
 */
function invalidCharacters(
  value: ValidatorInput<NationalRegisterNumber>,
): AtlasInvalidCharactersValidationErrors | ValidValidationResult {
  if (NationalRegisterNumber.isNationalRegisterNumber(value)) {
    return RESULT_VALID;
  }

  if (!value || validateCharacters(value)) {
    return RESULT_VALID;
  }

  return {
    atlasInvalidCharacters: true,
  };
}

/**
 * Checks the validity of the given input's length.
 *
 * @param value The national register number to validate
 */
function invalidLength(
  value: ValidatorInput<NationalRegisterNumber>,
): AtlasInvalidLengthValidationErrors | ValidValidationResult {
  if (NationalRegisterNumber.isNationalRegisterNumber(value)) {
    return RESULT_VALID;
  }

  if (!!value && validateLength(value)) {
    return RESULT_VALID;
  }

  return {
    atlasInvalidLength: {
      requiredLength: NATIONAL_REGISTER_NUMBER_LENGTH,
      actualLength: value ? value.length : 0,
    },
  };
}

function invalidDate(
  value: ValidatorInput<NationalRegisterNumber>,
): AtlasInvalidDateValidationErrors | ValidValidationResult {
  if (NationalRegisterNumber.isNationalRegisterNumber(value)) {
    return RESULT_VALID;
  }

  // Do not trigger date validation until we have a national register number with a minimum
  // length of 6 valid characters.
  if (!value || !validateCharacters(value) || value.length < DATE_PART_LENGTH) {
    return RESULT_VALID;
  }

  return validateDate(value) ? RESULT_VALID : {atlasInvalidDate: true};
}

/**
 * Checks if national register number's check digits are correct.
 *
 * @param value The national register number to validate
 */
function invalidCheckDigits(
  value: ValidatorInput<NationalRegisterNumber>,
): AtlasInvalidCheckDigitsValidationErrors | ValidValidationResult {
  if (NationalRegisterNumber.isNationalRegisterNumber(value)) {
    return RESULT_VALID;
  }

  // Do not trigger checksum validation until we have a national register number with the
  // correct length consisting only out of valid characters.
  if (!value || !validateLength(value) || !validateCharacters(value)) {
    return RESULT_VALID;
  }

  return validateCheckDigits(value) ? RESULT_VALID : {atlasInvalidCheckDigits: true};
}

/**
 * The BaseNationalRegisterNumberValidators object contains partial national register number
 * validators returning information on the invalidity instead of simply returning a boolean
 * valid/invalid result.
 *
 * @deprecated Use `validateNationalRegisterNumber` instead
 */
export const BaseNationalRegisterNumberValidators: {
  /**
   * Checks the validity of the given input's characters.
   *
   * @param value The national register number to validate
   */
  readonly invalidCharacters: (
    value: ValidatorInput<NationalRegisterNumber>,
  ) => AtlasInvalidCharactersValidationErrors | ValidValidationResult;
  /**
   * Checks the validity of the given input's length.
   *
   * @param value The national register number to validate
   */
  readonly invalidLength: (
    value: ValidatorInput<NationalRegisterNumber>,
  ) => AtlasInvalidLengthValidationErrors | ValidValidationResult;
  readonly invalidDate: (
    value: ValidatorInput<NationalRegisterNumber>,
  ) => AtlasInvalidDateValidationErrors | ValidValidationResult;
  /**
   * Checks if national register number's check digits are correct.
   *
   * @param value The national register number to validate
   */
  readonly invalidCheckDigits: (
    value: ValidatorInput<NationalRegisterNumber>,
  ) => AtlasInvalidCheckDigitsValidationErrors | ValidValidationResult;
} = {
  invalidCharacters,
  invalidLength,
  invalidDate,
  invalidCheckDigits,
};

/**
 * Checks if the national register number only contains the correct characters (decimals).
 *
 * @param nationalRegisterNumber The national register number to validate
 * @returns True in case the national register number characters are correct
 */
function validateCharacters(nationalRegisterNumber: string): boolean {
  return /^\d+$/.test(nationalRegisterNumber);
}

/**
 * Performs the length check on the given national register number.
 *
 * @param nationalRegisterNumber The national register number to validate
 * @returns True in case the national register number has the correct length
 */
function validateLength(nationalRegisterNumber: string): boolean {
  return nationalRegisterNumber.length === NATIONAL_REGISTER_NUMBER_LENGTH;
}

/**
 * Checks if the birth date contained within the nationatal register number is correct.
 *
 * @param nationalRegisterNumber The national register number to validate
 * @returns True in case the date contained within the national register number is correct
 */
function validateDate(nationalRegisterNumber: string): boolean {
  const [year, month, day] = nationalRegisterNumber
    .substr(0, DATE_PART_LENGTH)
    .match(/.{1,2}/g) as string[];

  // If the person is a refugee and the birthdate is not known,
  // then the month and day are set to 00.
  if (month === '00' && day === '00') {
    return true;
  }

  // If the person is a foreigner and the gender is not known
  // at the time of registration then the month is increased by 20,
  // otherwhise it is increased by 40.
  let monthAsNumber: number = parseInt(month, 10);
  if (
    monthAsNumber >= FOREIGNER_GENDER_NOT_KNOWN + DATE_FIRST_MONTH &&
    monthAsNumber <= FOREIGNER_GENDER_NOT_KNOWN + DATE_LAST_MONTH
  ) {
    monthAsNumber -= FOREIGNER_GENDER_NOT_KNOWN;
  } else if (
    monthAsNumber >= FOREIGNER_GENDER_KNOWN + DATE_FIRST_MONTH &&
    monthAsNumber <= FOREIGNER_GENDER_KNOWN + DATE_LAST_MONTH
  ) {
    monthAsNumber -= FOREIGNER_GENDER_KNOWN;
  }

  let formattedMonth = monthAsNumber.toString();
  if (formattedMonth.length === 1) {
    formattedMonth = `0${formattedMonth}`;
  }

  return isValidDate(`${day}${formattedMonth}19${year}`);
}

/**
 * Performs the check digit calculation on the national register number.
 *
 * @param nationalRegisterNumber The national register number to validate
 * @returns True in case the check digits of the national register number are correct
 */
function validateCheckDigits(nationalRegisterNumber: string): boolean {
  const checkDigitsStart = CHECK_DIGITS_START;
  const nonCheckDigitsLength = NON_CHECK_DIGITS_LENGTH;
  const checkDigit: number = parseInt(nationalRegisterNumber.substr(checkDigitsStart), 10);
  const nonCheckDigits: string = nationalRegisterNumber.substr(0, nonCheckDigitsLength);
  let modulo: number = parseInt(nonCheckDigits, 10) % CHECK_DIGITS_MODULO_BASE;
  let isValid: boolean = CHECK_DIGITS_MODULO_BASE - modulo === checkDigit;

  // If the check digits are not valid it could be that the person is born after 1999.
  // For people born after 1999 we have to add 2 to the national register number and recalculate
  // the modulo.
  if (!isValid) {
    modulo = parseInt(`2${nonCheckDigits}`, 10) % CHECK_DIGITS_MODULO_BASE;
    isValid = CHECK_DIGITS_MODULO_BASE - modulo === checkDigit;
  }

  return isValid;
}

/**
 * Checks whether the national register number is valid or not.
 *
 * @param input The national register number to validate
 */
export function isValidNationalRegisterNumber(
  input: ValidatorInput<NationalRegisterNumber>,
): boolean {
  return isValid(validateNationalRegisterNumber(input));
}

/**
 * Validate the given national register number input
 *
 * @param input The national register number to validate
 */
export function validateNationalRegisterNumber(
  input: ValidatorInput<NationalRegisterNumber>,
): ValidationErrors | ValidValidationResult {
  if (NationalRegisterNumber.isNationalRegisterNumber(input)) {
    return RESULT_VALID;
  }

  return (
    combineResults(invalidLength(input), invalidCharacters(input), invalidDate(input)) ??
    invalidCheckDigits(input)
  );
}
