import {ElementRef, Injectable} from '@angular/core';
import {EMPTY, from, Observable, Subject} from 'rxjs';
import {flatMap, mergeMapTo, pairwise, startWith, tap} from 'rxjs/operators';

import {DomIoService} from '../raf/dom-io.service';

import {getViewport, Viewport, VIEWPORT_NAMES, ViewportName} from './viewport';
import {ViewportService} from './viewport.service';

interface ViewportClasses {
  classIfActive: string;
  classIfBigger: string;
  classIfSmaller: string;
}

const CLASSES = VIEWPORT_NAMES.reduce((obj, viewport) => {
  obj[viewport] = {
    classIfActive: `p-maia-viewport--${viewport}`,
    classIfBigger: `p-maia-viewport--up-${viewport}`,
    classIfSmaller: `p-maia-viewport--down-${viewport}`,
  };
  return obj;
}, {} as {[k: string]: ViewportClasses});

function getClassesForViewport(viewportName: ViewportName | null): string[] {
  if (viewportName == null) {
    return [];
  }

  const viewport = getViewport(viewportName);
  const classes = [CLASSES[viewportName].classIfActive];

  for (let current: Viewport | undefined = viewport; current != null; current = current.bigger) {
    classes.push(CLASSES[current.name].classIfSmaller);
  }

  for (let current: Viewport | undefined = viewport; current != null; current = current.smaller) {
    classes.push(CLASSES[current.name].classIfBigger);
  }

  return classes;
}

/**
 * This service adds the viewport classes to a registered element
 */
@Injectable({providedIn: 'root'})
export class RenderViewportService {
  private readonly _classesUpdated$: Subject<void> = new Subject<void>();

  public constructor(
    private readonly viewportService: ViewportService,
    private readonly domIo: DomIoService,
  ) {}
  /**
   * Add viewport classes to the registered element
   *
   * @param elementRef The element to add the classes to
   * @returns An observable that never emits
   */
  public addClassesOnElement(
    elementRef: ElementRef<HTMLElement | Promise<HTMLElement>>,
  ): Observable<never> {
    return from(Promise.resolve(elementRef.nativeElement)).pipe(
      flatMap(element =>
        this.viewportService.viewport$.pipe(
          startWith(null),
          pairwise(),
          flatMap(([oldViewport, newViewport]) =>
            this._updateClasses(element, oldViewport, newViewport),
          ),
          tap(() => this._classesUpdated$.next()),
          mergeMapTo(EMPTY),
        ),
      ),
    );
  }

  public get classesUpdated$(): Observable<void> {
    return this._classesUpdated$.asObservable();
  }

  private _updateClasses(
    element: HTMLElement,
    oldViewport: ViewportName | null,
    newViewport: ViewportName | null,
  ): Observable<void> {
    const classesToRemove = getClassesForViewport(oldViewport);
    const classesToAdd = getClassesForViewport(newViewport);

    return from(
      this.domIo.mutate(() => {
        /* Note: we don't use the CssClassUtility here because of the
         * semantics: If media query X is active, we insert the following
         * classes:
         * - p-maia-viewport--X
         * - p-maia-viewport--up-X
         * - p-maia-viewport--down-X
         * which cannot be modeled using the CssClassUtility.
         */

        // IE doesn't support removing/adding multiple classes in a
        // single call, otherwise we could do
        // `rootElement.classList.remove(...classesToRemove)`

        for (const cls of classesToRemove) {
          element.classList.remove(cls);
        }

        for (const cls of classesToAdd) {
          element.classList.add(cls);
        }
      }),
    );
  }
}
