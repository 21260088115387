import {ElementRef, Injectable, Renderer2} from '@angular/core';

/**
 * Defines the classes linked to keys and values. This object mustn't change at
 * runtime.
 *
 * The structure of this object is `key -> value -> classname`. A `null` classname
 * means that no class should be added. To give an example:
 *
 * ```javascript
 * {
 *   size: {
 *     large: 'size-large',
 *     normal: null, // normal is default -> no class needed
 *     small: 'size-small',
 *   },
 *   position: {
 *     left: 'position-left',
 *     right: 'position-right',
 *     center: null, // default -> no class needed
 *   },
 * }
 * ```
 */
export interface CssClassDefinition {
  [key: string]: {[value: string]: string | string[] | null};
}

/**
 * Class utilities can be used to map values to classes.
 */
export interface CssClassUtility<C extends CssClassDefinition> {
  /**
   * Notifies the CssClassUtility that the given key is now set to the given value.
   * This will remove any previous classes set for the given key by the CssClassUtility
   * and it will set the correct class, if needed.
   */
  setValue<K extends keyof C>(key: K, value: keyof C[K] | null): void;
}

/**
 * An object where the last values that the CssClassUtility has received are stored
 * for every key.
 */
type StoredValues<C extends CssClassDefinition> = {
  [key in keyof C]?: string | string[];
};

/**
 * The CssClassUtility implementation.
 */
class CssClassUtilityImpl<C extends CssClassDefinition> implements CssClassUtility<C> {
  private readonly storedValues: StoredValues<C>;

  public constructor(
    private readonly renderer: Renderer2,
    private readonly element: ElementRef,
    private readonly classDefinition: C,
  ) {
    this.storedValues = {};
  }

  private removeOldClassIfNeeded(oldClass?: string | string[] | null): void {
    if (oldClass != null) {
      const nativeElement = this.element.nativeElement;
      if (Array.isArray(oldClass)) {
        for (const c of oldClass) {
          this.renderer.removeClass(nativeElement, c);
        }
      } else {
        this.renderer.removeClass(nativeElement, oldClass);
      }
    }
  }

  private addNewClassIfNeeded(newClass?: string | string[] | null): string | string[] | undefined {
    if (newClass != null) {
      const nativeElement = this.element.nativeElement;
      if (Array.isArray(newClass)) {
        for (const c of newClass) {
          this.renderer.addClass(nativeElement, c);
        }
      } else {
        this.renderer.addClass(nativeElement, newClass);
      }
    } else {
      newClass = undefined;
    }
    return newClass;
  }

  public setValue<K extends keyof C>(key: K, value: keyof C[K] | null): void {
    // For some reason TypeScript cannot deduce that {[k: string]: string|undefined}[K] is string or
    // undefined, so we need to cast these two variables explicitly to get it to work.

    const oldClass = this.storedValues[key] as string | string[] | undefined;
    const newClass =
      value != null ? (this.classDefinition[key][value] as string | string[] | null) : null;

    this.removeOldClassIfNeeded(oldClass);

    this.storedValues[key] = this.addNewClassIfNeeded(newClass);
  }
}

/**
 * A factory for CssClassUtilities. Inject this service to allow for creating new
 * CssClassUtility instances.
 */
@Injectable({providedIn: 'root'})
export class CssClassUtilityFactory {
  /**
   * Creates a new CssClassUtility linked to the given renderer and element.
   *
   * @param classDefinition The class definition, this object mustn't change after calling this
   * method.
   * @param renderer The renderer for the element
   * @param element The element to add/remove classes from
   */
  public create<C extends CssClassDefinition>(
    classDefinition: C,
    renderer: Renderer2,
    element: ElementRef,
  ): CssClassUtility<C> {
    return new CssClassUtilityImpl(renderer, element, classDefinition);
  }
}
