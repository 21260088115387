import {Injectable} from '@angular/core';
import {ValidationMessageExtractor} from '../form-element/validation-message-extractor.service';

@Injectable()
export abstract class BuiltinValidationMessageService {
  public abstract registerBbanMessages(extractor: ValidationMessageExtractor): () => void;
  public abstract registerBooleanMessages(extractor: ValidationMessageExtractor): () => void;
  public abstract registerCardNumberMessages(extractor: ValidationMessageExtractor): () => void;
  public abstract registerClientNumberMessages(extractor: ValidationMessageExtractor): () => void;
  public abstract registerDateMessages(extractor: ValidationMessageExtractor): () => void;
  public abstract registerDecimalMessages(extractor: ValidationMessageExtractor): () => void;
  public abstract registerEmailAddressMessages(extractor: ValidationMessageExtractor): () => void;
  public abstract registerFileMessages(extractor: ValidationMessageExtractor): () => void;
  public abstract registerHtmlMessages(extractor: ValidationMessageExtractor): () => void;
  public abstract registerIbanMessages(extractor: ValidationMessageExtractor): () => void;
  public abstract registerIdentityCardNumberMessages(
    extractor: ValidationMessageExtractor,
  ): () => void;
  public abstract registerInputAutocompleteMessages(
    extractor: ValidationMessageExtractor,
  ): () => void;
  public abstract registerNationalRegisterNumberMessages(
    extractor: ValidationMessageExtractor,
  ): () => void;
  public abstract registerPhoneNumberMessages(extractor: ValidationMessageExtractor): () => void;
  public abstract registerTextMessages(extractor: ValidationMessageExtractor): () => void;
  public abstract registerTsfrMessages(extractor: ValidationMessageExtractor): () => void;
  public abstract registerVatMessages(extractor: ValidationMessageExtractor): () => void;
}
