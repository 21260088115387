import {NotFoundError} from '../keepsake.errors';
import {Keepsake} from '../keepsake.interface';

export class MemoryStorage<T> implements Keepsake<T> {
  private _storage: {key: string; data: T}[] = [];

  public constructor() {}

  public async save(key: string, data: T): Promise<void> {
    const index = this._storage.findIndex(element => element.key === key);
    if (index > -1) {
      this._storage[index].data = data;
    } else {
      this._storage.push({key, data});
    }
  }

  public async get(key: string): Promise<T> {
    const storageItem = this._storage.filter(item => item.key === key);
    if (storageItem.length > 0 && storageItem[0].data != null) {
      return storageItem[0].data;
    }
    throw new NotFoundError(`No data found for the given key '${key}'`);
  }

  public async list(): Promise<[string, T][]> {
    return this._storage.map(({key, data}): [string, T] => [key, data]);
  }

  public async delete(key: string): Promise<void> {
    this._storage = this._storage.filter(item => item.key !== key);
  }

  public async getIndex(): Promise<string[]> {
    return this._storage.map(({key}) => key);
  }

  public async purge(): Promise<void> {
    this._storage = [];
  }
}
