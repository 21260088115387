export class StringedConfiguration<T> {
  public constructor(private readonly _values: Record<string, T>) {}

  /**
   * Queries whether the given key is known in this configuration
   *
   * @param key The key to query
   */
  public has(key: string): boolean {
    return key in this._values;
  }

  /**
   * Returns the data value for the given key
   *
   * This function assumes it's called after checking `obj.has(key)`, so it doesn't take into
   * account that the key might not be known (i.e. the return type doesn't include undefined).
   *
   * @param key The key of the value to return
   */
  public get(key: string): T {
    return this._values[key];
  }

  public toString(): string {
    return `${JSON.stringify(this._values)}`;
  }
}

/**
 * Language based configuration, e.g.
 *
 * ```ts
 * const languageName = new LanguageConfiguration({
 *   de: 'Deutsch',
 *   en: 'English',
 *   fr: 'Français',
 *   nl: 'Nederlands,
 * });
 * ```
 */
export class LanguageConfiguration<T> extends StringedConfiguration<T> {
  // @ts-ignore  We don't use it, but this property is required to let typescript know this object
  // has a different shape from a CompanyConfiguration.
  private readonly _language: void;
}

/**
 * Company based configuration, e.g.
 *
 * ```ts
 * const homepage = new CompanyConfiguration({
 *   '0001': 'https://kbc.be',
 *   '0002': 'https://cbc.be',
 *   '9998': 'https://kbcbrussels.be',
 * });
 * ```
 */
export class CompanyConfiguration<T> extends StringedConfiguration<T> {
  // @ts-ignore  We don't use it, but this property is required to let typescript know this object
  // has a different shape from a LanguageConfiguration.
  private readonly _company: void;
}

/**
 * Device based configuration, e.g.
 *
 * ```ts
 * const deviceUrlConfiguration = new DeviceConfiguration({
 *   'android': 'be.kbc.touch.local://start',
 *   'ios': 'be.kbc.touch.local://start',
 *   'browser': 'https://kbctouch-f.kbc.be',
 * });
 * ```
 */
export class DeviceConfiguration<T> extends StringedConfiguration<T> {
  // @ts-ignore  We don't use it, but this property is required to let typescript know this object
  // has a different shape from a LanguageConfiguration.
  private readonly _device: void;
}
