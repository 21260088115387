import {Injectable} from '@angular/core';

import {εCordova} from './apache/cordova.service';
import {ModuleMapper} from './apache/module-mapper';

/**
 * More info, see https://cordova.apache.org/docs/en/latest/plugin_ref/spec.html
 */
export interface ModuleDefinition {
  /** The id of the corresponding cordova module definition */
  id: string;
  /**
   * Used to specify the namespace under window object where module.exports gets inserted.
   * You can have as many <clobbers> as you like. Any object not available on window is
   * created.
   */
  clobbers?: string[];
  /**
   * Used to specify the namespace under window object where module.exports gets merged with
   * any existing value. If any key already exists, the module's version overrides the original.
   * You can have as many <merges> as you like. Any object not available on window is
   * created.
   */
  merges?: string[];
  /**
   * It implies that your code should be specified with cordova.require, but not installed
   * on the window object. This is useful when initializing the module, attaching event handlers
   * or otherwise. You can only have up to one <runs/> tag. Note that including a <runs/> with
   * <clobbers/> or <merges/> is redundant, since they also cordova.require your module.
   */
  runs?: boolean;
}

/**
 * Loads plugin definitions normally found in cordova_plugins.js. Normally cordova.js loads
 * cordova_plugins.js, but that is not really possible in our Angular setup, so we implement this
 * logic our selves. Similar logic is in cordova/pluginloader (cordova) module, see
 * https://github.com/apache/cordova-js/blob/master/src/common/pluginloader.js.
 */
@Injectable({
  providedIn: 'root',
})
export class εPluginLoader {
  private readonly moduleMapper: ModuleMapper;
  public constructor(cordova: εCordova) {
    this.moduleMapper = cordova.require('cordova/modulemapper');
  }

  /**
   * Loads a set of module definitions into cordova namespace. Note that this is normally only
   * needed for third party plugins (since these typically use window memory to make its
   * functionality available). Use this function in a third party's angular service wrapper.
   * @param moduleDefinitions
   */
  public load(...moduleDefinitions: ModuleDefinition[]) {
    moduleDefinitions.forEach(definition => {
      if (definition.clobbers != null) {
        definition.clobbers.forEach(clobber => this.moduleMapper.clobbers(definition.id, clobber));
      }
      if (definition.merges != null) {
        definition.merges.forEach(merge => this.moduleMapper.merges(definition.id, merge));
      }
      if (definition.runs) {
        this.moduleMapper.runs(definition.id);
      }
    });
  }
}
