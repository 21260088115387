import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SubNavigationToggleSignerManualSvgModule
 */
@Component({
  selector: 'svg[ivyHermesSubNavigationToggleSignerManual]',
  template: '<svg:path d="M451.6 32.4S358 0 262.3 0c-95 0-190.7 27.2-201.8 32.4C42.8 40.7 30.8 45.7 30.8 62l10.4 410.3c0 5.8 4.9 8.8 10.4 10.4 13.2 4 100.7 29.3 205.8 29.3 107.9 0 177.6-22.2 202.7-29.3 7.8-2.2 10.4-4.7 10.4-10.4L481.2 62c0-16.3-11-19.7-29.6-29.6zM114.4 47.6l21.9 33.3H92.5l21.9-33.3zM151 447.8c0 1.4-1.2 2.6-2.6 2.6h-47.2c-4.3 0-7.7-3.5-7.7-7.7v-22.1c0-1.4 1.2-2.6 2.6-2.6h52.3c1.4 0 2.6 1.2 2.6 2.6v27.2zm0-56.5H93.5v-32.4H151v32.4zm0-59H93.5v-32.4H151v32.4zm0-58.5H93.5v-32.4H151v32.4zm89.5 176.6H183V418h57.5v32.4zm0-59.1H183v-32.4h57.5v32.4zm0-59H183v-32.4h57.5v32.4zm0-58.5H183v-32.4h57.5v32.4zM330 450.4h-57.5V418H330v32.4zm0-59.1h-57.5v-32.4H330v32.4zm0-59h-57.5v-32.4H330v32.4zm0-58.5h-57.5v-32.4H330v32.4zm89.5 168.8c0 4.3-3.5 7.7-7.7 7.7h-47.2c-1.4 0-2.6-1.2-2.6-2.6v-27.2c0-1.4 1.2-2.6 2.6-2.6H417c1.4 0 2.6 1.2 2.6 2.6v22.1zm0-51.3H362v-32.4h57.5v32.4zm0-59H362v-32.4h57.5v32.4zm0-58.5H362v-32.4h57.5v32.4zm0-71.8c0 3.3-2.7 5.9-5.9 5.9H98.4c-3.3 0-5.9-2.7-5.9-5.9v-87.4c0-3.3 2.7-5.9 5.9-5.9h315.2c3.3 0 5.9 2.7 5.9 5.9V202zM375.6 80.9l21.9-33.3 21.9 33.3h-43.8z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 512 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubNavigationToggleSignerManualSvgComponent {
}

@NgModule({
  declarations: [
    SubNavigationToggleSignerManualSvgComponent,
  ],
  exports: [
    SubNavigationToggleSignerManualSvgComponent,
  ],
})
export class SubNavigationToggleSignerManualSvgModule {}
