import {Inject, Injectable} from '@angular/core';
import {Connector} from '@atlas-angular/connector';
import {Observable, of} from 'rxjs';
import {flatMap, map} from 'rxjs/operators';

import {
  FILE_UPLOADER_CALL_FACTORY,
  FileUploadCall,
  FileUploaderCallFactoryInterface,
  Reference,
} from './file-uploader.call.factory';
import {DropZoneAwareFile} from './file-uploader.service';

/**
 * Service responsible for uploading files
 */
@Injectable({providedIn: 'root'})
export class SimpleFileUploaderService {
  public constructor(
    private readonly connector: Connector,
    @Inject(FILE_UPLOADER_CALL_FACTORY)
    private readonly callFactory: FileUploaderCallFactoryInterface,
  ) {}

  /**
   * Uploads files to backend.
   * @param files
   * @param simpleFileUploadCall optional FileUploadCall by default takes plain or typed/json hermes calls
   */
  public upload(
    droppedFiles: DropZoneAwareFile[],
    simpleFileUploadCall: () => FileUploadCall = () => this.callFactory.getFileUploadCall(),
  ): Observable<Reference[]> {
    return of(droppedFiles).pipe(
      flatMap(files => {
        const formData = files.reduce((form: FormData, currentFile: DropZoneAwareFile) => {
          form.append('file', currentFile, `${currentFile.name}`);
          return form;
        }, new FormData());
        return this.connector.prepare(simpleFileUploadCall(), formData);
      }),
      map(({references}) => references),
    );
  }
}
