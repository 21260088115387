import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';

import {filter, mapTo, take} from 'rxjs/operators';

import {InputContainer} from '../input-container/input-container.interface';
import {ValidationComponent, ValidationType} from '../validation/validation.component';

export const VALIDATION_NONE = 'none';

/**
 * Shows validations when necessary. Validations are extracted from the surrounding input container
 * or from the content of the component.
 *
 * @ngModule FormsModule
 */
@Component({
  selector: 'maia-validation-container',
  templateUrl: './validation-container.component.html',
  styleUrls: ['./validation-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class ValidationContainerComponent implements OnInit, OnDestroy, AfterViewInit {
  private _hasError = false;

  public _errorString?: string = undefined;

  public _errorTemplate?: TemplateRef<any> = undefined;

  /**
   * When registerValidations change, we will emit a event so the form-element component can listen
   * to it.
   */
  @Output()
  public readonly onRegisteredValidationsChange = new EventEmitter<
    ValidationType | typeof VALIDATION_NONE
  >();

  @ViewChildren(forwardRef(() => ValidationComponent))
  private readonly _validationComponent: QueryList<ValidationComponent>;

  private _validationComponentType: ValidationType | typeof VALIDATION_NONE;

  public constructor(
    private readonly _inputContainer: InputContainer,
    private readonly _changeDetector: ChangeDetectorRef,
  ) {}

  public get validationType(): ValidationType | typeof VALIDATION_NONE {
    if (this._validationComponentType == null && this._validationComponent.length > 0) {
      this._validationComponentType = this._validationComponent.first.type;
    }
    return this._validationComponentType == null ? VALIDATION_NONE : this._validationComponentType;
  }

  public get hasError(): boolean {
    return this._hasError;
  }

  public ngOnInit(): void {
    if (this._inputContainer.hasError()) {
      this._hasError = true;
      this._errorString = this._inputContainer.errorString;
      this._errorTemplate = this._inputContainer.errorTemplate;
    }

    this._inputContainer.validationErrorChange$.pipe(takeUntilDestroyed(this)).subscribe(() => {
      const inputContainerHasError = this._inputContainer.hasError();
      const validationType = inputContainerHasError ? this.validationType : VALIDATION_NONE;

      this.onRegisteredValidationsChange.emit(validationType);

      if (inputContainerHasError) {
        this._hasError = true;
        if (
          this._errorString !== this._inputContainer.errorString ||
          this._errorTemplate !== this._inputContainer.errorTemplate
        ) {
          this._errorString = this._inputContainer.errorString;
          this._errorTemplate = this._inputContainer.errorTemplate;

          this._changeDetector.detectChanges();
        }
      } else if (this._hasError) {
        this._hasError = false;
        this._errorString = undefined;
        this._errorTemplate = undefined;

        this._changeDetector.detectChanges();
      }
    });
  }

  public ngOnDestroy(): void {
    this._hasError = false;
    this._errorString = undefined;
    this._errorTemplate = undefined;
  }

  public ngAfterViewInit(): void {
    this._validationComponent.changes
      .pipe(
        mapTo(this._validationComponent),
        filter(comp => comp.length > 0),
        take(1),
      )
      .subscribe((list: QueryList<ValidationComponent>) => {
        this._validationComponentType = list.first.type;
      });
  }
}
