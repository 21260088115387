import {Directive, Self} from '@angular/core';
import {IdentityCardNumberFormatter} from '@atlas/businesstypes';

import {ValueFormatter} from '../util/value-formatter.util';

import {MaskedInputComponent} from './masked-input.component';

/**
 * This directive configures the masked input for usage with Identity Card. It sets up
 * the correct mask and placeholder. Together with atlas's `[identityCardNumber]` directive
 * this makes the masked input create IdentityCardNumber businesstypes.
 *
 * @ngModule InputMasksModule
 */
@Directive({
  selector: 'maia-masked-input[atlasIdentityCardNumber]',
})
export class MaskedInputIdentityCardNumberDirective implements ValueFormatter {
  /**
   * Doesn't format anything, formatting is done by the `[atlasIdentityCardNumber]` directive.
   */
  public formatValue(value: string): string {
    return value;
  }

  /**
   * Unformats any formatting left in place by the `[atlasIdentityCardNumber]` directive.
   */
  public unformatValue(value: string): string {
    return IdentityCardNumberFormatter.unformat(value);
  }

  public constructor(@Self() private readonly _input: MaskedInputComponent) {
    this._input.setMaskAndPlaceholder('### - ####### - ##');
    this._input.setValueFormatter(this);
  }
}
