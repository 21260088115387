import {A11yModule} from '@angular/cdk/a11y';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {μSlideInsSvgModule} from '@maia/icons';
import {ModalsModule} from '@maia/modals';

import {SlideInController, μSlideInController} from './controller/slide-in-controller.service';
import {SlideInFooterDirective} from './footer/footer.directive';
import {SlideInComponent} from './slide-in/slide-in.component';

@NgModule({
  imports: [A11yModule, CommonModule, ModalsModule, μSlideInsSvgModule],
  declarations: [SlideInComponent, SlideInFooterDirective],
  exports: [SlideInFooterDirective],
  entryComponents: [SlideInComponent],
  providers: [μSlideInController, {provide: SlideInController, useExisting: μSlideInController}],
})
export class SlideInsModule {}
