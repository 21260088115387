import {Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {WindowRef} from '@atlas-angular/cdk/globals';
import {LoggerFactory} from '@atlas-angular/logger';
import {Logger} from '@atlas/logger';

import {MSG_NO_INTERSECTION_OBSERVER_API} from '../shared/intersection.constants';
import {isIntersectionObserverSupported} from '../shared/intersection.utils';

const DEFAULT_INTERSECTION_OPTIONS: IntersectionObserverInit = {
  threshold: 1,
};

/**
 * Applies the IntersectionObserver to the element where it's attached to as directive and
 * exposes an EventEmitter `onIntersectionChange` which can be subscribed to by others.
 *
 * Be sure the element with this directive has a height & width if you want events to be fired.
 *
 * For support on IE11 you will need to inject the IntersectionObserver polyfill in your project.
 * https://github.com/w3c/IntersectionObserver
 *
 * If you don't care about unsupported API you can set `maiaAllowMissingIntersectionObserver` to
 * true. No error logging will happen and the `onIntersectionObserverIsMissing` EventEmitter will
 * emit when the API isn't available.
 *
 * @ngModule IntersectionsModule
 */
@Directive({
  selector: '[maiaIntersection]',
})
export class IntersectionDirective implements OnInit, OnDestroy {
  @Output()
  public maiaIntersectionChange: EventEmitter<IntersectionObserverEntry[]> = new EventEmitter();

  @Output()
  public intersectionObserverIsMissing: EventEmitter<void> = new EventEmitter();

  @Input('maiaIntersectionOptions')
  public options: IntersectionObserverInit;

  @coerceBooleanPrimitive()
  @Input('maiaAllowMissingIntersectionObserver')
  public ignoreIntersectionObserverMissing = false;

  private readonly _logger: Logger;

  private _observer: IntersectionObserver;

  public constructor(
    private readonly _windowRef: WindowRef,
    private readonly _elementRef: ElementRef<Element>,
    loggerFactory: LoggerFactory,
  ) {
    this._logger = loggerFactory.createLogger('@maia/intersections intersection directive');
  }

  /**
   * Initialization of the IntersectionObserver API if available.
   */
  public ngOnInit() {
    if (isIntersectionObserverSupported(this._windowRef.window)) {
      const options: IntersectionObserverInit = this.options || DEFAULT_INTERSECTION_OPTIONS;
      this._observer = new IntersectionObserver(a => this.maiaIntersectionChange.emit(a), options);
      this._observer.observe(this._elementRef.nativeElement);
    } else if (this.ignoreIntersectionObserverMissing) {
      this.intersectionObserverIsMissing.emit();
    } else {
      this._logger.error(MSG_NO_INTERSECTION_OBSERVER_API);
    }
  }

  /**
   * Unobserve when the element is destroyed
   */
  public ngOnDestroy() {
    if (this._observer) {
      this._observer.unobserve(this._elementRef.nativeElement);
    }
  }
}
