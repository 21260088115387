import {coerceNumberProperty} from '@angular/cdk/coercion';
import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

/**
 * Context available within an element with `*maiaRange`.
 */
export class MaiaRangeContext {
  /**
   * The implicit value, i.e. the value of `foo` in `*maiaRange="let foo from 0 to 10"`
   */
  public readonly $implicit: number;

  /**
   * The index of the value in the range.
   */
  public index: number;

  /**
   * The total number of items in the range.
   */
  public count: number;

  public constructor($implicit: number, index: number, count: number) {
    this.$implicit = $implicit;
    this.index = index;
    this.count = count;
  }

  /**
   * Whether this is the first item in the range.
   */
  public get first(): boolean {
    return this.index === 0;
  }

  /**
   * Whether this is the last item in the range.
   */
  public get last(): boolean {
    return this.index === this.count - 1;
  }

  /**
   * Whether the index of this element in the range is even.
   */
  public get even(): boolean {
    return this.index % 2 === 0;
  }

  /**
   * Whether the index of this element in the range is odd.
   */
  public get odd(): boolean {
    return !this.even;
  }
}

function updateEmbeddedViewContexts(viewContainer: ViewContainerRef, count: number) {
  for (let i = 0; i < count; i++) {
    const view = viewContainer.get(i)! as EmbeddedViewRef<MaiaRangeContext>;

    view.context.index = i;
    view.context.count = count;
  }
}

/**
 * A directive to loop over a range of numbers.
 *
 * This is a lot like the `*ngFor` directive, but where the minimum and maximum numbers in the range
 * are defined, instead of looping over an array of numbers.
 *
 * @ngModule RangesModule
 */
@Directive({
  selector: '[maiaRange][maiaRangeFrom][maiaRangeTo]',
})
export class RangeDirective implements OnChanges {
  private _from = 0;

  private _to = 0;

  private _viewsNotRemoved = 0;

  public constructor(
    private readonly _viewContainer: ViewContainerRef,
    private readonly _template: TemplateRef<MaiaRangeContext>,
  ) {}

  /**
   * The lower bound of the range, inclusive.
   */
  @Input('maiaRangeFrom')
  public set from(from: number) {
    this._from = coerceNumberProperty(from);
  }

  /**
   * The upper bound of the range, inclusive.
   */
  @Input('maiaRangeTo')
  public set to(to: number) {
    this._to = coerceNumberProperty(to);
  }

  private _getPreviousState(changes: SimpleChanges) {
    const previousFrom = changes.from != null ? changes.from.previousValue : this._from;
    const previousTo = changes.to != null ? changes.to.previousValue : this._to;

    return {previousFrom, previousTo};
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.from != null && changes.from.isFirstChange()) {
      this._setupInitialViews();
      return;
    }

    const {previousFrom, previousTo} = this._getPreviousState(changes);

    const newFrom = this._from;
    const newTo = this._to;

    const previousCount = previousTo - previousFrom + 1;
    const newCount = newTo - newFrom + 1;

    if (newFrom > previousTo || newTo < previousFrom) {
      // There's literally no overlap, destroy all views and set them up anew
      this._removeLastViews(previousCount);
      this._setupInitialViews();
      return;
    }

    if (newFrom > previousFrom) {
      this._removeFirstViews(newFrom - previousFrom);
    } else if (newFrom < previousFrom) {
      this._insertFirstViews(previousFrom - newFrom, newFrom);
    }

    if (newTo < previousTo) {
      this._removeLastViews(previousTo - newTo);
    } else if (newTo > previousTo) {
      this._insertLastViews(newTo - previousTo, previousTo + 1 - this._viewsNotRemoved);
    }

    // Both existing views and newly created views will at this point have an incorrect index
    // and count. Update the contexts of the view.

    updateEmbeddedViewContexts(this._viewContainer, newCount);
    if (newCount < 0) {
      this._viewsNotRemoved = newCount;
    } else {
      this._viewsNotRemoved = 0;
    }
  }

  private _setupInitialViews(): void {
    const count = this._to - this._from + 1;

    for (let current = this._from, idx = 0; current <= this._to; current++, idx++) {
      this._viewContainer.createEmbeddedView(
        this._template,
        new MaiaRangeContext(current, idx, count),
      );
    }
  }

  /**
   * Inserts `count` views at the start. These views will have a context with invalid `index` and
   * `count`.
   *
   * @param count The number of views to insert
   * @param firstValue The value of the first view to insert
   */
  private _insertFirstViews(count: number, firstValue: number): void {
    for (let i = 0; i < count + this._viewsNotRemoved; i++) {
      this._viewContainer.createEmbeddedView(
        this._template,
        new MaiaRangeContext(firstValue + i, NaN, NaN),
        i,
      );
    }
  }

  /**
   * Removes `count` views at the start.
   *
   * @param count The number of views to remove.
   */
  private _removeFirstViews(count: number): void {
    for (let i = 0; i < count + this._viewsNotRemoved; i++) {
      // remove(0) removes the first view in the container
      this._viewContainer.remove(0);
    }
  }

  /**
   * Inserts `count` views at the end. These views will have a context with invalid `index` and
   * `count`.
   *
   * @param count The number of views to insert
   * @param firstValue The value of the first view to insert
   */
  private _insertLastViews(count: number, firstValue: number): void {
    for (let i = 0; i < count + this._viewsNotRemoved; i++) {
      this._viewContainer.createEmbeddedView(
        this._template,
        new MaiaRangeContext(firstValue + i, NaN, NaN),
      );
    }
  }

  /**
   * Removes `count` views at the end.
   *
   * @param count The number of views to remove.
   */
  private _removeLastViews(count: number): void {
    for (let i = 0; i < count + this._viewsNotRemoved; i++) {
      // remove() removes the last view in the container
      this._viewContainer.remove();
    }
  }
}
