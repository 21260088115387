import {ChangeDetectionStrategy, Component, Input, Optional, SkipSelf} from '@angular/core';

export type InitialFormGroupDepth = 0;
const INITIAL_FORM_GROUP_DEPTH: InitialFormGroupDepth = 0;

/**
 * Supported depths for form group components.
 */
export type FormGroupDepth = InitialFormGroupDepth | number;

/**
 * Form group component. This component groups form elements.
 *
 * From group support nesting. The nested form group is shown with a slightly blue
 * background and a small indentation.
 * Form groups can be nested indefinitely, that is:
 *
 * ```html
 * <maia-form-group><!-- not nested -->
 *   <maia-form-group><!-- first level of nesting -->
 *     <maia-form-group><!-- second level of nesting -->
 *       <!-- content -->
 *     </maia-form-group>
 *   </maia-form-group>
 * </maia-form-group>
 * ```
 *
 * @ngModule FormsModule
 */
@Component({
  selector: 'maia-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss'],

  host: {
    '[class.maia-form-group--nested]': 'depth !== 0',

    '[class.maia-form-group--depth-0]': 'depth === 0',
    '[class.maia-form-group--depth-1]': 'depth === 1',
    '[class.maia-form-group--depth-gteq-2]': 'depth >= 2',
  },

  // Form groups don't hold state and they don't accept any mutable inputs, so we can use OnPush
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGroupComponent {
  public readonly depth: FormGroupDepth;
  public deepestNestedLevel = true;

  @Input()
  public subtitle?: string;

  public constructor(@Optional() @SkipSelf() parent?: FormGroupComponent) {
    if (parent != null) {
      this.depth = parent.depth + 1;
    } else {
      this.depth = INITIAL_FORM_GROUP_DEPTH;
    }
  }
}
