import {ChangeDetectionStrategy, Component, Optional} from '@angular/core';
import {IconType, TextListComponent} from '../text-list/text-list.component';

/**
 * @ngModule TextListsModule
 */
@Component({
  selector: 'li[maiaTextListItem]',
  templateUrl: './text-list-item.component.html',
  styleUrls: ['./text-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextListItemComponent {
  private readonly _parent: TextListComponent;

  public constructor(@Optional() parent?: TextListComponent) {
    if (parent == null) {
      throw new Error(
        'Cannot create a <li maiaTextListItem/> component out of <ul maiaTextList/> component',
      );
    }
    this._parent = parent;
  }

  public get icon(): IconType {
    return this._parent.iconType;
  }

  public get indented(): boolean {
    return this._parent.indented;
  }
}
