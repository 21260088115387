import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SystemInfoSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaSystemInfo]',
  template: '<svg:path d="M256 0C114.3 0 0 114.3 0 256s114.3 256 256 256 256-114.3 256-256S397.7 0 256 0zm-37.5 104.1c0-3.4 1.7-5.1 3.4-6.8s3.4-3.4 6.8-3.4h54.6c3.4 0 5.1 1.7 6.8 3.4 1.7 1.7 3.4 3.4 3.4 6.8v46.1c0 3.4-1.7 5.1-3.4 6.8-1.7 1.7-3.4 3.4-6.8 3.4h-54.6c-3.4 0-5.1-1.7-6.8-3.4-1.7-1.7-3.4-3.4-3.4-6.8v-46.1zm110.9 303.8c0 3.4-1.7 5.1-3.4 6.8-1.7 1.7-3.4 3.4-6.8 3.4h-128c-3.4 0-5.1-1.7-6.8-3.4-1.7-1.7-3.4-3.4-3.4-6.8v-46.1c0-3.4 1.7-5.1 3.4-6.8 1.7-1.7 3.4-3.4 6.8-3.4h27.3v-92.2h-27.3c-3.4 0-5.1-1.7-6.8-3.4s-3.4-3.4-3.4-6.8v-44.4c0-3.4 1.7-5.1 3.4-6.8 1.7-1.7 3.4-3.4 6.8-3.4h92.2c3.4 0 5.1 1.7 6.8 3.4s3.4 3.4 3.4 6.8v146.8h27.3c3.4 0 5.1 1.7 6.8 3.4 1.7 1.7 3.4 3.4 3.4 6.8v46.1h-1.7z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 512 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemInfoSvgComponent {
}

@NgModule({
  declarations: [
    SystemInfoSvgComponent,
  ],
  exports: [
    SystemInfoSvgComponent,
  ],
})
export class SystemInfoSvgModule {}
