import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Text} from '@atlas/businesstypes';
import {ModalContentComponent, ModalControl} from '@maia/modals';

import {
  FILE_UPLOADER_ANALYTICS_SERVICE,
  FileUploaderAnalyticsServiceInterface,
} from '../shared/file-uploader-analytics.service';
import {FileUploaderCallbackService} from '../shared/file-uploader-callback.service';
import {
  FILE_UPLOADER_SETTINGS,
  εFileUploaderSettingsStrict,
} from '../shared/file-uploader-settings';

import {Reference} from './file-uploader.call.factory';
import {
  DropZoneAwareFile,
  DropZoneAwareReference,
  FileUploaderService,
} from './file-uploader.service';

const units = ['KB', 'MB', 'GB', 'TB'];
const decimalDigitsTotalSize = 2;
const fileSizeDivider = 1024;

@Component({
  selector: 'hermes-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FileUploaderService, FileUploaderCallbackService],
})
@UntilDestroy()
export class FileUploader extends ModalContentComponent<Reference[]> implements OnInit {
  public uploadedFiles: DropZoneAwareReference[] = [];
  public uploadingFiles: DropZoneAwareFile[] = [];
  public allowFinish = false;
  public totalSizeUploaded: number;
  private totalFileSizeDividerIterator = 1;
  public totalSizeUploadedRounded: string;
  public totalSizeAllowedRounded: string;
  public totalFileSizeUnit: string;
  public showTotalFileSize = true;
  public additionalInfo: Text = this.settings.additionalInfo;

  public constructor(
    public control: ModalControl<Reference[]>,
    private readonly fileUploader: FileUploaderService,
    private readonly fileUploaderCallbackService: FileUploaderCallbackService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(FILE_UPLOADER_ANALYTICS_SERVICE)
    private readonly fileUploaderAnalyticsService: FileUploaderAnalyticsServiceInterface,
    @Inject(FILE_UPLOADER_SETTINGS) public readonly settings: εFileUploaderSettingsStrict,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.fileUploaderCallbackService.registerOnUpload(this.settings.afterUpload);
    this.fileUploaderCallbackService.registerOnDownload(this.settings.afterDownload);
    this.fileUploaderCallbackService.registerOnDelete(this.settings.afterDelete);
    this.calculateTotalSizeFormatting();
    if (this.fileUploaderAnalyticsService.trackOpenSlideIn) {
      this.fileUploaderAnalyticsService.trackOpenSlideIn();
    }
    this.fileUploader.uploadingFiles$.pipe(takeUntilDestroyed(this)).subscribe(files => {
      this.uploadingFiles = files;
    });
    this.fileUploader.uploadedFiles$.pipe(takeUntilDestroyed(this)).subscribe(references => {
      this.uploadedFiles = references;
      if (this.showTotalFileSize) {
        this.totalSizeUploaded = this.uploadedFiles.reduce((a, b) => {
          return a + (b.fileSize ? b.fileSize : 0);
        }, 0);
        this.totalSizeUploadedRounded = (
          this.totalSizeUploaded /
          fileSizeDivider ** this.totalFileSizeDividerIterator
        ).toFixed(decimalDigitsTotalSize);
      }
      this.changeDetectorRef.detectChanges();
    });
    this.fileUploader.allowFinish$.pipe(takeUntilDestroyed(this)).subscribe(allow => {
      this.allowFinish = allow;
      this.changeDetectorRef.detectChanges();
    });
  }

  public calculateTotalSizeFormatting(): void {
    if (this.settings.maxTotalFileSize === Infinity) {
      this.showTotalFileSize = false;
      return;
    }

    let totalSizeAllowed = this.settings.maxTotalFileSize;
    for (let i = 0; i < units.length; i++) {
      totalSizeAllowed = totalSizeAllowed / fileSizeDivider;
      if (totalSizeAllowed < fileSizeDivider || i + 1 === units.length) {
        this.totalFileSizeUnit = units[i];
        this.totalFileSizeDividerIterator = i + 1;
        this.totalSizeAllowedRounded = totalSizeAllowed.toFixed(decimalDigitsTotalSize);
        break;
      }
    }
  }

  public hasDocuments(): boolean {
    return this.uploadedFiles.length > 0;
  }

  public hasUploadStarted(): boolean {
    return this.uploadedFiles.length > 0 || this.uploadingFiles.length > 0;
  }

  public close(): void {
    this.control.confirm(([] as Reference[]).concat(this.uploadedFiles));
  }
}
