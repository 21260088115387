import {coerceNumberProperty} from '@angular/cdk/coercion';
import {Component, Input} from '@angular/core';
/**
 * Basic textarea component, without any extras like masks, datepickers etc.
 *
 * @ngModule TextAreaModule
 */
@Component({
  selector: 'maia-text-area-limit',
  templateUrl: './text-area-limit.component.html',
  styleUrls: ['./text-area-limit.component.scss'],
})
export class TextAreaLimitComponent {
  private _maxLength: number;
  public actualValue = 0;

  /**
   * The maxLength defines the maxLength of the textarea
   */
  @Input()
  public get maxLength(): number {
    return this._maxLength;
  }

  public set maxLength(maxLength: number) {
    this._maxLength = coerceNumberProperty(maxLength);
  }

  /**
   * warns the user about the limit in characters by adding a class to the text, changing its color
   * @return a boolean with holds if the limit is reached
   */
  public isLimitReached = (): boolean => {
    return this.actualValue === this.maxLength;
  };
}
