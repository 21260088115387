import {
  BaseEmailAddressValidator,
  MaxLengthEmailAddressValidator,
  MinLengthEmailAddressValidator,
} from './validator';

export {EmailAddressDirective} from './directive';
export {EmailAddressPipe} from './pipe';
export {ExtraEmailAddressControlValidators} from './validator';

export const EMAIL_ADDRESS_VALIDATORS = [
  BaseEmailAddressValidator,
  MaxLengthEmailAddressValidator,
  MinLengthEmailAddressValidator,
];
