import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

/**
 * A header component used in complex datepickers. There are optional next/previous buttons denoted
 * by arrows.
 *
 * @ngModule DatePickersModule
 */
@Component({
  selector: 'maia-datepicker-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  private _hasNext = true;
  private _hasPrevious = true;

  /**
   * Emits when the right arrow is clicked.
   */
  @Output()
  public clickNext = new EventEmitter<void>();

  /**
   * Emits when the previous arrow is clicked.
   */
  @Output()
  public clickPrevious = new EventEmitter<void>();

  /**
   * Emits when the header itself is clicked.
   */
  @Output()
  public clickHeader = new EventEmitter<void>();

  /**
   * Whether or not there should be a next button.
   */
  @Input()
  public set hasNext(value: boolean) {
    this._hasNext = coerceBooleanProperty(value);
  }

  public get hasNext(): boolean {
    return this._hasNext;
  }

  /**
   * Whether or not there should be a previous buttno
   */
  @Input()
  public set hasPrevious(value: boolean) {
    this._hasPrevious = coerceBooleanProperty(value);
  }

  public get hasPrevious(): boolean {
    return this._hasPrevious;
  }
}
