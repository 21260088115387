import {Formatter} from '../base/formatter';
import {Text} from '../text/businesstype';

import {CardNumber} from './businesstype';
import {
  CARD_NUMBER_MAX_LENGTH,
  CARD_NUMBER_MIN_LENGTH,
  FIFTH_GROUP_START_POSITION,
  FIRST_GROUP_START_POSITION,
  FOURTH_GROUP_START_POSITION,
  GROUP_LENGTH,
  LAST_GROUP_LENGTH,
  SECOND_GROUP_START_POSITION,
  THIRD_GROUP_START_POSITION,
} from './constants';

/**
 * The pretty card number format used to represent card numbers.
 */
export const CardNumberFormatter: Formatter<CardNumber | Text> = {
  /**
   * Formats the card number (e.g. 1234123412341234 -> 1234 1234 1234 1234)
   *
   * @param value The card number to format
   */
  format(value: CardNumber | Text): string {
    let stringValue: string = value.asString();
    if (stringValue.length === CARD_NUMBER_MIN_LENGTH) {
      stringValue = `${stringValue.substr(
        FIRST_GROUP_START_POSITION,
        GROUP_LENGTH,
      )} ${stringValue.substr(SECOND_GROUP_START_POSITION, GROUP_LENGTH)} ${stringValue.substr(
        THIRD_GROUP_START_POSITION,
        GROUP_LENGTH,
      )} ${stringValue.substr(FOURTH_GROUP_START_POSITION, GROUP_LENGTH)}`;
    }
    if (stringValue.length === CARD_NUMBER_MAX_LENGTH) {
      stringValue = `${stringValue.substr(
        FIRST_GROUP_START_POSITION,
        GROUP_LENGTH,
      )} ${stringValue.substr(SECOND_GROUP_START_POSITION, GROUP_LENGTH)} ${stringValue.substr(
        THIRD_GROUP_START_POSITION,
        GROUP_LENGTH,
      )} ${stringValue.substr(FOURTH_GROUP_START_POSITION, GROUP_LENGTH)} ${stringValue.substr(
        FIFTH_GROUP_START_POSITION,
        LAST_GROUP_LENGTH,
      )}`;
    }
    return stringValue;
  },

  /**
   * Removes all spaces from the pretty value.
   *
   * @param value The pretty value
   */
  unformat(value: string): string {
    return value.replace(/[ ]+/g, '');
  },
};
