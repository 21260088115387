import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {Text} from '@atlas/businesstypes';

/**
 *  A table cell. Cells can have borders.
 *
 * @ngModule TablesModule
 */
@Component({
  selector: 'maia-table-cell',
  templateUrl: './table-cell.component.html',
  styleUrls: ['./table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCellComponent {
  /**
   * Whether or not the content of the cell should be aligned to the right
   */
  @coerceBooleanPrimitive()
  @Input()
  @HostBinding('class.maia-table-cell--right-aligned')
  public alignRight = false;

  /**
   * Whether or not the content of the cell should be aligned to the right on small (alignRight
   * default = left on XS screens)
   */
  @coerceBooleanPrimitive()
  @Input()
  @HostBinding('class.maia-table-cell--right-aligned-small')
  public alignRightOnSmall = false;

  /**
   * Whether or not the table should have a border on the right side.
   */
  @coerceBooleanPrimitive()
  @Input()
  @HostBinding('class.maia-table-cell--border')
  public borderRight = false;

  /**
   * Used on XS screens to set header value
   */
  @Input()
  public maiaTableHeaderValue?: Text | string;

  /**
   * Used on XS screens to remove header title
   */
  @coerceBooleanPrimitive()
  @Input()
  @HostBinding('class.maia-table-cell--hidden-small')
  public maiaTableHeaderHiddenOnSmall = false;
}
