import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {ErrorHandler, Injectable} from '@angular/core';
import {
  Call,
  ConnectorErrorResponse,
  ConnectorResponse,
  ConnectorResponseBase,
  SignResponseExtractor,
} from '@atlas/convertor';
import {Observable, of as of$, throwError} from 'rxjs';
import {catchError, filter, flatMap, mergeMap, tap} from 'rxjs/operators';

import {ConnectorBackend} from '../interfaces/interceptor';

import {CombinedConnectorInterceptor} from './combined-connector-interceptor.service';
import {CombinedPathResolver} from './combined-path-resolver.service';

/**
 * The connector allows performing HTTP requests
 *
 * A remote endpoint is represented by a `Call` object. This object is passed on to the registered
 * `PathResolver`s which turn it into a `HttpRequest`. This request is then passed through the
 * registered `ConnectorInterceptor`s. Finally the input is converted by the `Call` and inserted
 * into the request body, after which Angular handles the request.
 * The response is converted by the `Call` into a `ConnectorResponse`, which is then passed back
 * through the interceptors (in reverse order).
 */
@Injectable()
export class Connector {
  public constructor(
    private readonly _http: HttpClient,
    private readonly _errorHandler: ErrorHandler,
    private readonly _signResponseExtractor: SignResponseExtractor,
    private readonly _resolver: CombinedPathResolver,
    private readonly _interceptor: CombinedConnectorInterceptor,
  ) {}

  /**
   * Prepares the given call for execution once the observable is subscribed to
   *
   * The status and header of the result are hidden, only the data remains. If you want access to
   * the status or headers, use `#prepareRaw` instead.
   *
   * Whether the observable emits a value or an error is up to the `Call` implementation. As such
   * there is no strict mapping between the status and the resulting observable.
   *
   * No caching is implemented in the observable, so multiple calls to the observable will yield
   * multiple requests.
   *
   * @see #prepareRaw
   * @param call The call to execute
   * @param input The input to pass on to the call
   * @returns The output
   */
  public prepare<I, O>(call: Call<I, O>, input: I): Observable<O> {
    return this.prepareRaw(call, input).pipe(
      flatMap(
        (response: ConnectorResponseBase<O>): Observable<O> => {
          if (response.isSuccess() || response.isWarning()) {
            return of$((response as ConnectorResponse<O>).data);
          }

          return throwError(`Unexpected result status`);
        },
      ),
    );
  }

  /**
   * Prepares the given call for execution once the observable is subscribed to
   *
   * The result contains the header data and status. If you only need the content, use `#prepare`
   * instead.
   *
   * Whether the observable emits a value or an error is up to the `Call` implementation. As such
   * there is no strict mapping between the status and the resulting observable.
   *
   * No caching is implemented in the observable, so multiple calls to the observable will yield
   * multiple requests.
   *
   * @see #prepare
   * @param call The call to execute
   * @param input The input to pass on to the call
   * @returns The output wrapped in a connector response
   */
  public prepareRaw<I, O>(call: Call<I, O>, input: I): Observable<ConnectorResponseBase<O>> {
    const backend = this._createConnectorBackend(call, input);

    const response = this._resolver
      .resolve(call, input)
      .pipe(mergeMap(request => this._interceptor.intercept(call, input, request, backend)));

    if (call.options.skipLoggingErrors) {
      return response;
    }

    return response.pipe(
      tap({
        error: (err: unknown) => {
          if (err instanceof ConnectorErrorResponse && err.message) {
            this._errorHandler.handleError(err.message);
          } else if (err instanceof Error) {
            // Normally observables don't handle errors themselves, it's up to the subscriber to
            // handle the errors correctly. However, with the Connector it's different: stuff like
            // logging the error and making it visible for the user is built into the connector, which
            // implies a lot of Connector calls happen without their own error handling attached.
            //
            // That's why we explicitly also handle javascript Errors here. If there's an issue in the
            // code, beit in atlas or in e.g. an interceptor provided by the application.
            this._errorHandler.handleError(err);
          }
        },
      }),
    );
  }

  private _createConnectorBackend<I, O>(call: Call<I, O>, input: I): ConnectorBackend<O> {
    return {
      handle: (request: HttpRequest<null>): Observable<ConnectorResponse<O>> => {
        if (
          request.body != null &&
          (typeof request.body !== 'object' || Array.isArray(request.body))
        ) {
          return throwError(
            new Error(
              `request body should be empty or an object, not e.g. an array or a single primitive.`,
            ),
          );
        }

        const convertedInput = call.convertInput(input);
        let body;

        if (convertedInput instanceof FormData) {
          if (request.body != null) {
            return throwError(
              new Error(`request body should be empty when the input of a call is FormData.`),
            );
          }

          body = convertedInput;
        } else {
          body = {
            ...(request.body || {}),
            ...convertedInput,
          };
        }

        const realRequest: HttpRequest<unknown> = request.clone({
          body,
        });

        return this._http.request<unknown>(realRequest).pipe(
          filter(
            (event: HttpEvent<unknown>): event is HttpResponse<unknown> =>
              event instanceof HttpResponse,
          ),
          mergeMap((response: HttpResponse<unknown>) =>
            call.convertOutput(
              response.body as object,
              response.status,
              this._signResponseExtractor,
            ),
          ),
          catchError((err: Error | HttpErrorResponse | ConnectorErrorResponse<O>) => {
            if (err instanceof HttpErrorResponse) {
              return call.convertOutput(err.error, err.status, this._signResponseExtractor);
            } else {
              return throwError(err);
            }
          }),
        );
      },
    };
  }
}
