import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule as AngularFormsModule} from '@angular/forms';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';
import {CollapsiblesModule} from '@maia/collapsibles';
import {μFormsSvgModule} from '@maia/icons';
import {LayoutModule} from '@maia/layout';
import {SpinnersModule} from '@maia/spinners';

import {
  BbanDirective,
  BooleanDirective,
  CardNumberDirective,
  ClientNumberDirective,
  DateDirective,
  DecimalDirective,
  EmailAddressDirective,
  FileDirective,
  HtmlDirective,
  IbanDirective,
  IdentityCardNumberDirective,
  InputAutocompleteDirective,
  NationalRegisterNumberDirective,
  PhoneNumberDirective,
  TextDirective,
  TsfrDirective,
  VatDirective,
} from './builtin-validation-message/directives';
import {
  FormElementComponent,
  FormElementMultiDirective,
  FormElementNotMultiDirective,
} from './form-element/form-element.component';
import {FormSubelementComponent} from './form-element/form-subelement.component';
import {LabelTargetDirective} from './form-element/label-target.directive';
import {FormGroupComponent} from './form-group/form-group.component';
import {FormComponent} from './form/form.component';
import {LoadingFormComponent} from './form/loading-form.component';
import {QuestionFormComponent} from './form/question-form.component';
import {InputGroupButtonAddonComponent} from './input-group/input-group-button-addon.component';
import {InputGroupSuffixComponent} from './input-group/input-group-suffix.component';
import {InputGroupComponent} from './input-group/input-group.component';
import {InputNumberComponentWithNgControlDirective} from './input-number/input-number.component';
import {FakeInputComponent, FakeInputWithNgControlDirective} from './input/fake-input.component';
import {InputComponent, InputWithNgControlDirective} from './input/input.component';
import {LabelComponent} from './label/label.component';
import {ValidationContainerComponent} from './validation-container/validation-container.component';
import {RegisterValidationDirective} from './validation/register-validation.directive';
import {ValidationComponent} from './validation/validation.component';

export const MESSAGE_DIRECTIVES = [
  BbanDirective,
  BooleanDirective,
  CardNumberDirective,
  ClientNumberDirective,
  DateDirective,
  DecimalDirective,
  EmailAddressDirective,
  FileDirective,
  HtmlDirective,
  IbanDirective,
  IdentityCardNumberDirective,
  InputAutocompleteDirective,
  NationalRegisterNumberDirective,
  PhoneNumberDirective,
  TextDirective,
  TsfrDirective,
  VatDirective,
];

export const FORM_EXPORTS = [
  MESSAGE_DIRECTIVES,

  FakeInputComponent,
  FakeInputWithNgControlDirective,
  FormComponent,
  FormElementComponent,
  FormElementMultiDirective,
  FormElementNotMultiDirective,
  FormGroupComponent,
  FormSubelementComponent,
  InputComponent,
  InputWithNgControlDirective,
  InputGroupComponent,
  InputGroupButtonAddonComponent,
  InputGroupSuffixComponent,
  InputNumberComponentWithNgControlDirective,
  LabelComponent,
  LabelTargetDirective,
  LoadingFormComponent,
  RegisterValidationDirective,
  ValidationComponent,
  ValidationContainerComponent,
  QuestionFormComponent,
];

@NgModule({
  imports: [
    CommonModule,
    CollapsiblesModule,
    LayoutModule,
    SpinnersModule,
    BusinessTypesModule,
    AngularFormsModule,
    μFormsSvgModule,
  ],
  declarations: [FORM_EXPORTS],
  exports: [
    // Export Atlas's businesstype directives here, because we want to help people using our forms
    // with businesstypes.
    BusinessTypesModule,

    FORM_EXPORTS,
  ],
})
export class FormsModule {}
