import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 * @ngModule HighlightPanelModule
 */
@Component({
  selector: 'maia-highlight-panel',
  templateUrl: './highlight-panel.component.html',
  styleUrls: ['./highlight-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HighlightPanelComponent {}
