import {Pipe, PipeTransform} from '@angular/core';
import {Decimal, DecimalFormatter} from '@atlas/businesstypes';

/**
 * @ngModule BusinessTypesModule
 */
@Pipe({name: 'atlasDecimal'})
export class DecimalPipe implements PipeTransform {
  public transform(
    value: any,
    fractionDigits?: number,
    signSymbol?: string,
    thousandSeparator?: string,
  ): string {
    return Decimal.isDecimal(value)
      ? DecimalFormatter.formatDecimal(value, fractionDigits, signSymbol, thousandSeparator)
      : value;
  }
}
