import {bigConstructor} from '../base/big-utils';
import {MOD_VALUE} from '../base/modulo-validator';
import {
  AtlasInvalidCharactersValidationErrors,
  AtlasInvalidCheckDigitsValidationErrors,
  AtlasInvalidLengthValidationErrors,
  isValid,
  RESULT_VALID,
  ValidatorInput,
  ValidValidationResult,
  ValidationErrors,
  combineResults,
} from '../base/validators';

import {ClientNumber} from './businesstype';

const CLIENT_NUMBER_LENGTH = 9;
const CLIENT_CHECK_DIGIT_SIZE = 2;
const CHAR_INT_MAPPER = [
  /[ajs]/gi,
  /[bkt]/gi,
  /[clu]/gi,
  /[dmv]/gi,
  /[enw]/gi,
  /[fox]/gi,
  /[gpy]/gi,
  /[hqz]/gi,
  /[ir]/gi,
];

/**
 * Checks the validity of the given input's characters.
 * returns a RESULT_VALID if entry is a falsy value
 *
 * @param value The client number to validate
 */
function invalidCharacters(
  value: ValidatorInput<ClientNumber>,
): AtlasInvalidCharactersValidationErrors | ValidValidationResult {
  if (ClientNumber.isClientNumber(value) || !value) {
    return RESULT_VALID;
  }
  if (validateCharacters(value)) {
    return RESULT_VALID;
  }
  return {
    atlasInvalidCharacters: true,
  };
}

/**
 * Checks the modulo validity of the given input's.
 * returns a RESULT_VALID if entry complies
 *
 * @param value The client number to validate
 */
function invalidCheckDigits(
  value: ValidatorInput<ClientNumber>,
): AtlasInvalidCheckDigitsValidationErrors | ValidValidationResult {
  if (ClientNumber.isClientNumber(value) || !value) {
    return RESULT_VALID;
  }
  if (validateCheckDigits(value)) {
    return RESULT_VALID;
  }
  return {
    atlasInvalidCheckDigits: true,
  };
}

/**
 * Checks the validity of the given input's length.
 * always validate the input
 *
 * @param value The client number to validate
 */
function invalidLength(
  value: ValidatorInput<ClientNumber>,
): AtlasInvalidLengthValidationErrors | ValidValidationResult {
  if (ClientNumber.isClientNumber(value)) {
    return RESULT_VALID;
  }
  if (value && validateLength(value)) {
    return RESULT_VALID;
  }

  return {
    atlasInvalidLength: {
      requiredLength: CLIENT_NUMBER_LENGTH,
      actualLength: value ? value.length : 0,
    },
  };
}

/**
 * The BaseClientNumberValidators object contains partial client number
 * validators returning information on the invalidity instead of simply returning a boolean
 * valid/invalid result.
 *
 * @deprecated use `validateClientNumber` instead
 */
export const BaseClientNumberValidators: {
  /**
   * Checks the validity of the given input's characters.
   * returns a RESULT_VALID if entry is a falsy value
   *
   * @param value The client number to validate
   */
  readonly invalidCharacters: (
    value: ValidatorInput<ClientNumber>,
  ) => AtlasInvalidCharactersValidationErrors | ValidValidationResult;

  /**
   * Checks the modulo validity of the given input's.
   * returns a RESULT_VALID if entry complies
   *
   * @param value The client number to validate
   */
  readonly invalidCheckDigits: (
    value: ValidatorInput<ClientNumber>,
  ) => AtlasInvalidCheckDigitsValidationErrors | ValidValidationResult;

  /**
   * Checks the validity of the given input's length.
   * always validate the input
   *
   * @param value The client number to validate
   */
  readonly invalidLength: (
    value: ValidatorInput<ClientNumber>,
  ) => AtlasInvalidLengthValidationErrors | ValidValidationResult;
} = {
  invalidCharacters,
  invalidCheckDigits,
  invalidLength,
} as const;

/**
 * Checks if the client number only contains the correct characters (alphanumeric).
 *
 * @param clientNumber The client number to validate
 * @returns True in case the client number characters are correct
 */
function validateCharacters(clientNumber: string): boolean {
  return validateLength(clientNumber) && /^[a-z0-9]{7}\d{2}$/i.test(clientNumber);
}

/**
 * Performs the length check on the given client number.
 *
 * @param clientNumber The client number to validate
 * @returns True in case the client number has the correct length
 */
function validateLength(clientNumber: string): boolean {
  return clientNumber.length === CLIENT_NUMBER_LENGTH;
}

/**
 * Checks the modulo validity of the given input.
 * current validation differs from current validation
 * https://agit.kbc.be:6088/projects/ATLAS/repos/atlasjs-blessed/.../clientNumberValidator.coffee
 * this implementation aligns with MT
 * https://agit.kbc.be:6088/projects/ATLAS/repos/atlas-group-framework-blessed/.../ClientNumberValidationLogic.java
 *
 * @param clientNumber The client number to validate
 */
function validateCheckDigits(clientNumber: string): boolean {
  if (!validateCharacters(clientNumber)) {
    return false;
  }

  const clientNumberValue = CHAR_INT_MAPPER.reduce(
    (value, map, index) => value.replace(map, `${index + 1}`),
    clientNumber,
  );
  const base = clientNumberValue.slice(0, -CLIENT_CHECK_DIGIT_SIZE);
  const checkDigit = clientNumberValue.slice(-CLIENT_CHECK_DIGIT_SIZE);
  let modulo = bigConstructor(base).mod(MOD_VALUE);
  if (modulo.eq(bigConstructor(0))) {
    modulo = bigConstructor(MOD_VALUE);
  }
  const check = bigConstructor(checkDigit);
  return modulo.eq(check);
}

/**
 * Checks whether the client number is valid or not.
 *
 * @param input The client number to validate
 */
export function isValidClientNumber(input: ValidatorInput<ClientNumber>): boolean {
  return isValid(validateClientNumber(input));
}

/**
 * Validate the given client number input
 *
 * @param input The client number to validate
 */
export function validateClientNumber(
  input: ValidatorInput<ClientNumber>,
): ValidationErrors | ValidValidationResult {
  if (ClientNumber.isClientNumber(input)) {
    return RESULT_VALID;
  }

  return (
    combineResults(invalidLength(input), invalidCharacters(input)) ?? invalidCheckDigits(input)
  );
}
