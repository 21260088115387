import {
  isValid,
  RESULT_VALID,
  ValidationErrors,
  ValidatorInput,
  ValidValidationResult,
} from '../base/validators';

import {Boolean} from './businesstype';

/**
 * Validation errors object if the boolean is invalid.
 */
export interface AtlasInvalidBooleanValidationErrors extends ValidationErrors {
  atlasInvalidBoolean: {actualValue?: string | null};
}

/**
 * Checks whether the boolean input is valid or not.
 *
 * @param input The boolean input to validate
 */
export function isValidBoolean(input: ValidatorInput<Boolean> | boolean): boolean {
  return isValid(validateBoolean(input));
}

function doValidate(
  value: ValidatorInput<Boolean> | boolean,
): AtlasInvalidBooleanValidationErrors | ValidValidationResult {
  if (typeof value === 'boolean' || Boolean.isBoolean(value)) {
    return RESULT_VALID;
  }

  if ((value != null && value.toLowerCase() === 'true') || value?.toLowerCase() === 'false') {
    return RESULT_VALID;
  }

  return {atlasInvalidBoolean: {actualValue: value}};
}

/**
 * @deprecated Use `validateBoolean` instead
 */
export const BaseBooleanValidator: {
  readonly invalidBoolean: (
    value: ValidatorInput<Boolean> | boolean,
  ) => AtlasInvalidBooleanValidationErrors | ValidValidationResult;
} = {
  invalidBoolean: doValidate,
};

/**
 * Validate the given boolean input
 *
 * @param value The boolean to validate
 */
export function validateBoolean(
  value: ValidatorInput<Boolean> | boolean,
): ValidationErrors | ValidValidationResult {
  return doValidate(value);
}
