import {NgModule} from '@angular/core';
import {ModalsModule} from '@maia/modals';

import {AttachedDropdownComponent} from './attached/attached-dropdown.component';
import {DropdownController} from './controller/dropdown-controller.service';
import {DropdownHostFactory} from './host/host-factory.service';
import {DropdownHostDirective} from './host/host.directive';
import {RegularDropdownComponent} from './regular/regular-dropdown.component';

@NgModule({
  imports: [ModalsModule],
  declarations: [AttachedDropdownComponent, RegularDropdownComponent, DropdownHostDirective],
  exports: [DropdownHostDirective],
  providers: [DropdownController, DropdownHostFactory],
  entryComponents: [AttachedDropdownComponent, RegularDropdownComponent],
})
export class DropdownsModule {}
