import {BusinessType, createStringifier} from '../base/businesstype';
import {JsonValue} from '../base/json';

import {isValidFile} from './validator-base';

const stringifier = createStringifier('file');

const NOT_FOUND = -1;
const MIME_TYPE_SEPARATOR = ',';

export interface FileConstructor<T extends BusinessType<unknown>> {
  new (value: string, mimeType?: string, encryptedValue?: string): T;
}

/**
 * BusinessType wrapper around files
 */
export class File extends BusinessType<string> {
  /**
   * Returns whether or not `value` is a File.
   */
  public static isFile(value: unknown): value is File {
    return value instanceof File;
  }

  /**
   * Don't use this directly, use `fromJsonValue` instead
   */
  public static overrideFromJsonValue(value: JsonValue, encryptedValue?: string): File {
    if (typeof value !== 'string') {
      throw new Error(`Expected a string but got ${value && typeof value}`);
    }

    // Base64 does not ever contain a comma, we can take it as separator from MimeType and
    // Base64-InternalValue
    const mymeTypeSeparatorIdx = value.indexOf(MIME_TYPE_SEPARATOR);
    if (mymeTypeSeparatorIdx === NOT_FOUND) {
      return new this(value, undefined, encryptedValue);
    } else {
      return new this(
        value.substring(mymeTypeSeparatorIdx + MIME_TYPE_SEPARATOR.length).trim(), // InternalValue (skip the separator)
        value.substring(0, mymeTypeSeparatorIdx).trim(), // MimeType
        encryptedValue,
      );
    }
  }

  /**
   * Don't use this directly, use `toJsonValue` instead
   */
  public static overrideToJsonValue(value: File): string {
    return value.getMimeType()
      ? `${value.getMimeType()}${MIME_TYPE_SEPARATOR}${value.asString()}`
      : `${value.asString()}`;
  }

  private readonly mimeType?: string;

  /**
   * Constructs a new File instance.
   *
   * @param value The base 64 encoded file as a string
   * @param mimeType Format file identifier for the file in value
   * @param encryptedValue The encryptedValue, if any
   */
  public constructor(value: string, mimeType?: string, encryptedValue?: string) {
    if (typeof value !== 'string') {
      throw new Error(
        `Expected a string but got ${
          (typeof value === 'object' &&
            // eslint-disable-next-line @typescript-eslint/ban-types
            (value as object).constructor.name) ||
          typeof value
        }`,
      );
    } else if (!isValidFile(value)) {
      throw new Error(`Cannot create File for invalid input ${JSON.stringify(value)}`);
    }
    super(value, encryptedValue);

    Object.defineProperties(this, {
      mimeType: {
        value: mimeType,
        configurable: false,
        writable: false,
      },
    });
  }

  /**
   * Returns whether `other` is a `File` representing the same value.
   */
  public equals(other: unknown): boolean {
    if (!other) {
      return false;
    }

    if (other === this) {
      return true;
    }

    return File.isFile(other) && this.internalValue === other.internalValue;
  }

  /**
   * Exposes the value of this BusinessType as a Base64 encoded string. The returned value will
   * always be the same.
   */
  public asString(): string {
    return this.internalValue;
  }

  public toString(): string {
    if (this.mimeType) {
      return `${stringifier(this)}, ${this.mimeType}`;
    } else {
      return stringifier(this);
    }
  }

  /**
   * Returns the mime type of the File
   */
  public getMimeType(): string | undefined {
    return this.mimeType;
  }
}
