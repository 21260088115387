import {NgModule} from '@angular/core';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';
import {FormsModule} from '@maia/forms';
import {μFormElementWideModule as FormElementWideModule} from '@maia/forms';

import {TextAreaLimitComponent} from './text-area-limit.component';
import {TextAreaComponent, TextAreaWithNgModelDirective} from './text-area.component';

@NgModule({
  imports: [BusinessTypesModule, FormsModule],
  declarations: [TextAreaComponent, TextAreaWithNgModelDirective, TextAreaLimitComponent],
  exports: [
    TextAreaComponent,
    TextAreaWithNgModelDirective,
    TextAreaLimitComponent,

    // Exported so the wide form-element can only be accessed when TextAreaModule is imported
    FormElementWideModule,
  ],
})
export class TextAreaModule {}
