import {NgModule} from '@angular/core';

import {DotsSpinnerComponent} from './dots-spinner/dots-spinner.component';
import {SpinnerComponent} from './spinner/spinner.component';

@NgModule({
  declarations: [DotsSpinnerComponent, SpinnerComponent],
  exports: [DotsSpinnerComponent, SpinnerComponent],
})
export class SpinnersModule {}
