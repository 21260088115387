import {HostBinding, Input, Directive} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

@Directive()
export abstract class InlineActionAbstract {
  @coerceBooleanPrimitive()
  @Input()
  @HostBinding('class.maia-inline-action--disabled')
  public disabled = false;
}
