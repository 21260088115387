/*
 * This file contains the basic vat validators
 * to check whether a vat is actually a valid vat.
 */

import {MOD_VALUE, validateModulo} from '../base/modulo-validator';
import {
  AtlasInvalidCheckDigitsValidationErrors,
  AtlasInvalidLengthValidationErrors,
  AtlasMaxLengthValidationErrors,
  AtlasMinLengthValidationErrors,
  isValid,
  RESULT_VALID,
  ValidationErrors,
  ValidatorInput,
  ValidValidationResult,
} from '../base/validators';

import {Vat} from './businesstype';
import {
  BELGIAN_LENGTH,
  COUNTRY_SUBSTRING_LENGTH,
  FOREIGN_MAX_LENGTH,
  FOREIGN_MIN_LENGTH,
  VAT_SPLIT_LENGTH,
} from './constants';

const BELGIAN_VALID_VAT_REGEX = /^(BE)[\d]{10}$/;
const FOREIGN_VALID_VAT_REGEX = /^[A-Z]{2}[a-zA-Z0-9]{1,13}$/;

type AtlasVatValidationLength =
  | AtlasMaxLengthValidationErrors
  | AtlasInvalidLengthValidationErrors
  | AtlasMinLengthValidationErrors
  | ValidValidationResult;
type AtlasVatValidationCheckDigits =
  | AtlasInvalidCheckDigitsValidationErrors
  | ValidValidationResult;

/**
 * Checks the validity of the given input's length.
 *
 * @param value The vat input to validate
 */
function invalidLength(value: ValidatorInput<Vat>): AtlasVatValidationLength {
  if (Vat.isVat(value)) {
    return RESULT_VALID;
  } else if (value) {
    return validateLength(value);
  } else {
    return {
      atlasMinLength: {
        limit: Math.min(FOREIGN_MIN_LENGTH, BELGIAN_LENGTH),
        actualValue: 0,
      },
    };
  }
}

/**
 * Checks whether the given input is in an invalid format.
 *
 * The format is considered invalid if the check digit validation fails
 *
 * @param value The vat input to validate
 */
function invalidCheckDigits(value: ValidatorInput<Vat>): AtlasVatValidationCheckDigits {
  if (Vat.isVat(value)) {
    return RESULT_VALID;
  }
  if (value && !isValidVatValue(value)) {
    return {
      atlasInvalidCheckDigits: true,
    };
  } else {
    return RESULT_VALID;
  }
}

/**
 * The BaseVatValidators object contains partial vat validators, returning
 * information on the invalidity instead of simply returning a boolean
 * valid/invalid result.
 *
 * @deprecated Use `validateVat` instead
 */
export const BaseVatValidators: {
  /**
   * Checks the validity of the given input's length.
   *
   * @param value The vat input to validate
   */
  readonly invalidLength: (value: ValidatorInput<Vat>) => AtlasVatValidationLength;
  /**
   * Checks whether the given input is in an invalid format.
   *
   * The format is considered invalid if the check digit validation fails
   *
   * @param value The vat input to validate
   */
  readonly invalidCheckDigits: (value: ValidatorInput<Vat>) => AtlasVatValidationCheckDigits;
} = {
  invalidLength,
  invalidCheckDigits,
};

function isValidVatValue(inputVat: string): boolean {
  const countryCode = inputVat.substring(0, COUNTRY_SUBSTRING_LENGTH).toUpperCase();
  if (countryCode === 'BE') {
    const split = inputVat.length - VAT_SPLIT_LENGTH;
    const checkDigit =
      MOD_VALUE - (parseInt(inputVat.substring(VAT_SPLIT_LENGTH, split), 10) % MOD_VALUE);
    return (
      BELGIAN_VALID_VAT_REGEX.test(inputVat) &&
      validateModulo(inputVat.substring(split), `${checkDigit}`)
    );
  }
  return FOREIGN_VALID_VAT_REGEX.test(inputVat);
}

function validateBELength(
  inputVat: string,
): AtlasInvalidLengthValidationErrors | ValidValidationResult {
  const {length} = inputVat;

  if (length === BELGIAN_LENGTH) {
    return RESULT_VALID;
  } else {
    return {
      atlasInvalidLength: {
        requiredLength: BELGIAN_LENGTH,
        actualLength: length,
      },
    };
  }
}

function validateForeignLength(
  inputVat: string,
): AtlasMinLengthValidationErrors | AtlasMaxLengthValidationErrors | ValidValidationResult {
  const {length} = inputVat;

  if (length < FOREIGN_MIN_LENGTH) {
    return {
      atlasMinLength: {
        limit: FOREIGN_MIN_LENGTH,
        actualValue: length,
      },
    };
  }

  if (length <= FOREIGN_MAX_LENGTH) {
    return RESULT_VALID;
  } else {
    return {
      atlasMaxLength: {
        limit: FOREIGN_MAX_LENGTH,
        actualValue: length,
      },
    };
  }
}

/**
 *
 * Performs the length check on the given vat number
 *
 * @param inputVat The vat string to validate
 * @returns True in case the vat has the correct length
 */
function validateLength(inputVat: string): AtlasVatValidationLength {
  return inputVat.startsWith('BE') ? validateBELength(inputVat) : validateForeignLength(inputVat);
}

/**
 * Checks whether the vat input is valid or not.
 *
 * @param input The vat input to validate
 */
export function isValidVat(input: ValidatorInput<Vat>): boolean {
  return isValid(validateVat(input));
}

/**
 * Validate the given vat input
 *
 * @param input The vat to validate
 */
export function validateVat(input: ValidatorInput<Vat>): ValidationErrors | ValidValidationResult {
  if (Vat.isVat(input)) {
    return RESULT_VALID;
  }

  return invalidLength(input) ?? invalidCheckDigits(input);
}
