import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {μTilesSvgModule} from '@maia/icons';
import {LayoutModule} from '@maia/layout';

import {ImageTileIconComponent} from './image-tile-icon/image-tile-icon.component';
import {ImageTileImageComponent} from './image-tile-image/image-tile-image.component';
import {ImageTileLabelComponent} from './image-tile-label/image-tile-label.component';
import {ImageTileLargeComponent} from './image-tile-large/image-tile-large.component';
import {ImageTileSublabelComponent} from './image-tile-sublabel/image-tile-sublabel.component';
import {ImageTileComponent} from './image-tile/image-tile.component';
import {TileActionIconComponent} from './tile-action-icon/tile-action-icon.component';
import {TileAmountDisplayComponent} from './tile-amount-display/tile-amount-display.component';
import {TileDealComponent} from './tile-deal/tile-deal.component';
import {TileItemComponent} from './tile-item/tile-item.component';
import {TileItemsComponent} from './tile-items/tile-items.component';
import {TileTitleIconComponent} from './tile-title-icon/tile-title-icon.component';
import {TileTitleComponent} from './tile-title/tile-title.component';
import {TileComponent} from './tile/tile.component';
import {TileDocumentSignComponent} from './tile-document-sign/tile-document-sign.component';
import {TileDocumentSignLabelComponent} from './tile-document-sign-label/tile-document-sign-label.component';
import {TileDocumentSignTextComponent} from './tile-document-sign-text/tile-document-sign-text.component';
import {TileDocumentSignIconComponent} from './tile-document-sign-icon/tile-document-sign-icon.component';

@NgModule({
  imports: [CommonModule, μTilesSvgModule, LayoutModule],
  declarations: [
    ImageTileComponent,
    ImageTileLargeComponent,
    ImageTileIconComponent,
    ImageTileImageComponent,
    ImageTileLabelComponent,
    ImageTileSublabelComponent,
    TileActionIconComponent,
    TileAmountDisplayComponent,
    TileComponent,
    TileDealComponent,
    TileItemComponent,
    TileItemsComponent,
    TileTitleComponent,
    TileTitleIconComponent,
    TileDocumentSignComponent,
    TileDocumentSignLabelComponent,
    TileDocumentSignTextComponent,
    TileDocumentSignIconComponent,
  ],
  exports: [
    ImageTileComponent,
    ImageTileLargeComponent,
    ImageTileIconComponent,
    ImageTileImageComponent,
    ImageTileLabelComponent,
    ImageTileSublabelComponent,
    TileActionIconComponent,
    TileAmountDisplayComponent,
    TileComponent,
    TileDealComponent,
    TileItemComponent,
    TileItemsComponent,
    TileTitleComponent,
    TileTitleIconComponent,
    TileDocumentSignComponent,
    TileDocumentSignLabelComponent,
    TileDocumentSignTextComponent,
    TileDocumentSignIconComponent,
  ],
})
export class TilesModule {}
