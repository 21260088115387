import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule AlertFuncClearSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaAlertFuncClear]',
  template: '<svg:path d="M20.24 6v12H7.46l-5.12-6 5.12-6z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04"></svg:path><svg:path d="M11.36 10.14l3.73 3.72" fill="none" stroke-linecap="round" stroke-miterlimit="8" stroke-width="1.04"></svg:path><svg:path d="M11.36 13.86l3.73-3.72" fill="none" stroke-linecap="round" stroke-miterlimit="8" stroke-width="1.04"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertFuncClearSvgComponent {
}

@NgModule({
  declarations: [
    AlertFuncClearSvgComponent,
  ],
  exports: [
    AlertFuncClearSvgComponent,
  ],
})
export class AlertFuncClearSvgModule {}
