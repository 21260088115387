import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
} from '@angular/core';
import {Boolean} from '@atlas/businesstypes';
import {CheckboxComponent} from '@maia/switches';

/**
 * @ngModule TermsAndConditionsModule
 */
@Component({
  selector: 'maia-condition',
  templateUrl: './condition.component.html',
  styleUrls: ['./condition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConditionComponent implements AfterContentInit {
  @ContentChild(CheckboxComponent)
  private readonly _contentChildCheckbox!: CheckboxComponent;

  // When the checkbox is selected, the tile should be forced to be highlighted.
  public forceHighlightTile = false;

  public constructor(private readonly _changeDetector: ChangeDetectorRef) {}

  public ngAfterContentInit() {
    this._contentChildCheckbox.onValueChange.subscribe((checked: Boolean) => {
      this.forceHighlightTile = Boolean.isBoolean(checked) && checked.asBoolean();
      this._changeDetector.detectChanges();
    });
  }
}
