import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

import {InlineActionAbstract} from './inline-action.abstract';

/**
 * @ngModule ActionsModule
 */
@Component({
  selector: 'maia-inline-action',
  template: '<ng-content></ng-content>',
  styleUrls: ['./inline-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineActionButtonComponent extends InlineActionAbstract implements AfterContentInit {
  @Input()
  @HostBinding('attr.role')
  public role = 'button';
  @Input()
  @HostBinding('attr.type')
  public type = 'submit';

  @coerceBooleanPrimitive()
  @Input()
  public autofocus = false;

  @Output()
  public click: EventEmitter<Event> = new EventEmitter<Event>();

  @HostListener('keyup.space', ['$event'])
  @HostListener('keyup.spacebar', ['$event'])
  @HostListener('keyup.enter', ['$event'])
  public onKeydownHandler(event: Event) {
    if (!this.disabled) {
      const eventType = this.type === 'button' ? 'click' : this.type;
      this.click.emit(
        new Event(eventType, {
          cancelable: true,
          bubbles: true,
        }),
      );
    }
  }

  public constructor(private readonly _el: ElementRef) {
    super();
  }

  public ngAfterContentInit() {
    if (this.autofocus) {
      (this._el.nativeElement as HTMLElement).focus();
    }
  }
}
