import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionFuncFullscreenSvgModule
 */
@Component({
  selector: 'svg[ivyHermesActionFuncFullscreen]',
  template: '<svg:g class="nc-icon-wrapper" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"><svg:path fill="none" stroke-miterlimit="10" d="M4 8.8V4h4.8" stroke-width="1.04"></svg:path><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M15.2 4H20v4.8" stroke-width="1.04"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M20 15.2V20h-4.8" stroke-width="1.04"></svg:path><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M8.8 20H4v-4.8" stroke-width="1.04"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionFuncFullscreenSvgComponent {
}

@NgModule({
  declarations: [
    ActionFuncFullscreenSvgComponent,
  ],
  exports: [
    ActionFuncFullscreenSvgComponent,
  ],
})
export class ActionFuncFullscreenSvgModule {}
