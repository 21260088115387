import {FocusOrigin} from '@angular/cdk/a11y';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  Optional,
  QueryList,
  SkipSelf,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {
  isValidPhoneNumber,
  BasePhoneNumberValidators,
  ExtraPhoneNumberValidators,
  PhoneNumber,
} from '@atlas/businesstypes';
import {DropdownTemplateContext} from '@maia/dropdowns';
import {InputContainer, provideNestedInputContainer} from '@maia/forms';
import {BaseInputWithSelectComponent} from '@maia/input-with-select';
import {Option, OptionContainer} from '@maia/select';
import {Observable} from 'rxjs';

import {CountryDropdownComponent} from '../country-dropdown/country-dropdown.component';
import {PhoneNumberValue} from '../model/phonenumber-value';
import {PhonenumberCountry} from '../phonenumber-country/phonenumber-country';
import {PhonenumberCountryComponent} from '../phonenumber-country/phonenumber-country.component';
import {sanitizeNumber} from '../util/phonenumber-sanitizer';

/**
 * @ngModule InputPhonenumberModule
 */
@Component({
  selector: 'maia-input-phonenumber',
  templateUrl: './input-phonenumber.component.html',
  styleUrls: ['./input-phonenumber.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    provideNestedInputContainer(),
    {provide: OptionContainer, useExisting: forwardRef(() => InputPhonenumberComponent)},
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPhonenumberComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputPhonenumberComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class InputPhonenumberComponent
  extends BaseInputWithSelectComponent<PhonenumberCountry>
  implements OptionContainer<PhonenumberCountry>, AfterContentInit, Validator {
  public readonly number: FormControl;
  public readonly country: FormControl;

  private _rawValue: string | undefined = undefined;
  private phoneNumber?: PhoneNumber;
  public _initialValue?: PhoneNumberValue;

  private _onValidationChange?: () => void = undefined;

  @Input()
  public set initialValue(value: PhoneNumberValue) {
    this._initialValue = value;
  }

  @ViewChild('dropdown', {static: true})
  public readonly countryDropdown: CountryDropdownComponent;

  @ViewChild('dropdownContent', {static: true})
  public dropdownContent: TemplateRef<DropdownTemplateContext<Option<PhonenumberCountry>>>;

  @ContentChildren(PhonenumberCountryComponent)
  public options: QueryList<Option<PhonenumberCountry>>;

  public get keyEvent$(): Observable<void> {
    return this.countryDropdown.keyEvent$;
  }

  public constructor(
    fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    @SkipSelf() @Optional() inputContainer?: InputContainer,
  ) {
    super(inputContainer);
    this.number = fb.control(undefined);
    this.country = fb.control(undefined);

    this.number.valueChanges.pipe(takeUntilDestroyed(this)).subscribe(() => this.emitValue());
    this.country.valueChanges.pipe(takeUntilDestroyed(this)).subscribe(() => {
      this._onValidationChange?.();
      this.emitValue();
    });
  }

  public ngAfterContentInit(): void {
    this.applyInitialValue();

    if (!this.country.value && this.options.length > 0) {
      this.country.setValue(this.options.first.value, {emitEvent: false});
    }

    this._inputContainer?.disabled$.subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  public applyInitialValue(): void {
    if (this.phoneNumber != null) {
      this.writeValue(this.phoneNumber);
      return;
    }

    if (!this._initialValue || this.options == null) {
      return;
    }

    const countryOption = this.options.find(
      option => option.value.prefix === this._initialValue!.prefix,
    );

    if (!countryOption) {
      return;
    }

    this.country.setValue(countryOption.value);
    this.number.setValue(this._initialValue.number);
  }

  public writeValue(value: unknown): void {
    if (!value) {
      this.phoneNumber = undefined;
      this.applyInitialValue();
      return;
    }

    let stringValue: string;
    if (PhoneNumber.isPhoneNumber(value)) {
      this.phoneNumber = value;
      stringValue = value.asString();
    } else {
      this.phoneNumber = undefined;
      stringValue = value != null ? String(value) : '';
    }

    if (this.options == null) {
      // component is being initialised, we'll call this function again later on once the options
      // are filled in
      return;
    }

    const matchingPrefixes = this.options.filter(option =>
      stringValue.startsWith(option.value.prefix),
    );
    if (matchingPrefixes.length === 0) {
      this.country.setValue(this.options.first?.value);
      this.number.setValue(stringValue);
    } else {
      const longestMatchingPrefix = matchingPrefixes.reduce((a, b) =>
        a.value.prefix.length > b.value.prefix.length ? a : b,
      );
      this.country.setValue(longestMatchingPrefix.value);
      this.number.setValue(stringValue.slice(longestMatchingPrefix.value.prefix.length));
    }
  }

  public emitValue(): void {
    this.updatePhoneNumber();
    this._onChange(this.phoneNumber ?? this._rawValue);
  }

  private updatePhoneNumber() {
    if (!this.number.value || !this.country.value) {
      this.clearPhoneNumber();
      return;
    }

    this.sanitizeNumber();

    // phone number might be empty after sanitization (if no number apart from prefix was provided)
    if (!this.number.value) {
      this.clearPhoneNumber();
      return;
    }

    this._rawValue =
      `${(this.country.value as {prefix: string}).prefix}${this.number.value as string}`.replace(
        /[./-]|\s/g,
        '',
      ) || undefined;
    const phoneNumber = isValidPhoneNumber(this._rawValue)
      ? new PhoneNumber(this._rawValue!)
      : undefined;

    if (phoneNumber?.equals(this.phoneNumber)) {
      return;
    }

    this.phoneNumber = phoneNumber;
  }

  private clearPhoneNumber(): void {
    this.phoneNumber = undefined;
    this._rawValue = undefined;
  }

  private sanitizeNumber() {
    const sanitizedNumber = sanitizeNumber(
      this.number.value,
      (this.country.value as {prefix: string}).prefix,
    );

    if (this.number.value === sanitizedNumber) {
      return;
    }

    this.number.setValue(sanitizedNumber);
  }

  public activateOption(option: Option<PhonenumberCountry>, focusOrigin?: FocusOrigin): void {
    this.countryDropdown.activateOption(option, focusOrigin);
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.number.disable();
      this.country.disable();
    } else {
      this.number.enable();
      this.country.enable();
    }
  }

  // Validator API

  public validate(control: AbstractControl): ValidationErrors | null {
    const {value} = control as {value: string | null | undefined};

    // Allow empty input to support optional fields
    if (value === undefined || value === null || value === '') {
      return null;
    }

    return (
      BasePhoneNumberValidators.invalidCharacters(control.value) ??
      BasePhoneNumberValidators.invalidLength(control.value) ??
      ExtraPhoneNumberValidators.callCodePrefixedPhoneNumber(control.value)
    );
  }

  public registerOnValidatorChange(fn?: () => void): void {
    this._onValidationChange = fn;
  }

  // /Validator API
}
