import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'maia-full-view-overlay-action',

  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullViewOverlayActionComponent {}
