import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule GeneralChevronSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaGeneralChevron]',
  template: '<svg:path d="M-107 462.2l49.8 49.8L189 256.2-57.6 0-107 49.4v.8l198.3 206z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '-107 0 296 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralChevronSvgComponent {
}

@NgModule({
  declarations: [
    GeneralChevronSvgComponent,
  ],
  exports: [
    GeneralChevronSvgComponent,
  ],
})
export class GeneralChevronSvgModule {}
