import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

/**
 * @ngModule DisplaysModule
 */
@Component({
  selector: 'maia-opening-hours-display-item',
  templateUrl: './opening-hours-display-item.component.html',
  styleUrls: ['./opening-hours-display-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpeningHoursDisplayItemComponent {
  @Input()
  public label: string;
}
