import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprSignScanSvgModule
 */
@Component({
  selector: 'svg[ivyHermesInformationExprSignScan]',
  template: '<svg:path id="svgGyYm7zuweagQUyMEJGH4qhGE3iOQmBOjP8i6BTlHn8M=" d="M13.6 19.9h5v17.3h-5z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M21.5 19.9h5v17.3h-5z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M29.4 19.9h5v17.3h-5z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M5.7 19.9h5v17.3h-5z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path id="svgaARYTFzw6SfFeyiNZRT2C5sYJ+fyCZJ1O9kEY3MdHb8=" d="M4.8 10.8l3.3 5.8 3.4-5.8z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M37.4 19.9h5v17.3h-5z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M36.5 10.8l3.4 5.8 3.3-5.8z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprSignScanSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprSignScanSvgComponent,
  ],
  exports: [
    InformationExprSignScanSvgComponent,
  ],
})
export class InformationExprSignScanSvgModule {}
