/* eslint-disable import/no-extraneous-dependencies */
import {A11yModule} from '@angular/cdk/a11y';
import {PortalModule} from '@angular/cdk/portal';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ModalsModule} from '@maia/modals';

import {µToastComponent} from './container/toast.component';
import {ToastController} from './controller/toast-controller.service';

@NgModule({
  imports: [A11yModule, CommonModule, ModalsModule, PortalModule],
  declarations: [µToastComponent],
  entryComponents: [µToastComponent],
  providers: [ToastController],
})
export class ToastModule {}
