import {Renderer2} from '@angular/core';
import {Subscription} from 'rxjs';

import {RichTextLinkHandler} from './rich-text-link-handler.service';
import {RichTextToken} from './rich-text-lexer.util';

// Corresponding classes to rich-text tags
const SIMPLE_CLASSES = {
  B: 'maia-bold',
  I: 'maia-italic',
  L: 'maia-as-label',
  NR: 'maia-nowrap',
  SUP: 'maia-superscript',
  U: 'maia-underline',
};

const CODE_OFFSET = '[T]';
const CLASS_OFFSET = 'maia-with-offset';
const DEFAULT_COLOR = 'primary';

export interface ParserContext {
  tokens: RichTextToken[];
  index: number;
  length: number;

  renderer: Renderer2;

  linkHandler: RichTextLinkHandler;
  subscription: Subscription;
}

export function parseRichText(context: ParserContext, parent: HTMLElement): Node[] {
  const [firstToken] = context.tokens;

  if (firstToken != null && firstToken.type === 'tag' && firstToken.text === CODE_OFFSET) {
    context.index++;
    return [_parseSpan(context, parent, CLASS_OFFSET)];
  } else {
    return _parseRichText(context, parent);
  }
}

function _parseRichText(context: ParserContext, parent: HTMLElement, end?: string): Node[] {
  const nodes: Node[] = [];

  while (context.index < context.length) {
    const token = context.tokens[context.index];
    context.index++;

    if (token.type === 'text') {
      nodes.push(_parseTextNode(context, parent, token));
      continue;
    }

    if (end != null && token.text === `[/${end}]`) {
      break;
    }

    const tagMatch = token.text.match(/^\[(.+?)(?:[ =](.+))?\]$/);

    // Not a valid tag, display it as is and continue
    if (tagMatch == null) {
      nodes.push(_parseTextNode(context, parent, token));
      continue;
    }

    const [, tag, tagValue] = tagMatch;

    const parser = tagParsers[tag];

    if (parser != null) {
      nodes.push(parser(context, parent, tag, tagValue));
    } else {
      nodes.push(_parseTextNode(context, parent, token));
    }
  }

  return nodes;
}

const tagParsers: {
  [tag: string]:
    | ((context: ParserContext, parent: HTMLElement, tag: string, tagValue?: string) => Node)
    | undefined;
} = {
  UL: _parseElement,
  OL: _parseElement,
  LI: _parseElement,

  B: _parseSimpleSpan,
  I: _parseSimpleSpan,
  L: _parseSimpleSpan,
  NR: _parseSimpleSpan,
  SUP: _parseSimpleSpan,
  U: _parseSimpleSpan,

  C: _parseColorSpan,

  BR: _parseLineBreak,

  URL: _parseLink,
};

function _parseTextNode(context: ParserContext, parent: HTMLElement, token: RichTextToken) {
  const text = context.renderer.createText(token.text) as Text;

  context.renderer.appendChild(parent, text);

  return text;
}

function _parseElement(context: ParserContext, parent: HTMLElement, tag: string) {
  const node = context.renderer.createElement(tag);
  context.renderer.appendChild(parent, node);

  _parseRichText(context, node, tag);

  return node;
}

function _parseSimpleSpan(context: ParserContext, parent: HTMLElement, tagName: string) {
  return _parseSpan(
    context,
    parent,
    SIMPLE_CLASSES[tagName as keyof typeof SIMPLE_CLASSES],
    tagName,
  );
}

function _parseColorSpan(
  context: ParserContext,
  parent: HTMLElement,
  tagName: string,
  tagValue?: string,
) {
  return _parseSpan(context, parent, `maia-rich-text--color-${tagValue || DEFAULT_COLOR}`, tagName);
}

function _parseSpan(context: ParserContext, parent: HTMLElement, classToAdd: string, tag?: string) {
  const node = context.renderer.createElement('span');
  context.renderer.addClass(node, classToAdd);
  context.renderer.appendChild(parent, node);

  // Call parseContent function inside the current node, to parse rich-text markers inside it
  void _parseRichText(context, node, tag);

  return node;
}

function _parseLineBreak(context: ParserContext, parent: HTMLElement, tag: string) {
  const node = context.renderer.createElement('br') as HTMLBRElement;
  context.renderer.appendChild(parent, node);
  return node;
}

function _parseLink(context: ParserContext, parent: HTMLElement, tag: string, tagValue: string) {
  const node = context.renderer.createElement('a');

  context.renderer.setAttribute(node, 'href', '');
  context.renderer.appendChild(parent, node);

  context.subscription.add(
    context.renderer.listen(node, 'click', event => {
      event.preventDefault();
      context.linkHandler.handleLink(tagValue.trim());
    }),
  );

  void _parseRichText(context, node, tag);

  return node;
}
