/* eslint-disable import/no-extraneous-dependencies */
import {Portal} from '@angular/cdk/portal';
import {ChangeDetectionStrategy, Component, InjectionToken} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {Observable} from 'rxjs';

export const TOAST_PORTALS = new InjectionToken<Observable<Portal<any>[]>>('toastPortals');

/**
 * @ngModule
 */
@Component({
  selector: 'maia-toast',
  template:
    '<ng-container *ngFor="let portal of (control.input | async)" [cdkPortalOutlet]="portal"></ng-container>',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class µToastComponent implements ModalContentComponent<void, Observable<Portal<any>[]>> {
  public constructor(public readonly control: ModalControl<void, Observable<Portal<any>[]>>) {}
}
