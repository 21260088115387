import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

/**
 * maia tile document sign Component
 *
 * This component contains an maia-tile-document-sign-icon
 * along with maia-tile-document-sign-text and maia-tile-document-sign-label
 * in case of long text you must pass `longText` property
 * An example using an svg icon and longText and label:
 *
 * ```html
 * <maia-tile-document-sign longText>
 *    <maia-tile-document-sign-icon>
 *       <svg class="maia-icon" ivyMaiaFileFuncDocument></svg>
 *    </maia-tile-document-sign-icon>
 *     <maia-tile-document-sign-text>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
 *       Ut tempor pulvinar ex,sit amet blandit eros congue a</maia-tile-document-sign-text>
 *     <maia-tile-document-sign-label>
 *        <button maiaButton>Sign</button>
 *     </maia-tile-document-sign-label>
 * </maia-tile-document-sign>
 * ```
 *
 * An example using an svg icon and text and label:
 *
 * ``` html
 * <maia-tile-document-sign>
 *    <maia-tile-document-sign-icon>
 *      <svg class="maia-icon" ivyMaiaFileFuncDocument></svg>
 *    </maia-tile-document-sign-icon>
 *    <maia-tile-document-sign-text>Lorem ipsum dolor sit amet</maia-tile-document-sign-text>
 *    <maia-tile-document-sign-label>
 *      <button maiaButton>Sign</button>
 *    </maia-tile-document-sign-label>
 * </maia-tile-document-sign>
 * ```

 * @ngModule TilesModule
 */
@Component({
  selector: 'maia-tile-document-sign',
  templateUrl: './tile-document-sign.component.html',
  styleUrls: ['./tile-document-sign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileDocumentSignComponent {
  /**
   * Property to indicate the tile has a long text
   * If true, add a class that aligns icon and label top.
   * Default center align
   */
  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('class.p-maia-tile-document-sign--long-text')
  public longText = false;

  /**
   * Property to indicate the tile is disabled
   * If true, add a class that disable the tile
   * Default enabled
   */
  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('class.maia-tile-document-sign--disabled')
  public disabled = false;
}
