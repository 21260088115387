import {ChangeDetectionStrategy, Component, Optional} from '@angular/core';

import {RadioButtonComponent} from '../radio-button/radio-button.component';
import {RadioGroupComponent} from '../radio-group/radio-group.component';

/**
 * @ngModule RadioButtonsModule
 */
@Component({
  selector: 'maia-radio-button-sublabel',
  templateUrl: './radio-button-sublabel.component.html',
  styleUrls: ['./radio-button-sublabel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonSublabelComponent {
  public constructor(
    private readonly _radioGroup: RadioGroupComponent,
    @Optional() private readonly _parent?: RadioButtonComponent,
  ) {
    if (this._parent == null || this._radioGroup == null) {
      throw new Error(
        'maia-radio-button-sublabel cannot be used standalone, only' +
          'inside a maia-radio-button that belongs to a maia-radio-group.',
      );
    }
  }
  public get isVertical(): boolean {
    return !this._radioGroup.horizontal;
  }
}
