import {Directive, forwardRef, Input} from '@angular/core';
import {NG_VALIDATORS, ValidatorFn} from '@angular/forms';
import {Date, ExtraDateValidators, validateDate} from '@atlas/businesstypes';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore See https://github.com/microsoft/rushstack/issues/1050
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type {ParameterizedValidatorConstructor, AtlasValidatorFn} from '../base/validator';
import {
  AbstractValidatorDirective,
  createParameterizedValidatorDirective,
  createValidatorFn,
} from '../base/validator';

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasDate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BaseDateValidator),
      multi: true,
    },
  ],
})
export class BaseDateValidator extends AbstractValidatorDirective {
  protected readonly validatorFn = validateDate;
}

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasDate][atlasBefore]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BeforeDateValidator),
      multi: true,
    },
  ],
})
export class BeforeDateValidator extends createParameterizedValidatorDirective<string | Date>(
  ExtraDateValidators.before,
) {
  @Input('atlasBefore')
  public set limit(newLimit: string | Date) {
    this.setParameter(newLimit);
  }
}

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasDate][atlasAfter]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AfterDateValidator),
      multi: true,
    },
  ],
})
export class AfterDateValidator extends createParameterizedValidatorDirective<string | Date>(
  ExtraDateValidators.after,
) {
  @Input('atlasAfter')
  public set limit(newLimit: string | Date) {
    this.setParameter(newLimit);
  }
}

export const ExtraDateControlValidators = {
  before(date: string | Date): ValidatorFn {
    return createValidatorFn(ExtraDateValidators.before(date));
  },

  after(date: string | Date): ValidatorFn {
    return createValidatorFn(ExtraDateValidators.after(date));
  },
} as const;
