import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 * A container element for messages when no options are shown, e.g. to tell the user to start typing
 * in order to get type-ahead values.
 *
 * @ngModule InputAutocompleteExportedPartsModule
 */
@Component({
  selector: 'maia-empty-option',
  template: '<ng-content></ng-content>',
  styleUrls: ['./empty-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyOptionComponent {}
