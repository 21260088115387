import {Boolean, Text} from '@atlas/businesstypes';
import {CallOptions, CallType} from '@atlas/convertor';
import {TypedJSONCall} from '@atlas/convertor-typed-json';
import {HermesAuthenticatedPathIdentifier, HermesPublicPathIdentifier} from '@hermes/core';
import {JsonType} from './type';

export interface TokenInput {
  token: Text;
  callerId?: Text;
  categoryId?: Text;
}

export interface ValidateTokenOutput {
  valid: Boolean;
}

const ACCEPT_HEADER = 'Accept';
const CONTENT_TYPE_HEADER = 'Content-Type';

export abstract class TokenCall<I, O> extends TypedJSONCall<I, O> {
  public options: CallOptions = {
    callType: CallType.READ,
    headers: new Map([
      [ACCEPT_HEADER, this.jsonType],
      [CONTENT_TYPE_HEADER, this.jsonType],
    ]),
  };
  protected constructor(private readonly jsonType: JsonType) {
    super();
  }
}

export class DecryptTokenCall extends TokenCall<TokenInput, any> {
  public identifier = new HermesAuthenticatedPathIdentifier('decryptToken', '1');
  public constructor(acceptHeader: JsonType) {
    super(acceptHeader);
  }
}

export class ValidateTokenCall extends TokenCall<TokenInput, ValidateTokenOutput> {
  public identifier = new HermesPublicPathIdentifier('validateToken', '1');
  public constructor(acceptHeader: JsonType) {
    super(acceptHeader);
  }
}
