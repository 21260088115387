/**
 * Possible modal resolutions.
 */
export enum ModalResolution {
  /**
   * The modal was dismissed by code, not by user interaction.
   */
  DISMISSED,

  /**
   * The modal was cancelled by the user, e.g. by clicking a cancel button or clicking the backdrop.
   */
  CANCELLED,

  /**
   * The modal was confirmed by the user, e.g. by clicking a button.
   */
  CONFIRMED,
}

export interface ModalConfirmedResult<T> {
  resolution: ModalResolution.CONFIRMED;
  result: T;
}

/**
 * A modal result.
 */
export type ModalResult<T> =
  | {
      resolution: ModalResolution.CANCELLED | ModalResolution.DISMISSED;
    }
  | ModalConfirmedResult<T>;
