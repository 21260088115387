import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PlatformModule} from '@angular/cdk/platform';
import {μLayoutSvgModule} from '@maia/icons';
import {ModalsModule} from '@maia/modals';

import {LayoutContentFooterComponent} from './content-footer-layout/layout-content-footer.component';
import {LayoutContentComponent} from './content-footer-layout/layout-content/layout-content.component';
import {LayoutFooterComponent} from './content-footer-layout/layout-footer/layout-footer.component';
import {FixedFluidLayoutComponent} from './fixed-fluid-layout/fixed-fluid-layout.component';
import {FluidFixedLayoutComponent} from './fluid-fixed-layout/fluid-fixed-layout.component';
import {FluidFluidLayoutComponent} from './fluid-fluid-layout/fluid-fluid-layout.component';
import {FullViewOverlayActionComponent} from './full-view-overlay/action/action.component';
import {FullViewOverlayController} from './full-view-overlay/controller/full-view-overlay-controller.service';
import {FullViewOverlayComponent} from './full-view-overlay/full-view-overlay.component';
import {FullViewOverlayHeaderDirective} from './full-view-overlay/header/header.directive';
import {InheritLayoutComponent} from './inherit-layout/inherit-layout.component';
import {LayoutComponent} from './layout/layout.component';
import {GridListComponent} from './grid-list/grid-list.component';
import {ImageContentLayoutComponent} from './image-content-layout/image-content-layout.component';
import {ImageContentLayoutImageComponent} from './image-content-layout-image/image-content-layout-image.component';
@NgModule({
  imports: [CommonModule, ModalsModule, PlatformModule, μLayoutSvgModule],
  declarations: [
    FixedFluidLayoutComponent,
    FluidFixedLayoutComponent,
    FluidFluidLayoutComponent,
    FullViewOverlayActionComponent,
    FullViewOverlayComponent,
    FullViewOverlayHeaderDirective,
    ImageContentLayoutComponent,
    ImageContentLayoutImageComponent,
    InheritLayoutComponent,
    LayoutComponent,
    LayoutContentComponent,
    LayoutContentFooterComponent,
    LayoutFooterComponent,
    GridListComponent,
  ],
  exports: [
    FixedFluidLayoutComponent,
    FluidFixedLayoutComponent,
    FluidFluidLayoutComponent,
    FullViewOverlayActionComponent,
    FullViewOverlayComponent,
    FullViewOverlayHeaderDirective,
    ImageContentLayoutComponent,
    ImageContentLayoutImageComponent,
    InheritLayoutComponent,
    LayoutComponent,
    LayoutContentComponent,
    LayoutContentFooterComponent,
    LayoutFooterComponent,
    GridListComponent,
  ],
  entryComponents: [FullViewOverlayComponent],
  providers: [FullViewOverlayController],
})
export class LayoutModule {}
