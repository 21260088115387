const OPEN_BRACKET = '[';
const CLOSE_BRACKET = ']';

export interface RichTextToken {
  type: 'tag' | 'text';
  text: string;
}

/**
 * Transform a rich-text string into a stream of tokens
 */
export function* lexRichText(value: string): IterableIterator<RichTextToken> {
  let index = 0;
  const {length} = value;

  while (index < length) {
    const openBracket = value.indexOf(OPEN_BRACKET, index);
    if (openBracket === -1) {
      break;
    }

    const closeBracket = value.indexOf(CLOSE_BRACKET, openBracket);
    if (closeBracket === -1) {
      break;
    }

    const text = value.slice(index, openBracket);
    if (text) {
      yield {type: 'text', text};
    }

    yield {type: 'tag', text: value.slice(openBracket, closeBracket + 1)};
    index = closeBracket + 1;
  }

  const leftOverText = value.slice(index);
  if (leftOverText) {
    yield {type: 'text', text: leftOverText};
  }
}
