import {FocusOrigin} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Optional,
  Output,
  QueryList,
  Self,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {DropdownTemplateContext} from '@maia/dropdowns';
import {SelectDropdownComponent} from '@maia/dropdowns/select';
import {ModalResolution} from '@maia/modals';
import {Subject} from 'rxjs';

import {SelectListKeyManager} from '../list-key-manager';
import {Option, OptionContainer} from '../option/option';

import {OPEN_AS_DROPDOWN, SelectOpener} from './select-open.directive';

/**
 * Simple select component, allowing users to pick an item from a list _outside_ of a form.
 *
 * If you want to add a select to a form, use `<maia-input-select />` instead.
 *
 * Example usage:
 *
 * ```html
 * <maia-select [(value)]="myValue">
 *   <maia-option *ngFor="let option of options" [value]="option">
 *     {{ option | atlasText }}
 *   </maia-option>
 * </maia-select>
 * ```
 *
 * This component follows the WAI ARIA guidelines for a collapsible listbox, it is fully accessible
 * using keyboard and screenreader.
 *
 * @see [WAI ARIA collapsible listbox](https://www.w3.org/TR/wai-aria-practices-1.1/examples/listbox/listbox-collapsible.html)
 * @ngModule SelectModule
 */
@Component({
  selector: 'maia-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    tabindex: '0',
    role: 'button',
    'aria-haspopup': 'listbox',
    '[attr.aria-expanded]': 'isOpen ? true : null',
    '[attr.aria-disabled]': 'disabled ? true : null',
  },
  providers: [{provide: OptionContainer, useExisting: forwardRef(() => SelectComponent)}],
})
export class SelectComponent<T> implements AfterContentInit, AfterViewInit, OptionContainer<T> {
  public keyEvent$ = new Subject<void>();

  private _disabled = false;

  @ViewChild('dropdown', {static: true})
  public dropdown: SelectDropdownComponent<Option<T>>;

  public isOpen = false;

  @ViewChild('dropdownContent', {static: true})
  public dropdownContent: TemplateRef<DropdownTemplateContext<Option<T>>>;

  @ContentChildren(forwardRef(() => Option))
  public options: QueryList<Option<T>>;

  public keyManager: SelectListKeyManager<Option<T>> = undefined!;

  private _value?: T | null = undefined;

  @Output()
  public valueChange = new EventEmitter<T | undefined>();

  private readonly _opener: SelectOpener;

  public constructor(
    private readonly _elementRef: ElementRef,
    @Optional() @Self() opener?: SelectOpener,
  ) {
    // Set default here instead of using default function argument because angular injects `null`
    // for unavailable optional injectables, but default arguments are only used when the value is
    // `undefined`.
    this._opener = opener || OPEN_AS_DROPDOWN;
  }

  /**
   * Whether the select is disabled or not
   */
  @Input()
  @HostBinding('class.maia-select--disabled')
  public get disabled(): boolean {
    return this._disabled;
  }

  public set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
  }

  /**
   * The value of the select
   */
  @Input()
  public set value(value: T | null | undefined) {
    this._value = value;

    if (this.isOpen) {
      this._updateOptionsSelectedState();
    }
  }

  public get value(): T | null | undefined {
    return this._value;
  }

  private _updateOptionsSelectedState(): void {
    // istanbul ignore if Hard to test as keyManager is set in afterViewInit
    if (this.keyManager == null) {
      return;
    }
    const option = this.options.find(o => o.value === this._value);
    this.options.forEach(o => (o.selected = o === option));

    if (option != null) {
      this.keyManager.setActiveItem(option);
    } else {
      this.keyManager.setActiveItem(-1);
    }
  }

  @HostListener('click', ['$event'])
  @HostListener('keydown.space', ['$event'])
  @HostListener('keydown.enter', ['$event'])
  public open(event: Event): void {
    event.preventDefault();
    if (this.disabled) {
      return;
    }

    this._updateOptionsSelectedState();

    this._opener.open(this.dropdown, this.dropdownContent).subscribe(result => {
      if (result.resolution === ModalResolution.CONFIRMED) {
        const value = result.result != null ? result.result.value : undefined;

        this._value = value;
        this.valueChange.emit(value);
      }

      if (result.resolution !== ModalResolution.DISMISSED) {
        (this._elementRef.nativeElement as HTMLElement).focus();
      }
    });
  }

  /* OptionContainer API */
  public activateOption(option: Option<T>, focusOrigin?: FocusOrigin): void {
    // istanbul ignore else Hard to test because keyManager is set in afterViewInit
    if (this.keyManager != null) {
      this.keyManager.setActiveItem(option, focusOrigin);
    }
  }

  public ngAfterViewInit(): void {
    this.keyManager = new SelectListKeyManager(this.options).withWrap();
  }

  public ngAfterContentInit(): void {
    Promise.resolve().then(() => {
      // re-assign to propagate any value that has been set
      this.value = this.value;
    });
  }
}
