import {Pipe, PipeTransform} from '@angular/core';
import {CardNumber, Text, CardNumberFormatter} from '@atlas/businesstypes';

/**
 * @ngModule BusinessTypesModule
 */
@Pipe({name: 'atlasCardNumber'})
export class CardNumberPipe implements PipeTransform {
  public transform(value: any): string {
    return CardNumber.isCardNumber(value) || Text.isText(value)
      ? CardNumberFormatter.format(value)
      : value;
  }
}
