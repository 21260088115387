import {
  ComponentFactory,
  Directive,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  StaticProvider,
  TemplateRef,
} from '@angular/core';
import {ModalContentComponent, ModalResult} from '@maia/modals';
import {Observable} from 'rxjs';

import {AbstractDropdownComponent} from '../shared/dropdown.component';
import {DropdownMargin, DropdownOptions} from '../shared/dropdown.interfaces';

import {DropdownHost} from './host';
import {DropdownHostFactory} from './host-factory.service';

/**
 * The `[maiaDropdownHost]` directive declares an element as a reference point for dropdowns. This
 * directive exposes one method to open dropdowns attached to this element.
 *
 * @ngModule DropdownsModule
 */
@Directive({
  selector: '[maiaDropdownHost]',
  exportAs: 'maiaDropdownHost',
  providers: [{provide: DropdownHost, useExisting: forwardRef(() => DropdownHostDirective)}],
})
export class DropdownHostDirective implements DropdownHost {
  private readonly _host: DropdownHost;

  public constructor(factory: DropdownHostFactory, element: ElementRef, injector: Injector) {
    this._host = factory.createHost(element, injector);
  }

  /**
   * The margin to use. If no margins are supplied, the default margins of the dropdown element
   * will be used.
   *
   * If the margin is a number, it will be used for both `top` and `bottom`.
   */
  @Input('maiaDropdownHostMargin')
  public set margin(margin: DropdownMargin | number | undefined) {
    this._host.margin = margin;
  }

  public prepare<T>(
    componentFactory: ComponentFactory<ModalContentComponent<T>>,
    options?: DropdownOptions,
    providers?: StaticProvider[] | undefined,
  ): Observable<ModalResult<T>> {
    return this._host.prepare(componentFactory, options, providers);
  }

  public prepareTemplate<T>(
    templateRef: TemplateRef<any>,
    options?: DropdownOptions,
  ): Observable<ModalResult<T>> {
    return this._host.prepareTemplate(templateRef, options);
  }

  public prepareCustom<T, C extends AbstractDropdownComponent>(
    dropdownFactory: ComponentFactory<C>,
    componentFactory: ComponentFactory<ModalContentComponent<T>>,
    options?: DropdownOptions,
    providers?: StaticProvider[] | undefined,
  ): Observable<ModalResult<T>> {
    return this._host.prepareCustom(dropdownFactory, componentFactory, options, providers);
  }

  public prepareTemplateCustom<T, C extends AbstractDropdownComponent>(
    dropdownFactory: ComponentFactory<C>,
    templateRef: TemplateRef<any>,
    options?: DropdownOptions,
    providers?: StaticProvider[] | undefined,
  ): Observable<ModalResult<T>> {
    return this._host.prepareTemplateCustom(dropdownFactory, templateRef, options, providers);
  }
}
