import {NgModule} from '@angular/core';

import {
  CellAlignDirective,
  CellAlignLgDirective,
  CellAlignMdDirective,
  CellAlignSmDirective,
  CellAlignXlDirective,
} from './flex-grid-cell/flex-grid-cell.align.directive';
import {FlexGridCellComponent} from './flex-grid-cell/flex-grid-cell.component';
import {
  CellOffsetDirective,
  CellOffsetLgDirective,
  CellOffsetMdDirective,
  CellOffsetSmDirective,
  CellOffsetXlDirective,
} from './flex-grid-cell/flex-grid-cell.offset.directive';
import {
  CellSizeDirective,
  CellSizeLgDirective,
  CellSizeMdDirective,
  CellSizeSmDirective,
  CellSizeXlDirective,
} from './flex-grid-cell/flex-grid-cell.size.directive';
import {
  RowAlignDirective,
  RowAlignLgDirective,
  RowAlignMdDirective,
  RowAlignSmDirective,
  RowAlignXlDirective,
} from './flex-grid-row/flex-grid-row.align.directive';
import {FlexGridRowComponent} from './flex-grid-row/flex-grid-row.component';
import {FlexGridComponent} from './flex-grid/flex-grid.component';

/**
 * @deprecated use maia-fluid-fluid-layout, maia-fixed-fluid-layout, maia-fluid-fixed-layout or flexbox instead
 */
@NgModule({
  declarations: [
    FlexGridComponent,
    FlexGridCellComponent,
    FlexGridRowComponent,
    CellAlignDirective,
    CellAlignLgDirective,
    CellAlignMdDirective,
    CellAlignSmDirective,
    CellAlignXlDirective,
    CellOffsetDirective,
    CellOffsetLgDirective,
    CellOffsetMdDirective,
    CellOffsetSmDirective,
    CellOffsetXlDirective,
    CellSizeDirective,
    CellSizeLgDirective,
    CellSizeMdDirective,
    CellSizeSmDirective,
    CellSizeXlDirective,
    RowAlignDirective,
    RowAlignLgDirective,
    RowAlignMdDirective,
    RowAlignSmDirective,
    RowAlignXlDirective,
  ],
  exports: [
    FlexGridComponent,
    FlexGridCellComponent,
    FlexGridRowComponent,
    CellAlignDirective,
    CellAlignLgDirective,
    CellAlignMdDirective,
    CellAlignSmDirective,
    CellAlignXlDirective,
    CellOffsetDirective,
    CellOffsetLgDirective,
    CellOffsetMdDirective,
    CellOffsetSmDirective,
    CellOffsetXlDirective,
    CellSizeDirective,
    CellSizeLgDirective,
    CellSizeMdDirective,
    CellSizeSmDirective,
    CellSizeXlDirective,
    RowAlignDirective,
    RowAlignLgDirective,
    RowAlignMdDirective,
    RowAlignSmDirective,
    RowAlignXlDirective,
  ],
})
export class FlexGridModule {}
