import {Directive} from '@angular/core';
import {AbstractControl, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';

export type AtlasValidatorFn = (value: any) => ValidationErrors | null;

export function createValidatorFn(validator: AtlasValidatorFn): ValidatorFn {
  return function (control?: AbstractControl): ValidationErrors | null {
    // allow undefined/null values (optional fields)
    if (control == null || control.value == null) {
      return null;
    } else {
      return validator(control.value);
    }
  };
}

@Directive()
export abstract class AbstractValidatorDirective implements Validator {
  protected abstract readonly validatorFn: AtlasValidatorFn;

  public validate(control: AbstractControl): ValidationErrors | null {
    // allow undefined/null values (optional fields)
    if (control.value == null) {
      return null;
    } else {
      return this.validatorFn(control.value);
    }
  }
}

export declare interface ParameterizedValidatorDirective<T> extends Validator {
  setParameter(value: T): void;

  validate(control?: AbstractControl): ValidationErrors | null;
}

export type ParameterizedValidatorConstructor<T> = new () => ParameterizedValidatorDirective<T>;

export function createParameterizedValidatorDirective<T>(
  validatorFactory: (_: T) => AtlasValidatorFn,
): ParameterizedValidatorConstructor<T> {
  return class AbstractParameterizedValidatorDirective
    implements ParameterizedValidatorDirective<T> {
    private validator?: AtlasValidatorFn;

    private onValidatorChange?: () => void;

    public setParameter(newValue: T): void {
      this.validator = validatorFactory(newValue);

      this.onValidatorChange?.();
    }

    public validate(control?: AbstractControl): ValidationErrors | null {
      // allow undefined/null values (optional fields)
      if (control == null || control.value == null || this.validator == null) {
        return null;
      } else {
        return this.validator(control.value);
      }
    }

    /**
     * This method is only intended to be used by Angular.
     */
    public registerOnValidatorChange(fn: () => void): void {
      this.onValidatorChange = fn;
    }
  };
}
