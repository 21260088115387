import {Pipe, PipeTransform} from '@angular/core';
import {EmailAddress, EmailAddressFormatter} from '@atlas/businesstypes';

/**
 * @ngModule BusinessTypesModule
 */
@Pipe({name: 'atlasEmailAddress'})
export class EmailAddressPipe implements PipeTransform {
  public transform(value: any): string {
    return EmailAddress.isEmailAddress(value) ? EmailAddressFormatter.format(value) : value;
  }
}
