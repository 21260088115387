import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionFuncFilterCircleSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaActionFuncFilterCircle]',
  template: '<svg:g class="nc-icon-wrapper" transform="matrix(.8 0 0 .8 2.4 2.4)"><svg:circle class="cls-1" cx="12" cy="12" r="11.02" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"></svg:circle><svg:path class="cls-2" d="M13.96 8.94h4.16" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path class="cls-2" d="M5.88 8.94h1.75" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path class="cls-2" d="M17.07 15.06h1.05" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path class="cls-2" d="M5.88 15.06h5.3" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:circle class="cls-2" cx="9.66" cy="8.94" r="2.03" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:circle><svg:circle class="cls-2" cx="13.21" cy="15.06" r="2.03" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:circle></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionFuncFilterCircleSvgComponent {
}

@NgModule({
  declarations: [
    ActionFuncFilterCircleSvgComponent,
  ],
  exports: [
    ActionFuncFilterCircleSvgComponent,
  ],
})
export class ActionFuncFilterCircleSvgModule {}
