import {ModuleWithProviders, NgModule} from '@angular/core';
import {provideKeepsake} from 'keepsake';

import {DeviceStorageAccessorFactory} from './device-storage-accessor';
import {StorageConfiguration} from './storage.configuration';
import {UserStorageService} from './user-storage.service';

@NgModule()
export class StorageModule {
  // This module should be loaded in a browser context only.
  // If your app will also run in a cordova environment, please use the StorageModule.forCordova()
  // which can be found in `@hermes/storage-cordova`.
  public static forWeb(
    config: StorageConfiguration = {prefix: 'touch'},
  ): ModuleWithProviders<StorageModule> {
    return {
      ngModule: StorageModule,
      providers: [
        provideKeepsake(['dom', 'memory']),
        {provide: StorageConfiguration, useValue: config},
        UserStorageService,
        DeviceStorageAccessorFactory,
      ],
    };
  }
}
