import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Subject} from 'rxjs';

import {InputContainer} from '../input-container/input-container.interface';
import {MultipleInputContainer} from '../input-container/multiple-input-container.service';
import {SingleInputContainer} from '../input-container/single-input-container.service';
import {
  VALIDATION_NONE,
  ValidationContainerComponent,
} from '../validation-container/validation-container.component';
import {ValidationComponent, ValidationType} from '../validation/validation.component';

import {LabelContainerService} from './label-container.service';
import {ValidationMessageExtractor} from './validation-message-extractor.service';

/**
 * The form element label type:
 * - 'simple': use the label attribute value
 * - 'element': use the maia-label child as label
 * - 'none': No label used
 */
export type FormElementLabelType = 'simple' | 'element' | 'none';

/**
 * Options for the form element.
 */
export interface FormElementOptions {
  /**
   * Whether or not to align the label to the left.
   * Defaults to false.
   */
  alignLabelLeft?: boolean;

  /**
   * The label size.
   * Defaults to 'normal'
   *
   * @deprecated Doesn't do anything anymore
   */
  labelSize?: string;

  /**
   * Whether to show the validation instant or wait until blur.
   * Defaults to false.
   */
  validationInstant?: boolean;
}

/**
 * @ngModule FormsModule
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'maia-form-element:not([multi]), maia-text-area-form-element-wide:not([multi])',
  providers: [{provide: InputContainer, useClass: SingleInputContainer}],
})
export class FormElementNotMultiDirective {}

/**
 * @ngModule FormsModule
 */
@Directive({
  selector: 'maia-form-element[multi], maia-text-area-form-element-wide[multi]',
  providers: [
    MultipleInputContainer,
    {provide: InputContainer, useExisting: MultipleInputContainer},
  ],

  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.maia-form-element--multi]': 'true',
  },
})
export class FormElementMultiDirective {}

/**
 * Factory function for LabelContainerService, exported because angular requires it to be in order
 * to use it in `Component#providers`
 */
export function labelContainerServiceFactory(
  formElement: FormElementComponent,
  renderer: Renderer2,
): LabelContainerService {
  const getLabel = () => formElement.labelElement;
  return new LabelContainerService(getLabel, renderer);
}

/**
 * A form element. Form elements contain a label, _one_ input (or input-like component) and the
 * validation of that input. Form elements can contain multiple subelements which each contain
 * their own input and validation.
 *
 * @ngModule FormsModule
 */
@Component({
  selector: 'maia-form-element',
  templateUrl: './form-element.component.html',
  styleUrls: ['./form-element.component.scss'],

  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.maia-form-element--label-aligned-left]': 'options.alignLabelLeft || false',
  },

  providers: [
    ValidationMessageExtractor,
    {
      provide: LabelContainerService,
      useFactory: labelContainerServiceFactory,
      deps: [forwardRef(() => FormElementComponent), Renderer2],
    },
  ],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class FormElementComponent implements OnInit, AfterViewInit {
  @ViewChild(forwardRef(() => ValidationContainerComponent), {static: true})
  public _validationContainerComponent: ValidationContainerComponent;

  @ContentChildren(forwardRef(() => ValidationComponent))
  public _validationComponents!: QueryList<ValidationComponent>;

  @ViewChild('labelElement', {static: true, read: ElementRef})
  public labelElement: ElementRef<HTMLLabelElement>;

  /**
   * The label of the form element. This label is only shown if no `<maia-label />` is present
   * inside this form element.
   */
  @Input()
  public label?: string;

  private _disabled = false;

  private _focused = false;

  private _optional = true;

  private _shouldShowOptional = this._optional;

  private _labelType: FormElementLabelType = 'simple';

  private _options: FormElementOptions = {};

  private _validationType: ValidationType | typeof VALIDATION_NONE = VALIDATION_NONE;

  private readonly _onRegisteredValidationsChange: Subject<ValidationType | typeof VALIDATION_NONE>;

  public showValidation = false;

  /**
   * Switches, radio buttons, checkboxes and the input stepper do not have a height of
   * 44px causing the labels not to be correctly aligned with the input next to it.
   * This property adds an extra 10px to the top and bottom of the input container.
   */
  @HostBinding('class.p-maia-form-element--extra-padding')
  public extraPadding = false;

  /**
   * Indicate whether or not the form sub-elements should be displayed horizontally (row) or vertically (column).
   * Default: false (=horizontal).
   *
   * This is only relevant for a multi-input form element.
   */
  @coerceBooleanPrimitive()
  @Input()
  public vertical = false;

  /**
   * Optionally display a label stating whether the element is disabled. Default: false.
   */
  @coerceBooleanPrimitive()
  @Input()
  public showDisabledLabel = false;

  /**
   * Whether the "optional" indicator should be hidden in case the field is not mandatory.
   * Default: false.
   *
   * This input is aimed to be used for those fields where the "optional" indicator is not
   * applicable.
   * This is also the case for some maia components such as switches, checkboxes and input steppers
   */
  @coerceBooleanPrimitive()
  @Input()
  public hideOptionalIndicator = false;

  public constructor(
    private readonly _inputContainer: InputContainer,
    private readonly _changeDetection: ChangeDetectorRef,
  ) {
    this._onRegisteredValidationsChange = new Subject<ValidationType | typeof VALIDATION_NONE>();
  }

  /**
   * The form element options.
   */
  @Input()
  public get options(): FormElementOptions {
    return this._options;
  }

  public set options(options: FormElementOptions) {
    this._options = options || {};
  }

  public get labelType(): FormElementLabelType {
    if (this._labelType !== 'simple') {
      return this._labelType;
    }
    return this.label == null || !this.label.length ? 'none' : 'simple';
  }

  public get shouldShowDisabledLabel(): boolean {
    return this.showDisabledLabel && this.disabled;
  }

  public get shouldShowOptionalLabel(): boolean {
    const hasValidationComponents = this._validationComponents
      ? this._validationComponents.length > 0
      : false;
    const isShowingValidationErrors = hasValidationComponents || this._inputContainer.hasError();
    // trigger change detection cycle optionally (only if previous and new value differ)
    // to prevent ExpressionChangedAfterItHasBeenCheckedError
    const shouldTriggerChangeDetection = this._shouldShowOptional !== this._optional;

    // The "optional" indicator should be shown as long as there are no errors currently shown.
    // Sometimes the "showValidation" is true but the field has no errors (when the user is editing
    // the field to make it valid: the field is changing to a valid state immediately!)
    if (
      (!this.showValidation || !isShowingValidationErrors) &&
      !this.shouldShowDisabledLabel &&
      !this.hideOptionalIndicator
    ) {
      this._shouldShowOptional = this._optional;

      if (shouldTriggerChangeDetection) {
        this._changeDetection.detectChanges();
      }
      return this._shouldShowOptional;
    }

    this._shouldShowOptional = false;
    return this._shouldShowOptional;
  }

  @HostBinding('class.maia-form-element--disabled')
  public get disabled(): boolean {
    return this._disabled;
  }

  @HostBinding('class.p-maia-form-element--focused')
  public get focused(): boolean {
    return this._focused;
  }

  @HostBinding('class.p-maia-form-element--validation-error')
  public get validationIsError(): boolean {
    return this._validationType === 'error';
  }

  @HostBinding('class.p-maia-form-element--validation-warning')
  public get validationIsWarning(): boolean {
    return this._validationType === 'warning';
  }

  public ngOnInit(): void {
    this._inputContainer.disabled$.pipe(takeUntilDestroyed(this)).subscribe(disabled => {
      this._disabled = disabled;
      this._changeDetection.markForCheck();
    });

    this._inputContainer.optional$.pipe(takeUntilDestroyed(this)).subscribe(isOptional => {
      this._optional = isOptional;
      this._changeDetection.markForCheck();
    });

    this._inputContainer.focused$.pipe(takeUntilDestroyed(this)).subscribe(focused => {
      this._focused = focused;

      if (!this._focused) {
        this._updateValidationType(true);
      }

      this._changeDetection.markForCheck();
    });

    this._onRegisteredValidationsChange
      .pipe(takeUntilDestroyed(this))
      .subscribe((validationType: ValidationType | typeof VALIDATION_NONE): void => {
        const shouldShowValidation = this.options.validationInstant || !this._focused;
        const hasValidation = validationType !== VALIDATION_NONE;
        if (shouldShowValidation || hasValidation) {
          this.showValidation = shouldShowValidation;
          this._changeDetection.detectChanges();
        }
      });
  }

  public ngAfterViewInit(): void {
    this._onRegisteredValidationsChange
      .pipe(takeUntilDestroyed(this))
      .subscribe((validationType: ValidationType | typeof VALIDATION_NONE): void => {
        this._validationType = validationType;
      });

    this._validationComponents.changes
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => this._updateValidationType(false));

    /**
     * We need to wait until promise is resolved because triggering detectChanges on the
     * changeDetectionRef at this point in the lifecycle would create a infinite loop.
     */
    void Promise.resolve().then(() => this._updateValidationType(true));
  }

  /**
   * The HTML spec tells browsers that it has to propagate clicks on a <label> to the first
   * control inside the label. However, there's a browser that misbehaves: Mobile Safari.
   *
   * So we have to re-implement this browser behaviour, and stop the default browser behaviour
   * if the click targets a control.
   *
   * @see https://html.spec.whatwg.org/multipage/forms.html#the-label-element
   */
  public stopClicksOnControls(event: MouseEvent): void {
    if ((event.target as HTMLElement).closest('a, button, input, textarea')) {
      event.preventDefault();
    }
  }

  /**
   * Notifies the form-element that a `<maia-label />` child is present. Calling this method sets
   * the `labelType` to `'element'`.
   * A form element can only have one `<maia-label />` as child.
   *
   * @return A function that unregisters the custom label, resetting the `labelType`.
   */
  public registerCustomLabel(): () => void {
    if (this._labelType !== 'simple') {
      throw new Error('A maia-form-element cannot contain more than one maia-label');
    }

    this._labelType = 'element';
    let reset = false;

    return () => {
      if (reset) {
        return;
      }

      reset = true;
      this._labelType = 'simple';
    };
  }

  /**
   * We need this listener for updating correctly when we are going from having validation to not
   * having one
   */
  public onRegisteredValidationsChange(validationType: ValidationType): void {
    this._onRegisteredValidationsChange.next(validationType);
  }

  private _updateValidationType(detectChanges = false): void {
    this._validationType = VALIDATION_NONE;

    if (this._validationComponents && this._validationComponents.length) {
      this._validationType = this._validationComponents.first.type;
    } else if (this._validationContainerComponent && this._validationContainerComponent.hasError) {
      this._validationType = this._validationContainerComponent.validationType;
    }

    if (detectChanges) {
      this.showValidation =
        this.options.validationInstant ||
        (!this._focused && this._validationType !== VALIDATION_NONE);
    }

    // a change detection check should be scheduled in any case so that the "optional" indicator is
    // kept in sync and displayed when necessary
    this._changeDetection.markForCheck();
  }
}
