/*
 * This file contains the basic html validators
 * to check whether an html is actually a valid html.
 */

import {
  RESULT_VALID,
  ValidationErrors,
  ValidatorInput,
  ValidValidationResult,
} from '../base/validators';

import {Html} from './businesstype';

export interface InvalidBaseHtmlValidationResult extends ValidationErrors {
  /**
   * If this property is set, the html is incorrect.
   */
  atlasInvalidHtml?: boolean;
}

export type BaseHtmlValidationResult = InvalidBaseHtmlValidationResult | ValidValidationResult;

/**
 * Checks whether the html input is valid or not.
 *
 * @param value The input to validate
 */
export function isValidHtml(value: any): boolean {
  return typeof value === 'string';
}

/**
 * The BaseHtmlValidator object contains partial html validators, returning
 * information on the invalidity instead of simply returning a boolean
 * valid/invalid result.
 *
 * @deprecated Doesn't do anything
 */
export const BaseHtmlValidator = {
  /**
   * Checks whether the input is valid html
   *
   * @param value The html to validate
   */
  invalidHtml(value: ValidatorInput<Html>): BaseHtmlValidationResult {
    if (Html.isHtml(value)) {
      return RESULT_VALID;
    }

    return {atlasInvalidHtml: true};
  },
} as const;
