import {
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Subscription} from 'rxjs';

import {AuthorisationService} from './authorisation.service';

@Directive({selector: '[hermesHasAccess]'})
@UntilDestroy()
export class AuthorisationDirective implements OnChanges {
  @Input('hermesHasAccessThen')
  public thenTemplate: TemplateRef<{}>;

  @Input('hermesHasAccessElse')
  public elseTemplate: TemplateRef<{}>;

  private subscription = Subscription.EMPTY;

  private authorised = false;

  public constructor(
    private readonly viewContainer: ViewContainerRef,
    template: TemplateRef<{}>,
    private readonly authorisationService: AuthorisationService,
  ) {
    this.thenTemplate = template;
  }

  @Input('hermesHasAccess')
  public set functionCode(functionCode: string) {
    this.subscription.unsubscribe();
    this.authorised = false;

    this.subscription = this.authorisationService
      .isUserAuthorised(functionCode)
      .pipe(takeUntilDestroyed(this))
      .subscribe(authorised => {
        this.authorised = authorised;
        this.updateView();
      });
  }

  private updateView() {
    this.viewContainer.clear();
    if (this.authorised) {
      if (this.thenTemplate != null) {
        this.viewContainer.createEmbeddedView(this.thenTemplate);
      }
    } else if (this.elseTemplate != null) {
      this.viewContainer.createEmbeddedView(this.elseTemplate);
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (
      (this.authorised && changes.thenTemplate != null) ||
      (!this.authorised && changes.elseTemplate != null)
    ) {
      this.updateView();
    }
  }
}
