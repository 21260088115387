import {Directive, forwardRef} from '@angular/core';
import {NG_VALIDATORS} from '@angular/forms';
import {validateBoolean} from '@atlas/businesstypes';

import {AbstractValidatorDirective} from '../base/validator';

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasBoolean]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BooleanValidatorDirective),
      multi: true,
    },
  ],
})
export class BooleanValidatorDirective extends AbstractValidatorDirective {
  protected readonly validatorFn = validateBoolean;
}
