import {MonoTypeOperatorFunction, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

/**
 * Operator that throws if the source observable has more than `limit` items
 *
 * @param limit The maximum number of items to allow
 * @param errorFactory A factory function called to produce the error to be thrown when the source
 * observable emits more than `limit` values.
 */
export function limitNumberOfValues<T>(
  limit: number,
  errorFactory: () => any,
): MonoTypeOperatorFunction<T> {
  return source =>
    new Observable<T>(observer => {
      let count = 0;

      return source
        .pipe(
          tap<T>({
            next() {
              count++;
              if (count > limit) {
                throw errorFactory();
              }
            },
          }),
        )
        .subscribe(observer);
    });
}
