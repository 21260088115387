import {ElementRef} from '@angular/core';

// TODO(MAIA-214) Replace this with the version from @maia/core

/**
 * Calling this method with an ElementRef triggers a synchronous layout.
 *
 * > __DANGER! DANGER! This is a performance bottleneck!__
 *
 * Calling this method will halt javascript execution until the layout is complete.
 * Only use this when setting up animations in response to user interaction.
 *
 * @param element The element to trigger a synchronous layout on.
 */
export function triggerSynchronousLayout(element: ElementRef<HTMLElement>): void {
  if (element.nativeElement != null) {
    // We ignore the result, as our intended result is the side effect of the function call.
    element.nativeElement.getBoundingClientRect();
  }
}
