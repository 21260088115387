import {Directive, forwardRef} from '@angular/core';

import {BifrostHeaderVariant} from './header-variant';

@Directive({
  selector: 'bifrost-header[oscarPublic], header[oscarPublic]',
  providers: [
    {
      provide: BifrostHeaderVariant,
      useExisting: forwardRef(() => HeaderVariantOscarPublicDirective),
    },
  ],
})
export class HeaderVariantOscarPublicDirective implements BifrostHeaderVariant {
  public isPublic = true;
  public isCollapsible = false;
  public allowBackButton = false;
  public hasBackground = false;
}
