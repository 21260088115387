import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {AvatarImageComponent} from './avatar-image/avatar-image.component';
import {AvatarStringComponent} from './avatar-string/avatar-string.component';
import {AvatarSvgComponent} from './avatar-svg/avatar-svg.component';

@NgModule({
  declarations: [AvatarSvgComponent, AvatarImageComponent, AvatarStringComponent],
  exports: [AvatarSvgComponent, AvatarImageComponent, AvatarStringComponent],
  imports: [CommonModule],
})
export class AvatarsModule {}
