import {NgModule} from '@angular/core';

import {ComparisonPanelComponent} from './comparison-panel/comparison-panel.component';
import {ComparisonPanelGroupComponent} from './comparison-panel-group/comparison-panel-group.component';

@NgModule({
  declarations: [ComparisonPanelComponent, ComparisonPanelGroupComponent],
  exports: [ComparisonPanelComponent, ComparisonPanelGroupComponent],
})
export class ComparisonPanelModule {}
