import {CommonModule as AngularCommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule as AngularFormsModule} from '@angular/forms';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';
import {CollapsiblesModule} from '@maia/collapsibles';
import {FormsModule} from '@maia/forms';

import {CollapsibleRadioButtonComponent} from './collapsible-radio-button/collapsible-radio-button.component';
import {CollapsibleRadioGroupFormElementComponent} from './collapsible-radio-group-form-element/collapsible-radio-group-form-element.component';
import {GroupWithNgControlDirective} from './group.directive';
import {RadioButtonSublabelComponent} from './radio-button-sublabel/radio-button-sublabel.component';
import {RadioButtonComponent} from './radio-button/radio-button.component';
import {RadioGroupComponent} from './radio-group/radio-group.component';
import {ToggleButtonComponent} from './toggle-button/toggle-button.component';
import {ToggleGroupComponent} from './toggle-group/toggle-group.component';

@NgModule({
  imports: [
    AngularFormsModule,
    AngularCommonModule,
    BusinessTypesModule,
    CollapsiblesModule,
    FormsModule,
  ],
  declarations: [
    ToggleButtonComponent,
    RadioButtonComponent,
    RadioButtonSublabelComponent,
    RadioGroupComponent,
    ToggleGroupComponent,
    GroupWithNgControlDirective,
    CollapsibleRadioButtonComponent,
    CollapsibleRadioGroupFormElementComponent,
  ],
  exports: [
    ToggleButtonComponent,
    RadioButtonComponent,
    RadioButtonSublabelComponent,
    RadioGroupComponent,
    ToggleGroupComponent,
    GroupWithNgControlDirective,
    CollapsibleRadioButtonComponent,
    CollapsibleRadioGroupFormElementComponent,
  ],
})
export class RadioButtonsModule {}
