import {Injectable, isDevMode} from '@angular/core';
import {DebugLogger, Logger, ProductionLogger} from '@atlas/logger';
import {WindowRef} from '@atlas-angular/cdk/globals';

import {LoggerEventsServiceImplementation} from './logger-events.service';

interface LoggerConstructor {
  new (
    name: string,
    loggerEventsService: LoggerEventsServiceImplementation,
    window: Window,
  ): Logger;
}

/**
 * A LoggerFactory creates loggers.
 */
@Injectable({providedIn: 'root'})
export class LoggerFactory {
  private readonly LoggerImpl: LoggerConstructor;

  public constructor(
    private readonly loggerEventsService: LoggerEventsServiceImplementation,
    private readonly window: WindowRef,
  ) {
    // istanbul ignore next: this is impossible to test
    this.LoggerImpl = isDevMode() ? DebugLogger : ProductionLogger;
  }

  /**
   * Creates a new logger with the given name.
   *
   * @param name The name for the new logger
   */
  public createLogger(name: string): Logger {
    return new this.LoggerImpl(name, this.loggerEventsService, this.window.window);
  }
}
