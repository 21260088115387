import {FocusOrigin} from '@angular/cdk/a11y';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Directive,
  OnDestroy,
  QueryList,
  Renderer2,
  TemplateRef,
} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {DropdownHost, DropdownOptions, DropdownTemplateContext} from '@maia/dropdowns';
import {CapturedInput} from '@maia/forms/capture';
import {ModalResolution} from '@maia/modals';
import {Option, OptionContainer, SelectListKeyManager} from '@maia/select';

import {Subject, Subscription} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';

import {OPEN_AS_DROPDOWN, μInputSelectOpener} from './input-select-opener.directive';

const ATTR_ARIA_EXPANDED = 'aria-expanded';

/**
 * Select component to be used inside forms
 *
 * If you want to use a select outside of forms, look at `<maia-select>` (@maia/select) instead.
 *
 * This component expects an input inside, beit a real `<input>` or a `<maia-fake-input>`. All input
 * handling is handled through the input element. This element should never be focused directly.
 *
 * The value of the input element will not be set automatically, the consumer of this component is
 * responsible for binding that value. __Note__ that setting the value of an `<input>` element to
 * `undefined` will show "undefined" in the input, you must set the value to `null` to clear it.
 *
 * Example usage:
 *
 * ```html
 * <maia-input-select
 *     name="value" [(ngModel)]="value"
 *     [disabled]="disabled | atlasBoolean">
 *   <input maiaInput
 *     placeholder="Select value"
 *     [value]="(value | atlasText) || null">
 *
 *   <!-- options is a list of Text instances -->
 *   <maia-option *ngFor="let option of options" [value]="option">
 *     Option {{ option | atlasText }}
 *   </maia-option>
 * </maia-input-select>
 * ```
 *
 * This component follows the WAI ARIA guidelines for a collapsible listbox, it is fully accessible
 * using keyboard and screenreader.
 *
 * @see [WAI ARIA collapsible listbox](https://www.w3.org/TR/wai-aria-practices-1.1/examples/listbox/listbox-collapsible.html)
 */
@Directive()
@UntilDestroy()
export abstract class BaseInputSelectComponent<T>
  implements OptionContainer<T>, ControlValueAccessor, AfterContentInit, OnDestroy {
  public abstract dropdownHost: DropdownHost;

  public abstract dropdownContent: TemplateRef<DropdownTemplateContext<Option<T>>>;

  public abstract options: QueryList<Option<T>>;

  private readonly willClose = new Subject<void>();

  public readonly afterClose = new Subject<ModalResolution>();

  private _isOpen = false;

  private _disabled = false;

  public keyManager: SelectListKeyManager<Option<T>> = undefined!;

  /**
   * The actual value of the input
   */
  protected abstract value?: T | null;

  /**
   * A subscription used to manage listeners to the captured input element
   *
   * This subscription gets unsubscribed at the time of this component's destruction.
   */
  protected _inputSubscription = Subscription.EMPTY;

  // Defined by OptionContainer<T>
  public keyEvent$ = new Subject<void>();

  /**
   * The captured input
   *
   * Accessing this property when no input has been captured will yield an error
   */
  public abstract capturedInput: CapturedInput;

  private readonly _opener: μInputSelectOpener;

  private _onChange = (value?: T) => {};

  private _onTouch = () => {};

  public constructor(
    protected readonly _cdr: ChangeDetectorRef,
    protected readonly _renderer: Renderer2,
    opener?: μInputSelectOpener,
  ) {
    // Set default here instead of using default function argument because angular injects `null`
    // for unavailable optional injectables, but default arguments are only used when the value is
    // `undefined`.
    this._opener = opener || OPEN_AS_DROPDOWN;
  }

  /**
   * Whether the dropdown is open or not
   */
  public get isOpen(): boolean {
    return this._isOpen;
  }

  /**
   * Whether the input is disabled or not
   */
  public get isDisabled(): boolean {
    return this._disabled;
  }

  protected abstract _hasCapturedInput(): boolean;

  protected _assertHasCapturedInput(): void {
    if (!this._hasCapturedInput()) {
      throw new Error(`No input to capture in the <maia-input-select>`);
    }
  }

  public ngOnDestroy(): void {
    this.willClose.complete();
    this.afterClose.complete();
    this._inputSubscription.unsubscribe();
  }

  protected initKeyManager(): void {
    if (this.keyManager != null) {
      throw new Error(`KeyManager already initialised`);
    }

    this.keyManager = new SelectListKeyManager(this.options).withWrap();
  }

  public ngAfterContentInit(): void {
    this._assertHasCapturedInput();

    Promise.resolve().then(() => {
      // re-assign to propagate any value that has been set
      this.writeValue(this.value);
    });
  }

  private _updateOptionsSelectedState(): void {
    // istanbul ignore if Hard to test as keyManager is set in afterViewInit
    if (this.keyManager == null) {
      return;
    }

    const option = this.options.find(o => o.value === this.value);
    this.options.forEach(o => (o.selected = o === option));

    if (option != null) {
      this.keyManager.setActiveItem(option);
    } else {
      this.keyManager.setActiveItem(-1);
    }
  }

  /**
   * The base dropdown options to use when opening this dropdown
   */
  protected abstract get _dropdownOptions(): DropdownOptions;

  /**
   * Open the selection dropdown
   *
   * @param event The event that triggered the open
   */
  protected _open(): void {
    if (this._disabled || this._isOpen) {
      return;
    }

    this._updateOptionsSelectedState();

    this._isOpen = true;
    this._renderer.setAttribute(this.capturedInput.element, ATTR_ARIA_EXPANDED, 'true');

    this._opener
      .open(this.dropdownHost, this.dropdownContent, this._dropdownOptions)
      .pipe(
        takeUntil(this.willClose),
        takeUntilDestroyed(this),
        finalize(() => {
          this._renderer.removeAttribute(this.capturedInput.element, ATTR_ARIA_EXPANDED);
          this._isOpen = false;
          this._onTouch();
        }),
      )
      .subscribe(result => {
        this._cdr.markForCheck();

        if (result.resolution === ModalResolution.CONFIRMED) {
          this._selectOption(result.result);
        }

        if (result.resolution !== ModalResolution.DISMISSED) {
          this.capturedInput.element.focus();
        }

        this.afterClose.next(result.resolution);
      });

    this._cdr.detectChanges();
  }

  /**
   * Select the given value and propagates it through to the form control
   */
  protected _selectValue(value: T | undefined): void {
    this.value = value;
    this._onChange(value);
  }

  /**
   * Close the modal
   */
  protected _close(): void {
    // istanbul ignore if - only happens in input-autocomplete which doesn't count for our coverage
    if (!this._isOpen) {
      return;
    }

    this.willClose.next();
    this.afterClose.next(ModalResolution.DISMISSED);
  }

  /**
   * Mark the given option as selected
   *
   * Whenever the value changes through selecting an option in the component, this function is
   * called to set the new value.
   */
  protected _selectOption(option?: Option<T>) {
    this._selectValue(option != null ? option.value : undefined);
  }

  // OptionContainer API

  public activateOption(option: Option<T>, focusOrigin?: FocusOrigin): void {
    this.keyManager.setActiveItem(option, focusOrigin);
  }

  // ControlValueAccessor API

  public writeValue(value: any): void {
    this.value = value;

    if (this._isOpen) {
      this._updateOptionsSelectedState();
    }

    this._cdr.markForCheck();
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this._disabled = disabled;

    if (this._hasCapturedInput()) {
      this.capturedInput.setDisabledState(disabled);
    }
  }
}
