import {HttpRequest} from '@angular/common/http';
import {Injectable, Injector, Optional, SkipSelf} from '@angular/core';
import {Call, ConnectorResponseBase} from '@atlas/convertor';
import {Observable} from 'rxjs';

import {
  ConnectorBackend,
  ConnectorInterceptor,
  CONNECTOR_INTERCEPTORS,
} from '../interfaces/interceptor';
import {filterUnique} from '../utils/unique';

function wrapInterceptor<I, O>(
  interceptor: ConnectorInterceptor,
  call: Call<I, O>,
  input: I,
  backend: ConnectorBackend<O>,
): ConnectorBackend<O> {
  return {
    handle(request: HttpRequest<null>) {
      return interceptor.intercept(call, input, request, backend);
    },
  };
}

@Injectable()
export class CombinedConnectorInterceptor implements ConnectorInterceptor {
  private _interceptors?: ConnectorInterceptor[] = undefined;

  public constructor(
    private readonly _injector: Injector,
    @SkipSelf() @Optional() private readonly _parent?: CombinedConnectorInterceptor,
  ) {}

  private get interceptors(): ConnectorInterceptor[] {
    if (this._interceptors == null) {
      this._interceptors = filterUnique([
        ...this._injector.get(CONNECTOR_INTERCEPTORS, []),
        ...(this._parent ? this._parent.interceptors : []),
      ]);
    }
    return this._interceptors;
  }

  public intercept<I, O>(
    call: Call<I, O>,
    input: I,
    request: HttpRequest<null>,
    next: ConnectorBackend<O>,
  ): Observable<ConnectorResponseBase<O>> {
    return this.interceptors
      .reduceRight((intermediaryBackend, interceptor) => {
        return wrapInterceptor(interceptor, call, input, intermediaryBackend);
      }, next)
      .handle(request);
  }
}
