import {Directive, ViewContainerRef} from '@angular/core';

import {ModalViewContainer} from './modal-view-container.service';

/**
 * This directive marks the element that the ModalController uses to inject modals. Modals and their
 * backdrops are appended to this element.
 *
 * There must be one and only one instance of this directive. It should be placed as high as
 * possible in the app's module tree, e.g. at
 * the bottom of the app's template:
 *
 * ```html
 * <ng-container maiaModalContainer></ng-container>
 * ```
 *
 * @ngModule ModalsModule
 */
@Directive({
  selector: '[maiaModalContainer]',
})
export class ModalContainerDirective {
  public constructor(viewContainer: ViewContainerRef, modalViewContainer: ModalViewContainer) {
    modalViewContainer.viewContainer = viewContainer;
  }
}
