import {Directive, HostListener, OnInit, Optional} from '@angular/core';
import {NgControl} from '@angular/forms';
import {onDestroy, UntilDestroy} from '@atlas-angular/rxjs';
import {InputContainer, μOptionalSingleInputContainerProvider} from '@maia/forms';

/**
 * @ngModule SwitchesModule
 */
@Directive({
  selector: `
      maia-checkbox[ngModel], maia-checkbox[formControl], maia-checkbox[formControlName],
      maia-switch[ngModel], maia-switch[formControl], maia-switch[formControlName]
      `,
  providers: [μOptionalSingleInputContainerProvider],
})
@UntilDestroy()
export class ElementWithNgControlDirective implements OnInit {
  public constructor(
    private readonly _control: NgControl,
    @Optional() private readonly _container?: InputContainer,
  ) {}

  @HostListener('blur')
  public blur(): void {
    if (this._container != null) {
      this._container.focused = false;
    }
  }

  @HostListener('focus')
  public focus(): void {
    if (this._container != null) {
      this._container.focused = true;
    }
  }

  public ngOnInit() {
    if (this._container != null) {
      const destroyFn = this._container.registerFormControl(this._control);
      onDestroy(this).subscribe(destroyFn);
    }
  }
}
