import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {HeaderLayoutButtonComponent} from './header-layout-button/header-layout-button.component';
import {HeaderLayoutTitleComponent} from './header-layout-title/header-layout-title.component';
import {HeaderLayoutComponent} from './header-layout/header-layout.component';

@NgModule({
  imports: [CommonModule],
  declarations: [HeaderLayoutComponent, HeaderLayoutButtonComponent, HeaderLayoutTitleComponent],
  exports: [HeaderLayoutComponent, HeaderLayoutButtonComponent, HeaderLayoutTitleComponent],
})
export class HeaderLayoutModule {}
