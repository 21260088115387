import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

const DEFAULT_DIVIDER_VERTICAL = false;

/**
 * Divider
 *
 * @ngModule DividerModule
 */
@Component({
  selector: 'maia-divider',
  template: '',
  styleUrls: ['./divider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DividerComponent {
  private _vertical: boolean;

  @Input()
  @HostBinding('class.p-maia-divider--vertical')
  public get vertical(): boolean {
    return this._vertical;
  }

  public set vertical(vertical: boolean) {
    this._vertical = coerceBooleanProperty(vertical);
  }

  public constructor() {
    this.vertical = DEFAULT_DIVIDER_VERTICAL;
  }
}
