/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import {fromEvent} from 'rxjs';
import {mapTo, take} from 'rxjs/operators';

import {NotFoundError} from '../keepsake.errors';
import {Keepsake} from '../keepsake.interface';

type SqliteWindow = Window & {sqlitePlugin: any};

function isSqliteWindow(window: Window | SqliteWindow): window is SqliteWindow {
  return 'sqlitePlugin' in window && typeof window.sqlitePlugin === 'object';
}

type CordovaWindow = Window & {cordova: any};
function isCordovaWindow(window: Window | CordovaWindow): window is CordovaWindow {
  return 'cordova' in window && typeof window.cordova === 'object';
}

declare global {
  interface DocumentEventMap {
    // cordova events
    deviceready: Event;
  }
}

export class CordovaSqliteStorage<T> implements Keepsake<T> {
  public static async isSupported(): Promise<boolean> {
    if (!isCordovaWindow(window) || window.cordova.platformId == null) {
      return false;
    }
    await fromEvent(document, 'deviceready')
      .pipe(take(1), mapTo(undefined))
      .toPromise();
    // Since we're in an static function the test which contains lambdas needs to be stored in a
    // variable.
    // eslint-disable-next-line sonarjs/prefer-immediate-return
    const sqliteTest = new Promise<boolean>(function(resolve) {
      if (!isSqliteWindow(window)) {
        resolve(false);
        return;
      }
      window.sqlitePlugin.echoTest(
        function() {
          resolve(true);
        },
        function() {
          resolve(false);
        },
      );
    });
    return sqliteTest;
  }

  public static async prepareSqliteStorage<T>(
    tableName: string,
    databaseAlternativeName?: string,
  ): Promise<CordovaSqliteStorage<T>> {
    const sqliteStorage = new CordovaSqliteStorage<T>(tableName);
    await sqliteStorage.initDatabase(databaseAlternativeName);
    return sqliteStorage;
  }

  private readonly _tableName: string;
  private readonly _cordovaReady: Promise<void>;

  private _db!: Database;

  public constructor(tableName: string) {
    this._tableName = tableName;
    this._cordovaReady = fromEvent(document, 'deviceready')
      .pipe(take(1), mapTo(undefined))
      .toPromise();
  }

  public async save(key: string, data: T): Promise<void> {
    await this._cordovaReady;
    const query = `INSERT OR REPLACE INTO ${this._tableName} (id, value) VALUES (?,?)`;
    const args = [key, JSON.stringify(data)];
    const sqlTransaction = await this.createTransaction();
    await this.executeInTransaction(sqlTransaction, query, args);
  }

  public async delete(key: string): Promise<void> {
    await this._cordovaReady;
    const sqlTransaction = await this.createTransaction();
    await this.executeInTransaction(sqlTransaction, `DELETE FROM ${this._tableName} WHERE id=?`, [
      key,
    ]);
  }

  public async get(key: string): Promise<T> {
    await this._cordovaReady;
    const query = `SELECT id, value FROM ${this._tableName} WHERE id=? LIMIT 1`;
    const sqlTransaction = await this.createReadTransaction();
    const resultSet = (await this.executeInTransaction(sqlTransaction, query, [
      key,
    ])) as SQLResultSet;
    if (resultSet.rows.length === 1) {
      return JSON.parse(resultSet.rows.item(0).value);
    }
    throw new NotFoundError(`No data found for the given key '${key}'`);
  }

  public async getIndex(): Promise<string[]> {
    await this._cordovaReady;
    const query = `SELECT id FROM ${this._tableName} ORDER BY timestamp DESC`;
    const sqlTransaction = await this.createReadTransaction();
    const resultSet = (await this.executeInTransaction(sqlTransaction, query)) as SQLResultSet;
    const results = [];
    for (let i = 0; i < resultSet.rows.length; i++) {
      results.push(resultSet.rows.item(i).id);
    }
    return results;
  }

  public async list(): Promise<[string, T][]> {
    await this._cordovaReady;
    const query = `SELECT * FROM ${this._tableName}`;
    const sqlTransaction = await this.createReadTransaction();
    const resultSet = (await this.executeInTransaction(sqlTransaction, query)) as SQLResultSet;
    const results = [];
    for (let i = 0; i < resultSet.rows.length; i++) {
      const item = resultSet.rows.item(i);
      results.push([item.id, JSON.parse(item.value)] as [string, T]);
    }
    return results;
  }

  public async purge(): Promise<void> {
    await this._cordovaReady;
    const sqlTransaction = await this.createTransaction();
    await this.executeInTransaction(sqlTransaction, `DELETE FROM ${this._tableName} WHERE 1=1`);
  }

  private async initDatabase(databaseAlternativeName?: string): Promise<void> {
    await this._cordovaReady;
    if (!isSqliteWindow(window)) {
      throw new Error(`The sqlitePlugin is no longer defined on the window.
          It was defined and functioning upon initialization, but somehow got removed.`);
    }
    this._db = window.sqlitePlugin.openDatabase({
      name: databaseAlternativeName || this._tableName,
      location: 'default',
    });
    const sqlTransaction = await this.createTransaction();
    await this.executeInTransaction(
      sqlTransaction,
      `CREATE TABLE IF NOT EXISTS ${this._tableName} (id NVARCHAR(32) UNIQUE PRIMARY KEY, value TEXT, timestamp REAL)`,
    );
  }

  private async executeInTransaction(
    sqlTransaction: SQLTransaction,
    sqlStatement: string,
    args?: ObjectArray,
  ): Promise<SQLResultSet | void> {
    return new Promise<SQLResultSet>(function(resolve, reject) {
      sqlTransaction.executeSql(
        sqlStatement,
        args || [],
        (transaction, resultSet) => resolve(resultSet),
        (transaction, {message}) => {
          reject(new Error(message as string));
          return false;
        },
      );
    });
  }

  private createTransaction(): Promise<SQLTransaction> {
    return new Promise<SQLTransaction>((resolve, reject) =>
      this._db.transaction(
        transaction => resolve(transaction),
        ({message}) => reject(new Error(message as string)),
      ),
    );
  }

  private createReadTransaction(): Promise<SQLTransaction> {
    return new Promise<SQLTransaction>((resolve, reject) =>
      this._db.readTransaction(
        transaction => resolve(transaction),
        ({message}) => reject(new Error(message as string)),
      ),
    );
  }
}
