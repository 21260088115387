import {Directive, ElementRef, OnDestroy, OnInit} from '@angular/core';

import {BaseInputAutocompleteComponent} from './base-input-autocomplete.component';

/**
 * This directive captures the input that the input-autocomplete possibly shows in the options modal
 *
 * This is a very clean solution for an otherwise complicated problem:
 *
 * - Angular doesn't respect `ViewChild[ren]` annotations for parts inside a `<ng-template>`. This
 *   means we cannot add `@ViewChild('optionsInput')` on the input autocomplete component's
 *   `optionsInput` setter (assuming `#optionsInput` in the template). Note that this is not always
 *   true, e.g. elements with `*ngIf` on it will be reachable using a view child query. For our
 *   modals, it sometimes works. Even after hours of debugging it was impossible to determine when
 *   it works and when not, the answer is "sometimes it suddenly works if you trigger an event
 *   listener, but not consistently".
 *   - See also: https://github.com/angular/angular/issues/14835
 * - Our modals are set up roughly identical to portals in `@angular/cdk`. Just like these portals,
 *   our modals are always instantiated using the `ViewContainerRef` of the element where the modal
 *   is rendered, not the `ViewContainerRef` of the location the template is defined. This makes a
 *   lot of sense, because angular doesn't separate the "logical" location in the view tree from the
 *   physical location of the elements in the DOM.
 *   Even if we modify the modal render algorithm to use the `ViewContainerRef` of the autocomplete
 *   input and then "magically" move the DOM nodes to the modal element, it still doesn't work
 *   properly. Again attempts to determine what triggered it to work failed after half a day of
 *   investigation. The only thing that has been assertained: it works more often than the solution
 *   where the `ViewContainerRef` of the modal render directive is used.
 *
 * This directive works because the dependency injection tree is respected in the modals, so this
 * directive can inject the `BaseInputAutocompleteComponent` and set the element manually.
 *
 * @ngModule InputAutocompletePartsModule
 */
@Directive({selector: '[maiaCaptureOptionsInput]'})
export class CaptureInputDirective implements OnInit, OnDestroy {
  public constructor(
    private readonly element: ElementRef<HTMLInputElement>,
    private readonly autocomplete: BaseInputAutocompleteComponent<unknown>,
  ) {}

  public ngOnInit() {
    this.autocomplete.optionsInput = this.element;
  }

  public ngOnDestroy() {
    this.autocomplete.optionsInput = undefined;
  }
}
