import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationFuncSearchSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaInformationFuncSearch]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M21.01 21.01L15.3 15.3"></svg:path><svg:circle cx="10.2" cy="10.2" r="7.21" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:circle>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationFuncSearchSvgComponent {
}

@NgModule({
  declarations: [
    InformationFuncSearchSvgComponent,
  ],
  exports: [
    InformationFuncSearchSvgComponent,
  ],
})
export class InformationFuncSearchSvgModule {}
