/**
 * A `ValueProcessor` is used to process raw values (the unformatted value of a masked input) into
 * string values that will be used as the actual value of the input (`[ngModel]`).
 */
export interface ValueProcessor {
  /**
   * Transforms a processed value into a raw value.
   *
   * This function is the inverse of the `fromRawValue` function, that is: the following statements
   * must be true
   *
   * ```ts
   * processor.fromRawValue(processor.toRawValue(processedValue)) === processedValue
   * processor.toRawValue(processor.fromRawValue(rawValue)) === rawValue
   * ```
   *
   * @param processedValue The processed value, possibly incomplete
   * @returns The raw value that corresponds to the given processed value
   */
  toRawValue(processedValue: string): string;

  /**
   * Transforms a raw value into a processed value.
   *
   * This function is the inverse of the `toRawValue` function, that is: the following statements
   * must be true
   *
   * ```ts
   * processor.toRawValue(processor.fromRawValue(rawValue)) === rawValue
   * processor.fromRawValue(processor.toRawValue(processedValue)) === processedValue
   * ```
   *
   * @param rawValue The raw value, possibly incomplete
   * @returns The processed value that corresponds to the given raw value
   */
  fromRawValue(rawValue: string): string;
}

/**
 * A value processor that doesn't do anything, that is: the processed value is identical to the raw
 * value.
 */
export const noOpValueProcessor: ValueProcessor = {
  toRawValue(processedValue: string) {
    return processedValue;
  },

  fromRawValue(rawValue: string) {
    return rawValue;
  },
};
