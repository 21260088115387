import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  Renderer2,
} from '@angular/core';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {Utilities} from '@maia/core';
import {
  FormElementComponent,
  InputContainer,
  LabelContainerService,
  labelContainerServiceFactory,
  ValidationMessageExtractor,
  μSingleInputContainer as SingleInputContainer,
} from '@maia/forms';

const generateName = Utilities.createIdGenerator('maia-collapsible-checkbox-group-');

/**
 * A collapsible form element. This form element is created sole for use with the
 * collapsible checkbox buttons.
 *
 * @ngModule FormsModule
 */
@Component({
  selector: 'maia-collapsible-checkbox-group-form-element',
  templateUrl: './collapsible-checkbox-group-form-element.component.html',
  styleUrls: [
    '../../../forms/src/form-element/form-element.component.scss',
    './collapsible-checkbox-group-form-element.component.scss',
  ],

  host: {
    '[class.maia-form-element--label-aligned-left]': 'options.alignLabelLeft || false',
  },

  providers: [
    ValidationMessageExtractor,
    {
      provide: LabelContainerService,
      useFactory: labelContainerServiceFactory,
      deps: [forwardRef(() => CollapsibleCheckboxGroupFormElementComponent), Renderer2],
    },
    {provide: InputContainer, useClass: SingleInputContainer},
  ],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class CollapsibleCheckboxGroupFormElementComponent extends FormElementComponent {
  public name = generateName();

  @HostBinding('attr.aria-activedescendant')
  public activeDescendant: string;

  public constructor(changeDetectorRef: ChangeDetectorRef, inputContainer: InputContainer) {
    super(inputContainer, changeDetectorRef);
    this.extraPadding = true;
  }
}
