import {Injectable} from '@angular/core';

/**
 * The active language
 *
 * The language can be injected into your code to access the name and/or code of the currently
 * active language.
 */
@Injectable()
export class Language {
  /**
   * The language code, e.g. `'en'` for English
   */
  public readonly code: string;

  /**
   * The language name, e.g. 'English'
   */
  public readonly name: string;
}
