import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 * Panel Component
 *
 * @ngModule PanelsModule
 */
@Component({
  selector: 'maia-panel-title-icon',
  template: '<ng-content></ng-content>',
  styleUrls: ['./panel-title-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelTitleIconComponent {}
