import {ErrorHandler, NgModule} from '@angular/core';

import {LoggerErrorHandler} from './logger-error-handler.service';

/**
 * Importing this module hooks the atlas logger into angular's error handling system, ensuring all
 * error messages pass through the logger, even if they aren't explicitly logged.
 *
 * Importing this module is not necessary if you just want to use the logging services, they are
 * automatically provided.
 */
@NgModule({
  providers: [{provide: ErrorHandler, useClass: LoggerErrorHandler}],
})
export class LoggerModule {}
