import {Injectable, Provider, Type, InjectionToken} from '@angular/core';
import {ExtractableConfiguration} from '@hermes/configuration';

export function createDefaultConfiguration(): DeeplinkingConfiguration {
  return {
    title: '',
  };
}

@Injectable({
  providedIn: 'root',
  useFactory: createDefaultConfiguration,
})
export abstract class DeeplinkingConfiguration {
  public abstract readonly title: ExtractableConfiguration<string>;
}

export function provideDeeplinkingConfigurationValue(value: DeeplinkingConfiguration): Provider {
  return {
    provide: DeeplinkingConfiguration,
    useValue: value,
  };
}

export function provideDeeplinkingConfigurationClass(
  clazz: Type<DeeplinkingConfiguration>,
): Provider {
  return {
    provide: DeeplinkingConfiguration,
    useClass: clazz,
  };
}

export function provideDeeplinkingConfigurationExisting(
  existing: Type<DeeplinkingConfiguration> | InjectionToken<DeeplinkingConfiguration>,
): Provider {
  return {
    provide: DeeplinkingConfiguration,
    useExisting: existing,
  };
}
