import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  Injector,
  Input,
  OnChanges,
  Renderer2,
} from '@angular/core';
import {WindowRef} from '@atlas-angular/cdk/globals';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {FrameThrottleService, μRenderViewportService as RenderViewportService} from '@maia/core';

import {merge, Subject} from 'rxjs';
import {filter, startWith} from 'rxjs/operators';
import shave from 'shave';

@Directive({
  selector: '[maiaLineClamp]',
})
@UntilDestroy()
export class LineClampDirective implements AfterViewInit, OnChanges {
  /**
   * Triggers a recalculation of the lineclamp.
   */
  public recalculate$ = new Subject();

  private _isClamped = false;

  /**
   * The maximum height of the text block.
   */
  @Input()
  public clampHeight: number;

  @HostBinding('class.maia-line-clamp--is-clamped')
  public get isClamped() {
    return this._isClamped;
  }

  /**
   * The character to end the clamped line with.
   */
  @Input()
  public character = '...';

  public constructor(
    private injector: Injector,
    private readonly element: ElementRef,
    private readonly renderViewportService: RenderViewportService,
    private readonly frameThrottleService: FrameThrottleService,
    private readonly renderer: Renderer2,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  // Trigger shave whenever any of the Inputs change
  public ngOnChanges() {
    this.recalculate$.next();
  }

  public ngAfterViewInit() {
    const window = this.injector.get<WindowRef>(WindowRef);
    this.frameThrottleService
      .throttle$(
        merge(
          window.on$('resize', {passive: true}),
          this.renderViewportService.classesUpdated$,
          this.recalculate$,
        ),
      )
      .pipe(
        startWith(null),
        // dont try to execute shave when no clamp height is set
        filter(() => this.clampHeight != null),
        takeUntilDestroyed(this),
      )
      .subscribe(_ => {
        // The shave lib seems to incorrectly calculate the height of the text when a fontWeight
        // lower than 400 is used. Therefore we temporarily change the fontWeight to '900' before
        // calling shave. That way there should be no chance of accidentally having an extra line.

        const originalFontWeight = this.element.nativeElement.style.fontWeight;
        this.renderer.setStyle(this.element.nativeElement, 'fontWeight', '900');

        shave(this.element.nativeElement, this.clampHeight, {
          classname: 'maia-shave',
          character: this.character,
        });

        // Execute this async to avoid changing the variable after it has been checked already.
        setTimeout(() => {
          this._isClamped = this.element.nativeElement.querySelector('.maia-shave') != null;
          this.cdRef.markForCheck();
        });

        this.renderer.setStyle(this.element.nativeElement, 'fontWeight', originalFontWeight);
      });
  }
}
