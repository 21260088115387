import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule CommunicationExprKateSvgModule
 */
@Component({
  selector: 'svg[kdlBifrostCommunicationExprKate]',
  template: '<svg:circle fill="#B2E7FA" cx="24" cy="24" r="19"></svg:circle><svg:circle fill="#E6F7FD" cx="24" cy="24" r="17.4"></svg:circle><svg:circle fill="#F5FCFF" cx="24" cy="24" r="15.8"></svg:circle><svg:circle fill="#FFFFFF" cx="24" cy="24" r="14.2"></svg:circle><svg:path fill="#00AEEF" d="M20.1 34.1c-.4 0-.8-.4-.8-.8V14.7c0-.4.4-.8.8-.8s.8.4.8.8v18.6c0 .4-.3.8-.8.8z"></svg:path><svg:path fill="#00AEEF" d="M16.2 26.6c-.4 0-.8-.4-.8-.8v-3.7c0-.4.4-.8.8-.8s.8.4.8.8v3.7c0 .5-.3.8-.8.8z"></svg:path><svg:path fill="#00AEEF" d="M24 28.5c-.4 0-.8-.4-.8-.8v-7.4c0-.4.4-.8.8-.8s.8.4.8.8v7.4c0 .4-.4.8-.8.8z"></svg:path><svg:path fill="#00AEEF" d="M27.9 30.4c-.4 0-.8-.4-.8-.8V18.4c0-.4.4-.8.8-.8s.8.4.8.8v11.2c0 .5-.4.8-.8.8z"></svg:path><svg:path fill="#00AEEF" d="M31.8 26.6c-.4 0-.8-.4-.8-.8v-3.7c0-.4.4-.8.8-.8s.8.4.8.8v3.7c-.1.5-.4.8-.8.8z"></svg:path>',

  styles: [
  ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunicationExprKateSvgComponent {
}

@NgModule({
  declarations: [
    CommunicationExprKateSvgComponent,
  ],
  exports: [
    CommunicationExprKateSvgComponent,
  ],
})
export class CommunicationExprKateSvgModule {}
