import {BusinessType, createStringifier} from '../base/businesstype';

import {isValidBban} from './validator-base';

const stringifier = createStringifier('bban');

/**
 * BusinessType wrapper around bbans, exposing the bban
 * number as a formatted text.
 */
export class Bban extends BusinessType<string> {
  /**
   * Returns whether or not `value` is a Bban.
   */
  public static isBban(value: unknown): value is Bban {
    return value instanceof Bban;
  }

  /**
   * Constructs a new Bban instance.
   *
   * @param value The bban value
   * @param encryptedValue The encryptedValue, if any
   */
  public constructor(value: string, encryptedValue?: string) {
    if (typeof value !== 'string') {
      throw new Error(
        `Expected a string but got ${(typeof value === 'object' &&
          (value as object).constructor.name) ||
          typeof value}`,
      );
    } else if (!isValidBban(value)) {
      throw new Error(`Cannot create Bban for invalid input ${JSON.stringify(value)}`);
    }
    super(value, encryptedValue);
  }

  /**
   * Returns whether `other` is a `Bban` representing the same value.
   */
  public equals(other: unknown): boolean {
    if (!other) {
      return false;
    }

    if (other === this) {
      return true;
    }

    return Bban.isBban(other) && this.internalValue === other.internalValue;
  }

  /**
   * Exposes the value of this BusinessType as a string. The returned value will
   * always be the same.
   */
  public asString(): string {
    return this.internalValue;
  }

  public toString(): string {
    return stringifier(this);
  }
}

export const BANK_IDENTIFIER_START = 0;
export const BANK_IDENTIFIER_LENGTH = 3;
export const BANK_IDENTIFIER_END = BANK_IDENTIFIER_START + BANK_IDENTIFIER_LENGTH;
export const ACCOUNT_NUMBER_START = BANK_IDENTIFIER_END;
export const ACCOUNT_NUMBER_LENGTH = 7;
export const ACCOUNT_NUMBER_END = ACCOUNT_NUMBER_START + ACCOUNT_NUMBER_LENGTH;
export const NON_CHECK_DIGITS_LENGTH = BANK_IDENTIFIER_LENGTH + ACCOUNT_NUMBER_LENGTH;
export const CHECK_DIGITS_START = ACCOUNT_NUMBER_END;
export const CHECK_DIGITS_LENGTH = 2;
export const CHECK_DIGITS_END = CHECK_DIGITS_START + CHECK_DIGITS_LENGTH;
export const BBAN_LENGTH = BANK_IDENTIFIER_LENGTH + ACCOUNT_NUMBER_LENGTH + CHECK_DIGITS_LENGTH;
export const CHECK_DIGITS_MODULO_BASE = 97;
