import {
  Compiler,
  Injectable,
  Injector,
  NgModuleFactory,
  NgModuleFactoryLoader,
  NgModuleRef,
  Type,
} from '@angular/core';
import {LoadChildren, Route, Router, Routes} from '@angular/router';
import {LoggerFactory} from '@atlas-angular/logger';
import {Logger} from '@atlas/logger';

import {MncDetector} from './mnc-detector';

export const ROUTE_PATH_MNC = 'mnc';

/**
 * Loads the MNC platform at runtime.
 */
@Injectable()
export class MncLoader {
  private readonly logger: Logger;

  public constructor(
    private readonly loader: NgModuleFactoryLoader,
    private readonly injector: Injector,
    private readonly compiler: Compiler,
    private readonly mncDetector: MncDetector,
    loggerFactory: LoggerFactory,
  ) {
    this.logger = loggerFactory.createLogger('MncLoader');
  }

  /**
   * Loads the MNC platform if it is detected through the MncDetector.
   */
  public async load(): Promise<NgModuleRef<any> | void> {
    if (!this.mncDetector.isMnc()) {
      this.logger.debug('Platform is not MNC, doing nothing');
      return;
    }

    this.logger.info('Platform is MNC, loading...');

    const router = this.injector.get(Router);
    const platformRoute = this.getMncRoute(router.config);

    if (platformRoute == null || platformRoute.loadChildren == null) {
      throw new Error('No or malformed route configuration for MNC platform');
    }

    return this.loadModule(platformRoute.loadChildren).then((moduleRef: NgModuleRef<any>) => {
      this.logger.debug('MNC platform successfully loaded');
      return moduleRef;
    });
  }

  private async loadModule(loadChildrenCallback: LoadChildren): Promise<NgModuleRef<any>> {
    return this.loadModuleFactory(loadChildrenCallback)
      .then(elementModuleFactory => {
        return elementModuleFactory.create(this.injector);
      })
      .catch(err => {
        return Promise.reject('Error loading MNC platform: ' + err);
      });
  }

  private async loadModuleFactory(
    loadChildrenCallback: LoadChildren,
  ): Promise<NgModuleFactory<any>> {
    if (typeof loadChildrenCallback === 'string') {
      // old syntax; it's deprecated now but due to multiple issues with the lambda syntax, we're forced to use it for lazy loading
      return this.loader.load(loadChildrenCallback);
    } else {
      return (loadChildrenCallback() as Promise<NgModuleFactory<any> | Type<any>>).then(
        elementModuleOrFactory => {
          if (elementModuleOrFactory instanceof NgModuleFactory) {
            this.logger.debug('=> AOT mode');
            return elementModuleOrFactory;
          } else {
            this.logger.debug('=> JIT mode');
            return this.compiler.compileModuleAsync(elementModuleOrFactory);
          }
        },
      );
    }
  }

  /**
   * Get the path to the MNC module from the available routes.
   */
  protected getMncRoute(routes: Routes): Route | undefined {
    return routes.find(route => route.path === ROUTE_PATH_MNC);
  }
}
