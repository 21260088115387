import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Injector,
  OnInit,
} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';

import {take} from 'rxjs/operators';

import {AbstractDropdownComponent} from '../shared/dropdown.component';
import {DropdownMargin} from '../shared/dropdown.interfaces';

/**
 * A regular dropdown.
 *
 * The dropdown doesn't have a visual blue border.
 *
 * @ngModule DropdownsModule
 */
@Component({
  selector: 'maia-regular-dropdown',
  templateUrl: './regular-dropdown.component.html',
  styleUrls: ['./regular-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  animations: [
    trigger('show', [
      state('pending', style({opacity: 0})),
      transition('* => ready', [
        style({opacity: 0}),
        animate(150, style({opacity: 1})),
        style('*'),
      ]),
      transition(':leave', [style({opacity: 1}), animate(150, style({opacity: 0}))]),
    ]),
  ],
})
@UntilDestroy()
export class RegularDropdownComponent extends AbstractDropdownComponent implements OnInit {
  private _positioned = 'pending';

  /**
   * Default margins: 8px.
   */
  public readonly defaultMargins: DropdownMargin = {
    top: 8,
    bottom: 8,
    alignedHorizontal: -8,
  };

  public constructor(injector: Injector, private readonly _changeDetector: ChangeDetectorRef) {
    super(injector);
  }

  public ngOnInit() {
    this.onRepositioned$.pipe(take(1), takeUntilDestroyed(this)).subscribe(() => {
      this._positioned = 'ready';
      this._changeDetector.detectChanges();
    });

    super.ngOnInit();
  }

  @HostBinding('@show')
  public get positioned(): string {
    return this._positioned;
  }
}
