import {
  Directive,
  ElementRef,
  EventEmitter,
  Injector,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {WindowRef} from '@atlas-angular/cdk/globals';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {
  CssClassUtility,
  CssClassUtilityFactory,
  DomIoService,
  FrameThrottleService,
} from '@maia/core';
import {merge, Observable} from 'rxjs';
import {mapTo} from 'rxjs/operators';

import {RepositionerService} from './dropdown-repositioner.service';
import {calculatePosition as realCalculatePosition, TargetRect} from './dropdown.calculations';
import {
  DropdownAlignment,
  DropdownAnchor,
  parsePosition,
  POSITION_CLASSES,
  RepositionReason,
} from './dropdown.constants';
import {
  DropdownMargin,
  InternalDropdownOptions,
  ResolvedDropdownPosition,
} from './dropdown.interfaces';

const CLASSES = {
  position: POSITION_CLASSES,
};

let calculatePosition = realCalculatePosition;

/**
 * @internal only exposed for testing
 */
export function setPositionCalculator(positionCalculator: typeof calculatePosition | null): void {
  calculatePosition = positionCalculator ?? realCalculatePosition;
}

const createComputePositionRelativeToViewport = (target: ClientRect, window: Window) => {
  const windowHeight = window.innerHeight;
  const scrollY = window.pageYOffset;
  const scrollX = window.pageXOffset;

  return (position: ResolvedDropdownPosition) => {
    const height = position.height || target.height;

    return {
      bottom: `${windowHeight - (position.top + height + scrollY)}px`,
      top: `${scrollY + position.top}px`,
      left: `${scrollX + position.left}px`,
    };
  };
};

const createComputePositionRelativeToAncestor = (ancestor: ClientRect, target: ClientRect) => {
  return (position: ResolvedDropdownPosition) => {
    const height = position.height || target.height;

    return {
      bottom: `${ancestor.bottom - (position.top + height)}px`,
      top: `${position.top - ancestor.top}px`,
      left: `${position.left - ancestor.left}px`,
    };
  };
};

const createSetPosition = (targetElement: HTMLElement, target: ClientRect, window: Window) => {
  const offsetParentElement = targetElement.offsetParent || window.document.body;

  // In case there's no positioned ancestor, positioning is done relative to the viewport.
  const computePosition =
    window.getComputedStyle(offsetParentElement).position === 'static'
      ? createComputePositionRelativeToViewport(target, window)
      : createComputePositionRelativeToAncestor(
          offsetParentElement.getBoundingClientRect(),
          target,
        );

  return (position: ResolvedDropdownPosition) => {
    const computedPosition = computePosition(position);

    if (parsePosition(position.position).anchor === DropdownAnchor.Top) {
      targetElement.style.bottom = computedPosition.bottom;

      // We __must__ set the top, because it's set to -100vh using CSS to keep the dropdowns
      // off-screen during initialisation. Ideally we'd use `unset` or `initial`, but browser
      // support means we have to set the actual initial value ourself. (Thanks, IE)
      targetElement.style.top = 'auto';
    } else {
      targetElement.style.top = computedPosition.top;

      // Clear any bottom set previously
      // @ts-expect-error statement
      targetElement.style.bottom = null;
    }

    targetElement.style.left = computedPosition.left;

    if (position.cramped && position.height != null) {
      targetElement.style.height = `${position.height}px`;
    }

    if (position.width != null) {
      targetElement.style.width = `${position.width}px`;
    }
  };
};

/**
 * Abstract class for dropdown components. This class takes care of positioning the dropdown but
 * doesn't include any styling.
 *
 * Concrete subclasses __must__ make the host element absolutely positioned, otherwise the
 * positioning won't work.
 * If a subclass provides a `ngOnInit` method, it __must__ call `super.ngOnInit()`, otherwise the
 * positioning won't work.
 */
@Directive()
@UntilDestroy()
export abstract class AbstractDropdownComponent implements OnDestroy, OnInit {
  private readonly _classUtility: CssClassUtility<typeof CLASSES>;
  private readonly _hostClassUtility: CssClassUtility<typeof CLASSES>;

  public readonly repositioner: RepositionerService;

  /**
   * Emits an event each time the dropdown is repositioned
   */
  public onRepositioned$ = new EventEmitter<void>();

  /**
   * The default margins for the dropdown. These will be used if no margins are specified in the
   * `InternalDropdownOptions`.
   *
   * @see InternalDropdownOptions#margins
   */
  protected abstract readonly defaultMargins: DropdownMargin;

  private _cramped = false;

  private readonly _domIo: DomIoService;
  private readonly _window: WindowRef;
  private readonly _element: ElementRef<HTMLElement>;
  private readonly _options: InternalDropdownOptions;
  private readonly _frameThrottler: FrameThrottleService;
  private readonly _zone: NgZone;

  public constructor(injector: Injector) {
    const classUtilityFactory = injector.get(CssClassUtilityFactory);
    const renderer = injector.get(Renderer2);

    this.repositioner = injector.get(RepositionerService);
    this._domIo = injector.get(DomIoService);
    this._window = injector.get(WindowRef);
    this._element = injector.get<ElementRef<HTMLElement>>(ElementRef);
    // FIXME default value is only used in tests, because we don't want to expose
    // InternalDropdownOptions
    this._options = injector.get(InternalDropdownOptions, {}) as InternalDropdownOptions;
    this._frameThrottler = injector.get(FrameThrottleService);
    this._zone = injector.get(NgZone);

    this._classUtility = classUtilityFactory.create(CLASSES, renderer, this._element);
    this._hostClassUtility = classUtilityFactory.create(CLASSES, renderer, {
      nativeElement: this._options.referencePoint,
    });
  }

  public ngOnDestroy(): void {
    // Required to use takeUntilDestroyed

    this._hostClassUtility.setValue('position', null);
    this.onRepositioned$.complete();
  }

  public ngOnInit(): void {
    const zone = this._zone;

    zone.runOutsideAngular(() => {
      const event$: Observable<RepositionReason> = merge(
        this._window.on$('resize', {passive: true}).pipe(mapTo(RepositionReason.RESIZE)),
        this._window.on$('scroll', {passive: true}).pipe(mapTo(RepositionReason.SCROLL)),
      );
      const throttledEvent$ = this._frameThrottler.throttle$(event$);
      throttledEvent$
        .pipe(takeUntilDestroyed(this))
        .subscribe(reason => zone.run(() => void this.reposition(reason)));
    });

    this.repositioner.repositionObservable.pipe(takeUntilDestroyed(this)).subscribe(() => {
      void this.reposition(RepositionReason.EXTERNAL);
    });

    void this.reposition(RepositionReason.SETUP);
  }

  /**
   * Recalculates the position of the dropdown. This method may only be called once per frame.
   */
  protected async reposition(reason: RepositionReason): Promise<void> {
    const {window} = this._window;
    const targetElement = this._element.nativeElement;
    const referencePointElement = this._options.referencePoint;

    // Checks if windows was resized, there was a scroll, and dropdown didn't fit
    // or when it was requested by an external component to reset the height before
    // calculating to see whether it fits now (and to update height override)
    if (
      ((reason === RepositionReason.RESIZE || reason === RepositionReason.SCROLL) &&
        this._cramped) ||
      reason === RepositionReason.EXTERNAL
    ) {
      void this._domIo.mutate(() => {
        this._cramped = false;
        // @ts-expect-error statement
        targetElement.style.height = null;
      });
    }

    await this._domIo.measure(() => {
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      const target = targetElement.getBoundingClientRect();
      const referencePoint = referencePointElement.getBoundingClientRect();

      const setPosition = createSetPosition(targetElement, target, window);

      void this._domIo.mutate(() => {
        const position = this.calculatePosition(target, referencePoint, windowHeight, windowWidth);

        setPosition(position);

        if (position.cramped) {
          this._cramped = true;
        }

        this._classUtility.setValue('position', position.position);
        this._hostClassUtility.setValue('position', position.position);
      });
    });
    // This code runs after the measure and mutate (because the mutation promise resolves after
    // the measure promise)

    this.onRepositioned$.emit();
  }

  private calculatePosition(
    target: TargetRect,
    referencePoint: ClientRect,
    windowHeight: number,
    windowWidth: number,
  ): ResolvedDropdownPosition {
    const margins = Object.assign(this.defaultMargins, this._options.margins);

    const result = calculatePosition(
      target,
      referencePoint,
      this._options.position,
      margins,
      windowHeight,
      windowWidth,
      this._options.withoutReferenceOverlapping,
    );

    if (
      parsePosition(result.position).alignment !== DropdownAlignment.Aligned ||
      this._options.alignedDropdownExtra == null
    ) {
      return result;
    }

    const {
      alternativePosition,
      maximumSize = Infinity,
      minimumSize = 0,
    } = this._options.alignedDropdownExtra;

    let alternativeTarget: TargetRect | undefined;

    if (result.width! > maximumSize) {
      alternativeTarget = {
        height: target.height,
        width: maximumSize,
      };
    } else if (result.width! < minimumSize) {
      alternativeTarget = {
        height: target.height,
        width: minimumSize,
      };
    }

    if (alternativeTarget == null) {
      return result;
    }

    const alternativeResult = calculatePosition(
      alternativeTarget,
      referencePoint,
      alternativePosition,
      margins,
      windowHeight,
      windowWidth,
      this._options.withoutReferenceOverlapping,
    );

    if (alternativeResult.width == null) {
      alternativeResult.width = alternativeTarget.width;
    }

    return alternativeResult;
  }
}
