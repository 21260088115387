import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {
  DropdownHost,
  DropdownOptions,
  DropdownPosition,
  DropdownTemplateContext,
} from '@maia/dropdowns';
import {CapturedInput} from '@maia/forms/capture';
import {BaseInputSelectComponent, μInputSelectOpener} from '@maia/input-select';

import {IconTextComponent} from '../icon-text/icon-text.component';

/**
 * @ngModule InputWithSelectModule
 */
@Component({
  selector: 'maia-content-dropdown',
  templateUrl: './content-dropdown.component.html',
  styleUrls: ['./content-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContentDropdownComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class ContentDropdownComponent<T> extends BaseInputSelectComponent<T> {
  /** @internal */
  @Input()
  public dropdownHost: DropdownHost;

  /** @internal */
  @Input()
  public dropdownContent: TemplateRef<DropdownTemplateContext<IconTextComponent<T>>>;

  private _options?: QueryList<IconTextComponent<T>>;

  @Input()
  public get options(): QueryList<IconTextComponent<T>> {
    return this._options || new QueryList();
  }

  public set options(value: QueryList<IconTextComponent<T>>) {
    if (this._options == null) {
      this._options = value;
      this.initKeyManager();
    }
  }

  @Input()
  public ariaLabel: string;

  private _value: T = {} as T;

  public set value(content: T) {
    if (!content) {
      return;
    }

    this._value = content;
  }

  public get value(): T {
    return this._value;
  }

  /** @internal */
  @ViewChild('contentDropdownInput', {read: CapturedInput, static: true})
  public capturedInput: CapturedInput;

  public constructor(cdr: ChangeDetectorRef, renderer: Renderer2, opener?: μInputSelectOpener) {
    super(cdr, renderer, opener);
  }

  protected _hasCapturedInput(): boolean {
    return true;
  }

  protected get _dropdownOptions(): DropdownOptions {
    return {position: DropdownPosition.BOTTOM_ALIGNED};
  }

  public openDropdown(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this._open();
  }
  public get activeOption(): IconTextComponent<T> | undefined {
    return this.options.find(o => o.value === this.value);
  }
}
