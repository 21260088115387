import {Injectable} from '@angular/core';

import {MessagingScopeService} from './messaging-scope/messaging-scope.service';
import {ScopedMessenger} from './messaging-scope/scoped-messenger';
import {MessagingActionService} from './messaging.action.service';
import {Messenger} from './messenger';

@Injectable({
  providedIn: 'root',
})
export class MessagingService extends Messenger {
  public constructor(
    messagingActionService: MessagingActionService,
    private readonly _messagingScopeService: MessagingScopeService,
  ) {
    super(messagingActionService);
  }

  /**
   * Creates a messaging scope on which backend calls can be assigned
   * @param identifier An identifier describing the scope
   */
  public createScope(identifier: string): ScopedMessenger {
    return new ScopedMessenger(
      identifier,
      this,
      this._messagingScopeService,
      this._messagingActionService,
    );
  }
}
