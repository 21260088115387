import {ChangeDetectionStrategy, Component, Optional} from '@angular/core';
import {TileComponent} from '../tile/tile.component';

/**
 * Tile title component
 *
 * @ngModule TilesModule
 */
@Component({
  selector: 'maia-tile-title',
  template: '<ng-content></ng-content>',
  styleUrls: ['./tile-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileTitleComponent {
  public constructor(@Optional() parent?: TileComponent) {
    if (parent === null) {
      throw new Error(
        'Cannot create a <maia-tile-title /> component out of <maia-tile /> component',
      );
    }
  }
}
