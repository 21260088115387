import {Directive, forwardRef, Input} from '@angular/core';
import {NG_VALIDATORS, ValidatorFn} from '@angular/forms';
import {Decimal, ExtraDecimalValidators, validateDecimal} from '@atlas/businesstypes';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore See https://github.com/microsoft/rushstack/issues/1050
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AtlasValidatorFn, ParameterizedValidatorConstructor} from '../base/validator';
import {
  createParameterizedValidatorDirective,
  AbstractValidatorDirective,
  createValidatorFn,
} from '../base/validator';

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasDecimal]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BaseDecimalValidator),
      multi: true,
    },
  ],
})
export class BaseDecimalValidator extends AbstractValidatorDirective {
  protected readonly validatorFn = validateDecimal;
}

@Directive({
  selector: '[atlasDecimal][atlasMinValue]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MinLimitDecimalValidator),
      multi: true,
    },
  ],
})
export class MinLimitDecimalValidator extends createParameterizedValidatorDirective<string>(
  ExtraDecimalValidators.minLimit,
) {
  @Input('atlasMinValue')
  public set limit(newLimit: string) {
    this.setParameter(newLimit);
  }
}

@Directive({
  selector: '[atlasDecimal][atlasMaxValue]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MaxLimitDecimalValidator),
      multi: true,
    },
  ],
})
export class MaxLimitDecimalValidator extends createParameterizedValidatorDirective<string>(
  ExtraDecimalValidators.maxLimit,
) {
  @Input('atlasMaxValue')
  public set limit(newLimit: string) {
    this.setParameter(newLimit);
  }
}

@Directive({
  selector: '[atlasDecimal][atlasMaxLengthFractionPart]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MaxLengthFractionPartDecimalValidator),
      multi: true,
    },
  ],
})
export class MaxLengthFractionPartDecimalValidator extends createParameterizedValidatorDirective<
  string
>(ExtraDecimalValidators.maxLengthFractionPart) {
  @Input('atlasMaxLengthFractionPart')
  public set limit(newLimit: string) {
    this.setParameter(newLimit);
  }
}

export const ExtraDecimalControlValidators = {
  minLimit(limit: Decimal | string): ValidatorFn {
    return createValidatorFn(ExtraDecimalValidators.minLimit(limit));
  },

  maxLimit(limit: Decimal | string): ValidatorFn {
    return createValidatorFn(ExtraDecimalValidators.maxLimit(limit));
  },
  maxLengthFractionPart(limit: number | string): ValidatorFn {
    return createValidatorFn(ExtraDecimalValidators.maxLengthFractionPart(limit));
  },
} as const;
