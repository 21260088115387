/**
 * The lengths of IBAN accounts as defined in ISO 13616-1:2007.
 */
export const IBAN_COUNTRY_LENGTH = {
  AL: 28,
  AD: 24,
  AT: 20,
  AZ: 28,
  BH: 22,
  BE: 16,
  BA: 20,
  BR: 29,
  BG: 22,
  CR: 21,
  HR: 21,
  CY: 28,
  CZ: 24,
  DK: 18,
  FO: 18,
  GL: 18,
  DO: 28,
  EE: 20,
  FI: 18,
  FR: 27,
  GE: 22,
  DE: 22,
  GI: 23,
  GR: 27,
  GT: 28,
  HU: 28,
  IS: 26,
  IE: 22,
  IL: 23,
  IT: 27,
  JO: 30,
  KZ: 20,
  XK: 20,
  KW: 30,
  LV: 21,
  LB: 28,
  LI: 21,
  LT: 20,
  LU: 20,
  MK: 19,
  MT: 31,
  MR: 27,
  MU: 30,
  MD: 24,
  MC: 27,
  ME: 22,
  NL: 18,
  NO: 15,
  PK: 24,
  PS: 29,
  PL: 28,
  PT: 25,
  QA: 29,
  RO: 24,
  LC: 32,
  SM: 27,
  ST: 25,
  SA: 24,
  RS: 22,
  SC: 31,
  SK: 24,
  SI: 19,
  ES: 24,
  SE: 24,
  CH: 21,
  TL: 23,
  TN: 24,
  TR: 26,
  UA: 29,
  AE: 23,
  GB: 22,
  VG: 24,
} as Readonly<Record<string, number>>;

/**
 * The default length of an IBAN if the country is not known
 */
export const DEFAULT_IBAN_MAX_LENGTH = 34 as number;

/**
 * The minimum length for IBAN numbers
 *
 * This minimum length is required because an IBAN contains at least a country designation (at the
 * start) followed by checkdigits.
 */
export const IBAN_MIN_LENGTH = 4 as number;
