import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  forwardRef,
  QueryList,
} from '@angular/core';
import {asapScheduler} from 'rxjs';
import {observeOn, startWith} from 'rxjs/operators';

import {FlowProgressComponent} from '../flow-progress.component';

import {Step} from './step';
import {StepComponent} from './step.component';

interface Weights {
  leftoverProgress: number;
  totalStepWeight: number;
  weightOfStepsWithProgress: number;
}

function calculateWeights(steps: QueryList<StepComponent>): Weights {
  return steps.reduce(
    ({leftoverProgress, weightOfStepsWithProgress, totalStepWeight}, step) => {
      if (step.progress > 0) {
        leftoverProgress -= step.progress * step.weight;
        weightOfStepsWithProgress += step.weight;
      }

      totalStepWeight += step.weight;

      return {leftoverProgress, weightOfStepsWithProgress, totalStepWeight};
    },
    {
      leftoverProgress: 1,
      totalStepWeight: 0,
      weightOfStepsWithProgress: 0,
    },
  );
}

function updateStepStates(steps: QueryList<StepComponent>, activeStep?: Step): void {
  let activeFound = false;

  steps.forEach(step => {
    if (step.matches(activeStep)) {
      activeFound = true;
      step.markActive(activeStep!);
      return;
    }

    if (activeFound) {
      step.markTodo();
    } else {
      step.markDone();
    }
  });
}

function updateStepWeights(
  steps: QueryList<StepComponent>,
  {leftoverProgress, totalStepWeight, weightOfStepsWithProgress}: Weights,
) {
  steps.forEach(step => {
    if (step.done) {
      // for done steps that don't have a progress yet, store the progress
      if (step.progress === 0) {
        if (step.weight === 0) {
          // If a step has no weight, it doesn't _need_ to store progress. If a step has 0 weight,
          // we might be in a scenario where totalStepWeight === weightOfStepsWithProgress, which
          // means we would otherwise set Infinity as progress, and 0 * Infinity is NaN, so our
          // calculations would break down.
          // The clean and easy solution: don't store progress for steps with no weight.
          step.progress = 0;
        } else {
          step.progress = leftoverProgress / (totalStepWeight - weightOfStepsWithProgress);
        }
      }
    } else {
      // clear progress for all steps that are not done
      step.progress = 0;
    }
  });
}

/**
 * A step in the flow progress that contains substeps
 *
 * @ngModule FlowProgressModule
 */
@Component({
  selector: 'maia-flow-progress-multi-step',
  template: '<ng-content></ng-content>',
  styleUrls: ['./multi-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  inputs: ['active', 'disabled', 'title'],

  outputs: ['activeChange'],

  providers: [{provide: Step, useExisting: forwardRef(() => MultiStepComponent)}],
})
export class MultiStepComponent extends Step implements AfterViewInit {
  /**
   * The substeps of this step
   *
   * @internal
   */
  @ContentChildren(StepComponent)
  public _substeps: QueryList<StepComponent>;

  public constructor(
    private readonly _container: FlowProgressComponent,
    changeDetector: ChangeDetectorRef,
  ) {
    super(changeDetector);
  }

  /**
   * Updates the activity state of the substeps when the content changes, e.g. a step is added or
   * removed.
   */
  private _onContentChange(): void {
    if (this.done) {
      this._markSubstepsDone();
    } else if (this.active) {
      this._markSubstepsActive(this._getActiveSubstep() || this);
    } /* state is todo */ else {
      this._markSubstepsTodo();
    }
  }

  public ngAfterViewInit(): void {
    this._substeps.changes
      .pipe(
        // Dynamically adding steps (e.g. with *ngIf) will synchronously trigger this change in
        // a context where changes to the newly added steps are not allowed. The
        // _onContentChanged method will set the state of the newly added step, so we must
        // trigger the method in the next tick.
        observeOn(asapScheduler),
        // Immediately perform the content change check
        startWith(null),
      )
      .subscribe(() => this._onContentChange());
  }

  private _markSubstepsDone(): void {
    this._substeps.forEach(step => step.markDone());
  }

  private _markSubstepsActive(activeStep?: Step): void {
    if (activeStep === this) {
      // The multi-step itself is made active so we make the first enabled active
      activeStep = this._getFirstEnabledSubstep();
    }

    const weights = calculateWeights(this._substeps);

    updateStepStates(this._substeps, activeStep);
    updateStepWeights(this._substeps, weights);
  }

  private _markSubstepsTodo(): void {
    this._substeps.forEach(step => step.markTodo());
  }

  private _getFirstEnabledSubstep(): Step | undefined {
    return this._substeps != null ? this._substeps.find(step => !step.disabled) : undefined;
  }

  private _getActiveSubstep(): Step | undefined {
    return this._substeps != null ? this._substeps.find(step => step.active) : undefined;
  }

  // Step API overrides

  public markDone(): void {
    super.markDone();
    this._markSubstepsDone();
  }

  public markTodo(): void {
    super.markTodo();
    this._markSubstepsTodo();
  }

  public markActive(activeStep: Step): void {
    super.markActive(activeStep);
    this._markSubstepsActive(activeStep);
  }

  protected _setInputActive(active: boolean): void {
    const activeSubstep = this._getActiveSubstep();
    const substepToActivate =
      activeSubstep != null ? activeSubstep : this._getFirstEnabledSubstep();

    // if there are no substeps (yet), there's nothing to do
    if (substepToActivate != null) {
      this._container.updateActive(substepToActivate, active);
    }
  }

  public matches(step?: Step | null): boolean {
    return this === step || this._substeps.some(substep => substep.matches(step));
  }

  public get progress(): number {
    return this._substeps.reduce((sum, step) => sum + (step.done ? step.progress : 0), 0);
  }
}
