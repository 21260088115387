import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule LogosKbcSvgModule
 */
@Component({
  selector: 'svg[kdlBifrostLogosKbc]',
  template: '<svg:ellipse fill="#00AEEF" cx="161.4" cy="104.3" rx="44.2" ry="44.2"></svg:ellipse><svg:path fill="#00AEEF" d="M194.1 140.2c-8.3 9.1-23.2 18.2-42.2 18.2-14.7 0-27.5-5.8-35.7-12.2-48.4 5.2-84.2 12.4-84.2 12.4v19.5h256v-40.3s-43.8-.4-93.9 2.4z"></svg:path><svg:path fill="#003665" d="M62.4 235.2V259H32v-63.4h30.4v28.5h.2l17.9-28.5H117l-26.7 32.8 25.1 30.6H80.6l-17.9-23.8h-.3z"></svg:path><svg:path fill="#003665" d="M288 256.3c-7.2 2.2-16.6 3.5-25.1 3.5-30.1 0-52.2-8.5-52.2-33.4 0-22.4 21.4-32.3 50.7-32.3 8.2 0 18.4 1 26.6 3.5v21.8c-5.9-3.7-12-5.9-20.5-5.9-11.2 0-22.2 5.4-22.2 13.4s11 13.4 22.2 13.4c8.5 0 14.6-2.4 20.5-5.8v21.8z"></svg:path><svg:path fill="#003665" d="M121.9 195.7h61.4c16 0 21.3 6.2 21.3 15.8 0 10.9-9.6 15.5-19.7 16v.2c10.2.8 20.2 1.9 20.2 14.7 0 8.3-5.3 16.5-22.7 16.5h-60.5v-63.2zm30.4 50.4h15.8c5.9 0 8-2.4 8-6.1 0-3.7-2.1-6.2-7.8-6.2h-16v12.3zm0-23.7h15c5.9 0 8.2-2.4 8.2-6.1 0-4.2-2.2-6.2-7.7-6.2h-15.5v12.3z"></svg:path>',

  styles: [
  ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 320 320',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogosKbcSvgComponent {
}

@NgModule({
  declarations: [
    LogosKbcSvgComponent,
  ],
  exports: [
    LogosKbcSvgComponent,
  ],
})
export class LogosKbcSvgModule {}
