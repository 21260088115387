import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {BehaviorSubject, NEVER} from 'rxjs';
import {distinctUntilChanged, switchMap} from 'rxjs/operators';

import {TemplateContext} from '../util/abstract-container.service';
import {createEmbeddedView$} from '../util/embedded-view';

import {ApplicationSubheaderContainer} from './application-subheader-container.service';

/**
 * Marks this element as the subheader for the application
 *
 * If the used application type and layout support a subheader, it will pick up this element and
 * show it in the correct place. If the application type or layout doesn't support showing any
 * subheader, the element won't be shown at all.
 *
 * @example
 *
 * ```html
 * <maia-flow-progress *atlasApplicationSubheader>
 *   <!-- ... -->
 * </maia-flow-progress>
 * ```
 *
 * @ngModule ApplicationSubheaderModule
 */
@Directive({selector: '[atlasApplicationSubheader]'})
@UntilDestroy()
export class ApplicationSubheaderDirective implements OnInit, OnDestroy {
  private readonly withInlineFallback$ = new BehaviorSubject(false);

  public constructor(
    private readonly template: TemplateRef<TemplateContext>,
    private readonly viewContainer: ViewContainerRef,
    private readonly container: ApplicationSubheaderContainer,
  ) {}

  /**
   * Whether this directive's host element should be shown inline if the application container doesn't support subheaders
   *
   * @default false
   * @example
   *
   * ```html
   * <maia-flow-progress *atlasApplicationSubheader="let isSubheaderInline = isInline; withInlineFallback: true">
   *   The value of <pre>{&zwj;{isSubheaderInline}&zwj;}</pre> shows whether the element is shown inline (true) or in the layout (false). Its value is <pre>{{isSubheaderInline}}</pre>
   * </maia-flow-progress>
   * ```
   */
  @Input('atlasApplicationSubheaderWithInlineFallback')
  public set withInlineFallback(withInlineFallback: boolean) {
    this.withInlineFallback$.next(withInlineFallback);
  }

  public ngOnInit(): void {
    this.container
      .registerTemplate(
        this.template,
        this.withInlineFallback$.pipe(
          distinctUntilChanged(),
          switchMap(enabled =>
            enabled
              ? createEmbeddedView$(this.viewContainer, this.template, {isInline: true})
              : NEVER,
          ),
        ),
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.withInlineFallback$.complete();
  }
}
