import {ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2} from '@angular/core';
import {CssClassUtility, CssClassUtilityFactory} from '@maia/core';

/**
 * All valid values for the `type` property of ButtonComponents.
 */
export type ButtonType =
  | 'secondary'
  | 'primary'
  | 'tertiary'
  | 'delete'
  | 'secondary-delete'
  | 'tertiary-delete';

const CLASSES = {
  type: {
    secondary: null,
    'secondary-delete': 'maia-button--secondary-delete',
    primary: 'maia-button--primary',
    delete: 'maia-button--delete',
    tertiary: 'maia-button--tertiary',
    'tertiary-delete': 'maia-button--tertiary-delete',
  } as {[key in ButtonType]: string | null},
};

/**
 * Button component. Buttons have a type, which decides the colour scheme to use.
 *
 * It is up to the consumer of this button component to ensure the proper accessibility
 * support is present.
 *
 * @ngModule ButtonsModule
 */
@Component({
  selector: 'button[maiaButton]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],

  // Buttons don't hold state and they don't accept any mutable inputs, so we can use OnPush
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  private _type: ButtonType | null;
  private readonly _cssClassUtility: CssClassUtility<typeof CLASSES>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(CLASSES, renderer, elementRef);
  }

  /**
   * The button type.
   */
  @Input('maiaButtonType')
  public get type(): ButtonType | null {
    return this._type;
  }

  public set type(newType: ButtonType | null) {
    if (newType === this._type) {
      return;
    }

    this._type = newType;
    this._cssClassUtility.setValue('type', newType);
  }
}
