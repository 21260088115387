/* eslint-disable import/no-extraneous-dependencies */
import {ComponentPortal} from '@angular/cdk/portal';
import {Injectable, Injector} from '@angular/core';
import {ToastController} from '@maia/modals/toasts';
import {Observable} from 'rxjs';

import {SYSTEM_MESSAGE, µSystemMessageComponent} from './system-message.component';
import {SystemMessage} from './system-message.interface';

@Injectable({providedIn: 'root'})
export class SystemMessageController {
  public constructor(
    private readonly toastCtrl: ToastController,
    private readonly injector: Injector,
  ) {}

  public showSystemMessage(systemMessage: SystemMessage): Observable<never> {
    return new Observable(observer => {
      const injector = Injector.create({
        providers: [
          {
            provide: SYSTEM_MESSAGE,
            useValue: {...systemMessage, doClose: () => observer.complete()},
          },
        ],
        parent: this.injector,
      });
      const portal = new ComponentPortal(µSystemMessageComponent, undefined, injector);

      return this.toastCtrl.addToastMessage(portal).subscribe(observer);
    });
  }
}
