import {Injectable} from '@angular/core';
import {WindowRef} from '@atlas-angular/cdk/globals';
import {LoggerFactory} from '@atlas-angular/logger';
import {Logger} from '@atlas/logger';

const RE_MNC_PLATFORM = /A\d{3}-MNC/i;

/**
 * This will detect, based on the user agent, whether the device is MNC or not.
 */
@Injectable({
  providedIn: 'root',
})
export class MncDetector {
  private readonly window: Window;
  private readonly logger: Logger;

  public constructor(windowRef: WindowRef, loggerFactory: LoggerFactory) {
    this.window = windowRef.window;
    this.logger = loggerFactory.createLogger('MncDetector');
    this.logger.debug('MNC detection enabled');
  }

  public isMnc(): boolean {
    return (
      this.window.navigator != null &&
      this.window.navigator.userAgent.match(RE_MNC_PLATFORM) !== null
    );
  }
}
