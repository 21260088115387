import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule CommunicationFuncBubbleSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaCommunicationFuncBubble]',
  template: '<svg:path fill="none" stroke-miterlimit="10" d="M18.72 15.84c1.28-1.28 2.08-2.88 2.08-4.64 0-4-3.92-7.2-8.8-7.2s-8.8 3.2-8.8 7.2 3.92 7.2 8.8 7.2c.88 0 1.68-.08 2.48-.32L19.2 20l-.48-4.16z" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunicationFuncBubbleSvgComponent {
}

@NgModule({
  declarations: [
    CommunicationFuncBubbleSvgComponent,
  ],
  exports: [
    CommunicationFuncBubbleSvgComponent,
  ],
})
export class CommunicationFuncBubbleSvgModule {}
