import {
  AtlasInvalidCharactersValidationErrors,
  AtlasInvalidLengthValidationErrors,
  combineResults,
  isValid,
  RESULT_VALID,
  ValidationErrors,
  ValidatorInput,
  ValidValidationResult,
} from '../base/validators';

import {CardNumber} from './businesstype';
import {CARD_NUMBER_MAX_LENGTH, CARD_NUMBER_MIN_LENGTH} from './constants';

/**
 * The BaseCardNumberValidators object contains partial card number
 * validators returning information on the invalidity instead of simply returning a boolean
 * valid/invalid result.
 *
 * @deprecated Use `validateCardNumber` instead
 */
export const BaseCardNumberValidators: {
  /**
   * Checks the validity of the given input's characters.
   *
   * @param value The card number to validate
   */
  readonly invalidCharacters: (
    value: ValidatorInput<CardNumber>,
  ) => AtlasInvalidCharactersValidationErrors | ValidValidationResult;

  /**
   * Checks the validity of the given input's length.
   *
   * @param value The card number to validate
   */
  readonly invalidLength: (
    value: ValidatorInput<CardNumber>,
  ) => AtlasInvalidLengthValidationErrors | ValidValidationResult;
} = {
  invalidCharacters: validateCharacters,

  invalidLength: validateLength,
};

/**
 * Checks if the card number only contains the correct characters (decimals).
 *
 * @param cardNumber The card number to validate
 * @returns True in case the card number characters are correct
 */
function validateCharacters(
  value: ValidatorInput<CardNumber>,
): AtlasInvalidCharactersValidationErrors | ValidValidationResult {
  if (CardNumber.isCardNumber(value)) {
    return RESULT_VALID;
  }

  if (!value || /^\d*$/.test(value)) {
    return RESULT_VALID;
  }

  return {
    atlasInvalidCharacters: true,
  };
}

/**
 * Performs the length check on the given card number.
 *
 * @param cardNumber The card number to validate
 */
function validateLength(
  value: ValidatorInput<CardNumber>,
): AtlasInvalidLengthValidationErrors | ValidValidationResult {
  if (CardNumber.isCardNumber(value)) {
    return RESULT_VALID;
  }

  if (!!value && value.length >= CARD_NUMBER_MIN_LENGTH && value.length <= CARD_NUMBER_MAX_LENGTH) {
    return RESULT_VALID;
  }

  return {
    atlasInvalidLength: {
      requiredLength: CARD_NUMBER_MIN_LENGTH,
      actualLength: value ? value.length : 0,
    },
  };
}

/**
 * Checks whether the card number is valid or not.
 *
 * @param input The card number to validate
 */
export function isValidCardNumber(input: ValidatorInput<CardNumber>): boolean {
  return isValid(validateCardNumber(input));
}

/**
 * Validate the given card number input
 *
 * @param input The card number to validate
 */
export function validateCardNumber(
  input: ValidatorInput<CardNumber>,
): ValidationErrors | ValidValidationResult {
  if (CardNumber.isCardNumber(input)) {
    return RESULT_VALID;
  }

  return combineResults(validateLength(input), validateCharacters(input));
}
