export interface HiddenTypedJson {
  /**
   * The type.
   */
  T: 'hidden';

  /**
   * The encrypted value.
   */
  E: string;
}
export interface VisibleTypedJson<S extends string> {
  /**
   * The type.
   */
  T: S;

  /**
   * The value.
   */
  V: string;

  /**
   * The encrypted value, if any.
   */
  E?: string;
}

/**
 * Concrete subtypes describe a single businesstype type in a serializable JS object.
 */
export type TypedJSONBase<S extends string> = S extends 'hidden'
  ? HiddenTypedJson
  : VisibleTypedJson<S>;

/**
 * Type describing businesstypes in a format that can be serialized into JSON and
 * sent somewhere else.
 */
export type TypedJSON = HiddenTypedJson | VisibleTypedJson<string>;

/**
 * Returns whether the given value is typed JSON or not.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function isTypedJSON(value: object): value is TypedJSON {
  const typedJSONValue = value as TypedJSON;

  return typeof typedJSONValue.T === 'string';
}
