/*
 * This file contains the basic PhoneNumber validators
 * to check whether a phone number is actually a valid phone number.
 */

import {
  AtlasInvalidCharactersValidationErrors,
  AtlasMinLengthValidationErrors,
  combineResults,
  isValid,
  RESULT_VALID,
  ValidationErrors,
  ValidatorInput,
  ValidValidationResult,
} from '../base/validators';

import {PhoneNumber} from './businesstype';

const MINIMUN_PHONE_NUMBER_LENGTH = 2;

/**
 * Checks the validity of the given input's characters.
 *
 * @param value The phone number to validate
 */
function invalidCharacters(
  value: ValidatorInput<PhoneNumber>,
): AtlasInvalidCharactersValidationErrors | ValidValidationResult {
  if (PhoneNumber.isPhoneNumber(value)) {
    return RESULT_VALID;
  }

  if (!value || /^\+\d+$/.test(value)) {
    return RESULT_VALID;
  } else {
    return {
      atlasInvalidCharacters: true,
    };
  }
}

/**
 * Checks the validity of the given input's length.
 *
 * @param value The phone number to validate
 */
function invalidLength(
  value: ValidatorInput<PhoneNumber>,
): AtlasMinLengthValidationErrors | ValidValidationResult {
  if (PhoneNumber.isPhoneNumber(value)) {
    return RESULT_VALID;
  }

  if (value == null) {
    return {
      atlasMinLength: {limit: MINIMUN_PHONE_NUMBER_LENGTH, actualValue: 0},
    };
  }

  const {length} = value;
  if (length < MINIMUN_PHONE_NUMBER_LENGTH) {
    return {
      atlasMinLength: {
        limit: MINIMUN_PHONE_NUMBER_LENGTH,
        actualValue: length,
      },
    };
  }

  return RESULT_VALID;
}

/**
 * The BasePhoneNumberValidators object contains partial phone number validators
 * for country independent length, returning information on the invalidity instead of
 * simply returning a boolean valid/invalid result.
 *
 * @deprecated Use `validatePhoneNumber` instead
 */
export const BasePhoneNumberValidators: {
  /**
   * Checks the validity of the given input's characters.
   *
   * @param value The phone number to validate
   */
  readonly invalidCharacters: (
    value: ValidatorInput<PhoneNumber>,
  ) => AtlasInvalidCharactersValidationErrors | ValidValidationResult;
  /**
   * Checks the validity of the given input's length.
   *
   * @param value The phone number to validate
   */
  readonly invalidLength: (
    value: ValidatorInput<PhoneNumber>,
  ) => AtlasMinLengthValidationErrors | ValidValidationResult;
} = {
  invalidCharacters,
  invalidLength,
};

/**
 * Checks whether the phone number input is valid or not.
 *
 * @param input The phone number input to validate
 */
export function isValidPhoneNumber(input: ValidatorInput<PhoneNumber>): boolean {
  return isValid(validatePhoneNumber(input));
}

/**
 * Validate the given phone number input
 *
 * @param input The phone number to validate
 */
export function validatePhoneNumber(
  input: ValidatorInput<PhoneNumber>,
): ValidationErrors | ValidValidationResult {
  if (PhoneNumber.isPhoneNumber(input)) {
    return RESULT_VALID;
  }

  return combineResults(invalidCharacters(input), invalidLength(input));
}
