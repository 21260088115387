import {NgModule} from '@angular/core';

import { AvatarUtilPrivateSvgModule } from './@kdl/illustrations/avatar/avatar-util-private.component';
import { AlertExprExclamationCircleSvgModule } from './@kdl/icons/alert/alert-expr-exclamation-circle.component';
import { AlertExprExclamationTriangleSvgModule } from './@kdl/icons/alert/alert-expr-exclamation-triangle.component';
import { AlertFuncCheckCircleSvgModule } from './@kdl/icons/alert/alert-func-check-circle.component';
import { AlertFuncCheckSvgModule } from './@kdl/icons/alert/alert-func-check.component';
import { AlertFuncClearSvgModule } from './@kdl/icons/alert/alert-func-clear.component';
import { AlertFuncExclamationCircleSvgModule } from './@kdl/icons/alert/alert-func-exclamation-circle.component';
import { AlertFuncExclamationTriangleSvgModule } from './@kdl/icons/alert/alert-func-exclamation-triangle.component';
import { AlertFuncInfoCircleSvgModule } from './@kdl/icons/alert/alert-func-info-circle.component';
import { NavigationFuncArrowCircleSvgModule } from './@kdl/icons/navigation/navigation-func-arrow-circle.component';
import { NavigationFuncArrowSvgModule } from './@kdl/icons/navigation/navigation-func-arrow.component';
import { NavigationFuncChevronSvgModule } from './@kdl/icons/navigation/navigation-func-chevron.component';
import { NavigationFuncCrossSvgModule } from './@kdl/icons/navigation/navigation-func-cross.component';
import { NavigationFuncLiveHelpSvgModule } from './@kdl/icons/navigation/navigation-func-live-help.component';
import { NavigationFuncMenuSvgModule } from './@kdl/icons/navigation/navigation-func-menu.component';
import { NavigationFuncOutlineArrowSvgModule } from './@kdl/icons/navigation/navigation-func-outline-arrow.component';
import { FileFuncDocumentSignedSvgModule } from './@kdl/icons/file/file-func-document-signed.component';
import { FileFuncDocumentSvgModule } from './@kdl/icons/file/file-func-document.component';
import { CommunicationFuncBubbleSvgModule } from './@kdl/icons/communication/communication-func-bubble.component';
import { CommunicationFuncTagSvgModule } from './@kdl/icons/communication/communication-func-tag.component';
import { SocialFuncPersonSvgModule } from './@kdl/icons/social/social-func-person.component';
import { ActionFuncBinSvgModule } from './@kdl/icons/action/action-func-bin.component';
import { ActionFuncBulletSvgModule } from './@kdl/icons/action/action-func-bullet.component';
import { ActionFuncCalendarSvgModule } from './@kdl/icons/action/action-func-calendar.component';
import { ActionFuncClockSvgModule } from './@kdl/icons/action/action-func-clock.component';
import { ActionFuncCrossMiniSvgModule } from './@kdl/icons/action/action-func-cross-mini.component';
import { ActionFuncFilterCircleSvgModule } from './@kdl/icons/action/action-func-filter-circle.component';
import { ActionFuncPlusSvgModule } from './@kdl/icons/action/action-func-plus.component';
import { TransportationExprCarSvgModule } from './@kdl/icons/transportation/transportation-expr-car.component';
import { InformationExprSearchEmptyStateSvgModule } from './@kdl/icons/information/information-expr-search-empty-state.component';
import { InformationFuncCommunicationSvgModule } from './@kdl/icons/information/information-func-communication.component';
import { InformationFuncLightbulbSvgModule } from './@kdl/icons/information/information-func-lightbulb.component';
import { InformationFuncSearchSvgModule } from './@kdl/icons/information/information-func-search.component';
import { InformationFuncTodoSvgModule } from './@kdl/icons/information/information-func-todo.component';
import { FormsStepperMinusSvgModule } from './@ivy/svg/svgs/forms-stepper-minus.component';
import { FormsStepperPlusSvgModule } from './@ivy/svg/svgs/forms-stepper-plus.component';
import { GeneralArrowPointerSvgModule } from './@ivy/svg/svgs/general-arrow-pointer.component';
import { GeneralChevronSvgModule } from './@ivy/svg/svgs/general-chevron.component';
import { GeneralDeleteCircleCrossSvgModule } from './@ivy/svg/svgs/general-delete-circle-cross.component';
import { GeneralDeleteRubbishSvgModule } from './@ivy/svg/svgs/general-delete-rubbish.component';
import { GeneralInfoSvgModule } from './@ivy/svg/svgs/general-info.component';
import { GeneralSearchSvgModule } from './@ivy/svg/svgs/general-search.component';
import { QuickSearchDetailedSearchSvgModule } from './@ivy/svg/svgs/quick-search-detailed-search.component';
import { QuickSearchPriceTagSvgModule } from './@ivy/svg/svgs/quick-search-price-tag.component';
import { SystemInfoSvgModule } from './@ivy/svg/svgs/system-info.component';

@NgModule({
  exports: [
    NavigationFuncChevronSvgModule,
  ],
})
export class μAdditionalInformationSvgModule {
}
@NgModule({
  exports: [
    ActionFuncCalendarSvgModule,
    CommunicationFuncBubbleSvgModule,
    CommunicationFuncTagSvgModule,
    InformationFuncCommunicationSvgModule,
    InformationFuncSearchSvgModule,
    GeneralInfoSvgModule,
    GeneralSearchSvgModule,
    NavigationFuncCrossSvgModule,
    SocialFuncPersonSvgModule,
    QuickSearchDetailedSearchSvgModule,
    QuickSearchPriceTagSvgModule,
  ],
})
export class μAdvancedSearchSvgModule {
}
@NgModule({
  exports: [
    ActionFuncBinSvgModule,
    NavigationFuncArrowCircleSvgModule,
  ],
})
export class μBeamSvgModule {
}
@NgModule({
  exports: [
    ActionFuncCrossMiniSvgModule,
  ],
})
export class μChartBuilderSvgModule {
}
@NgModule({
  exports: [
    AlertFuncCheckSvgModule,
  ],
})
export class μCircleStepSvgModule {
}
@NgModule({
  exports: [
    ActionFuncCalendarSvgModule,
    GeneralChevronSvgModule,
    GeneralDeleteCircleCrossSvgModule,
    NavigationFuncCrossSvgModule,
  ],
})
export class μDatepickersSvgModule {
}
@NgModule({
  exports: [
    ActionFuncCrossMiniSvgModule,
  ],
})
export class μDisplaysSvgModule {
}
@NgModule({
  exports: [
    FileFuncDocumentSvgModule,
    FileFuncDocumentSignedSvgModule,
  ],
})
export class μDocumentLinkSvgModule {
}
@NgModule({
  exports: [
    NavigationFuncChevronSvgModule,
  ],
})
export class μDropdownListsSvgModule {
}
@NgModule({
  exports: [
    ActionFuncPlusSvgModule,
  ],
})
export class μEcommerceSvgModule {
}
@NgModule({
  exports: [
    InformationExprSearchEmptyStateSvgModule,
  ],
})
export class μEmptyStateSvgModule {
}
@NgModule({
  exports: [
    AlertFuncExclamationTriangleSvgModule,
    AlertExprExclamationCircleSvgModule,
  ],
})
export class μFormsSvgModule {
}
@NgModule({
  exports: [
    ActionFuncFilterCircleSvgModule,
  ],
})
export class μFiltersSvgModule {
}
@NgModule({
  exports: [
    AlertFuncCheckSvgModule,
    NavigationFuncChevronSvgModule,
    NavigationFuncMenuSvgModule,
  ],
})
export class μFlowProgressSvgModule {
}
@NgModule({
  exports: [
    InformationFuncLightbulbSvgModule,
  ],
})
export class μInformationBlockSvgModule {
}
@NgModule({
  exports: [
    AlertFuncCheckCircleSvgModule,
    AlertFuncExclamationCircleSvgModule,
    AlertFuncExclamationTriangleSvgModule,
    AlertExprExclamationTriangleSvgModule,
    AlertFuncInfoCircleSvgModule,
  ],
})
export class μInlineMessagesSvgModule {
}
@NgModule({
  exports: [
    FormsStepperMinusSvgModule,
    FormsStepperPlusSvgModule,
  ],
})
export class μInputStepperSvgModule {
}
@NgModule({
  exports: [
    AlertFuncCheckSvgModule,
    AlertFuncClearSvgModule,
  ],
})
export class μKeypadSvgModule {
}
@NgModule({
  exports: [
    NavigationFuncCrossSvgModule,
  ],
})
export class μLayoutSvgModule {
}
@NgModule({
  exports: [
    NavigationFuncChevronSvgModule,
  ],
})
export class μListSvgModule {
}
@NgModule({
  exports: [
    AlertFuncCheckSvgModule,
    InformationFuncTodoSvgModule,
  ],
})
export class μNextStepsSvgModule {
}
@NgModule({
  exports: [
    NavigationFuncArrowSvgModule,
  ],
})
export class μOscarPullToNavigateSvgModule {
}
@NgModule({
  exports: [
    NavigationFuncArrowSvgModule,
  ],
})
export class μOscarRouterOutletSvgModule {
}
@NgModule({
  exports: [
    NavigationFuncOutlineArrowSvgModule,
  ],
})
export class μPaginationSvgModule {
}
@NgModule({
  exports: [
    ActionFuncCrossMiniSvgModule,
  ],
})
export class μPanelsSvgModule {
}
@NgModule({
  exports: [
    GeneralArrowPointerSvgModule,
  ],
})
export class μScrollIndicatorSvgModule {
}
@NgModule({
  exports: [
    NavigationFuncCrossSvgModule,
    InformationFuncSearchSvgModule,
  ],
})
export class μSearchBarSvgModule {
}
@NgModule({
  exports: [
    NavigationFuncCrossSvgModule,
    InformationFuncSearchSvgModule,
  ],
})
export class μSearchAutocompleteSvgModule {
}
@NgModule({
  exports: [
    NavigationFuncCrossSvgModule,
    NavigationFuncLiveHelpSvgModule,
  ],
})
export class μSlideInsSvgModule {
}
@NgModule({
  exports: [
    AlertFuncCheckSvgModule,
  ],
})
export class μSwitchesSvgModule {
}
@NgModule({
  exports: [
    AlertFuncCheckCircleSvgModule,
    AlertFuncExclamationCircleSvgModule,
    AlertFuncExclamationTriangleSvgModule,
    AlertFuncInfoCircleSvgModule,
    ActionFuncCrossMiniSvgModule,
  ],
})
export class μSystemMessagesSvgModule {
}
@NgModule({
  exports: [
    ActionFuncBinSvgModule,
    NavigationFuncArrowSvgModule,
    NavigationFuncMenuSvgModule,
    NavigationFuncChevronSvgModule,
  ],
})
export class μTablesSvgModule {
}
@NgModule({
  exports: [
    NavigationFuncCrossSvgModule,
  ],
})
export class μTagsSvgModule {
}
@NgModule({
  exports: [
    AlertFuncCheckSvgModule,
    ActionFuncBulletSvgModule,
  ],
})
export class μTextListSvgModule {
}
@NgModule({
  exports: [
    SystemInfoSvgModule,
    GeneralDeleteRubbishSvgModule,
    AlertFuncCheckSvgModule,
    TransportationExprCarSvgModule,
    AvatarUtilPrivateSvgModule,
    ActionFuncCrossMiniSvgModule,
  ],
})
export class μTilesSvgModule {
}
@NgModule({
  exports: [
    ActionFuncClockSvgModule,
  ],
})
export class μTimepickersSvgModule {
}
