import {EventEmitter, Injectable} from '@angular/core';
import {ProductionLoggerListener} from '@atlas/logger';
import {Observable} from 'rxjs';

/**
 * A message sent to a logger.
 */
export interface LoggerEvent {
  /**
   * The name of the logger that sent the message.
   */
  loggerName: string;

  /**
   * The message itself. The array has at least one item.
   */
  message: any[];
}

@Injectable({providedIn: 'root'})
export class LoggerEventsServiceImplementation
  implements LoggerEventsService, ProductionLoggerListener {
  public readonly warnings = new EventEmitter<LoggerEvent>();
  public readonly errors = new EventEmitter<LoggerEvent>();

  public onWarning(loggerName: string, message: any[]): void {
    this.warnings.emit({loggerName, message});
  }

  public onError(loggerName: string, message: any[]): void {
    this.errors.emit({loggerName, message});
  }
}

/**
 * The LoggerEventsService grants access to the LoggerEvents created by all loggers.
 */
@Injectable({
  providedIn: 'root',
  useExisting: LoggerEventsServiceImplementation,
})
export abstract class LoggerEventsService {
  /**
   * The warning LoggerEvents created by loggers.
   */
  public abstract readonly warnings: Observable<LoggerEvent>;

  /**
   * The error LoggerEvents created by loggers.
   */
  public abstract readonly errors: Observable<LoggerEvent>;
}
