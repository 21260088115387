import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Boolean} from '@atlas/businesstypes';

import {CheckboxComponent} from '../checkbox/checkbox.component';

/**
 * @ngModule SwitchesModule
 */
@Component({
  selector: 'maia-collapsible-checkbox',
  templateUrl: './collapsible-checkbox.component.html',
  styleUrls: ['./collapsible-checkbox.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class CollapsibleCheckboxComponent implements AfterContentInit {
  @ContentChild(CheckboxComponent, {static: false})
  public checkbox: CheckboxComponent;

  public checked = Boolean.FALSE;

  public constructor(private readonly cdr: ChangeDetectorRef) {}

  public ngAfterContentInit(): void {
    if (!this.checkbox) {
      throw new Error('maia-collapsible-checkbox should contain a maia-checkbox component.');
    }

    if (this.checkbox.checked) {
      this.checked = Boolean.TRUE;
      this.cdr.detectChanges();
    }

    this.checkbox.onValueChange.pipe(takeUntilDestroyed(this)).subscribe((v: Boolean) => {
      this.checked = v;
      this.cdr.detectChanges();
    });
  }
}
