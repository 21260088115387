import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Boolean} from '@atlas/businesstypes';
import {Utilities} from '@maia/core';
import {FormElementComponent} from '@maia/forms';

const generateLabelId = Utilities.createIdGenerator('maia-checkbox__label');

export const DEFAULT_CHECKBOX_VALUE = false;

export const DEFAULT_CHECKBOX_DISABLED_VALUE = false;

const DEFAULT_CHECKBOX_HIDDEN_LABEL = false;

/**
 * @ngModule SwitchesModule
 */
@Component({
  selector: 'maia-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => CheckboxComponent)},
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    role: 'checkbox',
    tabindex: '0',
  },
})
export class CheckboxComponent implements AfterContentChecked, ControlValueAccessor {
  @Output()
  public onValueChange = new EventEmitter<Boolean>();

  private _checked = DEFAULT_CHECKBOX_VALUE;

  private _disabled = DEFAULT_CHECKBOX_DISABLED_VALUE;

  @ViewChild('checkboxLabel', {static: true})
  public checkboxLabel: ElementRef;

  @HostBinding('class.maia-checkbox--alone')
  public _hiddenLabel = DEFAULT_CHECKBOX_HIDDEN_LABEL;

  @HostBinding('attr.aria-labelledby')
  public readonly labelId = generateLabelId();

  public constructor(
    private readonly _cdr: ChangeDetectorRef,
    @Optional() parent?: FormElementComponent,
  ) {
    if (parent != null) {
      parent.hideOptionalIndicator = true;
    }
  }

  private _onChange = (_: any) => {};

  @HostListener('blur')
  public _onTouched = () => {};

  @HostListener('keydown.space', ['$event'])
  public _handleKeyboardEvent(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();
    this._toggleChecked();
  }

  @HostListener('click', ['$event'])
  public _toggleChecked(event?: MouseEvent) {
    if ((!event || this._isNotALink(event)) && !this.disabled) {
      this._setChecked(!this._checked);
    }
  }

  private _isNotALink(event: MouseEvent): boolean {
    const element = event.target as HTMLElement;
    return !element || element.tagName.toLowerCase() !== 'a';
  }

  private _setChecked(value: boolean): void {
    this.writeValue(value);
    const booleanValue = new Boolean(value);
    this._onChange(booleanValue);
    this.onValueChange.emit(booleanValue);
  }

  @HostBinding('class.maia-checkbox--checked')
  @HostBinding('attr.aria-checked')
  public get checked(): boolean {
    return this._checked;
  }

  @HostBinding('class.maia-checkbox--disabled')
  @HostBinding('attr.aria-disabled')
  public get disabled(): boolean {
    return this._disabled;
  }

  public writeValue(value?: Boolean | string | boolean): void {
    // Don't use _setChecked here because that would trigger onTouched
    this._checked =
      value == null
        ? false
        : typeof value === 'string'
        ? value === 'true'
        : Boolean.isBoolean(value)
        ? value.asBoolean()
        : value;
    this._cdr.markForCheck();
  }

  public ngAfterContentChecked() {
    const elementText = (this.checkboxLabel.nativeElement as HTMLElement).innerHTML.trim();
    this._hiddenLabel = elementText ? elementText.length === 0 : true;
  }

  public registerOnChange(fn: (_: Boolean | null) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }
}
