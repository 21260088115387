import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule AvatarUtilPrivateSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaAvatarUtilPrivate]',
  template: '<svg:path fill="#00AEEF" d="M0 0h160v160H0z"></svg:path><svg:path opacity=".16" fill="#FFFFFF" d="M160 35.8v118.8l-5.4 5.4H35.9l19.5-19.6L65.8 130l1.6-1.6L126.9 69z"></svg:path><svg:path opacity=".16" fill="#FFFFFF" d="M160 101v59h-59.9l-32.7-31.6-1.2-1.2-20.1-19.4L0 63.2V0h55.5l25.2 24.4L126.9 69l24.9 24.1z"></svg:path><svg:path opacity=".16" fill="#FFFFFF" d="M0 160V0h15.1l31 107.8 9.3 32.6 5.7 19.6z"></svg:path><svg:path opacity=".16" fill="#FFFFFF" d="M84.1 0l-3.4 24.4-14.5 102.8-.4 2.8-4.2 30H0V23.9L3.4 0z"></svg:path><svg:path opacity=".16" fill="#FFFFFF" d="M160 64.4V160h-27.4l19.2-66.9z"></svg:path>',

  styles: [
  ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 160 160',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarUtilPrivateSvgComponent {
}

@NgModule({
  declarations: [
    AvatarUtilPrivateSvgComponent,
  ],
  exports: [
    AvatarUtilPrivateSvgComponent,
  ],
})
export class AvatarUtilPrivateSvgModule {}
