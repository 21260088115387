import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {μCircleStepSvgModule} from '@maia/icons';

import {CircleStepDisplayModule} from '../circle-step-display/circle-step-display.module';
import {FlowChapter2GroupComponent} from './flow-chapter2-group.component';
import {FlowChapter2Component} from './flow-chapter2/flow-chapter2.component';

@NgModule({
  declarations: [FlowChapter2Component, FlowChapter2GroupComponent],
  imports: [CommonModule, CircleStepDisplayModule, μCircleStepSvgModule],
  exports: [FlowChapter2Component, FlowChapter2GroupComponent],
})
export class FlowChapter2Module {}
