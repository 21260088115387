import {Directive, forwardRef, Inject} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {EmailAddress, EmailAddressFormatter, isValidEmailAddress} from '@atlas/businesstypes';

import {
  ATLAS_VALUE_ACCESSOR,
  AtlasControlValueAccessor,
  BaseBusinesstypeDirective,
} from '../base/index';

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasEmailAddress]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EmailAddressDirective),
    },
  ],
})
export class EmailAddressDirective extends BaseBusinesstypeDirective {
  public constructor(@Inject(ATLAS_VALUE_ACCESSOR) input: AtlasControlValueAccessor) {
    super(input);
  }

  public writeValue(value?: EmailAddress | string): void {
    this._input.writeValue(
      EmailAddress.isEmailAddress(value)
        ? EmailAddressFormatter.format(value)
        : value == null
        ? ''
        : value,
    );
  }

  public registerOnChange(fn: (_: EmailAddress | string | null) => void): void {
    this._input.registerOnChange(value => {
      if (value === '') {
        fn(null);
        return;
      }

      const unformatted = EmailAddressFormatter.unformat(value);
      fn(isValidEmailAddress(unformatted) ? new EmailAddress(unformatted) : unformatted);
    });
  }
}
