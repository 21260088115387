import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {ConfigurationExtractor, ExtractableConfiguration} from '@hermes/configuration';
import {filter, map} from 'rxjs/operators';

import {TransactionService} from './transaction.service';

const mapToLeafRoute = map(
  (route: ActivatedRouteSnapshot): ActivatedRouteSnapshot => {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  },
);

const findTitle = map<ActivatedRouteSnapshot | null, ExtractableConfiguration<string> | undefined>(
  route => {
    while (route != null && !('title' in route.data)) {
      route = route.parent;
    }

    if (route != null) {
      return route.data.title;
    }

    return undefined;
  },
);

@Injectable()
export class TransactionRouteHandler {
  public constructor(
    private readonly transactionService: TransactionService,
    private readonly configurationExtractor: ConfigurationExtractor,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  public handleTitleRouterEvents() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute.snapshot),
        mapToLeafRoute,
        filter(route => route.outlet === 'primary'),
        findTitle,
      )
      .subscribe(title => {
        this.transactionService.title =
          title != null ? this.configurationExtractor.extract(title) : '';
      });
  }
}
