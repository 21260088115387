import {ElementRef} from '@angular/core';
import {onDestroy} from '@atlas-angular/rxjs';

type FocusableElement = Element & HTMLOrSVGElement;

/**
 * Capture focus into the modal and restore it when the modal closes
 *
 * Only acts if focus needs to move, e.g. if the focus has already moved outside of the modal after
 * closing it, the focus will not be moved back to the element that had focus.
 *
 * @param destroyable The modal instance
 * @param document The document
 * @param modalElement The modal element
 */
export function captureFocus(
  destroyable: any,
  document: Document,
  modalElement: ElementRef<FocusableElement>,
) {
  const previousFocusedElement = document.activeElement;

  function isInModal(element: Element) {
    return modalElement.nativeElement.contains(element);
  }

  if (previousFocusedElement != null && isInModal(previousFocusedElement)) {
    // The focused element is already part of the element we need to focus, so assume that's fine
    return;
  }

  modalElement.nativeElement.focus();

  if (previousFocusedElement != null) {
    onDestroy(destroyable).subscribe(() => {
      const currentFocusedElement = document.activeElement;
      if (currentFocusedElement != null && !isInModal(currentFocusedElement)) {
        // Focus has already moved out of the modal, keep it where it is
        return;
      }

      (previousFocusedElement as FocusableElement).focus();
    });
  }
}
