import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  forwardRef,
  HostBinding,
  Input,
  Optional,
  QueryList,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {FormElementComponent, InputContainer} from '@maia/forms';

import {GroupComponent} from '../group.component';
import {RadioButtonComponent} from '../radio-button/radio-button.component';
import {DEFAULT_RADIO_GROUP_HORIZONTAL} from '../utils';

/**
 * @ngModule RadioButtonsModule
 */
@Component({
  selector: 'maia-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioGroupComponent), multi: true},
  ],
  host: {role: 'radiogroup', tabIndex: '0'},
})
export class RadioGroupComponent extends GroupComponent {
  @ContentChildren(forwardRef(() => RadioButtonComponent))
  protected queryList: QueryList<RadioButtonComponent>;

  private _horizontal: boolean;

  @Input()
  @HostBinding('class.maia-radio-group--horizontal')
  @HostBinding('class.p-maia-radio-group--horizontal')
  public get horizontal(): boolean {
    return this._horizontal;
  }

  public set horizontal(horizontal: boolean) {
    this._horizontal = coerceBooleanProperty(horizontal);
  }

  public set selectedValue(selectedValue: any) {
    this.writeValue(selectedValue);
    this.onChange(selectedValue);
  }

  public constructor(
    @Optional() parent?: FormElementComponent,
    @Optional() container?: InputContainer,
  ) {
    super();
    this.horizontal = DEFAULT_RADIO_GROUP_HORIZONTAL;
    if (parent != null) {
      parent.extraPadding = true;
    }
    if (container != null && this.queryList != null) {
      container.disabled$.subscribe(isDisabled => {
        this.queryList.forEach(
          radioButtonComponent => (radioButtonComponent.forceDisabled = isDisabled),
        );
      });
    }
  }
}
