import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';
import {IntersectionsModule as MaiaIntersectionsModule} from '@maia/intersections';

import {AmountDisplayAnimatedComponent} from './amount-display-animated/amount-display-animated.component';
import {NumberDisplayAnimatedComponent} from './number-display-animated/number-display-animated.component';
import {PercentageDisplayAnimatedComponent} from './percentage-display-animated/percentage-display-animated.component';

@NgModule({
  imports: [BusinessTypesModule, CommonModule, MaiaIntersectionsModule],
  declarations: [
    NumberDisplayAnimatedComponent,
    AmountDisplayAnimatedComponent,
    PercentageDisplayAnimatedComponent,
  ],
  exports: [
    NumberDisplayAnimatedComponent,
    AmountDisplayAnimatedComponent,
    PercentageDisplayAnimatedComponent,
  ],
})
export class AnimatedDisplaysModule {}
