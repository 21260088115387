import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule AlertExprExclamationCircleSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaAlertExprExclamationCircle]',
  template: '<svg:circle cx="24" cy="33.08" r=".83"></svg:circle><svg:circle cx="24" cy="24" r="18.17" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:circle><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M24 14.09V27.3"></svg:path><svg:circle cx="24" cy="33.08" r=".83" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:circle>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertExprExclamationCircleSvgComponent {
}

@NgModule({
  declarations: [
    AlertExprExclamationCircleSvgComponent,
  ],
  exports: [
    AlertExprExclamationCircleSvgComponent,
  ],
})
export class AlertExprExclamationCircleSvgModule {}
