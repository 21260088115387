import {Pipe, PipeTransform} from '@angular/core';
import {Bban, BbanFormatter} from '@atlas/businesstypes';

/**
 * @ngModule BusinessTypesModule
 */
@Pipe({name: 'atlasBban'})
export class BbanPipe implements PipeTransform {
  public transform(value: any): string {
    return Bban.isBban(value) ? BbanFormatter.format(value) : value;
  }
}
