import {Directive, ElementRef, forwardRef, Renderer2} from '@angular/core';

import {CapturedInput} from './captured-input';

export const DISABLED_CLASS = 'maia-fake-input--disabled';

/**
 * @ngModule FormsCaptureModule
 */
@Directive({
  selector: 'maia-fake-input:not([ngModel]):not([formControl]):not([formControlName])',
  providers: [{provide: CapturedInput, useExisting: forwardRef(() => CapturedFakeInputDirective)}],
})
export class CapturedFakeInputDirective implements CapturedInput {
  public readonly element: HTMLElement;

  public constructor(elementRef: ElementRef<HTMLElement>, private readonly _renderer: Renderer2) {
    this.element = elementRef.nativeElement;
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this._renderer.addClass(this.element, DISABLED_CLASS);
    } else {
      this._renderer.removeClass(this.element, DISABLED_CLASS);
    }
  }
}
