import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {μPanelsSvgModule} from '@maia/icons';

import {PanelTitleExtraActionComponent} from './panel-title-extra-action/panel-title-extra-action.component';
import {PanelTitleIconComponent} from './panel-title-icon/panel-title-icon.component';
import {PanelTitleComponent} from './panel-title/panel-title.component';
import {PanelComponent} from './panel/panel.component';

@NgModule({
  imports: [CommonModule, μPanelsSvgModule],
  declarations: [
    PanelComponent,
    PanelTitleComponent,
    PanelTitleIconComponent,
    PanelTitleExtraActionComponent,
  ],
  exports: [
    PanelComponent,
    PanelTitleComponent,
    PanelTitleIconComponent,
    PanelTitleExtraActionComponent,
  ],
})
export class PanelsModule {}
