import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {AvatarComponent} from '@maia/avatars';

// Keep in sync with $icon-size in the SCSS file
const avatarSize = 48; /* px */

/**
 * Action tile component.
 *
 * @ngModule ActionTileModule
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[maiaActionTile], div[maiaActionTile]',
  templateUrl: './action-tile.component.html',
  styleUrls: ['./action-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[attr.disabled]': 'disabled ? "" : null'},
})
export class ActionTileComponent implements AfterContentInit {
  private _disabled = false;

  /**
   * The tile sub label.
   */
  @HostBinding('class.maia-label-container--with-sub-label')
  public hasSubLabel = false;

  @ViewChild('subLabel', {static: true})
  private readonly subLabel: ElementRef<HTMLElement>;

  @Input()
  public withBadge: boolean;

  /**
   * The tile selected.
   */
  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('class.maia-action-tile--selected')
  @HostBinding('class.p-maia-action-tile--selected')
  public selected = false;

  @ContentChild(AvatarComponent, {static: false})
  public set avatar(avatar: AvatarComponent | null | undefined) {
    if (avatar != null) {
      avatar.avatarSize = avatarSize;
    }
  }

  public ngAfterContentInit(): void {
    this.hasSubLabel = this.subLabel.nativeElement.childNodes.length > 0;
  }

  /**
   * The tile disabled.
   */
  @Input()
  @HostBinding('attr.aria-disabled')
  public get disabled(): boolean {
    return this._disabled;
  }

  public set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
  }
}
