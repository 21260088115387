import {inject, Injectable, Injector} from '@angular/core';

export type SuccessCallback = (result: any) => any;
export type ErrorCallback = (err: any) => any;

/**
 * This bridge service provides the means to communicate with the JavaFX wrapper that the Angular application
 * runs in on the MNC device. If the device is not MNC, then trying to use this service will report an error
 * (as no actual MncBridge implementation will be found).
 */
@Injectable({
  providedIn: 'root',
  useFactory: mncBridgeServiceFactory,
})
export class MncBridgeService {
  public constructor(private readonly bridgeInjector: εBridgeInjector) {}

  public exec(
    service: string,
    action: string,
    args?: any[],
    successCallback?: SuccessCallback,
    errorCallback?: ErrorCallback,
  ): void {
    // Get the actual MncBridge implementation and delegate
    const mncBridge = this.getMncBridgeImplementation();
    if (mncBridge !== null) {
      mncBridge.exec(service, action, args, successCallback, errorCallback);
    }
  }

  private getMncBridgeImplementation(): MncBridge | null {
    return this.bridgeInjector.get();
  }
}

/**
 * Interface for the actual bridge implementation.
 */
export interface MncBridge {
  exec(
    service: string,
    action: string,
    args?: any[],
    successCallback?: SuccessCallback,
    errorCallback?: ErrorCallback,
  ): void;
}

/**
 * Creates the bridge service, with the injector that will retrieve the bridge implementation if MNC is loaded.
 */
export function mncBridgeServiceFactory(): MncBridgeService {
  return new MncBridgeService(inject(εBridgeInjector));
}

/**
 * This injector keeps track of the MncBridge implementation, which is created and set by the lazy loaded MncModule.
 */
@Injectable({
  providedIn: 'root',
})
export class εBridgeInjector implements Injector {
  private bridge: MncBridge | null;

  public get(notFoundValue?: any): MncBridge | null {
    if (this.bridge !== undefined) {
      return this.bridge;
    }
    if (notFoundValue === undefined || notFoundValue === Injector.THROW_IF_NOT_FOUND) {
      throw new Error('MncBridge not found, are you sure the MNC platform was loaded?');
    }
    return notFoundValue;
  }

  public set(value: MncBridge | null) {
    this.bridge = value;
  }
}
