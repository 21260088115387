import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';
import {μSystemMessagesSvgModule} from '@maia/icons';
import {LayoutModule} from '@maia/layout';
import {MiniButtonsModule} from '@maia/mini-buttons';
import {SystemMessagesModule as NewSystemMessagesModule} from '@maia/system-messages/toasts';

import {SystemMessageComponent} from './system-message.component';
import {SystemMessageScrollDirective} from './system-message.directive';

@NgModule({
  imports: [
    BusinessTypesModule,
    CommonModule,
    LayoutModule,
    MiniButtonsModule,
    μSystemMessagesSvgModule,
    NewSystemMessagesModule,
  ],
  declarations: [SystemMessageComponent, SystemMessageScrollDirective],
  exports: [SystemMessageComponent, SystemMessageScrollDirective],
})
export class SystemMessagesModule {}
