import {Directive, HostListener} from '@angular/core';
import {NgControl} from '@angular/forms';
import {Decimal, DecimalFormatter} from '@atlas/businesstypes';

/**
 * Input number component.
 *
 * @ngModule FormsModule
 */
@Directive({
  selector:
    'input[maiaInputNumber][ngModel], input[maiaInputNumber][formControl], input[maiaInputNumber][formControlName]',
})
export class InputNumberComponentWithNgControlDirective {
  public constructor(private readonly _control: NgControl) {}

  @HostListener('blur')
  public blur(): void {
    const value = this._control!.value;
    if (Decimal.isDecimal(value)) {
      const formattedValue = DecimalFormatter.formatDecimal(value, -1);
      this._setValue(formattedValue);
    }
  }

  /**
   * Passes the given value on to the input element
   *
   * This method doesn't modify this component's value.
   *
   * @param value The new value
   */
  private _setValue(value: string): void {
    // istanbul ignore if: unsure how to test this, but absence of this if causes problems
    if (this._control.value == null) {
      return;
    }
    this._control.valueAccessor!.writeValue(value);
  }
}
