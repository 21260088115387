import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SignerSignSvgModule
 */
@Component({
  selector: 'svg[ivyHermesSignerSign]',
  template: '<svg:path d="M475.4 125.4L70 150.5c-22.5 1-44.5 21.5-47.6 44L.4 348.1c-3.1 23 13.3 41 36.3 38.4l405.9-35.8c23-2.1 44.5-22.6 48.1-45.6l21-140.8c2.6-22.5-13.3-40.4-36.3-38.9zM183.9 290.6c-5.1 3.9-12 5.9-20.8 5.9-5.8 0-11.1-1.1-15.9-3.2-4.8-2.1-8.6-5.1-11.3-8.9s-4-8.2-4-13.2h13.9c0 4.5 1.5 8 4.5 10.5s7.3 3.7 12.9 3.7c4.8 0 8.5-1 10.9-2.9 2.4-1.9 3.6-4.5 3.6-7.7 0-3.5-1.2-6.2-3.7-8.1-2.5-1.9-6.9-3.8-13.4-5.8-6.4-1.9-11.6-4.1-15.4-6.5-7.3-4.6-10.9-10.5-10.9-17.9 0-6.4 2.6-11.7 7.9-15.9 5.3-4.2 12.1-6.3 20.4-6.3 5.6 0 10.5 1 14.9 3.1 4.4 2 7.8 5 10.3 8.8s3.7 8 3.7 12.6h-13.8c0-4.1-1.3-7.4-3.9-9.7-2.6-2.3-6.4-3.5-11.2-3.5-4.5 0-8.1 1-10.6 2.9s-3.8 4.6-3.8 8.1c0 2.9 1.4 5.3 4.1 7.3s7.2 3.8 13.4 5.7c6.2 1.8 11.2 3.9 15 6.3 3.8 2.4 6.5 5.1 8.3 8.1 1.8 3.1 2.6 6.6 2.6 10.7 0 6.7-2.6 12-7.7 15.9zm34.5 4.7h-13.8v-79.9h13.8v79.9zm78.3-10.3c-2.9 3.8-6.9 6.6-12 8.6-5.1 1.9-10.9 2.9-17.5 2.9-6.7 0-12.6-1.5-17.8-4.6-5.2-3-9.1-7.4-11.9-13-2.8-5.6-4.3-12.2-4.4-19.8v-6.3c0-12.1 2.9-21.5 8.7-28.3 5.8-6.8 13.8-10.2 24.2-10.2 8.9 0 16 2.2 21.2 6.6 5.2 4.4 8.4 10.7 9.4 19H283c-1.5-9.6-7.1-14.4-16.7-14.4-6.2 0-10.9 2.2-14.2 6.7s-4.9 11-5 19.7v6.1c0 8.6 1.8 15.3 5.5 20.1 3.6 4.8 8.7 7.1 15.2 7.1 7.1 0 12.1-1.6 15.1-4.8v-15.6h-16.5v-10.5h30.3V285zm78.7 10.3h-13.9l-35.6-56.7v56.7H312v-79.9h13.9l35.7 56.9v-56.9h13.8v79.9z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 512 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignerSignSvgComponent {
}

@NgModule({
  declarations: [
    SignerSignSvgComponent,
  ],
  exports: [
    SignerSignSvgComponent,
  ],
})
export class SignerSignSvgModule {}
