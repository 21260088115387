import {getInternalValue} from '../base/businesstype';
import {Formatter} from '../base/formatter';

import {Decimal} from './businesstype';

const INTEGER_PART_OFFSET = 3;

/* eslint-disable @typescript-eslint/interface-name-prefix */
export interface IDecimalFormatter extends Formatter<Decimal> {
  formatDecimal(
    value: Decimal,
    fractionDigits?: number,
    signSymbol?: string,
    thousandSeparator?: string,
  ): string;
}

export const DecimalFormatter: IDecimalFormatter = {
  /**
   * Returns the formatted string value of the decimal by adding ',' as
   * separator of the integer and fraction parts, positive/negative sign
   * and thousand separator.
   *
   * @param value The decimal to format
   * @param fractionDigits  The number of fraction digits that we want to display
   * @param signSymbol  The symbol we want to use to indicate a negative number ('-' by default)
   * @param thousandSeparator The thousand separator we want to use every three digits of the
   * integer part (space by default)
   */
  formatDecimal(
    value: Decimal,
    fractionDigits = 2,
    signSymbol = '-',
    thousandSeparator = ' ',
  ): string {
    let sign = '';
    let numberAsBig = getInternalValue(value);
    // If Big.js sign is negative
    if (numberAsBig.s === -1) {
      sign = signSymbol; // Set negative sign symbol
      numberAsBig = numberAsBig.abs();
    }
    // Set entire numberString to split it in integer and fraction parts
    const numberString =
      fractionDigits >= 0 ? numberAsBig.toFixed(fractionDigits) : numberAsBig.toString();
    const numberStringSplit = numberString.split('.');
    let [integerPart] = numberStringSplit;
    const [, fractionPart] = numberStringSplit;

    // Format integer part
    const result: string[] = [];
    for (let i = integerPart.length; i >= 0; i -= INTEGER_PART_OFFSET) {
      const part = integerPart.substring(i - INTEGER_PART_OFFSET, i);
      if (part !== '') {
        result.unshift(part);
      }
    }
    integerPart = result.join(thousandSeparator);
    return fractionPart ? `${sign}${integerPart},${fractionPart}` : `${sign}${integerPart}`;
  },

  /**
   * Returns the string value of the decimal. Specific format is done,
   * with 2 decimals and default sign and thousand separator.
   * (This method is written because its implementation is required
   * by the Formatter interface)
   *
   * @param value The decimal to format
   */
  format(value: Decimal): string {
    return this.formatDecimal(value);
  },

  /**
   * Returns the unformatted decimal by replacing ',' by '.' and removing
   * the thousand operators.
   *
   * @param value The pretty value
   */
  unformat(value: string): string {
    return value
      .split(' ')
      .join('')
      .replace(',', '.');
  },
};
