/**
 * This function looks for the parent of the element and checks if it is inside a modal
 *
 * @param element the current element
 */

export function findScrollingParent(element: Element): Element | null {
  const parent = element.parentElement;

  // If we reach an element without parent, we have been destroyed in
  // the mean time (e.g. because we run asynchronously via the
  // DomIoService).
  if (parent == null) {
    return null;
  }

  if (parent.scrollHeight > parent.clientHeight) {
    return parent.tagName === 'HTML' ? element : parent;
  }

  // If we reach a modal element, stop.
  if (parent.classList.contains('p-maia-modal')) {
    return null;
  }

  // If height is not defined scrollHeight and clientHeight has the same value over and over until
  // HTML element is reached, also browsers sometimes list the entire document as scrollable when
  // they actually mean the body, then element should be returned
  if (parent.tagName === 'HTML') {
    return element;
  }

  return findScrollingParent(parent);
}
