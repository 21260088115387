import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {ButtonGroupComponent} from './button-group/button-group.component';
import {ButtonComponent} from './button/button.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ButtonComponent, ButtonGroupComponent],
  exports: [ButtonComponent, ButtonGroupComponent],
})
export class ButtonsModule {}
