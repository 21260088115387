import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {ModalTemplateContext} from '@maia/modals';
import {μSlideInController} from '@maia/slide-ins';

/**
 * @ngModule AdditionalInformationModule
 */
@Component({
  selector: 'maia-additional-information[slideIn]:not([custom]):not([collapsible])',
  templateUrl: './additional-information-slide-in.component.html',
  styleUrls: ['./additional-information-slide-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalInformationSlideInComponent {
  @Input('slideInTitle')
  public slideInTitle?: string | null;

  @Input('openLinkText')
  public openLinkText?: Text | string | null;

  @ViewChild('content', {static: true})
  public readonly content: TemplateRef<ModalTemplateContext<void>>;

  public constructor(
    private readonly _injector: Injector,
    private readonly _slideInCtrl: μSlideInController,
  ) {}

  public openSlideIn(event?: Event): void {
    this._slideInCtrl
      .prepareTemplate(
        this.content,
        this._injector,
        {
          title: this.slideInTitle || '',
        },
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
        },
      )
      .subscribe();
    if (event != null) {
      event.preventDefault();
    }
  }
}
