import {Injectable} from '@angular/core';
import {DocumentRef} from '@atlas-angular/cdk/globals';

import {GlobalStyleService} from './../global-style/global-style.service';

const CLASS_BASE = 'maia-scroll-blocked';

/**
 * A scroll lock. While this lock is active, scrolling the body will be blocked. Unlocking this lock
 * will free scrolling on the body, if and only if there is no other active lock.
 */
export interface ScrollLock {
  /**
   * Whether this lock is active. Locks start active, they are deactivated by calling `unlock()`.
   */
  readonly locked: boolean;

  /**
   * Unlocks this lock. Doesn't do anything if the lock is already unlocked.
   */
  unlock(): void;
}

/**
 * The ScrollBlocker service allows blocking of scrolling on the body.
 */
@Injectable({providedIn: 'root'})
export class ScrollBlocker {
  private _nbLocks = 0;

  private _scrollBlockClass?: string = undefined;

  public constructor(private _document: DocumentRef, private _globalStyles: GlobalStyleService) {}

  /**
   * Locks scrolling on the body. Scrolling remains locked until the last scroll lock is unlocked.
   *
   * @returns The scroll lock
   */
  public lock(): ScrollLock {
    let locked = true;
    this._doLock();

    return {
      get locked(): boolean {
        return locked;
      },

      unlock: (): void => {
        if (!locked) {
          return;
        }

        locked = false;
        this._doUnlock();
      },
    };
  }

  private _getScrollBlockClass(): string {
    if (this._scrollBlockClass == null) {
      this._scrollBlockClass = this._globalStyles.addRule(
        CLASS_BASE,
        'overflow: hidden;',
      ).className;
    }

    return this._scrollBlockClass;
  }

  private _doLock(): void {
    if (this._nbLocks === 0) {
      this._document.document.body.classList.add(this._getScrollBlockClass());
    }

    this._nbLocks++;
  }

  private _doUnlock(): void {
    this._nbLocks--;

    if (this._nbLocks === 0) {
      this._document.document.body.classList.remove(this._getScrollBlockClass());
    }
  }
}
