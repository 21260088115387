import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Component, HostBinding, Input} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

import {ListItemService} from '../list-item-service/list-item.service';

/**
 * @ngModule ListsModule
 */
@Component({
  selector: 'maia-list-item',
  template: '<ng-content></ng-content>',
  styleUrls: ['./list-item.component.scss'],
  providers: [ListItemService],
})
export class ListItemComponent {
  private _disabled: boolean;

  @Input()
  @HostBinding('class.p-maia-list-item--disabled')
  public get disabled(): boolean {
    return this._disabled;
  }

  /**
   * Set all item disabled. Set disabled in the service to share the state in all children
   */
  public set disabled(disabled: boolean) {
    this._listItemService.updateIsDisabled(disabled);
    this._disabled = coerceBooleanProperty(disabled);
  }

  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('class.p-maia-list-item--condensed')
  public condensed = false;

  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('class.p-maia-list-item--actioned')
  public actioned = false;

  public constructor(private readonly _listItemService: ListItemService) {}
}
