import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  QueryList,
} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {ViewportName, ViewportService} from '@maia/core';

import {combineLatest} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import {ComparisonPanelComponent} from '../comparison-panel/comparison-panel.component';

const COMPARISON_PANEL_NUMBER = 2;

/**
 * @ngModule ComparisonPanelModule
 */
@Component({
  selector: 'maia-comparison-panel-group',
  template: '<ng-content></ng-content>',
  styleUrls: ['./comparison-panel-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class ComparisonPanelGroupComponent implements AfterContentInit {
  @ContentChildren(ComparisonPanelComponent)
  public comparisonPanels: QueryList<ComparisonPanelComponent>;

  @coerceBooleanPrimitive()
  @Input()
  @HostBinding('class.p-maia-comparison-panel-group--horizontal')
  public horizontal: boolean;

  public constructor(private readonly _viewPortService: ViewportService) {}

  public ngAfterContentInit() {
    if (this.horizontal) {
      const numberOfPanels$ = this.comparisonPanels.changes.pipe(
        map(() => this.comparisonPanels.length),
        startWith(this.comparisonPanels.length),
      );

      combineLatest([this._viewPortService.isAtLeast$(ViewportName.MEDIUM), numberOfPanels$])
        .pipe(takeUntilDestroyed(this))
        .subscribe(([isAtLeastMedium, numberOfPanels]) => {
          this.horizontal = numberOfPanels === COMPARISON_PANEL_NUMBER && isAtLeastMedium;
        });
    }
  }
}
