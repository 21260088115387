import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CollapsiblesModule} from '@maia/collapsibles';
import {DividerModule} from '@maia/divider';
import {μFlowProgressSvgModule} from '@maia/icons';
import {ProgressModule} from '@maia/progress';

import {FalloutComponent} from '../fallout/fallout.component';
import {FlowProgressComponent} from './flow-progress.component';
import {InvisibleStepComponent} from './step/invisible-step.component';
import {MultiStepComponent} from './step/multi-step.component';
import {StepComponent} from './step/step.component';

/**
 * Module for the `<maia-flow-progress>`
 *
 * This module doesn't expose any helpers for setting the active step in the flow-progress. Every
 * step has an input/output pair so you can bind on `[(active)]`.
 *
 * Example usage
 *
 * ```html
 * <maia-flow-progress>
 *   <maia-flow-progress-step title="Step 1" [active]="isStepOneActive()"></maia-flow-progress-step>
 *   <maia-flow-progress-step title="Step 2" [active]="isStepTwoActive()"></maia-flow-progress-step>
 *   <maia-flow-progress-multi-step title="Step 3">
 *     <maia-flow-progress-step title="Step 3 substep 1" [active]="isStepThreePointOneActive()">
 *     </maia-flow-progress-step>
 *     <maia-flow-progress-step title="Step 3 substep 2" [active]="isStepThreePointTwoActive()">
 *     </maia-flow-progress-step>
 *   </maia-flow-progress-multi-step>
 * </maia-flow-progress>
 * ```
 *
 * See the `FlowProgressWithRoutingModule` for a flow progress that's hooked into the Angular router
 *
 * @see FlowProgressWithRoutingModule
 */
@NgModule({
  imports: [
    CommonModule,
    ProgressModule,
    μFlowProgressSvgModule,
    CollapsiblesModule,
    DividerModule,
  ],
  declarations: [
    FlowProgressComponent,
    StepComponent,
    InvisibleStepComponent,
    MultiStepComponent,
    FalloutComponent,
  ],
  exports: [FlowProgressComponent, StepComponent, InvisibleStepComponent, MultiStepComponent],
})
export class FlowProgressModule {}
