import {BusinessType, createStringifier} from '../base/businesstype';

import {isValidNationalRegisterNumber} from './validator-base';

const stringifier = createStringifier('nationalRegisterNumber');

/**
 * BusinessType wrapper around a national register number.
 */
export class NationalRegisterNumber extends BusinessType<string> {
  /**
   * Returns whether or not `value` is a NationalRegisterNumber.
   */
  public static isNationalRegisterNumber(value: unknown): value is NationalRegisterNumber {
    return value instanceof NationalRegisterNumber;
  }

  /**
   * Constructs a new NationalRegisterNumber instance.
   *
   * @param value The national register number.
   * @param encryptedValue The encrypted value, if any
   */
  public constructor(value: string, encryptedValue?: string) {
    if (typeof value !== 'string') {
      throw new Error(
        `Expected a string but got ${(typeof value === 'object' &&
          (value as object).constructor.name) ||
          typeof value}`,
      );
    } else if (!isValidNationalRegisterNumber(value)) {
      throw new Error(
        `Cannot create NationalRegisterNumber from invalid input ${JSON.stringify(value)}`,
      );
    }

    super(value, encryptedValue);
  }

  /**
   * Returns whether `other` is a `NationalRegisterNumber` representing the same value.
   */
  public equals(other: unknown): boolean {
    if (!other) {
      return false;
    }

    if (other === this) {
      return true;
    }

    return (
      NationalRegisterNumber.isNationalRegisterNumber(other) &&
      this.internalValue === other.internalValue
    );
  }

  /**
   * Exposes the value of this BusinessType as a string.
   */
  public asString(): string {
    return this.internalValue;
  }

  public toString(): string {
    return stringifier(this);
  }
}

export const NON_CHECK_DIGITS_LENGTH = 9;
export const CHECK_DIGITS_START = 9;
export const CHECK_DIGITS_LENGTH = 2;
export const CHECK_DIGITS_MODULO_BASE = 97;
export const FOREIGNER_GENDER_NOT_KNOWN = 20;
export const FOREIGNER_GENDER_KNOWN = 40;
export const DATE_FIRST_MONTH = 1;
export const DATE_LAST_MONTH = 12;
