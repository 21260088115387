/* eslint-disable import/no-extraneous-dependencies */

import {Portal} from '@angular/cdk/portal';
import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injectable,
  Injector,
} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {
  ModalContentComponent,
  ModalController,
  ModalOptions,
  ModalOptionsWithInput,
  ModalResult,
} from '@maia/modals';
import {BehaviorSubject, NEVER, Observable} from 'rxjs';
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators';

import {µToastComponent} from '../container/toast.component';

/**
 * The ToastController allows showing pop-ups.
 */
@Injectable()
@UntilDestroy()
export class ToastController {
  private readonly _toastPortalsSubject: BehaviorSubject<Portal<Component>[]> = new BehaviorSubject(
    [],
  );

  private readonly _toastPortals$: Observable<
    Portal<any>[]
  > = this._toastPortalsSubject.asObservable();

  public constructor(
    private readonly _modalCtrl: ModalController,
    componentFactoryResolver: ComponentFactoryResolver,
    injector: Injector,
  ) {
    const toastFactory = componentFactoryResolver.resolveComponentFactory(µToastComponent);

    this._toastPortalsSubject
      .pipe(
        map(array => array.length > 0),
        distinctUntilChanged(),

        switchMap(hasToasts =>
          hasToasts
            ? this.prepare<void, Observable<Portal<any>[]>>(toastFactory, injector, {
                input: this._toastPortals$,
              })
            : NEVER,
        ),

        takeUntilDestroyed(this),
      )
      .subscribe();
  }

  /**
   * Prepares a pop-up. Once the returned observable is subscribed to, the pop-up will be shown.
   * The pop-up gets dismissed if the subscription is ended.
   *
   * By default the open pop-up will not block scrolling on the page.
   *
   * @param componentFactory The content component to show in the pop-up
   * @param injector The parent injector for the content component
   * @param options The options, e.g. title, for this pop-up
   * @param modalOptions The options to pass on to the modal controller
   */
  private prepare<O>(
    componentFactory: ComponentFactory<ModalContentComponent<O>>,
    injector: Injector,
    modalOptions?: ModalOptions,
  ): Observable<ModalResult<O>>;
  private prepare<O, I>(
    componentFactory: ComponentFactory<ModalContentComponent<O, I>>,
    injector: Injector,
    modalOptions: ModalOptionsWithInput<I>,
  ): Observable<ModalResult<O>>;
  private prepare<O, I>(
    componentFactory: ComponentFactory<ModalContentComponent<O>>,
    injector: Injector,
    modalOptions?: ModalOptions<I>,
  ): Observable<ModalResult<O>> {
    return new Observable<ModalResult<O>>(observer => {
      const mergedModalOptions = Object.assign({}, modalOptions);

      mergedModalOptions.blockScrolling = false;

      return this._modalCtrl
        .prepareOnTop(
          'maiaToast',
          injector,
          componentFactory,
          componentFactory,
          // Explicit cast is necessary because typescript doesn't know what we know thanks to
          // our function definition: The input property on the merged modal options is always
          // present if I isn't undefined/void.
          mergedModalOptions as ModalOptionsWithInput<I>,
        )
        .subscribe(observer);
    });
  }

  public addToastMessage(portal: Portal<any>): Observable<never> {
    return new Observable(() => {
      const addedPortalList = [...this._toastPortalsSubject.value, portal];
      this._toastPortalsSubject.next(addedPortalList);

      return () => {
        const removedPortalList = this._toastPortalsSubject.value.filter(p => p != portal);
        this._toastPortalsSubject.next(removedPortalList);
      };
    });
  }
}
