import {Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {CssClassUtility, CssClassUtilityFactory} from '@maia/core';
import {
  CellSize,
  CellSizeClasses,
  CellSizeLgClasses,
  CellSizeMdClasses,
  CellSizeSmClasses,
  CellSizeXlClasses,
} from './utils';

/**
 * CellSizeDirective to determine mobile-first breakpoint size
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-cell[cellSize]',
})
export class CellSizeDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof CellSizeClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(CellSizeClasses, renderer, elementRef);
  }

  @Input()
  public set cellSize(cellSize: CellSize | null) {
    this._cssClassUtility.setValue('cellSize', cellSize);
  }
}

/**
 * CellSizeSmDirective to determine Small breakpoint size
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-cell[cellSizeSm]',
})
export class CellSizeSmDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof CellSizeSmClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(CellSizeSmClasses, renderer, elementRef);
  }

  @Input()
  public set cellSizeSm(cellSizeSm: CellSize | null) {
    this._cssClassUtility.setValue('cellSizeSm', cellSizeSm);
  }
}

/**
 * CellSizeMdDirective to determine Medium breakpoint size
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-cell[cellSizeMd]',
})
export class CellSizeMdDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof CellSizeMdClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(CellSizeMdClasses, renderer, elementRef);
  }

  @Input()
  public set cellSizeMd(cellSizeMd: CellSize | null) {
    this._cssClassUtility.setValue('cellSizeMd', cellSizeMd);
  }
}

/**
 * CellSizeLgDirective to determine Large breakpoint size
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-cell[cellSizeLg]',
})
export class CellSizeLgDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof CellSizeLgClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(CellSizeLgClasses, renderer, elementRef);
  }

  @Input()
  public set cellSizeLg(cellSizeLg: CellSize | null) {
    this._cssClassUtility.setValue('cellSizeLg', cellSizeLg);
  }
}

/**
 * CellSizeXlDirective to determine Extra Large breakpoint size
 *
 * @ngModule FlexGridModule
 */
@Directive({
  selector: 'maia-flex-grid-cell[cellSizeXl]',
})
export class CellSizeXlDirective {
  private readonly _cssClassUtility: CssClassUtility<typeof CellSizeXlClasses>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(CellSizeXlClasses, renderer, elementRef);
  }

  @Input()
  public set cellSizeXl(cellSizeXl: CellSize | null) {
    this._cssClassUtility.setValue('cellSizeXl', cellSizeXl);
  }
}
