import {Injectable, InjectionToken} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';

import {UploadValidationError} from '../file-uploader/file-uploader.service';

export interface FileUploaderAnalyticsServiceInterface {
  trackUploadDocument?(): void;

  trackDownloadDocument?(): void;

  trackDeleteDocument?(): void;

  trackOpenSlideIn?(): void;

  trackError?(error: UploadValidationError): void;

  trackCloseSlideIn?(): void | Promise<void>;

  trackConfirmSlideIn?(): void | Promise<void>;
}

@Injectable()
export class NoopFileUploaderAnalyticsService implements FileUploaderAnalyticsServiceInterface {
  private readonly log = this.logger.createLogger('NoopFileUploaderAnalyticsService');

  public constructor(private readonly logger: LoggerFactory) {}

  public trackUploadDocument() {
    this.log.debug('upload document');
  }

  public trackDownloadDocument() {
    this.log.debug('download document');
  }

  public trackDeleteDocument() {
    this.log.debug('delete document');
  }

  public trackOpenSlideIn() {
    this.log.debug('open slideIn');
  }

  public trackCloseSlideIn() {
    this.log.debug('close slideIn');
  }

  public trackConfirmSlideIn() {
    this.log.debug('close confirm slideIn');
  }

  public trackError(error: UploadValidationError) {
    this.log.debug('error', error.errorType);
  }
}

export const FILE_UPLOADER_ANALYTICS_SERVICE = new InjectionToken<
  FileUploaderAnalyticsServiceInterface
>('fileUploaderAnalyticsService');
