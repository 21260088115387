import {
  Inject,
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import {ApplicationElementRef} from '@atlas-angular/cdk/globals';

import {RenderViewportService} from './viewport/render-viewport.service';

export const ROOT_TOKEN = new InjectionToken<{}>('CoreServicesModule root token');

/**
 * Module containing the services of the `@maia/core` package.
 */
@NgModule()
export class CoreServicesModule {
  /**
   * Registers root providers for the CoreServicesModule
   */
  public static forRoot(): ModuleWithProviders<CoreServicesModule> {
    return {
      ngModule: CoreServicesModule,
      providers: [{provide: ROOT_TOKEN, useValue: {}}],
    };
  }

  public constructor(
    renderViewport: RenderViewportService,
    appElement: ApplicationElementRef,
    @Optional() @SkipSelf() parent?: CoreServicesModule,
    @Optional() @Inject(ROOT_TOKEN) token?: any,
  ) {
    if (parent != null) {
      throw new Error(`Only import CoreServicesModule once, in the AppModule`);
    }

    if (token == null) {
      throw new Error(`Import CoreServicesModule.forRoot(), not CoreServicesModule directly`);
    }

    // subscribe and forget!
    renderViewport.addClassesOnElement(appElement).subscribe();
  }
}
