import {ScrollDirection} from './waypoint.directive';
import {PreviousRatioAndY} from './waypoint.interfaces';

export function isLeaving(
  entry: IntersectionObserverEntry,
  prevRatioAndY: PreviousRatioAndY,
): boolean {
  const {intersectionRatio: ratio, isIntersecting} = entry;
  const {ratio: prevRatio} = prevRatioAndY;

  return !isIntersecting || ratio < prevRatio;
}

export function getScrollDirection(
  entry: IntersectionObserverEntry,
  prevRatioAndY: PreviousRatioAndY,
): ScrollDirection {
  const {
    boundingClientRect: {top: y},
  } = entry;
  const {y: prevY} = prevRatioAndY;

  return y < prevY ? ScrollDirection.Down : ScrollDirection.Up;
}
