import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Optional,
  ViewChild,
} from '@angular/core';
import {DomIoService} from '@maia/core';

import {TableComponent} from '../table/table.component';

/**
 * Table header. The header must be placed inside a table. If the header is less wide than the table
 * itself, a padding will be added on the right side to make the width match.
 *
 * @ngModule TablesModule
 */
@Component({
  selector: 'maia-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableHeaderComponent implements AfterViewInit {
  private _table: TableComponent;

  @ViewChild('header', {static: true})
  private _header: ElementRef;

  public constructor(
    private _headerHost: ElementRef,
    private _domIoService: DomIoService,
    @Optional() table?: TableComponent,
  ) {
    if (table == null) {
      throw new Error(
        'Cannot create a <maia-table-header /> component out of <maia-table /> component',
      );
    }

    this._table = table;
  }

  public ngAfterViewInit() {
    this._changeWidth(this._table.width);
  }

  /**
   * Makes sure that the header is at least as wide as the table itself.
   */
  private _changeWidth(tableWidth: number) {
    if (!tableWidth) {
      return;
    }
    let headerWidth: number;

    this._domIoService.measure(() => {
      headerWidth = this._headerHost.nativeElement.offsetWidth;
    });

    this._domIoService.mutate(() => {
      // Update the padding to make the header width match the table height
      if (tableWidth < headerWidth) {
        (this._header.nativeElement as HTMLElement).style.paddingRight = `${
          headerWidth - tableWidth
        }px`;
      } else {
        // @ts-expect-error
        (this._header.nativeElement as HTMLElement).style.paddingRight = null;
      }
    });
  }
}
