import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Date, DateKey, DateUtils, DayOfMonth, isValidDate, Month} from '@atlas/businesstypes';
import {DateRange} from '../../daterange';
import {DisabledDayDecider} from '../../parts/daypicker/daypicker.util';
import {createDefaultYearRange, DefaultYearRange} from '../../parts/yearpicker/yearpicker.util';
import {DatelikeInput, DatepickerVisibleView} from '../datepicker/datepicker.component';

/**
 * A daterangepicker allows the selection of a range of dates. The user needs to select a start date
 * and an end date.
 *
 * @ngModule DatePickersModule
 */
@Component({
  selector: 'maia-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  styleUrls: ['./daterangepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DaterangepickerComponent),
      multi: true,
    },
    // Do not register as DISABLED_DAY_DECIDER, because the token here would override any tokens
    // in parent injectors.
  ],
})
export class DaterangepickerComponent
  implements ControlValueAccessor, DisabledDayDecider, OnInit, OnDestroy {
  /**
   * The maximum allowed date. If no value is passed, the `DefaultYearRange` will be used to
   * calculate the maximum year relative to the current year.
   */
  @Input('maximum')
  public set rawMaximum(value: DatelikeInput) {
    if (value == null) {
      value = DateUtils.add(DateUtils.today(), DateKey.Year, this._defaultRange.upperBound);
    }

    this._maximum = !Date.isDate(value) ? new Date(value) : value;
  }

  /**
   * The minimum allowed date. If no value is passed, the `DefaultYearRange` will be used to
   * calculate the minimum year relative to the current year.
   */
  @Input('minimum')
  public set rawMinimum(value: DatelikeInput) {
    if (value == null) {
      value = DateUtils.subtract(DateUtils.today(), DateKey.Year, this._defaultRange.lowerBound);
    }

    this._minimum = !Date.isDate(value) ? new Date(value) : value;
  }

  public get minimum(): Date {
    return this._minimum;
  }

  public get maximum(): Date {
    return this._maximum;
  }

  /**
   * Set whether the confirm button of the DateRangeActionButtons should be disabled if the value
   * has not changed.
   *
   * Defaults to true.
   * */
  @Input()
  public disableConfirmIfNoChanges = true;

  /**
   * The initially active view. This can e.g. be used to create a picker where the year is initially
   * shown rather than the default (day).
   */
  @Input()
  public set visibleView(visibleView: DatepickerVisibleView) {
    if (this._visibleView == null) {
      this._visibleView = visibleView;
    }
  }

  @Output()
  public cancelSelection: EventEmitter<void> = new EventEmitter();

  /**
   * The year to show initially in the yearpicker if there's no selected year.
   */
  public get initialYear(): number {
    return this._initialYear;
  }

  /**
   * The selected year.
   */
  public get selectedYear(): number | undefined {
    return this._selectedYear;
  }

  /**
   * The selected month.
   */
  public get selectedMonth(): number | undefined {
    return this._selectedMonth;
  }

  /**
   * The selected daterange. Default is an empty daterange.
   */
  public get selectedDateRange(): DateRange {
    return this._selectedDateRange;
  }

  /**
   * The months to disable for the currently selected year.
   */
  public get disabledMonths(): number[] {
    return this._disabledMonths;
  }
  private _visibleView: DatepickerVisibleView;

  /**
   * The value set with `[ngModel]`. We will re-use this object if the same date gets selected to
   * keep any `encryptedValue` in the businesstype intact.
   *
   * If an `initialYear` is set, that year will be preselected and the month view will be opened at
   * the start.
   */
  private _value?: DateRange = undefined;

  private _minimum: Date;
  private _maximum: Date;

  private _selectedYear?: number;
  private _selectedMonth?: Month;
  private _selectedDateRange: DateRange = new DateRange();
  private _previouslySelectedDateRange: DateRange = new DateRange();

  private _initialValue: Date;
  private _initialYear: number;

  private _disabledMonths: Month[] = [];

  private readonly _defaultRange: DefaultYearRange;

  // istanbul ignore next: provided by [(ngModel)]
  private _onTouched = () => {};
  // istanbul ignore next: provided by [(ngModel)]
  private _onChanged = (_value: DateRange | undefined) => {};

  private _onDayDisableChanged = () => {};

  public constructor(
    private readonly _changeDetector: ChangeDetectorRef,
    @Optional() defaultRange?: DefaultYearRange,
  ) {
    this._defaultRange = createDefaultYearRange(defaultRange);
    this.rawMaximum = null;
    this.rawMinimum = null;

    this._initialValue = DateUtils.today();
    this._initialYear = DateUtils.get(DateUtils.today(), DateKey.Year);
  }

  /**
   * Whether the year view is currently shown. Mutually exclusive with `isMonthVisible()` and
   * `isDayVisible()`.
   */
  public isYearVisible(): boolean {
    return this._visibleView === DatepickerVisibleView.YEAR;
  }

  /**
   * Whether the month view is currently shown. Mutually exclusive with `isYearVisible()` and
   * `isDayVisible()`.
   */
  public isMonthVisible(): boolean {
    return this._visibleView === DatepickerVisibleView.MONTH;
  }

  /**
   * Whether the day view is currently shown. Mutually exclusive with `isMonthVisible()` and
   * `isYearVisible()`.
   */
  public isDayVisible(): boolean {
    return this._visibleView === DatepickerVisibleView.DAY;
  }

  private _updateDisabledMonths(): void {
    const disabledMonths: number[] = [];

    if (this._selectedYear === DateUtils.get(this._minimum, DateKey.Year)) {
      const minimumMonth = DateUtils.get(this._minimum, DateKey.Month);

      for (let i = Month.January; i < minimumMonth; i++) {
        disabledMonths.push(i);
      }
    }

    if (this._selectedYear === DateUtils.get(this._maximum, DateKey.Year)) {
      const maximumMonth = DateUtils.get(this._maximum, DateKey.Month);

      for (let i = maximumMonth + 1; i <= Month.December; i++) {
        disabledMonths.push(i);
      }
    }

    this._disabledMonths = disabledMonths;
  }

  public get hasSelectionChanged() {
    return this._selectedDateRange.equals(this._previouslySelectedDateRange);
  }

  /**
   * Whether there is a year before the selected year.
   */
  public hasPreviousYear(): boolean {
    return this._selectedYear! > DateUtils.get(this._minimum, DateKey.Year);
  }

  /**
   * Selects the previous year.
   * There's no validation here, this assumes there _is_ a previous year to go to. Use
   * `hasPreviousYear()` to protect this call.
   */
  public selectPreviousYear(): void {
    this._selectedYear!--;
    this._updateDisabledMonths();
  }

  /**
   * Whether there is a year after the selected year.
   */
  public hasNextYear(): boolean {
    return this._selectedYear! < DateUtils.get(this._maximum, DateKey.Year);
  }

  /**
   * Selects the next year.
   * There's no validation here, this assumes there _is_ a next year to go to. Use `hasNextYear()`
   * to protect this call.
   */
  public selectNextYear(): void {
    this._selectedYear!++;
    this._updateDisabledMonths();
  }

  private _getStartOfCurrentlySelectedMonth(): Date {
    if (this._selectedYear !== undefined && this._selectedMonth !== undefined) {
      return DateUtils.unserialize({
        [DateKey.Year]: this._selectedYear,
        [DateKey.Month]: this._selectedMonth.valueOf(),
        [DateKey.Day]: 1,
      });
    } else {
      return DateUtils.set(DateUtils.today(), DateKey.Day, 1);
    }
  }

  /**
   * Whether there is a month before the selected month.
   */
  public hasPreviousMonth(): boolean {
    return DateUtils.isAfter(
      this._getStartOfCurrentlySelectedMonth(),
      this._minimum,
      DateKey.Month,
    );
  }

  /**
   * Selects the previous month.
   * There's no validation here, this assumes there _is_ a previous month to go to. Use
   * `hasPreviousMonth()` to protect this call.
   */
  public selectPreviousMonth(): void {
    if (this._selectedMonth === Month.January) {
      this._selectedMonth = Month.December;
      this.selectPreviousYear();
    } else {
      this._selectedMonth = (this._selectedMonth! - 1) as Month;
    }
    this._onDayDisableChanged();
  }

  /**
   * Whether there is a month after the selected month.
   */
  public hasNextMonth(): boolean {
    return DateUtils.isBefore(
      this._getStartOfCurrentlySelectedMonth(),
      this._maximum,
      DateKey.Month,
    );
  }

  /**
   * Selects the next month.
   * There's no validation here, this assumes there _is_ a next month to go to. Use `hasNextMonth()`
   * to protect this call.
   */
  public selectNextMonth(): void {
    if (this._selectedMonth === Month.December) {
      this._selectedMonth = Month.January;
      this.selectNextYear();
    } else {
      this._selectedMonth = (this._selectedMonth! + 1) as Month;
    }
    this._onDayDisableChanged();
  }

  /**
   * Opens the year view.
   */
  public openYearView(): void {
    this._visibleView = DatepickerVisibleView.YEAR;
  }

  /**
   * Opens the month view.
   */
  public openMonthView(): void {
    this._visibleView = DatepickerVisibleView.MONTH;
  }

  private _getCurrentSelectedValue(): DateRange {
    const startOfSelectedMonth = DateUtils.unserialize({
      [DateKey.Year]: this._selectedYear!,
      [DateKey.Month]: this._selectedMonth || Month.January,
      [DateKey.Day]: 1,
    });

    if (!this._selectedDateRange || this._selectedDateRange.isEmpty()) {
      return new DateRange(startOfSelectedMonth, null);
    }

    return this._selectedDateRange;
  }

  /**
   * Marks the given year as selected and opens the day view.
   */
  public selectYear(year: number): void {
    this._selectedYear = year;
    this._visibleView = DatepickerVisibleView.DAY;
    this._updateDisabledMonths();
    this._onDayDisableChanged();
  }

  /**
   * Marks the given month as selected and opens the day view.
   */
  public selectMonth(month: number): void {
    this._selectedMonth = month;
    this._visibleView = DatepickerVisibleView.DAY;
    this._onDayDisableChanged();
  }

  /**
   * This updates the `[(ngModel)]` value to the selected
   * day/month/year combination.
   * */
  public updateDateRangeSelection() {
    this._value = this._getCurrentSelectedValue();
    this._previouslySelectedDateRange = this._value;
    this._onChanged(this._value);
  }

  /**
   * Emit tells parents that user cancelled his selection.
   */
  public cancelDateRangeSelection() {
    this._selectedDateRange = new DateRange(
      this._previouslySelectedDateRange.start,
      this._previouslySelectedDateRange.end,
    );
    this.cancelSelection.emit();
  }

  /**
   * Marks the given range as selected.
   */
  public selectDateRange(dateRange: DateRange): void {
    this._selectedDateRange = dateRange;
  }

  /**
   * The date range to show as selected. This returns a new date range based on the current
   * selection.
   */
  public getSelectedDateRange(): DateRange {
    return new DateRange(this._selectedDateRange.start, this._selectedDateRange.end);
  }

  /**
   * The day to highlight. This is the day of today if the currently shown month/year combination is
   * the current month/year.
   */
  public getHighlightedDay(): number | null {
    const currentMonth = DateUtils.get(DateUtils.today(), DateKey.Month);
    const currentYear = DateUtils.get(DateUtils.today(), DateKey.Year);

    if (currentMonth === this._selectedMonth && currentYear === this._selectedYear) {
      return DateUtils.get(DateUtils.today(), DateKey.Day);
    }

    return null;
  }

  private _setInitialValues(): void {
    // Only pre-select the initial values up to the active view, otherwise we'd show
    // a selected value even though there is no selected value (only an initial value)
    if (!this.isYearVisible()) {
      this._selectedYear = DateUtils.get(this._initialValue, DateKey.Year);

      if (this.isDayVisible()) {
        this._selectedMonth = DateUtils.get(this._initialValue, DateKey.Month);
      }
    }
  }

  public ngOnInit() {
    // Default to day view (the visibleView setter doesn't allow overriding values)
    this.visibleView = DatepickerVisibleView.DAY;

    this._setInitialValues();
  }

  public ngOnDestroy() {
    this._onTouched();
  }

  // ControlValueAccessor API
  public writeValue(obj: any): void {
    if ((obj != null && DateRange.isDateRange(obj)) || (Date.isDate(obj) && isValidDate(obj))) {
      this._value = DateRange.isDateRange(obj) ? obj : new DateRange(obj, null);
      this._selectedDateRange = this._value;
      this._previouslySelectedDateRange = new DateRange(this._value.start, this._value.end);

      if (!Date.isDate(this._value.start)) {
        this._resetValues();
      } else {
        this._selectedYear = DateUtils.get(this._value.start, DateKey.Year);
        this._selectedMonth = DateUtils.get(this._value.start, DateKey.Month);

        this._visibleView = DatepickerVisibleView.DAY;
      }
    } else {
      this._resetValues();
    }

    this._changeDetector.detectChanges();
  }

  /**
   * Resets values to undefined and call function to set them back to defaults.
   * */
  private _resetValues() {
    this._value = undefined;

    this._selectedYear = undefined;
    this._selectedMonth = undefined;

    this._setInitialValues();
  }

  public registerOnChange(fn: any): void {
    this._onChanged = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  // DisabledDayDecider API

  public isDisabled(day: DayOfMonth): boolean | null {
    const startOfSelectedMonth = DateUtils.unserialize({
      [DateKey.Year]: this._selectedYear!,
      [DateKey.Month]: this._selectedMonth || Month.January,
      [DateKey.Day]: 1,
    });

    const currentSelection = DateUtils.set(startOfSelectedMonth, DateKey.Day, day);
    return DateUtils.isBefore(currentSelection, this._minimum) ||
      DateUtils.isAfter(currentSelection, this._maximum)
      ? true
      : null;
  }

  public registerOnUpdate(updateFn?: (() => void) | undefined): void {
    this._onDayDisableChanged = updateFn || (() => {});
  }
}
