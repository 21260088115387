/* eslint-disable @typescript-eslint/no-explicit-any */

import {Injectable} from '@angular/core';
import {WindowRef} from '@atlas-angular/cdk/globals';

export type CommonJsWrapper = (require: any, exports: any, module: any) => any;
export type SuccessCallback = (data: any) => any;
export type ErrorCallback = (err: any) => any;

export interface εCordovaInterface {
  /** Gets the operating system name (support only Android/iOS). */
  platformId: 'ios' | 'android';
  /** Namespace for Cordova plugin functionality */
  plugins: {};
  /** Gets Cordova framework version */
  version: string;
  /**
   * Invokes native functionality by specifying corresponding service name, action and optional
   * parameters.
   * @param success A success callback function.
   * @param fail An error callback function.
   * @param service The service name to call on the native side (corresponds to a native class).
   * @param action The action name to call on the native side (generally corresponds to the native
   * class method).
   * @param args An array of arguments to pass into the native environment.
   */
  exec: (
    success: SuccessCallback,
    fail: ErrorCallback,
    service: string,
    action: string,
    args?: any[],
  ) => void;
  /**
   * Defines custom logic as a Cordova module. Other modules can later access it using module name
   * provided.
   */
  define: (moduleName: string, factory: CommonJsWrapper) => void;
  /** Access a Cordova module by name. */
  require: (moduleName: string) => any;
}

export interface εCordovaWindow extends Window {
  cordova: εCordovaInterface;
}

/**
 * Wraps the actual Apache Cordova javascript library (which is on window memory).
 */
@Injectable({
  providedIn: 'root',
})
export class εCordova implements εCordovaInterface {
  private readonly window: Window;

  public constructor(windowRef: WindowRef) {
    this.window = windowRef.window;
  }

  private get cordova(): εCordovaInterface {
    const {cordova} = this.window as εCordovaWindow;

    if (cordova == null) {
      throw new Error('Cordova not found, make sure the correct Cordova module has been loaded');
    }
    return cordova;
  }

  public get platformId(): 'ios' | 'android' {
    return this.cordova.platformId;
  }

  public get plugins(): {} {
    return this.cordova.plugins;
  }

  public get version(): string {
    return this.cordova.version;
  }

  public exec(
    success: SuccessCallback,
    fail: ErrorCallback,
    service: string,
    action: string,
    args?: any[],
  ): void {
    return this.cordova.exec(success, fail, service, action, args);
  }

  public define(moduleName: string, factory: CommonJsWrapper): void {
    return this.cordova.define(moduleName, factory);
  }

  public require(moduleName: string): any {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.cordova.require(moduleName);
  }
}
