import {Directive} from '@angular/core';
import {ClientNumberFormatter} from '@atlas/businesstypes';

import {ValueFormatter} from '../util/value-formatter.util';

import {MaskedInputComponent} from './masked-input.component';

/**
 * This directive configures the masked input for usage with client numbers. It sets up
 * the correct mask and placeholder. Together with atlas's `[atlasClientNumber]` directive
 * this makes the masked input create ClientNumber businesstypes.
 *
 * @ngModule InputMasksModule
 */
@Directive({
  selector: 'maia-masked-input[atlasClientNumber]',
})
export class MaskedClientNumberInputDirective implements ValueFormatter {
  /**
   * Doesn't format anything, formatting is done by the `[atlasClientNumber]` directive.
   */
  public formatValue(value: string): string {
    return value;
  }

  /**
   * Unformats any formatting left in place by the `[atlasClientNumber]` directive.
   */
  public unformatValue(value: string): string {
    return ClientNumberFormatter.unformat(value);
  }

  public constructor(input: MaskedInputComponent) {
    input.setMaskAndPlaceholder('#########');
    input.setValueFormatter(this);
  }
}
