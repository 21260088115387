import {NgModule} from '@angular/core';
import {μListSvgModule} from '@maia/icons';

import {LinkedListItemComponent} from './linked-list-item/linked-list-item.component';
import {LinkedListComponent} from './linked-list/linked-list.component';
import {ListItemContentIconComponent} from './list-item-content-icon/list-item-content-icon.component';
import {ListItemContentComponent} from './list-item-content/list-item-content.component';
import {ListItemExtraActionComponent} from './list-item-extra-action/list-item-extra-action.component';
import {ListItemComponent} from './list-item/list-item.component';
import {ListComponent} from './list/list.component';

@NgModule({
  imports: [μListSvgModule],
  declarations: [
    LinkedListComponent,
    LinkedListItemComponent,
    ListComponent,
    ListItemComponent,
    ListItemContentComponent,
    ListItemContentIconComponent,
    ListItemExtraActionComponent,
  ],
  exports: [
    LinkedListComponent,
    LinkedListItemComponent,
    ListComponent,
    ListItemComponent,
    ListItemContentComponent,
    ListItemContentIconComponent,
    ListItemExtraActionComponent,
  ],
})
export class ListsModule {}
