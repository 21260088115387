import {Directive, forwardRef, Inject} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {isValidTsfr, Tsfr, TsfrFormatter} from '@atlas/businesstypes';

import {
  ATLAS_VALUE_ACCESSOR,
  AtlasControlValueAccessor,
  BaseBusinesstypeDirective,
} from '../base/index';

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasTsfr]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TsfrDirective),
    },
  ],
})
export class TsfrDirective extends BaseBusinesstypeDirective {
  public constructor(@Inject(ATLAS_VALUE_ACCESSOR) input: AtlasControlValueAccessor) {
    super(input);
  }

  public writeValue(value?: Tsfr | string): void {
    this._input.writeValue(
      Tsfr.isTsfr(value) ? TsfrFormatter.format(value) : value == null ? '' : value,
    );
  }

  public registerOnChange(fn: (_: Tsfr | string | null) => void): void {
    this._input.registerOnChange(value => {
      if (value === '') {
        fn(null);
        return;
      }

      const unformatted = TsfrFormatter.unformat(value);
      fn(isValidTsfr(unformatted) ? new Tsfr(unformatted) : unformatted);
    });
  }
}
