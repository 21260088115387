import {FocusOrigin} from '@angular/cdk/a11y';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Optional,
  Output,
  QueryList,
  Renderer2,
  SkipSelf,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {DropdownTemplateContext} from '@maia/dropdowns';
import {InputContainer, provideNestedInputContainer} from '@maia/forms';
import {CapturedInput} from '@maia/forms/capture';
import {OptionContainer} from '@maia/select';
import {Observable, Subject, Subscription} from 'rxjs';

import {ContentDropdownComponent} from '../content-dropdown/content-dropdown.component';
import {IconTextComponent} from '../icon-text/icon-text.component';

import {BaseInputWithSelectComponent} from './base-input-with-select.component';

/**
 * @ngModule InputWithSelectModule
 */
@Component({
  selector: 'maia-input-with-select',
  templateUrl: './input-with-select.component.html',
  styleUrls: ['./input-with-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    provideNestedInputContainer(),
    {provide: OptionContainer, useExisting: forwardRef(() => InputWithSelectComponent)},
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputWithSelectComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class InputWithSelectComponent<T>
  extends BaseInputWithSelectComponent<T>
  implements OptionContainer<T>, OnInit, AfterContentInit {
  public _initialValue?: T;
  public readonly content: FormControl;
  public inputWithSelectValue?: T;
  private _input?: CapturedInput = undefined;

  private _disabled = false;

  protected _inputSubscription = Subscription.EMPTY;
  private _typeAheadValue = '';

  public readonly queryChange = new Subject<void>();

  /**
   * The raw typeahead input typed by the user, can be used for e.g. filtering the options to show
   */

  @Output()
  public readonly typeAhead = new EventEmitter<string>();

  @Input()
  public set initialValue(value: T) {
    this._initialValue = value;
  }

  @ViewChild('dropdown', {static: true})
  public readonly contentDropdown: ContentDropdownComponent<T>;

  @ViewChild('dropdownContent', {static: true})
  public dropdownContent: TemplateRef<DropdownTemplateContext<IconTextComponent<T>>>;

  @ContentChildren(IconTextComponent)
  public options: QueryList<IconTextComponent<T>>;

  public get keyEvent$(): Observable<void> {
    return this.contentDropdown.keyEvent$;
  }

  public constructor(
    fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly _renderer: Renderer2,
    @SkipSelf() @Optional() inputContainer?: InputContainer,
  ) {
    super(inputContainer);
    this.content = fb.control(undefined);
  }

  public ngOnInit(): void {
    this.content.valueChanges.pipe(takeUntilDestroyed(this)).subscribe(() => this.emitValue());
  }

  public ngAfterContentInit(): void {
    this.applyInitialValue();

    if (!this.content.value && this.options.length > 0) {
      this.content.setValue(this.options.first.value);
    }
  }

  public applyInitialValue(): void {
    if (!this._initialValue) {
      return;
    }
    const contentOption = this.options.find(option => option.value === this._initialValue);

    if (!contentOption) {
      return;
    }
    this.content.setValue(contentOption.value);
  }

  public writeValue(value: unknown): void {
    this.inputWithSelectValue = value as T | undefined;
    this.content.setValue(value);
  }

  public emitValue(): void {
    this.inputWithSelectValue = this.content.value as T;
    this._onChange(this.inputWithSelectValue);
  }

  public activateOption(option: IconTextComponent<T>, focusOrigin?: FocusOrigin): void {
    this.contentDropdown.activateOption(option, focusOrigin);
  }

  @ContentChild(CapturedInput)
  public get capturedInput(): CapturedInput {
    this._assertHasCapturedInput();
    return this._input!;
  }

  public set capturedInput(capturedInput: CapturedInput) {
    this._inputSubscription.unsubscribe();
    this._input = capturedInput;

    if (this._input != null) {
      this._input.setDisabledState(this.isDisabled);
      const {element} = this._input;

      const inputSubscription = new Subscription();

      if (element instanceof HTMLInputElement) {
        inputSubscription.add(this._listenToInput(element));
      }
    }
  }

  protected _hasCapturedInput(): boolean {
    return this._input != null;
  }

  protected _assertHasCapturedInput(): void {
    if (!this._hasCapturedInput()) {
      throw new Error('No input to capture in the <maia-input-select>');
    }
  }

  private _listenToInput(element: HTMLInputElement): Subscription {
    const subscription = new Subscription();
    subscription.add(
      this._renderer.listen(element, 'input', () => {
        this.updateTypeAhead(element.value);
      }),
    );

    return subscription;
  }

  public updateTypeAhead(typeAheadValue: string | null): void {
    if (this._typeAheadValue === typeAheadValue) {
      return;
    }

    this._typeAheadValue = typeAheadValue || '';
    this.typeAhead.emit(this._typeAheadValue);

    if (this.contentDropdown.isOpen) {
      this.queryChange.next();
    }

    this.cdr.markForCheck();
  }

  public get isDisabled(): boolean {
    return this._disabled;
  }

  public setDisabledState(disabled: boolean): void {
    this._disabled = disabled;

    if (disabled) {
      this.content.disable();
    } else {
      this.content.enable();
    }

    if (this._hasCapturedInput()) {
      this.capturedInput.setDisabledState(disabled);
    }

    this.cdr.markForCheck();
  }
}
