import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {coerceBooleanPrimitive, coerceText} from '@atlas-angular/cdk/coercion';
import {Text} from '@atlas/businesstypes';

/**
 * @ngModule DisplaysModule
 */
@Component({
  selector: 'maia-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent {
  @Input()
  @coerceText()
  public bannerText: Text;

  @Input()
  @coerceText()
  public buttonText: Text;

  @Input()
  @coerceBooleanPrimitive()
  public showIcon = false;

  /**
   * Emits when the banner button is clicked
   */
  @Output()
  public clickBanner = new EventEmitter<void>();

  /**
   * Emits when the close button is clicked
   */
  @Output()
  public closeBanner = new EventEmitter<void>();
}
