import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 * @ngModule LayoutModule
 * @deprecated Use the maia-page-layout instead
 */
@Component({
  selector: 'maia-layout',
  template: '<ng-content></ng-content>',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.p-maia-page-layout--regular]': 'true',
  },
})
export class LayoutComponent {}
