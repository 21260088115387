/**
 * Cell size options
 */
export type CellSize =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | 'auto'
  | 'default'
  | 'grow';

/**
 * Cell size for XS breakpoing
 */
export const CellSizeClasses = {
  cellSize: {
    '1': 'maia-flex-grid-cell--size-1',
    '2': 'maia-flex-grid-cell--size-2',
    '3': 'maia-flex-grid-cell--size-3',
    '4': 'maia-flex-grid-cell--size-4',
    '5': 'maia-flex-grid-cell--size-5',
    '6': 'maia-flex-grid-cell--size-6',
    '7': 'maia-flex-grid-cell--size-7',
    '8': 'maia-flex-grid-cell--size-8',
    '9': 'maia-flex-grid-cell--size-9',
    '10': 'maia-flex-grid-cell--size-10',
    '11': 'maia-flex-grid-cell--size-11',
    '12': 'maia-flex-grid-cell--size-12',
    auto: 'maia-flex-grid-cell--size-auto',
    default: 'maia-flex-grid-cell',
    grow: 'maia-flex-grid-cell--size-grow',
  } as {[key in CellSize]: string | null},
};

/**
 * Cell size for SM breakpoing
 */
export const CellSizeSmClasses = {
  cellSizeSm: {
    '1': 'maia-flex-grid-cell--size-sm-1',
    '2': 'maia-flex-grid-cell--size-sm-2',
    '3': 'maia-flex-grid-cell--size-sm-3',
    '4': 'maia-flex-grid-cell--size-sm-4',
    '5': 'maia-flex-grid-cell--size-sm-5',
    '6': 'maia-flex-grid-cell--size-sm-6',
    '7': 'maia-flex-grid-cell--size-sm-7',
    '8': 'maia-flex-grid-cell--size-sm-8',
    '9': 'maia-flex-grid-cell--size-sm-9',
    '10': 'maia-flex-grid-cell--size-sm-10',
    '11': 'maia-flex-grid-cell--size-sm-11',
    '12': 'maia-flex-grid-cell--size-sm-12',
    auto: 'maia-flex-grid-cell--size-sm-auto',
    default: 'maia-flex-grid-cell--size-sm',
    grow: 'maia-flex-grid-cell--size-sm-grow',
  } as {[key in CellSize]: string | null},
};

/**
 * Cell size for MD breakpoing
 */
export const CellSizeMdClasses = {
  cellSizeMd: {
    '1': 'maia-flex-grid-cell--size-md-1',
    '2': 'maia-flex-grid-cell--size-md-2',
    '3': 'maia-flex-grid-cell--size-md-3',
    '4': 'maia-flex-grid-cell--size-md-4',
    '5': 'maia-flex-grid-cell--size-md-5',
    '6': 'maia-flex-grid-cell--size-md-6',
    '7': 'maia-flex-grid-cell--size-md-7',
    '8': 'maia-flex-grid-cell--size-md-8',
    '9': 'maia-flex-grid-cell--size-md-9',
    '10': 'maia-flex-grid-cell--size-md-10',
    '11': 'maia-flex-grid-cell--size-md-11',
    '12': 'maia-flex-grid-cell--size-md-12',
    auto: 'maia-flex-grid-cell--size-md-auto',
    default: 'maia-flex-grid-cell--size-md',
    grow: 'maia-flex-grid-cell--size-md-grow',
  } as {[key in CellSize]: string | null},
};

/**
 * Cell size for LG breakpoing
 */
export const CellSizeLgClasses = {
  cellSizeLg: {
    '1': 'maia-flex-grid-cell--size-lg-1',
    '2': 'maia-flex-grid-cell--size-lg-2',
    '3': 'maia-flex-grid-cell--size-lg-3',
    '4': 'maia-flex-grid-cell--size-lg-4',
    '5': 'maia-flex-grid-cell--size-lg-5',
    '6': 'maia-flex-grid-cell--size-lg-6',
    '7': 'maia-flex-grid-cell--size-lg-7',
    '8': 'maia-flex-grid-cell--size-lg-8',
    '9': 'maia-flex-grid-cell--size-lg-9',
    '10': 'maia-flex-grid-cell--size-lg-10',
    '11': 'maia-flex-grid-cell--size-lg-11',
    '12': 'maia-flex-grid-cell--size-lg-12',
    auto: 'maia-flex-grid-cell--size-lg-auto',
    default: 'maia-flex-grid-cell--size-lg',
    grow: 'maia-flex-grid-cell--size-lg-grow',
  } as {[key in CellSize]: string | null},
};

/**
 * Cell size for XL breakpoing
 */
export const CellSizeXlClasses = {
  cellSizeXl: {
    '1': 'maia-flex-grid-cell--size-xl-1',
    '2': 'maia-flex-grid-cell--size-xl-2',
    '3': 'maia-flex-grid-cell--size-xl-3',
    '4': 'maia-flex-grid-cell--size-xl-4',
    '5': 'maia-flex-grid-cell--size-xl-5',
    '6': 'maia-flex-grid-cell--size-xl-6',
    '7': 'maia-flex-grid-cell--size-xl-7',
    '8': 'maia-flex-grid-cell--size-xl-8',
    '9': 'maia-flex-grid-cell--size-xl-9',
    '10': 'maia-flex-grid-cell--size-xl-10',
    '11': 'maia-flex-grid-cell--size-xl-11',
    '12': 'maia-flex-grid-cell--size-xl-12',
    auto: 'maia-flex-grid-cell--size-xl-auto',
    default: 'maia-flex-grid-cell--size-xl',
    grow: 'maia-flex-grid-cell--size-xl-grow',
  } as {[key in CellSize]: string | null},
};

/**
 * Cell offset options
 */
export type CellOffset = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11';

/**
 * Cell offset for XS breakpoing
 */
export const CellOffsetClasses = {
  cellOffset: {
    '0': null, // No class in XS for offset 0
    '1': 'maia-flex-grid-cell--offset-1',
    '2': 'maia-flex-grid-cell--offset-2',
    '3': 'maia-flex-grid-cell--offset-3',
    '4': 'maia-flex-grid-cell--offset-4',
    '5': 'maia-flex-grid-cell--offset-5',
    '6': 'maia-flex-grid-cell--offset-6',
    '7': 'maia-flex-grid-cell--offset-7',
    '8': 'maia-flex-grid-cell--offset-8',
    '9': 'maia-flex-grid-cell--offset-9',
    '10': 'maia-flex-grid-cell--offset-10',
    '11': 'maia-flex-grid-cell--offset-11',
  } as {[key in CellOffset]: string | null},
};

/**
 * Cell offset for SM breakpoing
 */
export const CellOffsetSmClasses = {
  cellOffsetSm: {
    '0': 'maia-flex-grid-cell--offset-sm-0',
    '1': 'maia-flex-grid-cell--offset-sm-1',
    '2': 'maia-flex-grid-cell--offset-sm-2',
    '3': 'maia-flex-grid-cell--offset-sm-3',
    '4': 'maia-flex-grid-cell--offset-sm-4',
    '5': 'maia-flex-grid-cell--offset-sm-5',
    '6': 'maia-flex-grid-cell--offset-sm-6',
    '7': 'maia-flex-grid-cell--offset-sm-7',
    '8': 'maia-flex-grid-cell--offset-sm-8',
    '9': 'maia-flex-grid-cell--offset-sm-9',
    '10': 'maia-flex-grid-cell--offset-sm-10',
    '11': 'maia-flex-grid-cell--offset-sm-11',
  } as {[key in CellOffset]: string | null},
};

/**
 * Cell offset for MD breakpoing
 */
export const CellOffsetMdClasses = {
  cellOffsetMd: {
    '0': 'maia-flex-grid-cell--offset-md-0',
    '1': 'maia-flex-grid-cell--offset-md-1',
    '2': 'maia-flex-grid-cell--offset-md-2',
    '3': 'maia-flex-grid-cell--offset-md-3',
    '4': 'maia-flex-grid-cell--offset-md-4',
    '5': 'maia-flex-grid-cell--offset-md-5',
    '6': 'maia-flex-grid-cell--offset-md-6',
    '7': 'maia-flex-grid-cell--offset-md-7',
    '8': 'maia-flex-grid-cell--offset-md-8',
    '9': 'maia-flex-grid-cell--offset-md-9',
    '10': 'maia-flex-grid-cell--offset-md-10',
    '11': 'maia-flex-grid-cell--offset-md-11',
  } as {[key in CellOffset]: string | null},
};

/**
 * Cell offset for LG breakpoing
 */
export const CellOffsetLgClasses = {
  cellOffsetLg: {
    '0': 'maia-flex-grid-cell--offset-lg-0',
    '1': 'maia-flex-grid-cell--offset-lg-1',
    '2': 'maia-flex-grid-cell--offset-lg-2',
    '3': 'maia-flex-grid-cell--offset-lg-3',
    '4': 'maia-flex-grid-cell--offset-lg-4',
    '5': 'maia-flex-grid-cell--offset-lg-5',
    '6': 'maia-flex-grid-cell--offset-lg-6',
    '7': 'maia-flex-grid-cell--offset-lg-7',
    '8': 'maia-flex-grid-cell--offset-lg-8',
    '9': 'maia-flex-grid-cell--offset-lg-9',
    '10': 'maia-flex-grid-cell--offset-lg-10',
    '11': 'maia-flex-grid-cell--offset-lg-11',
  } as {[key in CellOffset]: string | null},
};

/**
 * Cell offset for XL breakpoing
 */
export const CellOffsetXlClasses = {
  cellOffsetXl: {
    '0': 'maia-flex-grid-cell--offset-xl-0',
    '1': 'maia-flex-grid-cell--offset-xl-1',
    '2': 'maia-flex-grid-cell--offset-xl-2',
    '3': 'maia-flex-grid-cell--offset-xl-3',
    '4': 'maia-flex-grid-cell--offset-xl-4',
    '5': 'maia-flex-grid-cell--offset-xl-5',
    '6': 'maia-flex-grid-cell--offset-xl-6',
    '7': 'maia-flex-grid-cell--offset-xl-7',
    '8': 'maia-flex-grid-cell--offset-xl-8',
    '9': 'maia-flex-grid-cell--offset-xl-9',
    '10': 'maia-flex-grid-cell--offset-xl-10',
    '11': 'maia-flex-grid-cell--offset-xl-11',
  } as {[key in CellOffset]: string | null},
};

/**
 * Cell self align options
 */
export type CellAlign = 'auto' | 'start' | 'end' | 'center' | 'baseline' | 'stretch';

/**
 * Cell self align for XS breakpoing
 */
export const CellAlignClasses = {
  cellAlign: {
    auto: 'maia-flex-grid-cell--align-self-auto',
    start: 'maia-flex-grid-cell--align-self-start',
    end: 'maia-flex-grid-cell--align-self-end',
    center: 'maia-flex-grid-cell--align-self-center',
    baseline: 'maia-flex-grid-cell--align-self-baseline',
    stretch: 'maia-flex-grid-cell--align-self-stretch',
  } as {[key in CellAlign]: string | null},
};

/**
 * Cell self align for SM breakpoing
 */
export const CellAlignSmClasses = {
  cellAlignSm: {
    auto: 'maia-flex-grid-cell--align-self-sm-auto',
    start: 'maia-flex-grid-cell--align-self-sm-start',
    end: 'maia-flex-grid-cell--align-self-sm-end',
    center: 'maia-flex-grid-cell--align-self-sm-center',
    baseline: 'maia-flex-grid-cell--align-self-sm-baseline',
    stretch: 'maia-flex-grid-cell--align-self-sm-stretch',
  } as {[key in CellAlign]: string | null},
};

/**
 * Cell self align for MD breakpoing
 */
export const CellAlignMdClasses = {
  cellAlignMd: {
    auto: 'maia-flex-grid-cell--align-self-md-auto',
    start: 'maia-flex-grid-cell--align-self-md-start',
    end: 'maia-flex-grid-cell--align-self-md-end',
    center: 'maia-flex-grid-cell--align-self-md-center',
    baseline: 'maia-flex-grid-cell--align-self-md-baseline',
    stretch: 'maia-flex-grid-cell--align-self-md-stretch',
  } as {[key in CellAlign]: string | null},
};

/**
 * Cell self align for LG breakpoing
 */
export const CellAlignLgClasses = {
  cellAlignLg: {
    auto: 'maia-flex-grid-cell--align-self-lg-auto',
    start: 'maia-flex-grid-cell--align-self-lg-start',
    end: 'maia-flex-grid-cell--align-self-lg-end',
    center: 'maia-flex-grid-cell--align-self-lg-center',
    baseline: 'maia-flex-grid-cell--align-self-lg-baseline',
    stretch: 'maia-flex-grid-cell--align-self-lg-stretch',
  } as {[key in CellAlign]: string | null},
};

/**
 * Cell self align for XL breakpoing
 */
export const CellAlignXlClasses = {
  cellAlignXl: {
    auto: 'maia-flex-grid-cell--align-self-xl-auto',
    start: 'maia-flex-grid-cell--align-self-xl-start',
    end: 'maia-flex-grid-cell--align-self-xl-end',
    center: 'maia-flex-grid-cell--align-self-xl-center',
    baseline: 'maia-flex-grid-cell--align-self-xl-baseline',
    stretch: 'maia-flex-grid-cell--align-self-xl-stretch',
  } as {[key in CellAlign]: string | null},
};
