import {ComponentFactory, InjectionToken, Injector, TemplateRef} from '@angular/core';

import {ModalContentComponent} from './modal-content.component';
import {ModalControl} from './modal-control';

export interface ModalTemplateContext<O, I = undefined> {
  control: ModalControl<O, I>;
  $implicit: ModalControl<O, I>;
}

export interface FactoryModalContentDescription<O, I = undefined> {
  readonly factory: ComponentFactory<ModalContentComponent<O, I>>;

  readonly injector: Injector;
}

export interface TemplateModalContentDescription<
  O,
  I = undefined,
  C extends ModalTemplateContext<O, I> = ModalTemplateContext<O, I>
> {
  readonly template: TemplateRef<C>;

  readonly templateContext: C;
}
export type ModalContentDescription<
  O,
  I = undefined,
  C extends ModalTemplateContext<O, I> = ModalTemplateContext<O, I>
> = FactoryModalContentDescription<O, I> | TemplateModalContentDescription<O, I, C>;

export const MODAL_CONTENT_DESCRIPTION = new InjectionToken<ModalContentDescription<any, any, any>>(
  'modal content description',
);
