import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import {filter, map, shareReplay, startWith} from 'rxjs/operators';

import {fetchBooleanPropertyInsideRouteTree, getDeepestRoute} from '../../header.utils';

import {
  HeaderPropertiesRewritable,
  RewritableBooleanProperties,
} from './header-properties-rewritable';

function capitalizeFirstLetter(s: string) {
  return `${s.slice(0, 1).toUpperCase()}${s.slice(1)}`;
}

function getObservablePropertyBoolean(
  propertyName: RewritableBooleanProperties,
  overwrittenProperties: HeaderPropertiesRewritable,
  observableActivatedRoute: Observable<ActivatedRoute>,
  defaultValue = true,
): Observable<boolean> {
  return overwrittenProperties.getProperty(
    propertyName,
    observableActivatedRoute.pipe(
      map(route =>
        fetchBooleanPropertyInsideRouteTree(
          route,
          `header${capitalizeFirstLetter(propertyName)}`,
          defaultValue,
        ),
      ),
    ),
  );
}

@Injectable({
  providedIn: 'root',
})
export class HeaderProperties {
  private readonly _overwrittenProperties = new HeaderPropertiesRewritable();

  public readonly showBackButton$: Observable<boolean>;
  public readonly showCloseButton$: Observable<boolean>;
  public readonly showFlowProgress$: Observable<boolean>;
  public readonly showLiveHelpButton$: Observable<boolean>;
  public readonly showKateButton$: Observable<boolean>;
  public readonly showExtraActionButton$: Observable<boolean>;
  public readonly vetoIsForced$: Observable<boolean>;
  public readonly vetoCustomBody$: Observable<string | undefined>;

  public constructor(activatedRoute: ActivatedRoute, router: Router) {
    const observableActivatedRoute = router.events.pipe(
      // Only handle when we have a NavigationEnd event
      filter(event => event instanceof NavigationEnd),
      // Inject the current activated route into the subscription.
      map(() => activatedRoute),
      // Do it the first time with the current active route
      startWith(activatedRoute),
      // From that route fetch the firstChild until there isn't one anymore.
      map(getDeepestRoute),
      // We only filter the routers for the primary route outlet.
      filter(route => route.outlet === 'primary'),
      shareReplay(1),
    );

    const getBooleanObservable = (
      name: RewritableBooleanProperties,
      defaultValue: boolean = true,
    ) =>
      getObservablePropertyBoolean(
        name,
        this._overwrittenProperties,
        observableActivatedRoute,
        defaultValue,
      );

    this.showBackButton$ = getBooleanObservable('showBackButton');
    this.showCloseButton$ = getBooleanObservable('showCloseButton');
    this.showFlowProgress$ = getBooleanObservable('showFlowProgress');
    this.showLiveHelpButton$ = getBooleanObservable('showLiveHelpButton');
    this.showKateButton$ = getBooleanObservable('showKateButton', false);
    this.showExtraActionButton$ = getBooleanObservable('showExtraActionButton');
    this.vetoIsForced$ = getBooleanObservable('vetoIsForced');
    this.vetoCustomBody$ = this._overwrittenProperties.getProperty('vetoCustomBody');
  }

  public overwriteVetoCustomBody(value?: string): Observable<never> {
    const vetoCustomBody$ = new Observable<never>(() => {
      this._overwrittenProperties.vetoCustomBody.next(value || null);
      return () => this._overwrittenProperties.vetoCustomBody.next(null);
    });

    return forkJoin([vetoCustomBody$, this.overwriteVetoIsForced(true)]) as Observable<never>; // cast because typescript can't tell [never, never] is never
  }

  public overwriteVetoIsForced(value: boolean): Observable<never> {
    return new Observable(() => {
      this._overwrittenProperties.vetoIsForced.next(value);
      return () => this._overwrittenProperties.vetoIsForced.next(null);
    });
  }
  public overwriteShowBackButton(value: boolean): Observable<never> {
    return new Observable(() => {
      this._overwrittenProperties.showBackButton.next(value);
      return () => this._overwrittenProperties.showBackButton.next(null);
    });
  }

  public overwriteShowCloseButton(value: boolean): Observable<never> {
    return new Observable(() => {
      this._overwrittenProperties.showCloseButton.next(value);
      return () => this._overwrittenProperties.showCloseButton.next(null);
    });
  }

  public overwriteShowFlowProgress(value: boolean): Observable<never> {
    return new Observable(() => {
      this._overwrittenProperties.showFlowProgress.next(value);
      return () => this._overwrittenProperties.showFlowProgress.next(null);
    });
  }

  public overwriteShowLiveHelpButton(value: boolean): Observable<never> {
    return new Observable(() => {
      this._overwrittenProperties.showLiveHelpButton.next(value);
      return () => this._overwrittenProperties.showLiveHelpButton.next(null);
    });
  }

  public overwriteShowKateButton(value: boolean): Observable<never> {
    return new Observable(() => {
      this._overwrittenProperties.showKateButton.next(value);
      return () => this._overwrittenProperties.showKateButton.next(null);
    });
  }

  public overwriteShowExtraActionButton(value: boolean): Observable<never> {
    return new Observable(() => {
      this._overwrittenProperties.showExtraActionButton.next(value);
      return () => this._overwrittenProperties.showExtraActionButton.next(null);
    });
  }
}
