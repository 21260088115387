import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule GeneralSearchSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaGeneralSearch]',
  template: '<svg:path d="M258.8 468.2L110.3 319.7c29.2-34 46.8-78.1 46.8-126.3C157.1 86.5 70.1-.5-36.8-.5s-194 87-194 193.9 87 193.9 193.9 193.9c42.4 0 81.6-13.7 113.5-36.8l149.9 149.9 32.3-32.2zM-208 193.4C-208 99-131.2 22.3-36.9 22.3s171.1 76.8 171.1 171.1S57.4 364.5-36.9 364.5-208 287.8-208 193.4z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '-243 -6 512 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralSearchSvgComponent {
}

@NgModule({
  declarations: [
    GeneralSearchSvgComponent,
  ],
  exports: [
    GeneralSearchSvgComponent,
  ],
})
export class GeneralSearchSvgModule {}
