import {Observable} from 'rxjs';

import {ComponentFactory, Injectable, Injector, TemplateRef} from '@angular/core';

import {ModalTemplateContext} from '../interfaces/modal-content-description';
import {ModalContentComponent} from '../interfaces/modal-content.component';
import {ModalOptions, ModalOptionsWithInput} from '../interfaces/modal-options';
import {ModalResult} from '../interfaces/modal-result';

/**
 * The ModalController is used to instantiate and show modals.
 */
@Injectable()
export abstract class ModalController {
  /**
   * Creates and presents a new modal to the user.
   *
   * @param modalType The type of the modal, to be used in modal lifecycle callbacks
   * @param parentInjector The parent injector for creating the modal and backdrop.
   * @param componentFactory The component factory for creating the modal component.
   * @param contentFactory The component factory for creating the modal's content.
   * @param options The options for this modal.
   * @return An observable that emits the result
   */
  public abstract prepare<T>(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentFactory: ComponentFactory<ModalContentComponent<T>>,
    options?: ModalOptions,
  ): Observable<ModalResult<T>>;
  public abstract prepare<T, I>(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentFactory: ComponentFactory<ModalContentComponent<T, I>>,
    options: ModalOptionsWithInput<I>,
  ): Observable<ModalResult<T>>;

  /**
   * Creates and presents a new modal to the user.
   *
   * Note that the given providers will be available in the modal component itself but not in the
   * content component or its contents. You shouldn't need to access these, however, because using a
   * `TemplateRef` gives you access to everything on the containing component.
   *
   * @param modalType The type of the modal, to be used in modal lifecycle callbacks
   * @param parentInjector The parent injector for creating the modal and backdrop.
   * @param componentFactory The component factory for creating the modal component.
   * @param contentTemplate The template for creating the modal's content.
   * @param options The options for this modal.
   * @param contentContext Context to pass on to the content when instantiating the template. A
   * `control` property with the `ModalControl` will be added to the context.
   * @return An observable that emits the result
   */
  public abstract prepareTemplate<T>(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentTemplate: TemplateRef<ModalTemplateContext<T>>,
    options?: ModalOptions,
    contentContext?: {},
  ): Observable<ModalResult<T>>;
  public abstract prepareTemplate<T, C extends ModalTemplateContext<T>>(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentTemplate: TemplateRef<C>,
    options: ModalOptions | undefined,
    contentContext: Omit<C, 'control' | '$implicit'>,
  ): Observable<ModalResult<T>>;
  public abstract prepareTemplate<T, I>(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentTemplate: TemplateRef<ModalTemplateContext<T, I>>,
    options: ModalOptionsWithInput<I>,
    contentContext?: {},
  ): Observable<ModalResult<T>>;
  public abstract prepareTemplate<T, I, C extends ModalTemplateContext<T, I>>(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentTemplate: TemplateRef<C>,
    options: ModalOptionsWithInput<I>,
    contentContext: Omit<C, 'control' | '$implicit'>,
  ): Observable<ModalResult<T>>;

  /**
   * Creates a shield modal and presents it to the user.
   *
   * @param options The options for this shield modal.
   * @return A promise resolved when the shield modal is closed.
   */
  public abstract prepareShield(options?: ModalOptions<void>): Observable<ModalResult<never>>;

  /**
   * Creates and presents a new modal on top of all other modals
   *
   * @param modalType The type of the modal, to be used in modal lifecycle callbacks
   * @param parentInjector The parent injector for creating the modal and backdrop.
   * @param componentFactory The component factory for creating the modal component.
   * @param contentFactory The component factory for creating the modal's content.
   * @param options The options for this modal.
   * @return An observable that emits the result
   */
  public abstract prepareOnTop<T>(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentFactory: ComponentFactory<ModalContentComponent<T>>,
    options?: Omit<ModalOptions, 'withBackdrop' | 'withVisibleBackdrop' | 'withClickableBackdrop'>,
  ): Observable<ModalResult<T>>;
  public abstract prepareOnTop<T, I>(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentFactory: ComponentFactory<ModalContentComponent<T, I>>,
    options: Omit<
      ModalOptionsWithInput<I>,
      'withBackdrop' | 'withVisibleBackdrop' | 'withClickableBackdrop'
    >,
  ): Observable<ModalResult<T>>;
  public abstract prepareOnTop<T, I = undefined>(
    modalType: string,
    parentInjector: Injector,
    componentFactory: ComponentFactory<any>,
    contentFactory: ComponentFactory<ModalContentComponent<T, I>>,
    options?: Omit<
      ModalOptions<I>,
      'withBackdrop' | 'withVisibleBackdrop' | 'withClickableBackdrop'
    >,
  ): Observable<ModalResult<T>>;
}
