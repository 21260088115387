import {ChangeDetectionStrategy, Component, HostBinding, Input, Optional} from '@angular/core';
import {TileDocumentSignComponent} from '../tile-document-sign/tile-document-sign.component';
import {throwErrorWhenInvalidParent} from '../tile-document-sign/tile-document-sign.util';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

/**
 * maia-tile-document-sign-label Component
 *
 * This component contains the icon and label
 * Can contain `img` or `svg`
 * Can only be used inside a maia-tile-document-sign
 * you must pass `signed` property to get the signed icon
 * Example
 ```html
 <maia-tile-document-sign>
    <maia-tile-document-sign-icon>
       <svg class="maia-icon" ivyMaiaFileFuncDocumentSigned></svg>
     </maia-tile-document-sign-icon>
     <maia-tile-document-sign-text>Lorem ipsum dolor sit amet</maia-tile-document-sign-text>
     <maia-tile-document-sign-label signed>
       <maia-tile-document-sign-icon>
         <svg class="maia-icon" ivyMaiaAlertFuncCheck></svg>
       </maia-tile-document-sign-icon>
         <button maiaButton>Signed</button>
     </maia-tile-document-sign-label>
 </maia-tile-document-sign>
 ```
 * @ngModule TilesModule
 */
@Component({
  selector: 'maia-tile-document-sign-label',
  template: '<ng-content></ng-content>',
  styleUrls: ['./tile-document-sign-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileDocumentSignLabelComponent {
  /**
   * Property to indicate the tile has a signed icon
   * If true, add a class that add change color of icon
   */
  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('class.maia-tile-document-sign--color-change')
  public signed = false;

  public constructor(@Optional() parent?: TileDocumentSignComponent) {
    throwErrorWhenInvalidParent('<maia-tile-document-sign-label />', parent);
  }
}
