import {Directive, forwardRef, Inject} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {isValidPhoneNumber, PhoneNumber, PhoneNumberFormatter} from '@atlas/businesstypes';

import {
  ATLAS_VALUE_ACCESSOR,
  AtlasControlValueAccessor,
  BaseBusinesstypeDirective,
} from '../base/index';

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasPhoneNumber]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PhoneNumberDirective),
    },
  ],
})
export class PhoneNumberDirective extends BaseBusinesstypeDirective {
  public constructor(@Inject(ATLAS_VALUE_ACCESSOR) input: AtlasControlValueAccessor) {
    super(input);
  }

  public writeValue(value?: PhoneNumber | string): void {
    this._input.writeValue(
      PhoneNumber.isPhoneNumber(value)
        ? PhoneNumberFormatter.format(value)
        : value == null
        ? ''
        : value,
    );
  }

  public registerOnChange(fn: (_: PhoneNumber | string | null) => void): void {
    this._input.registerOnChange(value => {
      if (value === '') {
        fn(null);
        return;
      }

      const unformatted = PhoneNumberFormatter.unformat(value);
      fn(isValidPhoneNumber(unformatted) ? new PhoneNumber(unformatted) : unformatted);
    });
  }
}
