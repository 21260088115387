import {FocusOrigin} from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnInit,
} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {DomIoService, Utilities} from '@maia/core';
import {ModalControl} from '@maia/modals';

import {fromEvent, merge, of, pipe, timer} from 'rxjs';
import {distinctUntilChanged, mapTo, startWith, switchMapTo, withLatestFrom} from 'rxjs/operators';

import {Option, OptionContainer} from './option';

const generateId = Utilities.createIdGenerator('maia-option');

/**
 * Maps an observable onto booleans
 *
 * - Emit `true` immediately
 * - Emit `false` when a value is emitted by the source observable
 * - Emit `true` again 200ms after the value is emitted
 * - If a new value is emitted before the 200ms target, cancel and restart the timer
 */
const mapToListenToHover = pipe(
  // map to false immediately + true 200ms later
  // switchMap to abort the timer if a new value is emitted
  switchMapTo(merge(of(false), timer(200).pipe(mapTo(true)))),
  // start with true immediately
  startWith(true),
  // don't emit duplicate values (the switchMapTo can emit multiple consecutive false values)
  distinctUntilChanged(),
);

function findScrollingParent(element: Element): Element | null {
  const parent = element.parentElement;

  // If we reach an element without parent we have been destroyed in the mean time, so ignore!
  if (parent == null) {
    return null;
  }

  if (parent.scrollHeight > parent.clientHeight) {
    return parent;
  }

  // Don't go looking further than the modal element
  if (parent.classList.contains('p-maia-modal')) {
    return null;
  }

  return findScrollingParent(parent);
}

function getTargetScrollPosition(
  element: HTMLElement,
  scrollingElement: HTMLElement,
  focusOrigin: FocusOrigin,
): number | undefined {
  const offsetTop = element.offsetTop - scrollingElement.offsetTop;

  if (focusOrigin === 'keyboard') {
    // The user is navigating using the arrow keys

    const {clientHeight: optionHeight} = element;
    const {clientHeight: containerHeight, scrollTop} = scrollingElement;

    if (offsetTop < scrollTop) {
      // Item is (partially) scrolled out of view at the top
      return offsetTop;
    } else if (offsetTop + optionHeight > scrollTop + containerHeight) {
      // Item is (partially) scrolled out of view at the bottom
      return offsetTop + optionHeight - containerHeight;
    } else {
      // Item is completely in the view, no need to scroll
      return undefined;
    }
  } else {
    // The option is activating programmaticaly, e.g. because it is the first matching option
    // in the input-autocomplete

    // Always scroll the active option to the top of the dropdown / slide-in
    // This is
    // 1) a UX requirement
    // 2) a workaround for an issue on iOS where we don't know the keyboard is open so the
    // option
    //    might actually be invisible but Safari doesn't tell us
    return offsetTop;
  }
}

/**
 * @ngModule OptionModule
 */
@Component({
  selector: 'maia-option:not([custom])',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    role: 'option',
    '[attr.aria-selected]': 'selected ? true : null',
  },
  providers: [{provide: Option, useExisting: forwardRef(() => OptionComponent)}],
})
@UntilDestroy()
export class OptionComponent<T> implements Option<T>, OnInit {
  private _isActive = false;
  private _isScrolling = false;

  @Input()
  public value: T;

  // At the moment this is always undefined, because there's no definition as to how an option
  // should look when it's disabled. Once this style is defined, we can hook this up to an @Input
  // and get proper disabled option support.
  // Until that happens, we'll assume disabled options don't exist.
  public disabled?: boolean = undefined;

  @HostBinding('class.p-maia-option--selected')
  @HostBinding('class.maia-option--selected')
  public selected = false;

  @HostBinding('id')
  @Input()
  public id = generateId();

  public control: ModalControl<Option<T>, any>;

  public constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _container: OptionContainer<T>,
    private readonly _domIo: DomIoService,
    private readonly _element: ElementRef<HTMLElement>,
    private readonly _zone: NgZone,
  ) {}

  public ngOnInit(): void {
    this._zone.runOutsideAngular(() => {
      fromEvent(this._element.nativeElement, 'mousemove', {passive: true})
        .pipe(
          takeUntilDestroyed(this),
          withLatestFrom(this._container.keyEvent$.pipe(mapToListenToHover)),
        )
        .subscribe(([_, listenToHover]) => {
          if (!this.disabled && !this._isActive && listenToHover) {
            this._zone.run(() => this._container.activateOption(this, 'mouse'));
          }
        });
    });
  }

  @HostBinding('class.p-maia-option--active')
  @HostBinding('class.maia-option--active')
  public get isActive(): boolean {
    return this._isActive;
  }

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    event.preventDefault();

    if (!this.disabled) {
      this.control.confirm(this);
    }
  }

  // When used in a ActiveDescendantKeyManager

  public setActiveStyles(focusOrigin: FocusOrigin): void {
    this._isActive = true;
    this._cdr.markForCheck();

    if (focusOrigin !== 'mouse' && focusOrigin !== 'touch') {
      // Scroll the element into view iff the element was focused through keyboard interaction.
      // Not doing this on hover/touch ensures the UI doesn't jump under the user's pointer.
      this._scrollIntoView(focusOrigin);
    }
  }

  private _scrollIntoView(focusOrigin: FocusOrigin): void {
    // istanbul ignore if Hard to test
    if (this._isScrolling) {
      return;
    }
    this._isScrolling = true;

    this._domIo.measure(() => {
      if (!this._isActive) {
        this._isScrolling = false;
        return;
      }

      const element = this._element.nativeElement;
      const scrollingElement = findScrollingParent(element);

      if (scrollingElement == null) {
        // No scrolling? That's easy then
        return;
      }

      const scrollTo = getTargetScrollPosition(
        element,
        scrollingElement as HTMLElement,
        focusOrigin,
      );

      if (scrollTo == null) {
        this._isScrolling = false;
        return;
      }

      this._domIo.mutate(() => {
        // istanbul ignore if This is very hard to test because we can't tell angular's fakeAsync
        // to only tick for the measure function and not the mutate function
        if (!this._isActive) {
          this._isScrolling = false;
          return;
        }

        if (typeof scrollingElement.scrollTo === 'function') {
          scrollingElement.scrollTo(0, scrollTo);
        } else {
          scrollingElement.scrollTop = scrollTo;
        }
        this._isScrolling = false;
      });
    });
  }

  public setInactiveStyles(): void {
    this._isActive = false;

    // Important note: if the options change and the active option gets removed from the DOM, this
    // method will be called _after_ destruction of the component. The changeDetector doesn't like
    // calling detectChanges() on a destroyed component, so we use markForCheck() instead.
    this._cdr.markForCheck();
  }
}
