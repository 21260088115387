import {Component, ContentChild, ElementRef, Input, OnInit, TemplateRef} from '@angular/core';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {OptionComponent} from '@maia/select';
import {PhonenumberCountry} from './phonenumber-country';

@Component({
  selector: 'maia-phonenumber-country',
  templateUrl: './phonenumber-country.component.html',
  styleUrls: ['./phonenumber-country.component.scss'],
})
@UntilDestroy()
export class PhonenumberCountryComponent
  extends OptionComponent<PhonenumberCountry>
  implements OnInit {
  @Input()
  public prefix: string;

  @Input()
  public name: string;

  @ContentChild('flag')
  public flag: TemplateRef<ElementRef>;

  public ngOnInit(): void {
    this.value = this;
    super.ngOnInit();
  }
}
