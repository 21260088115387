import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionFuncBulletSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaActionFuncBullet]',
  template: '<svg:g class="nc-icon-wrapper" transform="matrix(.8 0 0 .8 2.4 2.4)"><svg:g><svg:circle cx="12" cy="12" r="3.677"></svg:circle></svg:g></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionFuncBulletSvgComponent {
}

@NgModule({
  declarations: [
    ActionFuncBulletSvgComponent,
  ],
  exports: [
    ActionFuncBulletSvgComponent,
  ],
})
export class ActionFuncBulletSvgModule {}
