import {ChangeDetectionStrategy, Component, Optional} from '@angular/core';
import {TileDocumentSignComponent} from '../tile-document-sign/tile-document-sign.component';
import {throwErrorWhenInvalidParent} from '../tile-document-sign/tile-document-sign.util';

/**
 * maia-tile-document-sign-icon Component
 *
 * This component contains the icon
 * Can contain `img` or `svg`
 * Can only be used inside a maia-tile-document-sign
 *
 * @ngModule TilesModule
 */
@Component({
  selector: 'maia-tile-document-sign-icon',
  template: '<ng-content></ng-content>',
  styleUrls: ['./tile-document-sign-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileDocumentSignIconComponent {
  public constructor(@Optional() parent?: TileDocumentSignComponent) {
    throwErrorWhenInvalidParent('<maia-tile-document-sign-icon />', parent);
  }
}
