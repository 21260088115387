import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

import {FormComponent} from './form.component';

/**
 * @ngModule FormsModule
 */
@Component({
  selector: 'form[maiaForm][loading]',
  templateUrl: './loading-form.component.html',
  styleUrls: ['./loading-form.component.scss'],

  // Forms don't hold state and they don't accept any mutable inputs, so we can use OnPush
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingFormComponent extends FormComponent {
  private _loading = false;

  /**
   * Whether or not the form is ready. This defaults to `false`.
   */
  @Input()
  @HostBinding('class.maia-form--loading')
  public get loading(): boolean {
    return this._loading;
  }

  public set loading(loading: boolean) {
    this._loading = coerceBooleanProperty(loading);
  }
}
