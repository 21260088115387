import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {
  DropdownHost,
  DropdownOptions,
  DropdownPosition,
  DropdownTemplateContext,
} from '@maia/dropdowns';
import {CapturedInput} from '@maia/forms/capture';
import {BaseInputSelectComponent, μInputSelectOpener} from '@maia/input-select';
import {Option} from '@maia/select';

import {PhonenumberCountry} from '../phonenumber-country/phonenumber-country';

@Component({
  selector: 'maia-country-dropdown',
  templateUrl: './country-dropdown.component.html',
  styleUrls: ['./country-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountryDropdownComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class CountryDropdownComponent extends BaseInputSelectComponent<PhonenumberCountry> {
  /** @internal */
  @Input()
  public dropdownHost: DropdownHost;

  /** @internal */
  @Input()
  public dropdownContent: TemplateRef<DropdownTemplateContext<Option<PhonenumberCountry>>>;

  private _options?: QueryList<Option<PhonenumberCountry>>;

  @Input()
  public set options(value: QueryList<Option<PhonenumberCountry>>) {
    if (this._options == null) {
      this._options = value;
      this.initKeyManager();
    }
  }

  public get options(): QueryList<Option<PhonenumberCountry>> {
    return this._options || new QueryList();
  }

  private _value: PhonenumberCountry = {} as PhonenumberCountry;

  public set value(country: PhonenumberCountry) {
    if (!country) {
      return;
    }

    this._value = country;
  }

  public get value(): PhonenumberCountry {
    return this._value;
  }

  /** @internal */
  @ViewChild('countryDropdownInput', {read: CapturedInput, static: true})
  public capturedInput: CapturedInput;

  public constructor(cdr: ChangeDetectorRef, renderer: Renderer2, opener?: μInputSelectOpener) {
    super(cdr, renderer, opener);
  }

  protected _hasCapturedInput(): boolean {
    return true;
  }

  protected get _dropdownOptions(): DropdownOptions {
    return {position: DropdownPosition.BOTTOM_ALIGNED};
  }

  public openDropdown(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this._open();
  }
}
