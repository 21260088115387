import {EMPTY, merge, MonoTypeOperatorFunction, Observable, of, timer} from 'rxjs';
import {mapTo, mergeMapTo} from 'rxjs/operators';

/**
 * Timeboxes the source observable by not allowing it to complete between `offset` and
 * `offset + minimumDuration` milliseconds
 *
 * The resulting observable emits all values and errors of the source observable when they are
 * encountered. Completion of the source may be delayed:
 *
 * - If the source completes before `offset` milliseconds have passed or after
 *   `offset + minimumDuration` have passed , the resulting observable completes immediately when
 *   source completes.
 * - If the source completes after `offset` milliseconds have passed but before
 *   `offset + minimumDuration` milliseconds have passed, the completion is delayed until `offset +
 *   minimumDuration` milliseconds have passed.
 *
 * @param offset The start of the subscription to the given observable
 * @param minimumDuration The minimum duration of the subscription to the given observable
 * @param observable The observable to subscribe to, if needed
 */
export function timeboxWith<T>(
  offset: number,
  minimumDuration: number,
  observable: Observable<unknown>,
): MonoTypeOperatorFunction<T> {
  return source =>
    new Observable(observer => {
      let canComplete = true;
      let shouldComplete = false;

      observer.add(
        timer(offset)
          .pipe(
            mergeMapTo(
              merge(
                of(false),
                timer(minimumDuration).pipe(mapTo(true)),
                observable.pipe(mergeMapTo(EMPTY)),
              ),
            ),
          )
          .subscribe(cc => {
            canComplete = cc;

            if (canComplete && shouldComplete) {
              observer.complete();
            }
          }),
      );

      return source.subscribe({
        next: value => observer.next(value),
        error: err => observer.error(err),
        complete() {
          if (canComplete) {
            observer.complete();
          } else {
            shouldComplete = true;
          }
        },
      });
    });
}
