import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

/**
 *
 * @ngModule TilesModule
 * @deprecated in favor of maia-image-content-layout
 *
 * Image Tile Component
 * Large version
 *
 * This is the large version of the Image Tile component requires a `large` attribute to be present
 *
 * This component contains an image-tile-image, image-tile-icon, image-tile-label and optional image-tile-sublabel
 * The image-tile-icon and image-tile-sublabel can be optional
 *
 * An example using an svg background image and an svg icon:
 *
 * ``` html
 * <maia-image-tile large>
 *   <maia-image-tile-image>
 *     <svg ivyMaiaAvatarUtilPrivate></svg>
 *   </maia-image-tile-image>
 *   <maia-image-tile-icon>
 *     <svg>...</svg>
 *   </maia-image-tile-icon>
 *   <maia-image-tile-label>Seat Ibiza</maia-image-tile-label>
 *   <maia-image-tile-sublabel>1-BJB-007</maia-image-tile-sublabel>
 * </maia-image-tile>
 * ```
 */
@Component({
  selector: 'maia-image-tile[large]',
  templateUrl: './image-tile-large.component.html',
  styleUrls: ['./image-tile-large.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.p-maia-image-tile--large]': 'true',
  },
})
export class ImageTileLargeComponent {
  /**
   * Optional property to remove the padding to the image in an <maia-image-tile large>
   * By default = false
   */
  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('class.p-maia-image-tile-large--without-padding')
  public withoutPadding? = false;
}
