import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  HostListener,
  Optional,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Boolean} from '@atlas/businesstypes';
import {FormElementComponent} from '@maia/forms';

export const DEFAULT_SWITCH_VALUE = false;

export const DEFAULT_SWITCH_DISABLED_VALUE = false;

/**
 * Switch Component
 *
 * @ngModule SwitchesModule
 */
@Component({
  selector: 'maia-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => SwitchComponent)},
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    role: 'checkbox',
    tabindex: '0',
  },
})
export class SwitchComponent implements ControlValueAccessor {
  private _checked = DEFAULT_SWITCH_VALUE;

  private _disabled = DEFAULT_SWITCH_DISABLED_VALUE;

  public constructor(
    private readonly _cdr: ChangeDetectorRef,
    @Optional() parent?: FormElementComponent,
  ) {
    if (parent != null) {
      parent.hideOptionalIndicator = true;
    }
  }

  private _onChange = (_: any) => {};

  @HostListener('blur')
  public _onTouched = () => {};

  @HostListener('keydown.space', ['$event'])
  public _handleKeyboardEvent(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();
    this._toggleChecked();
  }

  @HostListener('click', ['$event'])
  @HostListener('touchend', ['$event'])
  public _toggleChecked(event?: MouseEvent) {
    if (event) {
      // click also triggers touchend, so prevent that
      event.preventDefault();
    }
    if (!this._disabled) {
      this._setChecked(!this._checked);
    }
  }

  private _setChecked(checked: boolean) {
    this.writeValue(checked);
    this._onChange(new Boolean(checked));
  }

  @HostBinding('class.maia-switch--checked')
  @HostBinding('attr.aria-checked')
  public get checked(): boolean {
    return this._checked;
  }

  @HostBinding('class.maia-switch--disabled')
  @HostBinding('attr.aria-disabled')
  public get disabled(): boolean {
    return this._disabled;
  }

  public writeValue(value?: Boolean | string | boolean): void {
    // Don't use _setChecked here because that would trigger onTouched
    this._checked =
      value == null
        ? false
        : typeof value === 'string'
        ? value === 'true'
        : Boolean.isBoolean(value)
        ? value.asBoolean()
        : value;
    this._cdr.markForCheck();
  }

  public registerOnChange(fn: (_: Boolean | null) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }
}
