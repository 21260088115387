import {ElementRef} from '@angular/core';
import {onDestroy} from '@atlas-angular/rxjs';
import {Utilities} from '@maia/core';

/**
 * Ensure the top of the slide-in is shown when it opens.
 *
 * iOS lies about the size of the viewport if the keyboard is open. This can lead to weird behaviour
 * like the top half of the freshly opened slide-in being hidden off-screen.
 *
 * Verifies whether the given element is scrolled out at the top, and scrolls if not.
 *
 * This function potentially triggers synchronous layouts. It can only be used in code that runs as
 * response to user activity, e.g. a tap to open a slide-in.
 *
 * Never run this during an animation!
 *
 * @param destroyable The modal instance
 * @param element The modal element
 */
export function fixIosScroll(destroyable: any, element: ElementRef<Element>) {
  const position = element.nativeElement.getBoundingClientRect().top;

  if (position >= 0) {
    // We're not in the problem case
    return;
  }

  const scrollParent = Utilities.findScrollingParent(element.nativeElement);

  if (scrollParent == null) {
    // Okay, we don't know what parent to scroll on, so abort
    return;
  }

  scrollParent.scrollBy(0, position);
  onDestroy(destroyable).subscribe(() => scrollParent.scrollBy(0, -position));
}
