/**
 * Options for a modal's backdrop.
 */
export abstract class BackdropOptions {
  /**
   * Whether or not the backdrop should be transparent.
   */
  public transparent: boolean;

  /**
   * Whether or not the backdrop should be clickable.
   */
  public clickable: boolean;

  /**
   * Whether or not the scroll state of the application should be locked while the backdrop is open.
   */
  public blockScrolling: boolean;
}
