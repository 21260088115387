import {arrayRemove} from './array-remove.utility';
import {findScrollingParent} from './find-scrolling-parent';
import {createIdGenerator} from './id-generator';
import {randomizeClass} from './randomize-class.utility';
import {triggerSynchronousLayout} from './trigger-synchronous-layout';

export const Utilities = {
  arrayRemove,
  findScrollingParent,
  createIdGenerator,
  randomizeClass,
  triggerSynchronousLayout,
};
