import {ChangeDetectionStrategy, Component, Optional} from '@angular/core';

import {ImageTileLargeComponent} from '../image-tile-large/image-tile-large.component';
import {ImageTileComponent} from '../image-tile/image-tile.component';

/**
 * Image Tile Image Component
 *
 * This component contains the background image for the left/top section
 * Can contain `img` or `svg`
 * Can only be used inside a maia-image-tile
 *
 * @ngModule TilesModule
 */
@Component({
  selector: 'maia-image-tile-image',
  template: '<ng-content></ng-content>',
  styleUrls: ['./image-tile-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageTileImageComponent {
  public constructor(
    @Optional() parent?: ImageTileComponent,
    @Optional() parentLarge?: ImageTileLargeComponent,
  ) {
    if (parent == null && parentLarge == null) {
      throw new Error(
        'Cannot create a <maia-image-tile-image /> component out of <maia-image-tile /> component',
      );
    }
  }
}
