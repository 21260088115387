import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 * @ngModule FormsModule
 */
@Component({
  selector: 'form[maiaForm]:not([loading])',
  template: '<ng-content></ng-content>',
  styleUrls: ['./form.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent {}
