import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {BusinessTypesModule} from '@atlas-angular/businesstypes';

import {DisplaysModule} from '@maia/displays';
import {PanelsModule} from '@maia/panels';
import {TilesModule} from '@maia/tiles';

import {PriceBlockComponent} from './price-block.component';

@NgModule({
  imports: [BusinessTypesModule, CommonModule, DisplaysModule, PanelsModule, TilesModule],
  declarations: [PriceBlockComponent],
  exports: [PriceBlockComponent],
})
export class PriceBlockModule {}
