import {BusinessTypeConstructor, αgetEncryptedValue} from '@atlas/businesstypes';
import {AnyBusinessType} from '@atlas/convertor';

import {BusinessTypeConvertor, BusinessTypesConvertor} from '../businesstype-convertor';

/**
 * Map containing the convertors for each businesstype with the specific `BusinessType`
 * subtype as key.
 */
const fromBusinessTypeRegistry = new Map<
  BusinessTypeConstructor<AnyBusinessType>,
  BusinessTypeConvertor<AnyBusinessType>
>();

/**
 * Converts between any `BusinessType` and their corresponding `TypedJSON` representation.
 */
export const businessTypeConvertor: BusinessTypesConvertor = {
  fromBusinessType(value: AnyBusinessType): string {
    const ctor = value.constructor as BusinessTypeConstructor<AnyBusinessType>;

    if (fromBusinessTypeRegistry.has(ctor)) {
      return fromBusinessTypeRegistry.get(ctor)!.fromBusinessType(value);
    }

    const encryptedValue = αgetEncryptedValue(value);

    if (encryptedValue != null) {
      return encryptedValue;
    }

    const jsonValue = ctor.toJsonValue(value);

    if (jsonValue == null) {
      throw new Error(`Invalid result from toJsonValue: ${jsonValue}`);
    }

    if (typeof jsonValue === 'boolean') {
      // TODO(ATLAS-339): Break this in atlas 5 and return booleans
      return String(jsonValue);
    }

    if (typeof jsonValue !== 'string') {
      // TODO(ATLAS-339): Break this in atlas 5 and allow any JSON value
      throw new Error('Businesstypes must serialize to booleans or strings');
    }

    return jsonValue;
  },
};

/**
 * A concrete businesstype implementation must at least provide a constructor
 * accepting a string value and an optional encrypted value for the convertor
 * to be able to use it.
 *
 * @deprecated
 */
export interface BusinessTypeImpl<T> {
  new (value: string, encryptedValue?: string): T;
}

/**
 * @deprecated Ensure your custom businesstype class is a valid {@link BusinessTypeConstructor} instead
 */
export function registerConvertor<BT extends AnyBusinessType, T extends string>(
  businesstype: BusinessTypeImpl<BT>,
  convertor: BusinessTypeConvertor<BT>,
  ...types: T[]
): void;
export function registerConvertor<BT extends AnyBusinessType>(
  businesstype: BusinessTypeImpl<BT>,
  convertor: BusinessTypeConvertor<BT>,
): void {
  fromBusinessTypeRegistry.set((businesstype as unknown) as BusinessTypeConstructor<BT>, convertor);
}
