import {Pipe, PipeTransform} from '@angular/core';

export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

/**
 * @ngModule TablesModule
 */
@Pipe({name: 'orderBy'})
export class OrderByPipe implements PipeTransform {
  public transform(value?: number[], query?: string): number[] | null {
    if (!Array.isArray(value)) {
      return null;
    }
    switch (query) {
      case SORT_DIRECTION.ASC:
        return value.sort((a: number, b: number) => a - b);
      case SORT_DIRECTION.DESC:
        return value.sort((a: number, b: number) => b - a);
      default:
        return value;
    }
  }
}
