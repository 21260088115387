import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';

import {BadgeComponent} from './badge/badge.component';
import {BadgeSmallComponent} from './badge-small/badge-small.component';

@NgModule({
  imports: [BusinessTypesModule, CommonModule],
  declarations: [BadgeComponent, BadgeSmallComponent],
  exports: [BadgeComponent, BadgeSmallComponent],
})
export class BadgeModule {}
