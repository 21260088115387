import {Directive, forwardRef, Input, TemplateRef} from '@angular/core';
import {DropdownTemplateContext, SmallDropdownVisualisation} from '@maia/dropdowns';
import {SelectDropdownComponent} from '@maia/dropdowns/select';
import {ModalResult} from '@maia/modals';
import {Observable} from 'rxjs';

import {Option} from '../option/option';

/**
 * Helper that opens the select dropdown
 */
export abstract class SelectOpener {
  /**
   * Open the select template
   */
  public abstract open<T>(
    dropdown: SelectDropdownComponent<Option<T>>,
    content: TemplateRef<DropdownTemplateContext<Option<T>>>,
  ): Observable<ModalResult<Option<T>>>;
}

/**
 * Opener that always uses a dropdown, regardless of the viewport.
 */
export const OPEN_AS_DROPDOWN: SelectOpener = {
  open(dropdown, content) {
    return dropdown.open(content);
  },
};

/**
 * Opens the select as slide-in on small breakpoints
 *
 * @ngModule SelectModule
 */
@Directive({
  selector: 'maia-select[slideInTitle]',
  providers: [
    {provide: SelectOpener, useExisting: forwardRef(() => OpenSelectWithSlideInDirective)},
  ],
})
export class OpenSelectWithSlideInDirective implements SelectOpener {
  /**
   * The title of the slide-in for the select on small breakpoints
   */
  @Input()
  public slideInTitle: string;

  public open<T>(
    dropdown: SelectDropdownComponent<Option<T>>,
    content: TemplateRef<DropdownTemplateContext<Option<T>>>,
  ): Observable<ModalResult<Option<T>>> {
    return dropdown.open(content, {
      visualisation: SmallDropdownVisualisation.SlideIn,
      options: {
        title: this.slideInTitle,
        fullWidthContent: true,
      },
    });
  }
}
