import {Injectable, Injector} from '@angular/core';
import {Connector} from '@atlas-angular/connector';
import {LoggerFactory} from '@atlas-angular/logger';
import {Boolean, Text} from '@atlas/businesstypes';
import {ConnectorErrorResponse} from '@atlas/convertor';
import {Logger} from '@atlas/logger';
import {defer, noop, Observable, of, throwError} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';
import {DecryptTokenCall, ValidateTokenCall, ValidateTokenOutput} from './deeplinking.calls';
import {εJSON_TYPE_TOKEN} from './type';

type DeeplinkingFunctionData =
  | {
      requiresInput: true;
      starterFunction: (input: object) => void;
    }
  | {
      requiresInput: false;
      starterFunction: () => void;
    }
  | {
      requiresInput: null;
      starterFunction: (input?: object) => void;
    };

/**
 * Central Deeplinking Service. This service handles all deeplinking logic.
 */
@Injectable()
export class DeeplinkingService {
  private readonly logger: Logger;
  private readonly registeredFunctions: Map<string, DeeplinkingFunctionData> = new Map();

  /**
   * The function to handle errors when starting functions via deeplinking.
   */
  public errorFunction: () => void = noop;

  /**
   * Possibility to run some preparation steps before starting the deeplink.
   */
  public prepare: () => Observable<void> = () => of(undefined);

  public constructor(
    private readonly connector: Connector,
    private readonly injector: Injector,
    loggerFactory: LoggerFactory,
  ) {
    this.logger = loggerFactory.createLogger('DeeplinkingService');
  }

  /**
   * Registers a deeplinking function that requires input
   *
   * Duplicate function names are not allowed. Trying to register a function twice will result in
   * an error being thrown
   *
   * @param name The name of the function.
   * @param requiresInput Whether this function requires input (i.e. a token)
   * @param starterFunction The function that starts the correct transaction
   */
  public registerFunction(
    name: string,
    requiresInput: true,
    starterFunction: (input: object) => void,
  ): void;
  /**
   * Registers a deeplinking function that doesn't accept input
   *
   * Duplicate function names are not allowed. Trying to register a function twice will result in
   * an error being thrown
   *
   * @param name The name of the function.
   * @param requiresInput Whether this function requires input (i.e. a token)
   * @param starterFunction The function that starts the correct transaction
   */
  public registerFunction(name: string, requiresInput: false, starterFunction: () => void): void;
  /**
   * Registers a deeplinking function that doesn't require but accepts input
   *
   * Duplicate function names are not allowed. Trying to register a function twice will result in
   * an error being thrown
   *
   * @param name The name of the function.
   * @param requiresInput Whether this function requires input (i.e. a token)
   * @param starterFunction The function that starts the correct transaction
   */
  public registerFunction(
    name: string,
    requiresInput: null,
    starterFunction: (input?: object) => void,
  ): void;
  public registerFunction(
    name: string,
    requiresInput: DeeplinkingFunctionData['requiresInput'],
    starterFunction: DeeplinkingFunctionData['starterFunction'],
  ): void {
    if (this.hasFunction(name)) {
      throw new Error(
        `Function with name '${name}' already exists. Cannot register multiple functions with the same name`,
      );
    }
    this.registeredFunctions.set(name, {requiresInput, starterFunction} as DeeplinkingFunctionData);
  }

  /**
   * Validates a token containing the input for a deeplinked function
   *
   * @param token the token to validate
   * @param callerId the id identifying the encryption used
   * @param categoryId the encryption's category
   * @returns an observable that will be resolved with a boolean indicating if the token is valid
   */
  public validateToken(token: Text, callerId?: Text, categoryId?: Text): Observable<Boolean> {
    const jsonType = this.injector.get(εJSON_TYPE_TOKEN);
    const call = new ValidateTokenCall(jsonType);
    const input = {token, callerId, categoryId};
    return this.connector
      .prepare(call, input)
      .pipe(map((response: ValidateTokenOutput) => response.valid));
  }

  /**
   * Decrypts a token into an object containing the input for a deeplinked function
   *
   * @param token the token to decrypt
   * @param callerId the id identifying the encryption used
   * @param categoryId the encryption's category
   * @returns an observable that will be resolved with the input for the function
   */
  public decryptToken(token: Text, callerId?: Text, categoryId?: Text): Observable<any> {
    const jsonType = this.injector.get(εJSON_TYPE_TOKEN);
    const call = new DecryptTokenCall(jsonType);
    const input = {token, callerId, categoryId};
    return this.connector.prepare(call, input);
  }

  /**
   * Starts a deeplink.
   *
   * When an error occurs trying to start the function, the registered errorFunction will be started
   * (if available)
   *
   * @param functionName the name of the function to start
   * @param token the token containing the encrypted input for the function
   * @param callerId the id identifying the encryption used
   * @param categoryId the encryption's category
   */
  public start(functionName: string, token?: Text, callerId?: Text, categoryId?: Text): void {
    this.startFunction(functionName, token, callerId, categoryId).subscribe({
      error: message => {
        this.logger.error(
          `Error occurred when starting function '${functionName}. Original message ${message}`,
        );
        this.errorFunction();
      },
    });
  }

  /**
   * Starts a deeplink without error handling.
   *
   * @param functionName the name of the function to start
   * @param token the token containing the encrypted input for the function
   * @param callerId the id identifying the encryption used
   * @param categoryId the encryption's category
   */
  public startFunction(
    functionName: string,
    token?: Text,
    callerId?: Text,
    categoryId?: Text,
  ): Observable<void> {
    return defer(() => {
      if (!this.hasFunction(functionName)) {
        return throwError(new Error(`No function registered with name '${functionName}'`));
      }

      // existence with the 'has' function above.
      const registeredFunction = this.registeredFunctions.get(functionName)!;
      if (registeredFunction.requiresInput) {
        if (token == null) {
          return throwError(new Error(`Function '${functionName}' requires input`));
        }
      } else if (registeredFunction.requiresInput === false || token == null) {
        registeredFunction.starterFunction();
        return of(undefined);
      }

      return this.prepare().pipe(
        flatMap(() => this.decryptToken(token, callerId, categoryId)),
        catchError((error: ConnectorErrorResponse<any>) => {
          const detail =
            error.message != null ? `original message: ${error.message}` : 'no more info available';
          return throwError(new Error(`Error decrypting token, ${detail}`));
        }),
        map(contract => registeredFunction.starterFunction(contract)),
      );
    });
  }

  /**
   * Verifies if a given function is registered
   * @param functionName
   */
  public hasFunction(functionName: string): boolean {
    return this.registeredFunctions.has(functionName);
  }
}
