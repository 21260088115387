import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {Injectable} from '@angular/core';
import {
  CanActivateChild,
  CanDeactivate,
  Event as NavigationEvent,
  NavigationStart,
  Router,
} from '@angular/router';
import {filter} from 'rxjs/operators';

import {HeaderController} from './services/controller/header-control.service';

/**
 * @description
 *
 * The header hardware back button guard tries to block the usage of the hardware navigation buttons
 * if its not allowed to navigate backwards. This is achieved by checking if the back button is
 * visible in the header.
 *
 * When a popState trigger was fired and the restoreState contains a lower navigationId we can
 * assume this was by clicking the hardware back button.
 *
 * The HeaderHardwareBackButtonGuard can be used in two modes:
 * - canDeactivate on specific route components for preventing the component to be left. This only
 * works if the component is the deepest route match. So putting it on the parent route will not
 * work for preventing one of it's child routes to be left.
 * - canActivateChild can be used on a parent route for preventing unwanted navigation by the back
 * button all it's child routes.
 *
 * It's a known issue that the guard fails when the user hits multiple times the back button, this
 * is due an bug in angular, more information about this bug can be found
 * [here](https://github.com/angular/angular/issues/13586)
 *
 * On line 72 We applied a hack that was found by accident, but it only works for apps that make use
 * of the `PathLocationStrategy` so currently PSA processes aren't supported.
 *
 * @ngModule HeaderModule
 */
@Injectable({
  providedIn: 'root',
})
export class HeaderHardwareBackButtonGuard implements CanDeactivate<unknown>, CanActivateChild {
  private _blockNavigation = false;
  public constructor(
    private readonly _locationPlatform: LocationStrategy,
    private readonly _location: Location,
    private readonly _router: Router,
    private readonly _headerController: HeaderController,
  ) {
    // Subscribe to the router
    this._router.events
      .pipe(filter((event: NavigationEvent): boolean => event instanceof NavigationStart))
      .subscribe((event: NavigationStart): void => {
        const isPopState = event.navigationTrigger === 'popstate';
        const hasRestoredState = event.restoredState;
        if (isPopState && hasRestoredState) {
          this._blockNavigation = !this._headerController.canGoBack();
        } else {
          this._blockNavigation = false;
        }
      });
  }

  private _blockBackButton(): boolean {
    if (this._blockNavigation) {
      // Hack found by accident, when forcing the location to go to a random hash,
      // the history isn't going back, does only works with the PathLocationStrategy.
      if (this._locationPlatform instanceof PathLocationStrategy) {
        this._location.go('#block-back-button');
      }
      return false;
    }
    return true;
  }

  public canDeactivate(): boolean {
    return this._blockBackButton();
  }

  public canActivateChild(): boolean {
    return this._blockBackButton();
  }
}
