import {Injectable} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {SystemMessage, SystemMessageController} from '@maia/system-messages/toasts';
import {Subscription} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';

import {Message} from './message';
import {MessagingActionService} from './messaging.action.service';
import {MessagingService} from './messaging.service';
import {Messenger} from './messenger';

/**
 * A messaging service handling the rendering of messages as maia-system-messages
 */
@UntilDestroy()
@Injectable({providedIn: 'root'})
export class RenderSystemMessageService extends Messenger {
  private readonly messageList: Subscription[] = [];

  public constructor(
    messagingService: MessagingService,
    messagingActionService: MessagingActionService,
    systemMessageCtrl: SystemMessageController,
  ) {
    super(messagingActionService);

    messagingService
      .onMessage()
      .pipe(
        tap(message =>
          this.messageList.push(
            systemMessageCtrl
              .showSystemMessage(this.mapMessage(message))
              .pipe(takeUntil(messagingService.onClear()))
              .subscribe(),
          ),
        ),
        takeUntilDestroyed(this),
      )
      .subscribe();
  }

  private mapMessage(message: Message): SystemMessage {
    return {
      messageType: message.type,
      messageContent: message.text,
      messageActionLabel: message.actionButtonText,
      hideClose: !message.showCloseButton,
      messageAction: () => this._messagingActionService.onAction(message),
    };
  }
}
