import {Formatter} from '../base/formatter';

import {Date} from './businesstype';
import {DateKey} from './date-constants';
import {DateUtils} from './date-utils';

function padLeft(value: number): string {
  return value < 10 ? `0${value}` : `${value}`;
}

export const DateFormatter: Formatter<Date> = {
  /**
   * Format the date to display on-screen
   *
   * @param value The date to format
   */
  format(value: Date): string {
    return `${padLeft(DateUtils.get(value, DateKey.Day))}-${padLeft(
      DateUtils.get(value, DateKey.Month) + 1,
    )}-${DateUtils.get(value, DateKey.Year)}`;
  },

  /**
   * Remove all date separator characters (spaces, `.`, `/` and `-`) from the pretty value
   *
   * @param value The pretty value
   */
  unformat(value: string): string {
    return value.replace(/[\s/.-]/g, '');
  },
};
