import {Formatter} from '../base/formatter';

import {CHECK_DIGITS_START, NationalRegisterNumber} from './businesstype';

const YEAR_START_POSITION = 0;
const YEAR_END_POSITION = 2;
const MONTH_START_POSITION = 2;
const MONTH_END_POSITION = 4;
const DAY_START_POSITION = 4;
const DAY_END_POSITION = 6;
const DAY_COUNTER_START_POSITION = 6;
const DAY_COUNTER_END_POSITION = 9;

/**
 * The pretty national register number format used to represent national register numbers.
 */
export const NationalRegisterNumberFormatter: Formatter<NationalRegisterNumber> = {
  /**
   * Formats the national register number (e.g. 93051800189 -> 93.05.18-001.89)
   *
   * @param value The national register number to format
   */
  format(value: NationalRegisterNumber): string {
    const stringValue = value.asString();
    return `${stringValue.substring(
      YEAR_START_POSITION,
      YEAR_END_POSITION,
    )}.${stringValue.substring(MONTH_START_POSITION, MONTH_END_POSITION)}.${stringValue.substring(
      DAY_START_POSITION,
      DAY_END_POSITION,
    )}-${stringValue.substring(
      DAY_COUNTER_START_POSITION,
      DAY_COUNTER_END_POSITION,
    )}.${stringValue.substring(CHECK_DIGITS_START)}`;
  },

  /**
   * Removes all separators characters (spaces, `.`  and `-`) from the pretty value.
   *
   * @param value The pretty value
   */
  unformat(value: string): string {
    return value.replace(/[\s/.-]/g, '');
  },
};
