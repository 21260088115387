/* eslint-disable import/no-extraneous-dependencies */
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';
import {μSystemMessagesSvgModule} from '@maia/icons';
import {MiniButtonsModule} from '@maia/mini-buttons';
import {ToastModule} from '@maia/modals/toasts';

import {SystemMessageController} from './system-message-controller.service';
import {µSystemMessageComponent} from './system-message.component';

@NgModule({
  imports: [
    CommonModule,
    ToastModule,
    MiniButtonsModule,
    μSystemMessagesSvgModule,
    BusinessTypesModule,
  ],
  declarations: [µSystemMessageComponent],
  entryComponents: [µSystemMessageComponent],
  providers: [SystemMessageController],
})
export class SystemMessagesModule {}
