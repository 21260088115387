import {onDestroy} from '@atlas-angular/rxjs';

import {ConnectableObservable, MonoTypeOperatorFunction, Observable} from 'rxjs';

const CLASS_DROPDOWN_HOST_OPEN = 'p-maia-dropdownhost--is-open';

/**
 * Operator that connects the source connectable observable on the first subscription
 *
 * This operator can be used in conjunction with the `publish` family of operators (`publish`,
 * `publishReplay`, `publishBehavior`, `publishLast`) to automatically connect the published
 * observable on the first subscription. The connection lasts until the destroyable is destroyed,
 * even if no subscriptions exist anymore. This differs from the `refCount()` operator that
 * destroys the source subscription if no subscribers are left.
 *
 * This can be used to mimick the behaviour of the `share` family of operators, with one big
 * difference: the `share` operators don't store errors. That is, in the example
 *
 * ```ts
 * const source = throwError(new Error('oh no, an error')).pipe(tap({
 *   error: () => console.log('got an error!'),
 * }));
 *
 * const shared = source.pipe(shareReplay(1));
 * const published = source.pipe(publishReplay(1), connectUntilDestroyed(destroyable));
 * ```
 *
 * subscribing to `shared` twice will log `got an error!` twice, while subscribing to `published`
 * twice will log it once.
 *
 * The connection lasts until the destruction of the given destroyable.
 *
 * @param destroyable Destroyable to end the connection
 */
export function connectUntilDestroyed<T>(destroyable: any): MonoTypeOperatorFunction<T> {
  let connected = false;

  return source => {
    return new Observable(observer => {
      const subscription = source.subscribe(observer);

      if (!connected) {
        connected = true;
        const connectableSource = source as ConnectableObservable<T>;
        const connection = connectableSource.connect();

        onDestroy(destroyable).subscribe(() => connection.unsubscribe());
      }

      return subscription;
    });
  };
}

export function withOpenClass<T>(element: Element, source$: Observable<T>): Observable<T> {
  return new Observable(observer => {
    element.classList.add(CLASS_DROPDOWN_HOST_OPEN);
    observer.add(() => element.classList.remove(CLASS_DROPDOWN_HOST_OPEN));

    return source$.subscribe(observer);
  });
}
