import {JsonObject} from '@atlas/businesstypes';

import {PlainJsonCall} from './call';
import {createConvertor, CtorObject} from './enhanced-convertor';

/**
 * A {@link PlainJsonCall} that converts output messages based on a {@link CtorObject}
 */
export abstract class EnhancedPlainJsonCall<I, O> extends PlainJsonCall<I, O> {
  /**
   * @param outputCtorObject The {@link CtorObject} describing the output interface
   */
  public constructor(private readonly outputCtorObject: CtorObject<O>) {
    super();
  }

  /**
   * Converts a message based on the {@link CtorObject} that was passed into the constructor
   */
  public convertMessage(message: JsonObject): O {
    return createConvertor(this.outputCtorObject)(message);
  }
}
