import {TemplateRef, ViewContainerRef} from '@angular/core';
import {Observable} from 'rxjs';

export function createEmbeddedView$<T>(
  viewContainer: ViewContainerRef,
  template: TemplateRef<T>,
  context?: T,
  index?: number,
): Observable<never> {
  return new Observable(() => {
    const view = viewContainer.createEmbeddedView(template, context, index);
    view.markForCheck();

    return () => {
      view.destroy();
    };
  });
}
