import {Injectable} from '@angular/core';
import {WindowRef} from '@atlas-angular/cdk/globals';

/**
 * The `RichTextLinkHandler` handle click on URL in rich text component
 */
@Injectable({providedIn: 'root'})
export class RichTextLinkHandler {
  public constructor(private readonly _window: WindowRef) {}

  /**
   * Handle the click. By default simply open a blank and browse to the URL
   * @param url The URL to browse to
   */
  public handleLink(url: string): void {
    this._window.window.open(url, '_blank', 'noopener resizable scrollbars');
  }
}
