import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 * The document link can either be a button or a link.
 * Optionally you can add a footer text with the maia-document-link-footer tag.
 * Example usages:
 *
 * @example Link:
 * <a maiaDocumentAction rel="noopener noreferrer" href="https://www.kbc.be" target="_blank">
 *   Text in link
 *   <span maia-document-link-footer>Maia document link footer text</span>
 * </a>
 *
 * @example Button:
 * <button maiaDocumentAction>
 *   Text in link
 *   <span maia-document-link-footer>Maia document link footer text</span>
 * </button>
 *
 * @ngModule ActionsModule
 */
@Component({
  selector: 'a[maiaDocumentAction], button[maiaDocumentAction]',
  templateUrl: './document-action.component.html',
  styleUrls: ['./document-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentActionComponent {}
