import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule GeneralDeleteCircleCrossSvgModule
 */
@Component({
  selector: 'svg[ivyHermesGeneralDeleteCircleCross]',
  template: '<svg:path d="M256 512C114.3 512 0 397.7 0 256S114.3 0 256 0s256 114.3 256 256-114.3 256-256 256zm119.4-194.6l-59.8-59.8 59.8-59.8c3.5-3.5 6.8-8.6 6.8-15.4 0-5.1-1.6-11.9-6.8-15.4l-30.7-30.7c-3.5-3.5-10.2-6.8-15.4-6.8-5.1 0-10.2 1.6-15.4 6.8l-59.8 59.8-59.6-59.6c-3.5-3.5-8.6-6.8-15.4-6.8-5.1 0-11.9 1.6-15.4 6.8L133 167.2c-3.5 3.5-6.8 10.2-6.8 15.4 0 5.1 1.6 10.2 6.8 15.4l59.8 59.8-59.8 59.6c-3.5 3.5-6.8 8.6-6.8 15.4 0 5.1 1.6 11.9 6.8 15.4l30.7 30.7c3.5 3.5 10.2 6.8 15.4 6.8s10.2-1.6 15.4-6.8l59.8-59.8 59.8 59.8c3.5 3.5 8.6 6.8 15.4 6.8 5.1 0 11.9-1.6 15.4-6.8l30.7-30.7c3.5-3.5 6.8-10.2 6.8-15.4 0-6.8-1.9-11.9-7-15.4z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 512 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralDeleteCircleCrossSvgComponent {
}

@NgModule({
  declarations: [
    GeneralDeleteCircleCrossSvgComponent,
  ],
  exports: [
    GeneralDeleteCircleCrossSvgComponent,
  ],
})
export class GeneralDeleteCircleCrossSvgModule {}
