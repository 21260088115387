import {
  ComponentFactory,
  ComponentFactoryResolver,
  Injectable,
  Injector,
  StaticProvider,
  TemplateRef,
} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {ViewportName, ViewportService} from '@maia/core';
import {ModalContentComponent, ModalController, ModalOptions, ModalResult} from '@maia/modals';
import {μSlideInController} from '@maia/slide-ins';

import {defer, Observable, of, throwError} from 'rxjs';
import {catchError, flatMap, map, publishReplay, switchMap, take} from 'rxjs/operators';

import {AttachedDropdownComponent} from '../attached/attached-dropdown.component';
import {RegularDropdownComponent} from '../regular/regular-dropdown.component';
import {DropdownRepositionerService} from '../shared/dropdown-repositioner.service';
import {AbstractDropdownComponent} from '../shared/dropdown.component';
import {
  DropdownTemplateContext,
  InternalDropdownOptions,
  SmallDropdownVisualisation,
  VisualiseSmallDropdownAsSlideInOption,
} from '../shared/dropdown.interfaces';
import {connectUntilDestroyed, withOpenClass} from './util';

const MODAL_TYPE_DROP_DOWN = 'maiaDropdown';

const REGULAR_DROPDOWN_CUTOFF = ViewportName.MEDIUM;

type SlideInFactory<T> = (ctrl: μSlideInController) => Observable<ModalResult<T>>;

/**
 * Dropdown controllers can show dropdowns.
 */
@Injectable()
@UntilDestroy()
export class DropdownController {
  private readonly _dropdownFactory: ComponentFactory<RegularDropdownComponent>;
  private readonly _attachedDropdownFactory: ComponentFactory<AttachedDropdownComponent>;

  private readonly _slideInCtrl$: Observable<μSlideInController>;

  public constructor(
    private readonly _dropdownRepositionerService: DropdownRepositionerService,
    private readonly _modalCtrl: ModalController,
    componentFactoryResolver: ComponentFactoryResolver,
    private readonly _viewport: ViewportService,
    injector: Injector,
    loggerFactory: LoggerFactory,
  ) {
    this._dropdownFactory = componentFactoryResolver.resolveComponentFactory(
      RegularDropdownComponent,
    );
    this._attachedDropdownFactory = componentFactoryResolver.resolveComponentFactory(
      AttachedDropdownComponent,
    );

    this._slideInCtrl$ = defer(() => of(injector.get(μSlideInController))).pipe(
      catchError(err => {
        loggerFactory
          .createLogger('@maia/dropdowns:DropdownController')
          .error(`Couldn't load SlideInsModule from @maia/slide-ins`, err);

        return throwError(err);
      }),
      // Use publishReplay, not shareReplay, because publishReplay also replays errors
      // while shareReplay will retry the source for future subscriptions if it errors.
      // More info: https://rxjs.dev/api/operators/shareReplay
      publishReplay(1),
      connectUntilDestroyed(this),
    );
  }

  private _createModalOptions(
    options: InternalDropdownOptions,
    providers: StaticProvider[],
  ): ModalOptions {
    return {
      withBackdrop: !options.withoutBackdrop,
      withClickableBackdrop: true,
      withVisibleBackdrop: false,
      blockScrolling: false,
      providers: providers.concat([{provide: InternalDropdownOptions, useValue: options}]),
    };
  }

  private _switchOnViewport<T>(
    large$: Observable<ModalResult<T>>,
    small$: Observable<ModalResult<T>>,
  ): Observable<ModalResult<T>> {
    return this._viewport.isAtLeast$(REGULAR_DROPDOWN_CUTOFF).pipe(
      switchMap(isLarge => (isLarge ? large$ : small$)),
      // Limit to one value, because the ViewportService will keep
      // yielding results when the viewport changes. Without this
      // `take(1)`, the dropdown/slide-in would keep popping up every time
      // the viewport crosses the cutoff threshold.
      take(1),
    );
  }

  private _visualiseSmallBreakpoint<T>(
    dropdown$: Observable<ModalResult<T>>,
    slideIn$Factory: SlideInFactory<T>,
    attachedDropdown$: Observable<ModalResult<T>>,
    options: InternalDropdownOptions,
  ): Observable<ModalResult<T>> {
    if (
      options.smallDropdownVisualisation == null ||
      options.smallDropdownVisualisation.visualisation === SmallDropdownVisualisation.Dropdown
    ) {
      // we always show as dropdown, but show attached on small viewports
      return this._switchOnViewport(dropdown$, attachedDropdown$);
    }

    // We show as slide-in for small breakpoints

    return this._slideInCtrl$.pipe(
      map(slideIn$Factory),
      flatMap(slideIn$ => this._switchOnViewport(dropdown$, slideIn$)),
    );
  }

  private _doPrepare<T, C extends AbstractDropdownComponent>(
    injector: Injector,
    dropdownFactory: ComponentFactory<C>,
    contentComponentFactory: ComponentFactory<ModalContentComponent<T>>,
    options: InternalDropdownOptions,
    providers: StaticProvider[],
  ): Observable<ModalResult<T>> {
    return defer(() => {
      const modalOptions = this._createModalOptions(options, providers);

      const dropdown$ = this._modalCtrl.prepare(
        MODAL_TYPE_DROP_DOWN,
        injector,
        dropdownFactory,
        contentComponentFactory,
        modalOptions,
      );
      const attachedDropdown$ = this._modalCtrl.prepare(
        MODAL_TYPE_DROP_DOWN,
        injector,
        this._attachedDropdownFactory,
        contentComponentFactory,
        {...modalOptions, withBackdrop: true, withVisibleBackdrop: true},
      );
      const slideIn$Factory: SlideInFactory<T> = slideInCtrl =>
        slideInCtrl.prepare(
          contentComponentFactory,
          injector,
          (options.smallDropdownVisualisation as VisualiseSmallDropdownAsSlideInOption).options,
          modalOptions,
        );

      return withOpenClass(
        options.referencePoint,
        this._visualiseSmallBreakpoint(dropdown$, slideIn$Factory, attachedDropdown$, options),
      );
    });
  }

  private _doPrepareTemplate<T, C extends AbstractDropdownComponent>(
    injector: Injector,
    dropdownFactory: ComponentFactory<C>,
    contentTemplateRef: TemplateRef<DropdownTemplateContext<T>>,
    options: InternalDropdownOptions,
    providers: StaticProvider[],
  ): Observable<ModalResult<T>> {
    return defer(() => {
      const modalOptions = this._createModalOptions(options, providers);

      const dropdown$ = this._modalCtrl.prepareTemplate<T, DropdownTemplateContext<T>>(
        MODAL_TYPE_DROP_DOWN,
        injector,
        dropdownFactory,
        contentTemplateRef,
        modalOptions,
        {
          repositioner: this._dropdownRepositionerService,
        },
      );
      const attachedDropdown$ = this._modalCtrl.prepareTemplate<T, DropdownTemplateContext<T>>(
        MODAL_TYPE_DROP_DOWN,
        injector,
        this._attachedDropdownFactory,
        contentTemplateRef,
        {...modalOptions, withBackdrop: true, withVisibleBackdrop: true},
        {repositioner: this._dropdownRepositionerService},
      );
      const slideIn$Factory: SlideInFactory<T> = slideInCtrl =>
        slideInCtrl.prepareTemplate(
          contentTemplateRef,
          injector,
          (options.smallDropdownVisualisation as VisualiseSmallDropdownAsSlideInOption).options,
          {...modalOptions, withBackdrop: true, withVisibleBackdrop: true},
        );

      return withOpenClass(
        options.referencePoint,
        this._visualiseSmallBreakpoint(dropdown$, slideIn$Factory, attachedDropdown$, options),
      );
    });
  }

  /**
   * Prepares a regular dropdown. Once the returned observable is subscribed to, the dropdown will
   * be shown. The dropdown gets dismissed if the subscription is ended.
   *
   * @param injector The parent injector for the dropdown components
   * @param componentFactory The component to show in the dropdown
   * @param options The options, e.g. for the dropdown
   * @param providers Extra providers to make available in the dropdown content component
   */
  public prepare<T>(
    injector: Injector,
    contentComponentFactory: ComponentFactory<ModalContentComponent<T>>,
    options: InternalDropdownOptions,
    providers: StaticProvider[],
  ): Observable<ModalResult<T>> {
    return this._doPrepare(
      injector,
      this._dropdownFactory,
      contentComponentFactory,
      options,
      providers,
    );
  }

  /**
   * Prepares a regular dropdown. Once the returned observable is subscribed to, the dropdown will
   * be shown. The dropdown gets dismissed if the subscription is ended.
   *
   * @param injector The parent injector for the dropdown components
   * @param contentTemplateRef The template to show in the dropdown
   * @param options The options, e.g. for the dropdown
   * @param providers Extra providers to make available in the dropdown content component
   */
  public prepareTemplate<T>(
    injector: Injector,
    contentTemplateRef: TemplateRef<DropdownTemplateContext<T>>,
    options: InternalDropdownOptions,
  ): Observable<ModalResult<T>> {
    return this._doPrepareTemplate(
      injector,
      this._dropdownFactory,
      contentTemplateRef,
      options,
      [],
    );
  }

  /**
   * Prepares a custom dropdown component. Once the returned observable is subscribed to, the
   * dropdown will be shown. The dropdown gets dismissed if the subscription is ended.
   *
   * @param injector The parent injector for the dropdown components
   * @param dropdownFactory The component factory responsible for creating new dropdown instances
   * @param componentFactory The component to show in the dropdown
   * @param options The options, e.g. for the dropdown
   * @param providers Extra providers to make available in the dropdown content component
   */
  public prepareCustom<T, C extends AbstractDropdownComponent>(
    injector: Injector,
    dropdownFactory: ComponentFactory<C>,
    contentComponentFactory: ComponentFactory<ModalContentComponent<T>>,
    options: InternalDropdownOptions,
    providers: StaticProvider[],
  ): Observable<ModalResult<T>> {
    return this._doPrepare(injector, dropdownFactory, contentComponentFactory, options, providers);
  }

  /**
   * Prepares a custom dropdown component. Once the returned observable is subscribed to, the
   * dropdown will be shown. The dropdown gets dismissed if the subscription is ended.
   *
   * @param injector The parent injector for the dropdown components
   * @param dropdownFactory The component factory responsible for creating new dropdown instances
   * @param contentTemplateRef The template to show in the dropdown
   * @param options The options, e.g. for the dropdown
   * @param providers Extra providers to make available in the dropdown content component
   */
  public prepareTemplateCustom<T, C extends AbstractDropdownComponent>(
    injector: Injector,
    dropdownFactory: ComponentFactory<C>,
    contentTemplateRef: TemplateRef<DropdownTemplateContext<T>>,
    options: InternalDropdownOptions,
    providers: StaticProvider[],
  ): Observable<ModalResult<T>> {
    return this._doPrepareTemplate(
      injector,
      dropdownFactory,
      contentTemplateRef,
      options,
      providers,
    );
  }
}
