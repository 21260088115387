import {NgModule} from '@angular/core';
import {ModalsModule} from '@maia/modals';
import {SquaresModule} from '@maia/squares';

import {SelectDropdownComponent} from './dropdown/select-dropdown.component';

@NgModule({
  imports: [ModalsModule, SquaresModule],
  declarations: [SelectDropdownComponent],
  exports: [SelectDropdownComponent],
})
export class SelectDropdownModule {}
