import {ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {ModalTemplateContext} from '@maia/modals';

/**
 * All valid values for the `contentType` property of AdditionalInformationComponents.
 */
type ContentType = 'information' | 'content';

/**
 * @ngModule AdditionalInformationModule
 */
@Component({
  selector: 'maia-additional-information:not([slideIn]):not([custom])',
  templateUrl: './additional-information-collapse.component.html',
  styleUrls: ['./additional-information-collapse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalInformationCollapseComponent {
  public isCollapsed = true;

  @Input('openLinkText')
  public openLinkText?: Text | string | null;

  @Input('closeLinkText')
  public closeLinkText?: Text | string | null;

  @Input('maiaContentType')
  public type: ContentType;

  @ViewChild('content', {static: true})
  public readonly content: TemplateRef<ModalTemplateContext<void>>;

  public constructor() {}

  public switchCollapsible(event?: Event): void {
    this.isCollapsed = !this.isCollapsed;
    if (event != null) {
      event.preventDefault();
    }
  }
}
