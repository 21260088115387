import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

/**
 * Name Value Collection Item
 * Component for each item in name value collections
 *
 * @ngModule NameValueCollectionsModule
 */
@Component({
  selector: 'maia-name-value-collection-group',
  templateUrl: './name-value-collection-group.component.html',
  styleUrls: ['./name-value-collection-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NameValueCollectionGroupComponent {
  @coerceBooleanPrimitive()
  @Input()
  @HostBinding('class.maia-name-value-collection-group--with-columns')
  public withColumns = false;

  @coerceBooleanPrimitive()
  @Input()
  @HostBinding('class.maia-name-value-collection-group--with-border')
  public withBorder = false;
}
