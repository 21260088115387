import {
  InjectableType,
  ɵComponentType as ComponentType,
  ɵDirectiveType as DirectiveType,
  OnDestroy,
  Type,
} from '@angular/core';

import {completeSubjectOnTheInstance, markAsDecorated} from './internals';
import {isPipe, PipeType} from './ivy';

function decorate(onDestroy: (() => void) | null | undefined, type: Type<unknown>) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return function(this: object) {
    // Invoke the original `ngOnDestroy` if it exists
    if (onDestroy) {
      onDestroy.call(this);
    }

    if (this.constructor === type) {
      // It's important to use `this` instead of caching instance
      // that may lead to memory leaks
      completeSubjectOnTheInstance(this);
    }
  };
}

function decorateProviderDirectiveOrComponent<T>(
  type: InjectableType<T> | DirectiveType<T> | ComponentType<T>,
): void {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const proto: Partial<OnDestroy> = type.prototype;
  proto.ngOnDestroy = decorate(proto.ngOnDestroy, type);
}

function decoratePipe<T>(type: PipeType<T>): void {
  const def = type.ɵpipe;
  def.onDestroy = decorate(def.onDestroy, type);
}

export function UntilDestroy(): ClassDecorator {
  return type => {
    if (isPipe(type)) {
      decoratePipe(type);
    } else {
      decorateProviderDirectiveOrComponent(type as any);
    }

    markAsDecorated((type as unknown) as Type<unknown>);
  };
}
