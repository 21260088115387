import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionFuncCalendarSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaActionFuncCalendar]',
  template: '<svg:g class="nc-icon-wrapper" transform="matrix(.8 0 0 .8 2.4 2.4)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M1 8h22"></svg:path><svg:rect x="1" y="3" width="22" height="19" rx="2" ry="2" fill="none" stroke-miterlimit="10"></svg:rect><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M6 1v3"></svg:path><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M18 1v3"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionFuncCalendarSvgComponent {
}

@NgModule({
  declarations: [
    ActionFuncCalendarSvgComponent,
  ],
  exports: [
    ActionFuncCalendarSvgComponent,
  ],
})
export class ActionFuncCalendarSvgModule {}
