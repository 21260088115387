import {AfterContentInit, Component, ElementRef, NgZone, OnInit} from '@angular/core';
import {WindowRef} from '@atlas-angular/cdk/globals';
import {DomIoService, FrameThrottleService, ViewportName, ViewportService} from '@maia/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';

import {merge} from 'rxjs';

export const MODAL_TYPE_TOOLTIP = 'maiaTooltip';
const BOTTOM_MARGIN = 8;
@Component({
  selector: 'maia-tooltip-content',
  template: '<ng-container maiaRenderModal></ng-container>',
  styleUrls: ['./tooltip-content.component.scss'],
})
export class TooltipContentComponent
  extends ModalContentComponent<void, {anchorElement: HTMLElement}>
  implements OnInit, AfterContentInit {
  public constructor(
    public readonly control: ModalControl<void, {anchorElement: HTMLElement}>,
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _domIo: DomIoService,
    private readonly _frameThrottler: FrameThrottleService,
    private readonly _zone: NgZone,
    private readonly _window: WindowRef,
    private readonly _viewPortService: ViewportService,
  ) {
    super();
  }

  public ngAfterContentInit() {
    this.calculateAndSetPostion();
  }

  public ngOnInit() {
    this._zone.runOutsideAngular(() => {
      this._frameThrottler
        .throttle$(merge(this._window.on$('resize'), this._window.on$('scroll')))
        .subscribe(evt => {
          this._zone.run(() => {
            this.calculateAndSetPostion();
          });
        });
    });
  }

  private calculateAndSetPostion() {
    this._domIo.measure(() => {
      const anchorRect = this.control.input.anchorElement.getBoundingClientRect();
      const ownRect = this._elementRef.nativeElement.getBoundingClientRect();
      this._domIo.mutate(() => {
        this.setPosition(anchorRect, ownRect);
      });
    });
  }

  private setPosition(anchorRect: ClientRect, ownRect: ClientRect) {
    this._elementRef.nativeElement.style.top = `${
      anchorRect.top - ownRect.height - BOTTOM_MARGIN
    }px`;
    if (this._viewPortService.isAtLeast(ViewportName.MEDIUM)) {
      this._elementRef.nativeElement.style.left = `${
        anchorRect.left + (anchorRect.width - ownRect.width) / 2
      }px`;
    } else {
      // @ts-expect-error
      this._elementRef.nativeElement.style.left = null;
    }
  }
}
