import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  Optional,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Date, DateUtils, Text, TextFormatter, validateDate} from '@atlas/businesstypes';
import {DropdownHost, DropdownPosition, DropdownTemplateContext} from '@maia/dropdowns';
import {FormElementComponent, InputContainer} from '@maia/forms';
import {
  DATE_TEXT_LENGTH,
  DateFormat,
  DEFAULT_DATE_FORMAT,
  MaskedInputComponent,
  MaskedTextInputDirective,
} from '@maia/input-masks';
import {ModalResolution, ModalResult} from '@maia/modals';
import {finalize, tap} from 'rxjs/operators';

import {DateRange} from '../../daterange';
import {DatepickerVisibleView} from '../../pickers/datepicker/datepicker.component';
import {formatDateToCustomFormat, formatDateToDefaultFormat} from '../../util';
import {MAXIMUM_DROPDOWN_WIDTH, MINIMUM_DROPDOWN_WIDTH} from '../constants';

const enum Limit {
  Minimum = 'Minimum',
  Maximum = 'Maximum',
}

function createLimitValidator(date: Date, limit: Limit): ValidatorFn {
  const isInvalid =
    limit === Limit.Maximum
      ? (value: Date) => DateUtils.isAfter(value, date)
      : (value: Date) => DateUtils.isBefore(value, date);

  return control => {
    const value = control.value;

    if (value == null || !DateRange.isDateRange(value)) {
      return null;
    }

    const errors: ValidationErrors = {};
    let hasError = false;

    if (Date.isDate(value.start) && isInvalid(value.start)) {
      hasError = true;
      errors[`dateRange${limit}Start`] = true;
    }

    if (Date.isDate(value.end) && isInvalid(value.end)) {
      hasError = true;
      errors[`dateRange${limit}End`] = true;
    }

    return hasError ? errors : null;
  };
}

/**
 * Unique object meant to signify "no value has recently been set using writeValue"
 */
const NO_WRITTEN_VALUE = {};

/**
 * A date input. This input provides an input with input mask where a date can be typed and a button
 * to open a dropdown which allows selecting a date.
 *
 * @ngModule DatePickersModule
 */
@Component({
  selector: 'maia-input-date-range',
  templateUrl: './input-daterange.component.html',
  styleUrls: ['./input-daterange.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDaterangeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputDaterangeComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class InputDaterangeComponent implements AfterViewInit, ControlValueAccessor, Validator {
  /**
   * The current value, be it complete or incomplete
   */
  public readonly control: FormControl;

  @ViewChild('dropdownHost')
  public _dropdownHost: DropdownHost;

  private _minimum?: Date;
  private _minimumValidator: ValidatorFn;

  private _maximum?: Date;
  private _maximumValidator: ValidatorFn;

  private _dateFormat: DateFormat = DEFAULT_DATE_FORMAT;

  public dateFormatMask = this._convertDateFormatToDateRangeMask(this._dateFormat);
  public placeholder = `${this._dateFormat} - ${this._dateFormat}`;
  public initialPlaceholder = `${this.placeholder}`;

  @Input()
  public set dateFormat(dateFormat: DateFormat) {
    if (dateFormat == null) {
      return;
    }

    this._dateFormat = dateFormat;

    if (this.maskedInputDirective) {
      this.setPlaceholder();
    }
  }

  public get dateFormat(): DateFormat {
    return this._dateFormat;
  }

  /**
   * The initial year/month combination to open.
   */
  @Input()
  public set initialValue(val: DateRange) {
    this._initialValue = val;

    if (DateRange.isDateRange(val)) {
      this.control.setValue(val);
    } else {
      this.control.setValue(new DateRange());
    }
  }

  public get initialValue(): DateRange {
    return this._initialValue;
  }

  @ViewChild('maskedInput')
  public maskedInput: MaskedInputComponent;

  @ViewChild('maskedInputDirective', {read: MaskedTextInputDirective})
  public maskedInputDirective: MaskedTextInputDirective;

  @ViewChild('dropdownContent')
  public dropdownContent: TemplateRef<DropdownTemplateContext<DateRange>>;

  /**
   * The view to open the datepicker on.
   */
  @Input()
  public visibleView?: DatepickerVisibleView;

  public disabled = false;

  public optional = true;

  private _open = false;

  private _recentlyWrittenValue: unknown = NO_WRITTEN_VALUE;

  private _initialValue: DateRange;

  public constructor(
    fb: FormBuilder,
    private readonly _changeDetector: ChangeDetectorRef,
    @Optional() private readonly _inputContainer?: InputContainer,
    @Optional() private readonly _parentFormElement?: FormElementComponent,
  ) {
    this.control = fb.control(undefined);
  }

  // istanbul ignore next: provided by [(ngModel)]
  private _onValidatorChanged = () => {};

  // istanbul ignore next: provided by [(ngModel)]
  private _onTouched = () => {};

  /**
   * The last allowed date.
   *
   * While it's technically fine to have a maximum date that lies before the minimum date, this
   * would render the user unable to select a date.
   */
  public get maximum(): Date | undefined {
    return this._maximum;
  }

  @Input()
  public set maximum(maximum: Date | undefined) {
    if (maximum != null && !Date.isDate(maximum)) {
      maximum = new Date(maximum);
    }

    this._maximum = maximum;
    this._maximumValidator =
      maximum != null ? createLimitValidator(maximum, Limit.Maximum) : Validators.nullValidator;
    this._onValidatorChanged();
  }

  /**
   * The first allowed date.
   *
   * While it's technically fine to have a minimum date that lies after the maximum date, this would
   * render the user unable to select a date.
   */
  @Input()
  public get minimum(): Date | undefined {
    return this._minimum;
  }

  public set minimum(minimum: Date | undefined) {
    if (minimum != null && !Date.isDate(minimum)) {
      minimum = new Date(minimum);
    }

    this._minimum = minimum;
    this._minimumValidator =
      minimum != null ? createLimitValidator(minimum, Limit.Minimum) : Validators.nullValidator;
    this._onValidatorChanged();
  }

  /**
   * @returns the value to be used in the maia-masked-input
   * */
  public get value(): string | null {
    if (!DateRange.isDateRange(this.control.value)) {
      return null;
    }

    const {start} = this.control.value;

    if (!start) {
      return null;
    }

    return this.control.value.toString(this.dateFormat);
  }

  /**
   * @returns the value to be used in the maia-fake-input
   * */
  public get fakeValue(): string | null {
    if (!DateRange.isDateRange(this.control.value)) {
      return null;
    }

    const {start, end} = this.control.value;

    if (!start) {
      return null;
    }

    return `${formatDateToCustomFormat(start, this.dateFormat, true)} - ${
      end ? formatDateToCustomFormat(end, this.dateFormat, true) : ''
    }`;
  }

  /**
   * @returns the control value (used in the template)
   * */
  public get dateRangeValue() {
    return this.control.value;
  }

  /**
   * Stores values typed into the raw input
   */
  public setRawInputValue(value: Text | null): void {
    if (value == null) {
      if (this.optional) {
        this.control.setValue(null);
      }
      return;
    }

    const dateRangeText: string = TextFormatter.format(value);

    // only process text input when 2 dates (or null values) can be made
    if (dateRangeText.length % DATE_TEXT_LENGTH === 0) {
      const startDate = this._convertInputToDate(dateRangeText.substr(0, DATE_TEXT_LENGTH));
      const endDate = this._convertInputToDate(
        dateRangeText.substr(DATE_TEXT_LENGTH, DATE_TEXT_LENGTH),
      );
      this.control.setValue(new DateRange(startDate, endDate));
    }
  }

  public isValidDateRange(value: DateRange): boolean {
    return DateRange.isDateRange(value) && value.isValid();
  }

  /**
   * Opens a datepicker in a dropdown.
   */
  public openDropdown(): false {
    if (this.disabled || this._open) {
      return false;
    }

    this._open = true;

    this._dropdownHost
      .prepareTemplate(this.dropdownContent, {
        position: DropdownPosition.BOTTOM_ALIGNED,
        alignedDropdownExtra: {
          maximumSize: MAXIMUM_DROPDOWN_WIDTH,
          minimumSize: MINIMUM_DROPDOWN_WIDTH,
          alternativePosition: DropdownPosition.BOTTOM_LEFT,
        },
      })
      .pipe(
        finalize(() => (this._open = false)),
        takeUntilDestroyed(this),
        // call onTouched before filtering on confirmation
        tap(() => this._onTouched()),
      )
      .subscribe(result => {
        this.handleDropdownClose(result);

        this._changeDetector.markForCheck();
      });

    return false;
  }

  public handleDropdownClose(modalResult: ModalResult<DateRange>): void {
    // Accept clicking on backdrop after manual input
    if (modalResult.resolution === ModalResolution.CANCELLED) {
      this._handleDropdownCancel();
    } else if (
      modalResult.resolution === ModalResolution.CONFIRMED &&
      DateRange.isDateRange(modalResult.result)
    ) {
      this._handleDropdownSubmit(modalResult.result);
    }
  }

  private _handleDropdownCancel() {
    const value = this.control.value as DateRange | null;

    if (!DateRange.isDateRange(value)) {
      return;
    }

    if (value.isValid()) {
      const normalizedValue = value.normalize();
      // If the normalized value differs from the value, write the normalized value to the control
      if (!normalizedValue.equals(value)) {
        this.control.setValue(normalizedValue);
      } else {
        // The value didn't change, so we don't need to emit it. We do need to write it to the
        // masked input because the user could have typed in it
        this.maskedInput.writeValue(this.value || '');
      }
    } else if (this.optional && value.isEmpty()) {
      this.maskedInput.writeValue(this.value || '');
    } else {
      this.control.setValue(this.initialValue);
    }
  }

  private _handleDropdownSubmit(result: DateRange): void {
    const normalizedResult = result.normalize();
    if (normalizedResult.equals(this.control.value)) {
      // The value didn't change, so we don't need to emit it. We do need to write it to the
      // masked input because the user could have typed in it
      this.maskedInput.writeValue(this.value || '');
    } else {
      this.control.setValue(normalizedResult);
    }
  }

  public ngAfterViewInit(): void {
    if (this._inputContainer != null) {
      // IMPORTANT: this control cannot be registered to this._inputContainer because that container
      // already has a control registered by the MaskedInputWithControlDirective (the
      // maia-masked-input used in this component's template)

      this._inputContainer.disabled$.subscribe(() => {
        this._changeDetector.detectChanges();
      });
    }

    if (this.maskedInputDirective) {
      this.setPlaceholder();
    }
  }

  // ControlValueAccessor

  public writeValue(obj: any): void {
    this._recentlyWrittenValue = obj;
    this.control.setValue(obj);
    this._changeDetector.detectChanges();
  }

  public registerOnChange(fn: any): void {
    // FormControl has a `registerOnChange` function, but that's meant for applying model changes to
    // the view. Its listeners never get called when the view triggers the change to the model. In
    // our case the registered listener doesn't get called when the user types a value in the masked
    // input, which renders the component unusable with a keyboard.

    this.control.valueChanges.pipe(takeUntilDestroyed(this)).subscribe((value: any) => {
      if (
        this._recentlyWrittenValue !== value &&
        (value == null || (DateRange.isDateRange(value) && value.isValid()))
      ) {
        fn(value);
      }

      this._recentlyWrittenValue = NO_WRITTEN_VALUE;
    });
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (this.disabled === isDisabled) {
      return;
    }

    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }

    this.disabled = isDisabled;
    this._changeDetector.detectChanges();
  }

  // Validator API

  public validate(control: AbstractControl): ValidationErrors | null {
    const value: DateRange | null = control && control.value;

    const validatorFn = control.validator;

    // the control will be instance of FormControl only when called by Angular and not by ourselves
    if (validatorFn && control instanceof FormControl && this._parentFormElement) {
      // the validator(s) should be executed against a 'null' value to detect if the 'required'
      // validation is defined
      // the passed control is an simple object (NOT an instance of FormControl)
      const validationErrors = validatorFn({...control, value: null} as AbstractControl);
      this.optional = !(validationErrors && validationErrors['required']);
      this._parentFormElement.hideOptionalIndicator = !this.optional;
    }

    // treat empty date input as valid
    if (value == null) {
      return null;
    }

    return (
      validateDate(value.start) ||
      validateDate(value.end) ||
      this._minimumValidator(control) ||
      this._maximumValidator(control)
    );
  }

  public registerOnValidatorChange(onValidatorChange: () => void): void {
    this._onValidatorChanged = onValidatorChange;
  }

  public cancelSelection(): void {
    this.control.setValue(this.initialValue);
  }

  public clearSelection(): void {
    this.control.setValue(null);
    this.initialValue = new DateRange();
  }

  /**
   * Save a daterange to be used as initial value
   * */
  public saveSelection(dateRange: DateRange): void {
    if (this.isValidDateRange(dateRange)) {
      this.initialValue = dateRange;
    }
  }

  private _convertDateFormatToDateRangeMask(dateFormat: DateFormat): string {
    return `${dateFormat} - ${dateFormat}`.replace(/[A-Z]/g, '#');
  }

  /**
   * Attempts to convert user input into a Date object, returning null when receiving incorrect
   * input
   * @param val String to convert to Date;
   * @private
   */
  private _convertInputToDate(val: string): Date | null {
    if (this.dateFormat !== DEFAULT_DATE_FORMAT) {
      val = formatDateToDefaultFormat(val, this.dateFormat);
    }

    try {
      return new Date(val);
    } catch (e) {
      return null;
    }
  }

  /**
   * Adapt the date range placeholder based on the date format and set it in the masked input
   * directive.
   *
   * This is needed since the masked input directive used in this component is the
   * MaskedTextInputDirective instead of the MaskedDateInputDirective that is used in the
   * input-date component. Therefore, the placeholder needs to be constructed here.
   */
  private setPlaceholder() {
    this.dateFormatMask = this._convertDateFormatToDateRangeMask(this.dateFormat);
    this._changeDetector.detectChanges();

    this.placeholder = this._adaptPlaceholder(
      this.dateFormat,
      // take the current placeholder from the directive as basis (cause it is the one coming from
      // translated XLIFF file)
      this.maskedInputDirective.placeholder,
    );
    this.maskedInputDirective.placeholder = this.placeholder;
  }

  /**
   * Adapt the current placeholder to match the current format.
   * In other words: put the date parts of the localized placeholder in the same order as the
   * current format.
   *
   * Example: for a date format: 'YYYY-MM-DD' and a placeholder in dutch 'DD-MM-JJJJ', this
   * placeholder is adapted to 'JJJJ-MM-DD'
   */
  private _adaptPlaceholder(dateFormat: DateFormat, localizedPlaceholder: string): string {
    const dateFormatParts = dateFormat.match(/(DD|MM|YYYY)/g);

    if (dateFormatParts == null) {
      throw new Error('Invalid format. Please use one of the accepted formats.');
    }

    const placeholder = localizedPlaceholder || this.placeholder;
    const placeholderSingleDate = placeholder.substring(0, dateFormat.length);
    const yearRegex = /\w{4}/; // Year is always 4 chars
    const placeholderMonthRegex = /MM|HH/; // H for Hungarian translation: 'hónap'
    const placeholderYear = yearRegex.exec(placeholderSingleDate)![0];
    const placeholderMonth = placeholderMonthRegex.exec(placeholderSingleDate)![0];
    // the days are the residual characters after removing year, month and separators
    const placeholderDay = placeholderSingleDate
      .replace(yearRegex, '')
      .replace(placeholderMonthRegex, '')
      .replace(/[-\s]/g, '');

    const dateRangePartPlaceholder = dateFormatParts
      .map((part: string) => {
        if (yearRegex.test(part)) {
          return placeholderYear;
        } else if (/MM/.test(part)) {
          return placeholderMonth;
        }
        return placeholderDay;
      })
      .join('-');

    return `${dateRangePartPlaceholder} - ${dateRangePartPlaceholder}`;
  }
}
