import {Injectable} from '@angular/core';
import {WindowRef} from '@atlas-angular/cdk/globals';

const WINDOW_SETTINGS = 'location=yes,resizable=yes,scrollbars=yes';
const DOCUMENT_WINDOW_NAME = 'document_window';

/**
 * Central URL Service. This service handles all methods related to an URL.
 */
@Injectable({providedIn: 'root'})
export class OpenUrlService {
  private readonly window: Window;

  public constructor(windowRef: WindowRef) {
    this.window = windowRef.window;
  }

  /**
   * Submits a form passing parameters implementing POST method
   */
  public post(
    urlS: string,
    parameters = new Map<string, string>(),
    target: string,
    windowSettings = WINDOW_SETTINGS,
    intermediateUrl = '',
  ): Window | null {
    target = target ? target : `${DOCUMENT_WINDOW_NAME}_${Date.now()}`;
    const newWindow: Window | null = this.window.open(intermediateUrl, target, windowSettings);
    if (newWindow) {
      const form: HTMLFormElement = newWindow.document.createElement('form');
      form.style.display = 'none';
      form.target = '_self';
      form.method = 'POST';
      form.action = urlS;
      parameters.forEach((value: string, key: string) => {
        const input: HTMLInputElement = newWindow.document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = value;
        form.appendChild(input);
      });
      newWindow.document.body.appendChild(form);
      form.submit();
      newWindow.document.body.removeChild(form);
    }
    return newWindow;
  }

  /**
   * Opens a window after creating an URL with parameters implementing GET method
   */
  public open(
    urlS: string,
    parameters = new Map<string, string>(),
    target = '_blank',
    windowSettings = WINDOW_SETTINGS,
  ): Window | null {
    const url = new URL(urlS, this.window.location.href);
    const searchParams = url.searchParams;
    parameters.forEach((value: string, key: string) => {
      searchParams.append(key, value);
    });
    return this.window.open(url.toString(), target, windowSettings);
  }

  /**
   * Opens a window in an external browser implementing GET method
   */
  public openInExternalBrowser(
    url: string,
    parameters = new Map<string, string>(),
    windowSettings = WINDOW_SETTINGS,
  ): Window | null {
    return this.open(url, parameters, '_system', windowSettings);
  }
}
