import {Injectable} from '@angular/core';

/**
 * Default device key/value map to fetch configurations from DeviceConfiguration
 *
 * @see {@link ConfigurationExtractor}
 * @see {@link Device}
 */
export const DEFAULT_DEVICE: Device = {
  code: 'browser',
  name: 'browser',
};

/**
 * IOS device key/value map to fetch configurations from DeviceConfiguration.
 * This value for Device is provided from CoreAndroidModule
 *
 * @see {@link ConfigurationExtractor}
 * @see {@link Device}
 */
export const IOS_DEVICE: Device = {
  code: 'ios',
  name: 'ios',
};

/**
 * Android device key/value map to fetch configurations from DeviceConfiguration.
 * This value for Device is provided from CoreIosModule
 *
 * @see {@link ConfigurationExtractor}
 * @see {@link Device}
 */
export const ANDROID_DEVICE: Device = {
  code: 'android',
  name: 'android',
};

/**
 * The active device
 *
 * The device can be injected into your code to access the name and/or code of the currently active
 * device.
 */
@Injectable()
export class Device {
  /**
   * The device code, e.g. `'android'` for android devices
   */
  public readonly code: string;

  /**
   * The device name, e.g. 'Android'
   */
  public readonly name: string;
}
