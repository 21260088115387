import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule GeneralDeleteRubbishSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaGeneralDeleteRubbish]',
  template: '<svg:path d="M471.5 117.4c0 5.9-4.7 10.6-10.6 10.6h-32.2v316c0 36.7-24.2 68-53.5 68H96.7c-29.5 0-53.5-30.1-53.5-66.6V128H11.1C5 128 .5 123.3.5 117.4V96.1c0-5.9 4.7-10.6 10.6-10.6h103.4l23.3-55.7C144.7 13.3 164.7 0 182.5 0h107.1c17.8 0 37.9 13.3 44.4 29.7l23.3 55.7h103.4c6.1 0 10.6 4.7 10.6 10.6l.2 21.4zM385.9 128H86.1v316c0 16 9 25.4 10.6 25.4h278.5c1.6 0 10.6-9.2 10.6-25.4l.1-316zM171.7 394.6c0 5.9-4.7 10.6-10.6 10.6h-21.3c-6.1 0-10.6-4.7-10.6-10.6V202.8c0-5.9 4.7-10.6 10.6-10.6h21.5c6.1 0 10.6 4.7 10.6 10.6v191.9h-.2v-.1zM311 85.4l-16.2-39.1c-1-1.2-3.9-3.3-5.7-3.7H183.2c-2 .4-4.7 2.3-5.7 3.7L161 85.4h150zm-53.5 309.2c0 5.9-4.7 10.6-10.6 10.6h-21.3c-6.1 0-10.6-4.7-10.6-10.6V202.8c0-5.9 4.7-10.6 10.6-10.6h21.3c6.1 0 10.6 4.7 10.6 10.6v191.8zm85.6 0c0 5.9-4.7 10.6-10.6 10.6H311c-5.9 0-10.6-4.7-10.6-10.6V202.8c0-5.9 4.7-10.6 10.6-10.6h21.3c6.1 0 10.6 4.7 10.6 10.6v191.9h.2v-.1z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0.5 0 471 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralDeleteRubbishSvgComponent {
}

@NgModule({
  declarations: [
    GeneralDeleteRubbishSvgComponent,
  ],
  exports: [
    GeneralDeleteRubbishSvgComponent,
  ],
})
export class GeneralDeleteRubbishSvgModule {}
