import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

/**
 * @ngModule DisplaysModule
 */
@Component({
  selector: 'maia-opening-hours-display-line',
  templateUrl: './opening-hours-display-line.component.html',
  styleUrls: ['./opening-hours-display-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpeningHoursDisplayLineComponent {
  /**
   * The (optional) suffix for this line
   */
  @Input()
  public suffix: string;
}
