import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 * @ngModule DisplaysModule
 */
@Component({
  selector: 'maia-opening-hours-display',
  templateUrl: './opening-hours-display.component.html',
  styleUrls: ['./opening-hours-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpeningHoursDisplayComponent {}
