import {ComponentFactory, StaticProvider, TemplateRef} from '@angular/core';
import {ModalContentComponent, ModalResult} from '@maia/modals';
import {Observable} from 'rxjs';

import {AbstractDropdownComponent} from '../shared/dropdown.component';
import {
  DropdownMargin,
  DropdownOptions,
  DropdownTemplateContext,
} from '../shared/dropdown.interfaces';

/**
 * A dropdown host.
 */
export abstract class DropdownHost {
  /**
   * The margin to use. If no margins are supplied, the default margins
   * of the dropdown element will be used.
   *
   * If the margin is a number, it will be used for both `top` and
   * `bottom`.
   */
  public abstract set margin(margin: DropdownMargin | number | undefined);

  /**
   * Prepares a regular dropdown. Once the returned observable is subscribed to, the dropdown will
   * be shown. The dropdown gets dismissed if the subscription is ended.
   *
   * This method uses a barebones provided dropdown component. There's no blue border or possibility
   * to influence the style of the dropdown whatsoever. If you want a custom dropdown, use
   * `prepareCustom` instead.
   *
   * @see #prepareCustom
   * @param componentFactory The component to show in the dropdown
   * @param options Options for creating the dropdown
   * @param providers Extra providers to make available in the dropdown content component
   */
  public abstract prepare<T>(
    componentFactory: ComponentFactory<ModalContentComponent<T>>,
    options?: DropdownOptions,
    providers?: StaticProvider[],
  ): Observable<ModalResult<T>>;

  /**
   * Prepares a regular dropdown. Once the returned observable is subscribed to, the dropdown will
   * be shown. The dropdown gets dismissed if the subscription is ended.
   *
   * This method uses a barebones provided dropdown component. There's no blue border or possibility
   * to influence the style of the dropdown whatsoever. If you want a custom dropdown, use
   * `prepareCustom` instead.
   *
   * @see #prepareTemplateCustom
   * @param templateRef The template to show in the dropdown
   * @param options Options for creating the dropdown
   */
  public abstract prepareTemplate<T>(
    templateRef: TemplateRef<DropdownTemplateContext<T>>,
    options?: DropdownOptions,
  ): Observable<ModalResult<T>>;

  /**
   * Prepares a custom dropdown. Once the returned observable is subscribed to, the dropdown will
   * be shown. The dropdown gets dismissed if the subscription is ended.
   *
   * @param dropdownFactory The component factory responsible for creating new dropdown instances
   * @param componentFactory The component to show in the dropdown
   * @param options Options for creating the dropdown
   * @param providers Extra providers to make available in the dropdown content component
   */
  public abstract prepareCustom<T, C extends AbstractDropdownComponent>(
    dropdownFactory: ComponentFactory<C>,
    componentFactory: ComponentFactory<ModalContentComponent<T>>,
    options?: DropdownOptions,
    providers?: StaticProvider[],
  ): Observable<ModalResult<T>>;

  /**
   * Prepares a custom dropdown. Once the returned observable is subscribed to, the dropdown will
   * be shown. The dropdown gets dismissed if the subscription is ended.
   *
   * @param dropdownFactory The component factory responsible for creating new dropdown instances
   * @param templateRef The template to show in the dropdown
   * @param options Options for creating the dropdown
   * @param providers Extra providers to make available in the dropdown content component
   */
  public abstract prepareTemplateCustom<T, C extends AbstractDropdownComponent>(
    dropdownFactory: ComponentFactory<C>,
    templateRef: TemplateRef<DropdownTemplateContext<T>>,
    options?: DropdownOptions,
    providers?: StaticProvider[],
  ): Observable<ModalResult<T>>;
}
