import {ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2} from '@angular/core';
import {CssClassUtility, CssClassUtilityFactory} from '@maia/core';

export type ActionWithIconSize =
  | 'tiny'
  | 'extra-small'
  | 'small'
  | 'normal'
  | 'medium'
  | 'large'
  | 'extra-large'
  | 'grand';

const ActionWithButtonClasses = {
  size: {
    tiny: 'maia-action-with-icon--tiny',
    'extra-small': 'maia-action-with-icon--extra-small',
    small: 'maia-action-with-icon--small',
    normal: 'maia-action-with-icon--normal',
    medium: 'maia-action-with-icon--medium',
    large: 'maia-action-with-icon--large',
    'extra-large': 'maia-action-with-icon--extra-large',
    grand: 'maia-action-with-icon--grand',
  } as {[key in ActionWithIconSize]: string | null},
};

/**
 * The maiaActionWithIcon tag should be placed on a button to show a link with an icon in front.
 * Change the size of the action with icon by adding a size as an input paramater.
 * Possible sizes to add: tiny, small, normal, medium, large, grand (Default: normal)
 * Example usage:
 *
 * @example
 * <button maiaActionWithIcon [size]="size">
 *  <svg ivyMaiaAlertFuncInfoCircle maia-action-icon></svg>
 *  <span>Action with icon text</span>
 * </button>
 *
 * @ngModule ActionsModule
 */
@Component({
  selector: 'button[maiaActionWithIcon]',
  templateUrl: './action-with-icon.component.html',
  styleUrls: ['./action-with-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionWithIconComponent {
  private readonly _cssClassUtility: CssClassUtility<typeof ActionWithButtonClasses>;
  private _size: ActionWithIconSize;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(
      ActionWithButtonClasses,
      renderer,
      elementRef,
    );
    this.size = 'normal';
  }

  @Input()
  public get size(): ActionWithIconSize {
    return this._size;
  }

  public set size(size: ActionWithIconSize) {
    if (this._size === size) {
      return;
    }
    this._size = size;
    this._cssClassUtility.setValue('size', this.size);
  }
}
