import {Component} from '@angular/core';

/**
 * Name Value Collection Item
 * Component for each item in name value collections
 *
 * @ngModule NameValueCollectionsModule
 */
@Component({
  selector: 'maia-name-value-collection-item',
  template: '<ng-content></ng-content>',
  styleUrls: ['./name-value-collection-item.component.scss'],
})
export class NameValueCollectionItemComponent {}
