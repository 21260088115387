import {HostBinding, OnDestroy, QueryList, Directive} from '@angular/core';
import {Option} from '@maia/select';
import {Observable} from 'rxjs';

import {OptionHighlightComponent} from './option-highlight.component';

/**
 * Option with highlightable content
 */
@Directive()
export abstract class OptionWithHighlights<T> extends Option<T> implements OnDestroy {
  /**
   * The highlightable contents registered in the option
   */
  public readonly highlights = new QueryList<OptionHighlightComponent>();

  /**
   * Returns whether there's a matching highlight in this option
   */
  @HostBinding('class.maia-option--without-match')
  public get hasNoMatch(): boolean {
    return this.highlights.find(highlight => highlight.hasMatch) == null;
  }

  /**
   * Registers the given highlight as long as the observable is subscribed to
   */
  public registerHighlight(highlight: OptionHighlightComponent): Observable<never> {
    const {highlights} = this;
    return new Observable<never>(() => {
      highlights.reset([...highlights.toArray(), highlight]);
      highlights.notifyOnChanges();

      return () => {
        const highlightsArray = highlights.toArray();
        const highlightIndex = highlightsArray.indexOf(highlight);

        // istanbul ignore else: this shouldn't be possible using RxJs but better safe than sorry.
        // Well, technically still possible if a highlight registers twice...
        if (highlightIndex !== -1) {
          highlightsArray.splice(highlightIndex, 1);
          highlights.reset(highlightsArray);
          highlights.notifyOnChanges();
        }
      };
    });
  }

  /**
   * Unregisters the given highlight.
   */
  public unregisterHighlight(highlight: OptionHighlightComponent): void {
    const {highlights} = this;
    const highlightsArray = highlights.toArray();
    const index = highlightsArray.indexOf(highlight);

    // istanbul ignore else
    if (index !== -1) {
      highlightsArray.splice(index, 1);
      highlights.reset([...highlightsArray]);
      highlights.notifyOnChanges();
    }
  }

  /**
   * Returns the key (possibly `undefined`) of the first highlight that has a match, or `undefined`
   * if there's no match
   */
  public get activeHighlightKey(): string | undefined {
    const highlightWithMatch = this.highlights.find(highlight => highlight.hasMatch);

    if (highlightWithMatch != null) {
      return highlightWithMatch.key;
    } else {
      return undefined;
    }
  }

  /**
   * Returns the label registered to the highlight with the given key, if any
   *
   * If no key is given and no highlight is registered without key, the first highlight is used
   * instead.
   * If no highlight is found for the given key, the result is `null`.
   */
  public getHighlightLabel(highlightKey: string | undefined): string | null {
    let highlight = this.highlights.find(h => h.key === highlightKey);

    if (highlight == null && highlightKey == null) {
      highlight = this.highlights.first;
    }

    if (highlight == null) {
      return null;
    }

    return highlight.label;
  }

  public ngOnDestroy(): void {
    this.highlights.destroy();
  }
}
