import {Observable} from 'rxjs';

import {Highlighter} from '../highlight';

/**
 * Container for options
 */
export abstract class OptionContainer {
  /**
   * Emits every time the highlight might change, i.e. the result of `findHighlight` for given
   * parameters should be the same until this observable emits
   */
  public abstract readonly queryChange: Observable<void>;

  /**
   * Looks for highlights in the given content.
   */
  public abstract findHighlight(
    highlighter: Highlighter,
    content: string,
    key?: string,
  ): string | null;
}
