import {ObservableInput} from 'rxjs';

import {ModalControl} from './modal-control';

/**
 * Superclass for modal content components.
 */
export abstract class ModalContentComponent<T, I = undefined> {
  /**
   * The control. This property must be implemented by the subclasses.
   *
   * This property is not strictly necessary in this interface, but defining it here has some
   * benefits:
   *
   * - It's needed in just about every modal content component, so why not define a common property
   * name?
   * - Defining it here means we can lock in the generic parameter T. This is the _only_ link
   * between the T parameter used in the ModalController and the T parameter in the injected
   * ModalControl.
   */
  public abstract get control(): ModalControl<T, I>;

  /**
   * Can this modal be cancelled?
   *
   * If this function exists, it is called before cancelling the modal. It is _not_ called when
   * submitting the modal or dismissing it.
   */
  public canCancel?(): true | ObservableInput<boolean>;
}
