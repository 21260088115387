import {InjectionToken} from '@angular/core';
import {PathIdentifier} from '@atlas/convertor';
import {ObservableInput} from 'rxjs';

/**
 * Dynamic url resolver which an application needs to provide. Note that is very similar to path
 * resolvers.
 */
export const DYNAMIC_RESOURCE_URL_RESOLVERS = new InjectionToken<DynamicResourceUrlResolver[]>(
  'dynamicResourceUrlResolvers',
);

/**
 * Describes a dynamic resource.
 */
export interface DynamicResource {
  /** Defines the endpoint */
  identifier: PathIdentifier;
  /** Should a xsrf token be added to the url, defaults to true */
  xsrfNeeded?: boolean;
  /** Which xsrf parameter should be used, defaults to 'xsrfToken' */
  xsrfQueryParamName?: string;
}

/**
 * Resolves the base url of the dynamic resource.
 */
export interface DynamicResourceUrlResolver {
  resolve(resource: DynamicResource): ObservableInput<URL | null> | URL | null;
}
