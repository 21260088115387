import {ComponentFactoryResolver, Injectable, Injector} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {ConfigurationExtractor, LanguageConfiguration} from '@hermes/configuration';
import {ModalResolution} from '@maia/modals';
import {PopUpController} from '@maia/pop-ups';
import {defer, from, Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

import {VetoModalComponent} from '../veto-modal/veto-modal.component';

type canDeactivateType =
  | Observable<boolean>
  | Promise<boolean>
  | boolean
  | Observable<CanDeactivateResult>
  | Promise<CanDeactivateResult>
  | CanDeactivateResult;

export interface CanDeactivateResult {
  canDeactivate: boolean;

  customVetoBody?: string;
  customVetoTitle?: string;
}

export interface CanDeactivateInterface {
  canDeactivate(): canDeactivateType;
}

function toObservable(
  deactivateResult: canDeactivateType,
): Observable<boolean | CanDeactivateResult> {
  if (typeof deactivateResult === 'boolean' || 'canDeactivate' in deactivateResult) {
    return of(deactivateResult);
  }
  return from(deactivateResult);
}

function castToCanDeactivateResult(input: boolean | CanDeactivateResult) {
  return typeof input === 'boolean' ? {canDeactivate: input} : input;
}

@Injectable({
  providedIn: 'root',
})
export class VetoStateCheck implements CanDeactivate<CanDeactivateInterface> {
  public constructor(
    private readonly _configExtractor: ConfigurationExtractor,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _injector: Injector,
    private readonly _popUpController: PopUpController,
  ) {}

  public canDeactivate(component: CanDeactivateInterface): Observable<boolean> {
    return defer(() =>
      toObservable(component.canDeactivate()).pipe(
        map(castToCanDeactivateResult),
        mergeMap(({canDeactivate, customVetoBody, customVetoTitle}) =>
          canDeactivate ? of(true) : this.confirmDeactivation(customVetoBody, customVetoTitle),
        ),
      ),
    );
  }

  public confirmDeactivation(customBody?: string, userTitle?: string): Observable<boolean> {
    return defer(() => {
      const title =
        userTitle ||
        this._configExtractor.extract(
          new LanguageConfiguration({
            de: 'Sind Sie sicher, dass Sie diese Seite verlassen wollen?',
            en: 'Sure you want to leave this page?',
            fr: 'Voulez-vous vraiment quitter cette page?',
            nl: 'Zeker dat je deze pagina wilt verlaten?',
          }),
        );

      return this._popUpController
        .prepare(
          this._componentFactoryResolver.resolveComponentFactory(VetoModalComponent),
          this._injector,
          {title},
          {
            withClickableBackdrop: false,
            withVisibleBackdrop: true,
            input: {customBody},
          },
        )
        .pipe(map(modalResult => modalResult.resolution === ModalResolution.CONFIRMED));
    });
  }
}
