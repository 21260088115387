import {ChangeDetectionStrategy, Component, ContentChild, TemplateRef} from '@angular/core';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {OptionComponent} from '@maia/select';

/**
 * @ngModule InputWithSelectModule
 */
@Component({
  selector: 'maia-input-with-select-option',
  templateUrl: './icon-text.component.html',
  styleUrls: ['./icon-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class IconTextComponent<T> extends OptionComponent<T> {
  @ContentChild(TemplateRef)
  public label: TemplateRef<{}>;
}
