import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationFuncSignSvgModule
 */
@Component({
  selector: 'svg[ivyHermesInformationFuncSign]',
  template: '<svg:path d="M40.856.008L6.014 2.312a4.758 4.758 0 0 0-4.091 4.039L.032 20.452c-.266 2.111 1.143 3.764 3.12 3.525l34.885-3.286a5.025 5.025 0 0 0 4.134-4.186l1.8-12.926A2.993 2.993 0 0 0 40.856.008zM15.8 15.173a2.738 2.738 0 0 1-1.787.542 3.189 3.189 0 0 1-1.367-.294 2.325 2.325 0 0 1-.971-.817 2.136 2.136 0 0 1-.344-1.212h1.2a1.221 1.221 0 0 0 .387.964 1.608 1.608 0 0 0 1.108.34 1.436 1.436 0 0 0 .937-.266.883.883 0 0 0 .309-.707.9.9 0 0 0-.318-.744 3.851 3.851 0 0 0-1.151-.532 5.206 5.206 0 0 1-1.324-.6 1.9 1.9 0 0 1-.937-1.643 1.831 1.831 0 0 1 .679-1.46 2.624 2.624 0 0 1 1.754-.578 2.756 2.756 0 0 1 1.28.285 2.1 2.1 0 0 1 .885.808 2.154 2.154 0 0 1 .318 1.156H15.27a1.172 1.172 0 0 0-.335-.89 1.359 1.359 0 0 0-.962-.322 1.416 1.416 0 0 0-.911.267.9.9 0 0 0-.327.743.828.828 0 0 0 .352.67 3.943 3.943 0 0 0 1.152.524 4.919 4.919 0 0 1 1.289.578 2.2 2.2 0 0 1 .714.744 2.008 2.008 0 0 1 .223.982 1.785 1.785 0 0 1-.665 1.462zm2.965.432h-1.183V8.27h1.186zm6.73-.946a2.331 2.331 0 0 1-1.031.79 4.043 4.043 0 0 1-1.5.266 2.782 2.782 0 0 1-1.53-.422A2.7 2.7 0 0 1 20.41 14.1a4.364 4.364 0 0 1-.378-1.817V11.7a4.054 4.054 0 0 1 .748-2.6 2.5 2.5 0 0 1 2.079-.936 2.62 2.62 0 0 1 1.822.606 2.655 2.655 0 0 1 .808 1.744H24.32A1.342 1.342 0 0 0 22.885 9.2a1.382 1.382 0 0 0-1.22.615 3.248 3.248 0 0 0-.43 1.809v.56a3.109 3.109 0 0 0 .473 1.845 1.5 1.5 0 0 0 1.306.652 1.648 1.648 0 0 0 1.3-.441v-1.435h-1.42v-.964h2.6v2.818zm6.764.946h-1.2l-3.06-5.2v5.205h-1.186V8.27h1.194l3.069 5.223V8.27h1.186z" transform="matrix(.8 0 0 .8 4.4 2.4)"></svg:path>',

  styles: [
    ':host { fill: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 44 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationFuncSignSvgComponent {
}

@NgModule({
  declarations: [
    InformationFuncSignSvgComponent,
  ],
  exports: [
    InformationFuncSignSvgComponent,
  ],
})
export class InformationFuncSignSvgModule {}
