import {ChangeDetectionStrategy, Component, HostBinding} from '@angular/core';

import {InputGroupComponent} from './input-group.component';

/**
 * @ngModule FormsModule
 */
@Component({
  selector: 'maia-input-group-suffix',
  templateUrl: './input-group-suffix.component.html',
  styleUrls: ['./input-group-suffix.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputGroupSuffixComponent {
  public constructor(private readonly _inputGroup: InputGroupComponent) {}

  @HostBinding('class.maia-input-group-suffix--disabled')
  public get disabled(): boolean {
    return this._inputGroup.disabled;
  }
}
