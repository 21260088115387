import {BusinessType} from '@atlas/businesstypes';
import {
  AnyBusinessType,
  DeepTypedObject,
  DeepTypedUnknownObject,
  mapValues,
} from '@atlas/convertor';

import {businessTypeConvertor} from './businesstype-convertors/index';
import {isTypedJSON, TypedJSON} from './typed-json';

function mapToTypedJsonHelper(
  value: AnyBusinessType | DeepTypedObject<AnyBusinessType>,
): TypedJSON | DeepTypedObject<TypedJSON> {
  if (value instanceof BusinessType) {
    return businessTypeConvertor.fromBusinessType(value);
  }

  return mapToTypedJson(value);
}

/**
 * Maps an object containing `BusinessType`s into an object containing TypedJSON.
 */
export function mapToTypedJson(value: DeepTypedUnknownObject): DeepTypedObject<TypedJSON> {
  return mapValues(value, mapToTypedJsonHelper);
}

function mapFromTypedJsonHelper(
  value: TypedJSON | DeepTypedObject<TypedJSON>,
): AnyBusinessType | DeepTypedObject<AnyBusinessType> {
  if (isTypedJSON(value)) {
    return businessTypeConvertor.toBusinessType(value);
  }

  return mapFromTypedJson(value);
}

/**
 * Maps an object containing TypedJSON into an object containing `BusinessType`s.
 */
export function mapFromTypedJson(
  value: DeepTypedObject<TypedJSON>,
): DeepTypedObject<AnyBusinessType> {
  return mapValues(value, mapFromTypedJsonHelper);
}
