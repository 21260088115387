export const INPUT_CHAR = '#';
export const RE_INPUT_CHAR = /#/g;
export const DEFAULT_PLACEHOLDER_CHAR = '_';

// Non breaking regular-width space. We don't use a regular space because we'd like to be able to
// tell the difference between a "significant" space and a spacer.
export const SPACER_CHAR = '\u00A0';

/**
 * The MaskHelper contains methods for formatting/unformatting input, applying the placeholder and
 * converting the caret position from a formatted input to a non-formatted string or vice versa.
 *
 * Examples use the following special characters:
 *
 * - `[` marks the start of the string,
 * - `]` marks the end,
 * - `|` marks the caret
 *
 * This class uses the following terminology:
 *
 * - Unformatted string: the raw input string, without any formatting whatsoever.
 * - Unformatted spaced string: the raw input string, where each format part of the mask in the
 *   string is replaced with a space
 * - Formatted string: the string formatted to match the mask.
 *
 * An example of the terms defined here:
 *
 * ```
 * Mask:
 *   [+++ ### / ### / #### +++]
 * Unformatted value:
 *   [1234567890]
 * Unformatted spaced value:
 *   [123 456 7890]
 * Formatted value:
 *   [    123   456   7890]
 * ```
 */
export class MaskHelper {
  /**
   * The mask string.
   */
  private readonly _mask: string;

  /**
   * The placeholder, only the input characters, i.e. if we want to show `DD/MM/YYYY` for mask
   * `##/##/####` this value would be `DDMMYYYY`.
   */
  private readonly _placeholder: string;

  /**
   * @param mask The mask string, where `#` is the input character, e.g. `##-##-####`
   * @param placeholder The placeholder string, formatted in the format, e.g. `DD-MM-YYYY` for mask
   * `##-##-####`
   */
  public constructor(mask: string, placeholder?: string) {
    this._mask = mask;
    const maskLength = mask.length;
    const inputLength = (mask.match(RE_INPUT_CHAR) || []).length;

    if (inputLength === 0) {
      throw new Error(`Mask "${mask} doesn't contain input characters`);
    }

    if (placeholder != null) {
      if (placeholder.length !== mask.length) {
        throw new Error(`Mask "${mask}" and placeholder "${placeholder}" have different lengths`);
      }

      const placeholderParts: string[] = [];

      for (let i = 0; i < maskLength; i++) {
        if (mask[i] === INPUT_CHAR) {
          placeholderParts.push(placeholder[i]);
        } else if (mask[i] !== placeholder[i]) {
          throw new Error(
            `Mask "${mask}" and placeholder "${placeholder}" have different non-input parts`,
          );
        }
      }

      this._placeholder = placeholderParts.join('');
    } else {
      this._placeholder = DEFAULT_PLACEHOLDER_CHAR.repeat(inputLength);
    }
  }

  /**
   * Returns the visible placeholder to show.
   *
   * @param value The unformatted value of the input
   */
  public getPlaceholder(value: string | undefined | null): string {
    let idx = -1;
    const {length} = value || '';

    return this._mask.replace(RE_INPUT_CHAR, () => {
      idx++;

      if (idx < length) {
        return ' ';
      } else {
        return this._placeholder[idx];
      }
    });
  }

  /**
   * Formats the input value with spaces, to make the value match the input characters defined in
   * the mask.
   *
   * @param value The unformatted input
   */
  public formatInput(value: string | undefined | null): string {
    const maxValueLength = this._placeholder.length;
    const valueLength = Math.min((value || '').length, maxValueLength);
    const formattedInput: string[] = [];

    const maskLength = this._mask.length;
    let valueIdx = 0;
    for (let i = 0; i < maskLength; i++) {
      if (this._mask[i] === INPUT_CHAR) {
        if (valueIdx >= valueLength) {
          break;
        }

        formattedInput.push(value![valueIdx]);
        valueIdx++;

        if (valueIdx === maxValueLength) {
          // We're at the end of the value, don't add any extra spacers here
          break;
        }
      } else {
        formattedInput.push(SPACER_CHAR);
      }
    }

    return formattedInput.join('');
  }

  /**
   * Unformats the input. This method is the inverse of the formatInput method, except it gracefully
   * handles significant characters typed in the space between input fields.
   *
   * @see #formatInput
   * @param formattedInput The formatted input
   */
  public unformatInput(formattedInput: string | undefined | null): string {
    const {length} = formattedInput || '';
    const input: string[] = [];

    for (let valueIndex = 0; valueIndex < length; valueIndex++) {
      const currentCharacter = formattedInput![valueIndex];

      if (currentCharacter !== SPACER_CHAR) {
        input.push(currentCharacter);
      }
    }

    if (input.length > this._placeholder.length) {
      // more input characters than we can handle
      return input.slice(0, this._placeholder.length).join('');
    }

    return input.join('');
  }

  /**
   * Maps a caret position from the unformatted input to the formatted input.
   *
   * This is the inverse of the `unmapCaret` function.
   *
   * @see #unmapCaret
   * @param caret The caret position in the unformatted input
   */
  public mapCaret(caret: number): number {
    const mask = this._mask;
    const lastInputInMaskIndex = mask.lastIndexOf(INPUT_CHAR);

    for (let inputIndex = 0, maskIndex = 0; maskIndex <= lastInputInMaskIndex; maskIndex++) {
      if (mask[maskIndex] === INPUT_CHAR) {
        if (inputIndex === caret) {
          return maskIndex;
        }

        inputIndex++;
      }
    }

    return lastInputInMaskIndex + 1;
  }

  /**
   * Maps a caret position from the formatted input to the unformatted input.
   *
   * This is the inverse of the `mapCaret` function.
   *
   * @see #mapCaret
   * @param caret The caret position in the formatted input
   */
  public unmapCaret(caret: number): number {
    const mask = this._mask;

    let inputIndex = 0;

    for (let i = 0; i < caret; i++) {
      if (mask[i] === INPUT_CHAR) {
        inputIndex++;
      }
    }

    return inputIndex;
  }

  /**
   * Maps a caret position from the unformatted spaced input to the formatted input.
   *
   * To give an example:
   *
   * ```
   * Mask:
   *         [+++ ### / ### / #### +++]
   * Formatted value:
   *         [    123   456|  7890]
   * Formatted caret position: 13
   * Unformatted spaced value:
   *         [123 456| 7890]
   * Unformatted spaced caret position: 7
   * ```
   *
   * This is the inverse of the `unmapSpacedCaret` function.
   *
   * @see #unmapSpacedCaret
   * @param caret The caret position in the unformatted input
   */
  public mapSpacedCaret(caret: number): number {
    const mask = this._mask;
    const lastInputInMaskIndex = mask.lastIndexOf(INPUT_CHAR);

    let maskIndex = mask.indexOf(INPUT_CHAR);

    for (let unmappedIndex = 0; unmappedIndex < caret; unmappedIndex++) {
      if (mask[maskIndex] === INPUT_CHAR) {
        maskIndex++;
      } else {
        while (mask[maskIndex] !== INPUT_CHAR && maskIndex < lastInputInMaskIndex) {
          maskIndex++;
        }
      }
    }

    return maskIndex;
  }

  /**
   * Maps a caret position from the formatted input to the unformatted spaced input.
   *
   * This is the inverse of the `mapSpacedCaret` function.
   *
   * @see #mapSpacedCaret
   * @param caret The caret position in the formatted input
   */
  public unmapSpacedCaret(caret: number): number {
    const mask = this._mask;
    const lastInputInMaskIndex = mask.lastIndexOf(INPUT_CHAR);

    let inInputPart = false;
    let mappedIndex = 0;

    let maskIndex = mask.indexOf(INPUT_CHAR);
    while (true) {
      if (maskIndex === caret || maskIndex > lastInputInMaskIndex) {
        return mappedIndex;
      }

      if (mask[maskIndex] === INPUT_CHAR) {
        inInputPart = true;
        mappedIndex++;
      } else if (inInputPart) {
        inInputPart = false;
        mappedIndex++;
      }

      maskIndex++;
    }
  }
}
