import {InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';

export interface AuthorisedFunctionsProvider {
  authorisedFunctions(): Observable<string[] | AuthorisationFunction[]>;
}

/**
 * authorisation object per transactionId/functionNumber (found in PersonalisationOutput.functions)
 */
export interface AuthorisationFunction {
  functionNumber: string;
  questionaireC: boolean;
  actionCode: string;
}

export const AUTHORISATION_PROVIDER_TOKEN = new InjectionToken<AuthorisedFunctionsProvider>(
  'Authorisation#AuthorisedFunctionsProvider',
);
