import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionFuncTurnLeftSvgModule
 */
@Component({
  selector: 'svg[ivyHermesActionFuncTurnLeft]',
  template: '<svg:path d="M8.5 5.6h5.6a4.7 4.7 0 0 1 4.6 4.6h0v2.3" fill="none" stroke-linecap="round" stroke-miterlimit="8"></svg:path><svg:path d="M5.3 11.8h9.2V21H5.3z" fill="none" stroke-linecap="round" stroke-miterlimit="8"></svg:path><svg:path d="M11.1 3L8.5 5.6l2.6 2.6" fill="none" stroke-linecap="round" stroke-miterlimit="8"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionFuncTurnLeftSvgComponent {
}

@NgModule({
  declarations: [
    ActionFuncTurnLeftSvgComponent,
  ],
  exports: [
    ActionFuncTurnLeftSvgComponent,
  ],
})
export class ActionFuncTurnLeftSvgModule {}
