import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
import {CssClassUtility, CssClassUtilityFactory} from '@maia/core';

/**
 * Possible values for the type of a `<maia-validation />`.
 */
export type ValidationType = 'warning' | 'error';

const DEFAULT_VALIDATION_TYPE: ValidationType = 'error';

const CLASSES = {
  type: {
    warning: 'maia-type--warning',
    error: 'maia-type--error',
  } as {[key in ValidationType]: string},
};

function isValidType(type: string): type is ValidationType {
  return type === 'warning' || type === 'error';
}

/**
 * Validation component, showing an icon based on the type. Hovering over the icon, or focusing the
 * icon shows the validation message.
 *
 * @ngModule FormsModule
 */
@Component({
  selector: 'maia-validation',

  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss'],

  host: {
    '[attr.role]': '"alert"',
  },

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationComponent {
  private _type: ValidationType;

  private _cssClassUtility: CssClassUtility<typeof CLASSES>;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(CLASSES, renderer, elementRef);

    this.type = DEFAULT_VALIDATION_TYPE;
  }

  /**
   * The validation type.
   * This defaults to 'error'
   */
  @Input()
  public get type(): ValidationType {
    return this._type;
  }

  public set type(type: ValidationType) {
    if (type === this._type) {
      return;
    }

    if (!isValidType(type)) {
      throw new Error(`Invalid validation type "${type}"`);
    }

    this._cssClassUtility.setValue('type', type);
    this._type = type;
  }

  @HostListener('click', ['$event'])
  public _stopPropagation(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }
}
