import {bigConstructor} from './big-utils';

export const MOD_VALUE = 97;

/**
 * checks if the substring of a value is a valid modulo
 *
 * @param moduloValue string representation of the businesstype to check the modulo
 * @param checkDigit number to check modulo validity
 */
export function validateModulo(moduloValue: string, checkDigit: string): boolean {
  const modulo = bigConstructor(moduloValue).mod(MOD_VALUE);
  const check = bigConstructor(checkDigit);
  return (modulo.eq(0) ? bigConstructor(MOD_VALUE) : modulo).eq(check);
}
