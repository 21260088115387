import {Injectable} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';
import {Logger} from '@atlas/logger';
import {ErrorCallback, MncBridgeService, SuccessCallback} from '@hermes-mnc/core';
import {defer, Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {PlatformDownloadManager} from './download-manager';
import {Target} from './target';
import {UniformTypeIdentifier} from './uniform-type-indentifier';

const FEATURE_NAME = 'bridgeOpenFileFeature';

@Injectable({
  providedIn: 'root',
})
export class MncDownloadManager implements PlatformDownloadManager {
  private readonly _logger: Logger;
  public constructor(
    private readonly bridgeService: MncBridgeService,
    loggerFactory: LoggerFactory,
  ) {
    this._logger = loggerFactory.createLogger('DownloadManager (mnc)');
  }

  /**
   * Downloads (and opens) a (static) file url.
   * @param url
   * @param target
   * @param type
   */
  public downloadWithUrl(
    url: string,
    target: Target = Target.BLANK,
    type = UniformTypeIdentifier.PDF,
  ): Observable<void> {
    return defer(() => {
      this._logger.debug(
        `Executing MncBridgeService.exec with url: '${url}' and type '${type.toString()}'`,
      );
      this.bridgeService.exec(FEATURE_NAME, 'open', [url, type]);
      return of(undefined);
    }).pipe(
      catchError((errorMessage: string) => {
        this._logger.error(`Download with url stopped with error: ${errorMessage}`);
        return of(undefined);
      }),
    );
  }

  /**
   * Verifies whether the file can be opened on MNC.
   * @param url
   * @param type
   */
  public canOpen(url: string, type = UniformTypeIdentifier.PDF): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const onSuccess: SuccessCallback = (value: number) => {
        const result = value > 0;
        observer.next(result);
        return observer.complete();
      };

      const onError: ErrorCallback = (error: Error) => {
        this._logger.warn(`canOpen stopped by error: ${error.toString()}`);
        observer.next(false);
        return observer.complete();
      };

      this.bridgeService.exec(FEATURE_NAME, 'canOpen', [url, type], onSuccess, onError);
    });
  }
}
