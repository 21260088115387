import {Hidden, Text} from '@atlas/businesstypes';
import {
  Call,
  CallOptions,
  ConnectorErrorResponse,
  ConnectorErrorResponseConstructorArguments,
  ConnectorResponseConstructorArguments,
  ConnectorSigningResponse,
  DeepTypedObject,
  PathIdentifier,
} from '@atlas/convertor';
import {Observable, of, throwError} from 'rxjs';

import {TypedJSON} from './typed-json';
import {mapFromTypedJson, mapToTypedJson} from './typed-json-converter';

export interface TypedJsonConnectorResponseBaseConstructorArguments<O>
  extends ConnectorResponseConstructorArguments<O> {
  resultCode?: ResultCode;
  signingId?: Hidden;
}

export class TypedJsonConnectorSigningResponse<O> extends ConnectorSigningResponse<O> {
  public readonly resultCode?: ResultCode;
  private readonly signingId?: Hidden;

  public constructor(input: TypedJsonConnectorResponseBaseConstructorArguments<O>) {
    super(input);
    this.resultCode = input.resultCode;
    this.signingId = input.signingId;
  }
  public isSuccess(): boolean {
    return this.resultCode === ResultCode.Success;
  }

  public isWarning(): boolean {
    return this.resultCode === ResultCode.Warning;
  }

  public isSigningRequired(): boolean {
    return this.resultCode === ResultCode.SigningRequired;
  }

  public getSigningId(): Hidden | undefined {
    return this.signingId;
  }

  public isRetrySigning(): boolean {
    return this.resultCode === ResultCode.SigningRetry;
  }
}

export interface TypedJsonConnectorErrorResponseConstructorArguments<O>
  extends ConnectorErrorResponseConstructorArguments<O> {
  resultCode?: ResultCode;
  httpResponseCode: number;
}

export class TypedJsonConnectorErrorResponse<O> extends ConnectorErrorResponse<O> {
  public readonly httpResponseCode: number;
  public readonly resultCode?: ResultCode;

  public constructor(input: TypedJsonConnectorErrorResponseConstructorArguments<O>) {
    super(input);
    this.resultCode = input.resultCode;
    this.httpResponseCode = input.httpResponseCode;
  }

  public isError(): boolean {
    return this.resultCode === ResultCode.Error;
  }

  public isTimeout(): boolean {
    return this.resultCode === ResultCode.Timeout;
  }

  public isUnknown(): boolean {
    return this.resultCode == null && isResponseOk(this.httpResponseCode);
  }

  public isHttpError(): boolean {
    return !isResponseOk(this.httpResponseCode);
  }
}

export enum ResultCode {
  Success = '00',
  Warning = '01',
  Error = '02',
  Timeout = '03',
  SigningRequired = '09',
  SigningRetry = '10',
}

const enum HttpStatusCode {
  Ok = 200,
  MultipleChoices = 300,
}

function isResponseOk(httpResponseCode: number): boolean {
  return httpResponseCode >= HttpStatusCode.Ok && httpResponseCode < HttpStatusCode.MultipleChoices;
}

interface Header {
  resultCode: Text;
  resultMessage: Text;
  signingId?: Hidden;
}

/**
 * Abstract superclass for `Call` that use the TypedJSON format.
 *
 * This class implements the conversion part of the call using a generic mapping
 * between objects containing `BusinessType`s and objects containing TypedJSON.
 */
export abstract class TypedJSONCall<I, O> implements Call<I, O> {
  /**
   * The identifier of this call.
   */
  public abstract get identifier(): PathIdentifier;

  /**
   * The options for this call.
   */
  public abstract get options(): CallOptions;

  /**
   * Converts the given input object containing `BusinessType`s into an object containing TypedJSON.
   *
   * @param input The input object to convert
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  public convertInput(input: I): object | null {
    return mapToTypedJson(input as any);
  }

  /**
   * Converts the given TypedJSON output object into an object containing `BusinessType`s.
   *
   * The success callback on the observable is triggered when the http response code is in the 200
   * range and the resultCode in the header is `Succeeded` or `Warning`. In all other cases the
   * error callback will be triggered.
   *
   * @param output The output object to convert
   * @param httpResponseCode The response code of the http request
   */
  public convertOutput(
    // eslint-disable-next-line @typescript-eslint/ban-types
    output: object,
    httpResponseCode: number,
  ): Observable<ConnectorSigningResponse<O>> {
    if (!isResponseOk(httpResponseCode)) {
      return this.handleHttpError(httpResponseCode);
    }

    const data = (mapFromTypedJson(output as DeepTypedObject<TypedJSON>) as unknown) as O;
    const {message, resultCode, signingId} = this.getHeaderData(data);

    switch (resultCode) {
      case ResultCode.Success:
      case ResultCode.Warning:
      case ResultCode.SigningRequired:
      case ResultCode.SigningRetry:
        return of(
          new TypedJsonConnectorSigningResponse<O>({
            resultCode,
            message,
            data,
            signingId,
          }),
        );
      default:
        return throwError(
          new TypedJsonConnectorErrorResponse<O>({
            httpResponseCode,
            resultCode,
            message,
            data,
          }),
        );
    }
  }

  private handleHttpError(httpResponseCode: number): Observable<ConnectorSigningResponse<O>> {
    return throwError(
      new TypedJsonConnectorErrorResponse<O>({
        httpResponseCode,
      }),
    );
  }

  private getHeaderData(
    data: any,
  ): {
    resultCode?: ResultCode;
    message?: string;
    signingId?: Hidden;
  } {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const header: Header = data?.header;
    const resultCode: ResultCode | undefined =
      header && header.resultCode ? (header.resultCode.asString() as ResultCode) : undefined;
    const message = header && header.resultMessage ? header.resultMessage.asString() : undefined;
    const signingId = header ? header.signingId : undefined;
    return {message, resultCode, signingId};
  }
}
