import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

/**
 * @ngModule ComparisonPanelModule
 */
@Component({
  selector: 'maia-comparison-panel',
  templateUrl: './comparison-panel.component.html',
  styleUrls: ['./comparison-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComparisonPanelComponent {
  @Input()
  @coerceBooleanPrimitive()
  public optionalBackground = false;
}
