import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule AppBranCbcSignSvgModule
 */
@Component({
  selector: 'svg[ivyHermesAppBranCbcSign]',
  template: '<svg:path d="M160 124.4a35.64 35.64 0 0 1-35.7 35.6H35.7A35.64 35.64 0 0 1 0 124.4V35.6A35.64 35.64 0 0 1 35.7 0h88.5A35.66 35.66 0 0 1 160 35.6z" fill="#00aeef"></svg:path><svg:path d="M86.6 76.3a6.2 6.2 0 1 0-12.4 0 6.12 6.12 0 0 0 3.5 5.6l-2.3 13.8h9.9L83 81.9a6 6 0 0 0 3.6-5.6z" fill="#fff"></svg:path><svg:path d="M93.3 37a2 2 0 0 1-2-2 11.4 11.4 0 1 0-22.8 0 2 2 0 0 1-4 0 15.5 15.5 0 0 1 31 0 2.27 2.27 0 0 1-2.2 2z" fill="#fff"></svg:path><svg:path d="M93.3 44.8a2 2 0 0 1-2-2V35a2 2 0 0 1 4 0v7.8a1.89 1.89 0 0 1-2 2z" fill="#fff"></svg:path><svg:path d="M66.5 57.9a2 2 0 0 1-2-2v-21a2 2 0 0 1 4 0v21a2.07 2.07 0 0 1-2 2z" fill="#fff"></svg:path><svg:path d="M105 110.2H55a6.66 6.66 0 0 1-6.6-6.6V60.9a6.66 6.66 0 0 1 6.6-6.6h50a6.66 6.66 0 0 1 6.6 6.6v42.8a6.64 6.64 0 0 1-6.6 6.5zM55 58.3a2.65 2.65 0 0 0-2.6 2.6v42.8a2.65 2.65 0 0 0 2.6 2.6h50a2.65 2.65 0 0 0 2.6-2.6V60.9a2.65 2.65 0 0 0-2.6-2.6z" fill="#fff"></svg:path><svg:path d="M113 139.4a22.2 22.2 0 0 1-6.5.9c-7.8 0-13.5-2.2-13.5-8.6 0-5.8 5.5-8.3 13.1-8.3a22.31 22.31 0 0 1 6.8.9v5.6a9.74 9.74 0 0 0-5.3-1.5c-2.9 0-5.8 1.4-5.8 3.5s2.9 3.5 5.8 3.5a9.74 9.74 0 0 0 5.3-1.5l.1 5.5z" fill="#fff"></svg:path><svg:path d="M67 139.4a22.2 22.2 0 0 1-6.5.9c-7.8 0-13.5-2.2-13.5-8.6 0-5.8 5.5-8.3 13.1-8.3a22.31 22.31 0 0 1 6.8.9v5.6a9.74 9.74 0 0 0-5.3-1.5c-2.9 0-5.7 1.4-5.7 3.5s2.9 3.5 5.7 3.5a9.74 9.74 0 0 0 5.3-1.5l.1 5.5z" fill="#fff"></svg:path><svg:path d="M70.2 123.8H86c4.1 0 5.5 1.6 5.5 4.1 0 2.8-2.5 4-5.1 4.1 2.6.2 5.2.5 5.2 3.8 0 2.2-1.3 4.2-5.9 4.2H70.2zm7.8 13h4.1c1.5 0 2.1-.6 2.1-1.6s-.5-1.6-2-1.6h-4.1l-.1 3.2zm0-6.1h4c1.5 0 2.1-.6 2.1-1.6s-.6-1.6-2-1.6h-4v3.2z" fill="#fff"></svg:path>',

  styles: [
  ],

  host: {
    'viewBox': '0 0 160 160',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBranCbcSignSvgComponent {
}

@NgModule({
  declarations: [
    AppBranCbcSignSvgComponent,
  ],
  exports: [
    AppBranCbcSignSvgComponent,
  ],
})
export class AppBranCbcSignSvgModule {}
