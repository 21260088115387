import {randomize} from './randomize-string';

/**
 * Create an id generator function that creates a new unique id every time it's called.
 */
export function createIdGenerator(rawName: string): () => string {
  const name = randomize(rawName);
  let counter = 0;

  return () => {
    counter++;
    return `${name}-${counter}`;
  };
}
