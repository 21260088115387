import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule AlertExprExclamationTriangleSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaAlertExprExclamationTriangle]',
  template: '<svg:circle cx="24" cy="34.78" r=".86"></svg:circle><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M5.88 40.82L24 7.18l18.12 33.64H5.88z"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M24 19.26v9.48"></svg:path><svg:circle cx="24" cy="34.78" r=".86" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:circle>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertExprExclamationTriangleSvgComponent {
}

@NgModule({
  declarations: [
    AlertExprExclamationTriangleSvgComponent,
  ],
  exports: [
    AlertExprExclamationTriangleSvgComponent,
  ],
})
export class AlertExprExclamationTriangleSvgModule {}
