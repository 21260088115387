import {Formatter} from '../base/formatter';

import {ClientNumber} from './businesstype';

const UNFORMAT_REGEX = /(-|\/|·| )/g;

/**
 * The pretty card number format used to represent card numbers.
 */
export const ClientNumberFormatter: Formatter<ClientNumber> = {
  /**
   * Formats the client number
   *
   * @param value The client number to format
   */
  format(value: ClientNumber): string {
    return value.asString();
  },

  /**
   * Remove delimiters and placeholder.
   *
   * @param clientNumberString The string to unformat.
   */
  unformat(clientNumberString: string): string {
    return clientNumberString.replace(UNFORMAT_REGEX, '');
  },
};
