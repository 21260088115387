import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  forwardRef,
  Optional,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {
  DropdownHost,
  DropdownOptions,
  DropdownPosition,
  DropdownTemplateContext,
} from '@maia/dropdowns';
import {CapturedInput} from '@maia/forms/capture';
import {Option, OptionContainer} from '@maia/select';
import {Subscription} from 'rxjs';

import {BaseInputSelectComponent} from './base-input-select.component';
import {μInputSelectOpener} from './input-select-opener.directive';

const ATTR_ARIA_EXPANDED = 'aria-expanded';

/**
 * Select component to be used inside forms
 *
 * If you want to use a select outside of forms, look at `<maia-select>` (@maia/select) instead.
 *
 * This component expects an input inside, beit a real `<input>` or a `<maia-fake-input>`. All input
 * handling is handled through the input element. This element should never be focused directly.
 *
 * The value of the input element will not be set automatically, the consumer of this component is
 * responsible for binding that value. __Note__ that setting the value of an `<input>` element to
 * `undefined` will show "undefined" in the input, you must set the value to `null` to clear it.
 *
 * Example usage:
 *
 * ```html
 * <maia-input-select
 *     name="value" [(ngModel)]="value"
 *     [disabled]="disabled | atlasBoolean">
 *   <input maiaInput
 *     placeholder="Select value"
 *     [value]="(value | atlasText) || null">
 *
 *   <!-- options is a list of Text instances -->
 *   <maia-option *ngFor="let option of options" [value]="option">
 *     Option {{ option | atlasText }}
 *   </maia-option>
 * </maia-input-select>
 * ```
 *
 * This component follows the WAI ARIA guidelines for a collapsible listbox, it is fully accessible
 * using keyboard and screenreader.
 *
 * @see [WAI ARIA collapsible listbox](https://www.w3.org/TR/wai-aria-practices-1.1/examples/listbox/listbox-collapsible.html)
 * @ngModule InputSelectModule
 */
@Component({
  selector: 'maia-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: OptionContainer, useExisting: forwardRef(() => InputSelectComponent)},
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputSelectComponent), multi: true},
  ],
})
@UntilDestroy()
export class InputSelectComponent<T> extends BaseInputSelectComponent<T> implements AfterViewInit {
  @ViewChild('dropdownHost', {static: true})
  public dropdownHost: DropdownHost;

  @ViewChild('dropdownContent', {static: true})
  public dropdownContent: TemplateRef<DropdownTemplateContext<Option<T>>>;

  @ContentChildren(Option)
  public options: QueryList<Option<T>>;

  protected value?: T | null = undefined;

  private _input?: CapturedInput = undefined;

  public constructor(
    cdr: ChangeDetectorRef,
    renderer: Renderer2,
    @Optional() opener?: μInputSelectOpener,
  ) {
    super(cdr, renderer, opener);
  }

  protected _hasCapturedInput(): boolean {
    return this._input != null;
  }

  /**
   * The captured input
   *
   * Accessing this property when no input has been captured will yield an error
   */
  @ContentChild(CapturedInput)
  public get capturedInput(): CapturedInput {
    this._assertHasCapturedInput();

    return this._input!;
  }

  public set capturedInput(capturedInput: CapturedInput) {
    this._inputSubscription.unsubscribe();
    this._input = capturedInput;

    if (this._input != null) {
      this._input.setDisabledState(this.isDisabled);
      const {element} = this._input;

      const inputSubscription = new Subscription();

      const open = (event: Event) => this.openDropdown(event);

      inputSubscription.add(this._renderer.listen(element, 'click', open));
      inputSubscription.add(this._renderer.listen(element, 'keydown.enter', open));
      inputSubscription.add(this._renderer.listen(element, 'keydown.space', open));

      this._renderer.setAttribute(element, 'aria-haspopup', 'listbox');
      this._renderer.removeAttribute(element, ATTR_ARIA_EXPANDED);

      if (element.tagName === 'INPUT') {
        // mark the input readonly to prevent the client from changing the value using the keyboard
        (element as HTMLInputElement).readOnly = true;
      } else {
        // set the tabIndex to 0 if not set to ensure the element is in the tab order
        element.tabIndex = element.tabIndex || 0;
      }

      this._inputSubscription = inputSubscription;
    }
  }

  protected get _dropdownOptions(): DropdownOptions {
    return {position: DropdownPosition.BOTTOM_ALIGNED};
  }

  public openDropdown(event: Event): void {
    this._open();

    event.preventDefault();
    event.stopPropagation();
  }

  public ngAfterViewInit(): void {
    this.initKeyManager();
  }
}
