import {Injectable, TemplateRef} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';

/**
 * Storage container for the footer of a pop-up.
 */
@Injectable()
export class FooterContainer {
  private _footer?: TemplateRef<any> = undefined;

  private _footer$ = new ReplaySubject<TemplateRef<any> | undefined>(1);

  /**
   * Sets the footer. Note that setting a footer is not allowed if there's already a footer.
   */
  public setFooter(footer: TemplateRef<any> | undefined): void {
    if (footer != null && this._footer != null) {
      throw new Error('Do you have multiple *maiaPopUpFooter directives?');
    }

    this._footer = footer;
    this._footer$.next(footer);
  }

  /**
   * Observable that emits the footer for the pop-up. This observable immediately emits the footer
   * if any footer has already been set.
   */
  public get footer$(): Observable<TemplateRef<any> | undefined> {
    return this._footer$.asObservable();
  }
}
