import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {onDestroy, UntilDestroy} from '@atlas-angular/rxjs';

import {InputContainer} from '../input-container/input-container.interface';

/**
 * @ngModule FormsModule
 */
@Component({
  selector: 'maia-fake-input',
  template: '<ng-content></ng-content>',
  styleUrls: ['./fake-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FakeInputComponent), multi: true},
  ],
})
export class FakeInputComponent implements ControlValueAccessor {
  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('class.maia-fake-input--disabled')
  public disabled = false;

  @HostListener('blur')
  public _onTouch = () => {};

  public constructor(private readonly _cdr: ChangeDetectorRef) {}

  public writeValue(obj: any): void {
    // Don't do anything, this is a _fake_ input
  }

  public registerOnChange(fn: any): void {
    // Don't register anything, this is a _fake_ input
  }

  public registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this._cdr.detectChanges();
  }
}

/**
 * @ngModule FormsModule
 */
@Directive({
  selector:
    'maia-fake-input[ngModel], maia-fake-input[formControl], maia-fake-input[formControlName]',
})
@UntilDestroy()
export class FakeInputWithNgControlDirective implements OnInit {
  public constructor(
    private readonly _control: NgControl,
    @Optional() private readonly _container?: InputContainer,
  ) {}

  @HostBinding('class.maia-fake-input--as-placeholder')
  public get empty(): boolean {
    return this._control.value == null;
  }

  public ngOnInit() {
    if (this._container != null) {
      const destroyFn = this._container.registerFormControl(this._control);
      onDestroy(this).subscribe(destroyFn);
    }
  }

  @HostListener('blur')
  public blur(): void {
    if (this._container != null) {
      this._container.focused = false;
    }
  }

  @HostListener('focus')
  public focus(): void {
    if (this._container != null) {
      this._container.focused = true;
    }
  }
}
