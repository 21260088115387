import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ButtonsModule} from '@maia/buttons';
import {PopUpsModule} from '@maia/pop-ups';

import {VetoModalComponent} from './veto-modal/veto-modal.component';

/**
 * The veto module provides services & components to handle a route guard for deactivating
 * route. If a route is deactivated it checks if there are no pending changes
 * and opens a popup if so
 */
@NgModule({
  imports: [CommonModule, ButtonsModule, PopUpsModule],
  declarations: [VetoModalComponent],
  exports: [VetoModalComponent],
  entryComponents: [VetoModalComponent],
})
export class VetoModule {}
