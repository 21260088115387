import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule CommunicationFuncTagSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaCommunicationFuncTag]',
  template: '<svg:g class="nc-icon-wrapper" transform="matrix(.8 0 0 .8 2.4 2.4)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"><svg:path fill="none" stroke-miterlimit="10" d="M13 22l9-9L10 1H1v9z"></svg:path><svg:circle data-color="color-2" fill="none" stroke-miterlimit="10" cx="7" cy="7" r="2"></svg:circle></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunicationFuncTagSvgComponent {
}

@NgModule({
  declarations: [
    CommunicationFuncTagSvgComponent,
  ],
  exports: [
    CommunicationFuncTagSvgComponent,
  ],
})
export class CommunicationFuncTagSvgModule {}
