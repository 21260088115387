import {BusinessType, createStringifier} from '../base/businesstype';

const stringifier = createStringifier('text');

/**
 * BusinessType wrapper around texts without imposing any
 * format or structure on that text.
 */
export class Text extends BusinessType<string> {
  /**
   * Returns whether or not `value` is a Text.
   */
  public static isText(value: unknown): value is Text {
    return value instanceof Text;
  }

  /**
   * Constructs a new Text instance.
   *
   * @param value The text value
   * @param encryptedValue The encryptedValue, if any
   */
  public constructor(value: string, encryptedValue?: string) {
    if (typeof value !== 'string') {
      throw new Error(
        `Expected a string but got ${(typeof value === 'object' &&
          (value as object).constructor.name) ||
          typeof value}`,
      );
    }

    super(value, encryptedValue);
  }

  /**
   * Returns whether `other` is a `Text` representing the same value.
   */
  public equals(other: unknown): boolean {
    if (!other) {
      return false;
    }

    if (other === this) {
      return true;
    }

    return Text.isText(other) && this.internalValue === other.internalValue;
  }

  /**
   * Exposes the value of this BusinessType as a string. The returned value will
   * always be the same.
   */
  public asString(): string {
    return this.internalValue;
  }

  public toString(): string {
    return stringifier(this);
  }
}
