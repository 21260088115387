import {APP_INITIALIZER, ModuleWithProviders, NgModule, Provider, Type} from '@angular/core';
import {ConfigurationLoader, CONFIG_PROPERTIES} from './configuration-loader.service';
import {Configuration} from './configuration.abstract';

export function initializeApp(appConfig: ConfigurationLoader): () => Promise<void> {
  // We cannot return the lambda directly due to angular issue:
  // https://github.com/angular/angular/issues/14485
  // Using an intermediate variable as described in the workaround
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const res = () => appConfig.load();
  return res;
}

export function provideConfiguration(
  path: string,
  customConfiguration: Type<Configuration>,
): Provider[] {
  return [
    customConfiguration,
    {
      provide: CONFIG_PROPERTIES,
      useValue: {path, type: customConfiguration},
      multi: true,
    },
  ];
}

@NgModule({
  providers: [
    ConfigurationLoader,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigurationLoader],
      multi: true,
    },
  ],
})
export class ConfigurationModule {
  /**
   * Injecting the Module via the forConfiguration function will load a configuration
   * (json) file from the given path and store the object in the customConfiguration provider
   *
   * @param path the path to the configuration file
   * @param customConfiguration The injectable where to store the configuration (should extend
   * Configuration)
   * @return A module which contains the provider with the requested configuration
   * @deprecated Use `ConfigurationModule` with `provideConfiguration` instead
   */
  public static forConfiguration(
    path: string,
    customConfiguration: Type<Configuration>,
  ): ModuleWithProviders<ConfigurationModule> {
    return {
      ngModule: ConfigurationModule,
      providers: [provideConfiguration(path, customConfiguration)],
    };
  }
}
