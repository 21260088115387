import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {WindowRef} from '@atlas-angular/cdk/globals';
import {Decimal, DecimalUtils} from '@atlas/businesstypes';
import {DomIoService} from '@maia/core';

/**
 * @deprecated
 * This directive allow to scroll up to the system message position in the screen. Optionally a
 * parameter with the margin-top can be passed through.
 *
 * @ngModule SystemMessagesModule
 */
@Directive({
  selector: 'maia-system-message[scrollTo]',
})
export class SystemMessageScrollDirective implements OnInit {
  private _scrollTo = false;

  @Input()
  public get scrollTo(): boolean {
    return this._scrollTo;
  }

  public set scrollTo(scrollTo: boolean) {
    this._scrollTo = coerceBooleanProperty(scrollTo);
  }

  @Input()
  public scrollTopMargin: Decimal | number;

  public constructor(
    private readonly _windowRef: WindowRef,
    private readonly _elementRef: ElementRef,
    private readonly _domIo: DomIoService,
  ) {}

  public ngOnInit() {
    if (this._scrollTo) {
      this._scrollToMessage();
    }
  }

  /**
   * The scroll top function
   */
  private _scrollToMessage() {
    let offsetLeft: number, offsetTop: number;
    this._domIo.measure(() => {
      ({offsetLeft, offsetTop} = this._elementRef.nativeElement);
    });

    this._domIo.mutate(() => {
      if (this.scrollTopMargin) {
        const margin = Decimal.isDecimal(this.scrollTopMargin)
          ? +DecimalUtils.toFixed(this.scrollTopMargin)
          : this.scrollTopMargin;
        this._windowRef.window.scrollTo(offsetLeft, offsetTop - margin);
      } else {
        (this._elementRef.nativeElement as Element).scrollIntoView();
      }
    });
  }
}
