import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  Optional,
  SkipSelf,
} from '@angular/core';

/**
 * Button Group component
 *
 * @ngModule ButtonsModule
 */
@Component({
  selector: 'maia-button-group',
  template: '<ng-content select="button"></ng-content>',
  styleUrls: ['./button-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonGroupComponent {
  private _vertical = false;
  public constructor(@Optional() @SkipSelf() parent?: ButtonGroupComponent) {
    // It should not allow to nest component
    if (parent != null) {
      throw new Error('Cannot nest more than one <maia-button-group />s');
    }
  }

  @Input()
  @HostBinding('class.p-maia-button-group--vertical')
  @HostBinding('class.maia-button-group--vertical')
  public get vertical(): boolean {
    return this._vertical;
  }

  public set vertical(vertical: boolean) {
    this._vertical = coerceBooleanProperty(vertical);
  }
}
