import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';
import {FormsCaptureModule} from '@maia/forms/capture';
import {RichTextModule} from '@maia/rich-text';
import {OptionModule} from '@maia/select';

import {CustomOptionComponent} from './custom-option/custom-option.component';
import {EmptyOptionComponent} from './empty-option/empty-option.component';
import {OptionHighlightComponent} from './option/option-highlight.component';
import {OptionDirective} from './option/option.directive';

export const EXPORTED_DECLARATIONS = [
  CustomOptionComponent,
  EmptyOptionComponent,
  OptionDirective,
  OptionHighlightComponent,
];

/**
 * Internal module that contains helper components and directives to be used in autocomplete
 * components. This module is meant to be exported from the autocomplete modules, exposing its
 * directives to consumers of the autocomplete module.
 */
@NgModule({
  imports: [BusinessTypesModule, CommonModule, OptionModule, RichTextModule],
  declarations: [EXPORTED_DECLARATIONS],
  exports: [FormsCaptureModule, OptionModule, EXPORTED_DECLARATIONS],
})
export class InputAutocompleteExportedPartsModule {}
