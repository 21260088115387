import {Highlightable} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
} from '@angular/core';
import {Text} from '@atlas/businesstypes';
import {Utilities} from '@maia/core';

import {SelectGroupComponent} from '../select-group/select-group.component';

const generateId = Utilities.createIdGenerator('maia-select-tile');
const generateLabelId = Utilities.createIdGenerator('maia-select-tile-label');

/**
 * Select tile component
 *
 * @ngModule SelectTileModule
 */
@Component({
  selector: 'maia-select-tile',
  templateUrl: './select-tile.component.html',
  styleUrls: ['./select-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    role: 'radio',
    '[attr.aria-disabled]': 'disabled ? "true": null',
    '[attr.aria-selected]': 'selected ? "true": null',
  },
})
export class SelectTileComponent<T> implements Highlightable {
  private _disabled = false;

  @HostBinding('class.maia-select-tile--focused')
  public focused: boolean;

  @HostBinding('attr.aria-labelledby')
  public readonly labelId = generateLabelId();

  @Input()
  @HostBinding('attr.id')
  public id = generateId();

  private _selected = false;

  /**
   * The tile sub label.
   */
  @Input()
  public subLabel: Text | string | null;

  /**
   * The tile value.
   */
  @Input()
  public value: T;

  public constructor(
    @Inject(forwardRef(() => SelectGroupComponent))
    private readonly _selectGroup: SelectGroupComponent<T>,
    private readonly _changeDetector: ChangeDetectorRef,
  ) {}

  /**
   * The tile disabled.
   */
  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }

  public set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
  }

  public get selected(): boolean {
    return this._selected;
  }

  public set selected(selected: boolean) {
    this._selected = selected;
    this._changeDetector.markForCheck();
  }

  @HostListener('click')
  public select(): void {
    if (!this.disabled) {
      this._selectGroup.select(this);
      this.selected = true;
    }
  }

  public setActiveStyles(): void {
    this.focused = true;
    this._changeDetector.markForCheck();
  }

  public setInactiveStyles(): void {
    this.focused = false;
    this._changeDetector.markForCheck();
  }
}
