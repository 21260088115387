import {Component} from '@angular/core';

/**
 * FlexGridCellComponent - Creates a flex cell
 *
 * @ngModule FlexGridModule
 */
@Component({
  selector: 'maia-flex-grid-cell',
  templateUrl: './flex-grid-cell.component.html',
  styleUrls: ['./flex-grid-cell.component.scss'],
})
export class FlexGridCellComponent {}
