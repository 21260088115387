import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {
  FormsModule as NgFormsModule,
  ReactiveFormsModule as NgReactiveFormsModule,
} from '@angular/forms';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';
import {DropdownsModule} from '@maia/dropdowns';
import {FormsModule as MaiaFormsModule} from '@maia/forms';
import {μDatepickersSvgModule} from '@maia/icons';
import {InputMasksModule} from '@maia/input-masks';
import {RangesModule} from '@maia/ranges';

import {DisableWeekendDirective} from './disable-weekend/disable-weekend.directive';
import {InputDateComponent} from './inputs/input-date/input-date.component';
import {InputDaterangeComponent} from './inputs/input-daterange/input-daterange.component';
import {InputDateWithControlDirective} from './inputs/input-date/input-date-with-control.directive';
import {InputMonthWithControlDirective} from './inputs/input-month/input-month-with-control.directive';
import {InputMonthComponent} from './inputs/input-month/input-month.component';
import {InputYearComponent} from './inputs/input-year/input-year.component';
import {DaterangeActionButtonsComponent} from './parts/daterange-action-buttons/daterange-action-buttons.component';
import {DaypickerComponent} from './parts/daypicker/daypicker.component';
import {HeaderComponent} from './parts/header/header.component';
import {MonthpickerComponent} from './parts/monthpicker/monthpicker.component';
import {RangepickerComponent} from './parts/rangepicker/rangepicker.component';
import {YearpickerComponent} from './parts/yearpicker/yearpicker.component';
import {DefaultYearRange} from './parts/yearpicker/yearpicker.util';
import {DatepickerComponent} from './pickers/datepicker/datepicker.component';
import {DaterangepickerComponent} from './pickers/daterangepicker/daterangepicker.component';
import {YearmonthpickerComponent} from './pickers/yearmonthpicker/yearmonthpicker.component';

@NgModule({
  imports: [
    BusinessTypesModule,
    CommonModule,
    RangesModule,
    μDatepickersSvgModule,
    DropdownsModule,
    InputMasksModule,
    MaiaFormsModule,
    NgFormsModule,
    NgReactiveFormsModule,
  ],
  declarations: [
    DatepickerComponent,
    DaterangepickerComponent,
    DaterangeActionButtonsComponent,
    DaypickerComponent,
    DisableWeekendDirective,
    HeaderComponent,
    InputDateComponent,
    InputDaterangeComponent,
    InputDateWithControlDirective,
    InputMonthComponent,
    InputMonthWithControlDirective,
    InputYearComponent,
    MonthpickerComponent,
    RangepickerComponent,
    YearmonthpickerComponent,
    YearpickerComponent,
  ],
  exports: [
    DatepickerComponent,
    DaterangepickerComponent,
    DaterangeActionButtonsComponent,
    DaypickerComponent,
    DisableWeekendDirective,
    InputDaterangeComponent,
    InputDateComponent,
    InputDateWithControlDirective,
    InputMonthComponent,
    InputMonthWithControlDirective,
    InputYearComponent,
    MonthpickerComponent,
    RangepickerComponent,
    YearmonthpickerComponent,
    YearpickerComponent,
  ],
})
export class DatePickersModule {
  public static withDefaultYearRanges(
    defaults: DefaultYearRange,
  ): ModuleWithProviders<DatePickersModule> {
    return {
      ngModule: DatePickersModule,
      providers: [{provide: DefaultYearRange, useValue: defaults}],
    };
  }
}
