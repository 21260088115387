import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import {Reference} from '../file-uploader/file-uploader.call.factory';

@Injectable()
/**
 * serves as a bridge service between FileUploaderService
 * and FileUploaderTileComponent, exporting behaviour from FileUploaderService
 *
 * @see {@link FileUploaderService}
 * @see {@link FileUploaderTileComponent}
 */
export class FileUploaderProgressService {
  private readonly _onProgress$ = new BehaviorSubject<Reference[]>([]);
  public readonly onProgress$: Observable<Reference[]> = this._onProgress$.asObservable();

  private readonly _completed = new BehaviorSubject(false);
  public readonly completed$: Observable<boolean> = this._completed.asObservable();

  /**
   * signals the completion status of the upload
   * @param completed
   */
  public setCompletionStatus(completed: boolean) {
    this._completed.next(completed);
  }

  /**
   * signals the progress of the upload
   * @param references
   */
  public setProgress(references: Reference[]) {
    this._onProgress$.next(references.slice());
  }
}
