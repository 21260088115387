import {BusinessType, createStringifier} from '../base/businesstype';

import {isValidCardNumber} from './validator-base';

const stringifier = createStringifier('cardNumber');

/**
 * BusinessType wrapper around a card number.
 */
export class CardNumber extends BusinessType<string> {
  /**
   * Returns whether or not `value` is a CardNumber.
   */
  public static isCardNumber(value: unknown): value is CardNumber {
    return value instanceof CardNumber;
  }

  /**
   * Constructs a new CardNumber instance.
   *
   * @param value The card number.
   * @param encryptedValue The encrypted value, if any
   */
  public constructor(value: string, encryptedValue?: string) {
    if (typeof value !== 'string') {
      throw new Error(
        `Expected a string but got ${(typeof value === 'object' &&
          (value as object).constructor.name) ||
          typeof value}`,
      );
    } else if (!isValidCardNumber(value)) {
      throw new Error(`Cannot create CardNumber from invalid input ${JSON.stringify(value)}`);
    }

    super(value, encryptedValue);
  }

  /**
   * Returns whether `other` is a `CardNumber` representing the same value.
   */
  public equals(other: unknown): boolean {
    if (!other) {
      return false;
    }

    if (other === this) {
      return true;
    }

    return CardNumber.isCardNumber(other) && this.internalValue === other.internalValue;
  }

  /**
   * Exposes the value of this BusinessType as a string.
   */
  public asString(): string {
    return this.internalValue;
  }

  public toString(): string {
    return stringifier(this);
  }
}
