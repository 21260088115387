import {Injectable, OnDestroy, TemplateRef} from '@angular/core';
import {NgControl} from '@angular/forms';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';

import {EMPTY, Observable, ReplaySubject} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

import {InputContainer} from '../input-container/input-container.interface';

@Injectable()
@UntilDestroy()
export class MultipleInputContainer implements InputContainer, OnDestroy {
  public readonly validationErrorChange$: Observable<void> = EMPTY;

  public readonly errorString?: string = undefined;

  public readonly errorTemplate?: TemplateRef<any> = undefined;

  private _children: InputContainer[] = [];

  private _disabled = false;

  private readonly _disabled$ = new ReplaySubject<boolean>(1);

  private _focused = false;

  private readonly _focused$ = new ReplaySubject<boolean>(1);

  private _isOptional = true;

  private readonly _optional$ = new ReplaySubject<boolean>(1);

  public get disabled(): boolean {
    return this._disabled;
  }

  public get disabled$(): Observable<boolean> {
    return this._disabled$.asObservable();
  }

  public get focused(): boolean {
    return this._focused;
  }

  public get focused$(): Observable<boolean> {
    return this._focused$.asObservable().pipe(distinctUntilChanged());
  }

  public get optional(): boolean {
    return this._isOptional;
  }

  public get optional$(): Observable<boolean> {
    return this._optional$.asObservable().pipe(distinctUntilChanged());
  }

  public hasError(): boolean {
    return false;
  }

  public registerFormControl(control: NgControl): never {
    throw new Error(
      `Cannot register a form control directly in a multi form element, did you forget to include a maia-form-subelement?`,
    );
  }

  public registerChildContainer(container: InputContainer): () => void {
    this._children.push(container);

    this._updateDisabled();
    this._updateFocused();
    this._updateOptional();
    const subscriptionOnDisabled = container.disabled$
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => this._updateDisabled());
    const subscriptionOnFocused = container.focused$
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => this._updateFocused());
    const subscriptionOnOptional = container.optional$
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => this._updateOptional());

    let reset = false;
    // return de-registration function
    return () => {
      if (reset) {
        return;
      }
      reset = true;

      subscriptionOnDisabled.unsubscribe();
      subscriptionOnFocused.unsubscribe();
      subscriptionOnOptional.unsubscribe();

      const idx = this._children.indexOf(container);
      if (idx !== -1) {
        this._children.splice(idx, 1);
        this._updateDisabled();
        this._updateFocused();
        this._updateOptional();
      }
    };
  }

  private _updateDisabled(): void {
    const disabled = this._children.length > 0 && this._children.every(child => child.disabled);

    if (disabled !== this._disabled) {
      this._disabled = disabled;
      this._disabled$.next(disabled);
    }
  }

  private _updateFocused(): void {
    const focused = this._children.length > 0 && this._children.some(child => child.focused);

    if (focused !== this._focused) {
      this._focused = focused;
      this._focused$.next(focused);
    }
  }

  private _updateOptional(): void {
    // it will be optional as long as all children are optional or there are no children at all
    const optional = this._children.every(child => child.optional);

    if (optional !== this._isOptional) {
      this._isOptional = optional;
      this._optional$.next(optional);
    }
  }

  public ngOnDestroy() {
    this._disabled$.complete();
    this._optional$.complete();
    this._focused$.complete();
    this._children.length = 0;
  }
}
