import {DOWN_ARROW, LEFT_ARROW, RIGHT_ARROW, SPACE, UP_ARROW} from '@angular/cdk/keycodes';
import {HostBinding, HostListener, OnDestroy, QueryList, Directive} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {ReplaySubject} from 'rxjs';

import {CommonButton} from './common-button.interface';
import {allDisabled, getIndex, isKeySupported} from './utils';

@Directive()
export abstract class GroupComponent implements ControlValueAccessor, OnDestroy {
  protected abstract queryList: QueryList<CommonButton>;
  public selectedValue$ = new ReplaySubject(1);
  private _selectedValueIndex = 0;

  @HostBinding('attr.aria-activedescendant')
  public activeDescendant: string;

  @HostListener('keydown', ['$event'])
  public keyEvent(event: KeyboardEvent): void {
    if (isKeySupported(event.keyCode) && !allDisabled(this.queryList.toArray())) {
      this.manageButtonSelection(event.keyCode);
      event.preventDefault();
      event.stopPropagation();
    }
  }

  public set selectedValue(selectedValue: any) {
    this.writeValue(selectedValue);
    this.onChange(selectedValue);
  }

  private manageButtonSelection(keyCode: number) {
    if (keyCode === RIGHT_ARROW || keyCode === DOWN_ARROW) {
      this._selectedValueIndex = (this._selectedValueIndex + 1) % this.queryList.length;
    } else if (keyCode === LEFT_ARROW || keyCode === UP_ARROW) {
      this._selectedValueIndex =
        (this._selectedValueIndex + this.queryList.length - 1) % this.queryList.length;
    }

    if (!this.queryList.toArray()[this._selectedValueIndex].disabled) {
      this.selectedValue = this.queryList.toArray()[this._selectedValueIndex].value;
    } else {
      // In case keyCode is space, we need to change it to select the next one and avoid infinite
      // loop
      keyCode = keyCode === SPACE ? DOWN_ARROW : keyCode;
      this.manageButtonSelection(keyCode);
    }
  }

  public onTouched = () => {};
  public onChange = (_: any) => {};

  public writeValue(obj: any): void {
    this.selectedValue$.next(obj);
    this._selectedValueIndex = this.queryList ? getIndex(this.queryList.toArray(), obj) : 0;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public ngOnDestroy() {
    this.selectedValue$.complete();
  }
}
