/**
 * A call type defines the kind of the call:
 *
 * - READ: the call fetches data
 * - COMMIT: the call stores data (could also fetch)
 */
export enum CallType {
  /**
   * Denotes that the call is a READ call, which only reads data.
   */
  READ,

  /**
   * Marks the call as a COMMIT call, which stores (and possibly
   * also reads) data.
   */
  COMMIT,
}

export type CallHeaders = Map<string, string>;

export type CallParameters = Map<string, string>;

/**
 * The CallOptions pass along certain options for calls.
 */
export interface CallOptions {
  /**
   * The call type.
   *
   * This is the only required option.
   */
  callType: CallType;

  /**
   * The timeout for this call, in milliseconds.
   *
   * If this is not set, the connector that executes the call
   * can use a default value. If this value is present, it
   * overrides any values configured in the connector.
   */
  timeout?: number;

  /**
   * Don't log possible errors.
   */
  skipLoggingErrors?: boolean;

  headers?: CallHeaders;

  parameters?: CallParameters;
}
