import {Directive} from '@angular/core';
import {CardNumberFormatter} from '@atlas/businesstypes';

import {ValueFormatter} from '../util/value-formatter.util';

import {MaskedInputComponent} from './masked-input.component';

const BANK_IDENTIFICATION_NUMBER = '6703';

/**
 * This directive configures the masked input for usage with card numbers. It sets up
 * the correct mask and placeholder. Together with atlas's `[atlasCardNumber]` directive
 * this makes the masked input create CardNumber businesstypes.
 *
 * @ngModule InputMasksModule
 */
@Directive({
  selector: 'maia-masked-input[atlasCardNumber]',
})
export class MaskedCardNumberInputDirective implements ValueFormatter {
  /**
   * Doesn't format anything, formatting is done by the `[atlasCardNumber]` directive.
   */
  public formatValue(value: string): string {
    return value;
  }

  /**
   * Unformats any formatting left in place by the `[atlasCardNumber]` directive.
   */
  public unformatValue(value: string): string {
    return CardNumberFormatter.unformat(value);
  }

  public fromRawValue(rawValue: string): string {
    return `${BANK_IDENTIFICATION_NUMBER}${rawValue}`;
  }

  public toRawValue(processedValue: string): string {
    return processedValue.substr(BANK_IDENTIFICATION_NUMBER.length);
  }

  public constructor(input: MaskedInputComponent) {
    input.setMaskAndPlaceholder(`${BANK_IDENTIFICATION_NUMBER} #### #### #### #`);
    input.setValueFormatter(this);
    input.setValueProcessor(this);
  }
}
