import {bigConstructor} from '../base/big-utils';
import {BusinessType, createStringifier} from '../base/businesstype';

const stringifier = createStringifier('decimal');

/**
 * BusinessType wrapper around decimals, exposing the decimal value as an big.js object.
 */
export class Decimal extends BusinessType<BigJs.Big> {
  /**
   * Returns whether `value` is a Decimal or not.
   */
  public static isDecimal(value: unknown): value is Decimal {
    return value instanceof Decimal;
  }

  /**
   * Don't use this directly, use `toJsonValue` instead
   */
  public static overrideToJsonValue(value: Decimal): string {
    return value.internalValue.toPrecision();
  }

  /**
   * Creates a new Decimal instance.
   *
   * @param value The decimal, either a big.js object or a valid string
   * @param encryptedValue The encrypted value, if any
   */
  public constructor(value: string, encryptedValue?: string) {
    let internalValue: BigJs.Big | undefined = undefined;

    // We don't allow Big.Js instances using typescript because this constructor is part of the
    // public API but the helper friendly function wrapBig does allow creating Decimals from big
    // instances
    if ((value as any) instanceof bigConstructor || typeof value === 'string') {
      try {
        internalValue = bigConstructor(value);
      } catch {
        // thrown later on
      }
    }

    if (internalValue == null) {
      throw new Error(`Cannot create Decimal for invalid input ${JSON.stringify(value)}`);
    }

    super(() => bigConstructor(internalValue!), encryptedValue);
  }

  /**
   * Returns whether `other` is a `Decimal` representing the same decimal.
   */
  public equals(other: unknown): boolean {
    if (!other) {
      return false;
    }

    if (other === this) {
      return true;
    }

    return Decimal.isDecimal(other) && this.internalValue.eq(other.internalValue);
  }

  public toString(): string {
    return stringifier(this, this.internalValue.toString());
  }
}

/**
 * Creates a Decimal with the value of the given big.js instance
 *
 * @param value The big.js instance to wrap in a decimal
 */
export function wrapBig(value: BigJs.Big): Decimal {
  return new Decimal(value as any);
}
