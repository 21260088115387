import {Component, HostBinding, Input} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

/**
 * @ngModule LayoutModule
 *
 * This is a layout component with 2 children.
 * The first child is fluid and will adjust width on available space.
 * The second child is fixed and should have a fixed width or child with fixed width.
 * The two sections can either be top or center aligned.(default top aligned)
 *
 * The exact width of the left section and the margin between the sections depend on the content and styling.
* ```
<maia-fluid-fixed-layout alignContentCenter>
  <div> ... </div>
  <div> ... </div>
</maia-fluid-fixed-layout>
```
 */
@Component({
  selector: 'maia-fluid-fixed-layout',
  template: '<ng-content></ng-content>',
  styleUrls: ['./fluid-fixed-layout.component.scss'],
})
export class FluidFixedLayoutComponent {
  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('class.maia-fluid-fluid-layout--align-top')
  public alignContentTop = true;

  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('class.maia-fluid-fluid-layout--align-center')
  public alignContentCenter = false;
}
