import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionFuncFullscreenUndoSvgModule
 */
@Component({
  selector: 'svg[ivyHermesActionFuncFullscreenUndo]',
  template: '<svg:g class="nc-icon-wrapper"><svg:path class="st0" d="M8.4 2.96V8.4H2.96" fill="none" stroke-width="1.04" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M21.04 8.4H15.6V2.96" fill="none" stroke-width="1.04" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M15.6 21.04V15.6h5.44" fill="none" stroke-width="1.04" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M2.96 15.6H8.4v5.44" fill="none" stroke-width="1.04" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 24 24',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionFuncFullscreenUndoSvgComponent {
}

@NgModule({
  declarations: [
    ActionFuncFullscreenUndoSvgComponent,
  ],
  exports: [
    ActionFuncFullscreenUndoSvgComponent,
  ],
})
export class ActionFuncFullscreenUndoSvgModule {}
