import {Injectable, TemplateRef} from '@angular/core';

import {Observable, ReplaySubject} from 'rxjs';

/**
 * Storage container for the header of a full view overlay.
 */
@Injectable()
export class HeaderContainer {
  private _header?: TemplateRef<any> = undefined;

  private readonly _header$ = new ReplaySubject<TemplateRef<any> | undefined>(1);

  /**
   * Sets the header. Note that setting a header is not allowed if there's already a header.
   */
  public setHeader(header: TemplateRef<any> | undefined): void {
    if (header != null && this._header != null) {
      throw new Error('Do you have multiple *maiaFullViewOverlayHeader directives?');
    }

    this._header = header;
    this._header$.next(header);
  }

  /**
   * Observable that emits the header for the full view overlay. This observable immediately
   * emits the header if any header has already been set.
   */
  public get header$(): Observable<TemplateRef<any> | undefined> {
    return this._header$.asObservable();
  }
}
