import {FocusOrigin, ListKeyManager, ListKeyManagerOption} from '@angular/cdk/a11y';
import {QueryList} from '@angular/core';

/**
 * Options that can be used in the `SelectListKeyManager`
 */
export interface Highlightable extends ListKeyManagerOption {
  setActiveStyles(focusOrigin: FocusOrigin): void;
  setInactiveStyles(focusOrigin: FocusOrigin): void;
}

/**
 * List key manager that tracks the active item using `aria-activedescendant`
 *
 * This class is a drop-in replacement of the `ActiveDescendantKeyManager` in `@angular/cdk`. It
 * keeps track of the origin of the activation of the items, allowing individual options to behave
 * differently depending on the source of the activation, e.g. options could scroll into view if
 * they're activated by the keyboard, but not when activated by hover.
 */
export class SelectListKeyManager<T extends Highlightable> extends ListKeyManager<T> {
  /**
   * The options of this manager
   */
  public readonly options: QueryList<T>;

  public constructor(options: QueryList<T>) {
    super(options);

    this.options = options;
  }

  /**
   * Sets the active item to the item at the specified index and adds the
   * active styles to the newly active item. Also removes active styles
   * from the previously active item.
   * @param index Index of the item to be set as active.
   */
  public setActiveItem(index: number, focusOrigin?: FocusOrigin): void;

  /**
   * Sets the active item to the item to the specified one and adds the
   * active styles to the it. Also removes active styles from the
   * previously active item.
   * @param item Item to be set as active.
   */
  public setActiveItem(item: T, focusOrigin?: FocusOrigin): void;

  public setActiveItem(index: any, focusOrigin: FocusOrigin = 'keyboard'): void {
    if (this.activeItem) {
      this.activeItem.setInactiveStyles(focusOrigin);
    }
    super.setActiveItem(index);
    if (this.activeItem) {
      this.activeItem.setActiveStyles(focusOrigin);
    }
  }
}
