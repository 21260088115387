import {Directive, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';

import {asapScheduler, BehaviorSubject, Observable, OperatorFunction} from 'rxjs';
import {distinctUntilChanged, filter, map, observeOn, startWith} from 'rxjs/operators';

// explicit function is useful for the type definition, which would only clobber the operator
// pipeline
function isDefined<T>(value?: T | null | undefined): value is T {
  return value != null;
}

/**
 * Find the first active leaf route
 */
function mapToLeafRouteSnapshot(
  activatedRoute: ActivatedRoute,
): OperatorFunction<any, ActivatedRouteSnapshot> {
  return map(() => {
    let route = activatedRoute.snapshot;

    while (route.firstChild != null) {
      route = route.firstChild;
    }

    return route;
  });
}

/**
 * Find the maiaFlowProgressId, looking up the route tree until a value is found
 */
function mapToMaiaFlowProgressId(): OperatorFunction<ActivatedRouteSnapshot, string | undefined> {
  return map(route => {
    while (route.parent != null) {
      if (route.data.maiaFlowProgressId != null) {
        return route.data.maiaFlowProgressId as string;
      }

      route = route.parent;
    }

    return undefined;
  });
}

/**
 * Extension on the flow-progress that exposes the active route id for individual steps to listen to
 *
 * @ngModule FlowProgressWithRoutingModule
 */
@Directive({
  selector: 'maia-flow-progress',
})
@UntilDestroy()
export class StepContainerDirective implements OnInit {
  private readonly _activeRouteId = new BehaviorSubject<string>('');

  /**
   * Observable that emits the `maiaFlowProgressId` linked to the active route
   *
   * The observable doesn't emit if the current route doesn't have a `maiaFlowProgressId` set, only
   * when a value is found.
   */
  public readonly activeRouteId: Observable<string>;

  public constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router,
  ) {
    // Make the observable asynchronous.
    //
    // These events are fired as part of the Angular router pipeline, and randomly triggering change
    // detection within this pipeline tends to result in random breaking things. Observed breakage:
    // animations based on :enter or :leave no longer work if this pipeline is synchronous.
    //
    // The cached value (this is a behaviour subject!) must also emit on the next tick, because the
    // routeId directive is potentially being instantiated by a directive like *ngIf. In this case
    // the directive starts listening before the @ContentChildren of the container element (flow
    // progress or multi-step) has been updated, leading to weird behaviour because our algorithms
    // for updating the step states in these containers assume the active step is part of that
    // @ContentChildren QueryList.
    this.activeRouteId = this._activeRouteId.pipe(observeOn(asapScheduler));
  }

  public ngOnInit(): void {
    this._router.events
      .pipe(
        // Ensure proper cleanup when this component is destroyed
        takeUntilDestroyed(this),

        // Perform an action at the end of navigation
        filter(event => event instanceof NavigationEnd),
        // NavigationEnd only triggers on subsequent navigations. The user already performed an
        // initial navigation, so emit one value already
        startWith(null),

        // Find the leaf activated route snapshot
        mapToLeafRouteSnapshot(this._activatedRoute),
        // Traverse the route hierarchy looking for the closest route with
        // data.maiaFlowProgressId set to a value
        mapToMaiaFlowProgressId(),

        // Limit the stream to routes where we actually find a value
        // This implies that the previous route stays active in the flow-progress if we move to
        // a route that doesn't have a `maiaFlowProgressId` in its data.
        filter(isDefined),

        // Don't emit for identical values, e.g. when moving to a subroute when the parent has
        // the `maiaFlowProgressId` set.
        distinctUntilChanged(),
      )
      .subscribe(this._activeRouteId);
  }
}
