import {NgModule} from '@angular/core';
import {LoadingOverlayModule} from '@maia/loading-overlay';

import {DeeplinkingService} from './deeplinking.service';

@NgModule({
  imports: [LoadingOverlayModule],
  providers: [DeeplinkingService],
})
export class εDeeplinkingModule {}
