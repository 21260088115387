import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {END, ENTER, ESCAPE, HOME} from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import {WindowRef} from '@atlas-angular/cdk/globals';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {ModalControl} from '@maia/modals';
import {EMPTY, Observable} from 'rxjs';
import {map, pairwise, startWith} from 'rxjs/operators';

import {SelectListKeyManager} from '../list-key-manager';
import {Option} from '../option/option';

/**
 * The dropdown element used inside of a `<maia-select />`
 *
 * This element serves as listbox in a collapsible listbox (see WAI ARIA guidelines for collapsible
 * listbox). It uses `aria-activedescendant` to correctly present the active option to screenreader
 * users.
 *
 * @see [WAI ARIA collapsible listbox](https://www.w3.org/TR/wai-aria-practices-1.1/examples/listbox/listbox-collapsible.html)
 * @ngModule SelectPartsModule
 */
@Component({
  selector: 'maia-select-options',
  template: '<ng-content></ng-content>',
  styleUrls: ['./select-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    tabindex: '-1',
    role: 'listbox',
    // TODO: aria-labelled-by
  },
})
@UntilDestroy()
export class SelectOptionsComponent<T> implements AfterViewInit {
  @HostBinding('attr.aria-activedescendant')
  public activeDescendantId: string | null = null;

  @Input()
  public control: ModalControl<Option<T> | null>;

  @Input()
  public keyManager: SelectListKeyManager<Option<T>>;

  private _captureFocus = true;

  @Output()
  public readonly keyNavigationTriggered = new EventEmitter<void>();

  @Input()
  public keyboardEvents: Observable<KeyboardEvent> = EMPTY;

  public constructor(
    private readonly _elementRef: ElementRef,
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly _window: WindowRef,
  ) {}

  @Input()
  public set captureFocus(captureFocus: boolean) {
    this._captureFocus = coerceBooleanProperty(captureFocus);
  }

  public ngAfterViewInit(): void {
    const {options} = this.keyManager;

    options.changes
      .pipe(
        takeUntilDestroyed(this),
        map(() => options.toArray()),
        startWith([], options.toArray()),
        pairwise(),
      )
      .subscribe(([oldOptions, newOptions]) => {
        for (const option of oldOptions) {
          option.control = undefined!;
        }

        for (const option of newOptions) {
          option.control = this.control;
        }
      });

    this.keyManager.change
      .pipe(
        map(() => this.keyManager.activeItem),
        takeUntilDestroyed(this),
      )
      .subscribe(option => {
        this.activeDescendantId = option != null ? option.id : null;
        this._changeDetector.markForCheck();
      });

    this.keyManager.tabOut.pipe(takeUntilDestroyed(this)).subscribe(() => this.control.cancel());

    this.keyboardEvents.pipe(takeUntilDestroyed(this)).subscribe(event => this.onKeydown(event));

    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (this._captureFocus) {
      // istanbul ignore else: hard to test as ElementRef is a special token for the injector
      if (this._elementRef.nativeElement != null) {
        // The instantiation of this element is likely happening while opening a dropdown. Dropdowns
        // are positioned at the top of the document before they get their proper position. A
        // browser that shan't be named but uses a fiery fox as logo always scrolls to elements that
        // gain focus. Wait with the focussing until _after_ the first frame, because by then the
        // dropdown has been positioned correctly
        this._window.window.requestAnimationFrame(() => {
          (this._elementRef.nativeElement as HTMLElement).focus();
        });
      }
    }
  }

  @HostListener('keydown', ['$event'])
  public onKeydown(event: KeyboardEvent): void {
    this.keyNavigationTriggered.next();

    if (event.keyCode === ESCAPE) {
      this.control.cancel();
      event.preventDefault();
    } else if (event.keyCode === ENTER) {
      this.control.confirm(this.keyManager.activeItem);
      event.preventDefault();
    } else if (event.keyCode === HOME) {
      this.keyManager.setFirstItemActive();
      event.preventDefault();
    } else if (event.keyCode === END) {
      this.keyManager.setLastItemActive();
      event.preventDefault();
    } else {
      this.keyManager.onKeydown(event);
    }
  }
}
