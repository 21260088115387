import {ErrorHandler, Injectable} from '@angular/core';
import {Logger} from '@atlas/logger';

import {LoggerFactory} from './logger-factory.service';

/**
 * Logs errors thrown by Angular via @atlas/logger.
 */
@Injectable()
export class LoggerErrorHandler implements ErrorHandler {
  private readonly logger: Logger;

  public constructor(loggerFactory: LoggerFactory) {
    this.logger = loggerFactory.createLogger('LoggerErrorHandler');
  }

  public handleError(error: any): void {
    this.logger.error(error);
  }
}
