import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SignerScanSvgModule
 */
@Component({
  selector: 'svg[ivyHermesSignerScan]',
  template: '<svg:path d="M475.4 125.4L70 150.5c-22.5 1-44.5 21.5-47.6 44L.4 348.1c-3.1 23 13.3 41 36.3 38.4l405.9-35.8c23-2.1 44.5-22.6 48.1-45.6l21-140.8c2.6-22.5-13.3-40.4-36.3-38.9zM165 291.2c-5.2 4-12.2 6-21.1 6-5.9 0-11.3-1.1-16.2-3.3-4.9-2.2-8.7-5.2-11.5-9-2.7-3.8-4.1-8.3-4.1-13.4h14.1c0 4.6 1.5 8.2 4.6 10.7 3 2.5 7.4 3.8 13.1 3.8 4.9 0 8.6-1 11.1-3s3.7-4.6 3.7-7.9c0-3.6-1.3-6.3-3.8-8.2-2.5-1.9-7-3.9-13.6-5.8-6.5-2-11.7-4.2-15.6-6.6-7.4-4.6-11.1-10.7-11.1-18.2 0-6.5 2.7-11.9 8-16.2 5.3-4.2 12.2-6.3 20.7-6.3 5.6 0 10.7 1 15.1 3.1s7.9 5 10.4 8.9c2.5 3.8 3.8 8.1 3.8 12.8h-14c0-4.2-1.3-7.5-4-9.9-2.7-2.4-6.5-3.6-11.4-3.6-4.6 0-8.2 1-10.7 2.9-2.5 2-3.8 4.7-3.8 8.2 0 3 1.4 5.4 4.1 7.4 2.7 2 7.3 3.9 13.6 5.8s11.4 4 15.2 6.4c3.8 2.4 6.6 5.1 8.4 8.2 1.8 3.1 2.7 6.7 2.7 10.9.1 6.9-2.5 12.3-7.7 16.3zm36.4-12.3c3.1 4.6 7.7 6.9 13.8 6.9 5.5 0 9.7-1.2 12.5-3.7 2.8-2.5 4.5-6.6 5.3-12.4h14c-.8 8.7-4 15.4-9.6 20.2-5.6 4.8-13 7.3-22.2 7.3-6.5 0-12.2-1.5-17.1-4.6-4.9-3.1-8.7-7.4-11.4-13.1-2.7-5.6-4.1-12.2-4.2-19.7v-7.6c0-7.6 1.4-14.4 4.1-20.2 2.7-5.8 6.6-10.3 11.7-13.5 5.1-3.2 10.9-4.7 17.6-4.7 8.9 0 16.2 2.4 21.6 7.3 5.5 4.8 8.6 11.7 9.5 20.6h-14c-.7-5.8-2.4-10-5.1-12.6-2.7-2.6-6.7-3.9-12-3.9-6.1 0-10.8 2.2-14.1 6.7s-5 11-5 19.7v7.2c-.1 8.8 1.5 15.5 4.6 20.1zm110.2 17.2l-6.6-18.9h-31.4l-6.6 18.9h-14.6L283 215h12.6l30.7 81.1h-14.7zm88.2 0h-14.1l-36.1-57.5v57.5h-14.1V215h14.1l36.3 57.8V215h14v81.1z"></svg:path><svg:path d="M277.5 265.8H301l-11.7-33.6z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 512 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignerScanSvgComponent {
}

@NgModule({
  declarations: [
    SignerScanSvgComponent,
  ],
  exports: [
    SignerScanSvgComponent,
  ],
})
export class SignerScanSvgModule {}
