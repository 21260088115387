import {Injectable} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';
import {Logger} from '@atlas/logger';
import {Keepsake, StorageFactory} from 'keepsake';

import {StorageConfiguration} from './storage.configuration';

export interface User {
  current?: boolean;
  lastUsed?: number;
  id: string;
}

@Injectable()
export class UserStorageService<T extends User = User> {
  private _keepsake: Promise<Keepsake<T>>;

  private get keepsake() {
    if (this._keepsake == null) {
      this._keepsake = this.storageFactory.createStorage<T>(
        `${this.configuration.prefix}Users`,
        `${this.configuration.prefix}Users.db`,
      );
    }
    return this._keepsake;
  }

  private readonly logger: Logger;

  public constructor(
    private readonly storageFactory: StorageFactory,
    private readonly configuration: StorageConfiguration,
    loggerFactory: LoggerFactory,
  ) {
    this.logger = loggerFactory.createLogger('UserStorageService');
  }

  public async users(): Promise<T[]> {
    return (await (await this.keepsake).list()).map(([, value]) => value);
  }

  public async clearUsers(): Promise<void> {
    return (await this.keepsake).purge();
  }

  public async createUser(key: string, value: T): Promise<void> {
    await this.setCurrentToStoredValueOrFalse(key, value);
    return this.saveOrUpdateUser(key, value);
  }

  public async updateUser(key: string, value: T): Promise<void> {
    await this.setCurrentToStoredValueOrFalse(key, value);
    return this.saveOrUpdateUser(key, value);
  }

  public async deleteUser(key: string): Promise<void> {
    return (await this.keepsake).delete(key);
  }

  public async getCurrentUser(): Promise<T | undefined> {
    const users = await this.users();
    const currentUsers = users.filter(user => user.current);

    if (currentUsers.length > 1) {
      this.logger.error(new Error('INVALID STATE: More than one current user was found'));

      // Sort the currentUsers array by lastUsed descending
      currentUsers.sort((a, b) => (b.lastUsed ?? 0) - (a.lastUsed ?? 0));
    }

    return currentUsers[0];
  }

  public async setCurrentUser(key: string): Promise<void> {
    const users = await this.users();
    const updateUserPromises = users
      .map(user => Object.assign({}, user, {current: user.id === key}))
      .map(user => this.saveOrUpdateUser(user.id, user));
    await Promise.all(updateUserPromises);
  }

  private async setCurrentToStoredValueOrFalse(key: string, value: T) {
    try {
      const oldUser = await (await this.keepsake).get(key);
      value.current = oldUser.current;
    } catch (e) {
      value.current = false;
    }
  }

  private async saveOrUpdateUser(key: string, value: T) {
    return (await this.keepsake).save(key, value);
  }
}
