import {randomize} from './randomize-string';

/**
 * Randomizes a class string by appending a random string.
 *
 * There is absolutely no guarantee that the returned class string is unique. Do not use this
 * function to generate unique classnames. It should be used to create unpredictable classnames, to
 * prevent outside styles from overriding the class that you are trying to define/use.
 *
 * @param klass The class string to randomize
 */
export function randomizeClass(className: string): string {
  return randomize(className);
}
