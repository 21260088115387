import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  QueryList,
} from '@angular/core';

import {FlowChapter2Component} from './flow-chapter2/flow-chapter2.component';

/**
 * @ngModule FlowChapter2Module
 */
@Component({
  selector: 'maia-flow-chapter-group',
  template: '<ng-content></ng-content>',
  styleUrls: ['./flow-chapter2-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowChapter2GroupComponent implements AfterContentInit {
  @ContentChildren(FlowChapter2Component)
  public flowChapters: QueryList<FlowChapter2Component>;

  public ngAfterContentInit(): void {
    this.flowChapters.forEach((chapter, index) => (chapter.chapterNumber = index + 1));
  }
}
