import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {DEFAULT_INLINE_MESSAGE_TYPE, MessageType} from './inline-message.utils';

/**
 * Inline Message Component
 *
 * @ngModule MessagesModule
 */
@Component({
  selector: 'maia-inline-message',
  templateUrl: './inline-message.component.html',
  styleUrls: ['./inline-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineMessageComponent {
  /**
   * Type of inline message
   */
  @Input('type')
  public messageType: MessageType;

  public constructor() {
    this.messageType = DEFAULT_INLINE_MESSAGE_TYPE;
  }
}
