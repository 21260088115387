/*
 * This file contains the basic file validators
 * to check whether a file is actually a valid file.
 */

import {
  isValid,
  RESULT_VALID,
  ValidationErrors,
  ValidatorInput,
  ValidValidationResult,
} from '../base/validators';

import {File} from './businesstype';

const BASE64_REGEX = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

export interface InvalidBaseFileValidationResult {
  /**
   * If this property is set, the encoded file string is incorrect.
   */
  atlasInvalidEncoding?: boolean;
}

export type BaseFileValidationResult = InvalidBaseFileValidationResult | ValidValidationResult;

/**
 * The BaseFileValidators object contains partial file validators, returning
 * information on the invalidity instead of simply returning a boolean
 * valid/invalid result.
 *
 * @deprecated Use `validateFile` instead
 */
export const BaseFileValidators: {
  /**
   * Checks whether the given file string in the input contains invalid characters.
   *
   * @param value The file string to validate
   */
  readonly invalidEncoding: (value: ValidatorInput<File>) => BaseFileValidationResult;
} = {
  invalidEncoding: doValidate,
};

function doValidate(value: ValidatorInput<File>): BaseFileValidationResult {
  if (File.isFile(value)) {
    return RESULT_VALID;
  }

  if (typeof value === 'string' && validateBase64Encoding(value)) {
    return RESULT_VALID;
  }

  return {
    atlasInvalidEncoding: true,
  };
}

/**
 * Check if the given input matches the Base 64 Regular Expression
 * @param value The file string to validate
 */
function validateBase64Encoding(value: string): boolean {
  return BASE64_REGEX.test(value);
}

/**
 * Checks whether the input file is valid or not.
 *
 * @param input The input file to validate
 */
export function isValidFile(input: ValidatorInput<File>): boolean {
  return isValid(validateFile(input));
}

/**
 * Validate the given file input
 *
 * @param input The file to validate
 */
export function validateFile(
  input: ValidatorInput<File>,
): ValidationErrors | ValidValidationResult {
  return doValidate(input);
}
