import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {βHeadersSvgModule} from '@bifrost/icons';
import {MessagingModule} from '@hermes/messaging';
import {VetoModule} from '@hermes/veto';
import {IntersectionsModule} from '@maia/intersections';
import {LayoutModule} from '@maia/layout';
import {HeaderLayoutModule} from '@maia/header-layout';
import {BadgeModule} from '@maia/badge';

import {HeaderMainSectionComponent} from './components/main-section/header-main-section.component';
import {HeaderBackgroundDirective} from './header-background.directive';
import {HeaderHardwareBackButtonGuard} from './header-hardware-back-button.guard';
import {HeaderWithBackgroundComponent} from './header-with-background.component';
import {HeaderWithFixedBackgroundComponent} from './header-with-fixed-background.component';
import {HeaderComponent} from './header.component';
import {HeaderVariantBackgroundAuthenticatedDirective} from './variants/background-authenticated.directive';
import {HeaderVariantBackgroundPublicDirective} from './variants/background-public.directive';
import {HeaderVariantClassicAuthenticatedDirective} from './variants/classic-authenticated.directive';
import {HeaderVariantClassicPublicDirective} from './variants/classic-public.directive';
import {HeaderVariantOscarAuthenticatedDirective} from './variants/oscar-authenticated.directive';
import {HeaderVariantOscarPublicDirective} from './variants/oscar-public.directive';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderBackgroundDirective,
    HeaderMainSectionComponent,
    HeaderVariantBackgroundAuthenticatedDirective,
    HeaderVariantBackgroundPublicDirective,
    HeaderVariantClassicAuthenticatedDirective,
    HeaderVariantClassicPublicDirective,
    HeaderVariantOscarAuthenticatedDirective,
    HeaderVariantOscarPublicDirective,
    HeaderWithBackgroundComponent,
    HeaderWithFixedBackgroundComponent,
  ],
  imports: [
    BadgeModule,
    CommonModule,
    IntersectionsModule,
    HeaderLayoutModule,
    LayoutModule,
    MessagingModule.forRoot(),
    VetoModule,
    βHeadersSvgModule,
  ],
  exports: [
    HeaderComponent,
    HeaderBackgroundDirective,
    HeaderVariantBackgroundAuthenticatedDirective,
    HeaderVariantBackgroundPublicDirective,
    HeaderVariantClassicAuthenticatedDirective,
    HeaderVariantClassicPublicDirective,
    HeaderVariantOscarAuthenticatedDirective,
    HeaderVariantOscarPublicDirective,
    HeaderWithBackgroundComponent,
    HeaderWithFixedBackgroundComponent,
  ],
  providers: [HeaderHardwareBackButtonGuard],
})
export class HeaderModule {
  /**
   * eagerly instantiating HeaderHardwareBackButtonGuard to set the right state at any moment as
   * this service is listening on NavigationStart router events this can lead to incorrect states
   * when this service is instantiated for the first time after that event happens e.g canDeactivate
   * guard
   *
   * @param headerHardwareBackButtonGuard
   */
  public constructor(headerHardwareBackButtonGuard: HeaderHardwareBackButtonGuard) {}
}
