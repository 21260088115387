import {NgModule} from '@angular/core';

import {OptionComponent} from './option/option.component';

@NgModule({
  imports: [],
  declarations: [OptionComponent],
  exports: [OptionComponent],
})
export class OptionModule {}
