import {NgModule} from '@angular/core';

import {CapturedFakeInputDirective} from './captured-fake-input.directive';
import {CapturedInputDirective} from './captured-input.directive';

/**
 * This module exposes a directive to capture input elements, useful for e.g. input masks and
 * autocompletes that need access to a transcluded input element.
 *
 * Example usage:
 *
 * ```ts
 * @Component({template: '<ng-content></ng-content>'})
 * class InputMaskComponent {
 *   @ContentChild(CapturedInput)
 *   public input: CapturedInput;
 * }
 *
 * @NgModule({
 *   declarations: [InputMaskComponent],
 *   exports: [
 *     FormsCaptureModule,
 *
 *     InputMaskComponent,
 *   ]
 * })
 * export class InputMasksModule {}
 * ```
 */
@NgModule({
  declarations: [CapturedFakeInputDirective, CapturedInputDirective],
  exports: [CapturedFakeInputDirective, CapturedInputDirective],
})
export class FormsCaptureModule {}
