import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  Optional,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Text} from '@atlas/businesstypes';
import {DropdownHost, DropdownPosition, DropdownTemplateContext} from '@maia/dropdowns';
import {FormElementComponent, InputContainer} from '@maia/forms';
import {ModalConfirmedResult, ModalResolution, ModalResult} from '@maia/modals';

import {filter, finalize, map, tap} from 'rxjs/operators';

import {MAXIMUM_DROPDOWN_WIDTH, MINIMUM_DROPDOWN_WIDTH} from '../constants';

/**
 * Unique object meant to signify "no value has recently been set using writeValue"
 */
const NO_WRITTEN_VALUE = {};

/**
 * A year input. This input provides a dropdown where a year can be selected.
 *
 * @ngModule DatePickersModule
 */
@Component({
  selector: 'maia-input-year',
  templateUrl: './input-year.component.html',
  styleUrls: ['./input-year.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputYearComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputYearComponent),
      multi: true,
    },
  ],
})
@UntilDestroy()
export class InputYearComponent implements ControlValueAccessor, AfterViewInit, Validator {
  /**
   * The current value, be it complete or incomplete
   */
  public readonly control: FormControl;

  @ViewChild('dropdownHost', {static: true})
  private _dropdownHost: DropdownHost;

  @ViewChild('dropdownContent', {static: true})
  public dropdownContent: TemplateRef<DropdownTemplateContext<number>>;

  private _minimum?: number;
  private _minimumValidator: ValidatorFn;

  private _maximum?: number;
  private _maximumValidator: ValidatorFn;

  private _recentlyWrittenValue: unknown = NO_WRITTEN_VALUE;

  /**
   * The initial year to open.
   */
  @Input()
  public initialYear?: number;

  private _open = false;

  public _disabled = false;

  // istanbul ignore next: provided by [(ngModel)]
  private _onValidatorChanged = () => {};

  // istanbul ignore next: provided by [(ngModel)]
  public _onTouched = () => {};

  public constructor(
    fb: FormBuilder,
    private readonly _changeDetector: ChangeDetectorRef,
    @Optional() private readonly _inputContainer?: InputContainer,
    @Optional() private readonly _parentFormElement?: FormElementComponent,
  ) {
    this.control = fb.control(undefined);
  }

  /**
   * The last allowed date.
   */
  @Input()
  public get maximum(): number | undefined {
    return this._maximum;
  }

  public set maximum(maximum: number | undefined) {
    this._maximum = maximum;
    this._maximumValidator = maximum != null ? Validators.max(maximum) : Validators.nullValidator;
    this._onValidatorChanged();
  }
  /**
   * The first allowed date.
   */
  @Input()
  public get minimum(): number | undefined {
    return this._minimum;
  }

  public set minimum(minimum: number | undefined) {
    this._minimum = minimum;
    this._minimumValidator = minimum != null ? Validators.min(minimum) : Validators.nullValidator;
    this._onValidatorChanged();
  }

  public get value(): Text {
    return this.control.value ? new Text(this.control.value + '') : new Text('');
  }

  // YearPicker expects a number for input so can't handle the Text businesstype
  public get valueAsNumber(): number {
    return Number.parseInt(this.value.asString(), 10);
  }

  /**
   * Stores values typed into the raw input
   */
  public setRawInputValue(value: number | Text): void {
    if (Text.isText(value)) {
      this.control.setValue(Number.parseInt(value.asString(), 10));
    } else {
      this.control.setValue(value);
    }
  }

  /**
   * Opens a datepicker in a dropdown.
   */
  public openDropdown(): false {
    if (this._disabled || this._open) {
      return false;
    }

    this._open = true;

    this._dropdownHost
      .prepareTemplate(this.dropdownContent, {
        position: DropdownPosition.BOTTOM_ALIGNED,
        alignedDropdownExtra: {
          maximumSize: MAXIMUM_DROPDOWN_WIDTH,
          minimumSize: MINIMUM_DROPDOWN_WIDTH,
          alternativePosition: DropdownPosition.BOTTOM_LEFT,
        },
      })
      .pipe(
        finalize(() => (this._open = false)),
        takeUntilDestroyed(this),
        // call onTouched before filtering on confirmation
        tap(() => this._onTouched()),
        filter<ModalResult<number>, ModalConfirmedResult<number>>(
          (result): result is ModalConfirmedResult<number> =>
            result.resolution === ModalResolution.CONFIRMED,
        ),
        map(result => result.result),
      )
      .subscribe(value => {
        this.setRawInputValue(value);
        this._changeDetector.detectChanges();
      });
    return false;
  }

  public ngAfterViewInit(): void {
    if (this._inputContainer != null) {
      // IMPORTANT: this control cannot be registered to this._inputContainer because that container
      // already has a control registered by the MaskedInputWithControlDirective (the
      // maia-masked-input used in this component's template)

      this._inputContainer.disabled$.subscribe(() => {
        this._changeDetector.detectChanges();
      });
    }
  }

  // ControlValueAccessor

  public writeValue(obj: any): void {
    this._recentlyWrittenValue = obj;
    this.control.setValue(obj);
    this._changeDetector.detectChanges();
  }

  public registerOnChange(fn: any): void {
    // FormControl has a `registerOnChange` function, but that's meant for applying model changes to
    // the view. Its listeners never get called when the view triggers the change to the model. In
    // our case the registered listener doesn't get called when the user types a value in the masked
    // input, which renders the component unusable with a keyboard.
    this.control.valueChanges.pipe(takeUntilDestroyed(this)).subscribe((value: any) => {
      if (this._recentlyWrittenValue !== value) {
        fn(value);
      }

      this._recentlyWrittenValue = NO_WRITTEN_VALUE;
    });
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (this._disabled === isDisabled) {
      return;
    }

    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }

    this._disabled = isDisabled;
    this._changeDetector.detectChanges();
  }

  // Validator API

  public validate(control: AbstractControl): ValidationErrors | null {
    const value: number | null = control && control.value;

    const validatorFn = control.validator;

    // the control will be instance of FormControl only when called by Angular and not by ourselves
    if (validatorFn && control instanceof FormControl) {
      // the validator(s) should be executed against a 'null' value to detect if the 'required'
      // validation is defined
      // the passed control is an simple object (NOT an instance of FormControl)
      const validationErrors = validatorFn({...control, value: null} as AbstractControl);
      const hasRequiredError =
        validationErrors && validationErrors['required'] && validationErrors['required'] === true;
      if (hasRequiredError && this._parentFormElement) {
        this._parentFormElement.hideOptionalIndicator = true;
      }
    }

    // treat empty date input as valid
    if (value == null) {
      return null;
    }

    return this._minimumValidator(control) || this._maximumValidator(control);
  }

  public registerOnValidatorChange(onValidatorChange: () => void): void {
    this._onValidatorChanged = onValidatorChange;
  }
}
