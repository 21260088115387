import {PathIdentifier} from '@atlas/convertor';

export const BIFROST_AUTHENTICATED_PATH_IDENTIFIER_TYPE = 'bifrost-authenticated';

/**
 * MUST be used to connect to private endpoints
 */
export class BifrostAuthenticatedPathIdentifier implements PathIdentifier {
  public readonly type = BIFROST_AUTHENTICATED_PATH_IDENTIFIER_TYPE;

  public constructor(public readonly service: string, public readonly version?: string) {}
}

export function isAuthenticatedBifrostCall(
  identifier: PathIdentifier,
): identifier is BifrostAuthenticatedPathIdentifier {
  return identifier.type === BIFROST_AUTHENTICATED_PATH_IDENTIFIER_TYPE;
}
