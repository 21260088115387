/*
 * This file contains extra validators for Texts. Note that Texts
 * don't have a base validator, as Texts represent unstructured string
 * values.
 */

import {
  AtlasMaxLengthValidationErrors,
  AtlasMinLengthValidationErrors,
  Validator,
} from '../base/validators';
import {baseMaxLength, baseMinLength} from '../base/validators-extra';

import {Text} from './businesstype';

export const ExtraTextValidators = {
  minLength: (limit: number): Validator<Text, AtlasMinLengthValidationErrors> =>
    baseMinLength<Text>(limit),
  maxLength: (limit: number): Validator<Text, AtlasMaxLengthValidationErrors> =>
    baseMaxLength<Text>(limit),
} as const;
