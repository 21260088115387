/*
 * This file contains extra validators for Decimals, that is:
 * the validators used to check extra conditions on valid Decimal values.
 */
import {
  InvalidLimitResult,
  RESULT_VALID,
  ValidationErrors,
  Validator,
  ValidatorInput,
  ValidValidationResult,
} from '../base/validators';

import {Decimal} from './businesstype';
import {DecimalUtils} from './decimal-utils';
import {DECIMAL_CHAR, isValidDecimal} from './validator-base';

/**
 * Validation errors object when the decimal has too many digits in the fraction part
 */
export interface AtlasFractionPartTooLongValidationErrors extends ValidationErrors {
  atlasFractionPartTooLong: InvalidLimitResult<number>;
}

/**
 * Validation errors object when the decimal is too high
 */
export interface AtlasValueTooHighValidationErrors extends ValidationErrors {
  atlasValueTooHigh: InvalidLimitResult<Decimal>;
}

/**
 * Validation errors object when the decimal is too low
 */
export interface AtlasValueTooLowValidationErrors extends ValidationErrors {
  atlasValueTooLow: InvalidLimitResult<Decimal>;
}

export const ExtraDecimalValidators = {
  /**
   * Creates a validator to check if the input is greater than or equal to the value of limit.
   *
   * @param limit The limit number
   * @return The validator
   */
  minLimit(limit: Decimal | string): Validator<Decimal, AtlasValueTooLowValidationErrors> {
    const limitDecimal = Decimal.isDecimal(limit) ? limit : new Decimal(limit);

    return function(
      input: ValidatorInput<Decimal>,
    ): AtlasValueTooLowValidationErrors | ValidValidationResult {
      if (input == null || !isValidDecimal(input)) {
        return RESULT_VALID;
      }

      const inputDecimal = Decimal.isDecimal(input) ? input : new Decimal(input);

      if (DecimalUtils.lt(inputDecimal, limitDecimal)) {
        return {
          atlasValueTooLow: {actualValue: inputDecimal, limit: limitDecimal},
        };
      }

      return RESULT_VALID;
    };
  },

  /**
   * Creates a validator to check if the input is less than or equal to the value of limit.
   *
   * @param limit The limit number
   * @return The validator
   */
  maxLimit(limit: Decimal | string): Validator<Decimal, AtlasValueTooHighValidationErrors> {
    const limitDecimal = Decimal.isDecimal(limit) ? limit : new Decimal(limit);

    return function(
      input: ValidatorInput<Decimal>,
    ): AtlasValueTooHighValidationErrors | ValidValidationResult {
      if (input == null || !isValidDecimal(input)) {
        return RESULT_VALID;
      }

      const inputDecimal = Decimal.isDecimal(input) ? input : new Decimal(input);

      if (DecimalUtils.gt(inputDecimal, limitDecimal)) {
        return {
          atlasValueTooHigh: {actualValue: inputDecimal, limit: limitDecimal},
        };
      }

      return RESULT_VALID;
    };
  },

  /**
   * Creates a validator to check if the input fraction part length is more than the limit.
   *
   * @param limitInput The limit number
   * @return The validator
   */
  maxLengthFractionPart(
    limitInput: number | string,
  ): Validator<Decimal, AtlasFractionPartTooLongValidationErrors> {
    // parseInt(value) handles most of the cases we're interested in (it treats null, empty string,
    // and other non-number values as NaN, where Number just uses 0) but it considers the string
    // '123hello' to be a valid number. Therefore we also check if Number(value) is NaN.
    if (isNaN(parseInt(`${limitInput}`, 10)) || isNaN(Number(limitInput))) {
      throw new Error(
        `Cannot create maxLengthFractionPart validator for non-number ${JSON.stringify(
          limitInput,
        )}`,
      );
    }

    const limit = parseInt(`${limitInput}`, 10);

    if (limit < 0) {
      throw new Error(`Cannot create maxLengthFractionPart validator for negative number ${limit}`);
    }

    return function(
      input: ValidatorInput<Decimal>,
    ): AtlasFractionPartTooLongValidationErrors | ValidValidationResult {
      if (input == null || !isValidDecimal(input)) {
        return RESULT_VALID;
      }

      const value = Decimal.isDecimal(input) ? DecimalUtils.toPrecision(input) : input;
      const decimalPointIdx = value.indexOf(DECIMAL_CHAR);

      if (decimalPointIdx >= 0) {
        const fractionPartLength = value.length - (decimalPointIdx + 1);

        if (fractionPartLength > limit) {
          return {
            atlasFractionPartTooLong: {actualValue: fractionPartLength, limit},
          };
        }
      }

      return RESULT_VALID;
    };
  },
};
