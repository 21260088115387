import {Directive, Input, OnInit, Self} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {
  BELGIAN_LENGTH,
  COUNTRY_SUBSTRING_LENGTH,
  FOREIGN_MAX_LENGTH,
  VatFormatter,
} from '@atlas/businesstypes';

import {distinctUntilChanged, map, startWith} from 'rxjs/operators';

import {INPUT_CHAR} from '../util/mask-helper.util';
import {ValueFormatter} from '../util/value-formatter.util';
import {ValueProcessor} from '../util/value-processor.util';
import {MaskedInputComponent} from './masked-input.component';

/**
 * Extracts the country from a (partial) Vat
 *
 * @param value A (partial) Vat
 * @returns The country or empty string if no country is found
 */
function getVatCountry(value: string): string {
  if (!value || value.length < COUNTRY_SUBSTRING_LENGTH) {
    return '';
  }

  return value.substr(0, COUNTRY_SUBSTRING_LENGTH).toUpperCase();
}

/**
 * Returns the length of Vat numbers from the given country
 *
 * @param country The country of the Vat
 */
function getVatLength(country: string): number {
  return country === 'BE' ? BELGIAN_LENGTH : FOREIGN_MAX_LENGTH;
}

/**
 * Creates a string that is formatted like a VAT
 *
 * Example:
 *
 * ```ts
 * createVatLikeString('x', BELGIAN_MAX_LENGTH || FOREIGN_MAX_LENGTH) === 'xxxxxxxxxxxxxxxx'
 * ```
 *
 * @param character The character to use instead of actual Vat content
 * @param length The length of the "Vat"
 * @returns A formatted string
 */
function createVatLikeString(character: string, length: number) {
  return character.repeat(length);
}

/**
 * Creates a string that is formatted like a VAT for the specified country
 *
 * This string will include the country prefix, e.g.
 *
 * ```
 * createCountryVatlikeString('x', 'BE') === 'BExxxxxxxxxxxxxx'
 * ```
 *
 * @param character The character to use instead of actual VAT content
 * @param country The country for which to create a "VAT"
 * @returns A formatted string
 */
function createCountryVatlikeString(character: string, country: string): string {
  return (
    country + createVatLikeString(character, getVatLength(country)).substr(COUNTRY_SUBSTRING_LENGTH)
  );
}

/**
 * `ValueFormatter` that removes the formatting added by the `[atlasVat]` directive.
 */
const vatFormatter: ValueFormatter = {
  /**
   * Doesn't format anything, formatting is done by the `[atlasVat]` directive.
   */
  formatValue(value: string): string {
    return value;
  },

  /**
   * Unformats any formatting left in place by the `[atlasVat]` directive.
   */
  unformatValue(value: string): string {
    return VatFormatter.unformat(value);
  },
};

/**
 * Allows for VATs of a specific country to be entered in a masked input
 *
 * The country can be set by passing the two-digit key as `[country]` in this directive.
 * This value mustn't change after initialisation.
 *
 * @see MaskedVatInputDirective
 * @ngModule InputMasksModule
 */
@Directive({
  selector: 'maia-masked-input[atlasVat][country]',
})
export class MaskedVatInputWithCountryDirective implements OnInit, ValueProcessor {
  private _country: string;

  private _isInitialized = false;

  public constructor(@Self() private readonly _input: MaskedInputComponent) {
    this._input.setValueFormatter(vatFormatter);
    this._input.setValueProcessor(this);
  }

  /**
   * The country for which to create a masked input
   *
   * This value mustn't change after initialisation of the components.
   */
  @Input()
  public get country(): string {
    return this._country;
  }

  public set country(country: string) {
    if (country.toUpperCase() === this._country) {
      return;
    }

    if (this._isInitialized) {
      throw new Error(
        `Modifying country from ${this._country} to ${country} after initialisation is not allowed`,
      );
    }

    if (country.length !== COUNTRY_SUBSTRING_LENGTH) {
      throw new Error(
        `Expected a ${COUNTRY_SUBSTRING_LENGTH} character country code, got "${country}"`,
      );
    }

    this._country = country.toUpperCase();
  }

  public ngOnInit(): void {
    this._isInitialized = true;

    this._input.setMaskAndPlaceholder(createCountryVatlikeString(INPUT_CHAR, this.country));

    this._input.reprocessValue();
  }

  public fromRawValue(rawValue: string): string {
    return `${this.country}${rawValue}`;
  }

  public toRawValue(processedValue: string): string {
    return processedValue.startsWith(this.country)
      ? processedValue.substr(COUNTRY_SUBSTRING_LENGTH)
      : processedValue;
  }
}

/**
 * Allows for VATs to be entered in a masked input
 *
 * This directive allows VATs to be entered regardless of the VAT's country. The mask changes
 * dynamically when the country changes in the value.
 *
 * @see MaskedVatInputWithCountryDirective
 */
@Directive({
  selector: 'maia-masked-input[atlasVat]:not([country])',
})
@UntilDestroy()
export class MaskedVatInputDirective implements OnInit {
  public constructor(@Self() private readonly _input: MaskedInputComponent) {
    this._input.setValueFormatter(vatFormatter);
  }

  public ngOnInit(): void {
    this._input.value$
      .pipe(takeUntilDestroyed(this), map(getVatCountry), startWith(''), distinctUntilChanged())
      .subscribe(country => {
        const length = getVatLength(country);
        const mask = createVatLikeString(INPUT_CHAR, length);
        this._input.setMaskAndPlaceholder(
          mask,
          country ? undefined : createVatLikeString(' ', length),
        );
      });
  }
}
