import {
  ComponentFactory,
  ComponentFactoryResolver,
  Injectable,
  Injector,
  StaticProvider,
  TemplateRef,
} from '@angular/core';
import {
  ModalContentComponent,
  ModalController,
  ModalOptions,
  ModalOptionsWithInput,
  ModalResult,
  ModalTemplateContext,
} from '@maia/modals';
import {defer, Observable} from 'rxjs';

import {FooterContainer} from '../footer/footer-container.service';
import {
  MODAL_TYPE_SLIDE_IN,
  SLIDEIN_OPTIONS,
  SlideInComponent,
  SlideInOptions,
} from '../slide-in/slide-in.component';

/**
 * The SlideInController allows showing slide-ins.
 */
@Injectable()
export abstract class SlideInController {
  /**
   * Prepares a slide-in. Once the returned observable is subscribed to, the slide-in will be shown.
   * The slide-in gets dismissed if the subscription is ended.
   *
   * By default the open slide-in will block scrolling on the page.
   *
   * @param componentFactory The content component to show in the slide-in
   * @param injector The parent injector for the content component
   * @param options The options, e.g. title, for this slide-in
   * @param modalOptions The options to pass on to the modal controller
   */
  public abstract prepare<O>(
    componentFactory: ComponentFactory<ModalContentComponent<O>>,
    injector: Injector,
    options: SlideInOptions,
    modalOptions?: ModalOptions,
  ): Observable<ModalResult<O>>;
  public abstract prepare<O, I>(
    componentFactory: ComponentFactory<ModalContentComponent<O, I>>,
    injector: Injector,
    options: SlideInOptions,
    modalOptions: ModalOptionsWithInput<I>,
  ): Observable<ModalResult<O>>;
}

/**
 * The μSlideInController allows showing slide-ins.
 *
 * Contrary to the `SlideInController` this service allows creating slide-ins from `TemplateRef`
 * instances. We don't want to expose this behaviour to consumers of maia, but we need it
 * internally, e.g. to allow dropdowns to be shown as slide-ins on small breakpoints.
 */
@Injectable()
export class μSlideInController implements SlideInController {
  private _slideInFactory: ComponentFactory<SlideInComponent>;

  public constructor(
    private _modalCtrl: ModalController,
    componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this._slideInFactory = componentFactoryResolver.resolveComponentFactory(SlideInComponent);
  }

  private _mergeModalOptions<T>(
    modalOptions: ModalOptions<T> | undefined,
    options: SlideInOptions,
  ): ModalOptions<T> {
    const mergedModalOptions = Object.assign({}, modalOptions);

    const slideInProviders: StaticProvider[] = [
      {provide: SLIDEIN_OPTIONS, useValue: options},
      {provide: FooterContainer, deps: []},
    ];

    if (mergedModalOptions.providers != null) {
      mergedModalOptions.providers = mergedModalOptions.providers.concat(slideInProviders);
    } else {
      mergedModalOptions.providers = slideInProviders;
    }

    return mergedModalOptions;
  }

  /**
   * Prepares a slide-in. Once the returned observable is subscribed to, the slide-in will be shown.
   * The slide-in gets dismissed if the subscription is ended.
   *
   * By default the open slide-in will block scrolling on the page.
   *
   * @param componentFactory The content component to show in the slide-in
   * @param injector The parent injector for the content component
   * @param options The options, e.g. title, for this slide-in
   * @param modalOptions The options to pass on to the modal controller
   */
  public prepare<O>(
    componentFactory: ComponentFactory<ModalContentComponent<O>>,
    injector: Injector,
    options: SlideInOptions,
    modalOptions?: ModalOptions,
  ): Observable<ModalResult<O>>;
  public prepare<O, I>(
    componentFactory: ComponentFactory<ModalContentComponent<O, I>>,
    injector: Injector,
    options: SlideInOptions,
    modalOptions: ModalOptionsWithInput<I>,
  ): Observable<ModalResult<O>>;
  public prepare<O, I = undefined>(
    componentFactory: ComponentFactory<ModalContentComponent<O, I>>,
    injector: Injector,
    options: SlideInOptions,
    modalOptions?: ModalOptions<I>,
  ): Observable<ModalResult<O>> {
    return defer(() =>
      this._modalCtrl.prepare(
        MODAL_TYPE_SLIDE_IN,
        injector,
        this._slideInFactory,
        componentFactory,
        // Explicit cast is necessary because typescript doesn't know what we know thanks to
        // our function definition: The input property on the merged modal options is always
        // present if I isn't undefined/void.
        this._mergeModalOptions(modalOptions, options) as ModalOptionsWithInput<I>,
      ),
    );
  }

  /**
   * Prepares a slide-in based on a template. Once the returned observable is subscribed to, the
   * slide-in will be shown. The slide-in gets dismissed if the subscription is ended.
   *
   * By default the open slide-in will block scrolling on the page.
   *
   * @param componentFactory The content component to show in the slide-in
   * @param injector The parent injector for the content component
   * @param options The options, e.g. title, for this slide-in
   * @param modalOptions The options to pass on to the modal controller
   */
  public prepareTemplate<O>(
    templateRef: TemplateRef<ModalTemplateContext<O>>,
    injector: Injector,
    options: SlideInOptions,
    modalOptions?: ModalOptions,
  ): Observable<ModalResult<O>> {
    return defer(() =>
      this._modalCtrl.prepareTemplate(
        MODAL_TYPE_SLIDE_IN,
        injector,
        this._slideInFactory,
        templateRef,
        this._mergeModalOptions(modalOptions, options),
      ),
    );
  }
}
