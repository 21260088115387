import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 * @ngModule TermsAndConditionsModule
 */
@Component({
  selector: 'maia-condition-group',
  template: '<ng-content select="maia-condition"></ng-content>',
  styleUrls: ['./condition-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConditionGroupComponent {}
