import {Injectable} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';
import {Call} from '@atlas/convertor';
import {Logger} from '@atlas/logger';
import {ScopedMessenger} from './scoped-messenger';

@Injectable({
  providedIn: 'root',
})
export class MessagingScopeService {
  private _logger: Logger;
  private _scopedCalls = new WeakMap<Call<any, any>, ScopedMessenger>();

  public constructor(loggerFactory: LoggerFactory) {
    this._logger = loggerFactory.createLogger('MessagingScopeService');
  }

  public assignCallToScope<I, O>(call: Call<I, O>, scope: ScopedMessenger) {
    this._logger.debug(`Assigning call '${call.identifier}' to scope '${scope.identifier}'`);
    this._scopedCalls.set(call, scope);
  }

  public getAssignedScope<I, O>(call: Call<I, O>): ScopedMessenger | undefined {
    return this._scopedCalls.get(call);
  }

  public isScopedCall<I, O>(call: Call<I, O>): boolean {
    return this._scopedCalls.has(call);
  }
}
