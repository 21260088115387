import {HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Call, ConnectorResponseBase} from '@atlas/convertor';
import {Observable} from 'rxjs';

import {ConnectorBackend, ConnectorInterceptor} from '../interfaces/interceptor';

/**
 * A `ConnectorInterceptor` that will add the parameters from the `Call`s `options` property to the
 * `HttpRequest` object as request parameters.
 *
 * It will only add headers that are not yet present, so it won't overwrite existing headers.
 */
@Injectable()
export class RequestParametersInterceptor implements ConnectorInterceptor {
  public intercept<I, O>(
    call: Call<I, O>,
    input: I,
    request: HttpRequest<null>,
    next: ConnectorBackend<O>,
  ): Observable<ConnectorResponseBase<O>> {
    if (!call.options.parameters) {
      return next.handle(request);
    }
    let parameters = request.params;
    call.options.parameters.forEach((value: string, key: string): void => {
      if (!parameters.has(key)) {
        parameters = parameters.set(key, value);
      }
    });
    return next.handle(request.clone({params: parameters}));
  }
}
