import {NotFoundError} from '../keepsake.errors';
import {Keepsake} from '../keepsake.interface';

const INDEX_NAME = '_index_';
const TABLE_SEPARATOR = '.';

export class DomStorage<T> implements Keepsake<T> {
  private readonly _storage: Storage;
  private readonly _tableName: string;
  private readonly _tableIndexName: string;

  public static async isSupported(): Promise<boolean> {
    if (!window.localStorage) {
      return false;
    }
    // There are many reasons the storage might not be available. For example, in mobile
    // Safari if safe browsing is enabled, window.storage is defined but setItem calls
    // throw exceptions. More info available at MDN:
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
    const value = '_storage_test_';
    try {
      window.localStorage.setItem(value, value);
      window.localStorage.removeItem(value);
    } catch (e) {
      return false;
    }
    return true;
  }

  public constructor(tableName: string) {
    this._tableName = tableName;
    this._tableIndexName = `"${tableName + TABLE_SEPARATOR + INDEX_NAME}"`;
    this._storage = window.localStorage;
    this.initializeData();
  }

  private initializeData(): void {
    if (this._storage.getItem(this._tableIndexName) == null) {
      this._storage.setItem(this._tableIndexName, '[]');
    }
  }

  public async save(key: string, data: T): Promise<void> {
    await this.addIndex(key);
    this._storage.setItem(this._tableName + TABLE_SEPARATOR + key, JSON.stringify(data));
  }

  public async get(key: string): Promise<T> {
    const data = this._storage.getItem(this._tableName + TABLE_SEPARATOR + key);
    if (data != null) {
      return JSON.parse(data) as T;
    }
    throw new NotFoundError(`No data found for the given key '${key}'`);
  }

  public async delete(key: string): Promise<void> {
    this._storage.removeItem(this._tableName + TABLE_SEPARATOR + key);
    await this.removeFromIndex(key);
  }

  public async getIndex(withTablePrefix = false): Promise<string[]> {
    const index = this._storage.getItem(this._tableIndexName);
    if (index != null) {
      const values = JSON.parse(index) as string[];
      return withTablePrefix
        ? values
        : values.map(value => value.replace(this._tableName + TABLE_SEPARATOR, ''));
    }
    return [];
  }

  public async list(): Promise<[string, T][]> {
    const indexes = await this.getIndex(false);
    return Promise.all(
      indexes.map(async (index): Promise<[string, T]> => [index, await this.get(index)]),
    );
  }

  public async purge(): Promise<void> {
    const indexes = await this.getIndex(true);
    indexes.forEach(index => {
      this._storage.removeItem(index);
    });
    this._storage.removeItem(this._tableIndexName);
  }

  private async addIndex(key: string): Promise<void> {
    const isSaved = await this.isIndexItemSaved(key);
    if (!isSaved) {
      const index = await this.getIndex(true);
      index.push(this._tableName + TABLE_SEPARATOR + key);
      this._storage.setItem(this._tableIndexName, JSON.stringify(index));
    }
  }

  private async removeFromIndex(key: string): Promise<void> {
    const index = await this.getIndex(true);
    this._storage.setItem(
      this._tableIndexName,
      JSON.stringify(index.filter(item => item !== this._tableName + TABLE_SEPARATOR + key)),
    );
  }

  private async isIndexItemSaved(key: string): Promise<boolean> {
    const index = await this.getIndex(true);
    return index.findIndex(item => item === this._tableName + TABLE_SEPARATOR + key) > -1;
  }
}
