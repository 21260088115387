import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionFuncDownloadSvgModule
 */
@Component({
  selector: 'svg[ivyHermesActionFuncDownload]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M12 2.93v14.25"></svg:path><svg:path d="M6.39 11.57L12 17.18l5.61-5.61" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M2.5 21.07h19"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionFuncDownloadSvgComponent {
}

@NgModule({
  declarations: [
    ActionFuncDownloadSvgComponent,
  ],
  exports: [
    ActionFuncDownloadSvgComponent,
  ],
})
export class ActionFuncDownloadSvgModule {}
