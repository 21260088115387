import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Date, DateKey, DateUtils, DayOfMonth, isValidDate, Month} from '@atlas/businesstypes';

import {DisabledDayDecider} from '../../parts/daypicker/daypicker.util';
import {createDefaultYearRange, DefaultYearRange} from '../../parts/yearpicker/yearpicker.util';

/**
 * The visible view of the datepicker.
 */
export const enum DatepickerVisibleView {
  YEAR = 'year',
  MONTH = 'month',
  DAY = 'day',
}

export type DatelikeInput = Date | string | undefined | null;

/**
 * A datepicker combines the yearpicker, monthpicker and daypicker into one picker which allows the
 * selection of dates.
 *
 * @ngModule DatePickersModule
 */
@Component({
  selector: 'maia-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
    // Do not register as DISABLED_DAY_DECIDER, because the token here would override any tokens
    // in parent injectors.
  ],
})
export class DatepickerComponent
  implements ControlValueAccessor, DisabledDayDecider, OnInit, OnDestroy {
  private _visibleView: DatepickerVisibleView;

  /**
   * The value set with `[ngModel]`. We will re-use this object if the same date gets selected to
   * keep any `encryptedValue` in the businesstype intact.
   *
   * If an `initialYear` is set, that year will be preselected and the month view will be opened at
   * the start.
   */
  private _value?: Date = undefined;

  private _minimum: Date;
  private _maximum: Date;

  private _selectedYear?: number;
  private _selectedMonth?: Month;
  private _selectedDay?: DayOfMonth = undefined;

  private _initialValue: Date;
  private _initialYear: number;

  private _disabledMonths: Month[] = [];

  private readonly _defaultRange: DefaultYearRange;

  // istanbul ignore next: provided by [(ngModel)]
  private _onTouched = () => {};
  // istanbul ignore next: provided by [(ngModel)]
  private _onChanged = (_value: Date) => {};

  private _onDayDisableChanged = () => {};

  public constructor(
    private readonly _changeDetector: ChangeDetectorRef,
    @Optional() defaultRange?: DefaultYearRange,
  ) {
    this._defaultRange = createDefaultYearRange(defaultRange);
    this.rawMaximum = null;
    this.rawMinimum = null;
    this.initialValue = null;
  }

  /**
   * Whether the year view is currently shown. Mutually exclusive with `isMonthVisible()` and
   * `isDayVisible()`.
   */
  public isYearVisible(): boolean {
    return this._visibleView === DatepickerVisibleView.YEAR;
  }

  /**
   * Whether the month view is currently shown. Mutually exclusive with `isYearVisible()` and
   * `isDayVisible()`.
   */
  public isMonthVisible(): boolean {
    return this._visibleView === DatepickerVisibleView.MONTH;
  }

  /**
   * Whether the day view is currently shown. Mutually exclusive with `isMonthVisible()` and
   * `isYearVisible()`.
   */
  public isDayVisible(): boolean {
    return this._visibleView === DatepickerVisibleView.DAY;
  }

  /**
   * The maximum allowed date. If no value is passed, the `DefaultYearRange` will be used to
   * calculate the maximum year relative to the current year.
   */
  @Input('maximum')
  public set rawMaximum(value: DatelikeInput) {
    if (value == null) {
      value = DateUtils.add(DateUtils.today(), DateKey.Year, this._defaultRange.upperBound);
    }

    this._maximum = !Date.isDate(value) ? new Date(value) : value;
  }

  /**
   * The minimum allowed date. If no value is passed, the `DefaultYearRange` will be used to
   * calculate the minimum year relative to the current year.
   */
  @Input('minimum')
  public set rawMinimum(value: DatelikeInput) {
    if (value == null) {
      value = DateUtils.subtract(DateUtils.today(), DateKey.Year, this._defaultRange.lowerBound);
    }

    this._minimum = !Date.isDate(value) ? new Date(value) : value;
  }

  public get minimum(): Date {
    return this._minimum;
  }

  public get maximum(): Date {
    return this._maximum;
  }

  /**
   * The initially active view. This can e.g. be used to create a picker where the year is initially
   * shown rather than the default (day).
   */
  @Input()
  public set visibleView(visibleView: DatepickerVisibleView) {
    if (this._visibleView == null) {
      this._visibleView = visibleView;
    }
  }

  /**
   * The year/month combination to show initially. If no value is passed, the current year/month is
   * shown by default. This doesn't do anything if an actual value is set.
   */
  @Input()
  public set initialValue(value: DatelikeInput) {
    if (value == null) {
      value = DateUtils.today();
    }
    if (!Date.isDate(value)) {
      value = new Date(value);
    }

    this._initialValue = value;
    this._initialYear = DateUtils.get(value, DateKey.Year);
  }

  /**
   * The year to show initially in the yearpicker if there's no selected year.
   */
  public get initialYear(): number {
    return this._initialYear;
  }

  /**
   * The selected year.
   */
  public get selectedYear(): number | undefined {
    return this._selectedYear;
  }

  /**
   * The selected month.
   */
  public get selectedMonth(): number | undefined {
    return this._selectedMonth;
  }

  /**
   * The selected day, if any.
   */
  public get selectedDay(): number | undefined {
    return this._selectedDay;
  }

  /**
   * The months to disable for the currently selected year.
   */
  public get disabledMonths(): number[] {
    return this._disabledMonths;
  }

  private _updateDisabledMonths(): void {
    const disabledMonths: number[] = [];

    if (this._selectedYear === DateUtils.get(this._minimum, DateKey.Year)) {
      const minimumMonth = DateUtils.get(this._minimum, DateKey.Month);

      for (let i = Month.January; i < minimumMonth; i++) {
        disabledMonths.push(i);
      }
    }

    if (this._selectedYear === DateUtils.get(this._maximum, DateKey.Year)) {
      const maximumMonth = DateUtils.get(this._maximum, DateKey.Month);

      for (let i = maximumMonth + 1; i <= Month.December; i++) {
        disabledMonths.push(i);
      }
    }

    this._disabledMonths = disabledMonths;
  }

  /**
   * Whether there is a year before the selected year.
   */
  public hasPreviousYear(): boolean {
    return this._selectedYear! > DateUtils.get(this._minimum, DateKey.Year);
  }

  /**
   * Selects the previous year.
   * There's no validation here, this assumes there _is_ a previous year to go to. Use
   * `hasPreviousYear()` to protect this call.
   */
  public selectPreviousYear(): void {
    this._selectedYear!--;
    this._updateDisabledMonths();
  }

  /**
   * Whether there is a year after the selected year.
   */
  public hasNextYear(): boolean {
    return this._selectedYear! < DateUtils.get(this._maximum, DateKey.Year);
  }

  /**
   * Selects the next year.
   * There's no validation here, this assumes there _is_ a next year to go to. Use `hasNextYear()`
   * to protect this call.
   */
  public selectNextYear(): void {
    this._selectedYear!++;
    this._updateDisabledMonths();
  }

  private _getStartOfCurrentlySelectedMonth(): Date {
    if (this._selectedYear !== undefined && this._selectedMonth !== undefined) {
      return DateUtils.unserialize({
        [DateKey.Year]: this._selectedYear,
        [DateKey.Month]: this._selectedMonth.valueOf(),
        [DateKey.Day]: 1,
      });
    } else {
      return DateUtils.set(DateUtils.today(), DateKey.Day, 1);
    }
  }

  /**
   * Whether there is a month before the selected month.
   */
  public hasPreviousMonth(): boolean {
    return DateUtils.isAfter(
      this._getStartOfCurrentlySelectedMonth(),
      this._minimum,
      DateKey.Month,
    );
  }

  /**
   * Selects the previous month.
   * There's no validation here, this assumes there _is_ a previous month to go to. Use
   * `hasPreviousMonth()` to protect this call.
   */
  public selectPreviousMonth(): void {
    if (this._selectedMonth === Month.January) {
      this._selectedMonth = Month.December;
      this.selectPreviousYear();
    } else {
      this._selectedMonth = (this._selectedMonth! - 1) as Month;
    }
    this._onDayDisableChanged();
  }

  /**
   * Whether there is a month after the selected month.
   */
  public hasNextMonth(): boolean {
    return DateUtils.isBefore(
      this._getStartOfCurrentlySelectedMonth(),
      this._maximum,
      DateKey.Month,
    );
  }

  /**
   * Selects the next month.
   * There's no validation here, this assumes there _is_ a next month to go to. Use `hasNextMonth()`
   * to protect this call.
   */
  public selectNextMonth(): void {
    if (this._selectedMonth === Month.December) {
      this._selectedMonth = Month.January;
      this.selectNextYear();
    } else {
      this._selectedMonth = (this._selectedMonth! + 1) as Month;
    }
    this._onDayDisableChanged();
  }

  /**
   * Opens the year view.
   */
  public openYearView(): void {
    this._visibleView = DatepickerVisibleView.YEAR;
  }

  /**
   * Opens the month view.
   */
  public openMonthView(): void {
    this._visibleView = DatepickerVisibleView.MONTH;
  }

  private _getCurrentSelectedValue() {
    const startOfSelectedMonth = DateUtils.unserialize({
      [DateKey.Year]: this._selectedYear!,
      [DateKey.Month]: this._selectedMonth || Month.January,
      [DateKey.Day]: 1,
    });

    if (!this._selectedDay) {
      return startOfSelectedMonth;
    }

    return DateUtils.set(
      startOfSelectedMonth,
      DateKey.Day,
      Math.min(this._selectedDay, DateUtils.getDaysInMonth(startOfSelectedMonth)) as DayOfMonth,
    );
  }

  /**
   * Marks the given year as selected and opens the day view.
   */
  public selectYear(year: number): void {
    this._selectedYear = year;
    this._visibleView = DatepickerVisibleView.DAY;
    this._updateDisabledMonths();
    this._onDayDisableChanged();
  }

  /**
   * Marks the given month as selected and opens the day view.
   */
  public selectMonth(month: number): void {
    this._selectedMonth = month;
    this._visibleView = DatepickerVisibleView.DAY;
    this._onDayDisableChanged();
  }

  /**
   * Marks the given day as selected. This updates the `[(ngModel)]` value to the selected
   * day/month/year combination.
   */
  public selectDay(day: DayOfMonth): void {
    this._selectedDay = day;

    if (this._value != null && this._getCurrentSelectedValue().equals(this._value)) {
      // emit the previous value to keep encrypted values etc
      this._onChanged(this._value);
      return;
    }

    this._value = this._getCurrentSelectedValue();
    this._onChanged(this._value);
  }

  /**
   * The date to show as selected. This is the `selectedDay` property if the day view is currently
   * showing the selected year and month.
   */
  public getSelectedDay(): number | undefined | null {
    if (this._value == null) {
      return this._selectedDay;
    }

    const startOfMonth = DateUtils.atStartOf(this._getCurrentSelectedValue(), DateKey.Month);
    const previousStartOfMonth = DateUtils.atStartOf(this._value, DateKey.Month);

    if (startOfMonth.equals(previousStartOfMonth)) {
      return this._selectedDay;
    }

    return null;
  }

  /**
   * The day to highlight. This is the day of today if the currently shown month/year combination is
   * the current month/year.
   */
  public getHighlightedDay(): number | null {
    const selectedValue = this._getCurrentSelectedValue();
    const startOfCurrentMonth = DateUtils.atStartOf(DateUtils.today(), DateKey.Month);

    if (DateUtils.atStartOf(selectedValue, DateKey.Month).equals(startOfCurrentMonth)) {
      return DateUtils.get(DateUtils.today(), DateKey.Day);
    }

    return null;
  }

  private _setInitialValues(): void {
    // Only pre-select the initial values up to the active view, otherwise we'd show
    // a selected value even though there is no selected value (only an iniital value)
    if (!this.isYearVisible()) {
      this._selectedYear = DateUtils.get(this._initialValue, DateKey.Year);

      if (this.isDayVisible()) {
        this._selectedMonth = DateUtils.get(this._initialValue, DateKey.Month);
      }
    }
  }

  public ngOnInit() {
    // Default to day view (the visibleView setter doesn't allow overriding values)
    this.visibleView = DatepickerVisibleView.DAY;

    this._setInitialValues();
  }

  public ngOnDestroy() {
    this._onTouched();
  }

  // ControlValueAccessor API

  public writeValue(obj: any): void {
    if (obj != null && (Date.isDate(obj) || isValidDate(obj))) {
      this._value = Date.isDate(obj) ? obj : new Date(obj);

      this._selectedYear = DateUtils.get(this._value, DateKey.Year);
      this._selectedMonth = DateUtils.get(this._value, DateKey.Month);
      this._selectedDay = DateUtils.get(this._value, DateKey.Day);

      this._visibleView = DatepickerVisibleView.DAY;
    } else {
      this._value = undefined;

      this._selectedYear = undefined;
      this._selectedMonth = undefined;
      this._selectedDay = undefined;

      this._setInitialValues();
    }

    this._changeDetector.detectChanges();
  }

  public registerOnChange(fn: any): void {
    this._onChanged = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  // DisabledDayDecider API

  public isDisabled(day: DayOfMonth): boolean | null {
    const currentSelection = DateUtils.set(this._getCurrentSelectedValue(), DateKey.Day, day);
    return DateUtils.isBefore(currentSelection, this._minimum) ||
      DateUtils.isAfter(currentSelection, this._maximum)
      ? true
      : null;
  }

  public registerOnUpdate(updateFn?: (() => void) | undefined): void {
    this._onDayDisableChanged = updateFn || (() => {});
  }
}
