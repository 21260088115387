import {InjectionToken, QueryList} from '@angular/core';
import {DayOfMonth, DayOfWeek, Month} from '@atlas/businesstypes';

import {asapScheduler, MonoTypeOperatorFunction} from 'rxjs';
import {mapTo, mergeMap, observeOn} from 'rxjs/operators';

import {DaysInMonth} from '../../util';

export const DOW_CLASSES = {
  [DayOfWeek.Monday]: 'maia-list--offset-0',
  [DayOfWeek.Tuesday]: 'maia-list--offset-1',
  [DayOfWeek.Wednesday]: 'maia-list--offset-2',
  [DayOfWeek.Thursday]: 'maia-list--offset-3',
  [DayOfWeek.Friday]: 'maia-list--offset-4',
  [DayOfWeek.Saturday]: 'maia-list--offset-5',
  [DayOfWeek.Sunday]: 'maia-list--offset-6',
};

export const DAY_CLASSES = {
  [DaysInMonth.TwentyEight]: 'maia-list--28days',
  [DaysInMonth.TwentyNine]: 'maia-list--29days',
  [DaysInMonth.Thirty]: 'maia-list--30days',
  [DaysInMonth.ThirtyOne]: 'maia-list--31days',
};

const DAYS_IN_WEEK = 7;

/**
 * Watches for changes in the passed through query lists
 *
 * Note that the resulting observable doesn't emit immediately, it will only emit on changes.
 */
export function watchQueryListChanges<T>(): MonoTypeOperatorFunction<QueryList<T>> {
  return mergeMap(queryList =>
    queryList.changes.pipe(
      mapTo(queryList),
      // Observe on an async scheduler because the `changes` observable might emit during
      // a phase in change detection where further changes are not allowed, depending on whether
      // it's a property with @ViewChildren or @ContentChildren.
      observeOn(asapScheduler),
    ),
  );
}

/**
 * Calculates the day of the week for a given day in the month.
 *
 * @param dayOfMonth The day of the month, 1-based
 * @param firstWeekDay The day of the week of the first day of the month
 * @return The day of the week
 */
export function calculateWeekDay(dayOfMonth: number, firstWeekDay: DayOfWeek): DayOfWeek {
  return (dayOfMonth + firstWeekDay - 1) % DAYS_IN_WEEK;
}

/**
 * Multi-injection token for `DisabledDayDecider`s.
 */
export const DISABLED_DAY_DECIDER = new InjectionToken<DisabledDayDecider[]>('disabledDayDecider');

/**
 * Decides whether a given day should be disabled in a daypicker.
 */
export interface DisabledDayDecider {
  /**
   * Whether or not the day is disabled.
   *
   * A return value of `true` disables the date, a value of `false` enables the date, a value of
   * `null` defers to a different DisabledDayDecider if there are other. If no DisabledDayDecider
   * decides on whether the day is disabled, it is enabled.
   *
   * @param day The day of the month, 1-based
   * @param dayOfWeek The day of the week
   */
  isDisabled(day: number, dayOfWeek: DayOfWeek, month: number, year: number): boolean | null;

  /**
   * Registers a function to call when the DisabledDayDecider changes. Return values of `isDisabled`
   * for a given day are cached until this function is called, so if the decision of this decider
   * would change, the function has to be called to make the changes visible.
   *
   * @param updateFn The function to call when the decider is updated
   */
  registerOnUpdate(updateFn?: () => void): void;
}

/**
 * Multi-injection token for `StateDayDecider`s.
 */
export const STATE_DAY_DECIDER = new InjectionToken<StateDayDecider[]>('stateDayDecider');

/**
 * Decides whether a given day should have a certain state.
 */
export interface StateDayDecider {
  /**
   * Resolves the state of a given date
   *
   * Returns the state of a string or null when no match is found
   *
   *
   * @param day The day of the month, 1-based
   * @param month The Month, range from 0(January) to 11(December)
   * @param year The year as a number
   */
  resolveState(day: DayOfMonth, month: Month, year: number): string | null;

  /**
   * Registers a function to call when the StateDayDecider changes. Return values of `resolveState`
   * for a given day are cached until this function is called, so if the decision of this decider
   * would change, the function has to be called to make the changes visible.
   *
   * @param updateFn The function to call when the decider is updated
   */
  registerOnUpdate(updateFn?: () => void): void;
}
