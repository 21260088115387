/**
 * Type for a {@link UniqueToken}.
 */
export const enum UniqueTokenType {
  /**
   * If a child and parent connector have the same value, use the value of the child
   */
  ChildOnly,

  /**
   * Alias for `.ChildOnly`
   */
  First = ChildOnly,

  /**
   * If a child and parent connector have the same value, use the value of the parent
   */
  ParentOnly,

  /**
   * Alias for `.ParentOnly`.
   */
  Last = ParentOnly,
}

/**
 * Token declaring that a value in an array is supposed to be unique
 *
 * This token's `token` property is used to compare uniqueness. In other words, only one value with
 * a certain token must be present in the array.
 */
export interface UniqueToken {
  /**
   * The token to use, if no token is used the constructor of the value will be used instead
   */
  readonly token?: unknown;

  /**
   * The type of the uniqueness
   *
   * This property defines which of the values is kept if more than one value has the given token
   *
   * Note: if a `token` is present more than once, every value with that same `token` must pass the same `type`.
   */
  readonly type: UniqueTokenType;
}

/**
 * Filter an array based on uniqueness tokens
 *
 * @param values The values to filter
 * @returns An array containing the given values, in order, with duplicates filtered out
 */
export function filterUnique<T extends {uniquenessToken?: UniqueToken}>(values: T[]): T[] {
  const encounteredTokens = new Map<unknown, UniqueTokenType>();
  const valueForTokenMap = new Map<unknown, T>();

  // We loop twice

  // In the first loop we look for all tokens and store the value to keep for every token
  for (const value of values) {
    if (value.uniquenessToken == null) {
      continue;
    }

    const token = value.uniquenessToken.token ?? value.constructor;
    const type = value.uniquenessToken.type;
    const alreadyEncounteredType = encounteredTokens.get(token);

    if (alreadyEncounteredType != null && alreadyEncounteredType !== type) {
      throw new Error(
        `Cannot combine multiple types (${alreadyEncounteredType}, ${value.uniquenessToken.type}) for uniqueness token ${token}`,
      );
    }

    encounteredTokens.set(token, type);

    if (alreadyEncounteredType === UniqueTokenType.First) {
      // We have already seen this token, ignore it
      continue;
    }

    // Two scenarios:
    // - we need the first value and it's the first time we see this token -> store it
    // - we need the last value -> always override any previous stored value
    valueForTokenMap.set(token, value);
  }

  // Now we loop over the array and remove any of the tokens with type .ParentOnly that are not the
  // last with the token in the array
  return values.filter(value => {
    const lastValue = valueForTokenMap.get(
      value.uniquenessToken ? value.uniquenessToken.token ?? value.constructor : undefined,
    );

    return lastValue == null || value === lastValue;
  });
}
