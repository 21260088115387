import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule AppBranKbcSignSvgModule
 */
@Component({
  selector: 'svg[ivyHermesAppBranKbcSign]',
  template: '<svg:g class="nc-icon-wrapper"><svg:path class="st0" d="M160 124.4c0 19.7-16 35.6-35.7 35.6H35.7C16 160 0 144.1 0 124.4V35.6C0 15.9 16 0 35.7 0h88.5C144 0 160 15.9 160 35.6v88.8z" fill="#00AEEF"></svg:path><svg:path class="st1" d="M55 133.9v6.1h-7.8v-16.2H55v7.3l4.6-7.3H69l-6.9 8.4 6.5 7.8h-8.9l-4.7-6.1z" fill="#FFFFFF"></svg:path><svg:path class="st1" d="M112.8 139.3c-1.8.6-4.2.9-6.4.9-7.7 0-13.4-2.2-13.4-8.6 0-5.8 5.5-8.3 13-8.3 2.1 0 4.8.2 6.8.9v5.6c-1.5-.9-3.1-1.5-5.3-1.5-2.9 0-5.7 1.4-5.7 3.5s2.8 3.5 5.7 3.5c2.2 0 3.8-.6 5.3-1.5v5.5z" fill="#FFFFFF"></svg:path><svg:path class="st1" d="M70.2 123.8H86c4.1 0 5.4 1.6 5.4 4.1 0 2.8-2.5 4-5 4.1 2.6.2 5.2.5 5.2 3.8 0 2.1-1.3 4.2-5.8 4.2H70.2v-16.2zm7.8 12.9h4.1c1.5 0 2-.6 2-1.6s-.5-1.6-2-1.6H78v3.2zm0-6h3.9c1.5 0 2.1-.6 2.1-1.5 0-1.1-.6-1.6-2-1.6h-4v3.1z" fill="#FFFFFF"></svg:path><svg:path class="st1" d="M86.2 72.9c0-3.4-2.8-6.2-6.2-6.2s-6.2 2.8-6.2 6.2c0 2.5 1.4 4.6 3.5 5.6L75 92.2h10l-2.3-13.8c2.1-.9 3.5-3 3.5-5.5z" fill="#FFFFFF"></svg:path><svg:path class="st1" d="M92.9 33.5c-1.1 0-2-.9-2-2 0-6.3-5.1-11.4-11.4-11.4S68 25.2 68 31.5c0 1.1-.9 2-2 2s-2-.9-2-2C63.9 23 70.9 16 79.4 16s15.5 7 15.5 15.5c0 1.1-.9 2-2 2z" fill="#FFFFFF"></svg:path><svg:path class="st1" d="M92.9 41.3c-1.1 0-2-.9-2-2v-7.8c0-1.1.9-2 2-2s2 .9 2 2v7.8c0 1.1-.9 2-2 2z" fill="#FFFFFF"></svg:path><svg:path class="st1" d="M66 54.5c-1.1 0-2-.9-2-2v-21c0-1.1.9-2 2-2s2 .9 2 2v21c0 1.1-.9 2-2 2z" fill="#FFFFFF"></svg:path><svg:path class="st1" d="M105 107.2H55c-3.6 0-6.6-3-6.6-6.6V57.9c0-3.6 3-6.6 6.6-6.6h50c3.6 0 6.6 3 6.6 6.6v42.8c0 3.5-3 6.5-6.6 6.5zM55 55.3c-1.4 0-2.6 1.2-2.6 2.6v42.8c0 1.4 1.2 2.6 2.6 2.6h50c1.4 0 2.6-1.2 2.6-2.6V57.9c0-1.4-1.2-2.6-2.6-2.6H55z" fill="#FFFFFF"></svg:path></svg:g>',

  styles: [
  ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 160 160',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBranKbcSignSvgComponent {
}

@NgModule({
  declarations: [
    AppBranKbcSignSvgComponent,
  ],
  exports: [
    AppBranKbcSignSvgComponent,
  ],
})
export class AppBranKbcSignSvgModule {}
