import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';
import {CssClassUtility, CssClassUtilityFactory} from '@maia/core';

import {
  TILE_CLASSES,
  TILE_DISABLE_SHADOWS,
  TILE_DISABLED_DEFAULT,
  TILE_HEIGHT_DEFAULT,
  TILE_HIGHLIGHT_FORCED,
  TILE_HIGLIGHT_ON_FOCUS,
  TILE_HIGLIGHT_ON_HOVER,
  TILE_SELECTED_DEFAULT,
  TILE_WIDTH_DEFAULT,
  TileHeight,
  TileWidth,
} from './tile.utils';

/**
 * Tile component
 *
 * This component supports the use of badges in the upper right corner.
 *
 * To use a badge you must add the `withBadge` property to the <maia-tile>
 *
 * Example of a maia-tile with a maia-badge:
 * ```
   <maia-tile withBadge>
    <maia-badge value="2"></maia-badge>
    <maia-tile-items>
      <maia-tile-title>
        Tile with badge
      </maia-tile-title>
      <maia-tile-item>
        Some more text
      </maia-tile-item>
    </maia-tile-items>
  </maia-tile>
  ```
 * @ngModule TilesModule
 */
@Component({
  selector: 'maia-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileComponent {
  private _width: TileWidth;
  private _height: TileHeight;

  private readonly _cssClassSize: CssClassUtility<typeof TILE_CLASSES>;

  /**
   * The tile disabled.
   */
  @Input()
  @HostBinding('class.p-maia-tile--disabled')
  @coerceBooleanPrimitive()
  public disabled = TILE_DISABLED_DEFAULT;

  /**
   * Property to make tile having the highlight styling forced, hovering or not.
   * If true, add a class that forces the highlighting.
   * Default no forced highlighting
   */
  @Input()
  @HostBinding('class.maia-tile--highlight-forced')
  @coerceBooleanPrimitive()
  public highlightForced = TILE_HIGHLIGHT_FORCED;

  /**
   * Property to make tile hoverable
   * If true, add a class that add the hovering behavior
   * Default no hovering behavior
   */
  @Input()
  @HostBinding('class.maia-tile--highlight-on-hover')
  @coerceBooleanPrimitive()
  public highlightOnHover = TILE_HIGLIGHT_ON_HOVER;

  /**
   * Property to make tile selectable
   * If true, add a class that add the selected behavior
   * Default no selected behavior
   */
  @Input()
  @coerceBooleanPrimitive()
  @HostBinding('class.maia-tile--selected')
  @HostBinding('class.p-maia-tile--selected')
  public selected = TILE_SELECTED_DEFAULT;

  /**
   * Property to disable the tile's shadow
   * If true, add a class that removes the shadows
   * By default shadows are displayed
   */
  @Input()
  @HostBinding('class.maia-tile--disable-shadows')
  @coerceBooleanPrimitive()
  public disableShadows = TILE_DISABLE_SHADOWS;

  /**
   * Property to make tile focusable
   * If true, add a class that add the focusable behavior
   * Default no focusing behavior
   */
  @Input()
  @HostBinding('class.maia-tile--highlight-on-focus')
  @coerceBooleanPrimitive()
  public highlightOnFocus = TILE_HIGLIGHT_ON_FOCUS;

  /**
   * Property to indicate the tile has a badge
   * If true, add a class that add change position and overflow
   * Default no badge
   */
  @Input()
  @HostBinding('class.maia-tile--with-badge')
  @coerceBooleanPrimitive()
  public withBadge = false;

  /**
   * Property to indicate the tile has a stamp
   * If true, add a class that add change position and overflow
   * Default no badge
   */
  @Input()
  @HostBinding('class.maia-tile--with-stamp')
  @coerceBooleanPrimitive()
  public withStamp = false;

  /**
   * The tile width.
   */
  @Input()
  public get width(): TileWidth {
    return this._width;
  }

  public set width(width: TileWidth) {
    this._width = width;
    this._cssClassSize.setValue('width', width);
  }

  /**
   * The tile height.
   */
  @Input()
  public get height(): TileHeight {
    return this._height;
  }

  public set height(height: TileHeight) {
    this._height = height;
    this._cssClassSize.setValue('height', height);
  }

  @Input()
  @HostBinding('class.maia-tile--with-close-button')
  @coerceBooleanPrimitive()
  public withCloseButton = false;

  /**
   * Emits when the close button is clicked
   */
  @Output()
  public readonly closeTile = new EventEmitter<void>();

  @Input()
  @HostBinding('class.maia-tile--disabled-close-btn-visible')
  @coerceBooleanPrimitive()
  public showCloseBtnWhenTileDisabled = false;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassSize = cssClassUtilityFactory.create(TILE_CLASSES, renderer, elementRef);
    this.height = TILE_HEIGHT_DEFAULT;
    this.width = TILE_WIDTH_DEFAULT;
  }
}
