import {
  Bban,
  Boolean,
  BusinessType,
  BusinessTypeConstructor,
  CardNumber,
  ClientNumber,
  Date,
  Decimal,
  EmailAddress,
  File,
  Hidden,
  Html,
  Iban,
  IdentityCardNumber,
  NationalRegisterNumber,
  PhoneNumber,
  Text,
  Tsfr,
  Vat,
  αgetEncryptedValue,
} from '@atlas/businesstypes';
import {AnyBusinessType} from '@atlas/convertor';

import {BusinessTypeConvertor} from '../businesstype-convertor';
import {HiddenTypedJson, VisibleTypedJson} from '../typed-json';

export const TYPE_BBAN = 'bban';
export const TYPE_BOOLEAN = 'boolean';
export const TYPE_CARD_NUMBER = 'cardNumber';
export const TYPE_CLIENT_NUMBER = 'clientNumber';
export const TYPE_DATE = 'date';
export const TYPE_DECIMAL = 'decimal';
export const TYPE_SHORT = 'short';
export const TYPE_INTEGER = 'int';
export const TYPE_LONG = 'long';
export const TYPE_EMAIL_ADDRESS = 'emailaddress';
export const TYPE_FILE = 'file';
export const TYPE_HIDDEN = 'hidden';
export const TYPE_HTML = 'html';
export const TYPE_IBAN = 'iban';
export const TYPE_IDENTITY_CARD_NUMBER = 'identityCardNumber';
export const TYPE_NATIONAL_REGISTER_NUMBER = 'nationalRegistrationNumber';
export const TYPE_PHONE_NUMBER = 'phoneNumber';
export const TYPE_TEXT = 'text';
export const TYPE_TSFR = 'tsfr';
export const TYPE_VAT = 'vatNumber';

/**
 * Map object containing the convertors for each businesstype with the
 * `TypedJSON` `T` as key.
 */
const businessTypeConstructorRegistry = new Map<string, BusinessTypeConstructor<BusinessType<any>>>(
  [
    [TYPE_BBAN, Bban],
    [TYPE_BOOLEAN, Boolean],
    [TYPE_CARD_NUMBER, CardNumber],
    [TYPE_CLIENT_NUMBER, ClientNumber],
    [TYPE_DATE, Date],
    [TYPE_DECIMAL, Decimal],
    [TYPE_SHORT, Decimal],
    [TYPE_INTEGER, Decimal],
    [TYPE_LONG, Decimal],
    [TYPE_EMAIL_ADDRESS, EmailAddress],
    [TYPE_FILE, File],
    [TYPE_HIDDEN, Hidden],
    [TYPE_HTML, Html],
    [TYPE_IBAN, Iban],
    [TYPE_IDENTITY_CARD_NUMBER, IdentityCardNumber],
    [TYPE_NATIONAL_REGISTER_NUMBER, NationalRegisterNumber],
    [TYPE_PHONE_NUMBER, PhoneNumber],
    [TYPE_TEXT, Text],
    [TYPE_TSFR, Tsfr],
    [TYPE_VAT, Vat],
  ],
);
const reverseBusinessTypeConstructorRegistry = new Map<
  BusinessTypeConstructor<BusinessType<any>>,
  string
>([
  [Bban, TYPE_BBAN],
  [Boolean, TYPE_BOOLEAN],
  [CardNumber, TYPE_CARD_NUMBER],
  [ClientNumber, TYPE_CLIENT_NUMBER],
  [Date, TYPE_DATE],
  [Decimal, TYPE_DECIMAL],
  [EmailAddress, TYPE_EMAIL_ADDRESS],
  [File, TYPE_FILE],
  [Hidden, TYPE_HIDDEN],
  [Html, TYPE_HTML],
  [Iban, TYPE_IBAN],
  [IdentityCardNumber, TYPE_IDENTITY_CARD_NUMBER],
  [NationalRegisterNumber, TYPE_NATIONAL_REGISTER_NUMBER],
  [PhoneNumber, TYPE_PHONE_NUMBER],
  [Text, TYPE_TEXT],
  [Tsfr, TYPE_TSFR],
  [Vat, TYPE_VAT],
]);

/**
 * Map object containing the convertors for each businesstype with the
 * `TypedJSON` `T` as key.
 *
 * @deprecated
 */
const toBusinessTypeRegistry = new Map<string, BusinessTypeConvertor<AnyBusinessType, string>>();

/**
 * Map containing the convertors for each businesstype with the specific `BusinessType`
 * subtype as key.
 *
 * @deprecated
 */
const fromBusinessTypeRegistry = new Map<
  // eslint-disable-next-line @typescript-eslint/ban-types
  Function,
  BusinessTypeConvertor<AnyBusinessType, string>
>();

/**
 * Converts between any `BusinessType` and their corresponding `TypedJSON` representation.
 */
export const businessTypeConvertor = {
  toBusinessType(value: HiddenTypedJson | VisibleTypedJson<string>): AnyBusinessType {
    const businessType = toBusinessTypeRegistry
      .get(value.T)
      ?.toBusinessType?.(value as VisibleTypedJson<string>);

    if (businessType) {
      return businessType;
    }

    const ctor = businessTypeConstructorRegistry.get(value.T);

    if (ctor == null) {
      throw new Error(`Unsupported typed JSON type: ${JSON.stringify(value.T)}`);
    }

    return ctor.fromJsonValue((value as VisibleTypedJson<string>).V, value.E);
  },

  fromBusinessType(value: AnyBusinessType): HiddenTypedJson | VisibleTypedJson<string> {
    const ctor = value.constructor as BusinessTypeConstructor<BusinessType<any>>;

    if (fromBusinessTypeRegistry.has(ctor)) {
      return fromBusinessTypeRegistry.get(ctor)!.fromBusinessType(value);
    }

    const type = reverseBusinessTypeConstructorRegistry.get(ctor);

    if (type == null) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unsupported businesstype: ${value}`);
    }

    const jsonValue = ctor.toJsonValue(value);
    const encryptedValue = αgetEncryptedValue(value);

    if (jsonValue == null) {
      if (type !== TYPE_HIDDEN) {
        throw new Error(`Unexpected return type null from toJsonValue`);
      }

      return {
        T: type,
        E: encryptedValue!,
      };
    }

    return encryptedValue
      ? {T: type, E: encryptedValue, V: String(jsonValue)}
      : {T: type, V: String(jsonValue)};
  },
};

/**
 * A concrete businesstype implementation must at least provide a constructor
 * accepting a string value and an optional encrypted value for the convertor
 * to be able to use it.
 */
export interface BusinessTypeImpl<T> {
  new (value: string, encryptedValue?: string): T;
}

/**
 * @deprecated Use {@link registerType} instead
 */
export function registerConvertor<BT extends AnyBusinessType, T extends string>(
  businesstype: BusinessTypeImpl<BT>,
  convertor: BusinessTypeConvertor<BT, any, T>,
  ...types: T[]
): void {
  fromBusinessTypeRegistry.set(businesstype, convertor);

  for (const type of types) {
    toBusinessTypeRegistry.set(type, convertor);
  }
}

export function registerType<T extends BusinessTypeConstructor<any>>(
  businesstype: T,
  ...types: string[]
): void {
  for (const type of types) {
    businessTypeConstructorRegistry.set(type, businesstype);

    if (!reverseBusinessTypeConstructorRegistry.has(businesstype)) {
      reverseBusinessTypeConstructorRegistry.set(businesstype, type);
    }
  }
}
