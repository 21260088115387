import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

/**
 *
 * @ngModule ActionsModule
 */
@Component({
  selector: 'maia-document-tile',
  templateUrl: './document-tile.component.html',
  styleUrls: ['./document-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentTileComponent {
  /**
   * Text displayed in the document-tile
   */
  @Input()
  public text: Text | String;
}
