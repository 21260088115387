import {APP_INITIALIZER, ModuleWithProviders, NgModule} from '@angular/core';
import {provideRoutes, Route} from '@angular/router';
import {MncLoader, ROUTE_PATH_MNC} from './mnc-loader.service';

/**
 * Using the old syntax here versus the new, due to issues in lazy loading the modules in AOT mode.
 * 'loadChildren' should actually be:
 * <pre>
 *  loadChildren: () => import('@hermes-mnc/core/platform').then(module => module.MncModule)
 * </pre>
 * todo: revise this for future angular versions
 */
export const MNC_MODULE_ROUTE: Route = {
  path: ROUTE_PATH_MNC,
  loadChildren: '@hermes-mnc/core/platform#MncModule',
};

export function loadMnc(mncLoader: MncLoader): () => Promise<unknown> {
  // We cannot return the lambda directly due to angular issue:
  // https://github.com/angular/angular/issues/14485
  // Using an intermediate variable as described in the workaround
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const res = () => mncLoader.load();
  return res;
}

/**
 * This will lazy load modules required for the MNC device, if it is detected.
 */
@NgModule()
export class MncLoaderModule {
  /**
   * The route given should be MNC_MODULE_ROUTE; this will load the MncModule from the @hermes/core/platform subpackage.
   */
  public static forRoot(mncRoute: Route): ModuleWithProviders<MncLoaderModule> {
    return {
      ngModule: MncLoaderModule,
      providers: [
        MncLoader,
        provideRoutes([mncRoute]),
        {
          provide: APP_INITIALIZER,
          useFactory: loadMnc,
          deps: [MncLoader],
          multi: true,
        },
      ],
    };
  }
}
