import {BusinessType, createStringifier} from '../base/businesstype';
import {JsonValue} from '../base/json';

const stringifier = createStringifier('boolean');

/**
 * BusinessType wrapper around boolean.
 */
/* eslint-disable @typescript-eslint/ban-types */
// We need to import Boolean for the linter to know it's
// alright, but we can't do that as this is the actual file that defines the class.
export class Boolean extends BusinessType<boolean> {
  public static readonly FALSE = new Boolean(false);
  public static readonly TRUE = new Boolean(true);

  /**
   * Don't use this directly, use `fromJsonValue` instead
   */
  public static overrideFromJsonValue(value: JsonValue, encryptedValue?: string): Boolean {
    if (typeof value !== 'string' && typeof value !== 'boolean') {
      throw new Error(`Expected one of string or boolean but got ${value && typeof value}`);
    }

    return new this(value, encryptedValue);
  }

  /**
   * Returns whether or not `value` is a Boolean.
   */
  public static isBoolean(value: unknown): value is Boolean {
    return value instanceof Boolean;
  }

  /**
   * Constructs a new Boolean instance.
   *
   * @param value The boolean value
   * @param encryptedValue The encryptedValue, if any
   */
  public constructor(value: string | boolean, encryptedValue?: string) {
    if (typeof value === 'boolean') {
      super(value, encryptedValue);
    } else if (typeof value === 'string') {
      const lowerValue = value.toLowerCase();
      if (lowerValue === 'true') {
        super(true, encryptedValue);
      } else if (lowerValue === 'false') {
        super(false, encryptedValue);
      } else {
        throw new Error(`Invalid string for constructing a boolean: ${value}`);
      }
    } else {
      throw new Error(
        `Expected a boolean or a string but got ${
          (typeof value === 'object' && (value as object).constructor.name) || typeof value
        }`,
      );
    }
  }

  public asBoolean(): boolean {
    return this.internalValue;
  }

  /**
   * Returns whether `other` is a `Boolean` representing the same value.
   */
  public equals(other: unknown): boolean {
    if (!other) {
      return false;
    }

    if (other === this) {
      return true;
    }

    return Boolean.isBoolean(other) && this.internalValue === other.internalValue;
  }

  public toString(): string {
    return stringifier(this);
  }
}
