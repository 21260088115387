import {Directive, forwardRef, Inject} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Decimal, DecimalFormatter, isValidDecimal} from '@atlas/businesstypes';

import {
  ATLAS_VALUE_ACCESSOR,
  AtlasControlValueAccessor,
  BaseBusinesstypeDirective,
} from '../base/index';

/**
 * @ngModule BusinessTypesModule
 */
@Directive({
  selector: '[atlasDecimal]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DecimalDirective),
    },
  ],
})
export class DecimalDirective extends BaseBusinesstypeDirective {
  public constructor(@Inject(ATLAS_VALUE_ACCESSOR) input: AtlasControlValueAccessor) {
    super(input);
  }

  public writeValue(value?: Decimal | string): void {
    this._input.writeValue(
      Decimal.isDecimal(value) ? DecimalFormatter.format(value) : value == null ? '' : value,
    );
  }

  public registerOnChange(fn: (_: Decimal | string | null) => void): void {
    this._input.registerOnChange((value: string) => {
      if (value === '') {
        fn(null);
        return;
      }

      const unformatted = DecimalFormatter.unformat(value);
      fn(isValidDecimal(unformatted) ? new Decimal(unformatted) : unformatted);
    });
  }
}
