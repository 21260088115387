import {NgModule} from '@angular/core';

import {BackdropComponent} from './backdrop/backdrop.component';
import {ModalContainerDirective} from './container/modal-container.directive';
import {ModalController} from './controller/modal-controller.interface';
import {μModalController} from './controller/modal-controller.service';
import {RenderModalDirective} from './render/render-modal.directive';

/**
 * Module containing the `@maia/modals` package.
 */
@NgModule({
  declarations: [BackdropComponent, ModalContainerDirective, RenderModalDirective],
  exports: [ModalContainerDirective, RenderModalDirective],
  entryComponents: [BackdropComponent],
  providers: [μModalController, {provide: ModalController, useExisting: μModalController}],
})
export class ModalsModule {}
