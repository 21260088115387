import {Text} from '@atlas/businesstypes';

/**
 * A converter which is responsible for:
 *
 * - Converting raw typeahead string values into a generic model type
 * - Converting a generic model into a human readable label to be displayed
 *
 * @param <T> the type of the model
 */
export interface CustomValueConverter<T> {
  toModel(input: string): T;
  toLabel(model: T): string;
}

export class DefaultCustomValueConverter implements CustomValueConverter<Text> {
  public toModel(input: string): Text {
    return new Text(input);
  }

  public toLabel(model: Text): string {
    return model.asString();
  }
}
