import {Decimal, DecimalUtils} from '@atlas/businesstypes';

import {JsonValue} from './json';

/**
 * Converts stored values from device storage to the correct type for usage in application code,
 * and vice versa
 */
export interface DeviceStorageConverter<TStored extends JsonValue, TConverted = TStored> {
  /**
   * Converts a value from the storage to the type needed in code
   *
   * @param storedValue The value from the storage
   */
  convertFromStorage: (storedValue: TStored) => TConverted;

  /**
   * Converts a value from the application to the type needed in the device storage
   *
   * @param convertedValue The value in the application
   */
  convertToStorage: (convertedValue: TConverted) => TStored;
}

class IdentityDeviceStorageConverter<T extends JsonValue> implements DeviceStorageConverter<T> {
  public convertFromStorage(storedValue: T) {
    return storedValue;
  }
  public convertToStorage(convertedValue: T) {
    return convertedValue;
  }
}

/**
 * Creates a converter that just passes through the TStored object when converting from TStored, and
 * passes through the TConverted object when converting to TStored
 *
 * Naming was based on the identity function in functional programming
 */
export const createIdentityConverter = <T extends JsonValue>(): DeviceStorageConverter<T> =>
  new IdentityDeviceStorageConverter<T>();

/**
 * Default implementation for converting numbers from device storage to Decimals in the application
 * code, and vice versa
 */
export const NUMBER_DECIMAL_DEVICE_STORAGE_CONVERTER: DeviceStorageConverter<number, Decimal> = {
  convertFromStorage: (storedValue: number) => new Decimal(`${storedValue}`),
  convertToStorage: (convertedValue: Decimal) => parseInt(DecimalUtils.toFixed(convertedValue), 10),
};
