import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {UntilDestroy, takeUntilDestroyed} from '@atlas-angular/rxjs';
import {BehaviorSubject, NEVER} from 'rxjs';
import {distinctUntilChanged, switchMap} from 'rxjs/operators';

import {TemplateContext} from '../util/abstract-container.service';
import {createEmbeddedView$} from '../util/embedded-view';

import {ApplicationDisclaimerContainer} from './application-disclaimer-container.service';

/**
 * Marks this element as the application's disclaimer
 *
 * If this application type positions disclaimers, it will place the application disclaimer at the
 * spot the application has made available for it. If the application type doesn't position disclaimers,
 * the disclaimer will be shown where it has been placed in the template.
 *
 * @example
 *
 * ```html
 * <maia-disclaimer *atlasApplicationDisclaimer>
 *   <!-- ... -->
 * </maia-disclaimer>
 * ```
 *
 * @ngModule ApplicationDisclaimerModule
 */
@Directive({selector: '[atlasApplicationDisclaimer]'})
@UntilDestroy()
export class ApplicationDisclaimerDirective implements OnInit, OnDestroy {
  private readonly withInlineFallback$ = new BehaviorSubject(true);

  public constructor(
    private readonly template: TemplateRef<TemplateContext>,
    private readonly viewContainer: ViewContainerRef,
    private readonly container: ApplicationDisclaimerContainer,
  ) {}

  /**
   * Whether this directive's host element should be shown inline if the application container doesn't support disclaimers
   *
   * @default true
   * @example
   *
   * ```html
   * <maia-disclaimer *atlasApplicationDisclaimer="let isDisclaimerInline = isInline; withInlineFallback: false">
   *   This disclaimer will never be shown inline, so <pre>{{isDisclaimerInline}}</pre> will always show <pre>false</pre>.
   * </maia-disclaimer>
   * ```
   */
  @Input('atlasApplicationDisclaimerWithInlineFallback')
  public set withInlineFallback(withInlineFallback: boolean) {
    this.withInlineFallback$.next(withInlineFallback);
  }

  public ngOnInit(): void {
    this.container
      .registerTemplate(
        this.template,
        this.withInlineFallback$.pipe(
          distinctUntilChanged(),
          switchMap(enabled =>
            enabled
              ? createEmbeddedView$(this.viewContainer, this.template, {isInline: true})
              : NEVER,
          ),
        ),
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.withInlineFallback$.complete();
  }
}
