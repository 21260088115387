import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  Renderer2,
} from '@angular/core';
import {coerceBooleanPrimitive, coerceNumberPrimitive} from '@atlas-angular/cdk/coercion';
import {CssClassUtility, CssClassUtilityFactory} from '@maia/core';

/**
 * All valid values for the `type` property of StampComponents.
 */
export type StampType =
  | 'negative'
  | 'positive'
  | 'attention'
  | 'informative'
  | 'highlight'
  | 'neutral'
  | 'warning'
  | 'error'
  | 'other';

const CLASSES = {
  type: {
    negative: 'maia-stamp--negative',
    positive: 'maia-stamp--positive',
    attention: 'maia-stamp--attention',
    informative: 'maia-stamp--informative',
    highlight: 'maia-stamp--highlight',
    neutral: 'maia-stamp--neutral',
    warning: 'maia-stamp--warning',
    error: 'maia-stamp--error',
    other: 'maia-stamp--other',
  } as {[key in StampType]: string | null},
};

/**
 * Stamp component. Stamps have a type, which decides the colour scheme to use.
 *
 * It is up to the consumer of this stamp component to ensure the proper accessibility
 * support is present.
 *
 * @ngModule StampsModule
 */
@Component({
  selector: 'maia-stamp',
  template: '<ng-content></ng-content>',
  styleUrls: ['./stamp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line
  host: {
    '[class.maia-stamp--top-placement]': 'defaultPlacement || customSeparation',
  },
})
export class StampComponent {
  private _type: StampType;
  private readonly _cssClassUtility: CssClassUtility<typeof CLASSES>;

  @coerceBooleanPrimitive()
  @Input()
  @HostBinding('class.maia-stamp--disabled')
  public disabled = false;

  /**
   * Property to place a stamp in default position
   * If true, adds a class that places the stamp on top right corner
   */

  @Input()
  @coerceBooleanPrimitive()
  public defaultPlacement = false;

  /**
   * Property to place a stamp with custom margin
   */

  @Input()
  @coerceNumberPrimitive()
  @HostBinding('style.right.px')
  public customSeparation: number;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
  ) {
    this._cssClassUtility = cssClassUtilityFactory.create(CLASSES, renderer, elementRef);
    this._cssClassUtility.setValue('type', 'positive');
  }

  /**
   * The stamp type.
   */
  @Input('type')
  public get type(): StampType {
    return this._type;
  }

  public set type(newType: StampType) {
    if (newType === this._type) {
      return;
    }

    this._type = newType;
    this._cssClassUtility.setValue('type', newType);
  }
}
