import {Component, ContentChild, TemplateRef} from '@angular/core';

import {HeaderBackgroundDirective} from './header-background.directive';
import {HeaderComponent} from './header.component';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'header[bifrostHeaderWithBackground]',
  templateUrl: './header-with-background.component.html',
  styleUrls: ['./header.component.scss', './header-with-background.component.scss'],
})
export class HeaderWithBackgroundComponent extends HeaderComponent {
  @ContentChild(HeaderBackgroundDirective, {read: TemplateRef})
  public background: TemplateRef<{}>;
}
