import {HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DocumentRef} from '@atlas-angular/cdk/globals';
import {Call, ConnectorResponseBase} from '@atlas/convertor';
import {Observable} from 'rxjs';

import {ConnectorBackend, ConnectorInterceptor} from '../interfaces/interceptor';
import {parseCookieValue} from '../utils/cookie';

const XSRF_TOKENS = [
  // Industry standard XSRF token
  {cookie: 'XSRF-TOKEN', header: 'X-XSRF-TOKEN'},

  // "Temporary" custom token setup
  //
  // The problem is that the old XSRF implementation is midtier level based. That's fine for our old
  // setups, where we had only one midtier behind a webseal. New applications, however, go to a lot
  // of different midtiers instead of just one, e.g. their main server + signing infrastructure +
  // the PDF2IMG microservice. Instead of ending up with n different XSRF cookies—one per visited
  // midtier—, we shifted to an XSRF token on `/` that's set during login, which is even more
  // secure. Just to be sure that there is no conflict, this new cookie is called X-SEC-XSRF-TOKEN.
  // This to ensure old and new setups can run next to each other without any issues.
  //
  // Once everyone uses the new cookie, we can rename it back to the industry standard X-XSRF-TOKEN.
  // At the time of writing this comment, changing classic midtiers is not on the planning. Right
  // now they use some strange solution like having x XSRF cookies but all with the same isam backed
  // value.
  {cookie: 'XSRF-TOKEN-SAI', header: 'X-SEC-XSRF-TOKEN'},
];

/**
 * `ConnectorInterceptor` which adds an XSRF token to eligible outgoing requests.
 *
 * Code borrowed from
 * https://github.com/angular/angular/blob/master/packages/common/http/src/xsrf.ts
 * The difference here is that we also add the xsrf token on absolute requests and non-mutating requests
 * and we add multiple XSRF tokens for a very good reason (backwards-compatibility).
 */
@Injectable()
export class XsrfHeaderInterceptor implements ConnectorInterceptor {
  private _lastCookieString = '';

  private readonly _lastCookieValues = new Map<string, string | null>();

  public constructor(private readonly _document: DocumentRef) {}

  private _getCookie(name: string): string | null {
    const cookieString = this._document.document.cookie || '';

    if (cookieString !== this._lastCookieString) {
      this._lastCookieValues.clear();
    }

    let lastValue = this._lastCookieValues.get(name);

    if (lastValue === undefined) {
      this._lastCookieString = cookieString;
      lastValue = parseCookieValue(cookieString, name);
      this._lastCookieValues.set(name, lastValue);
    }

    return lastValue;
  }

  public intercept<I, O>(
    _call: Call<I, O>,
    _input: I,
    request: HttpRequest<null>,
    next: ConnectorBackend<O>,
  ): Observable<ConnectorResponseBase<O>> {
    for (const {cookie, header} of XSRF_TOKENS) {
      const token = this._getCookie(cookie);

      // Be careful not to overwrite an existing header of the same name.
      if (token != null && !request.headers.has(header)) {
        request = request.clone({headers: request.headers.set(header, token)});
      }
    }

    return next.handle(request);
  }
}
