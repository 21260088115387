import {Observable, Subject} from 'rxjs';
import {Message} from './message';
import {MessagingActionService} from './messaging.action.service';

export class Messenger {
  protected messaging = new Subject<Message>();
  protected readonly clearMessages = new Subject<void>();

  public readonly onMessageAction: Observable<Message>;

  public constructor(protected _messagingActionService: MessagingActionService) {
    this.onMessageAction = this._messagingActionService.onMessageAction;
  }

  /**
   * Adds a message to the broker
   * @param message The message to be added
   */
  public add(message: Message): void {
    if (message.showCloseButton === undefined) {
      message.showCloseButton = true;
    }
    this.messaging.next(message);
  }

  /**
   * Returns an observable emitting new messages
   */
  public onMessage(): Observable<Message> {
    return this.messaging.asObservable();
  }

  /**
   * Clear all messages
   */
  public clear() {
    this.clearMessages.next();
  }

  /**
   * Returns an observable emitting when all messages have been cleared
   */
  public onClear(): Observable<void> {
    return this.clearMessages.asObservable();
  }
}
