import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationFuncCommunicationSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaInformationFuncCommunication]',
  template: '<svg:g class="nc-icon-wrapper" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"><svg:path data-cap="butt" data-color="color-2" fill="none" stroke-miterlimit="10" d="M8.64 15.68c1.034 1.608 3.22 2.72 5.76 2.72.472 0 .93-.04 1.373-.114L19.2 20v-3.232c.993-.846 1.6-1.952 1.6-3.168 0-1.242-.634-2.369-1.665-3.222" stroke-width="1.04"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M11.2 3.2c-4.418 0-8 2.866-8 6.4 0 1.438.6 2.76 1.6 3.828V17.6l3.866-1.933A9.814 9.814 0 0 0 11.2 16c4.418 0 8-2.866 8-6.4s-3.582-6.4-8-6.4z" stroke-width="1.04"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationFuncCommunicationSvgComponent {
}

@NgModule({
  declarations: [
    InformationFuncCommunicationSvgComponent,
  ],
  exports: [
    InformationFuncCommunicationSvgComponent,
  ],
})
export class InformationFuncCommunicationSvgModule {}
