import {Component} from '@angular/core';

/**
 * List component that holds one or more LinkedListItems
 *
 * Example usage:
 *
 * ```html
 * <maia-linked-list>
 *     <maia-linked-list-item>...</maia-linked-list-item>
 *     <maia-linked-list-item>...</maia-linked-list-item>
 * </maia-linked-list>
 * ```
 *
 * For more info and examples on the usage of the Linked List Item see
 * @see {@link LinkedListItemComponent}
 * @ngModule ListsModule
 */
@Component({
  selector: 'maia-linked-list',
  templateUrl: './linked-list.component.html',
  styleUrls: ['./linked-list.component.scss'],
})
export class LinkedListComponent {}
