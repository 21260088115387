import {coerceNumberProperty} from '@angular/cdk/coercion';
import {
  ComponentFactory,
  ElementRef,
  Injectable,
  Injector,
  StaticProvider,
  TemplateRef,
} from '@angular/core';
import {ModalContentComponent, ModalResult} from '@maia/modals';
import {Observable} from 'rxjs';

import {DropdownController} from '../controller/dropdown-controller.service';
import {AbstractDropdownComponent} from '../shared/dropdown.component';
import {DropdownPosition} from '../shared/dropdown.constants';
import {
  DropdownMargin,
  DropdownOptions,
  DropdownTemplateContext,
  InternalDropdownOptions,
} from '../shared/dropdown.interfaces';

import {DropdownHost} from './host';

const DEFAULT_DROPDOWN_OPTIONS: DropdownOptions = {
  position: DropdownPosition.BOTTOM_ALIGNED,
};

export class DropdownHostImplementation implements DropdownHost {
  private _margin?: DropdownMargin = undefined;

  public constructor(
    private readonly _controller: DropdownController,
    private readonly _element: ElementRef,
    private readonly _injector: Injector,
  ) {}

  public set margin(margin: DropdownMargin | number | undefined) {
    if (typeof margin !== 'number' && typeof margin !== 'string') {
      this._margin = margin;
      return;
    }

    margin = coerceNumberProperty(margin);
    this._margin = {top: margin, bottom: margin};
  }

  private _createOptions(options: DropdownOptions): InternalDropdownOptions {
    return {
      ...options,

      margins: this._margin,
      referencePoint: this._element.nativeElement,
    };
  }

  public prepare<T>(
    componentFactory: ComponentFactory<ModalContentComponent<T>>,
    position: DropdownOptions = DEFAULT_DROPDOWN_OPTIONS,
    providers: StaticProvider[] = [],
  ): Observable<ModalResult<T>> {
    return this._controller.prepare(
      this._injector,
      componentFactory,
      this._createOptions(position),
      providers,
    );
  }

  public prepareTemplate<T>(
    templateRef: TemplateRef<DropdownTemplateContext<T>>,
    position: DropdownOptions = DEFAULT_DROPDOWN_OPTIONS,
  ): Observable<ModalResult<T>> {
    return this._controller.prepareTemplate(
      this._injector,
      templateRef,
      this._createOptions(position),
    );
  }

  public prepareCustom<T, C extends AbstractDropdownComponent>(
    dropdownFactory: ComponentFactory<C>,
    componentFactory: ComponentFactory<ModalContentComponent<T>>,
    position: DropdownOptions = DEFAULT_DROPDOWN_OPTIONS,
    providers: StaticProvider[] = [],
  ): Observable<ModalResult<T>> {
    return this._controller.prepareCustom(
      this._injector,
      dropdownFactory,
      componentFactory,
      this._createOptions(position),
      providers,
    );
  }

  public prepareTemplateCustom<T, C extends AbstractDropdownComponent>(
    dropdownFactory: ComponentFactory<C>,
    templateRef: TemplateRef<DropdownTemplateContext<T>>,
    position: DropdownOptions = DEFAULT_DROPDOWN_OPTIONS,
    providers: StaticProvider[] = [],
  ): Observable<ModalResult<T>> {
    return this._controller.prepareTemplateCustom(
      this._injector,
      dropdownFactory,
      templateRef,
      this._createOptions(position),
      providers,
    );
  }
}

/**
 * Factory for creating dropdown hosts attached to elements
 */
@Injectable()
export class DropdownHostFactory {
  public constructor(private readonly _dropdownCtrl: DropdownController) {}

  /**
   * Create and return a new DropdownHost
   *
   * @param element The anchor element to attach the dropdown to
   * @param injector The injector to use when instantiating components
   */
  public createHost(element: ElementRef, injector: Injector): DropdownHost {
    return new DropdownHostImplementation(this._dropdownCtrl, element, injector);
  }
}
