import {TemplateRef} from '@angular/core';
import {NgControl} from '@angular/forms';
import {Observable} from 'rxjs';

export abstract class InputContainer {
  public abstract readonly errorString?: string;

  public abstract readonly errorTemplate?: TemplateRef<any>;

  public abstract readonly disabled: boolean;

  public abstract readonly disabled$: Observable<boolean>;

  public abstract focused: boolean;

  public abstract readonly focused$: Observable<boolean>;

  public abstract readonly optional: boolean;

  public abstract readonly optional$: Observable<boolean>;

  public abstract readonly validationErrorChange$: Observable<void>;

  public abstract hasError(): boolean;

  public abstract registerFormControl(control: NgControl): () => void;
}
