/**
 * All valid values for the `type` property.
 */
export type PriceBlockType = 'info' | 'warning' | 'custom';

/**
 * The corresponding CSS classes for the type property of a price block component.
 */
export const PriceBlockClasses = {
  type: {
    custom: 'maia-price-block--custom',
    info: null,
    warning: 'maia-price-block--warning',
  } as {[key in PriceBlockType]: string | null},
};
