import {MediaMatcher} from '@angular/cdk/layout';
import {Injectable, NgZone} from '@angular/core';

import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';

import {getViewport, VIEWPORT_NAMES, ViewportName} from './viewport';

/**
 * The ViewportService grants access to the active `Viewport`.
 */
@Injectable({providedIn: 'root'})
export class ViewportServiceImpl implements ViewportService {
  // We initialise with null so we can create the instance declaratively
  private readonly _viewport$: BehaviorSubject<ViewportName>;

  private readonly _mediaQueries: Readonly<{[viewport in ViewportName]: MediaQueryList}>;

  private _isOverriden = false;

  public constructor(mediaMatcher: MediaMatcher, private readonly _zone: NgZone) {
    let activeViewport = ViewportName.LARGE;
    const mediaQueries = {} as {[viewport in ViewportName]: MediaQueryList};

    for (const viewport of VIEWPORT_NAMES) {
      const size = getViewport(viewport);

      let query: string;

      if (size.min != null) {
        if (size.max != null) {
          query = `(min-width: ${size.min}px) and (max-width: ${size.max}px)`;
        } else {
          query = `(min-width: ${size.min}px)`;
        }
      } else {
        query = `(max-width: ${size.max}px)`;
      }

      const mediaQuery = mediaMatcher.matchMedia(query);
      mediaQueries[viewport] = mediaQuery;

      if (mediaQuery.matches) {
        activeViewport = viewport;
      }
    }

    this._viewport$ = new BehaviorSubject<ViewportName>(activeViewport);
    this._mediaQueries = mediaQueries;

    this._listenToViewportChanges();
  }

  /**
   * Returns an observable watching the active viewport. The observable always immediately emits the
   * current viewport.
   */
  public get viewport$(): Observable<ViewportName> {
    return this._viewport$.asObservable();
  }

  /**
   * Returns the currently active viewport.
   */
  public get viewport(): ViewportName {
    return this._viewport$.value;
  }

  /**
   * Returns whether or not the given viewport is currently active
   * @param viewport The viewport to query
   */
  public is(viewport: ViewportName): boolean {
    return this.viewport === viewport;
  }

  /**
   * Returns an observable that emits whether or not the viewport matches the given value.
   * @param viewport The viewport to query
   */
  public is$(viewport: ViewportName): Observable<boolean> {
    return this._toObservable(() => this.is(viewport));
  }

  /**
   * Returns whether or not the current viewport is at least as big as the given viewport.
   * @param viewport The viewport to query
   */
  public isAtLeast(viewport: ViewportName): boolean {
    return getViewport(this.viewport).isBiggerThanOrEqualTo(getViewport(viewport));
  }

  /**
   * Returns an observable that emits whether or not the current viewport is at least as big as the
   * given viewport.
   * @param viewport The viewport to query
   */
  public isAtLeast$(viewport: ViewportName): Observable<boolean> {
    return this._toObservable(() => this.isAtLeast(viewport));
  }

  /**
   * Returns whether or not the current viewport is at most as big as the given viewport.
   * @param viewport The viewport to query
   */
  public isAtMost(viewport: ViewportName): boolean {
    return getViewport(viewport).isBiggerThanOrEqualTo(getViewport(this.viewport));
  }

  /**
   * Returns an observable that emits whether or not the current viewport is at most as big as the
   * given viewport.
   * @param viewport The viewport to query
   */
  public isAtMost$(viewport: ViewportName): Observable<boolean> {
    return this._toObservable(() => this.isAtMost(viewport));
  }

  private _toObservable(fn: () => boolean): Observable<boolean> {
    return this.viewport$.pipe(map(fn), distinctUntilChanged());
  }

  /**
   * Sets the active viewport to the given value
   * @param viewport The new viewport
   */
  protected _setViewport(viewport: ViewportName): void {
    if (this.viewport === viewport) {
      return;
    }

    this._viewport$.next(viewport);
  }

  private _listenToViewportChanges() {
    // Install the listeners outside of angular, because we're running at least two listeners
    // every time the active viewport changes. That would mean we'd be running angular's change
    // detection twice.
    this._zone.runOutsideAngular(() => {
      for (const viewport of VIEWPORT_NAMES) {
        const mediaQuery = this._mediaQueries[viewport];

        const listener = (mq: MediaQueryListEvent) => {
          if (!this._isOverriden && mq.matches && !this.is(viewport)) {
            // Run this inside of Angular's zone to trigger change detection.
            this._zone.runGuarded(() => this._setViewport(viewport));
          }
        };

        // MediaQueryList was modified in the specs to extend from EventTarget, which adds
        // addEventListener to its API.
        // IE and (older versions of) Safari don't implement this change yet, so we need to fallback
        // to the old addListener method for these browsers.
        // More info: https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList#Methods

        // istanbul ignore else: tests are executed on Chrome
        if (mediaQuery.addEventListener !== undefined) {
          mediaQuery.addEventListener('change', listener);
        } else {
          mediaQuery.addListener(listener);
        }
      }
    });
  }

  /**
   * Overrides the viewport.
   *
   * Setting to a value of null/undefined will undo the override, thereby making the actual viewport
   * active again.
   */
  public setViewport(viewport: ViewportName | null | undefined): void {
    if (viewport != null) {
      this._isOverriden = true;
      this._setViewport(viewport);
    } else {
      this._isOverriden = false;

      for (const viewportName of VIEWPORT_NAMES) {
        if (this._mediaQueries[viewportName].matches) {
          this._setViewport(viewportName);
          break;
        }
      }
    }
  }
}

/**
 * ViewportService superclass which provides all of the public API of the ViewportService. It
 * doesn't implement how the active viewport is retrieved, allowing the subclasses to decide this on
 * their own.
 *
 * This type is not injectable and it is not exported. Always inject the `ViewportService` instead.
 */
@Injectable({
  providedIn: 'root',
  useExisting: ViewportServiceImpl,
})
export abstract class ViewportService {
  /**
   * Returns an observable watching the active viewport. The observable always immediately emits the
   * current viewport.
   */
  public readonly viewport$: Observable<ViewportName>;

  /**
   * Returns the currently active viewport.
   */
  public readonly viewport: ViewportName;

  /**
   * Returns whether or not the given viewport is currently active
   * @param viewport The viewport to query
   */
  public abstract is(viewport: ViewportName): boolean;

  /**
   * Returns an observable that emits whether or not the viewport matches the given value.
   * @param viewport The viewport to query
   */
  public abstract is$(viewport: ViewportName): Observable<boolean>;

  /**
   * Returns whether or not the current viewport is at least as big as the given viewport.
   * @param viewport The viewport to query
   */
  public abstract isAtLeast(viewport: ViewportName): boolean;

  /**
   * Returns an observable that emits whether or not the current viewport is at least as big as the
   * given viewport.
   * @param viewport The viewport to query
   */
  public abstract isAtLeast$(viewport: ViewportName): Observable<boolean>;

  /**
   * Returns whether or not the current viewport is at most as big as the given viewport.
   * @param viewport The viewport to query
   */
  public abstract isAtMost(viewport: ViewportName): boolean;

  /**
   * Returns an observable that emits whether or not the current viewport is at most as big as the
   * given viewport.
   * @param viewport The viewport to query
   */
  public abstract isAtMost$(viewport: ViewportName): Observable<boolean>;
}
