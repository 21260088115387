import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

/**
 * @ngModule AdditionalInformationModule
 */
@Component({
  selector: 'maia-additional-information[custom]:not([slideIn]):not([collapsible])',
  templateUrl: './additional-information-custom.component.html',
  styleUrls: ['./additional-information-custom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalInformationCustomComponent {
  public isOpen = false;

  @Input('openLinkText')
  public openLinkText?: Text | string | null;

  @Input('closeLinkText')
  public closeLinkText?: Text | string | null;

  @Output() public click: EventEmitter<boolean> = new EventEmitter<boolean>();

  public constructor() {}

  public emitClickEvent(): void {
    this.isOpen = !this.isOpen;
    this.click.emit(this.isOpen);
  }
}
