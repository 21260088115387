export type GlobalDate = Date;

export default class GlobalDateModule {
  static today(): GlobalDate {
    return new Date();
  }

  static create(year: number, month: number, date: number): GlobalDate {
    return new Date(year, month, date);
  }
}

export const today = GlobalDateModule.today;
export const create = GlobalDateModule.create;
