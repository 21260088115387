import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 *  *
 * @ngModule TilesModule
 * @deprecated in favor of maia-image-content-layout
 *
 * Image Tile Component
 * Small version
 *
 * This component contains an image-tile-image, image-tile-icon, image-tile-label and optional image-tile-sublabel
 * The image-tile-icon and image-tile-sublabel can be optional
 *
 * An example using an svg background image and an svg icon:
 *
 * ``` html
 * <maia-image-tile>
 *   <maia-image-tile-image>
 *     <svg ivyMaiaAvatarUtilPrivate></svg>
 *   </maia-image-tile-image>
 *   <maia-image-tile-icon>
 *     <svg>...</svg>
 *   </maia-image-tile-icon>
 *   <maia-image-tile-label>Seat Ibiza</maia-image-tile-label>
 *   <maia-image-tile-sublabel>1-BJB-007</maia-image-tile-sublabel>
 * </maia-image-tile>
 * ```
 */
@Component({
  selector: 'maia-image-tile:not([large])',
  templateUrl: './image-tile.component.html',
  styleUrls: ['./image-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageTileComponent {}
