import {ComponentFactoryResolver, Inject, Injectable, Injector, ValueProvider} from '@angular/core';
import {ModalOptions, ModalResolution, ModalResult} from '@maia/modals';
import {SlideInController, SlideInOptions} from '@maia/slide-ins';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {
  FILE_UPLOADER_ANALYTICS_SERVICE,
  FileUploaderAnalyticsServiceInterface,
} from '../shared/file-uploader-analytics.service';
import {DEFAULT_SETTINGS_PROVIDER, FILE_UPLOADER_SETTINGS} from '../shared/file-uploader-settings';

import {Reference} from './file-uploader.call.factory';
import {FileUploader} from './file-uploader.component';

/**
 * Controller that will launch the file upload slide-in.
 */
@Injectable()
export class FileUploaderController {
  public constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly injector: Injector,
    private readonly slideInController: SlideInController,
    @Inject(FILE_UPLOADER_ANALYTICS_SERVICE)
    private readonly fileUploaderAnalyticsService: FileUploaderAnalyticsServiceInterface,
  ) {}

  /**
   * Prepares the file upload slide-in. Delegates to the slide-in controller.
   * @param options
   * @param modalOptions
   */
  public prepare(
    options: SlideInOptions,
    modalOptions?: ModalOptions,
  ): Observable<ModalResult<Reference[]>> {
    return this.slideInController
      .prepare(
        this.componentFactoryResolver.resolveComponentFactory(FileUploader),
        this.injector,
        {...options, forceFullHeight: true},
        this.prepareModalOptions(modalOptions),
      )
      .pipe(
        tap(({resolution}) => {
          if (resolution === ModalResolution.CONFIRMED) {
            // the main reason to execute both promises in an async block
            // is that analytics sends events reading from window so both events
            // can overlap info in this case
            (async () => {
              if (this.fileUploaderAnalyticsService.trackConfirmSlideIn) {
                await this.fileUploaderAnalyticsService.trackConfirmSlideIn();
              }
              if (this.fileUploaderAnalyticsService.trackCloseSlideIn) {
                await this.fileUploaderAnalyticsService.trackCloseSlideIn();
              }
            })();
          } else {
            if (this.fileUploaderAnalyticsService.trackCloseSlideIn) {
              this.fileUploaderAnalyticsService.trackCloseSlideIn();
            }
          }
        }),
      );
  }

  private prepareModalOptions(modalOptions?: ModalOptions): ModalOptions {
    const mergedModalOptions = Object.assign(
      {
        withVisibleBackdrop: true,
        withClickableBackdrop: true,
      },
      modalOptions,
    );
    if (mergedModalOptions.providers != null) {
      const foundSettings = mergedModalOptions.providers.find(
        (provider: ValueProvider) => provider.provide === FILE_UPLOADER_SETTINGS,
      ) as ValueProvider;
      if (foundSettings != null) {
        // If a ValueProvider was used, merge it with the default one
        if (foundSettings.useValue != null) {
          this.simpleMerge(foundSettings.useValue, DEFAULT_SETTINGS_PROVIDER.useValue);
        }
      } else {
        mergedModalOptions.providers.push(DEFAULT_SETTINGS_PROVIDER);
      }
    } else {
      mergedModalOptions.providers = [DEFAULT_SETTINGS_PROVIDER];
    }
    return mergedModalOptions;
  }

  private simpleMerge(target: {[key: string]: any}, source: {[key: string]: any}) {
    for (const key of Object.keys(source)) {
      if (!target.hasOwnProperty(key)) {
        target[key] = source[key];
      }
    }
  }
}
