import {BehaviorSubject, Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';

export type RewritableBooleanProperties =
  | 'showBackButton'
  | 'showCloseButton'
  | 'showFlowProgress'
  | 'showLiveHelpButton'
  | 'showKateButton'
  | 'showExtraActionButton'
  | 'vetoIsForced';
export type RewritableStringPropertes = 'vetoCustomBody';

type ObservableOutputTypes = boolean | string | undefined | null;

export class HeaderPropertiesRewritable {
  public readonly showBackButton = new BehaviorSubject<boolean | null>(null);
  public readonly showCloseButton = new BehaviorSubject<boolean | null>(null);
  public readonly showFlowProgress = new BehaviorSubject<boolean | null>(null);
  public readonly showLiveHelpButton = new BehaviorSubject<boolean | null>(null);
  public readonly showKateButton = new BehaviorSubject<boolean | null>(null);
  public readonly showExtraActionButton = new BehaviorSubject<boolean | null>(null);

  public readonly vetoIsForced = new BehaviorSubject<boolean | null>(null);
  public readonly vetoCustomBody = new BehaviorSubject<string | null>(null);

  public getProperty(
    name: RewritableBooleanProperties,
    defaultValue: Observable<boolean>,
  ): Observable<boolean>;
  public getProperty(
    name: RewritableStringPropertes,
    defaultValue?: Observable<string | undefined>,
  ): Observable<string | undefined>;
  public getProperty(
    name: RewritableBooleanProperties | RewritableStringPropertes,
    defaultValue?: Observable<ObservableOutputTypes>,
  ): Observable<ObservableOutputTypes> {
    if (defaultValue == null) {
      return this[name];
    }

    return (this[name] as Observable<ObservableOutputTypes>).pipe(
      switchMap(value => (value != null ? of(value) : defaultValue)),
    );
  }
}
