/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {ActivatedRoute} from '@angular/router';

/**
 * This method travels down the three until there isn't a firstChild anymore.
 * @param route
 */
export function getDeepestRoute(route: ActivatedRoute): ActivatedRoute {
  while (route.firstChild) {
    route = route.firstChild;
  }
  return route;
}

function canAndShouldSearchHigherInRouterTree(
  route: ActivatedRoute,
  defaultValue: any,
  matchedValue: any,
): boolean {
  return !!route.parent && matchedValue === defaultValue;
}

function getValueFromRouteOrReturnDefaultValue(
  route: ActivatedRoute,
  propertyName: string,
  defaultValue: any,
): any {
  const {data} = route.snapshot;
  return propertyName in data ? data[propertyName] : defaultValue;
}
export function fetchBooleanPropertyInsideRouteTree(
  startRoute: ActivatedRoute,
  propertyName: string,
  defaultValue: boolean,
): boolean {
  let currentRoute = startRoute;
  let value = getValueFromRouteOrReturnDefaultValue(currentRoute, propertyName, defaultValue);
  while (canAndShouldSearchHigherInRouterTree(currentRoute, defaultValue, value)) {
    currentRoute = currentRoute.parent as ActivatedRoute;
    value = getValueFromRouteOrReturnDefaultValue(currentRoute, propertyName, defaultValue);
  }
  return value;
}

export function fetchBooleanPropertyInsideRouteTreeIfNotOverridden(
  startRoute: ActivatedRoute,
  propertyName: string,
  defaultValue: boolean,
  // undefined is actually used as a possible value
  // and overrideValue: boolean|undefined = undefined is fugly
  overrideValue?: boolean,
): boolean {
  return overrideValue == null
    ? fetchBooleanPropertyInsideRouteTree(startRoute, propertyName, defaultValue)
    : overrideValue;
}
