import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationFuncLightbulbSvgModule
 */
@Component({
  selector: 'svg[ivyMaiaInformationFuncLightbulb]',
  template: '<svg:path d="M18.56 9.54a6.56 6.56 0 1 0-9 6.08v2.13h4.92v-2.13a6.56 6.56 0 0 0 4.08-6.08z" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M14.46 17.75V21H9.54v-3.25" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M8.72 9.54A3.28 3.28 0 0 1 12 6.26" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationFuncLightbulbSvgComponent {
}

@NgModule({
  declarations: [
    InformationFuncLightbulbSvgComponent,
  ],
  exports: [
    InformationFuncLightbulbSvgComponent,
  ],
})
export class InformationFuncLightbulbSvgModule {}
