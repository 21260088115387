import {ChangeDetectionStrategy, Component, Optional} from '@angular/core';
import {TileDocumentSignComponent} from '../tile-document-sign/tile-document-sign.component';
import {throwErrorWhenInvalidParent} from '../tile-document-sign/tile-document-sign.util';

/**
 * maia-tile-document-sign-text Component
 *
 * This component contains the text
 * Can only be used inside a maia-tile-document-sign
 *
 * @ngModule TilesModule
 */
@Component({
  selector: 'maia-tile-document-sign-text',
  template: '<ng-content></ng-content>',
  styleUrls: ['./tile-document-sign-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileDocumentSignTextComponent {
  public constructor(@Optional() parent?: TileDocumentSignComponent) {
    throwErrorWhenInvalidParent('<maia-tile-document-sign-text />', parent);
  }
}
