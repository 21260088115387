import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  Optional,
  Renderer2,
} from '@angular/core';
import {CssClassUtility, CssClassUtilityFactory} from '@maia/core';
import {TileItemsComponent} from '../tile-items/tile-items.component';

export type TileItemAlign = 'center' | 'right';
export type TileItemVerticalAlign = 'end' | 'center';

const TileItemClasses = {
  contentAlign: {center: 'maia-tile-item--align-center', right: 'maia-tile-item--align-right'} as {
    [key in TileItemAlign]: string;
  },
  verticalAlign: {
    end: 'maia-tile-item--vertical-align-self-end',
    center: 'maia-tile-item--vertical-align-self-center',
  } as {[key in TileItemVerticalAlign]: string},
};

/**
 * Tile item component
 *
 * @ngModule TilesModule
 */
@Component({
  selector: 'maia-tile-item',
  template: '<ng-content></ng-content>',
  styleUrls: ['./tile-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileItemComponent {
  private _contentAlign: TileItemAlign;
  private _noFlex = false;
  private _verticalAlign: TileItemVerticalAlign;

  private readonly _cssClassUtility: CssClassUtility<typeof TileItemClasses>;

  @Input()
  public label: string;

  public constructor(
    cssClassUtilityFactory: CssClassUtilityFactory,
    renderer: Renderer2,
    elementRef: ElementRef,
    @Optional() parent?: TileItemsComponent,
  ) {
    if (parent === null) {
      throw new Error(
        'Cannot create a <maia-tile-item /> component out of <maia-tile-items /> component',
      );
    }
    this._cssClassUtility = cssClassUtilityFactory.create(TileItemClasses, renderer, elementRef);
  }

  @Input()
  public get contentAlign(): TileItemAlign {
    return this._contentAlign;
  }

  public set contentAlign(contentAlign: TileItemAlign) {
    if (contentAlign === this._contentAlign) {
      return;
    }

    this._cssClassUtility.setValue('contentAlign', contentAlign);
    this._contentAlign = contentAlign;
  }

  @Input()
  public get verticalAlign(): TileItemVerticalAlign {
    return this._verticalAlign;
  }

  public set verticalAlign(verticalAlign: TileItemVerticalAlign) {
    if (verticalAlign === this._verticalAlign) {
      return;
    }

    this._cssClassUtility.setValue('verticalAlign', verticalAlign);
    this._verticalAlign = verticalAlign;
  }

  /**
   * No flex on tile item
   */
  @Input()
  @HostBinding('class.maia-tile-item--no-flex')
  public get noFlex(): boolean {
    return this._noFlex;
  }

  public set noFlex(noFlex: boolean) {
    this._noFlex = coerceBooleanProperty(noFlex);
  }
}
