import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SignerOkSvgModule
 */
@Component({
  selector: 'svg[ivyHermesSignerOk]',
  template: '<svg:path d="M218.6 225.4c-5.6 0-9.7 2.6-13.3 6.7s-5.1 9.2-5.1 15.9v13.8c0 6.7 2 11.8 5.1 15.9s7.7 6.7 13.3 6.7c6.1 0 10.2-2.6 13.8-6.7s5.1-9.2 5.1-15.9V248c0-6.7-2-11.8-5.1-15.9-3-4.1-7.6-6.7-13.8-6.7z"></svg:path><svg:path d="M475.4 125L70.1 150.6c-23.1 1.5-44.6 21.5-47.7 44.1L.4 348.4c-3.1 23.1 13.3 41 36.4 38.4l405.8-36.4c23.1-2 44.6-22.5 48.2-45.6l21-140.9c2.5-22.5-13.4-40.4-36.4-38.9zM250.9 261.8c0 9.2-3.1 17.4-8.7 23.1-5.6 5.6-13.3 9.2-23.1 9.2-8.7 0-16.9-3.1-23.1-9.2-6.1-6.1-8.2-13.8-8.2-23.1V248c0-9.2 2.6-17.4 8.2-23.1 5.6-6.1 12.8-9.2 23.1-9.2 9.2 0 17.4 3.1 23.1 9.2 5.6 6.1 8.7 13.8 8.7 23.1v13.8zm60.5 31.3l-24.6-32.8h-8.7v33.3h-12.8v-77.4h12.8V249h7.7l24.1-32.8h15.4L297 253.1l30.2 40h-15.8z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 512 512',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignerOkSvgComponent {
}

@NgModule({
  declarations: [
    SignerOkSvgComponent,
  ],
  exports: [
    SignerOkSvgComponent,
  ],
})
export class SignerOkSvgModule {}
